import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords, emailValidator } from 'src/app/theme/utils/app-validators';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Address, CompanyProfile, DigitalConsent, LatLng, StripePaymentDetails } from 'src/app/data-models/models';
import { Subscription } from 'rxjs';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';

@Component({
  selector: 'app-partner-license-company',
  templateUrl: './partner-license-company.component.html',
  styleUrls: ['./partner-license-company.component.scss']
})
export class PartnerLicenseCompanyComponent implements OnInit {
  @Output('register') registerEmitter: EventEmitter<any> = new EventEmitter<any>();

  public registerForm: FormGroup;

  private keyTypes: any = keyTypesEnum;
  public termViewer: boolean = false;

  private imgSubscription: Subscription;
  public imgId: string = null;
  public image: any;
  public imageDeleteAction: boolean = false;

  public termsAndConditionsConsent: DigitalConsent = {
    user: this.navigatorService.getProfileId(),
    company: this.navigatorService.getPlatformId(),
    title: "Partner License Terms & Conditions",
    field: 'SWARM-PL-T&C',
    consent: false,

    link: 'www.swarmoperative.com/partner-license-terms-conditions',

    location: null,
    ipData: null,
    date: undefined
  };

  constructor(
    public formBuilder: FormBuilder, 
    public router:Router, 
    public snackBar: MatSnackBar, 
    public navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService,
    private paymentHandler: PaymentHandlerService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      company_name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.required],
  
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      website: null,

      image: null,

      ein: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],

      street: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      city: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      state: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      zip: ['', Validators.compose([Validators.required, Validators.minLength(5)])]
    });

  }

  ngOnDestroy() {

    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }
  }  

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {

      if(img) {
        this.image = img;

        console.log("Image: ", this.image);
      }

    });
    
  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getFile(this.imgId);
    }
  }

  public uploadImage(image: any, folder: string): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique, folder).then(data => {
          this.imgId = imgIdUnique;
          this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
  }

  public deleteImg(): void {
    this.registerForm.controls["image"].setValue("");
    this.image = null;
    this.imageDeleteAction = true;
  }

  public onRegisterFormSubmit():void {
    if (this.registerForm.valid) {
      let companyId: string = this.navigatorService.generateKey(this.keyTypes.COMPANY_ID);
      let imgId: string = "";

      if((this.registerForm.get("image").value != null && this.registerForm.get("image").value.length > 0) || this.imageDeleteAction) {
        imgId = this.uploadImage(this.registerForm.get("image").value, companyId);
        this.imageDeleteAction = false;
      } else {
        imgId = this.imgId; 
      }

      let address: Address = {
        street: this.registerForm.get("street").value,
        city: this.registerForm.get("city").value,
        state: this.registerForm.get("state").value,
        zip: this.registerForm.get("zip").value
      }

      let company: CompanyProfile = {
        id: companyId,
        company_name: this.registerForm.get("company_name").value,
        email: this.registerForm.get("email").value,
        phone: this.registerForm.get("phone").value,
        address: address,
        linkedin: this.registerForm.get("linkedin").value,
        twitter: this.registerForm.get("twitter").value,
        instagram: this.registerForm.get("instagram").value,
        facebook: this.registerForm.get("facebook").value,
        website: this.registerForm.get("website").value,

        image: imgId,
        created_date: new Date
      }

      this.navigatorService.registerNewCompany(company).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Registration successfull!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.registerEmitter.emit();
        }

      });

    }
  }

  public termView(): void {
    this.termViewer = true;
  }

  public unlockView(): void {
    this.termViewer = false;
  }

  public consent(): void {

    this.navigatorService.getGeoLocation().then( (latLng: LatLng) => {

      this.termsAndConditionsConsent.location = latLng;

      this.navigatorService.getIP().then( (ipData: any) => {

        this.termsAndConditionsConsent.ipData = ipData;
        this.termsAndConditionsConsent.date = new Date();

      });

    });

  }

  public unlock(): void {

    let invoice: StripePaymentDetails = {
      stripeAccount: '',
      paymentDetails: [
        {
          name: "2 Yr. Partner License",
          currency: "usd",
          quantity: 1,
      
          // tax_rates: number;
          amount: 500000
        }
      ]
    };

    // this.paymentHandler.makePlatformStripePayment(invoice);

  }

}
