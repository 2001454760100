import {
  Directive, 
  Input, 
  OnChanges, 
  AfterViewInit,
  ElementRef, 
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ViewRef,
  HostBinding
} from '@angular/core';

import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { HostListener } from "@angular/core";

// Adjustable Components
import { VendorCalendarComponent } from "../../shared/professional/vendor-calendar/vendor-calendar.component";
import { ActiveBidExpandableTableComponent } from "../../shared/active-bid-expandable-table/active-bid-expandable-table.component";

enum testEnum {
  HORIZONTAL = 1,
  VERTICAL = 2,
  FREE = 3
}

@Directive({
  selector: '[cbDragDrop]'
})
export class ContentBuilderDragDropDirective implements OnChanges, AfterViewInit {

  @Input()
  cbClick: Function;

  @Input()
  cbMousedown: any;

  @Input()
  cbMouseup: any;

  @Input()
  cbMove: any;

  @Input()
  cbBoundary: string;

  @Input()
  cbResize: boolean;

  @Input()
  cbResizeVertical: boolean;

  @Input()
  cbResizeHorizontal: boolean;

  @Input()
  cbHoldingContainer: string;

  @Input()
  cbReceivingContainer: string;

  @Input()
  cbCurrentContainer: string;

  @Input()
  cbHoldingDragDisabled: true;

  @Input()
  cbReceivingDragDisabled: true;

  @Input()
  cbViewContainerRef: ViewContainerRef;

  child_unique_key: number = 0;
  componentsReferences = Array<ComponentRef<any>>()
  

  // @Input()
  // cardNumber: string;

  private target;
  private elementMouseDown: boolean;
  private elementMouseOver: boolean;
  private elementMoving: boolean;
  private elementResizing: boolean;
  private elementResized: boolean;

  

  constructor(private elementRef: ElementRef, private sanitized: DomSanitizer, private CFR: ComponentFactoryResolver) { 
    this.target = elementRef;
    this.elementMouseDown = false;
    this.elementMouseOver = false;
    this.elementMoving = false;
    this.elementResizing = false;
    this.elementResized = false;
 }

 createComponent() {
  // let componentFactory = this.CFR.resolveComponentFactory(VendorCalendarComponent);

  // let childComponentRef = this.VCR.createComponent(componentFactory);

  // let childComponent = childComponentRef.instance;

  // // add reference for newly created component
  // this.componentsReferences.push(childComponentRef);
}

 ngAfterViewInit(): void {
  if(!this.cbHoldingContainer) {
    this.cbCurrentContainer = this.target.nativeElement.parentElement;
  } else {
    this.cbCurrentContainer = this.cbHoldingContainer;
  }
}

  ngOnChanges() {
    
  }

  testClick(): void {
    // console.log("clicked");
  }

  @HostListener('mouseover', ['$event'])
  mouseOver(event: MouseEvent): void {
    this.elementMouseOver = true;
  }

  @HostListener('mouseout', ['$event'])
  mouseOut(event: MouseEvent): void {
    this.elementMouseOver = false;
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent): void {
    this.elementMouseDown = true;
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(event: MouseEvent): void {
    this.elementMouseDown = false;

    // (this.ddClick)();
  }

  @HostListener('mouseup', ['$event'])
  drop(event: MouseEvent) {

    let componentFactory: any = this.CFR.resolveComponentFactory(ActiveBidExpandableTableComponent);

    let childComponentRef = this.cbViewContainerRef.createComponent(componentFactory);

    // Type listed as any to allow for multiple child component types
    let childComponent:any = childComponentRef.instance;
    
    // add reference for newly created component
    this.componentsReferences.push(childComponentRef);

    // FIX
    // An ID check MUST be done and any element without an ID must not be allowed to build
    if(childComponent.id) {

    } else {
      // let componentRef = this.componentsReferences[0];
      // let vcrIndex: number = this.cbViewContainerRef.indexOf(componentRef as any);
      // this.cbViewContainerRef.remove(vcrIndex);
    }

    let targetedElement: any = event.currentTarget;
    // ALL TARGET IDENTITFYING ELEMENTS MUST BE RECONFIGURED
    if (targetedElement.classList.contains("document-builder-item")) {
        let element = targetedElement,
        container =  document.querySelector(this.cbCurrentContainer);
        // bodyRect = document.body.getBoundingClientRect(),
        // elemRect = element.getBoundingClientRect();
        // elementY = elemRect.top - bodyRect.top,
        // elementX = elemRect.left - bodyRect.left;
        
        let elementY:number = Number(element.style.top.replace("px", ""));
        let elementX = Number(element.style.left.replace("px", ""));

            // Top Boundary
        if(elementY + event.movementY < 0) {
            element.style.top = 0 + "px";
        } else {
                // Bottom Boundary
            if(elementY + element.clientHeight + event.movementY > container.clientHeight) {
                element.style.top = container.clientHeight - element.clientHeight + "px";
            } else {
                element.style.top = elementY + event.movementY  + "px";
            }
        }

            // Right Boundary
        if(elementX + element.clientWidth + event.movementX > container.clientWidth) {
            element.style.left = container.clientWidth - element.clientWidth + "px";
        } else {
                // Left Boundary
            if(elementX + event.movementX < 0) {
                element.style.left = 0 + "px";
            } else {
                element.style.left = elementX + event.movementX + "px";
            }
        }         
    } 
    
    else {
        // if(event.previousContainer.element.nativeElement === event.container.element.nativeElement) {

        // } else {

            

            let test: any = event.currentTarget;
            // let returnElement = event.item.element.nativeElement;
            let receivingContainer = document.querySelector(this.cbReceivingContainer);

            let element: any = document.querySelector("#" + childComponent.componentId);
            // let element: any = document.createElement("div");
            // element.innerHTML = test.parentElement.innerHTML;
            // let listContainer = event.item.element.nativeElement.parentElement;

            if(element.firstChild.classList.contains("list-placeholder")) {
                element.firstChild.classList.remove("list-placeholder");
                element.firstChild.classList.remove("cdk-drag-dragging");
                element.firstChild.classList.add("document-builder-item");
            }

            var elementY:number = Number(test.style.top.replace("px", ""));
            let elementX:number = Number(test.style.left.replace("px", ""));
            let elementParent:any = test.offsetParent;
            let elementListContainer:any = test.parentElement.parentElement.parentElement;
            let elementContainerWidth:number =  Number(elementParent.offsetWidth);
            let elementContainerHeightOffset:number = Number(test.offsetTop);
            let elementOptionsContainerHeightOffset:number = Number(elementListContainer.firstChild.clientHeight);

            // Top Boundary
            if(elementY + event.movementY + elementOptionsContainerHeightOffset- elementContainerHeightOffset < 0) {
                element.style.top = 0 + "px";
            } else {
                    // Bottom Boundary
                if(elementY + test.clientHeight + event.movementY + elementContainerHeightOffset > receivingContainer.clientHeight) {
                    element.style.top = receivingContainer.clientHeight - test.clientHeight + "px";
                } else {
                    element.style.top = elementY + event.movementY + elementContainerHeightOffset + elementOptionsContainerHeightOffset + "px";
                }
            }

                // Right Boundary
            if(elementX + event.movementX + test.clientWidth - elementContainerWidth > receivingContainer.clientWidth) {
                element.style.left = receivingContainer.clientWidth - test.clientWidth + "px";
            } else {
                    // Left Boundary
                if(elementX + event.movementX < elementContainerWidth) {
                    element.style.left = 0 + "px";
                } else {
                    element.style.left = elementX + event.movementX - elementContainerWidth + "px";
                }
            }   

            element.addEventListener("click", (event: Event) => {
                (this.cbClick)();
            });


            // let testElement:any = this.sanitized.bypassSecurityTrustHtml(element);
            // element.innerHTML = this.sanitized.bypassSecurityTrustHtml(element.innerHTML);

            // ERROR: CANNOT APPEND AN ELEMENT WITHOUT IT BEING SANITIZED. NEED TO BIND IT 
            // receivingContainer.appendChild(element);
            // listContainer.appendChild(returnElement);
        }      
    // }
  }

  @HostListener('mousemove', ['$event'])
  adjustElement(event: MouseEvent) {
    console.log("Move.elementMouseDown: " + this.elementMouseDown);
    if(this.elementMouseDown) {
        // Determine if it's an element drag or if a resize handle is being dragged
    }
  }

  dragElement(): void {

  }

  resizeElement(): void {

  }

  tempCode() {
        // this.testTarget = document.querySelectorAll(".test-box");
        // this.horizontTargetElements =  document.querySelectorAll(".horizontal-resizer-handler");
        // this.verticalTargetElements =  document.querySelectorAll(".vertical-resizer-handler");
        // this.freeTargetElements =  document.querySelectorAll(".free-resizer-handler");
        // this.rootElement =  document.querySelector("html");

        // for(let horizontalTargetIndex = 0; horizontalTargetIndex < this.horizontTargetElements.length; horizontalTargetIndex++) {

        //     this.mousedown$ = fromEvent(this.horizontTargetElements[horizontalTargetIndex], 'mousedown');
        //     this.mousedown$.subscribe((e) => {
        //         this.isHandleClicked = true;
        //         this.moveTarget = horizontalTargetIndex;
        //         this.resizeState = this.resizeHandle.HORIZONTAL;

        //         this.targetElement = this.horizontTargetElements[this.moveTarget].parentElement;
        //         // this.x = e.x;
        //         // this.y = e.y;
        //     })
        // }

        // for(let verticalTargetIndex = 0; verticalTargetIndex < this.verticalTargetElements.length; verticalTargetIndex++) {

        //     this.mousedown$ = fromEvent(this.verticalTargetElements[verticalTargetIndex], 'mousedown');
        //     this.mousedown$.subscribe((e) => {
        //         this.isHandleClicked = true;
        //         this.moveTarget = verticalTargetIndex;
        //         this.resizeState = this.resizeHandle.VERTICAL;

        //         this.targetElement = this.verticalTargetElements[this.moveTarget].parentElement;
                
        //         // this.x = e.x;
        //         // this.y = e.y;
        //     })
        // }

        // for(let freeTargetIndex = 0; freeTargetIndex < this.freeTargetElements.length; freeTargetIndex++) {

        //     this.mousedown$ = fromEvent(this.freeTargetElements[freeTargetIndex], 'mousedown');
        //     this.mousedown$.subscribe((e) => {
        //         this.isHandleClicked = true;
        //         this.moveTarget = freeTargetIndex;
        //         this.resizeState = this.resizeHandle.FREE;

        //         this.targetElement = this.freeTargetElements[this.moveTarget].parentElement;
                
        //         // this.x = e.x;
        //         // this.y = e.y;
        //     })
        // }

        // this.mousemove$ = fromEvent(this.rootElement, 'mousemove');
        // this.mousemove$.subscribe((e) => {
        //     if(this.isHandleClicked) {
        //         let parentContainer = this.targetElement.parentElement;

        //         if(this.targetElement.classList.contains("child")) {
        //             while(this.targetElement.classList.contains("child")) {
        //                 this.targetElement = this.targetElement.parentElement;
        //             }
        //         }

        //         // let parentContainerWidth = parentContainer.parentElement.parentElement.clientWidth;
        //         // let parentContainerHeight = parentContainer.parentElement.parentElement.clientHeight;
        //         // let elementWidth = this.horizontTargetElements[this.moveTarget].parentElement.clientWidth;
        //         // let elementContainerOffset = this.horizontTargetElements[this.moveTarget].parentElement.offsetLeft + elementWidth;

        //         switch(this.resizeState) {
        //             case this.resizeHandle.HORIZONTAL:
                        

        //                 // if(elementContainerOffset <= parentContainerWidth || e.movementX < 0) {
        //                 // if(e.x <= parentContainerWidth) {
                            
        //                     this.targetElement.style.width = this.targetElement.clientWidth + e.movementX + "px";
        //                 // }
        //                 break;
        //             case this.resizeHandle.VERTICAL:
        //                 // if(e.y <= parentContainerHeight) {
        //                     this.targetElement.style.height = this.targetElement.clientHeight + e.movementY + "px";
        //                 // }
        //                 break;

        //             case this.resizeHandle.FREE:
        //                 // if(e.y <= parentContainerHeight) {
        //                     this.targetElement.style.width = this.targetElement.clientWidth + e.movementX + "px";
        //                     this.targetElement.style.height = this.targetElement.clientHeight + e.movementY + "px";
        //                 // }
        //                 break;
        //         }
        //     }
        // })

        // this.mouseup$ = fromEvent(this.rootElement, 'mouseup');
        // this.mouseup$.subscribe(()=>{
        //     if(this.targetElement) {
        //         this.targetElement = 0;
        //     }

        //     this.isHandleClicked = false;
        // });
  }

}