import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupPermissions, Profile } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

export enum TeamViews {
  ASSIGNED = 0,
  ADD_TEAM = 1,
  ADD_EMPLOYEE = 2
}

@Component({
  selector: 'app-vendor-assign-staff',
  templateUrl: 'vendor-assign-staff.component.html',
  styleUrls: ['vendor-assign-staff.component.scss']
})

export class VendorAssignStaffComponent implements OnInit {
  @Input() teams: Array<string> = [];
  @Input() staff: Array<string> = [];
  @Input() companyId: string = null;
  @Input() customerViewer: boolean = false;

  @Output('assignedStaffUpdate') assignedStaffUpdateEmitter: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output('assignedTeamsUpdate') assignedTeamsUpdateEmitter: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  public teamViews: any = TeamViews;
  public filteredEmployeeOptions: Array<string> = [];
  public filteredEmployeeProfiles: Array<Profile> = [];
  private employeeLookupText: string = "";
  private focusTeamLookup: string = "";

  public assignedStaff: Array<Profile> = [];
  public assignedTeams: Array<GroupPermissions> = [];

  public addStaffPanel: boolean = false;
  public groups: Array<GroupPermissions> = [];

  public teamFocusView: number = this.teamViews.ASSIGNED;

  constructor(
    public snackBar: MatSnackBar, 
    public navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService
  ) { 
    
  }

  ngOnInit() {
    if(this.teams == undefined || this.teams == undefined) {
      this.teams = [];
    }

    if(this.staff == undefined || this.staff == undefined) {
      this.staff = [];
    }

    this.getGroups();

    this.getAssignedStaff().then( (assignedStaff: Array<Profile>) => {

      this.assignedStaff = assignedStaff;
      this.getAllProfileImgs();

    });

    this.getAssignedTeams().then( (assignedTeams: Array<GroupPermissions>) => {

      this.assignedTeams = assignedTeams;

    });

  }

  ngOnChanges(changes: SimpleChanges | any) {

    console.log("Assigned Staff", this.staff);

    if(this.staff == undefined || this.staff == null || !Array.isArray(this.staff) ) {
      this.staff = [];
    }

    this.getAssignedStaff().then( (assignedStaff: Array<Profile>) => {

      this.assignedStaff = assignedStaff;
      this.getAllProfileImgs();

    });

    this.getAssignedTeams().then( (assignedTeams: Array<GroupPermissions>) => {

      this.assignedTeams = assignedTeams;

    });

  }

  public getAllProfileImgs(): void {
    let imgIds: Array<string> = [];
    let folder: string = "";

    for(let profile of this.assignedStaff) {

      if(profile.profileImg == undefined) {

        profile.profileImg = {
          id: null,
          file: null
        }

      }

      profile.profileImg.id = profile.image;
      imgIds.push(profile.image);
    }

    if(!this.customerViewer) {
      folder = this.navigatorService.getCompanyId();
    } else {
      folder = this.companyId;
    }


    this.imageHandler.getAsyncFiles(imgIds, folder).then( (imgs: Array<any>) => {

      if(imgs) {
        let index: number = 0;

        for(let profile of this.assignedStaff) {
          profile.profileImg.file = imgs[index];
          index++;
        }

      }

    });
  }

  async getAssignedStaff(): Promise<Array<Profile>> {

    let assignedStaff: Array<Profile> = [];

    for(let profile of this.staff) {

      if(!this.customerViewer) {
        await this.navigatorService.getAsyncEmployeeById(profile).then( (employee: Profile) => {

          if(employee != undefined && employee != null) {
            assignedStaff.push(employee);
          }

        });
      } else {
        await this.navigatorService.getAsyncEmployeeByIdAsForeign(profile, this.companyId).then( (employee: Profile) => {

          if(employee != undefined && employee != null) {
            assignedStaff.push(employee);
          }

        });
      }

    }

    return assignedStaff;
  }

  async getAssignedTeams(): Promise<Array<GroupPermissions>> {

    let assignedTeams: Array<GroupPermissions> = [];

    if(this.teams == undefined) {
      this.teams = [];
    }

    for(let teamId of this.teams) {

      if(!this.customerViewer) {
        await this.navigatorService.getGroupPermission(teamId).then( (team: GroupPermissions) => {

          if(team != undefined && team != null) {

            assignedTeams.push(team);

          }

        });
      } else {
        await this.navigatorService.getGroupPermissionAsForeign(teamId, this.companyId).then( (team: GroupPermissions) => {

          if(team != undefined && team != null) {

            assignedTeams.push(team);

          }

        });
      }

    }

    return assignedTeams;
  }

  private getGroups(): void {

    if(!this.customerViewer) {
      this.navigatorService.getAsyncGroups().then( groups => { 
        
        if(groups != undefined && groups != null) {

          this.groups = groups;

        }

      });
    }

  }

  public deleteTeam(id: string): void {

    console.log("Delete Team: ", this.focusTeamLookup);

    let teamIndex: number = this.teams.findIndex( (team: string) => { 
      return team == id;
    });

    if(teamIndex > -1) {

      this.teams.splice(teamIndex, 1);

      this.snackBar.open('The Team Reduced!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      
      let assignedTeamIndex: number = this.assignedTeams.findIndex( (team: GroupPermissions) => { return team.id == id });

      if(assignedTeamIndex > -1) {
        this.assignedTeams.splice(assignedTeamIndex, 1);
        this.assignedTeamsUpdateEmitter.emit(this.teams);
      }

    } else {

        this.snackBar.open('There was trouble locating team information, please try again later.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });

    }

  }

  public deleteEmployee(id: string): void {

    console.log("Delete Employee: ", this.employeeLookupText);

    let employeeIndex: number = this.staff.findIndex( (profile: string) => { 
      return profile == id;
    });

    if(employeeIndex > -1) {
      this.filteredEmployeeProfiles = [];
      this.filteredEmployeeOptions = [];

      this.staff.splice(employeeIndex, 1);

      this.snackBar.open('The Team Reduced!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      
      let techIndex: number = this.assignedStaff.findIndex( (profile: Profile) => { return profile.id == id });

      if(techIndex > -1) {
        this.assignedStaff.splice(techIndex, 1);
        this.assignedStaffUpdateEmitter.emit(this.staff);
      }

    } else {
      this.snackBar.open('There removing staff, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public addTeam(): void {

    this.teams.push(this.focusTeamLookup);

    this.assignedTeamsUpdateEmitter.emit(this.teams);

    this.snackBar.open('The Team Expanded!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    this.getAssignedTeams().then( (assignedTeams: Array<GroupPermissions>) => {

      this.assignedTeams = assignedTeams;

    });

    this.changeTeamViewerFocus(this.teamViews.ASSIGNED);

  }

  public addEmployee(): void {

    console.log("Add Employee: ", this.employeeLookupText);

    let employee: Profile = this.filteredEmployeeProfiles.find( (profile: Profile) => { 
      let name: string = profile.first_name + ' ' + profile.last_name;
      return name.toLowerCase() == this.employeeLookupText.toLowerCase();
    });

    if(employee != undefined && employee != null) {
      this.filteredEmployeeProfiles = [];
      this.filteredEmployeeOptions = [];

      this.staff.push(employee.id);

      this.snackBar.open('The Team Expanded!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      this.assignedStaff.push(employee);

      this.assignedStaffUpdateEmitter.emit(this.staff);

      this.getAllProfileImgs();

      this.changeTeamViewerFocus(this.teamViews.ASSIGNED);
    } else {
      this.snackBar.open('There was trouble locating profile information. Please make sure the employee\'s name was spelled correctly!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  groupSelectionChange(group: GroupPermissions): void {
    this.focusTeamLookup = group.id;
  }

  public updateFilterText(filterText: string): void {
    this.employeeLookupText = filterText;
  }

  public filterEmployee(filterText: string, updateSearch: boolean = true): void {
    this.employeeLookupText = filterText;
    this.filteredEmployeeOptions = [];

    this.navigatorService.getPagedSearchProfiles(filterText, 0, 10).then( (filteredProfiles: Array<Profile>) => {
      if(updateSearch) {
        this.filteredEmployeeProfiles = [];
      }

      if(filteredProfiles != undefined && filteredProfiles != null) {
        
        if(updateSearch) {
          this.filteredEmployeeProfiles = filteredProfiles;
        }

        for(let company of filteredProfiles) {
          let name: string = company.first_name + ' ' + company.last_name;
          this.filteredEmployeeOptions.push(name);
        }

      }

    });

  }


  public changeTeamViewerFocus(focus: number): void {
    this.teamFocusView = focus;

    this.collapseAddTeamPanel();
  }

  public expandAddTeamPanel(): void {
    this.addStaffPanel = true;
  }

  public collapseAddTeamPanel(): void {
    this.addStaffPanel = false;
  }

}