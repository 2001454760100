import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PaymentHandlerService } from '../../../services/vendor/payment-handler.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { AutomationTag, CRMActivity, CRMDeal, CRMDealArchive, CRMDealLostReason, CRMDealPipeAuditLog, CRMPipe, CRMPipeline, DynamicData, FileObject, GroupPermissions, Profile, RowActionIconGroup, TableActionIconGroup } from 'src/app/data-models/models';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { VendorCRMAddDealDialogComponent } from 'src/app/shared/professional/vendor-crm-add-deal-dialog/vendor-crm-add-deal-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorCreatePipelineDialogComponent } from 'src/app/shared/professional/vendor-create-pipeline-dialog/vendor-create-pipeline-dialog.component';
import { pipeline } from 'stream';
import { ActivityLogCategories, ActivityTypes } from 'src/app/shared/professional/vendor-crm-deal/vendor-crm-deal.component';
import { VendorCRMActivityDialogComponent } from 'src/app/shared/professional/vendor-crm-activity-dialog/vendor-crm-activity-dialog.component';
import { TagTypes } from '../../admin-account/automation-tags/automation-tags.component';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { timeout } from 'rxjs/operators';
import { DynamicSearchMenu } from 'src/app/shared/professional/vendor-search/vendor-search.component';

export enum TransferPanelTypes {
  DELETE = 0,
  WON = 1,
  LOST = 2,
  TRANSFER = 3
}

export enum BoardTypes {
  PROJECTS = 0,
  SALES = 1,
  INTERNAL_TOOLS_SALES = 2
}

export enum ViewModes {
  PIPELINE = 0,
  TABLE = 1,
  CLOSED = 2
}

export interface FilteredPipeDetails {
  id: string,
  value: number,
  numDeals: number
}

@Component({
  selector: 'app-job-board',
  templateUrl: './job-board.component.html',
  styleUrls: ['./job-board.component.scss']
})

export class JobBoardComponent implements OnInit, AfterViewInit {
  public boardTypes: any = BoardTypes;

  @Input('quickActionMenuExpanded') quickActionMenuExpanded: boolean = false;
  @Input('boardType') boardType: number = this.boardTypes.PROJECTS;
  @Input('filterProfiles') filterProfiles: Array<Profile> = [];

  public menu: DynamicSearchMenu = {
    
    advancedSearch: true,
    searchBar: {
      label: "Search...",
      placeholder: "Search...",
      prefix: "",
      value: ""
    },

    dateFilter: {
      startDate: new Date(),
      endDate: new Date()
    },

    radioBtns: {
      options: ["Active", "Archived"],
      value: "Active"
    },

    slideToggles: [
      {
        title: "Won",
        value: false
      },
      {
        title: "Lost",
        value: false
      }
    ]

  };

  public transferPanelTypes: any = TransferPanelTypes;
  public tagTypes: any = TagTypes;
  public viewModes: any = ViewModes;

  public activeViewMode: number = this.viewModes.PIPELINE;

  private openVendorAddDealDialogRef: MatDialogRef<VendorCRMAddDealDialogComponent>;
  private openVendorCreatePipelineDialogRef: MatDialogRef<VendorCreatePipelineDialogComponent>;
  private activityDialogRef: MatDialogRef<VendorCRMActivityDialogComponent>;
  public activityLogCategories: any = ActivityLogCategories;
  public activityTypes: any = ActivityTypes;

  private keyTypes: any = keyTypesEnum;
  
  private assignedImgs: Array<FileObject> = [];

  public focusedPipeline: number = 0;
  public focusedDeal: CRMDeal = null;
  public messagePanelDealFocus: string = null;
  public pipelines: Array<CRMPipeline> = [];
  public pipeline: CRMPipeline = null;
  public pipeDetails: Array<FilteredPipeDetails> = [];
  public filteredPipeDeals: Array<CRMPipe> = [];
  public swimLaneHeight: number = 250;

  public totalPipelineValues: number = 0;
  public weightedPipelineValues: number = 0;

  public pipeIds: Array<string> = [];
  public pipes: Array<CRMPipe> = [];
  public dealTransferComment: string = "";
  public transferDealLostReason: CRMDealLostReason | null = null;
  public transferDealPipeline: CRMPipeline | null = null;
  public transferDealPipes: Array<CRMPipe> = [];
  public transferDealPipe: CRMPipe = null;
  public transferDeal: CRMDeal = null;
  public transferDealType: number = null;
  public transferDealDropEvent: CdkDragDrop<string[]> = null;
  public deals: Array<CRMDeal> = [];
  public teams: Array<GroupPermissions> = [];

  public transferDealLostReasons: Array<CRMDealLostReason> = [
    {
      id: "a1b1c1",
      title: 'No Interest'
    },
    {
      id: "a2b2c2",
      title: 'Went to Competitor'
    },
    {
      id: "a3b3c3",
      title: 'Too Expensive'
    },
    {
      id: "a4b4c4",
      title: 'Other'
    }
  ];

  public plannedActivities: Array<CRMActivity> = [];
  public activityActionPanelFocus: string = "";
  public draggingDeal: boolean = false;
  public isDealPanelOpen: boolean = false;
  public expandTransferPanel: boolean = false;
  public isPipelineDropDownExpanded: boolean = false;
  public isNewPipelineMenuExpanded: boolean = false;
  public messagingPanelExpanded: boolean = false;
  public teamMenuExpanded: boolean = false;
  public tagPanelExpanded: boolean = false;
  public editPipelines: boolean = false;

  public tags: Array<AutomationTag> = [];


  // Dynamic Table Variables
  public dynamicData: DynamicData = { data: [] };
  public dataModel: CRMDeal = {
    id: '',
    woIDs: [],
    assignedStaff: [],
    assignedTeams: [],
    companyTitle: '',
    scope: '',
    address: {
      street: '',
      state: '',
      city: '',
      zip: null
    },
    latLng: {
      lat: null,
      lng: null
    },
    serviceable: null,
    title: '',
    description: '',
    notes: '',
    price: null,
    poc: [],
    tags: [],
    leadSource: '',
    pipeAuditTrail: [],
    auditTrail: [],
    plannedActivities: [],
    pastDueActivities: null,
    activities: [],
    daysRotting: null,
    pipeline: '',
    pipe: '',
    priority: null,
    probability: null,
    expectedCloseDate: null,
    invoices: '',

    visible: null,

    last_update: null,
    created_by: ''
  }

  public pageNumber: number = 0;
  public pageLimit: number = 5;

  public filterString: string = "";
  public filterDateStart: Date = new Date();
  public filterDateEnd: Date = new Date();

  public tableActionIcons: TableActionIconGroup = {
    actionIcons: [ ]
  };

  public rowLeadingActionIcons: Array<RowActionIconGroup> = [
    {
      key: "id",
      title: "",
      styleColorFunction: null,
      actionIcons: [

        {
          title: "Open Deal",
          icon: "handyman",
          color: 
          {
            primary: "inherit",
            secondary: "inherit"
          },
          flipIcon: false,
          actionId: "row_a1"
        }

      ]
    }
  ];

  public rowTrailingActionIcons: Array<RowActionIconGroup> = [ ];

  // Set as a number to allow additional states in later feature upgrades
  public isOpenClosed: number = 0;

  constructor(
    public formBuilder: FormBuilder, 
    public snackBar: MatSnackBar, 
    private navigatorService: NavigatorService,
    private imageHandler: ImageHandlerService,
    private dialog: MatDialog) { 

  }

  ngOnInit() {
    console.log("Initiating Projects Board");
    this.setInitVariables();
    this.getGroups();

    this.getTags().then( () => {
      this.getPipelines();
    });

  }

  ngAfterViewInit(): void {
    
  }

  setInitVariables(): void {

    // Setting our default date search criteria
    this.menu.dateFilter.endDate.setMonth(this.menu.dateFilter.startDate.getMonth() - 12);

  }


  private getPipelines(): void {

    this.pipelines = [];

    this.navigatorService.getAsyncAllCRMPipeline().then( (pipelines: Array<CRMPipeline>) => {

      this.pipelines = pipelines;

      if(this.pipelines.length > 0) {

        if(this.focusedPipeline == undefined || this.focusedPipeline == null) {
          this.focusedPipeline = 0;
        }

        this.generatePipeline();
      }

    });

  }

  private generatePipeline(): void {

    this.navigatorService.getAsyncCRMPipeline(this.pipelines[this.focusedPipeline].id).then( (pipeline: CRMPipeline) => {

      this.pipeline = pipeline;

      this.getPipes();

    });

  }

  private async getPipes(): Promise<void> {

    this.pipes = [];

    for(let pipe of this.pipeline.pipes) {

      await this.navigatorService.getAsyncCRMPipe(pipe).then( (asyncPipe: CRMPipe) => {

        this.pipes.push(asyncPipe)

      });

    }

    this.generatePipelineDeals();

  }

  private async getTags(): Promise<void> {

    this.tags = [];

    await this.navigatorService.getAsyncAutomationTagsByType(this.tagTypes.DEAL).then( (tags: Array<AutomationTag>) => {

      if(tags.length == 0) {

        let defaultTags: Array<any> = [
          {
            id: "default_priority",
            title: "priority",
            border: "#ef9d0d",
            background: "#ef9d0d"
          },
          {
            id: "default_emergency",
            title: "emergency",
            border: "#e90909",
            background: "#e90909"
          },
          {
            id: "default_hot",
            title: "hot",
            border: "#10cb18",
            background: "#10cb18"
          },
          {
            id: "default_routine",
            title: "routine",
            border: "#1097cb",
            background: "#1097cb"
          },
        ]

        // Default Tags

        for(let defaultTag of defaultTags) {
          this.tags.push({
            id: defaultTag.id,
            companyId: this.navigatorService.getCompanyId(),
            title: defaultTag.title,
            tagType: this.tagTypes.DEAL,
          
            automation: null,

            border: defaultTag.border,
            background: defaultTag.background
          });
        }

      } else {
        this.tags = tags;
      }

    });

  }

  private generateTags(deal: CRMDeal): void {

    deal.builtTags = [];

    for(let tagId of deal.tags) {

      let tag: AutomationTag = this.tags.find( ( autoTag: AutomationTag) => { return autoTag.id == tagId });

      if(tag != undefined && tag != null) {
        deal.builtTags.push(tag);
      }

    }

  }

  private async getImg(imgId: string, assignedId?: string): Promise<any> {
    let serverImg: any = null;

    if(imgId != undefined && imgId.length > 0) {

      await this.imageHandler.getAsyncFile(imgId).then(img => {
  
        if(img != undefined && img != null) {

          this.assignedImgs.push({
            id: assignedId,
            file: img
          });

          serverImg = img;
        }
  
      });

    }

    return serverImg;
  }

  private generateDealsAssignedTeams(): void {

    for(let pipe of this.filteredPipeDeals) {

      for(let deal of pipe.deals) {
        deal.assignedTeamImgs = [];

        if(deal.assignedTeams == undefined || deal.assignedTeams == null) {
          deal.assignedTeams = [];
        }

        for(let teamId of deal.assignedTeams) {

          let team: GroupPermissions = this.teams.find( (group: GroupPermissions) => { return group.id == teamId });

          if(team != undefined && team != null) {

            deal.assignedTeamImgs.push({
              id: teamId,
              title: team.group,
              file: team.group.substr(0, 1)
            });

          }

        }

      }

    }

  }

  private async generateDealsAssignedImgs(): Promise<void> {

    for(let pipe of this.filteredPipeDeals) {

      for(let deal of pipe.deals) {
        deal.assignedStaffImgs = [];

        for(let assignedStaff of deal.assignedStaff) {
          let assignedImg: any = this.assignedImgs.find( (assigned: FileObject) => { return assigned.id == assignedStaff });

          if(assignedImg != undefined && assignedImg != null) {
            deal.assignedStaffImgs.push({
              id: assignedStaff,
              file: assignedImg.file
            })
          } else {

            await this.navigatorService.getAsyncEmployeeById(assignedStaff).then( async (employee: Profile) => {

              if(employee != undefined && employee != null) {

                await this.getImg(employee.image, assignedStaff).then( (img: any) => {

                  deal.assignedStaffImgs.push({
                    id: assignedStaff,
                    file: img
                  });

                });

              }
    
            });

          }

        }

      }

    }

  }

  private async updatePipeDeal(dealId: string): Promise<boolean> {
    let status: boolean = false;

    await this.navigatorService.getAsyncCRMDeal(dealId).then( (deal: CRMDeal) => {

      if(deal != undefined && deal != null) {

        for(let pipe of this.pipes) {

          let dealIndex: number = pipe.deals.findIndex( (element: CRMDeal) => { return element.id == deal.id });

          if(dealIndex > -1) {
            pipe.deals[dealIndex] = deal;
            this.recreatePipeDeals(pipe);
            break;
          }

        }

        status = true;
      }

    });

    return status;
  }

  private async getPipelinePipes(pipeline: CRMPipeline): Promise<Array<CRMPipe>> {

    let pipes: Array<CRMPipe> = [];

    for(let pipe of pipeline.pipes) {
      await this.navigatorService.getAsyncCRMPipe(pipe).then( (asyncPipe: CRMPipe) => {

        pipes.push(asyncPipe)

      });
    }

    return pipes;
  }

  private async recreatePipeDeals(pipe: CRMPipe): Promise<void> {
    let pipeIndex: number = this.pipes.findIndex( (element: CRMPipe) => { return element.id == pipe.id });

    for(let dealIndex: number = 0; dealIndex <  this.pipes[pipeIndex].deals.length; dealIndex++) {

      let deal: string = this.pipes[pipeIndex].deals[dealIndex].id;

      await this.navigatorService.getAsyncCRMDeal(deal).then( (asyncDeal: CRMDeal) => {

        this.pipes[pipeIndex].deals[dealIndex] = asyncDeal;
        this.pipes[pipeIndex].deals[dealIndex].plannedActivities = this.calculateDealQuickActionActivities(asyncDeal);
        this.generateTags(this.pipes[pipeIndex].deals[dealIndex]);
      });

    }

    this.initializePipelineVariables();
    this.filterPipeDeals(pipe);

    this.generateDealsAssignedTeams();
    await this.generateDealsAssignedImgs();
  }

  private getGroups(): void {

    this.navigatorService.getAsyncGroupPermissions().then( (teams: Array<GroupPermissions>) => {

      this.teams = teams;

    });

  }

  private calcPipelineValues(): void {
    this.totalPipelineValues = 0;
    this.weightedPipelineValues = 0;

    for(let pipe of this.pipes) {

      for(let deal of pipe.deals) {

        this.totalPipelineValues += deal.price;

        if(pipe.probability == undefined || pipe.probability == null) {
          pipe.probability = 0;
        }

        this.weightedPipelineValues += deal.price * (pipe.probability / 100);

      }

    }

  }

  private async filterPipeDeals(pipe: CRMPipe): Promise<void> {

    for(let deal of pipe.deals) {

      let staffIndex: number = deal?.assignedStaff?.findIndex( (id: string) => {
        let found: boolean = false;

        if(this.filterProfiles.length > 0) {
          for(let filterId of this.filterProfiles) {

            if(id == filterId.id) {
              found = true;
              break;
            }

          }
        } else {
          // The filter was cleared and so all deals must be visible
          found = true;
        }

        return found
      });

      let teamIndex: number = deal?.assignedTeams?.findIndex( (teamId: string) => {
        let found: boolean = false;


        if(this.filterProfiles.length > 0) {

          let team: GroupPermissions = this.teams.find( (el: GroupPermissions) => { return el.id == teamId });

          if(team != undefined && team != null) {

            for(let filterId of this.filterProfiles) {

              let teamEmpIndex: number = team.employeeIds.findIndex( (id: string) => { return id == filterId.id });

              if(teamEmpIndex > -1) {
                found = true;
                break;
              }

            } 

            return found;

          } else {
            return found;
          }

        } else {
          // The filter was cleared and so all deals must be visible
          found = true;
        }

        return found
      });

      // Filter is cleared or Filter has targeted correct staff deals
      if(staffIndex > -1 || teamIndex > -1 || this.filterProfiles.length == 0) {
        deal.visible = true;
      } else {
        deal.visible = false;
      }

    }

    let filteredPipeIndex: number = this.filteredPipeDeals.findIndex( (filterPipe: CRMPipe) => { return filterPipe.id == pipe.id});

    if(filteredPipeIndex > -1) {

      this.filteredPipeDeals[filteredPipeIndex] = this.syncFilteredPipeDeals(pipe);

    } else {
      this.filteredPipeDeals.push(this.syncFilteredPipeDeals(pipe));
    }

    this.filteredPipeDeals = [...this.filteredPipeDeals];
    pipe.deals = [...pipe.deals];
    this.initializePipelineVariables();
  }

  private async generatePipelineDeals(): Promise<void> {
    this.filteredPipeDeals = [];
    this.swimLaneHeight = 250;

    for(let pipeIndex: number = 0; pipeIndex < this.pipes.length; pipeIndex++) {

      for(let dealIndex: number = 0; dealIndex <  this.pipes[pipeIndex].deals.length; dealIndex++) {

        let deal: string = this.pipes[pipeIndex].deals[dealIndex];

        await this.navigatorService.getAsyncCRMDeal(deal).then( (asyncDeal: CRMDeal) => {

          asyncDeal.visible = false;
          this.pipes[pipeIndex].deals[dealIndex] = asyncDeal;

          if(this.pipes[pipeIndex].deals[dealIndex] != undefined && this.pipes[pipeIndex].deals[dealIndex] != null) {
            this.pipes[pipeIndex].deals[dealIndex].plannedActivities = this.calculateDealQuickActionActivities(asyncDeal);
            this.generateTags(this.pipes[pipeIndex].deals[dealIndex]);
          }

        });

      }

      this.filterPipeDeals(this.pipes[pipeIndex]);

      this.calculatePipeLength(this.pipes[pipeIndex]);
    }

    this.filteredPipeDeals = [...this.filteredPipeDeals];
    
    console.log("Pipe Deals Generated: ", this.pipes);
    this.initializePipelineVariables();

    this.generateDealsAssignedTeams();
    await this.generateDealsAssignedImgs();
    this.calcPipelineValues();

    let self = this;
    setTimeout(function() {
      self.calculateMaxPipeLength();
    }, 100);
  }

  private async generatePipeDeals(pipe: CRMPipe): Promise<void> {
    this.filteredPipeDeals = [];
    this.swimLaneHeight = 250;

    for(let dealIndex: number = 0; dealIndex <  pipe.deals.length; dealIndex++) {

      let deal: any = pipe.deals[dealIndex];
      let dealId: string = "";

      if(deal.id != undefined && deal.id != null) {
        dealId = deal.id;
      } else {
        dealId = deal;
      }

      await this.navigatorService.getAsyncCRMDeal(dealId).then( (asyncDeal: CRMDeal) => {

        pipe.deals[dealIndex] = asyncDeal;

        if(pipe.deals[dealIndex] != null && pipe.deals[dealIndex] != undefined) {
          pipe.deals[dealIndex].plannedActivities = this.calculateDealQuickActionActivities(asyncDeal);
          this.generateTags(pipe.deals[dealIndex]);
        }

      });

    }

    this.filterPipeDeals(pipe);
    this.calcPipelineValues();
    this.calculatePipeLength(pipe);


    this.filteredPipeDeals = [...this.filteredPipeDeals];
    console.log("Pipe Deals Generated: ", this.pipes);
    this.initializePipelineVariables();

    this.generateDealsAssignedTeams();
    await this.generateDealsAssignedImgs();
  }

  private calculatePipeLength(pipe: CRMPipe): void {

    let pipeIndex: number = this.pipes.findIndex( (crmPipe: CRMPipe) => { return crmPipe.id == pipe.id });
    let swimLanes: NodeListOf<Element> = document.querySelectorAll('.swimLane');
    let pipeLane: Element = swimLanes[pipeIndex];

    if(pipeLane != undefined && pipeLane != null) {
      
      if(pipeLane.clientHeight > this.swimLaneHeight) {
        this.swimLaneHeight = pipeLane.clientHeight + 500;
      }

    }

  }

  private calculateMaxPipeLength(): void {

    let swimLanes: NodeListOf<Element> = document.querySelectorAll('.swimLane');

    swimLanes.forEach( (pipeLane: Element) => {

      if(pipeLane != undefined && pipeLane != null) {
        
        if(pipeLane.clientHeight > this.swimLaneHeight) {
          this.swimLaneHeight = pipeLane.clientHeight + 500;
        }

      }

    });

  }

  private syncFilteredPipeDeals(pipe: CRMPipe): CRMPipe {
    let filterPipe: CRMPipe = { ...pipe };
    filterPipe.deals = [];

    for(let deal of pipe.deals) {

      if(deal?.visible) {
        filterPipe.deals.push(deal);
      }

    }

    return filterPipe;
  }

  private generatePlannedActivities(): void {



  }

  public dealLostReasonFocusChange(reasonId: string): void {
    let reason: CRMDealLostReason = this.transferDealLostReasons.find( (element: CRMDealLostReason) => { return element.id == reasonId ;});
    this.transferDealLostReason = reason;
  }

  public dealTransferPipelineFocusChange(pipelineId: string): void {

    let pipeline: CRMPipeline = this.pipelines.find( (element: CRMPipeline) => { return element.id == pipelineId ;});

    if(pipeline != undefined && pipeline != null) {
      this.transferDealPipeline = pipeline;

      this.getPipelinePipes(pipeline).then( (pipes: Array<CRMPipe>) => {
        this.transferDealPipes = pipes;
      });
    } else {
      this.snackBar.open('There seems to be a broken pipeline, try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public dealTransferPipeFocusChange(pipeId: string): void {
    let pipe: CRMPipe = this.transferDealPipes.find( (element: CRMPipe) => { return element.id == pipeId ;});
    this.transferDealPipe = pipe;
  }


  private updatePipe(pipe: CRMPipe): void {
    let deals: Array<CRMDeal | any> = pipe.deals;
    let brokenPipe: boolean = false;
    pipe.deals = [];

    for(let deal of deals) {
      if(deal?.id == undefined || deal?.id == null) {
        brokenPipe = true;
        this.reconstructBrokenPipe(pipe);
        break;
      }

      pipe.deals.push(deal.id);
    }

    if(!brokenPipe) {
      console.log("Pipe Update: ", pipe);
      this.navigatorService.upsertCRMPipe(pipe).then( (status: boolean) => {

        pipe.deals = [];
        pipe.deals = deals;

        this.generatePipeDeals(pipe);
      });
    }

  }

  private async reconstructBrokenPipe(pipe: CRMPipe): Promise<void> {

    this.snackBar.open('Sorry, we\ve encountered a broken pipe are attempting to fix it. Please excuse any disruption!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });

    this.navigatorService.getAsyncCRMDeals(pipe.id).then( (deals: Array<CRMDeal>) => {

      pipe.deals = deals;
      this.navigatorService.upsertCRMPipe(pipe).then( (status: boolean) => {

      });

    });

  }

  private updateDeal(deal: CRMDeal): void {
    deal.last_update = new Date();

    this.navigatorService.upsertCRMDeal(deal).then( (status: boolean) => {

    });

  }

  public checkActivityActionPanelPostion(ref: HTMLElement | any): boolean {
    let isRightOverflow: boolean = false;
    let windowWidth: number = window.innerWidth;
    let padding: number = 80;
    let leftStart: number = (ref.offsetParent.offsetParent.offsetParent.offsetLeft + ref.offsetParent.offsetParent.offsetParent.offsetWidth) + padding;
    let panelWidth: number = ref.clientWidth;

    if(windowWidth <= (leftStart + panelWidth)) {
      isRightOverflow = true;
    }

    return isRightOverflow;
  }

  public checkTeamMessagingPanelPostion(ref: HTMLElement | any): boolean {
    let isRightOverflow: boolean = false;
    let windowWidth: number = window.innerWidth;
    let padding: number = 80;
    let leftStart: number = (ref.offsetParent.offsetParent.offsetParent.offsetLeft + ref.offsetParent.offsetParent.offsetParent.offsetWidth) + padding;
    let panelWidth: number = ref.clientWidth;

    if(windowWidth <= (leftStart + panelWidth)) {
      isRightOverflow = true;
    }

    return isRightOverflow;
  }

  public calculateDealQuickActionActivities(deal: CRMDeal): Array<CRMActivity> {
    console.log("Deal: ", deal);

    this.plannedActivities = [];
    deal.pastDueActivities = false;

    if(deal != null) {

      for(let activity of deal.activities) {

        if(activity.type == this.activityLogCategories.ACTIVITY) {
          let currentDate: Date = new Date();

          if(activity.scheduleDate.getTime() > currentDate.getTime()) {
            this.plannedActivities.push(activity);
          } else {
            
            if(!activity.completed) {
              activity.pastDue = true;
              deal.pastDueActivities = true;
              this.plannedActivities.push(activity);
            }

          }

        }

      }

    }

    return this.plannedActivities;

  }

  public activityActionPanelFocusChange(deal: CRMDeal): void {

    if(deal != null) {
      if(deal.id != this.activityActionPanelFocus) {
        this.calculateDealQuickActionActivities(deal);
      }

      this.activityActionPanelFocus = deal.id;
    } else {
      this.activityActionPanelFocus = "";
    }
  }

  private initializePipelineVariables(): void {
    this.pipeDetails = [];
    this.pipeIds = ["delete", "lost", "won", "transfer"];

    for(let pipe of this.pipes) {
      this.pipeDetails.push({
        id: pipe.id,
        value: this.pipeValue(pipe.deals),
        numDeals: this.filteredDealsLength(pipe.deals)
      });

      this.pipeIds.push(pipe.id);
    }
  }

  public isDealRotting(pipe: CRMPipe, deal: CRMDeal): boolean {
    let isRotting: boolean = false;

    if(deal != undefined && deal != null) {

      if(deal.last_update != undefined && deal.last_update != null) {
        let rottingDate: Date = new Date(deal.last_update);
        let currentDate: Date = new Date();
        let rottingMs: number = 0;
        let ms: number = 86400000;

        rottingDate.setDate(rottingDate.getDate() + pipe.rotting);
        rottingMs = rottingDate.getTime() - currentDate.getTime();

        if(rottingMs < 0) {
          isRotting = true;
          deal.daysRotting = Math.abs(Math.floor(rottingMs / ms));

          if(deal.daysRotting == 0) {
            deal.daysRotting = 1;
          }

        } else {
          deal.daysRotting = null;
        }

      }

    }

    return isRotting;
  }

  public pipeValue(deals: Array<CRMDeal>): number {
    let pipeValue: number = 0;

    for(let deal of deals) {

      if(deal != undefined && deal != null && deal.price != undefined && deal.price != null) {

        if(deal?.visible || this.filterProfiles.length == 0) {
          pipeValue += deal.price;
        }

      }
    }

    return pipeValue;
  }

  public filteredDealsLength(deals: Array<CRMDeal>): number {
    let pipeDealLength: number = 0;

    for(let deal of deals) {

      if(deal != undefined && deal != null && deal.price != undefined && deal.price != null) {

        if(deal?.visible || this.filterProfiles.length == 0) {
          pipeDealLength++;
        }

      }
    }

    return pipeDealLength;
  }

  public changePipeline(id: string): void {
    let pipelineIndex: number = this.pipelines.findIndex( (pipeline: CRMPipeline) => { return pipeline.id == id; });

    if(pipelineIndex > -1) {
      this.focusedPipeline = pipelineIndex;
      this.generatePipeline();
    }

  }

  private addDeal(deal: CRMDeal): void {

    let pipeIndex: number = this.pipes.findIndex( (pipe: CRMPipe) => { return pipe.id == deal.pipe; });

    if(pipeIndex > -1) {
      this.pipes[pipeIndex].deals.push(deal);
      this.updatePipe(this.pipes[pipeIndex]);

      this.updateDeal(deal);
    }

  }

  public updateDealTransferComment(comments: string): void {
    this.dealTransferComment = comments;
  }

  public cancelDealTransfer(): void {
    this.transferDeal = null;
    this.dealTransferComment = "";
    this.transferDealDropEvent = null;
  }

  public completeDealTransfer(): void {

    let archivedDeal: CRMDealArchive = {
      id: this.navigatorService.generateKey(),
      type: this.transferDealType,
      data: undefined,
      deal: this.transferDeal?.id
    }

    switch(this.transferDealType) {

      case this.transferPanelTypes.DELETE:
        break;

      case this.transferPanelTypes.WON:
        archivedDeal.data = {
          pipeline: this.transferDealPipeline.id,
          pipe: this.transferDealPipe.id
        }

        this.transferDeal.lost = null;
        this.transferDeal.won = new Date();
        this.updateDeal(this.transferDeal);
        break;

      case this.transferPanelTypes.LOST:
        archivedDeal.data = {
          reason: this.transferDealLostReason.id,
          comments: this.dealTransferComment
        }

        this.transferDeal.won = null;
        this.transferDeal.lost = new Date();
        this.updateDeal(this.transferDeal);
        break;

      case this.transferPanelTypes.TRANSFER:
        archivedDeal.data = {
          pipeline: this.transferDealPipeline.id,
          pipe: this.transferDealPipe.id
        }
        break;

    }

    console.log("archived Deal: ", archivedDeal);

    if(
      this.transferDealType == this.transferPanelTypes.TRANSFER || 
      this.transferDealType == this.transferPanelTypes.WON) {


      this.transferDealPipe.deals.push(archivedDeal.deal);
      this.navigatorService.upsertCRMPipe(this.transferDealPipe).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('The Project Transfered Successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.drop(this.transferDealDropEvent);
        } else {
          this.snackBar.open('There Was A Problem With The Transfer, Please Try Again!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

        this.transferDeal = null;
        this.transferDealPipe = null;
        this.dealTransferComment = "";
        this.transferDealDropEvent = null;

      });
      

    } else {
    
      this.navigatorService.upsertCRMArchivedDeal(archivedDeal).then( (status: boolean) => {

        if(status) {

        } else {
          this.snackBar.open('There Was A Problem With The Transfer, Please Try Again!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

        this.drop(this.transferDealDropEvent);
        this.transferDeal = null;
        this.transferDealPipe = null;
        this.dealTransferComment = "";
        this.transferDealDropEvent = null;
      });

    }

  }

  public updatePipelineChanges(): void {
    this.getPipelines();
  }

  public openDeal(deal: CRMDeal): void {
    this.focusedDeal = deal;
    this.isDealPanelOpen = true;
  }

  public testOpenDeal(deal: CRMDeal): void {
    console.log("Deal Open: ", deal);
  }

  public closeDeal(): void {
    this.isDealPanelOpen = false;

    this.updatePipeDeal(this.focusedDeal.id);
  }

  public openPipelineEditMode(): void {

    if(this.pipelines.length > 0) {
      this.editPipelines = true;
    }

  }

  public closePipelineEditMode(changes: boolean): void {

    if(changes) {
      this.updatePipelineChanges();
    }

    this.editPipelines = false;
  }

  pipelineProductionMode(): void {
    this.editPipelines = false;
  }

  expandPipelineDropdown(): void {
    this.isPipelineDropDownExpanded = true;
  }

  collapsePipelineDropdown(): void {
    this.isPipelineDropDownExpanded = false;
  }

  expandNewPipelineMenu(): void {
    this.isNewPipelineMenuExpanded = true;
  }

  collapseNewPipelineMenu(): void {
    this.isNewPipelineMenuExpanded = false;
  }

  expandMessageChat(dealId: string): void {
    this.messagePanelDealFocus = dealId;
    this.messagingPanelExpanded = true;
  }

  collapseMessageChat(event: MouseEvent): void {

    let target: any = event.relatedTarget;

    if(target.className != 'mat-option-text') {
      this.messagePanelDealFocus = null;
      this.messagingPanelExpanded = false;
    }

  }

  expandTeamMenu(): void {
    this.teamMenuExpanded = true;
  }

  collapseTeamMenu(event: MouseEvent): void {

    let target: any = event.relatedTarget;

    if(target.className != 'mat-option-text') {
      this.teamMenuExpanded = false;
    }

  }

  expandTagsPanel(): void {
    this.tagPanelExpanded = true;
  }

  collapseTagsPanel(): void {
    this.tagPanelExpanded = false;
  }

  changeViewMode(mode: number): void {
    this.activeViewMode = mode;
  }

  public addTag(deal: CRMDeal, tag: AutomationTag) {

    deal.builtTags.push(tag);
    deal.tags.push(tag.id);

    this.updateDeal(deal);

  }

  public removeTag(deal: CRMDeal, tag: AutomationTag) {

    let builtTagIndex: number = deal.builtTags.findIndex( (builtTag: AutomationTag) => { return builtTag.id == tag.id });
    let savedTagIndex: number = deal.tags.findIndex( (savedTag: string) => { return savedTag == tag.id });

    if(builtTagIndex > -1) {
      deal.builtTags.splice(builtTagIndex, 1);
    }

    if(savedTagIndex > -1) {
      deal.tags.splice(savedTagIndex, 1);
    }

    this.updateDeal(deal);

  }

  createNewPipeline(title: string) {
    if(title.length == 0) {
      this.snackBar.open('The Pipe\'s Title Must Hold One Or More Characters!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    let pipeline: CRMPipeline = {
      id: this.navigatorService.generateKey(this.keyTypes.CRM_PIPELINE),
      title: title,
      pipes: []
    };

    this.navigatorService.upsertCRMPipeline(pipeline).then( (status: boolean) => {

      if(status) {
        this.pipelines.push(pipeline);
        this.collapseNewPipelineMenu();

        this.snackBar.open('New Pipe Laid!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open('There Was A Problem Laying The New Pipe!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  cdkDragStarted(): void {
    console.log("Drag Started");
    this.expandTransferPanel = true;
    this.draggingDeal = true;
  }

  cdkDragStopped(): void {
    console.log("Drag Stopped");
    this.expandTransferPanel = false;
    this.draggingDeal = false;
  }

  drop(event: CdkDragDrop<string[]>, transferPanel?: number) {

    if(transferPanel != undefined && transferPanel != null) {
      
      let deal: CRMDeal = event.item.data;
      console.log("Deal: ", deal);
      console.log("Panel: ", transferPanel);

      this.transferDeal = deal;
      this.transferDealType = transferPanel;
      this.transferDealDropEvent = event;
    }

    else if(event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      let currentPipe: CRMPipe = this.pipes.find( (pipe: CRMPipe) => { return pipe.id == event.container.id });

      if(currentPipe != undefined && currentPipe != null) {
        this.updatePipe(currentPipe);
      }
    } 
    
    else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      let currentPipe: CRMPipe = this.pipes.find( (pipe: CRMPipe) => { return pipe.id == event.container.id });
      let previousPipe: CRMPipe = this.pipes.find( (pipe: CRMPipe) => { return pipe.id == event.previousContainer.id });

      let deal: CRMDeal = currentPipe?.deals[event.currentIndex];

      if(deal != undefined && deal != null && deal.last_update != undefined) {

        deal.pipe = currentPipe.id;
        deal.probability = currentPipe.probability;
        deal.last_update = new Date();

        if(deal.pipeAuditTrail == undefined || deal.pipeAuditTrail == null) {
          deal.pipeAuditTrail = [];
        }

        let currentAuditIndex: number = deal.pipeAuditTrail.findIndex( (audit: CRMDealPipeAuditLog) => { return audit.pipe == currentPipe?.id; });
        let previousAuditIndex: number = deal.pipeAuditTrail.findIndex( (audit: CRMDealPipeAuditLog) => { return audit.pipe == previousPipe?.id; });

        if(currentAuditIndex > -1) {
          deal.pipeAuditTrail[currentAuditIndex].audit.push({
            in: new Date(),
            out: null
          });
        } else {
          deal.pipeAuditTrail.push({
            pipe: currentPipe.id,
            audit: [{
              in: new Date(),
              out: null
            }]
          })
        }

        if(previousAuditIndex > -1) {
          let auditIndex: number = deal.pipeAuditTrail[previousAuditIndex].audit.length - 1;
          deal.pipeAuditTrail[previousAuditIndex].audit[auditIndex].out = new Date();
        }

        this.updateDeal(deal);
      }

      if(currentPipe != undefined && currentPipe != null) {
        this.updatePipe(currentPipe);
      }

      if(previousPipe != undefined && previousPipe != null) {
        this.updatePipe(previousPipe);
      }

    }

    this.cdkDragStopped();
  }


  public openAddDealDialog(pipeId?: string) {

    if(this.pipelines.length > 0) {

      if(this.pipes.length > 0) {

        if(pipeId == undefined || pipeId == null) {
          pipeId = this.pipes[0].id;
        }

        this.openVendorAddDealDialogRef = this.dialog.open(
          VendorCRMAddDealDialogComponent,
          {
            data: {
              pipeline: this.pipeline.id,
              pipes: this.pipes,
              pipe: pipeId
            }
          }
        );

        this.openVendorAddDealDialogRef.afterClosed().subscribe( (deal: CRMDeal) => {
          console.log("Dialog Closed", deal);

          if(deal != undefined && deal != null && typeof deal != "string") {
            deal.last_update = new Date();

            let currentAuditIndex: number = deal.pipeAuditTrail.findIndex( (audit: CRMDealPipeAuditLog) => { return audit.pipe == deal.pipe; });

            if(currentAuditIndex > -1) {
              let auditIndex: number = deal.pipeAuditTrail[currentAuditIndex].audit.length - 1;
              deal.pipeAuditTrail[currentAuditIndex].audit[auditIndex].in = new Date();
            } else {
              deal.pipeAuditTrail.push({
                pipe: deal.pipe,
                audit: [{
                  in: new Date(),
                  out: null
                }]
              })
            }

            this.addDeal(deal);
          }

        })

      } else {
        this.snackBar.open('No Pipes Created, Please Create A Pipe(s) To Begin Adding Deals!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    } else {
      this.snackBar.open('No Pipelines Created, Please Create A Pipeline And Pipes to Begin Adding Deals!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public openCreatePipelineDialog() {

    this.openVendorCreatePipelineDialogRef = this.dialog.open(
      VendorCreatePipelineDialogComponent
    );

    this.openVendorCreatePipelineDialogRef.afterClosed().subscribe(data => {
      console.log("Dialog Closed", data);
    })

  }

  public openWorkOrderAuditDialog(deal: CRMDeal) {
    this.activityDialogRef = this.dialog.open(
      VendorCRMActivityDialogComponent,
      { 
        data: {
          activity: null,
          deal: deal 
        }
      }
    );

    this.activityDialogRef.beforeClosed().subscribe(data => {

    })
    
    this.activityDialogRef.afterClosed().subscribe(activity => {
      console.log("Dialog Closed: ", activity);

      if(activity != undefined && activity != null) {
        deal.last_update = new Date();
        deal.activities.unshift(activity); 

        this.updateDeal(deal);
      }

    });

  }

  public addActivity(deal: CRMDeal): void {
    console.log("Deal: ", deal);
  }

  public employeeFilter(profiles: Array<Profile>): void {

    this.filterProfiles = profiles;
  
    for(let pipe of this.pipes) {
      this.filterPipeDeals(pipe);
    }

    this.calcPipelineValues();

  }

  public filterDealUpdate(filter: DynamicSearchMenu): void {
    console.log("Deal Filter: ", filter);
  }

}