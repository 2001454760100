import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDataFilter } from 'src/app/data-models/models';

export interface DynamicSearchMenu {
  advancedSearch: boolean,
  searchBar: DynamicSearchBar,
  dateFilter?: DynamicDateFilter,
  radioBtns?: DynamicRadioBtns,
  slideToggles?: Array<DynamicToggle>,
  buttonToggles?: Array<DynamicToggle>
}

export interface DynamicSearchBar {
  label: string,
  placeholder: string,
  prefix: string,
  value: string
}

export interface DynamicRadioBtns{
  options: Array<string>,
  value: string
}

export interface DynamicToggle {
  title: string,
  value: boolean
}

export interface DynamicDateFilter {
  startDate: Date,
  endDate: Date
}

@Component({
  selector: 'app-vendor-search',
  templateUrl: './vendor-search.component.html',
  styleUrls: ['./vendor-search.component.scss']
})
export class VendorSearchComponent implements OnInit {

  @Input('menu') menu: DynamicSearchMenu = {

    advancedSearch: false,
    searchBar: {
      label: "Search...",
      placeholder: "Search...",
      prefix: "",
      value: ""
    }

  };

  @Output("filterUpdate") filterUpdate: EventEmitter<DynamicSearchMenu> = new EventEmitter<DynamicSearchMenu>();

  public advancedSearchPanelExpanded:boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  public expandAdvancedSearch(): void {
    
    this.advancedSearchPanelExpanded = true;

  }

  public collapseAdvancedSearch(): void {

    this.advancedSearchPanelExpanded = true;

  }

  public updateFilter(): void {
    console.log("Update Filter");
    this.filterUpdate.emit(this.menu);
  }

}