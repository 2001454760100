import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workOrderIdMask'
})

export class WorkOrderIdMaskPipe implements PipeTransform {
  transform(value: string) {

    return value.substring(0, 6);
  }

  

}