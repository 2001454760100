import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

import { HeartBeatService } from './heartbeat.service';
import { NavigatorService, keyTypesEnum, automationActionCategoryEnum, automationCategoriesEnum, automationCMMSActionEnum, automationGeneralActionEnum, automationInvoiceActionEnum, automationWorkOrderActionEnum, workOrderStatusTypes, notificationCategoriesEnum, notificationStatusEnum } from './navigator.service';
import { ApplicationNotificationMessage, ApplicationNotifications, Automation, AutomationTriggerEvent, CMMSLocation, NotificationCategory, UserDataSync } from 'src/app/data-models/models';
import { ifStmt, not } from '@angular/compiler/src/output/output_ast';
import { AutomationsService } from './automations.service';


@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    private updateTimestamp: Date = null;
    private generationKeyTypes: any = keyTypesEnum;
    private notificationCategories: any = notificationCategoriesEnum;
    private notificationStatus: any = notificationStatusEnum;

    

    private structuredNotifications: any = { messages: [], messageCount: 0 };
    private notificatonPageCategories: Array<NotificationCategory> = this.navigatorService.getNotificationPageCategories();
    private highestNotificationStatus: number = 0;
    private notifications: ApplicationNotifications = {
        id: this.navigatorService.getProfileId(),
        companyId: this.navigatorService.getCompanyId(),
        lastUpdated: new Date(),
        messages: [
        
        ]
    };



    // Automation Variables
    private automationCategories: any = automationCategoriesEnum;
    private automationActionTypes: any = automationActionCategoryEnum;

    private automationGeneralActions: any = automationGeneralActionEnum;
    private automationWorkOrderActions: any = automationWorkOrderActionEnum;
    private automationInvoiceActions: any = automationInvoiceActionEnum;
    private automationCMMSActions: any = automationCMMSActionEnum;

    private workOrderStatusTypes: any = workOrderStatusTypes;

    constructor(
        public http: HttpClient, 
        public realm: MongoService, 
        public router: Router, 
        private navigatorService: NavigatorService, 
        private heartbeat: HeartBeatService, 
        private automationService: AutomationsService,
        private sanitizer: DomSanitizer) { 
        
    }

    // ==============================================
    //              Notification Calls
    // ==============================================

    private liveUpdate(): void {
        this.heartbeat.getUserDataSyncTrigger().subscribe( (dataSync: UserDataSync) => {

            console.log("Live Notification Update");

            if(this.notifications.lastUpdated == undefined || this.notifications == null) {
                this.notifications.lastUpdated = new Date();
                this.updateNotifications();
            }

            if(dataSync != undefined && dataSync != null) {

                if(dataSync.categories.notification.getTime() < this.notifications.lastUpdated.getTime()) {
                    this.getLiveNotifications().then( () => {
                        dataSync.categories.notification = new Date();
                        // this.heartbeat.updateUserDataSync(dataSync);
                    });
                }

            }

        });
    }
    
    private async getLiveNotifications(): Promise<void> {
        await this.navigatorService.getAsyncNotifications(this.navigatorService.getProfileId()).then( (notifications: ApplicationNotifications) => {

            if(notifications != undefined && notifications != null) {
                this.notifications = notifications;

                this.notifications.messages.sort(this.notificationCategoryCompare);
                this.setStructureNotifications();
            
                this.setHighestNotificationStatus();

                this.updateTimestamp = new Date();
            }           

        });
    }

    public getNotifications(): ApplicationNotifications {
        return this.notifications;
    }

    private setHighestNotificationStatus(): void {
        this.highestNotificationStatus = 0;
        
        if(this.notifications != null && this.notifications != undefined) {
            
            for(let notification of this.notifications.messages) {
                if(notification.status > this.highestNotificationStatus) {
                    this.highestNotificationStatus = notification.status;
                }
            }

        }
    }

    private setStructureNotifications(): void {
        this.structuredNotifications = {
            messages: [],
            messageCount: this.notifications.messages.length
        };
    
        if(this.notifications != null && this.notifications != undefined) {
            let currentCategory: number = null;
            let currentCategoryIndex: number = 0;
        
            for(let notification of this.notifications.messages) {
                if(notification.category != currentCategory) {
        
                currentCategory = notification.category;
                currentCategoryIndex++;
                
        
                this.structuredNotifications.messages.push({
                    header: this.getNotificationCategoryHeader(notification.category),
                    notifications: [ notification ]
                });
        
                } else {
                    this.structuredNotifications.messages[currentCategoryIndex - 1].notifications.push(notification);
                }
            }
        
            console.log("Structured Notifications: ", this.structuredNotifications);
        
            this.structuredNotifications;
        }
    }
    
    private notificationCategoryCompare(el1: ApplicationNotificationMessage, el2: ApplicationNotificationMessage): number {
        if ( el1.category < el2.category ){
            return -1;
        }
        if ( el1.category > el2.category ){
            return 1;
        }
            return 0;
    }

    public initializeNotifications(): void {
        this.notifications.messages = [];

        this.getLiveNotifications().then( () => {
            this.liveUpdate();
            this.automationListener();

            this.updateNotifications();
        });
        
    
        // for(let testIndex: number = 0; testIndex < 3; testIndex++) {
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!",
        //         status: 0,
        //         category: 6
        //     });
    
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!",
        //         status: 0,
        //         category: 2
        //     });
    
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!",
        //         status: 1,
        //         category: 0
        //     });
    
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!  Admin Test Message!  Admin Test Message!  Admin Test Message! Admin Test Message!",
        //         status: 2,
        //         category: 2
        //     });
    
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!",
        //         status: 1,
        //         category: 6
        //     });
    
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!",
        //         status: 0,
        //         category: 4
        //     });
    
        //     this.notifications.messages.push({
        //         id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        //         message: "Admin Test Message!",
        //         status: 0,
        //         category: 2
        //     });
        // }
    
        // for(let index: number = 0; index < count; index++) {
        //   this.notifications.messages.push({
        //       message: "Admin Test Message!",
        //       status: status,
        //       category: 6
        //   });
        // }
    
        
    }
    
    private updateNotifications(): void {

        this.navigatorService.updateNotifications(this.notifications).then( (status: boolean) => {

            if(status) {
                this.notifications.messages.sort(this.notificationCategoryCompare);
                this.setStructureNotifications();
            
                this.setHighestNotificationStatus();

                this.updateTimestamp = new Date();
                this.requestUpdateUserData();
            }

        });
    }
    
    public getNotificationCategoryHeader(category: number): string {
        let categoryIndex: number = this.notificatonPageCategories.findIndex( element => {
            return element.id == category;
        });
    
        return this.notificatonPageCategories[categoryIndex].title;
    }
    
    public clearAllNotifications(): any {
        this.notifications.messages = [];
        this.highestNotificationStatus = 0;
    
        this.updateNotifications();

        return this.structuredNotifications;
    }

    public getStructuredNotifications(): any {
        return this.structuredNotifications;
    }

    public getHighestNotificationStatus(): number {
        return this.highestNotificationStatus;
    }

    public async addForeignNotification(userId: string, notification: ApplicationNotificationMessage): Promise<boolean> {
        let status: boolean = false;

        await this.navigatorService.getAsyncNotifications(userId).then( async (notifications: ApplicationNotifications) => {

            if(notifications != undefined && notifications != null) {
                notifications.messages.push(notification);
            } else {
                notifications = {
                    id: userId,
                    companyId: this.navigatorService.getCompanyId(),
                    lastUpdated: new Date(),
                    messages: []
                }

                notifications.messages.push(notification);
            }

            await this.navigatorService.updateNotifications(notifications).then( (success: boolean) => {
                status = success;
            });

        });

        return status;
    }

    public addNewNotification(notification: ApplicationNotificationMessage): any {
        this.notifications.messages.push(notification);
        this.updateNotifications();

        this.updateTimestamp = new Date();

        return this.structuredNotifications;
    }

    public deleteNotification(id: string): any {
        let notificationIndex: number = this.notifications.messages.findIndex( (element: ApplicationNotificationMessage) => {
            return element.id == id;
        });

        this.notifications.messages.splice(notificationIndex, 1);
        this.updateNotifications();

        this.updateTimestamp = new Date();

        return this.structuredNotifications;
    }

    public latestTimestamp(): Date {
        return this.updateTimestamp;
    }

    public requestUpdateUserData(): void {
        let currentDate: Date = new Date();

        this.heartbeat.getUserAsyncDataSync().then( (userDataSync: UserDataSync) => {

            userDataSync.categories.notification = currentDate;

            this.heartbeat.updateUserDataSync(userDataSync);

        });

    }


// =================================
//          Automations
// =================================

    // Only use for subset of actions originating in Automations Service 
    private automationListener(): void {

        this.automationService.getGeneralTrigger().subscribe( (automationTriggerEvent: AutomationTriggerEvent) => {

            if(automationTriggerEvent.data == null) {
                return;
            }

            this.automationWorkOrderNotification(automationTriggerEvent);
            this.automationCMMSWorkOrderNotification(automationTriggerEvent);

        });

    }

    private automationWorkOrderNotification(automationTriggerEvent: AutomationTriggerEvent): void {
        let employees: Array<string> = [];
            let notification: ApplicationNotificationMessage = 
                {
                    id: this.navigatorService.generateKey(this.generationKeyTypes.NOTIFICATION_ID),
                    message: "",
                    status: this.notificationStatus.GOOD,
                    category: null
                }
            
        if(
            automationTriggerEvent.automation.automationType == this.automationActionTypes.NOTIFY && 
            automationTriggerEvent.automation.automationCategory == this.automationCategories.WORK_ORDER 
            ) 
        {

            notification.category = this.notificationCategories.WO_SCHEDULING;
            notification.status = this.notificationStatus.INFORMATIONAL;
            notification.message = automationTriggerEvent.automation.title;
            notification.data = automationTriggerEvent.data;

            switch(automationTriggerEvent.automation.automationAction) {
                
                case this.automationGeneralActions.NOTIFY_INDIVIDUAL:
                    employees = automationTriggerEvent.automation.employees;
                    break;

                case this.automationGeneralActions.NOTIFY_TEAM:
                    break;

            }

        }

        this.sendNotificationToEmployees(notification, employees);
    }

    private automationCMMSWorkOrderNotification(automationTriggerEvent: AutomationTriggerEvent): void {
        let employees: Array<string> = [];
        let notification: ApplicationNotificationMessage = 
            {
                id: this.navigatorService.generateKey(this.generationKeyTypes.NOTIFICATION_ID),
                message: "",
                status: this.notificationStatus.GOOD,
                category: null
            }
        

        if(
            automationTriggerEvent.automation.automationType == this.automationActionTypes.NOTIFY && 
            automationTriggerEvent.automation.automationCategory == this.automationCategories.CMMS 
            ) 
        {

            notification.category = this.notificationCategories.CMMS;
            notification.status = this.notificationStatus.INFORMATIONAL;
            notification.message = automationTriggerEvent.automation.title;
            notification.data = automationTriggerEvent.data;

            switch(automationTriggerEvent.automation.automationAction) {
                
                case this.automationGeneralActions.NOTIFY_INDIVIDUAL:
                    employees = automationTriggerEvent.automation.employees;
                    break;

                case this.automationGeneralActions.NOTIFY_TEAM:
                    break;

            }

        }

        this.sendNotificationToEmployees(notification, employees);
    }

    private sendNotificationToEmployees(notification: ApplicationNotificationMessage, employees: Array<string>): void {

        if(notification.message == "" || notification.category == null) {
            return;
        }

        for(let employee of employees) {
            this.addForeignNotification(employee, notification);
        }

    }
    
}