import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of, from } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import imageCompression from 'browser-image-compression';

import { NavigatorService, keyTypesEnum } from './navigator.service';

import { Device } from '@twilio/voice-sdk';

// AWS
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { el } from 'date-fns/locale';
import { StripePaymentDetails, PaymentSettings } from 'src/app/data-models/models';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { timeout } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum VerificationPanelCategoryEnum {
    PHONE = 0,
    EMAIL = 1
}

export enum VerificationStatusCodes {
    VERIFYING = 0,
    INCORRECT_CODE = 1,
    EXPIRED_CODE = 2,
    VERIFIED = 3,
    SERVER_ERROR = 4
}

export enum PhoneStatusEnum {
    READY = 0,
    INCOMING_CALL = 1,
    OUTGOING_CALL = 2,
    CONNECTING_CALL= 3,
    CONNECTED_CALL = 4,
    DISCONNECTED_CALL= 5,
    CONFERENCE_CALL = 6,
    VIDEO = 7,
    HOLD = 8,
    ERROR = 9,
    AUTHORIZING = 10
}

export enum CallLogStatusEnum {
    INCOMING_CONNECTED = 0,
    INCOMING_MISSED = 1,
    INCOMING_REJECTED = 2,
    OUTBOUND_CONNECTED = 3,
    OUTBOUND_MISSED = 4,
    OUTBOUND_REJECTED = 5
  }

@Injectable({
    providedIn: 'root'
})

export class TwilioHandlerService {
    private phoneStatusCategories: any = PhoneStatusEnum;
    private callLogStatusCategories: any = CallLogStatusEnum;
    private verificationPanelCategories: any = VerificationPanelCategoryEnum;
    private verificationStatusCodes: any = VerificationStatusCodes;
    
    private device: Device = null;

    private emailVerifiedSubject = new Subject<number>();
    private phoneVerifiedSubject = new Subject<number>();

    private rootURL: string = '/twilio/api';
    private emailRootURL: string = 'twilio-sendgrid/api';

    private incomingCallObjectTrigger = new Subject<any>();
    private outboundCallTrigger = new Subject<any>();

    private connectedCallTimerTrigger = new Subject<string>();
    private incomingCallTrigger = new Subject<boolean>();
    private callStatusTrigger = new Subject<number>();
    private phoneNumberTrigger = new Subject<string>();
    private callInitiatedTrigger = new Subject<boolean>();
    private muteMicTrigger = new Subject<boolean>();


    private incomingCall: boolean = false;
    private callStatus = this.phoneStatusCategories.AUTHORIZING;
    private phoneNumber: string = "";
    private timerStarted: boolean = false;
    private connectedCallTimerFormatted: string = "";
    private connectedCallTimer: number = 0;
    private callInitiated: boolean = false;
    private muteMic: boolean = false;

    private call = null;

    // Test Phone Outgoing
    private onPhone: boolean = false;
    private muted: boolean = false;
    private isValidNumber: boolean = false;
    private logtext: string = "";


    constructor(
        public http:HttpClient, 
        private navigatorService: NavigatorService,
        public snackBar: MatSnackBar, 
        public realm:MongoService, 
        public router:Router) { 

    }


    public getIncomingCallObjectTrigger(): Observable<any> {
        return this.incomingCallObjectTrigger.asObservable();
    }

    public getOutboundCallTrigger(): Observable<any> {
        return this.outboundCallTrigger.asObservable();
    }


    public getIncomingCallTrigger(): Observable<boolean> {
        return this.incomingCallTrigger.asObservable();
    }

    public getCallStatusTrigger(): Observable<number> {
        return this.callStatusTrigger.asObservable();
    }

    public getPhoneNumberTrigger(): Observable<string> {
        return this.phoneNumberTrigger.asObservable();
    }

    public getCallInitiatedTrigger(): Observable<boolean> {
        return this.callInitiatedTrigger.asObservable();
    }

    public getConnectedCallTimerTrigger(): Observable<string> {
        return this.connectedCallTimerTrigger.asObservable();
    }

    

    public getMuteMicTrigger(): Observable<boolean> {
        return this.muteMicTrigger.asObservable();
    }

    public getIncomingCall(): boolean {
        return this.incomingCall;
    }

    public getCallStatus(): number {
        return this.callStatus;
    }

    public getPhoneNumber(): string {
        return this.phoneNumber;
    }

    public getConnectedCallTimer(): string {
        return this.connectedCallTimerFormatted;
    }

    public setConnectedCallTimer(timer: number): void {

        if(timer == 0) {
            this.connectedCallTimer = 0;
            this.connectedCallTimerFormatted = "";
            this.connectedCallTimerTrigger.next(this.connectedCallTimerFormatted);
            return;
        }

        let hours: number = Math.floor( (timer / 60) / 60 );

        timer -= (hours * 60) * 60;

        let minutes: number = Math.floor( timer / 60 );

        timer -= minutes * 60;

        let seconds: number = timer;


        let hoursBlock: string = "";
        let minutesBlock: string = "";
        let secondsBlock: string = "";

        if(hours < 10) {
            hoursBlock = "0";
        }

        if(minutes < 10) {
            minutesBlock = "0";
        }

        if(seconds < 10) {
            secondsBlock = "0";
        }

        if(hours == 0) {
            

            this.connectedCallTimerFormatted = minutesBlock + minutes + ":" + secondsBlock + seconds;
        } else {
            this.connectedCallTimerFormatted = hoursBlock + hours + ":" + minutesBlock + minutes + ":" + secondsBlock + seconds;
        }

        this.connectedCallTimerTrigger.next(this.connectedCallTimerFormatted);
    }

    public setPhoneNumber(phoneNumber: string): void {
        phoneNumber = this.formatToSafePhoneFormat(phoneNumber);

        this.phoneNumber = phoneNumber;
        this.phoneNumberTrigger.next(this.phoneNumber);
    }

    public getCallInitiated(): boolean {
        return this.callInitiated;
    }

    public getMuteMic(): boolean {
        return this.muteMic;
    }



    public async makeAPIKey(): Promise<void> {

        this.http.post(this.rootURL + '/createAPIKey', { keyDetails: { sid: "" } } ).subscribe( (data: any) => {
            console.log("Twilio Server Return - Create API Key", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });
        
    }

    public async testGenerateVoiceAccessToken(): Promise<void> {
        // const twilioAccountSid = tokenDetails.sid; 
        // const twilioAuthToken = tokenDetails.authToken; 
        // const twilioApplicationKey = tokenDetails.applicationKey;

        const twilioAccountSid = "ACd05a80fc2f1748e1dcab342a184ce424"; 
        const twilioAuthToken = "2bcfe480301934bce1940ea9187270ea"; 
        const twilioApplicationKey = "AP380278fe16457501ae2819d7bbee7644";

        let tokenDetails: any = {
            tokenDetails: { 
                sid: twilioAccountSid, 
                authToken: twilioAuthToken, 
                applicationKey: twilioApplicationKey
            }
        };

        console.log("Twilio Server Started - Create Voice Access Token" );

        this.http.post(this.rootURL + '/testVoiceTokenGenerate', tokenDetails ).subscribe( (token: any) => {
            var self = this;
            console.log("Twilio Server Return - Create Voice Access Token", token );

            if(token != null && token != undefined) {


                this.device = new Device(token.token);
                this.device.register();

                this.device.on('registered', device => {
                    console.log("Twilio Device is Ready For Incoming Calls", device);
                    self.handleSuccessfulRegistration();
                });

            }

        });
        
    }

    private handleSuccessfulRegistration(): void {
        console.log("Twilio Incoming Call");

        this.callStatusTrigger.next(this.callStatus);

        this.device.on('incoming', call => {
            
            console.log("Twilio Incoming Call Data: ", call);

            this.call = call;
            this.incomingCall = true;

            let phoneNumber:string = this.call.parameters.From;
            phoneNumber = phoneNumber.slice(2);

            this.setPhoneNumber(phoneNumber);
            this.updateCallStatus();

            this.callStatusTrigger.next(this.callStatus);
            this.incomingCallObjectTrigger.next(call);
            this.incomingCallTrigger.next(true);
        });
    }

    private initiateTimer(): void {
        if(this.call == undefined || this.call == null) {
            console.log("Call Object Empty - Stop Timer");
            this.setConnectedCallTimer(0);
            return;
        }

        this.connectedCallTimer++;
        this.setConnectedCallTimer(this.connectedCallTimer);

        setTimeout(() => { this.initiateTimer() }, 1000);
    }

    private updateCallStatus(): void {

        if(this.call == undefined || this.call == null) {
            console.log("Call Object Empty");
            return;
        }

        // The connection is incoming and hasn't yet been established.
        if(this.call.status() == "pending") {
            this.callStatus = this.phoneStatusCategories.INCOMING_CALL;
            this.callStatusTrigger.next(this.callStatus);
        }

        // The connection has been accepted by or initiated by the local client.
        if(this.call.status() == "connecting") {
            this.callStatus = this.phoneStatusCategories.CONNECTING_CALL;
            this.callStatusTrigger.next(this.callStatus);
        }

        // The callee has been notified of the call but has not yet responded.
        if(this.call.status() == "ringing") {
            this.callStatus = this.phoneStatusCategories.OUTBOUND_CALL;
            this.callStatusTrigger.next(this.callStatus);
        }

        // The connection has been established.
        if(this.call.status() == "open") {
            this.callStatus = this.phoneStatusCategories.CONNECTED_CALL;
            this.callStatusTrigger.next(this.callStatus);

            if(!this.timerStarted) {
                this.timerStarted = true;
                this.initiateTimer();
            }
        }

        // The connection has been disconnected.
        if(this.call.status() == "closed") {
            this.callStatus = this.phoneStatusCategories.DISCONNECTED_CALL;
            this.callStatusTrigger.next(this.callStatus);
            this.callEndedCleanUp();

            if(this.timerStarted) {
                this.setConnectedCallTimer(0);
                this.timerStarted = false;
            }

            return;
        }
            
        console.log("Call Status: ", this.call.status());
        setTimeout(() => { this.updateCallStatus() }, 100);

    }

    public async makeOutboundCall(phoneNumber: string): Promise<void> {

        phoneNumber = this.formatToSafePhoneFormat(phoneNumber);
        let fromPhoneNumber: string = "2542694774";

        if(phoneNumber.length != 10) {
            console.log("Test: ", phoneNumber);
            this.snackBar.open('Call Connection Refusal: A 10 digit number is required to initiate a phone call.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
            return;
        }

        let call = await this.device.connect({ 
            params: {
              To: '+1' + phoneNumber,
              From: '+1' + fromPhoneNumber
            } 
        });

        let callLog = {
            id: this.navigatorService.generateKey(),
            accountSid: this.navigatorService.getCallCenterId(),
            from: fromPhoneNumber,
            to: phoneNumber,
            startTime: new Date,
            stopTime: new  Date,
            status: this.callLogStatusCategories.OUTBOUND_CONNECTED
        }

        this.navigatorService.insertCallLog(callLog).then( status => {
            // Nothing to see here
        });

        this.call = call;
        this.callInitiated = true;

        this.updateCallStatus();

        this.callInitiatedTrigger.next(this.callInitiated);
        this.outboundCallTrigger.next(call);
    }

    public async disconnectAllOutboundCall(): Promise<void> {
        this.device.disconnectAll();
    }


    private formatToSafePhoneFormat(phone: string): string {

        if(phone.lastIndexOf("(") > -1) {
            phone = phone.replace("(", "");
            phone = phone.replace(")", "");
            phone = phone.replace("-", "");
            phone = phone.replace(" ", "");
        } 

        return phone;
    }








    public async generateVoiceAPIKey(): Promise<void> {

    //     const twilioAccountSid = tokenDetails.sid; 
    //   const twilioApiKey = tokenDetails.key;
    //   const twilioApiSecret = tokenDetails.secret;
    //   const twilioAppID = tokenDetails.appID;
    //   const twilioIdentity = tokenDetails.identity;

        let accessDetails = { 
            accessDetails: { 
                sid: "ACd05a80fc2f1748e1dcab342a184ce424", 
                companyId: this.navigatorService.getCompanyId(),
            } 
        };

        this.http.post(this.rootURL + '/createAPIKey', accessDetails ).subscribe( (API_key: any) => {
            console.log("Twilio Server Return - Create Voice API Key", API_key );

            if(API_key != null && API_key != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });
        
    }

    public async generateVoiceAccessToken(): Promise<void> {

         //     const twilioAccountSid = tokenDetails.sid; 
        //   const twilioApiKey = tokenDetails.key;
        //   const twilioApiSecret = tokenDetails.secret;
        //   const twilioAppID = tokenDetails.appID;
        //   const twilioIdentity = tokenDetails.identity;

        const twilioAccountSid = "ACd05a80fc2f1748e1dcab342a184ce424"; 
        const twilioAPIKey = "SK0465e5f394df33d5cdf09c2f288ec2b5"; 
        const twilioSecret = "ueMu2XioF2LixTo9dkXENcjTGwFKlJSz";
        const twilioAppId = "AP380278fe16457501ae2819d7bbee7644";

        let tokenDetails = { 
            tokenDetails: { 
                sid: twilioAccountSid, 
                key: twilioAPIKey, 
                secret: twilioSecret,
                appID: twilioAppId,
                identity: this.navigatorService.getProfileId()
            } 
        };

        this.http.post(this.rootURL + '/generateVoiceAccessToken', tokenDetails ).subscribe( (token: any) => {
            console.log("Twilio Server Return - Create Voice Access Token", token );

            if(token != null && token != undefined) {
                let self = this;

                this.device = new Device(token);
                this.device.register();

                this.device.on('registered', device => {
                    console.log("Twilio Device is Ready For Incoming Calls", device);

                    this.callStatus = this.phoneStatusCategories.READY;
                    this.callStatusTrigger.next(this.callStatus);


                    self.handleSuccessfulRegistration();
                });
            }

        });
        
    }

    public async generateOutboundCallApplication(): Promise<void> {

        //     const twilioAccountSid = tokenDetails.sid; 
       //   const twilioApiKey = tokenDetails.key;
       //   const twilioApiSecret = tokenDetails.secret;
       //   const twilioAppID = tokenDetails.appID;
       //   const twilioIdentity = tokenDetails.identity;

       const twilioAccountSid = "ACd05a80fc2f1748e1dcab342a184ce424"; 

       if(twilioAccountSid == undefined || twilioAccountSid == null) {
        return;
       }

       let accessDetails = { 
            accessDetails: { 
               sid: twilioAccountSid
           } 
       };

       this.http.post(this.rootURL + '/createTwiMLApp_outboundCall', accessDetails ).subscribe( (applicationSid: any) => {
           console.log("Twilio Server Return - Create Voice Outbound Call Application", applicationSid );
       });
       
   }

   public async placeCallOnHold(callSid: string): Promise<void> {

    //     const twilioAccountSid = tokenDetails.sid; 
   //   const twilioApiKey = tokenDetails.key;
   //   const twilioApiSecret = tokenDetails.secret;
   //   const twilioAppID = tokenDetails.appID;
   //   const twilioIdentity = tokenDetails.identity;

   if(callSid == undefined || callSid == null) {
       callSid = this.call.parameters.CallSid;
   }

   const twilioAccountSid = "ACd05a80fc2f1748e1dcab342a184ce424"; 

   let accessDetails = { 
        accessDetails: { 
           sid: twilioAccountSid,
           callID: callSid
       } 
   };

   console.log("Twilio Access Details: ", accessDetails);

   this.http.post(this.rootURL + '/putCallOnHold', accessDetails ).subscribe( (hold: any) => {
       console.log("Twilio Server Return - Call Placed On Hold: ", hold );
   });
   
}

    public acceptIncomingCall(): void {
        if(this.call == undefined || this.call == null) {
            return;
        }

        this.call.accept();

        this.callStatusTrigger.next(this.callStatus);

        this.callInitiated = true;

        this.callInitiatedTrigger.next(this.callInitiated);
    }

    public rejectIncomingCall(): void {
        if(this.call == undefined || this.call == null) {
            return;
        }

        this.call.reject();
        this.callStatusTrigger.next(this.callStatus);

        this.callInitiated = false;
        this.incomingCall = false;
        this.muteMic = false;
        this.phoneNumber = "";

        this.callInitiatedTrigger.next(this.callInitiated);
        this.incomingCallObjectTrigger.next(null);
        this.incomingCallTrigger.next(this.incomingCall);
        this.muteMicTrigger.next(this.muteMic);
        this.phoneNumberTrigger.next(this.phoneNumber);
    }

    public disconnectCall(): void {

        if(this.call == undefined || this.call == null) {
            return;
        }

        this.call.disconnect();
    }

    public callEndedCleanUp(): void {
        this.callInitiated = false;
        this.phoneNumber = "";
        this.incomingCall = false;

        this.callStatusTrigger.next(this.callStatus);

        this.muteMic = false;

        this.call = null;
        this.callInitiatedTrigger.next(this.callInitiated);
        this.incomingCallTrigger.next(this.incomingCall);
        this.incomingCallObjectTrigger.next(null);
        this.muteMicTrigger.next(this.muteMic);
        this.phoneNumberTrigger.next(this.phoneNumber);

        this.callStatus = this.phoneStatusCategories.READY;
        this.callStatusTrigger.next(this.callStatus);
    }
    
    public muteMicToggle(): void {

        if(this.call == undefined || this.call == null) {
            return;
        }

        if(this.muteMic) {
            this.muteMic = false;
        } else {
            this.muteMic = true;
        }

        this.call.mute(this.muteMic);
        this.muteMicTrigger.next(this.muteMic);
    }





    public getVerificationPanelCategories(): any {
        return this.verificationPanelCategories;
    }

    public getVerificationStatusCodes(): any {
        return this.verificationStatusCodes;
    }

    public getPhoneVerifiedListener(): Observable<number> {
        return this.phoneVerifiedSubject.asObservable();
    }

    public getEmailVerifiedListener(): Observable<number> {
        return this.emailVerifiedSubject.asObservable();
    }


    public async answerCallTwiML(): Promise<void> {
        console.log("Twilio Start - Answer Call TwiML");
    
        // this.http.post(this.rootURL + '/TwiML/voice/answer', { keyDetails: { sid: "" } } ).subscribe( (data: any) => {
        //     console.log("Twilio Server Return - Create API Key", data );
    
        //     if(data != null && data != undefined) {
        //         // this.http.get(data.url);
        //         // window.open(data.url);
        //     }
    
        // });
    
        this.http.post(this.rootURL + '/inboundCall', {  } ).subscribe( (data: any) => {
          console.log("Twilio Server Return - Create API Key", data );
    
          if(data != null && data != undefined) {
              // this.http.get(data.url);
              // window.open(data.url);
          }
    
      });
        
      }


    public async sendTextMessage(message: string, phone?: string): Promise<void> {

        if(phone == null) {
            // phone = 4696904591;
            this.snackBar.open('Phone number is invalid', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
            return;
        } else {
            this.formatToSafePhoneFormat(phone);
        }

        this.http.post(this.rootURL + '/sendTextMessage', { messageDetails: { message: message, phone: phone } } ).subscribe( (data: any) => {
            console.log("Twilio Server Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });


        // Testing
        // this.sendEmail(message);
        // this.sendPhoneVerification("aa092k21n", 1234567890);
        // this.checkPhoneVerification("aa092k21n", 1234567890);
    }

    public async sendEmail(email: string): Promise<void> {

        this.http.post(this.emailRootURL + '/sendEmail', { messageDetails: { message: email, phone: 1234567890 } } ).subscribe( (data: any) => {
            console.log("Twilio Email Server Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });

    }

    public async sendWorkOrderAccessEmail(workOrderId: string, code: string, email: string): Promise<void> {

        let details: any = {
            emailDetails: {
                companyName: this.navigatorService.getCompanyName(), 
                companyId: this.navigatorService.getCompanyId(),
                workOrderId: workOrderId,
                code: code,
                email: email
            }
        }

        this.http.post(this.emailRootURL + '/sendWorkOrderExternalAccessEmail', details ).subscribe( (data: any) => {
            console.log("Twilio Document Access Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });

    }

    public async sendPlatformUserInviteEmail(email: string, requestId: string): Promise<boolean> {

        let status: boolean = false;

        let details: any = {
            emailDetails: {
                companyName: this.navigatorService.getCompanyName(), 
                companyId: this.navigatorService.getCompanyId(),
                requestId: requestId,
                email: email
            }
        }

        await this.http.post(this.emailRootURL + '/sendPlatformUserInviteEmail', details ).toPromise().then( (completed: any) => {
            console.log("Twilio User Invite Return", completed );

            status = completed;

        });

        return status;
    }

    public async sendPlatformWorkOrderStatusEmail(email: string, message: string, statusTitle: string, url: string): Promise<boolean> {

        let status: boolean = false;

        let details: any = {
            emailDetails: {
                companyName: this.navigatorService.getCompanyName(), 
                companyId: this.navigatorService.getCompanyId(),
                email: email,
                status: statusTitle,
                message: message,
                url: url
            }
        }

        await this.http.post(this.emailRootURL + '/sendPlatformWorkOrderStatusEmail', details ).toPromise().then( (completed: any) => {
            console.log("SendGrid Work Order Status Email: ", completed );

            status = completed;

        });

        return status;
    }

    public async sendDocumentAccessEmail(documentId: string, code: string, email: string): Promise<void> {

        let details: any = {
            emailDetails: {
                companyName: this.navigatorService.getCompanyName(), 
                companyId: this.navigatorService.getCompanyId(),
                documentId: documentId,
                code: code,
                email: email
            }
        }

        this.http.post(this.emailRootURL + '/sendFormExternalAccessEmail', details ).subscribe( (data: any) => {
            console.log("Twilio Document Access Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });

    }

    public async sendDocumentSignRequestEmail(documentId: string, code: string, email: string): Promise<void> {

        let details: any = {
            emailDetails: {
                companyName: this.navigatorService.getCompanyName(), 
                companyId: this.navigatorService.getCompanyId(),
                documentId: documentId,
                code: code,
                email: email
            }
        }

        this.http.post(this.emailRootURL + '/sendFormSignatureRequestEmail', details ).subscribe( (data: any) => {
            console.log("Twilio Document Sign Request Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });

    }


    public async sendPhoneVerification(id: string, phone: number): Promise<void> {

        this.http.post(this.rootURL + '/sendPhoneVerification', { verificationDetails: { userId: id, phone: phone } } ).subscribe( (data: any) => {
            console.log("Twilio Verify Phone Server Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
            }

        });

    }

    public async phoneCodeVerification(id: string, phone: number, code: string): Promise<void> {

        this.http.post(this.rootURL + '/phoneCodeVerification', { verificationDetails: { userId: id, phone: phone, code: code } } ).subscribe( (data: any) => {
            console.log("Twilio Verify Phone Server Return", data );

            if(data != null && data != undefined) {
                this.phoneVerifiedSubject.next(data);
            }

        });

    }

    public async checkPhoneVerification(id: string, phone: number): Promise<void> {

        this.http.post(this.rootURL + '/checkPhoneVerification', { verificationDetails: { userId: id, phone: phone } } ).subscribe( (data: any) => {
            console.log("Twilio Verify Phone Server Return", data );

            if(data != null && data != undefined) {
                this.phoneVerifiedSubject.next(data);
            }

        });

    }

    public async sendEmailVerification(id: string, email: string, link: boolean = false): Promise<void> {

        if(link) {
            this.http.post(this.rootURL + '/sendEmailVerificationLink', { verificationDetails: { userId: id, email: email } } ).subscribe( (data: any) => {
                console.log("Twilio Verify Email Server Return", data );

                if(data != null && data != undefined) {
                    // this.http.get(data.url);
                    // window.open(data.url);
                }

            });
        } else {
            this.http.post(this.rootURL + '/sendEmailVerificationCode', { verificationDetails: { userId: id, email: email } } ).subscribe( (data: any) => {
                console.log("Twilio Verify Email Server Return", data );

                if(data != null && data != undefined) {
                    // this.http.get(data.url);
                    // window.open(data.url);
                }

            });
        }

    }

    public async emailCodeVerification(id: string, email: string, code: string): Promise<void> {

        this.http.post(this.rootURL + '/emailCodeVerification', { verificationDetails: { userId: id, email: email, code: code } } ).subscribe( (data: any) => {
            console.log("Twilio Verify Phone Server Return", data );

            if(data != undefined) {
                this.emailVerifiedSubject.next(data);
            }

        });

    }

    public async asyncEmailCodeVerification(id: string, email: string, code: string): Promise<number> {
        let verificationStatus: number = null;

        await this.http.post(this.rootURL + '/emailCodeVerification', { verificationDetails: { userId: id, email: email, code: code } } ).toPromise().then( (status: number) => {
            console.log("Twilio Verify Phone Server Return", status );

            verificationStatus = status;

        });

        return verificationStatus;
    }

    public async checkEmailVerification(id: string, email: string): Promise<void> {

        this.http.post(this.rootURL + '/checkEmailVerification', { verificationDetails: { userId: id, email: email } } ).subscribe( (data: any) => {
            console.log("Twilio Verify Email Server Return", data );

            if(data != null && data != undefined) {
                this.emailVerifiedSubject.next(data);
            }

        });

    }

}