import { Component, OnInit } from '@angular/core';
import { Settings, AppSettings } from '../../app.settings';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-key-platform-features',
  templateUrl: './key-platform-features.component.html',
  styleUrls: ['./key-platform-features.component.scss']
})
export class KeyPlatformFeaturesComponent implements OnInit {

  public bgImage;
  public settings: Settings;
  constructor(public appSettings:AppSettings, private viewportScroller: ViewportScroller) {
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){    
    
  }

  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
}

}
