import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { NavigatorService } from '../../../services/vendor/navigator.service'
// import { Group, TimeKeeper } from '../../../data-models/models'

@Component({
  selector: 'app-vendor-shift-menu',
  templateUrl: './vendor-shift-menu.component.html',
  styleUrls: ['./vendor-shift-menu.component.scss'],
})
export class VendorShiftMenuComponent implements OnInit, AfterViewInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  private currentDayActive: any;
  // private groups: Array<Group>;
  // public timeKeeper: Array<TimeKeeper> = [];
  public previousShiftElement: any;

  constructor(public appService:AppService, private navigator: NavigatorService) { 
    // this.navigator.getGroups().subscribe(data  => {
    //   if(data) {
    //     this.groups = data;
    //   }
    // });

    // this.navigator.getTimeKeeper().subscribe(data => {
    //   if(data) {
    //     this.timeKeeper = data;
    //   }
    // });

    // if(this.timeKeeper.length == 0) {
    //   this.timeKeeper = this.navigator.getLocalTimeKeeper();
    // }
  }

  ngOnInit() {
    this.currentDayActive = document.querySelector(".active");

    
   }

   ngAfterViewInit(): void {
    
  }

  public add(event: Event, groupTitle: string, groupId: number): void {
    // let dayKeeperIndex = Number(this.currentDayActive.attributes.id.nodeValue);
    // let shiftKeeperIndex = Number(this.previousShiftElement.attributes.id.nodeValue);

    // this.timeKeeper[ groupId ].dayKeeper[ dayKeeperIndex ].shiftKeeper[ shiftKeeperIndex ].count++;

    // console.log("Group Title: " + groupTitle);
  }

  public subtract(event: Event, groupTitle: string, groupId: number): void {
    // let dayKeeperIndex = Number(this.currentDayActive.attributes.id.nodeValue);
    // let shiftKeeperIndex = Number(this.previousShiftElement.attributes.id.nodeValue);
    // let count = this.timeKeeper[ groupId ].dayKeeper[ dayKeeperIndex ].shiftKeeper[shiftKeeperIndex].count;

    // if(count > 0) {
    //   this.timeKeeper[ groupId ].dayKeeper[ dayKeeperIndex ].shiftKeeper[ shiftKeeperIndex ].count--;
    // }

    // console.log("Group Title: " + groupTitle);
  }

  public sidenavToggle(): void{
    this.onMenuIconClick.emit();
  }

  public changeActiveDay(event: Event): void {

    if(this.currentDayActive) {
      this.currentDayActive.classList.remove("active");
    } else {
      this.currentDayActive = document.querySelector(".active");
      this.currentDayActive.classList.remove("active");
    }

    this.currentDayActive = event.currentTarget;
    this.currentDayActive.classList.add("active");
  }

  public shiftFocus(event: any, title: string) {

    if(this.previousShiftElement) {
        this.previousShiftElement.classList.remove("activeShift");
    }

    event.currentTarget.classList.add("activeShift");
    this.previousShiftElement = event.currentTarget;
  }

  public save(event: Event): void {
    // this.navigator.setTimeKeeper(this.timeKeeper);

    // // Click focus is required in the shift calendar in order for it to update changes
    // this.eventFire(document.querySelector(".cal-header"), 'click');
  }

  public setInitialActiveShift(element: any) {
    this.previousShiftElement = element;
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}