import { Component, OnInit, ViewChild } from '@angular/core';
import { FormDocument, InfoPanelImgSlide } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { FormStatusCategories } from 'src/app/vendor/forms/forms.component';
import { AnimationType } from "../../carousel-support/carousel-support.animations";
import { CarouselSupportComponent } from "../../carousel-support/carousel-support.component";

export interface FormSection { 
  id: number,
  title: string,
  documents: Array<string>,
  dailyCount: Array<string>,

  background: string,
  color: string,

  totalValue: number
}

@Component({
  selector: 'app-vendor-form-overview',
  templateUrl: 'vendor-form-overview.component.html',
  styleUrls: ['vendor-form-overview.component.scss']
})
export class VendorFormOverviewComponent implements OnInit {

  public formSectionTypes: any = FormStatusCategories;

  public documents: Array<FormDocument> = [];

  public drafts: Array<string> = [];
  public forApproval: Array<string> = [];
  public sent: Array<string> = [];
  public viewed: Array<string> = [];
  public completed: Array<string> = [];
  public expired: Array<string> = [];
  public waitingPayment: Array<string> = [];
  public paid: Array<string> = [];
  public declined: Array<string> = [];

  public formSections: Array<FormSection> = [
    {
      id: this.formSectionTypes.DRAFT,
      title: "Draft",
      documents: this.drafts,
      dailyCount: [],

      background: "#ffffff",
      color: "#898b8c",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.FOR_APPROVAL,
      title: "For approval",
      documents: this.forApproval,
      dailyCount: [],

      background: "#f27b41",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.SENT,
      title: "sent",
      documents: this.sent,
      dailyCount: [],

      background: "#255af4",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.VIEWED,
      title: "Viewed",
      documents: this.viewed,
      dailyCount: [],

      background: "#87299b",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.COMPLETED,
      title: "Completed",
      documents: this.completed,
      dailyCount: [],

      background: "#46c167",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.EXPIRED,
      title: "Expired",
      documents: this.expired,
      dailyCount: [],

      background: "#d13341",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.WAITING_PAYMENT,
      title: "Waiting for payment",
      documents: this.waitingPayment,
      dailyCount: [],

      background: "#28a24d",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.PAID,
      title: "Paid",
      documents: this.paid,
      dailyCount: [],

      background: "#0b8c3c",
      color: "#ededed",

      totalValue: 0
    },
    {
      id: this.formSectionTypes.DECLINED,
      title: "Declined",
      documents: this.declined,
      dailyCount: [],

      background: "#7f1821",
      color: "#ededed",

      totalValue: 0
    }
  ]

  constructor(private navigatorService: NavigatorService) { }

  ngOnInit() {
    this.getAllDocuments();
  }

  private getAllDocuments(): void {

    this.navigatorService.getAsyncDocuments().then( (docs: Array<FormDocument>) => {

      this.documents = docs;

      this.initializeFormSections();
    });

  }

  private initializeFormSections(): void {

    for(let document of this.documents) {

      switch(document.status) {

        case this.formSectionTypes.DRAFT:
          this.drafts.push(document.id);
          break;

        case this.formSectionTypes.FOR_APPROVAL:
          this.forApproval.push(document.id);
          break;

        case this.formSectionTypes.SENT:
          this.sent.push(document.id);
          break;

        case this.formSectionTypes.VIEWED:
          this.viewed.push(document.id);
          break;

        case this.formSectionTypes.COMPLETED:
          this.completed.push(document.id);
          break;

        case this.formSectionTypes.EXPIRED:
          this.expired.push(document.id);
          break;

        case this.formSectionTypes.WAITING_PAYMENT:
          this.waitingPayment.push(document.id);
          break;

        case this.formSectionTypes.PAID:
          this.paid.push(document.id);
          break;

        case this.formSectionTypes.DECLINED:
          this.declined.push(document.id);
          break;

        default:
          this.drafts.push(document.id);

      }

      let status: FormSection = this.getDefinedStatus(document);
      document.formSection = status;

    }

  }

  private getDefinedStatus(document: FormDocument): FormSection {

    let status: FormSection = this.formSections.find( (section: FormSection) => { return section.id == document.status });

    // Default
    if(status == undefined || status == null) {

      status = {
        id: this.formSectionTypes.DRAFT,
        title: "Draft",
        documents: this.drafts,
        dailyCount: [],
  
        background: "#ffffff",
        color: "#898b8c",
  
        totalValue: 0
      }

    } 


    let currentTime: number = new Date().getTime();
    let lastUpdate: number = document.dateLastUpdated.getTime();

    let seconds: number = 1000;
    let minutes: number = seconds * 60;
    let hours: number = minutes * 60;
    let day: number = hours * 24;

    let diff: number = currentTime - lastUpdate;

    if(diff < day) {
      status.dailyCount.push(document.id);
    }

    return status;
  }

}