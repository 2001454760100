import date_utils from './date_utils';
import { $, createSVG, animateSVG } from './svg_utils';

export default class Bar {

    constructor(gantt, task) {
        this.set_defaults(gantt, task);
        this.prepare();
        this.draw();
        this.bind();
    }

    set_defaults(gantt, task) {
        this.action_completed = false;
        this.gantt = gantt;
        this.task = task;
        this.iconWidth = 25;
    }

    prepare() {
        this.prepare_values();
        this.prepare_helpers();
    }

    prepare_values() {
        this.duration = 0;

        console.log("Bar Prepare Values: ", this.task);

        this.invalid = this.task.invalid;
        this.height = this.gantt.options.bar_height;
        this.x = this.compute_x();
        this.y = this.compute_y();
        this.corner_radius = this.gantt.options.bar_corner_radius;
        this.duration =
            date_utils.diff(this.task._end, this.task._start, 'hour') /
            this.gantt.options.step;
        this.width = this.gantt.options.column_width * this.duration;
        this.progress_width =
            (this.gantt.options.column_width - this.iconWidth ) *
                this.duration *
                (this.task.progress / 100) || 0;
        this.group = createSVG('g', {
            class: 'bar-wrapper ' + (this.task.custom_class || ''),
            'data-id': this.task.task_id,
            'wo-id': this.task.id
        });
        this.bar_group = createSVG('g', {
            class: 'bar-group',
            append_to: this.group,
        });
        this.handle_group = createSVG('g', {
            class: 'handle-group',
            append_to: this.group,
        });

        if (this.gantt.view_is('Hour')) {

            let minuteDiff =
            date_utils.diff(this.task._end, this.task._start, 'minute') /
            this.gantt.options.step;

            this.duration = Math.floor( minuteDiff / 30);

            if(minuteDiff % 30 > 0) {
                this.duration++;
            }

            this.width = (this.gantt.options.column_width / 2) * this.duration;

            // if(this.task._end.getMinutes() > 0 && this.task._end.getMinutes() < 30) {
            //     this.width += this.gantt.options.column_width / 2;
            // }

            // if(this.task._end.getMinutes() >= 30) {
            //     this.width += this.gantt.options.column_width / 2;
            // }

            // if(this.task._start.getMinutes() >= 30) {
            //     this.width += this.gantt.options.column_width / 2;
            // }
        }
    }

    prepare_helpers() {
        SVGElement.prototype.getX = function () {
            return +this.getAttribute('x');
        };
        SVGElement.prototype.getY = function () {
            return +this.getAttribute('y');
        };
        SVGElement.prototype.getWidth = function () {
            return +this.getAttribute('width');
        };
        SVGElement.prototype.getHeight = function () {
            return +this.getAttribute('height');
        };
        SVGElement.prototype.getEndX = function () {
            return this.getX() + this.getWidth();
        };
    }

    draw() {
        this.draw_bar();
        this.draw_progress_bar();
        this.draw_label();
        this.draw_resize_handles();
    }

    draw_bar() {
        this.$bar = createSVG('rect', {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            rx: this.corner_radius,
            ry: this.corner_radius,
            class: 'bar',
            append_to: this.bar_group,
        });

        animateSVG(this.$bar, 'width', 0, this.width);

        if (this.invalid) {
            this.$bar.classList.add('bar-invalid');
        }
    }

    draw_progress_bar() {
        if (this.invalid) return;

        let progress_width = (this.$bar.getWidth() - this.iconWidth) * (this.task.progress / 100);

        this.$bar_progress = createSVG('rect', {
            x: this.x + this.iconWidth,
            y: this.y,
            width: progress_width,
            height: this.height,
            rx: this.corner_radius,
            ry: this.corner_radius,
            class: 'bar-progress',
            append_to: this.bar_group,
        });

        animateSVG(this.$bar_progress, 'width', 0, progress_width);
    }

    draw_icon() {

    }

    draw_label() {
        const bar = this.$bar;
        let statusIcon = ""
        let statusClass = "";

        if(this.task.status_icon != undefined) {
            statusIcon = this.task.status_icon;
        }

        if(this.task.status != undefined) {
            statusClass = this.task.status;
        }

        createSVG('rect', {
            x: this.x,
            y: this.y,
            width: this.iconWidth,
            height: this.height,
            rx: this.corner_radius,
            ry: this.corner_radius,
            class: 'work-status status-icon-container ' + statusClass,
            append_to: this.bar_group,
        });

        createSVG('text', {
            x: bar.getX() + (this.iconWidth / 2),
            y: this.y + bar.getHeight() / 2,
            class: 'work-status status-icon material-symbols-outlined',
            innerHTML: statusIcon,
            append_to: this.bar_group,
        });

        createSVG('text', {
            // label.setAttribute('x', bar.getX() + (bar.getWidth() + this.iconWidth) / 2);
            x: bar.getX() + ( (bar.getWidth() + this.iconWidth) / 2),
            y: this.y + this.height / 2,
            innerHTML: this.task.name,
            id: this.task.task_id + "-label",
            class: 'bar-label',
            append_to: this.bar_group,
        });

        if(this.task.sub_tasks != undefined && this.task.sub_tasks != null && this.task.sub_tasks.length > 0) {
            createSVG('rect', {
                x: this.x + this.width / 2,
                y: (this.y + this.height) - 4,
                width: 28,
                height: this.height / 2,
                rx: this.corner_radius,
                ry: this.corner_radius,
                class: 'expand-collapse expandBackground',
                append_to: this.bar_group,
            });

            createSVG('text', {
                x: this.x + (this.width + this.iconWidth) / 2,
                y: (this.y + this.height) + 2,
                width: 17,
                height: this.height / 2,
                innerHTML: '...',
                class: 'expand-collapse expandIcon',
                append_to: this.bar_group,
            });
        }

        // labels get BBox in the next tick
        requestAnimationFrame(() => {
            this.update_label_position();
            this.update_label_ellipse();
        });
    }

    draw_resize_handles() {
        if (this.invalid) return;

        const bar = this.$bar;
        const handle_width = 8;

        createSVG('rect', {
            x: bar.getX() + bar.getWidth() - 9,
            y: bar.getY() + 1,
            width: handle_width,
            height: this.height - 2,
            rx: this.corner_radius,
            ry: this.corner_radius,
            class: 'handle right',
            append_to: this.handle_group,
        });

        createSVG('rect', {
            x: bar.getX() + 1,
            y: bar.getY() + 1,
            width: handle_width,
            height: this.height - 2,
            rx: this.corner_radius,
            ry: this.corner_radius,
            class: 'handle left',
            append_to: this.handle_group,
        });

        if (this.task.progress && this.task.progress < 100) {
            this.$handle_progress = createSVG('polygon', {
                points: this.get_progress_polygon_points().join(','),
                class: 'handle progress',
                append_to: this.handle_group,
            });
        }
    }

    get_progress_polygon_points() {
        const bar_progress = this.$bar_progress;
        return [
            bar_progress.getEndX() - 5,
            bar_progress.getY() + bar_progress.getHeight(),
            bar_progress.getEndX() + 5,
            bar_progress.getY() + bar_progress.getHeight(),
            bar_progress.getEndX(),
            bar_progress.getY() + bar_progress.getHeight() - 8.66,
        ];
    }

    bind() {
        if (this.invalid) return;
        this.setup_click_events();
    }

    setup_click_events() {
        let expandCollapse = this.group.querySelectorAll('.expand-collapse');

        $.on(this.group, 'focus ' + this.gantt.options.popup_trigger, (e) => {
            if (this.action_completed) {
                // just finished a move action, wait for a few seconds
                return;
            }

            this.show_popup();
            this.gantt.unselect_all();
            this.group.classList.add('active');
        });

        // $.on(this.group, 'dblclick', (e) => {
        //     if (this.action_completed) {
        //         // just finished a move action, wait for a few seconds
        //         return;
        //     }

        //     this.gantt.trigger_event('click', [
        //         {
        //             target: "body",
        //             task: this.task
        //         }
        //     ]);
        // });

        for(let menu of expandCollapse) {
            $.on(menu, 'dblclick', (e) => {
                console.log("Gantt Expand/Collapse Click: ", this.task);

                this.gantt.trigger_event('dblclick', [
                    {
                        target: "expand_collapse",
                        task: this.task
                    }
                ]);
            });
        }
    }

    show_popup() {
        let start = null;
        let end = null;

        if (this.gantt.bar_being_dragged) return;
            
        if (this.gantt.view_is('Hour')) {
            start = this.task._start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            end = this.task._end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            start = date_utils.format(
                this.task._start,
                'MMM D',
                this.gantt.options.language
            );

            end = date_utils.format(
                date_utils.add(this.task._end, -1, 'second'),
                'MMM D',
                this.gantt.options.language
            );
        }

        const subtitle = start + ' - ' + end;

        this.gantt.show_popup({
            target_element: this.$bar,
            title: this.task.name,
            subtitle: subtitle,
            task: this.task,
        });
    }

    update_bar_x_position({ x = null, width = null }) {
        const bar = this.$bar;
        if (x) {
            // get all x values of parent task
            const xs = this.task.dependencies.map((dep) => {
                let bar = this.gantt.get_bar(dep);

                if(bar != undefined && bar != null) {
                    return bar.$bar.getX()
                }

                return null;
            });

            // child task must not go before parent
            if(xs != undefined && xs != null) {
                const valid_x = xs.reduce((prev, curr) => {
                    return x >= curr;
                }, x);
                if (!valid_x) {
                    width = null;
                    return;
                }
                this.update_attr(bar, 'x', x);
            }
        }

        if (width) {
            this.update_attr(bar, 'width', width);
            this.update_label_ellipse();
        }

        this.update_label_position();
        this.update_handle_position();
        this.update_progressbar_position();
        this.update_arrow_position();
    }

    update_bar_y_position({ y = null, height = null }) {
        const bar = this.$bar;
        // const container = 

        // if (x) {
        //     // get all x values of parent task
        //     const xs = this.task.dependencies.map((dep) => {
        //         let bar = this.gantt.get_bar(dep);

        //         if(bar != undefined && bar != null) {
        //             return bar.$bar.getX()
        //         }

        //         return null;
        //     });

        //     // child task must not go before parent
        //     if(xs != undefined && xs != null) {
        //         const valid_x = xs.reduce((prev, curr) => {
        //             return x >= curr;
        //         }, x);
        //         if (!valid_x) {
        //             width = null;
        //             return;
        //         }
        //         this.update_attr(bar, 'x', x);
        //     }
        // }

        if(y) {
            this.update_attr(bar, 'y', y);
            

            // if (width) {
            //     this.update_attr(bar, 'width', width);
            // }

            this.update_label_position();
            this.update_handle_position();
            this.update_progressbar_position();
            this.update_arrow_position();
        }
    }

    date_changed() {
        let changed = false;
        const { new_start_date, new_end_date } = this.compute_start_end_date();

        if (Number(this.task._start) !== Number(new_start_date)) {
            changed = true;
            this.task._start = new_start_date;
        }

        if (Number(this.task._end) !== Number(new_end_date)) {
            changed = true;
            this.task._end = new_end_date;
        }

        if (!changed) return;

        this.gantt.trigger_event('date_change', [
            this.task,
            new_start_date,
            date_utils.add(new_end_date, -1, 'second'),
        ]);
    }

    employee_changed(forceUpdate = false, previousEmployee = null) {
        let changed = false;
        const task_height = this.compute_y();
        const row_height = this.gantt.options.bar_height + this.gantt.options.padding;
        const assigned_employee_index = Math.floor(task_height / row_height) - 1;
        const current_employee_index = this.compute_current_employee() - 1;

        if(assigned_employee_index != current_employee_index) {
            changed = true;
        }

        if (!changed && !forceUpdate) return { 
            assigned: assigned_employee_index,
            current: current_employee_index,
            changed: changed
        };

        this.gantt.trigger_event('employee_change', [
            this.task,
            previousEmployee,
            current_employee_index,
            forceUpdate
        ]);

        return { 
            assigned: assigned_employee_index,
            current: current_employee_index,
            changed: changed
        };
    }

    progress_changed() {
        const new_progress = this.compute_progress();
        this.task.progress = new_progress;
        this.gantt.trigger_event('progress_change', [this.task, new_progress]);
    }

    set_action_completed() {
        this.action_completed = true;
        setTimeout(() => (this.action_completed = false), 1000);
    }

    compute_current_employee() {
        const bar = this.$bar;
        const row_height = this.gantt.options.bar_height + this.gantt.options.padding;
        const y_in_rows = bar.getY() / row_height;

        return Math.floor(y_in_rows);
    }

    compute_start_end_date() {
        const bar = this.$bar;
        const x_in_units = bar.getX() / this.gantt.options.column_width;

        let addMinutes = 0;

        const new_start_date = date_utils.add(
            this.gantt.gantt_start,
            ((x_in_units * (this.gantt.options.step)) * 60),
            'minute'
        );

        new_start_date.setMinutes(new_start_date.getMinutes() + addMinutes);

        const width_in_units = bar.getWidth() / this.gantt.options.column_width;
        let new_end_date = null

        if (this.gantt.view_is('Hour')) {

            new_end_date = date_utils.add(
                new_start_date,
                ( (width_in_units * (this.gantt.options.step) ) * 60) ,
                'minute'
            );

        } else {
            new_end_date = date_utils.add(
                new_start_date,
                ( (width_in_units * (this.gantt.options.step) ) * 60),
                'minute'
            );
        }

        return { new_start_date, new_end_date };
    }

    compute_progress() {
        const progress =
            ( (this.$bar_progress.getWidth() + this.iconWidth) / this.$bar.getWidth()) * 100;
        return parseInt(progress, 10);
    }

    compute_x() {
        const { step, column_width } = this.gantt.options;
        const task_start = this.task._start;
        const gantt_start = this.gantt.gantt_start;


        // There appears to be a bug here that effects diff on Daylight Savings 
        // const diff = date_utils.diff(task_start, gantt_start, 'hour') - 1;
        const diff = date_utils.diff(task_start, gantt_start, 'hour');

        let x = (diff / step) * column_width;

        if (this.gantt.view_is('Month')) {
            const diff = date_utils.diff(task_start, gantt_start, 'day');
            x = (diff * column_width) / 30;
        } else if (this.gantt.view_is('Hour')) {

            if(task_start.getMinutes() >= 30) {
                x += column_width / 2;
            }
        }

        return x;
    }

    compute_y() {
        let row = this.task._index;

        if(this.gantt.options.employees != undefined && this.gantt.options.employees.length > 0) {
            
            if(this.task.isAttached != undefined && this.task.isAttached) {
                let parent_task_index = this.gantt.tasks.findIndex( task => { return  task.task_id == this.task.dependencies; })
                let parent_task = this.gantt.tasks[parent_task_index];

                row = this.gantt.options.employees.findIndex( employee => { return employee.id == parent_task.employee_id } );

                row++;
            } else {
                row = this.gantt.options.employees.findIndex( employee => { return employee.id == this.task.employee_id } );
            }

        }

        return (
            this.gantt.options.header_height +
            this.gantt.options.padding +
            row * (this.height + this.gantt.options.padding)
        );
    }

    get_snap_position(dx) {
        let odx = dx,
            rem,
            position;

        if (this.gantt.view_is('Week')) {
            rem = dx % (this.gantt.options.column_width / 7);
            position =
                odx -
                rem +
                (rem < this.gantt.options.column_width / 14
                    ? 0
                    : this.gantt.options.column_width / 7);
        } else if (this.gantt.view_is('Month')) {
            rem = dx % (this.gantt.options.column_width / 30);
            position =
                odx -
                rem +
                (rem < this.gantt.options.column_width / 60
                    ? 0
                    : this.gantt.options.column_width / 30);
        } else if (this.gantt.view_is('Hour')) {
            rem = dx % (this.gantt.options.column_width / 2);
            position =
                odx -
                rem +
                (rem < this.gantt.options.column_width / 4
                    ? 0
                    : this.gantt.options.column_width / 2);
        } else {
            rem = dx % this.gantt.options.column_width;
            position =
                odx -
                rem +
                (rem < this.gantt.options.column_width / 2
                    ? 0
                    : this.gantt.options.column_width);
        }
        return position;
    }

    update_attr(element, attr, value) {
        value = +value;
        if (!isNaN(value)) {
            element.setAttribute(attr, value);
        }
        return element;
    }

    update_progressbar_position() {
        this.$bar_progress.setAttribute('y', this.$bar.getY());
        this.$bar_progress.setAttribute('x', this.$bar.getX() + this.iconWidth);
        this.$bar_progress.setAttribute(
            'width',
            (this.$bar.getWidth() - this.iconWidth) * (this.task.progress / 100)
        );
    }

    update_label_position() {
        const bar = this.$bar,
            labels = this.group.querySelectorAll('.bar-label'),
            expandCollapse = this.group.querySelectorAll('.expand-collapse'),
            statusContainer = this.group.querySelectorAll('.status-icon-container'),
            status = this.group.querySelectorAll('.status-icon');

        for(let label of labels) {
            label.setAttribute('x', bar.getX() + (bar.getWidth() + this.iconWidth) / 2);
            label.setAttribute('y', bar.getY() + bar.getHeight() / 2);
        }

        for(let container of statusContainer) {
            container.setAttribute('x', bar.getX());
            container.setAttribute('y', bar.getY());
        }

        for(let icon of status) {
            icon.setAttribute('x', bar.getX() + (this.iconWidth / 2));
            icon.setAttribute('y', bar.getY() + bar.getHeight() / 2);
        }

        for(let menu of expandCollapse) {
            if (menu.getBBox().width > bar.getWidth()) {
                menu.classList.add('big');
                menu.setAttribute('x', bar.getX() + bar.getWidth() + 5);
            } else {
                menu.classList.remove('big');
                menu.setAttribute('x', (bar.getX() + bar.getWidth() / 2) - ( menu.getWidth() / 2) );
            }

            if(menu.classList.contains('expandIcon')) {
                menu.setAttribute('y', (bar.getY() + bar.getHeight() / 2) + 12 );
            } else if(menu.classList.contains('expandBackground')) {
                menu.setAttribute('y', (bar.getY() + bar.getHeight() / 2) + 6 );
            } else {
                menu.setAttribute('y', (bar.getY() + bar.getHeight() / 2) );
            }
        }
    }

    update_label_ellipse() {
        const bar = this.$bar;
        let labels = this.group.querySelectorAll('.bar-label');

        if(labels != undefined) {

            for(let label of labels) {
                label.innerHTML = this.task.name;

                label.setAttribute('x', bar.getX() + (bar.getWidth() + this.iconWidth) / 2);
                label.setAttribute('y', bar.getY() + bar.getHeight() / 2);

                this.text_wrap_ellipsis(label, label.innerHTML, bar.getWidth() - this.iconWidth);
            }

        }
    }

    text_wrap_ellipsis(el, text, width) {
        width -= this.gantt.options.padding;

        try {
            if (typeof el.getSubStringLength !== "undefined") {
                el.textContent = text;
                let len = text.length;

                while (el.getSubStringLength(0, len--) > width) {
                    el.textContent = text.slice(0, len) + "...";
                }
            } else if (typeof el.getComputedTextLength !== "undefined") {
                while (el.getComputedTextLength() > width) {
                    text = text.slice(0,-1);
                    el.textContent = text + "...";
                }
            } else {
                // the last fallback
                while (el.getBBox().width > width) {
                    text = text.slice(0,-1);
                    // we need to update the textContent to update the boundary width
                    el.textContent = text + "...";
                }
            }

        } catch {
            console.log("Text Ellipse Creation Error");
        }
    }

    update_handle_position() {
        const bar = this.$bar;

        this.handle_group
            .querySelector('.handle.left')
            .setAttribute('x', bar.getX() + 1);

        this.handle_group
            .querySelector('.handle.left')
            .setAttribute('y', bar.getY());

        this.handle_group
            .querySelector('.handle.right')
            .setAttribute('x', bar.getEndX() - 9);

        this.handle_group
            .querySelector('.handle.right')
            .setAttribute('y', bar.getY());

        const handle = this.group.querySelector('.handle.progress');
        handle &&
            handle.setAttribute('points', this.get_progress_polygon_points());
    }

    update_arrow_position() {
        this.arrows = this.arrows || [];
        for (let arrow of this.arrows) {
            arrow.update();
        }
    }
}

function isFunction(functionToCheck) {
    var getType = {};
    return (
        functionToCheck &&
        getType.toString.call(functionToCheck) === '[object Function]'
    );
}
