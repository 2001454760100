import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, Inject, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { InventoryItem, PartAttachBundle, Profile, ScheduleSwap, WorkOrder } from '../../../data-models/models';

import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-work-order-form-dialog.component',
    templateUrl: 'vendor-work-order-form-dialog.component.html',
    styleUrls: ['vendor-work-order-form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorWorkOrderFormDialogComponent implements OnInit, AfterViewInit {

    @ViewChild('docPreview') docPreviewRef: ElementRef;  

    public documentId: string = "default_wo_version";
    public workOrder: WorkOrder = null;
    public builderMode: boolean = false;
    public detailMode: boolean = true;
    public previewZoomLvl: number = 100;

    constructor(
      @Inject(MAT_DIALOG_DATA) public incomingData: any,
      private navigatorService: NavigatorService,
      public datePipe: DatePipe,
      public dialogRef: MatDialogRef<VendorWorkOrderFormDialogComponent>) {

    }
  
    ngOnInit() {
      this.initialize();

      this.dialogRef.beforeClosed().subscribe(data => {
        this.close();
      });

    }

    ngAfterViewInit() { 
      this.calcDocPreviewZoomLvl();
    }

    private initialize(): void {
      
      if(this.incomingData.workOrder != undefined && this.incomingData.workOrder != null) {

        this.navigatorService.getWorkOrder(this.incomingData.workOrder).then( (workOrder: WorkOrder) => {

          if(workOrder != undefined && workOrder != null) {

            this.workOrder = workOrder;

          }

        });

      }

    }

    private calcDocPreviewZoomLvl(): void {
      let self = this;
  
      this.previewZoomLvl = null;
      let previewWidth: number = this.docPreviewRef.nativeElement.clientWidth;
      let defaultDocWidth: number = 75 * 16;
      let padding: number = 5;
  
      let zoomLvl = Math.round( (previewWidth / defaultDocWidth) * 100 );
      zoomLvl -= padding;
  
      setTimeout(function() {
        self.previewZoomLvl = zoomLvl;
      }, 10);
      
    }
  
    submit() {
    }

    public reset(): void {

    }


    public close() {
      this.dialogRef.close();
    }
  }