import {
  Component,
  Directive, 
  Input, 
  OnChanges, 
  OnInit,
  AfterViewInit,
  ElementRef, 
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ViewRef,
  HostBinding,
  Output,
  ViewEncapsulation
} from '@angular/core';



@Component({
  selector: 'app-document-editor-toolbar',
  templateUrl: './document-editor-toolbar.component.html',
  styleUrls: ['./document-editor-toolbar.component.scss']
})

export class DocumentEditorToolbarComponent implements OnInit, AfterViewInit {

  constructor() { 
    
 }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {
    
  }
}
