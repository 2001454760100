import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberDirective } from './only-number.directive';
import { ContentBuilderDragDropDirective } from './content-builder-drag-drop';
import { TemplateVarDirective } from './templateVar.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { ResizeColumnDirective } from './resize-column.directive';
import { ClickStopPropagation } from './click-stop-propagation.directive';
import { MousedownStopPropagation } from './mousedown-stop-propagation.directive';
import { MouseupStopPropagation } from './mouseup-stop-propagation.directive';
import { BasePhoneMaskDirective } from './base-phone-mask.directive'
 
@NgModule({
  declarations: [
    OnlyNumberDirective,
    ContentBuilderDragDropDirective,
    TemplateVarDirective,
    PhoneMaskDirective,
    ResizeColumnDirective,
    ClickStopPropagation,
    BasePhoneMaskDirective,
    MousedownStopPropagation,
    MouseupStopPropagation
  ],
  exports: [
    OnlyNumberDirective,
    ContentBuilderDragDropDirective,
    TemplateVarDirective,
    PhoneMaskDirective,
    ResizeColumnDirective,
    ClickStopPropagation,
    BasePhoneMaskDirective,
    MousedownStopPropagation,
    MouseupStopPropagation
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
