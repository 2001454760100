import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { CompanyProfile, InventoryItem, LinkedAccountRequest, LinkedEmployeeRequest, Profile, ScheduleSwap } from '../../../data-models/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';

export enum viewModeEnum {
  ACCEPTED = 0,
  SENT = 1
}

@Component({
    selector: 'app-vendor-employee-linked-management-dialog.component',
    templateUrl: 'vendor-employee-linked-management-dialog.component.html',
    styleUrls: ['vendor-employee-linked-management-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorEmployeeLinkedManagementDialogComponent implements OnInit {
    public submitForm:FormGroup; 
    public companyProfile: CompanyProfile = null;
    public employees: Array<Profile> = [];
    public profileInvites: Array<Profile> = [];
    public linkRequests: Array<LinkedAccountRequest> = [];

    viewTypes = viewModeEnum;
    viewMode = this.viewTypes.ACCEPTED;

    constructor(
      private navigatorService: NavigatorService, 
      private twilioHandler: TwilioHandlerService, 
      private snackBar: MatSnackBar,
      private fb: FormBuilder,
      public datePipe: DatePipe) {

    }
  
    ngOnInit() {
      this.getCompanyProfile();
      this.getAllEmployees();
      this.getOpenLinkRequests();

      this.submitForm = this.fb.group({
        basic: this.fb.group({
          title: [null, Validators.required],
          desc: null,
          priceDollar: null,
          unit: null,
          quantity: null,
          location: null,
          gallery: null
        })
      }); 

    }

    async getProfile(profileId: string): Promise<Profile> {
      let profile: Profile = null;

      await this.navigatorService.getAsyncEmployeeById(profileId).then( (foundProfile: Profile) => { 
  
        if(foundProfile != undefined && foundProfile != null) {
          profile = foundProfile;
        }

      });

      return profile;
    }

    private getOpenLinkRequests(): void {
      let self = this;

      this.navigatorService.getCompanyOpenLinkRequests(this.navigatorService.getCompanyId()).then( (requests: Array<LinkedAccountRequest>) => {

        self.linkRequests = requests;

      });

    }

    private getLinkInviteProfiles(): void {

      if(this.companyProfile != undefined && this.companyProfile != null) {
        this.profileInvites = [];

        if(this.companyProfile.linked_employee_requests == undefined || this.companyProfile.linked_employee_requests == null) {
          this.companyProfile.linked_employee_requests = [];
        }

        for(let invite of this.companyProfile.linked_employee_requests) {
          this.getProfile(invite.profileId).then( (profile: Profile) => {

            if(profile != undefined && profile != null) {
              this.profileInvites.push(profile);
            }

          });
        }

      }

    }

    private getAllEmployees(): void {
      this.navigatorService.getAsyncAllEmployees().then( (employees: Array<Profile>) => { 

        this.employees = []; 
  
        if(employees != null) {
          for(let employee of employees) {
            this.employees.push(employee);
          }
        }

      });
    }

    getCompanyProfile(): void {
      this.navigatorService.getAsyncCompanyProfile().then( (company: CompanyProfile) => { 
  
        if(company != undefined && company != null) {
          this.companyProfile = company;

          this.getLinkInviteProfiles();
        } 
  
      });
    }
  
    updateCompanyProfile(): void {
      this.navigatorService.updateCompanyProfile(this.companyProfile).then( (status: boolean) => {
  
        if(status) {
          this.getCompanyProfile();
          this.snackBar.open('Request sent successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.snackBar.open('Request Failed to Send!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        }
  
      });
    }

    public deleteUser(employee: Profile): void {

      if(employee.id == this.navigatorService.getProfileId()) {
        this.snackBar.open('You cannot delete your own profile!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        return;
      }
  
      this.navigatorService.removeCompanyId(employee, this.navigatorService.getCompanyId(), true).then( (success: boolean) => {
  
        if(success) {
          this.snackBar.open('User deleted successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.navigatorService.getAllEmployees()
          this.getAllEmployees();
        } else {
          this.snackBar.open('Failed user deletion!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        }
  
      });
      
    }

    public sendLinkedInvite(email: any) {

      if(this.validateEmail(email)) {

        this.navigatorService.sendCompanyLinkRequest(email).then( (status: any) => {

          if(status.company && status.user) {
            this.snackBar.open('Link Request has been sent!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            this.getCompanyProfile();
          } 
          
          else if(status.company && !status.user) {
            this.snackBar.open('Failed to send invite due to a network error, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
            this.getCompanyProfile();
          }

          else if(!status.company && status.user) {
            this.snackBar.open('Failed to send invite due to this email not existing in the Swarm Network!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
            this.sendPlatformUserInvite(email);
          }

          else if(!status.company && !status.user) {
            this.snackBar.open('Failed to send invite due to this email not existing or a network error!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
            this.sendPlatformUserInvite(email);
          }

        });

      } else {
        this.snackBar.open('Invalid Email Format', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    }

    public sendPlatformUserInvite(email: string): void {

      if(this.validateEmail(email)) {
        let self = this;
  
        this.navigatorService.insertNewLinkRequest(email).then( (request: LinkedAccountRequest) => {

          self.twilioHandler.sendPlatformUserInviteEmail(email, request.id).then( (status: boolean) => {

            if(status) {
              self.snackBar.open('Invite has been sent, please make sure the invited user checks their spam, promotion, etc. email folders if they\'re unable to find it!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
              self.getOpenLinkRequests();
            } else {
              self.snackBar.open('Failed to send invite due to a network error, please try again later!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 5000 });
            }

          });

        });

      } else {
        this.snackBar.open('Invalid Email Format', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    }

    public cancelLinkedInvite(request: LinkedAccountRequest) {

      let companyId: string = this.navigatorService.getCompanyId();
      let self = this;

      this.navigatorService.rejectCompanyLinkRequestTransaction(companyId, request.id).then( (status: any) => {

        if(status) {
          self.snackBar.open('Invite has been canceled!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          self.getOpenLinkRequests();
        } 

      });

    }
  
    public changeView(view: number): void {
      this.viewMode = view;
    }

    validateEmail(email: string): boolean {

      if(email.length > 0) {
       
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const validate: boolean = expression.test(email); // true
  
        return validate;
      } else {
        return false;
      }
  
    }

  }