import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation, Input, SimpleChange, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addWeeks, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import { FormControl } from '@angular/forms';
import { GroupedObservable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { groups } from './groups/vendor-calendar-groups';
import { Subscription } from 'rxjs';

import { workOrderTypesEnum } from '../../../services/vendor/navigator.service';
import { NavigatorService } from '../../../services/vendor/navigator.service';

import { Address, AssetWorkOrder, GroupPermissions, Profile } from '../../../data-models/models'
import { VendorAddWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';
import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-vendor-work-order-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-work-order-calendar.component.html',
  styleUrls: ['vendor-work-order-calendar.component.scss']
})

export class VendorWorkOrderCalendarComponent implements OnInit, AfterViewInit {
  calendarTypes = workOrderTypesEnum;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() workOrders: Array<any> = [];
  @Input() calendarType: number = this.calendarTypes.FACILITY;
  @Input() incomingAddress: Address;
  @Input() filteredEmployees: Array<Profile> = [];

  @Output() workOrderDateChangedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() workOrderDataChangedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangeNextMonthEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangePreviousMonthEmitter: EventEmitter<any> = new EventEmitter<any>();

  public largeContainerOptions: boolean = false;
  public mediumContainerOptions: boolean = false;
  public smallContainerOptions: boolean = false;

  @ViewChild("eventCalendar") calendarContainer;

  @HostListener('window:resize')
  public onWindowResize():void {
    (this.calendarContainer.nativeElement.clientWidth > 960) ? this.largeContainerOptions = true : this.largeContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 960 && this.calendarContainer.nativeElement.clientWidth > 480) ? this.mediumContainerOptions = true : this.mediumContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 480) ? this.smallContainerOptions = true : this.smallContainerOptions = false; 
  }

  employeesSubscription: Subscription;
  groupPermissionSubscriber: Subscription;

  groupFocusIndex = 0;
  groupOptions: Array<any> = [];
  filteredWorkOrders: Array<any> = [];

  addWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;
  workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;

  employees: Array<Profile> = [];
  employeeGroup: Array<Profile> = [];
  public groupPermissions: Array<GroupPermissions> = [];

  activeFocusId: string = "all";

  view: CalendarView = CalendarView.Month;
  viewMobileSwitchMenu: boolean = false;
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  activeDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<span class="material-icons-outlined actionIcon">description</span>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log("Work Order Clicked: ", event);
        this.openWorkOrderDialog(event.meta);
        // this.handleEvent('Edited', event);
      },
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    // LEFT FOR EXAMPLES DURING DEVELOPMENT
    // DELETE FOR PRODUCTION
    // {
    //   start: new Date(2021, 11, 1, 0,0,0),
    //   end: new Date(2021, 11, 1, 2,0,0),
    //   title: 'A 3 day event',
    //   color: colors.red,
    //   actions: this.actions,
    //   allDay: false,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
    // {
    //   start: startOfDay(new Date()),
    //   title: 'An event with no end date',
    //   color: colors.yellow,
    //   actions: this.actions,
    // },
    // {
    //   start: subDays(endOfMonth(new Date()), 3),
    //   end: addDays(endOfMonth(new Date()), 3),
    //   title: 'A long event that spans 2 months',
    //   color: colors.blue,
    //   allDay: true,
    // },
    // {
    //   start: addHours(startOfDay(new Date()), 2),
    //   end: addHours(new Date(), 2),
    //   title: 'A draggable and resizable event',
    //   color: colors.yellow,
    //   actions: this.actions,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // }
  ];

  activeDayIsOpen: boolean = false;

  constructor(private modal: NgbModal, public dialog: MatDialog, private navigatorService: NavigatorService, private changeDetectorRef: ChangeDetectorRef) { 
    
  }

  ngOnInit(): void {
    this.subscribeToEmployees();
    this.subscribeToGroupPermissions();
    this.initializeFilterSize();
    this.activeDate = new Date();

    this.dateChangeNextMonthEmitter.emit(this.activeDate);
  }

  ngAfterViewInit(): void {
    this.initializeFilterSize();
  }

  ngOnDestroy() {
    if(this.employeesSubscription) {
      this.employeesSubscription.unsubscribe();
    }

    if(this.groupPermissionSubscriber) {
      this.groupPermissionSubscriber.unsubscribe();
    }
  } 

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {  
    console.log("Asset Work Order Table Calendar Dynamic Data: ", changes);

    // if(changes && changes.workOrders)  {
    //   this.workOrders = changes.workOrders.currentValue;
    // } else {
    //   this.workOrders = [];
    // }

    console.log("Calendar Work Order Type: ", this.calendarType);
    console.log("Calendar Work Orders: ", this.workOrders);

    this.filterWorkOrders();
    this.updateEvents();
  }

  private initializeFilterSize(): void {
    (this.calendarContainer.nativeElement.clientWidth > 960) ? this.largeContainerOptions = true : this.largeContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 960 && this.calendarContainer.nativeElement.clientWidth > 480) ? this.mediumContainerOptions = true : this.mediumContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 480) ? this.smallContainerOptions = true : this.smallContainerOptions = false; 
  }

  subscribeToEmployees(): void {
    this.employeesSubscription = this.navigatorService.getAllEmployees().subscribe(data => { 
      
      if(data != undefined) {
        this.employees = [];

        this.employees = data
      }

      this.updateGroupFocusedEmployees();

      this.changeDetectorRef.markForCheck();
    });
  }

  getEmployees(): void {
    this.navigatorService.getAllEmployees();
  }

  subscribeToGroupPermissions(): void {
    this.groupPermissionSubscriber = this.navigatorService.getGroupPermissions().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;

      if(data != null) {
        console.log("Returned Group Permissions: ", data);
        this.groupPermissions = data;

        this.updateGroupOptions();
        this.updateGroupFocusedEmployees();
      }
    });
  }

  getGroupPermissions(): void {
    this.navigatorService.getGroupPermissions();
  }

  changeEmployeeFocus(employeeId: string): void {
    this.activeFocusId = employeeId;

    this.filterWorkOrders();
  }

  addGroupsToCalendarEvents(): void {
    for(let index = 0; index < groups.length; index++) {
      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events.push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date
        });
      }
    }
  }

  updateGroupOptions(): void {
    this.groupOptions = [];

    for(let group of this.groupPermissions) {
      this.groupOptions.push(group.group);
    }
  }

  updateEvents(): void {
    this.events = [...[]];

    for(let workOrderIndex: number = 0; workOrderIndex < this.filteredWorkOrders.length; workOrderIndex++) {

      let startDate: Date = new Date(this.filteredWorkOrders[workOrderIndex].dateScheduled);
      let timeRequired: number = this.filteredWorkOrders[workOrderIndex].timeStretch;
      let endDateMiliseconds: number = startDate.getTime() + (timeRequired * 60 * 1000);

      let endDate: Date = new Date(endDateMiliseconds);

      // endDate.setMinutes(endDate.getMinutes() + this.filteredWorkOrders[workOrderIndex].timeStretch);

      if(this.calendarType != this.calendarTypes.FIELD_TECH) {
        this.events.push({
          title: this.filteredWorkOrders[workOrderIndex].title + ": " + this.filteredWorkOrders[workOrderIndex].description,
          color: {
            primary: '#ad2121',
            secondary: '#FAE3E3',
          },
          start: new Date(this.filteredWorkOrders[workOrderIndex].originationDate),
          end: endDate,
          actions: this.actions,
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
          meta: this.filteredWorkOrders[workOrderIndex]
        });
      } else {
        this.events.push({
          title: this.filteredWorkOrders[workOrderIndex].custFirstName + ": " + this.filteredWorkOrders[workOrderIndex].description,
          color: {
            primary: '#ad2121',
            secondary: '#FAE3E3',
          },
          start: new Date(this.filteredWorkOrders[workOrderIndex].dateScheduled),
          end: endDate,
          actions: this.actions,
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
          meta: this.filteredWorkOrders[workOrderIndex]
        });
      }
    }

  }

  toggleMobileSwitchMenu(): void {
    if(this.viewMobileSwitchMenu)
      this.viewMobileSwitchMenu = false;
    else {
      this.viewMobileSwitchMenu = true;
    }
  }

  nextMonth(): void {
    if(this.view === CalendarView.Month) {
      console.log("Previous Date: ", this.activeDate);
      this.activeDate = addMonths(this.activeDate, 1);
      console.log("Change Date: ", this.activeDate);

      this.dateChangeNextMonthEmitter.emit(this.activeDate);
    }

    else if(this.view === CalendarView.Week) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addWeeks(this.activeDate, 1);
      console.log("Change Date: ", this.activeDate);
      
      if(tempMonth != this.activeDate.getMonth()) {
        this.dateChangeNextMonthEmitter.emit(this.activeDate);
      }
    }

    else if(this.view === CalendarView.Day) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addDays(this.activeDate, 1);
      console.log("Change Date: ", this.activeDate);
      

      if(tempMonth != this.activeDate.getMonth()) {
        this.dateChangeNextMonthEmitter.emit(this.activeDate);
      }
    }
  }

  previousMonth(): void {
    if(this.view === CalendarView.Month) {
      console.log("Previous Date: ", this.activeDate);
      this.activeDate = addMonths(this.activeDate, -1);
      console.log("Change Date: ", this.activeDate);
      
      this.dateChangePreviousMonthEmitter.emit(this.activeDate);
    }

    else if(this.view === CalendarView.Week) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addWeeks(this.activeDate, -1);
      console.log("Change Date: ", this.activeDate);
      

      if(tempMonth != this.activeDate.getMonth()) {
        this.dateChangePreviousMonthEmitter.emit(this.activeDate);
      }
    }

    else if(this.view === CalendarView.Day) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addDays(this.activeDate, -1);
      console.log("Change Date: ", this.activeDate);
      

      if(tempMonth != this.activeDate.getMonth()) {
        this.dateChangePreviousMonthEmitter.emit(this.activeDate);
      }
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {

    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });

    this.updateWorkOrder(event.meta, newStart, newEnd);
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  updateWorkOrder(workOrder: any, startDate: Date, endDate: Date) {

    let update = ({
      workOrder: workOrder,
      startDate: startDate,
      endDate: endDate
    });

    console.log("Work Order Emitter");
    this.workOrderDateChangedEmitter.emit(update);
  }

  setGroupFilter(groupIndex: number) {
    console.log("Group Filter: ", groupIndex);
    this.groupFocusIndex = groupIndex;

    this.updateGroupFocusedEmployees();
  }

  updateGroupFocusedEmployees(): void {
    this.employeeGroup = [];

    for(let employeeIndex: number = 0; employeeIndex < this.employees.length; employeeIndex++) {

      // If the focused on group contains the current employee then add it to it's array
      if(this.groupPermissions.length > 0 && this.groupPermissions[this.groupFocusIndex].employeeIds.indexOf(this.employees[employeeIndex].id) > -1) {
        this.employeeGroup.push(this.employees[employeeIndex]);
      }
    }

    this.filterWorkOrders();
  }

  async filterWorkOrders(): Promise<void> {
    this.filteredWorkOrders = [];

    if(this.activeFocusId == "all") {
      for(let employeeIndex: number = 0; employeeIndex < this.employeeGroup.length; employeeIndex++) {

        for(let workOrder of this.workOrders) {

          if(this.activeFocusId == "all") {
            if(workOrder.technicianId == this.employeeGroup[employeeIndex].id) {
              // this.employeeGroup.push(this.employees[employeeIndex]);
              this.filteredWorkOrders.push(workOrder);
            }
          }
        }

      }

      // Unassigned Work Orders
      for(let workOrder of this.workOrders) {

        if(

          (workOrder?.technicianId == undefined || 
          workOrder?.technicianId == null) &&

          (workOrder?.assignedStaff == undefined || 
            workOrder?.assignedStaff == null || 
            workOrder?.assignedStaff.length >= 0) &&

          (workOrder?.assignedTeams == undefined || 
            workOrder?.assignedTeams == null || 
            workOrder?.assignedTeams.length >= 0)
        
        ) {
          this.filteredWorkOrders.push(workOrder);
        }

      }

    } else {
      let isContinue: boolean = true;

      for(let workOrder of this.workOrders) {
        isContinue = true;

        if(workOrder.assignedStaff == undefined) {
          workOrder.assignedStaff = [];
        }

        if(workOrder.assignedTeams == undefined) {
          workOrder.assignedTeams = [];
        }

        if(workOrder.technicianId == this.activeFocusId) {
          // this.employeeGroup.push(this.employees[employeeIndex]);
          this.filteredWorkOrders.push(workOrder);
          isContinue = false;
        }

        if(isContinue) {
          for(let staff of workOrder?.assignedStaff) {

            if(staff == this.activeFocusId) {
              this.filteredWorkOrders.push(workOrder);
              isContinue = false;
              break;
            }

          }
        }

        if(isContinue) {
          for(let team of workOrder?.assignedTeams) {

            if(isContinue) {

              await this.navigatorService.getGroupPermission(team).then( (group: GroupPermissions) => {

                for(let staff of group.employeeIds) {

                  if(staff == this.activeFocusId) {
                    this.filteredWorkOrders.push(workOrder);
                    isContinue = false;
                    break;
                  }

                }

              });

            }

          }
        }
        
      }
    }

    this.updateEvents();
  }

  addEvent(date: Date): void {
    let startDate = new Date(date);
    let endDate = new Date(date);
    endDate.setHours(endDate.getHours() + 1);

    this.events.push(
      {
        title: 'Admin',
        start: startDate,
        end: endDate,
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    )
  }

  contextMenuAddWorkOrder(date: Date, option): void {
    // this.events = [];

    
    this.openAddWorkOrderDialog(date);

    this.refresh.next();
  }

  // LEFT FOR EXAMPLES DURING DEVELOPMENT
  // DELETE FOR PRODUCTION
  // contextMenuAddEvent(date: Date): void {
  //   this.events.push({
  //     start: date,
  //     title: 'New event',
  //     color: colors.red,
  //   });
  //   this.refresh.next();
  // }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
      // Do Something
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
      // Do Something
    }

    this.activeDayIsOpen = false;
  }

  public openWorkOrderDialog(workOrder: AssetWorkOrder) {
    this.workOrderDialogRef = this.dialog.open(
      VendorWorkOrderDialogComponent,
      { data: workOrder }
    );

    this.workOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      this.workOrderDataChangedEmitter.emit(data);

      // this.files.push({ name, content: ''});
    })
  }

  public openAddWorkOrderDialog(date: any) {
    this.addWorkOrderDialogRef = this.dialog.open(
      VendorAddWorkOrderDialogComponent,
      {
        data: {
          date: date,
          address: this.incomingAddress
        }
      }
    );

    this.addWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed", data.workOrderData);

      this.navigatorService.updateWorkOrder(data.workOrderData);

      if(data) {
        this.addEvent(data.workOrderData.originationDate);
      }

      // this.files.push({ name, content: ''});
    })
  }
}
