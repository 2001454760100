import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, Inject  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { InventoryItem, PartAttachBundle, Profile, ScheduleSwap, WorkOrder } from '../../../data-models/models';

import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-vendor-work-audit-tracker-dialog.component',
    templateUrl: 'vendor-work-audit-tracker-dialog.component.html',
    styleUrls: ['vendor-work-audit-tracker-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorWorkAuditTrackerDialogComponent implements OnInit {

    public isPartRequest: boolean = false;
    public isDetailView: boolean = false;
    public detailViewFocusBarcode: string = "";
    public attachedParts: Array<PartAttachBundle> = [];
    public workOrder: WorkOrder = null;

    constructor(
      @Inject(MAT_DIALOG_DATA) public incomingData: any,
      private navigatorService: NavigatorService, 
      private fb: FormBuilder,
      public datePipe: DatePipe,
      public dialogRef: MatDialogRef<VendorWorkAuditTrackerDialogComponent>) {

    }
  
    ngOnInit() {

      if(this.incomingData != null && this.incomingData != undefined) {
        this.workOrder = this.incomingData;
      }

      this.dialogRef.beforeClosed().subscribe(data => {
        this.close();
      });

    }
  
    submit() {
    }

    public reset(): void {

    }

    public close() {
      this.dialogRef.close(this.attachedParts);
    }
  }