import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CRMDeal, FormDocument, FormDocumentExtAccess, WorkOrder } from 'src/app/data-models/models';
import { FormTypes, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { FormPages, PageChangeEvent } from 'src/app/vendor/forms/forms.component';

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-vendor-form-templates',
  templateUrl: 'vendor-form-templates.component.html',
  styleUrls: ['vendor-form-templates.component.scss']
})
export class VendorFormTemplatesComponent implements OnInit {
  @ViewChild('extAccessTable') extAccessTableRef: ElementRef;  
  @ViewChild('docPreview') docPreviewRef: ElementRef;  

  @Input() nativeFormPage: boolean = true; 
  @Input() deal: CRMDeal = null;
  @Input() workOrder: WorkOrder = null;
  @Input() workOrderBuilder: boolean = false;
  @Input() extAccessDisplay: boolean = true;
  @Input() altLayout: boolean = false;

  @Output() selectionClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChange: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();
  
  public formTypes: any = FormTypes;
  public formPages: any = FormPages;

  public documents: Array<FormDocument> = [];
  public templates: Array<FormDocument> = [];

  public previewZoomLvl: number = 100;

  public focusDoc: FormDocument = null;
  public editAccessPriviledge: FormDocumentExtAccess = null;

  public accessPanel: boolean = false;
  public accessControlForm: FormGroup;

  private activeDocVersion: FormDocument = null;

  constructor( 
    public navigationService: NavigatorService, 
    private twilioHandler: TwilioHandlerService,
    private snackBar: MatSnackBar,
    public fb: FormBuilder) { }

  ngOnInit() {

    this.accessControlForm = this.fb.group({
      email: [ { value: '', disabled: false }, Validators.compose([Validators.required, emailValidator])],
      phone: [ { value: '', disabled: false }, Validators.compose([Validators.required, Validators.minLength(10)])],
      expireDate: [ { value: new Date(), disabled: true }, Validators.compose([Validators.required, Validators.minLength(3)])]
    });


    this.getAllTemplates();

    if(this.workOrderBuilder) {
      this.getAllWorkOrderDocVersions();
    } else {
      this.getAllDocuments();
    }

  }

  private calcDocPreviewZoomLvl(): void {
    let self = this;

    this.previewZoomLvl = null;
    let previewWidth: number = this.docPreviewRef.nativeElement.clientWidth;
    let defaultDocWidth: number = 75 * 16;
    let padding: number = 5;

    let zoomLvl = (previewWidth / defaultDocWidth) * 100;
    zoomLvl -= padding;

    setTimeout(function() {
      self.previewZoomLvl = zoomLvl;
    }, 10);
    
  }

  private initializeActiveVersion(): void {

    this.activeDocVersion = this.documents.find( (document: FormDocument) => {

      if(document.activeWorkOrderVersion == undefined || document.activeWorkOrderVersion == null) {
        return false;
      } else {
        return document.activeWorkOrderVersion;
      }

    });

  }

  private getAllWorkOrderDocVersions(): void {

    this.navigationService.getAsyncWorkOrderVersions().then( (documents: Array<FormDocument>) => {

      this.documents = documents;

      this.initializeActiveVersion();

      // Test Data - REMOVE FOR PRODUCTION
      for(let index: number = 0; index < 0; index++) {
        let template: FormDocument = {
          id: this.navigationService.generateKey(),
          companyId: this.navigationService.getCompanyId(),
          title: "Test",
          backgroundColor: "",
          margin: 0,
          type: 0,
        
          // deal?: string,
          // workOrder?: string,
          // invoice?: string,
        
          header: {
            id: this.navigationService.generateKey(),
            active: false,
            height: 0,
            elements: []
          },
          footer: {
            id: this.navigationService.generateKey(),
            active: false,
            height: 0,
            elements: []
          },

          audit: [],
          signVersions: [],
        
          pages: [],
          activityAuditTrail: [],
          builderAuditTrail: [],
        
          extAccess: false,
          signatureAccessPriviledge: [],
          extAccessPriviledge: [],
          dateLastUpdated: new Date(),
          createdBy: this.navigationService.getProfileId(),
          created: new Date()
        }

        if(index % 3 == 0) {
          template.title = "Test;klja;lkjdlkfj ;aldfjlkasdlf;kjaslkdfjasdl kfjasdf;lllkjals;dkfjl;akjdflkasjdf;lajsdf;s";
        }

        for(let accessIndex: number = 0; accessIndex < 5; accessIndex++) {
          template.extAccessPriviledge.push({
            id: this.navigationService.generateKey(),
            email: "somthing@gmail.com",
            phone: "1234567890",
            code: "123abc",

            createdBy: this.navigationService.getProfileId(),
            createdDate: new Date(),
            expireDate: new Date(),
          })
        };

        this.templates.push(template);
      }


    });

  }

  private getAllTemplates(): void {

    this.navigationService.getAsyncTemplates().then( (templates: Array<FormDocument>) => {

      this.templates = templates;

      // Test Data - REMOVE FOR PRODUCTION
      for(let index: number = 0; index < 0; index++) {
        let template: FormDocument = {
          id: this.navigationService.generateKey(),
          companyId: this.navigationService.getCompanyId(),
          title: "Test",
          backgroundColor: "",
          margin: 0,
          type: 0,
        
          // deal?: string,
          // workOrder?: string,
          // invoice?: string,
        
          header: {
            id: this.navigationService.generateKey(),
            active: false,
            height: 0,
            elements: []
          },
          footer: {
            id: this.navigationService.generateKey(),
            active: false,
            height: 0,
            elements: []
          },

          audit: [],
          signVersions: [],
        
          pages: [],
          activityAuditTrail: [],
          builderAuditTrail: [],
        
          extAccess: false,
          signatureAccessPriviledge: [],
          extAccessPriviledge: [],
          dateLastUpdated: new Date(),
          createdBy: this.navigationService.getProfileId(),
          created: new Date()
        }

        if(index % 3 == 0) {
          template.title = "Test;klja;lkjdlkfj ;aldfjlkasdlf;kjaslkdfjasdl kfjasdf;lllkjals;dkfjl;akjdflkasjdf;lajsdf;s";
        }

        for(let accessIndex: number = 0; accessIndex < 5; accessIndex++) {
          template.extAccessPriviledge.push({
            id: this.navigationService.generateKey(),
            email: "somthing@gmail.com",
            phone: "1234567890",
            code: "123abc",

            createdBy: this.navigationService.getProfileId(),
            createdDate: new Date(),
            expireDate: new Date(),
          })
        };

        this.templates.push(template);
      }


    });

  }

  private getAllDocuments(): void {

    this.navigationService.getAsyncDocuments().then( (documents: Array<FormDocument>) => {

      this.documents = documents;


      // Test Data - REMOVE FOR PRODUCTION
      for(let index: number = 0; index < 0; index++) {
        let document: FormDocument = {
          id: this.navigationService.generateKey(),
          companyId: this.navigationService.getCompanyId(),
          title: "Test",
          backgroundColor: "",
          margin: 0,
          type: 0,
        
          // deal?: string,
          // workOrder?: string,
          // invoice?: string,
        
          header: {
            id: this.navigationService.generateKey(),
            active: false,
            height: 0,
            elements: []
          },
          footer: {
            id: this.navigationService.generateKey(),
            active: false,
            height: 0,
            elements: []
          },

          audit: [],
          signVersions: [],
        
          pages: [],
          activityAuditTrail: [],
          builderAuditTrail: [],
        
          extAccess: false,
          signatureAccessPriviledge: [],
          extAccessPriviledge: [],
          dateLastUpdated: new Date(),
          createdBy: this.navigationService.getProfileId(),
          created: new Date(),
        }

        if(index % 3 == 0) {
          document.title = "Test;klja;lkjdlkfj ;aldfjlkasdlf;kjaslkdfjasdl kfjasdf;lllkjals;dkfjl;akjdflkasjdf;lajsdf;s";
        }

        for(let accessIndex: number = 0; accessIndex < 5; accessIndex++) {
          document.extAccessPriviledge.push({
            id: this.navigationService.generateKey(),
            email: "somthing@gmail.com",
            phone: "1234567890",
            code: "123abc",

            createdBy: this.navigationService.getProfileId(),
            createdDate: new Date(),
            expireDate: new Date(),
          })
        };

        this.documents.push(document);
      }


    });

  }

  public searchDocumentTitle(search: any) {

    if(this.workOrderBuilder) {
      
      this.navigationService.searchAsyncWorkOrderVersions(search).then( (documents: Array<FormDocument>) => {

        this.documents = documents;
  
      });

    } else {

      this.navigationService.searchAsyncDocuments(search).then( (documents: Array<FormDocument>) => {

        this.documents = documents;
  
      });

    }

  }

  public searchTemplateTitle(search: any) {

    this.navigationService.searchAsyncTemplates(search).then( (templates: Array<FormDocument>) => {

      this.templates = templates;

    });

  }

  public openDocument(doc: FormDocument): void {
    this.pageChange.emit({
      page: this.formPages.BUILDER,
      value: {
        document: doc.id,
        template: null
      }
    })
  }

  public openTemplate(doc: FormDocument): void {
    let templateId = "locked_blank";

    if(doc != undefined && doc != null) {
      templateId = doc.id;
    }

    this.pageChange.emit({
      page: this.formPages.BUILDER,
      value: {
        document: null,
        template: templateId
      }
    })
  }

  public changeDocumentFocus(doc: FormDocument): void {
    this.focusDoc = doc;
    this.calcDocPreviewZoomLvl();
    this.closeAccessPanel();
  }

  public allowExtAccessChange(toggle: boolean): void {

    console.log("Allow Ext. Access: ", toggle);

    this.focusDoc.extAccess = toggle;

    this.navigationService.updateDocument(this.focusDoc).then( (status: boolean) => {

      if(status) {

        if(this.focusDoc.extAccess) {
          this.snackBar.open('The gates are open and you\'re free to share!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.snackBar.open('The gates are shut and nobody can get in!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        }

      } else {
        this.snackBar.open('Sorry, but there was an issue processing your request. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    });
  }

  public openAccessPanel(): void {
    this.accessPanel = true;
  }

  public closeAccessPanel(): void {
    this.accessPanel = false;
    this.editAccessPriviledge = null;
    this.accessControlForm.reset();
  }

  public editAccess(priviledge: FormDocumentExtAccess): void {
    this.accessControlForm.controls["email"].setValue(priviledge.email);
    this.accessControlForm.controls["phone"].setValue(priviledge.phone);
    this.accessControlForm.controls["expireDate"].setValue(priviledge.expireDate);

    this.editAccessPriviledge = priviledge;
    this.openAccessPanel();
    this.extAccessTableRef.nativeElement.scrollTop = 0;
  }

  public deleteAccess(priviledge: FormDocumentExtAccess): void {
    let priviledgeIndex: number = this.focusDoc.extAccessPriviledge.findIndex( (access: FormDocumentExtAccess) => { return access.id == priviledge.id });

    if(priviledgeIndex > -1) {
      this.focusDoc.extAccessPriviledge.splice(priviledgeIndex, 1);
    }

    this.navigationService.updateDocument(this.focusDoc).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Access Removed!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.snackBar.open('There was an issue with your request, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

    });

  }

  public saveNewPermission(): void {

    if(this.accessControlForm.valid) {

      let email: string = this.accessControlForm.controls["email"].value;
      let phone: string = this.accessControlForm.controls["phone"].value;
      let expireDate: Date = this.accessControlForm.controls["expireDate"].value;
      let code: string = "12345"

      if(this.editAccessPriviledge == undefined || this.editAccessPriviledge == null) {
        this.focusDoc.extAccessPriviledge.push({
          id: this.navigationService.generateKey(),
          email: email,
          phone: phone,
          code: code,
        
          createdBy: this.navigationService.getProfileId(),
          createdDate: new Date(),
          expireDate: expireDate
        });
      } else {
        let accessControl: FormDocumentExtAccess = this.focusDoc.extAccessPriviledge.find( (access: FormDocumentExtAccess) => { return access.id == this.editAccessPriviledge.id});

        if(accessControl != undefined && accessControl != null) {
          accessControl.email = email;
          accessControl.phone = phone;
          accessControl.expireDate = expireDate;
        }
      }

      console.log("Access Granted: ", this.focusDoc.extAccessPriviledge);
      this.navigationService.updateDocument(this.focusDoc).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Access Granted!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

          if(this.editAccessPriviledge == null) {
            this.twilioHandler.sendDocumentAccessEmail(this.focusDoc.id, code, email);
          }

        } else {
          this.snackBar.open('There was an issue with your request, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

      });

      this.closeAccessPanel();
    } else {
      this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public templateSelection(template: FormDocument): void {

    if(this.nativeFormPage) {
      this.openTemplate(template);
    } else {

      if(template != undefined && template != null) {
        this.selectionClick.emit(template.id);
      } else {
        this.selectionClick.emit("locked_blank");
      }

    }
    
  }

  public createNewTemplate(): void {
    this.selectionClick.emit();
  }

  public activeVersion(doc: FormDocument): void {

    if(this.activeDocVersion != null) {
      this.activeDocVersion.activeWorkOrderVersion = false;
    }

    doc.activeWorkOrderVersion = true;

    this.navigationService.updateWorkOrderVersion(doc).then( (status: boolean) => {

      if(status) {
        this.snackBar.open('Work Order Version Changed!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }

    });

    this.navigationService.updateWorkOrderVersion(this.activeDocVersion).then( (status: boolean) => {

      if(status) {
        // Do Nothing
      }

    });


    this.activeDocVersion = doc;

  }

}