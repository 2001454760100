import { Component, OnInit, AfterViewInit, OnDestroy, Input, Inject } from '@angular/core';
import { Color } from 'ng2-charts';
import { DOM_SERVER } from 'src/app/services/dom-service';

export enum CrossModuleChartTypes {
  LINE = 0,
  BAR = 1,
  DOUGHNUT = 2,
  RADAR = 3,
  PIE = 4,
  POLAR_AREA = 5,
  BUBBLE = 6,
  SCATTER = 7,
  DYNAMIC = 8,
  FINANCIAL = 9
}

export enum CrossModuleChartDataCategory {
  WORK_ORDERS = 0,
  INVOICES = 1,
  DEAL = 2,
  PROJECT = 3,
  FORMS = 4
}

export interface CrossModuleChart {
  id: string,
  type: number,
  calculation: ChartCalculation,
  days: number
}

export interface ChartCalculation {
  id: string,
  calculation: string,  // (x + x) * x - x Use 'x' as the placeholder to insert [data]
  data: Array<ChartDataType>
}

export interface ChartDataType {
  type: number,
  field: string
}


@Component({
  selector: 'app-vendor-cross-module-report',
  templateUrl: 'vendor-cross-module-report.component.html',
  styleUrls: ['vendor-cross-module-report.component.scss']
})

export class VendorCrossModuleReportComponent implements OnInit, AfterViewInit {

  @Input() chart: CrossModuleChart = null;

  public chartTypes: any = CrossModuleChartTypes;

  // Bar Chart
  public RI_labels: Array<string> = ['Extreme Heating & Cooling', 'Bob\'s Roofing', 'Arctic Air'];
  public RI_data: any = [

      { data: 350, label: '' }, 
      { data: 450, label: '' }, 
      { data: 100, label: '' }

  ];

  public RI_colors: Color[] = [
    {
      backgroundColor:["rgba(75, 161, 44, 0.5)", "rgba(210, 164, 25, 0.5)", "rgba(208, 23, 23, 0.5)", "rgba(208, 23, 23, 0.5)"]
    }
  ];


  // Line Chart
  public RT_labels: Array<string> = ['February', 'March', 'April', 'May', 'June', 'July'];
  public RT_data: any = {
    data: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Extreme Heating & Cooling' },
      { data: [192, 134, 240, 181, 265, 255, 174], label: 'Bob\'s Roofing' },
      { data: [77, 23, 45, 60, 65, 40, 81], label: 'Arctic Air' }
    ]
  };

  public RT_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    },
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    },
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];


  private PL_profits: number = 0;
  private PL_profjected: number = 0;
  private PL_missed_earnings: number = 0;


  // Doughnut Chart
  public PL_labels: Array<string> = ['Profit', 'Projected', 'Missed Earnings'];
  public PL_data: Array< Array<any> > | Array<any> = [ 
    // [0, 0, 0]
    [50, 150, 120],
    // [250, 130, 70],
  ];

  public PL_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];

  constructor(@Inject(DOM_SERVER) public token: any) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }



}