import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address, CustomCalendarEvent, LatLng, POC } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { EventStatus } from '../../professional/vendor-multi-purpose-calendar/vendor-multi-purpose-calendar.component';

enum TimePickerTypes {
  SCHEDULED = 0,
  EST_COMPLETE = 1
}

@Component({
  selector: 'app-vendor-calendar-event-panel',
  templateUrl: 'vendor-calendar-event-panel.component.html',
  styleUrls: ['vendor-calendar-event-panel.component.scss']
})


export class VendorCalendarEventPanelComponent implements OnInit {
  @Input() event: CustomCalendarEvent = null;
  @Input() date: Date = new Date();

  public assignedStaff: Array<string> = [];
  public assignedTeams: Array<string> = [];

  public eventForm: FormGroup;
  public pocForm: FormGroup;
  public locationForm: FormGroup;

  public eventStatus: any = EventStatus;

  public assignedPocs: Array<POC> = [];

  public incomingAddress: Address = null;
  public assignedAddress: Address = null;
  public addressLatLng: LatLng = null;
  public incomingLatLng: LatLng = null;

  public contactPanelExpanded: boolean = false;
  public addressPanelExpanded: boolean = false;
  public staffPanelExpanded: boolean = false;

  public timePickerTypes: any = TimePickerTypes;
  public timePickerFocus: number = this.timePickerTypes.SCHEDULED;
  public startTimeScheduled: any;
  public endTimeScheduled: any;
  public dateScheduled: Date;
  public endDateScheduled: Date;

  public autoMinuteAdjusted: boolean = false;

  constructor(
    private navigatorService: NavigatorService,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder) { }

  ngOnInit() {

    let start: Date = new Date(this.date);
    let end: Date = new Date(this.date);

    end.setMinutes(start.getMinutes() + 30);

    this.eventForm = this.formBuilder.group({
  
      title: [ {value: '', disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)]) ],
      assignedStaff: [ { value: this.assignedStaff, disabled: false } ],
      assignedTeams: [ { value: this.assignedTeams, disabled: false } ],
      assignedGuests: [ { value: [], disabled: false } ],
      organization: [ { value: '', disabled: false } ],
      desc: [ { value: null, disabled: false }],
      notes: [ { value: '', disabled: false }],
      startDate: [ { value: start, disabled: true }, Validators.required ],
      endDate: [ { value: end, disabled: true }, Validators.required ],
      startTime: [ { value: "", disabled: false }, Validators.required ],
      endTime: [ { value: "", disabled: false }, Validators.required ],
      timeStretch: [ { value: 0, disabled: true }, Validators.required ],
      allDay: [ { value: false, disabled: false } ],

      attachments: [ { value: [], disabled: false } ],
      status: [ { value: 0, disabled: true } ],

      notification: [ { value: new Date, disabled: false } ]
    });

    this.pocForm = this.formBuilder.group({
      first: [ {value: '', disabled: false}, Validators.compose([ Validators.required, Validators.minLength(3) ]) ],
      last: [ {value: '', disabled: false}, Validators.compose([ Validators.required, Validators.minLength(3) ]) ],
      email: [ {value: '', disabled: false}, 
        Validators.compose([
        Validators.required, 
        Validators.minLength(3), 
        Validators.email]) ],

      phone: [null, Validators.compose([ Validators.required ])]
    });

    this.locationForm = this.formBuilder.group({
      street: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      city: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      state: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(2)])],
      zip: ['', Validators.compose([
        Validators.required, 
        Validators.minLength(5),
        Validators.pattern('[0-9]*') ])]
    });


    if(this.event != null) {
      this.initializeEventForm();
    }
    
  }

  private initializeEventForm(): void {

    this.eventForm.controls['title'].setValue(this.event.title);
    this.eventForm.controls['assignedStaff'].setValue(this.event.assignedStaff);
    this.eventForm.controls['assignedTeams'].setValue(this.event.assignedTeams);
    this.eventForm.controls['assignedGuests'].setValue(this.event.assignedGuests);
    this.eventForm.controls['organization'].setValue(this.event.organization);
    this.eventForm.controls['desc'].setValue(this.event.desc);
    this.eventForm.controls['notes'].setValue(this.event.notes);
    this.eventForm.controls['startDate'].setValue(this.event.start);
    this.eventForm.controls['endDate'].setValue(this.event.end);
    this.eventForm.controls['startTime'].setValue(this.event.startTime);
    this.eventForm.controls['endTime'].setValue(this.event.endTime);
    this.eventForm.controls['timeStretch'].setValue(this.event.timeStretch);
    this.eventForm.controls['allDay'].setValue(this.event.allDay);
    this.eventForm.controls['attachments'].setValue(this.event.attachments);
    this.eventForm.controls['notification'].setValue(this.event.notification);

    this.assignedStaff = this.event.assignedStaff;
    this.assignedTeams = this.event.assignedTeams;
    
  }

  public expandContactPanel(): void {
    this.contactPanelExpanded = true;
  }

  public collapseContactPanel(): void {
    this.contactPanelExpanded = false;
  }

  public expandAddressPanel(): void {
    this.addressPanelExpanded = true;
  }

  public collapseAddressPanel(): void {
    this.addressPanelExpanded = false;
  }

  public assignNewContact(): void {

    if(this.pocForm.valid) {
      let poc: POC = {
        id: this.navigatorService.generateKey(),
        first: this.pocForm.get("first").value,
        last: this.pocForm.get("last").value,
        email: this.pocForm.get("email").value,
        phone: this.pocForm.get("phone").value
      }

      this.assignedPocs.push(poc);
      this.collapseContactPanel();
    } else {
      this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public cancelNewContact(): void {
    this.pocForm.reset();
    this.collapseContactPanel();
  }

  public removeContact(id: string): void {

    let contactIndex: number = this.assignedPocs.findIndex( (poc: POC) => { return poc.id == id });

    if(contactIndex > -1) {
      this.assignedPocs.splice(contactIndex, 1);
    } else {
      this.snackBar.open('There was an error trying to remove this contact!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }


  public expandStaffPanel(): void {
    this.staffPanelExpanded = true;
  }

  public collapseStaffPanel(): void {
    this.staffPanelExpanded = false;
  }

  public assignNewAddress(): void {

    if(this.locationForm.valid) {
      let address: Address = {
        street: this.locationForm.get("street").value,
        city: this.locationForm.get("city").value,
        state: this.locationForm.get("state").value,
        zip: this.locationForm.get("zip").value
      }

      this.assignedAddress = address;
      this.addressLatLng = this.incomingLatLng;
      this.collapseAddressPanel();
    } else {
      this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public cancelNewAddress(): void {
    this.locationForm.reset();
    this.collapseAddressPanel();
  }

  stringTimeToDate(time: string, date: Date): Date {

    if(
      date == undefined || 
      date == null) {
      return null;
    }

    if(
      time == undefined || 
      time == null) {

        time = "";

    }

    let reconstructedDate: Date = date;

    if(time.length > 0) {
      let parts, hours, minutes,
      timeReg = /(\d+)\:(\d+) (\w+)/;
      parts = time.match(timeReg);
      
      hours = /am/i.test(parts[3]) ?
          function(am) {return am < 12 ? am : 0}(parseInt(parts[1], 10)) :
          function(pm) {return pm < 12 ? pm + 12 : 12}(parseInt(parts[1], 10));
      
      minutes = parseInt(parts[2], 10);

      reconstructedDate.setHours(hours);
      reconstructedDate.setMinutes(minutes);
      
      console.log(time + ' => ' + reconstructedDate);
    }

    return reconstructedDate;
  }

  updateScheduleDate(): void {

    if(this.eventForm.get("startDate").value != undefined && !this.autoMinuteAdjusted) {
      let startDate: Date = new Date(this.eventForm.get("startDate").value);

      this.dateScheduled = this.stringTimeToDate(this.startTimeScheduled, startDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  updateEndDate(): void {

    if(this.eventForm.get("endDate").value != undefined && !this.autoMinuteAdjusted) {
      let endDate: Date = new Date(this.eventForm.get("endDate").value.setHours(0, 0, 0, 0));

      this.endDateScheduled = this.stringTimeToDate(this.endTimeScheduled, endDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  public changeTimePicker(picker: number): void {

    if(this.timePickerFocus != picker) {
      this.timePickerFocus = picker;
    }

  }

  updateScheduleTime(scheduledTime): void {
    this.eventForm.get("startTime").setValue(scheduledTime);
    this.startTimeScheduled = scheduledTime;
    this.updateScheduleDate();

    console.log("Date Scheduled: ", this.dateScheduled);
  }

  updateEndTime(endTime): void {
    this.eventForm.get("endTime").setValue(endTime);
    this.endTimeScheduled = endTime;
    this.updateEndDate();

    console.log("Date End: ", this.endTimeScheduled);
  }

  setEndTime(): void {

    let hours: number = this.endDateScheduled.getHours();
    let minutes: number = this.endDateScheduled.getMinutes();
    let ampm: string = "AM";

    if(hours > 12) {
      ampm = "PM";
      hours -= 12;
    }

    this.endTimeScheduled = hours + ":" + minutes + " " + ampm;
    console.log("End Time: ", this.endTimeScheduled);

  }

  minutesRequiredChange(minutes: number): void {
    
    this.eventForm.get("timeStretch").setValue(minutes);
    console.log("Minutes Req. Update: ", minutes);

    this.autoUpdateEndDateByMinutes();

  }

  autoUpdateEndDateByMinutes(): void {
    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null) {
        this.dateScheduled = new Date();
    }

    if(
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        this.endDateScheduled = new Date();
    }


    let startMili: number = this.dateScheduled.getTime();
    let minutes: number = Number.parseInt(this.eventForm.get("timeStretch").value);
    let minutesRegMili: number = (minutes * 1000) * 60;

    let diffMili: number = minutesRegMili + startMili;

    this.endDateScheduled.setTime(diffMili);

    console.log("Auto Date Start: ", this.dateScheduled); 
    console.log("Auto Date End: ", this.endDateScheduled);   

    this.autoMinuteAdjusted = true;
    this.eventForm.get("startDate").setValue(this.dateScheduled);

    this.autoMinuteAdjusted = true;
    this.eventForm.get("endDate").setValue(this.endDateScheduled);

    this.setEndTime();
  }

  updateMinutesRequired(): void {

    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null || 
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        return;
    }


    let startMili: number = this.dateScheduled.getTime();
    let endMili: number = this.endDateScheduled.getTime();

    let diffMili: number = endMili - startMili;

    let seconds: number = diffMili / 1000;
    let minutes: number = seconds / 60;

    this.eventForm.get("timeStretch").setValue(minutes);

    console.log("Minute Diff: ", minutes);
  }

  public assignedStaffUpdate(staff: Array<string>): void {
    this.assignedStaff = staff;
  }

  public assignedTeamsUpdate(teams: Array<string>): void {
    this.assignedTeams = teams;
  }


  public saveEvent(): void {
    
    if(this.eventForm.valid) {

      let event: CustomCalendarEvent = {
        id: this.navigatorService.generateKey(),
        type: 0,
        title: this.eventForm.get("title").value,
        assignedStaff: this.assignedStaff,
        assignedTeams: this.assignedTeams,
        assignedGuests: this.assignedPocs,
        organization: this.eventForm.get("organization").value,
        desc: this.eventForm.get("desc").value,
        notes: this.eventForm.get("notes").value,
        start: this.eventForm.get("startDate").value,
        end: this.eventForm.get("endDate").value,
        startTime: this.eventForm.get("startTime").value,
        endTime: this.eventForm.get("endTime").value,
        timeStretch: this.eventForm.get("timeStretch").value,
        allDay: this.eventForm.get("allDay").value,

        address: this.assignedAddress,
        addressLatLng: this.addressLatLng,

        attachments: this.eventForm.get("attachments").value,
        status: this.eventForm.get("status").value,
        notification: this.eventForm.get("notification").value,
        
        color: undefined,
        actions: [],
        
        meta: undefined
      }

      this.navigatorService.upsertCalendarEvent(event).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Calendar Event Added!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.snackBar.open('There was an issue adding your calendar event. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

      });

    } else {
      this.snackBar.open('Missing Required Fields! We\'re loooking for completeness not quickness here.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }


  }

}