import {
  Component,
  Directive, 
  Input, 
  OnChanges, 
  OnInit,
  AfterViewInit,
  ElementRef, 
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ViewRef,
  HostBinding,
  Output
} from '@angular/core';

import { HostListener } from "@angular/core";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { fromEvent } from 'rxjs';
import { properties } from './cssProperties';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { ListMenu } from './listMenu/listMenu';

// TESTING: Adjustable Components
import { VendorCalendarComponent } from "../../shared/professional/vendor-calendar/vendor-calendar.component";
import { VendorEfficiencyAndWorkloadChartComponent } from "../../shared/professional/vendor-efficiency-and-workload-chart/vendor-efficiency-and-workload-chart.component";
import { ActiveBidExpandableTableComponent } from "../../shared/active-bid-expandable-table/active-bid-expandable-table.component";
import { VendorWorkOrderSchedulingWeekViewComponent } from "../../shared/professional/vendor-work-order-scheduling-week-view/vendor-work-order-scheduling-week-view.component";

enum resizeStateEnum {
  HORIZONTAL_LEFT = "horizontal-left",
  HORIZONTAL_RIGHT = "horizontal-right",
  VERTICAL_TOP = "vertical-top",
  VERTICAL_BOTTOM = "vertical-bottom",
  FREE_TOP_LEFT = "free-top-left",
  FREE_TOP_RIGHT = "free-top-right",
  FREE_BOTTOM_LEFT = "free-bottom-left",
  FREE_BOTTOM_RIGHT = "free-bottom-right"
}

enum dragStateEnum {
  DRAG_ENTERED = 1,
  DRAG_STARTED = 2,
  DRAG_ENDED = 3
}

interface CbListOptions<MetaType = any> {
  overrideTemplate: string;
  width: string;
  customCss: string;
  collapsible: boolean;
  prepopulateBuildArea: boolean;
}

interface CbComponent<MetaType = any> {
  category: number;
  title: string;
  renderIcons: boolean;
  icon: string;
  component: any;
  componentPreview: boolean;
}

interface ClickList<MetaType = any> {
    handle: string,
    action: any
}

export interface CbOptions<MetaType = any> {
  components?: CbComponent[];
  listElements?: boolean;
  userDefinedCss?: boolean;
  customCss?: string;
  dragable?: boolean;
  grid?: boolean;
  templateManager?: boolean;
  buildAreaTabs?: boolean;
  gridAreaTabs?: boolean;
  listViewOptions?: CbListOptions;
}

@Component({
  selector: 'app-content-builder',
  templateUrl: './content-builder.component.html',
  styleUrls: ['./content-builder.component.scss']
})

export class ContentBuilderComponent implements OnInit, AfterViewInit {

  @ViewChild("viewContainerRef", { read: ViewContainerRef, static: false })
    VCR: ViewContainerRef;

  @Input()
  CbOptions;

  componentId: string;
  child_unique_key: number = 0;
  componentsReferences = Array<ComponentRef<any>>();

  @Output()
  documentElements: Array<any>;

  cssProperties: any;

  private elementMouseDown: boolean;
  private elementMouseOver: boolean;
  private elementMoving: boolean;
  private elementResizing: boolean;
  private elementResized: boolean;
  
  // Resize Variables
  private horizontTargetElements: any;
  private verticalTargetElements: any;
  private freeTargetElements: any;
  private resizeTargetElement: any;
  private resizeMoveTarget: number;
  private resizeRootElement: any;
  private isResizeHandleClicked: boolean;
  private width: number;
  private height: number;
  private resizeMouseup$: any;
  private resizeMousedown$: any;
  private resizeMousemove$: any;
  private resizeMousehold$: any;
  private resizeHandle: any; 
  private resizeState: any; 

  // Drag Variables
  private dragTargetElement: any;
  private dragMoveTarget: number;
  private dragRootElement: any;
  private isDragHandleClicked: boolean;
  private dragMouseup$: any;
  private dragMousedown$: any;
  private dragMousemove$: any;
  private dragMouseover$: any;
  private dragMouseout$: any;
  private dragMousehold$: any;
  private dragHandle: any; 
  private dragState: any; 

//   Quill Editor


  constructor(private CFR: ComponentFactoryResolver, private sanitizer: DomSanitizer) { 
    // let listMenu: ListMenu = new ListMenu(sanitizer);
    // this.documentElements = listMenu.documentList;
    this.componentId = "contentBuilder";
    this.resizeHandle = resizeStateEnum; 
    this.dragHandle = dragStateEnum; 

    this.elementMouseDown = false;
    this.elementMouseOver = false;
    this.elementMoving = false;
    this.elementResizing = false;
    this.elementResized = false;
    this.cssProperties = properties;
 }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    // this.initializeListMenu(this.documentElements);
  }

  ngOnChanges(): void {
    
  }

  createURL(): string {
      return "https://docs.google.com/document/d/1uqNTnYQYDNJn1hD3pn8iGbOLjvIqSnB25f7dpHuCHzk/edit" + "&output=embed";
  }

  initializeListMenu(elements: any): void {

    for(let categoryIndex = 0; categoryIndex < elements.length; categoryIndex++) {
        for(let elementIndex = 0; elementIndex < elements[categoryIndex].elements.length; elementIndex++) {
            if(elements[categoryIndex].elements[elementIndex].isComponent) {
                let componentFactory = this.CFR.resolveComponentFactory(elements[categoryIndex].elements[elementIndex].componentHandle);

                let childComponentRef = this.VCR.createComponent(componentFactory);

                let childComponent: any = childComponentRef.instance;

                // add reference for newly created component
                this.componentsReferences.push(childComponentRef);
            }
        }
    }
  }

  createComponent(): void {
  // let componentFactory = this.CFR.resolveComponentFactory(VendorCalendarComponent);

  // let childComponentRef = this.VCR.createComponent(componentFactory);

  // let childComponent = childComponentRef.instance;

  // // add reference for newly created component
  // this.componentsReferences.push(childComponentRef);
  }

  pseudoClassCheck(node: any, pseudoSelector: string): boolean {
    let nativeMatches = (node.matches || node.msMatchesSelector);

    try {
        return (nativeMatches.call(node, pseudoSelector));
    } catch(error) {
        return (false);
    }
  }

  
  testClick(): void {
    // console.log("clicked");
  }

  //   Drag Listeners
  attachDragListener(target: any, exclusionTargets: Array<string>): void {
    let dragRootElement =  document.querySelector("html");


    this.dragMousedown$ = fromEvent(target, 'mousedown');
    this.dragMousedown$.subscribe((event) => {
        this.isDragHandleClicked = true;

        for(let exclusionIndex: number = 0; exclusionIndex < exclusionTargets.length; exclusionIndex++) {
            if(exclusionTargets[exclusionIndex] === event.target.className) {
                this.isDragHandleClicked = false;
                break;
            }
        }

        
        this.dragState = this.dragHandle.DRAG_ENTERED;

        this.dragTargetElement = target;
    })

    this.dragMouseover$ = fromEvent(target, 'mouseover');
    this.dragMouseover$.subscribe((event) => {
    })

    this.dragMouseout$ = fromEvent(target, 'mouseout');
    this.dragMouseout$.subscribe((event) => {

        // If dragging started then mouseout will cancel and reset drag
        if(this.dragState === this.dragHandle.DRAG_STARTED) {
            // this.dragState = this.dragHandle.DRAG_ENDED;
            // this.isDragHandleClicked = false;
        }
    })

    this.dragMousemove$ = fromEvent(dragRootElement, 'mousemove');
    this.dragMousemove$.subscribe((event) => {
        

        if(this.isDragHandleClicked && this.pseudoClassCheck(target, ":active")) {
            this.dragState = this.dragHandle.DRAG_STARTED;
            let parentContainer = this.dragTargetElement.parentElement;

            if(this.dragTargetElement.classList.contains("child")) {
                while(this.dragTargetElement.classList.contains("child")) {
                    this.dragTargetElement = this.dragTargetElement.parentElement;
                }
            }

            if (target.classList.contains("document-builder-item")) {
                let element = target,
                container = target.parentElement,
                bodyRect = document.body.getBoundingClientRect(),
                elemRect = element.getBoundingClientRect();
                // elementY = elemRect.top - bodyRect.top,
                // elementX = elemRect.left - bodyRect.left;
                
                let elementY:number = Number(element.style.top.replace("px", ""));
                let elementX = Number(element.style.left.replace("px", ""));
        
                    // Top Boundary
                if(elementY + event.movementY < 0) {
                    element.style.top = 0 + "px";
                } else {
                        // Bottom Boundary
                    if(elementY + element.clientHeight + event.movementY > container.clientHeight) {
                        element.style.top = container.clientHeight - element.clientHeight + "px";
                    } else {
                        let wholeTop = elementY + (event.movementY / window.devicePixelRatio);
                        element.style.top = wholeTop + "px";
                    }
                }
        
                    // Right Boundary
                if(elementX + element.clientWidth + event.movementX > container.clientWidth) {
                    element.style.left = container.clientWidth - element.clientWidth + "px";
                } else {
                        // Left Boundary
                    if(elementX + event.movementX < 0) {
                        element.style.left = 0 + "px";
                    } else {
                        let wholeLeft = elementX + (event.movementX / window.devicePixelRatio);
                        element.style.left = wholeLeft + "px";
                    }
                }         
            } 
        }
    })

    this.dragMouseup$ = fromEvent(dragRootElement, 'mouseup');
    this.dragMouseup$.subscribe((event)=>{
        if(this.dragTargetElement) {
            this.dragTargetElement = 0;
        }

        this.dragState = this.dragHandle.DRAG_ENDED;
        this.isDragHandleClicked = false;
    });
  }

  // Resize Listeners
  attachResizeListener(target: any, exclusionTargets: Array<string>, attachDocumentListener: boolean): void {
    // let resizeClass = target.classList
    
    this.resizeMousedown$ = fromEvent(target, 'mousedown');
    this.resizeMousedown$.subscribe((event) => {
        let handle: string = event.target.className;
        this.isResizeHandleClicked = true;


        this.resizeState = event.target.className;

        this.resizeTargetElement = target.parentElement;
    })

    if(attachDocumentListener) {
        let resizeRootElement =  document.querySelector("html");

        this.resizeMousemove$ = fromEvent(resizeRootElement, 'mousemove');
        this.resizeMousemove$.subscribe((event) => {
            if(this.isResizeHandleClicked && this.pseudoClassCheck(target.parentElement, ":active")) { 
                let parentContainer = event.target.parentElement;

                if(this.resizeTargetElement.classList.contains("child")) {
                    while(event.target.classList.contains("child")) {
                        this.resizeTargetElement = event.target.parentElement;
                    }
                }

                // let parentContainerWidth = parentContainer.parentElement.parentElement.clientWidth;
                // let parentContainerHeight = parentContainer.parentElement.parentElement.clientHeight;
                // let elementWidth = this.horizontTargetElements[this.moveTarget].parentElement.clientWidth;
                // let elementContainerOffset = this.horizontTargetElements[this.moveTarget].parentElement.offsetLeft + elementWidth;


                let handle: string = this.resizeState;

                let elementY:number = Number(this.resizeTargetElement.style.top.replace("px", ""));
                let elementX:number = Number(this.resizeTargetElement.style.left.replace("px", ""));

                let freeWholeRight: number;
                let freeWholeBottom: number;
                let wholeTop: number;
                let wholeBottom: number;
                let wholeRight: number;
                let wholeLeft: number;

                switch(handle) {
                    case this.resizeHandle.HORIZONTAL_LEFT:
                        wholeRight = this.resizeTargetElement.clientWidth - (event.movementX / window.devicePixelRatio);
                        wholeLeft = (event.movementX / window.devicePixelRatio);

                        this.resizeTargetElement.style.width = wholeRight + "px";
                        this.resizeTargetElement.style.left = elementX + wholeLeft + "px";
                        break;
                    case this.resizeHandle.HORIZONTAL_RIGHT:
                        // if(elementContainerOffset <= parentContainerWidth || e.movementX < 0) {
                        // if(e.x <= parentContainerWidth) {
                        wholeRight = this.resizeTargetElement.clientWidth + (event.movementX / window.devicePixelRatio);
                        this.resizeTargetElement.style.width = wholeRight + "px";
                        // }

                        break;
                    case this.resizeHandle.VERTICAL_TOP:
                        wholeBottom = this.resizeTargetElement.clientHeight - (event.movementY / window.devicePixelRatio);
                        wholeTop = (event.movementY / window.devicePixelRatio);

                        this.resizeTargetElement.style.height = wholeBottom + "px";
                        this.resizeTargetElement.style.top = elementY + wholeTop + "px";
                        break;
                    case this.resizeHandle.VERTICAL_BOTTOM:
                        // if(e.y <= parentContainerHeight) {
                        wholeBottom = this.resizeTargetElement.clientHeight + (event.movementY / window.devicePixelRatio);
                        this.resizeTargetElement.style.height = wholeBottom + "px";
                        // }
                        break;
                    case this.resizeHandle.FREE_TOP_LEFT:
                        break;
                    case this.resizeHandle.FREE_TOP_RIGHT:
                        break;
                    case this.resizeHandle.FREE_BOTTOM_LEFT:
                        break;
                    case this.resizeHandle.FREE_BOTTOM_RIGHT:
                        // if(e.y <= parentContainerHeight) {
                        freeWholeRight = this.resizeTargetElement.clientWidth + (event.movementX / window.devicePixelRatio);
                        freeWholeBottom = this.resizeTargetElement.clientHeight + (event.movementY / window.devicePixelRatio);

                        this.resizeTargetElement.style.width = freeWholeRight + "px";
                        this.resizeTargetElement.style.height = freeWholeBottom + "px";
                        // }
                        break;
                }

                // switch(this.resizeState) {
                //     case this.resizeHandle.HORIZONTAL:
                //         break;
                //     case this.resizeHandle.VERTICAL:
                //         break;
                //     case this.resizeHandle.FREE: 
                //         break;
                // }
            }
        })

        this.resizeMouseup$ = fromEvent(resizeRootElement, 'mouseup');
        this.resizeMouseup$.subscribe(()=>{
            if(this.resizeTargetElement) {
                this.resizeTargetElement = 0;
            }

            this.isResizeHandleClicked = false;
        });
    }
  }

  //   Click Listeners
  attachClickListener(target: any, action: any) {
    target.addEventListener("click", (event: Event) => {
        (action)();
    });
  }

  // CdkDrag Override
  drop(event: CdkDragDrop<string[]> ) {
    if (event.item.element.nativeElement.classList.contains("document-builder-item")) {
        let element = event.item.element.nativeElement,
        container = event.container.element.nativeElement,
        bodyRect = document.body.getBoundingClientRect(),
        elemRect = element.getBoundingClientRect();
        // elementY = elemRect.top - bodyRect.top,
        // elementX = elemRect.left - bodyRect.left;
        
        let elementY:number = Number(element.style.top.replace("px", ""));
        let elementX = Number(element.style.left.replace("px", ""));

            // Top Boundary
        if(elementY + event.distance.y < 0) {
            element.style.top = 0 + "px";
        } else {
                // Bottom Boundary
            if(elementY + element.clientHeight + event.distance.y > container.clientHeight) {
                element.style.top = container.clientHeight - element.clientHeight + "px";
            } else {
                element.style.top = elementY + event.distance.y  + "px";
            }
        }

            // Right Boundary
        if(elementX + element.clientWidth + event.distance.x > container.clientWidth) {
            element.style.left = container.clientWidth - element.clientWidth + "px";
        } else {
                // Left Boundary
            if(elementX + event.distance.x < 0) {
                element.style.left = 0 + "px";
            } else {
                element.style.left = elementX + event.distance.x + "px";
            }
        }         
    } 
    
    else {
        let ctx = this;
        let element: HTMLElement = event.item.element.nativeElement;
        let component: any = ActiveBidExpandableTableComponent;
        let exclusionList: Array<string> = ["remove-handle"];
        let resizeHandles: Array<string> = 
            [
                "horizontal-left",
                "horizontal-right",
                "vertical-top",
                "vertical-bottom",
                "free-top-left",
                "free-top-right",
                "free-bottom-left",
                "free-bottom-right"
            ];

            Array.prototype.push.apply(exclusionList, resizeHandles);

            switch(Number.parseInt(element.id)) {
                case 0: 
                    component = ActiveBidExpandableTableComponent;
                    break;

                case 1: 
                    component = VendorEfficiencyAndWorkloadChartComponent;
                    break;

                case 2: 
                    component = VendorCalendarComponent;
                    break;

                case 3: 
                    component = VendorWorkOrderSchedulingWeekViewComponent;
                    break;

            }

            let componentFactory = this.CFR.resolveComponentFactory(component);

            let childComponentRef = this.VCR.createComponent(componentFactory);

            let childComponent: any = childComponentRef.instance;

            // add reference for newly created component
            this.componentsReferences.push(childComponentRef);

            let test = event.item.element.nativeElement;
            // let returnElement = event.item.element.nativeElement;
            let receivingContainer = event.container.element.nativeElement;

            // let element: any = document.createElement(test.parentElement.innerHTML);
            // let element: any = this.renderer.createElement("div");
            // element.innerHTML = test.parentElement.innerHTML;
            // let listContainer = event.item.element.nativeElement.parentElement;

            setTimeout(function() {
              // FIX: querySelectorAll will find more than one instance of the particular ID due to it also being in the list view
                let element:any = document.querySelectorAll("#" + childComponent.componentId)[1].parentElement;

                let elementWrapperInnerHTML:string = '<div class="remove-handle"></div>';

                for(let handleIndex: number = 0; handleIndex < resizeHandles.length; handleIndex++) {
                    elementWrapperInnerHTML += '<div class=' + resizeHandles[handleIndex] + '></div>'
                }

                let elementWrapper: any = document.createElement("div");
                elementWrapper.classList.add("test-box");
                elementWrapper.classList.add("document-builder-item");
                elementWrapper.classList.add("creatingElement");
                elementWrapper.innerHTML = elementWrapperInnerHTML;

                element.classList.add("fill");
                element.parentElement.appendChild(elementWrapper);

                document.querySelector(".creatingElement").appendChild(element);
                document.querySelector(".creatingElement").classList.remove("creatingElement");

                element = element.parentElement;
                // let element:any = document.querySelector(".template-placeholder").parentElement;

                // if(element.classList.contains("list-placeholder")) {
                //     element.classList.remove("list-placeholder");
                //     element.classList.remove("cdk-drag-dragging");
                //     element.classList.add("document-builder-item");
                // }
            

                let elementY:number = Number(test.style.top.replace("px", ""));
                let elementX:number = Number(test.style.left.replace("px", ""));
                let elementParent:any = test.offsetParent;
                let elementListContainer:any = test.parentElement.parentElement.parentElement;
                let elementContainerWidth:number =  Number(elementParent.offsetWidth);
                let elementContainerHeightOffset:number = Number(test.offsetTop);
                let elementOptionsContainerHeightOffset:number = Number(elementListContainer.firstChild.clientHeight);

                // Top Boundary
                if(elementY + event.distance.y + elementOptionsContainerHeightOffset- elementContainerHeightOffset < 0) {
                    element.style.top = 0 + "px";
                } else {
                        // Bottom Boundary
                    if(elementY + test.clientHeight + event.distance.y + elementContainerHeightOffset > receivingContainer.clientHeight) {
                        element.style.top = receivingContainer.clientHeight - test.clientHeight + "px";
                    } else {
                        element.style.top = elementY + event.distance.y + elementContainerHeightOffset + elementOptionsContainerHeightOffset + "px";
                    }
                }


                //  ERROR HERE SOMEWHERE, NOT ALIGNING LEFT/RIGHT CORRECTLY
                    // Right Boundary
                if(elementX + event.distance.x + test.clientWidth - elementContainerWidth > receivingContainer.clientWidth) {
                    element.style.left = receivingContainer.clientWidth - test.clientWidth + "px";
                } else {
                        // Left Boundary
                    if(elementX + event.distance.x < elementContainerWidth) {
                        element.style.left = 0 + "px";
                    } else {
                        element.style.left = elementX + event.distance.x - elementContainerWidth + "px";
                    }
                }   

                ctx.attachDragListener(element, exclusionList);
                let clickList: Array<ClickList> = 
                [
                    {
                        handle: "remove-handle",
                        action: function() {ctx.removeElement(element);}
                    }
                ];

                for(let childIndex: number = 0; childIndex < element.children.length; childIndex++) {
                    for(let clickListIndex: number = 0; clickListIndex < clickList.length; clickListIndex++) {
                        if(element.children[childIndex].className === clickList[clickListIndex].handle) {
                            ctx.attachClickListener(element.children[childIndex], clickList[clickListIndex].action);
                        }
                    }
                }

                for(let handleIndex: number = 0; handleIndex < resizeHandles.length; handleIndex++) {
                    for(let childIndex: number = 0; childIndex < element.children.length; childIndex++) {
                        if(element.children[childIndex].className === resizeHandles[handleIndex]) {
                            if(handleIndex == 0) {
                                ctx.attachResizeListener(element.children[childIndex], [], true);
                                break;
                            } else {
                                ctx.attachResizeListener(element.children[childIndex], [], false);
                                break;
                            }
                        }
                    }
                }

            }, 0, childComponent, event, ctx);
      }      
  }

  resizeElement(): void {

  }

  removeElement(target: any):void {
    if(target) {
        target.remove();
    }
  }
  
  //  NEEDS ADJUSTING: This only toggles and if the user clicks the same options twice then it switches to others options
  builderSidebarSwitch(event: any): void {
    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let value = idAttr.nodeValue;

    let listOne = document.getElementById("documentElementContainer");
    let listTwo = document.getElementById("UIElementContainer");

    document.querySelector(".builderSidebar li.builderSidebarActive").classList.remove("builderSidebarActive");
    document.querySelector("#" + value).classList.add("builderSidebarActive");

    if(listOne.classList.contains("autoHide")) {
        listOne.classList.remove("autoHide");
        listTwo.classList.add("autoHide");
    } else {
        listTwo.classList.remove("autoHide");
        listOne.classList.add("autoHide");
    }
  }

  //  NEEDS ADJUSTING: This only toggles and if the user clicks the same options twice then it switches to others options
  styleSidebarSwitch(event: any): void {
    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let value = idAttr.nodeValue;

    let listOne = document.getElementById("basicStyleContainer");
    let listTwo = document.getElementById("advancedStyleContainer");

    document.querySelector(".styleSidebarOptions ul li.styleSidebarActive").classList.remove("styleSidebarActive");
    document.querySelector("#" + value).classList.add("styleSidebarActive");

    if(listOne.classList.contains("autoHide")) {
        listOne.classList.remove("autoHide");
        listTwo.classList.add("autoHide");
    } else {
        listTwo.classList.remove("autoHide");
        listOne.classList.add("autoHide");
    }
  }
}
