import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef, Inject,
    Injectable,
    InjectionToken,
    Injector
  } from '@angular/core';

  import { ComponentPortal } from '@angular/cdk/portal';
  import { DOCUMENT } from '@angular/common';
  
  export const DOM_SERVER = new InjectionToken<{}>('DOM_SERVICE');
  
  @Injectable({
    providedIn: 'root'
  })

  export class DomService {
  
    constructor(
      @Inject(DOCUMENT) private document: Document,
      private componentFactoryResolver: ComponentFactoryResolver,
      private appRef: ApplicationRef,
    ) {
    }
  
    private _createInjector(useValue): Injector {
      return Injector.create({
        providers: [{provide: DOM_SERVER, useValue}]
      });
    }
  
    createComponent(
      component: any,
      appendTo: Element,
      componentProps: {},
      wrapperClass: string
    ): ComponentRef<unknown> {
      const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(component)
        .create(this._createInjector(componentProps));
  
      this.appRef.attachView(componentRef.hostView);
  
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
  
      if (wrapperClass) {
        const hasWrapper = appendTo.querySelector(`.${wrapperClass}`);
        if (!hasWrapper) {
          const divTag = this.document.createElement('div');
          divTag.className = wrapperClass;
          appendTo.appendChild(divTag);
        }
  
        appendTo = appendTo.querySelector(`.${wrapperClass}`);
      }
  
      appendTo.appendChild(domElem);
  
      return componentRef;
    }
  
    detachComponent(componentRef: ComponentRef<unknown>): void {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }
  }



  // EXAMPLE OF USE
    // this.componentRef = this.domService
    //      .createComponent(
    //       VendorCrossModuleReportComponent,
    //           this.document.getElementById('container'),
    //           { name: 'just for test' },
    //           'app-vendor-cross-module-report'
    //         );