import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-vendor-contact-dialog.component',
    templateUrl: 'vendor-contact-dialog.component.html',
    styleUrls: ['vendor-contact-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorContactDialogComponent implements OnInit {
    
    constructor(public matDialogRef: MatDialogRef<VendorContactDialogComponent>) {

    }
  
    ngOnInit() {

    }

    close() {
      this.matDialogRef.close();
    }

  }