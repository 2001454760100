import { Component, Input, Output, OnInit } from '@angular/core';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { VendorQuickActionMenuService } from '../../../services/vendor/vendor-quick-action-menu.service';

@Component({
  selector: 'app-vendor-footer',
  templateUrl: './vendor-footer.component.html',
  styleUrls: ['./vendor-footer.component.scss']
})
export class VendorFooterComponent implements OnInit {

  @Input() public footerPageTitle: string;
  @Input() public foreignViewer: boolean = false;

  public companyIdAssigned: string = null;

  public quickActionMenuToggle: boolean = true;
  
  constructor(private navigatorService: NavigatorService, private quickActionService: VendorQuickActionMenuService) { 

  }

  ngOnInit() {

    this.quickActionMenuToggle = this.quickActionService.getQuickActionMenuToggle();


    if(!this.foreignViewer) {
      this.monitorCompanyId();
    }

  }

  private monitorCompanyId(): void {
    let self = this;

    setTimeout(function() {
      self.companyIdAssigned = self.navigatorService.getCompanyId();
      self.monitorCompanyId();
    }, 1000);

  }

  public toggleQuickActionMenu(quickActionMenuToggle: boolean): void {
    this.quickActionMenuToggle = quickActionMenuToggle;
    this.quickActionService.setQuickActionMenuToggle(quickActionMenuToggle);
  }

}
