import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { GroupedObservable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';


export interface GroupColor {
  primary: string;
  secondary: string;
}

export interface Availability {
  date: Date;
  startTimes: Date[];
  durations: number[]
}

export interface Group<MetaType = any> {
  id?: number;
  group: string;
  availability: Availability[];
  end?: Date;
  title: string;
  color?: GroupColor;
  meta?: MetaType;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#08e33f',
    secondary: '#87d69b',
  },
  orange: {
    primary: '#fa8500',
    secondary: '#fab15f',
  },
  teal: {
    primary: '#00ffcc',
    secondary: '#cffcf3',
  },
  purple: {
    primary: '#d900fa',
    secondary: '#e687f5',
  }
};

export var groups: Group[] = [
  {
    id: 0,
    group: "admin",
    title: "PTO",
    availability: [
      {
        date: new Date(2021, 2, 1, 0,0,0),
        startTimes: [new Date(2021, 2, 1, 0,0,0), new Date(2021, 2, 1, 5,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 2, 0,0,0),
        startTimes: [new Date(2021, 2, 1, 0,0,0), new Date(2021, 2, 1, 5,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 3, 0,0,0),
        startTimes: [new Date(2021, 2, 1, 0,0,0), new Date(2021, 2, 1, 5,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 4, 0,0,0),
        startTimes: [new Date(2021, 2, 1, 0,0,0), new Date(2021, 2, 1, 5,0,0)], 
        durations: [60]
      }
    ],
    color: colors.red
  },
  {
    id: 1,
    group: "technician",
    title: "8-4",
    availability: [
      {
        date: new Date(2021, 1, 28, 1,0,0),
        startTimes: [new Date(2021, 1, 28, 1,0,0), new Date(2021, 1, 28, 1,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 3, 1,0,0),
        startTimes: [new Date(2021, 2, 1, 1,0,0), new Date(2021, 2, 1, 1,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 5, 1,0,0),
        startTimes: [new Date(2021, 2, 5, 1,0,0), new Date(2021, 2, 5, 1,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 6, 1,0,0),
        startTimes: [new Date(2021, 2, 6, 1,0,0), new Date(2021, 2, 6, 1,0,0)], 
        durations: [60]
      }
    ],
    color: colors.blue
  },
  {
    id: 2,
    group: "consultant",
    title: "9-5",
    availability: [
      {
        date: new Date(2021, 2, 1, 2,0,0),
        startTimes: [new Date(2021, 2, 1, 2,0,0), new Date(2021, 2, 1, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 2, 2,0,0),
        startTimes: [new Date(2021, 2, 1, 2,0,0), new Date(2021, 2, 1, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 3, 2,0,0),
        startTimes: [new Date(2021, 2, 5, 2,0,0), new Date(2021, 2, 5, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 4, 2,0,0),
        startTimes: [new Date(2021, 2, 6, 2,0,0), new Date(2021, 2, 6, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 5, 2,0,0),
        startTimes: [new Date(2021, 2, 6, 2,0,0), new Date(2021, 2, 6, 2,0,0)], 
        durations: [60]
      }
    ],
    color: colors.green
  },
  {
    id: 3,
    group: "consultant",
    title: "9-5",
    availability: [
      {
        date: new Date(2021, 2, 1, 3,0,0),
        startTimes: [new Date(2021, 2, 1, 2,0,0), new Date(2021, 2, 1, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 2, 3,0,0),
        startTimes: [new Date(2021, 2, 1, 2,0,0), new Date(2021, 2, 1, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 3, 3,0,0),
        startTimes: [new Date(2021, 2, 5, 2,0,0), new Date(2021, 2, 5, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 4, 3,0,0),
        startTimes: [new Date(2021, 2, 6, 2,0,0), new Date(2021, 2, 6, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 5, 3,0,0),
        startTimes: [new Date(2021, 2, 6, 2,0,0), new Date(2021, 2, 6, 2,0,0)], 
        durations: [60]
      }
    ],
    color: colors.green
  },
  {
    id: 4,
    group: "consultant",
    title: "9-5",
    availability: [
      {
        date: new Date(2021, 2, 1, 4,0,0),
        startTimes: [new Date(2021, 2, 1, 2,0,0), new Date(2021, 2, 1, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 2, 4,0,0),
        startTimes: [new Date(2021, 2, 1, 2,0,0), new Date(2021, 2, 1, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 3, 4,0,0),
        startTimes: [new Date(2021, 2, 5, 2,0,0), new Date(2021, 2, 5, 2,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, 2, 5, 4,0,0),
        startTimes: [new Date(2021, 2, 6, 2,0,0), new Date(2021, 2, 6, 2,0,0)], 
        durations: [60]
      }
    ],
    color: colors.green
  }
]

export class VendorCalendarGroups  {

  
  
}
