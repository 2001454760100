import { 
  Component, 
  OnInit, 
  AfterViewInit,
  Input,
  SimpleChange,
  Output,
  EventEmitter} from '@angular/core';

import { keyTypesEnum, NavigatorService } from '../../../services/vendor/navigator.service';

import { CompanyProfile, LinkedAccountRequest, Profile } from '../../../data-models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafeUrl } from '@angular/platform-browser';
import { link } from 'fs';



@Component({
  selector: 'app-vendor-linked-account-request',
  templateUrl: 'vendor-linked-account-request.component.html',
  styleUrls: ['vendor-linked-account-request.component.scss'],
//   encapsulation: ViewEncapsulation.Emulated

})


export class VendorLinkedAccountRequestComponent implements OnInit, AfterViewInit {
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Input() requests: Array<LinkedAccountRequest> = []; 

  public linkedCompanyRequests: Array<CompanyProfile> = [];
  
  private keyTypes: any = keyTypesEnum;

  public infoForm: FormGroup;
  public locationForm: FormGroup;
  public passwordForm: FormGroup;

  public imgId: string = null;
  public image: any;
  public imageDeleteAction: boolean = false;
  
  constructor(private navigatorService: NavigatorService,private imageHandler: ImageHandlerService, public snackBar: MatSnackBar, public formBuilder: FormBuilder) { 
        
  }

  ngOnInit(): void {

    this.infoForm = this.formBuilder.group({
      company_id: [ { value: '', disabled: true } ],
      company_name: [ { value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3)])],
      email: [ { value: '', disabled: true }, Validators.compose([Validators.required, emailValidator])],
      phone: [ { value: '', disabled: true }, Validators.required],
      image: [ { value: null, disabled: true } ],      
      facebook: [ { value: '', disabled: true } ],
      twitter: [ { value: '', disabled: true } ],
      linkedin: [ { value: '', disabled: true } ],
      instagram: [ { value: '', disabled: true } ],
      website: [ { value: '', disabled: true } ]
    });

    this.locationForm = this.formBuilder.group({
      street: [ { value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3)])],
      city: [ { value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3)])],
      state: [ { value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(2)])],
      zip: [ { value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(5)])]
    }); 

  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
  }
  
  ngOnChanges() {  
    this.populateCompanyRequests();
  }

  public getCompanyProfile(companyId: string): void {

    if(typeof companyId == 'string' && companyId.length > 0) {
      this.navigatorService.getAsyncCompanyProfile(companyId).then( (data: CompanyProfile) => { 
        // let result = this.filterData(data); 
        if(data == null) {
          return false;
        } 

        this.infoForm.controls["company_id"].setValue(data.id);
        this.infoForm.controls["company_name"].setValue(data.company_name);
        this.infoForm.controls["email"].setValue(data.email);
        this.infoForm.controls["phone"].setValue(data.phone);

        this.infoForm.controls["image"].setValue([]);

        this.infoForm.controls["linkedin"].setValue(data.linkedin);
        this.infoForm.controls["twitter"].setValue(data.twitter);
        this.infoForm.controls["instagram"].setValue(data.instagram);
        this.infoForm.controls["facebook"].setValue(data.facebook);
        this.infoForm.controls["website"].setValue(data.website);

        this.locationForm.controls["street"].setValue(data.address.street);
        this.locationForm.controls["city"].setValue(data.address.city);
        this.locationForm.controls["state"].setValue(data.address.state);
        this.locationForm.controls["zip"].setValue(data.address.zip);

        this.imgId = data.image;
        this.image = null;
        this.getImg();
      });
    }

  }

  public async populateCompanyRequests(): Promise<void> {
    if(this.requests == undefined || this.requests == null) {
      this.requests = [];
    }

    this.linkedCompanyRequests = [];

    // Pull all linked account profiles and their associated images/logos
    for(let linkedRequest of this.requests) {

      await this.navigatorService.getAsyncCompanyProfile(linkedRequest.companyId).then( (profileData: CompanyProfile) => {

        if(profileData != null && profileData != undefined) {

          this.linkedCompanyRequests.push(profileData);

        }
  
      });

    }

  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getAsyncFile(this.imgId).then( (image: SafeUrl) => {
        
        if(image != undefined && image != null) {
          this.image = image;
  
          console.log("Image: ", this.image);
        }

      });
    }
  }

  public uploadImage(image: any): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
          this.imgId = imgIdUnique;
          this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
  }

  public deleteImg(): void {
    this.infoForm.controls["image"].setValue("");
    this.image = null;
    this.imageDeleteAction = true;
  }

  public approveRequest(companyId: string): void {

    let user: Profile = this.navigatorService.getCurrentProfile();
    let requestIndex: number = user.linked_account_requests.findIndex( (linkedRequest: LinkedAccountRequest) => { return linkedRequest.companyId == companyId; })
    let requestId: string = null;

    if(requestIndex > -1) {
      requestId = user.linked_account_requests[requestIndex].id;
    } else {
      this.snackBar.open('There was an error approving this request!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return
    }

    if(typeof companyId != 'string' || companyId.length == 0) {
      this.snackBar.open('There was an error approving this request!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
    }

    if(typeof requestId != 'string' || requestId.length == 0) {
      this.snackBar.open('There was an error approving this request!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.navigatorService.approveCompanyLinkRequestByCurrentUser(companyId, requestId).then( (success: boolean) => {

      if(success) {
        this.snackBar.open('Request was approved!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.refresh.emit();
      } else {
        this.snackBar.open('There was an error approving this request!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  public denyRequest(companyId: string): void {

    let requestId: LinkedAccountRequest = this.requests.find( (request: LinkedAccountRequest) => { return request.companyId == companyId });

    if(requestId == undefined || requestId == null) {
      this.snackBar.open('There was an error canceling this request!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.navigatorService.cancelCompanyLinkRequestByCurrentUser(companyId, requestId.id).then( (success: boolean) => {

      if(success) {
        this.snackBar.open('Request was denied!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.refresh.emit();
      } else {
        this.snackBar.open('There was an error canceling this request!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  public unlinkAccount(companyId: string): void {

    if(typeof companyId != 'string' || companyId.length == 0) {
      this.snackBar.open('There was an error unlinking this account!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
    }

    let user: Profile = this.navigatorService.getCurrentProfile();

    this.navigatorService.removeCompanyId(user, companyId, true).then( (success: boolean) => {

      if(success) {
        this.snackBar.open('Unlinked account successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.refresh.emit();
      } else {
        this.snackBar.open('Failed to unlink this account!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

}