import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-hex-video-clip',
  templateUrl: './hex-video-clip.component.html',
  styleUrls: ['./hex-video-clip.component.scss']
})
export class HexVideoClipComponent implements OnInit, AfterViewInit {

  @ViewChild('hexVideo') hexVideo: any;

  @Input('vidSrc') vidSrc: string = null;
  @Input('imgSrc') imgSrc: string = null;
  @Input('type') type: string = "video/webm";
  @Input('altColor') altColor: boolean = false;

  constructor() { }
 

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    if(this.vidSrc != null) {
      let element: any = this.hexVideo.nativeElement;
      element.muted = true;
      element.play();
    }
    
  }

}
