import { Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';

import { ReferralLink, CompanyProfile, Profile, RequestReferralPayoutDetails } from '../../../data-models/models';
import { Subscription } from 'rxjs'; 
import { Color } from 'ng2-charts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { VendorQuickActionMenuService } from 'src/app/services/vendor/vendor-quick-action-menu.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { BoardTypes } from '../../crm/job-board/job-board.component';

export enum referralStatusTypes {
  SENT = 0,
  ACCEPTED = 1,
  EXPIRED = 2,
  REJECTED = 3,
  ERROR = 4
}

@Component({
  selector: 'app-vendor-sales-portal',
  templateUrl: './sales-portal.component.html',
  styleUrls: ['./sales-portal.component.scss']
})

export class SalesPortalComponent implements OnInit, AfterViewInit, OnDestroy {
  private quickActionSubscriber: Subscription;
  public quickActionMenuExpanded: boolean = true;
  public salesTrackerExpanded: boolean = true;

  public loggedProfile: Array<Profile> = [this.navigatorService.getCurrentProfile()];

  public boardTypes: any = BoardTypes;
  public referralStatus: any = referralStatusTypes;
  private referralLookupText: string = "";
  public filteredCompaniesOptions: Array<string> = [];
  public filteredCompanyProfiles: Array<CompanyProfile> = [];

  public graphContainerExpanded: boolean = false;

  public referralStatusLookup: any = [
    {
      title: "Sent",
      status: this.referralStatus.SENT
    },
    {
      title: "Accepted",
      status: this.referralStatus.ACCEPTED
    },
    {
      title: "Expired",
      status: this.referralStatus.EXPIRED
    },
    {
      title: "Rejected",
      status: this.referralStatus.REJECTED
    },
    {
      title: "Error",
      status: this.referralStatus.ERROR
    }
  ]

  public RI_labels: Array<string> = ['Extreme Heating & Cooling', 'Bob\'s Roofing', 'Arctic Air'];
  public RI_data: any = [

      { data: 350, label: '' }, 
      { data: 450, label: '' }, 
      { data: 100, label: '' }

  ];

  public RI_colors: Color[] = [
    {
      backgroundColor:["rgba(75, 161, 44, 0.5)", "rgba(210, 164, 25, 0.5)", "rgba(208, 23, 23, 0.5)", "rgba(208, 23, 23, 0.5)"]
    }
  ];

  public RT_labels: Array<string> = ['February', 'March', 'April', 'May', 'June', 'July'];
  public RT_data: any = {
    data: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Extreme Heating & Cooling' },
      { data: [192, 134, 240, 181, 265, 255, 174], label: 'Bob\'s Roofing' },
      { data: [77, 23, 45, 60, 65, 40, 81], label: 'Arctic Air' }
    ]
  };

  public RT_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    },
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    },
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];


  private PL_profits: number = 0;
  private PL_profjected: number = 0;
  private PL_missed_earnings: number = 0;

  public PL_labels: Array<string> = ['Profit', 'Projected', 'Missed Earnings'];
  public PL_data: Array< Array<any> > | Array<any> = [ 
    [0, 0, 0]
    // [50, 150, 120],
    // [250, 130, 70],
  ];

  public PL_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];

  public referralLinks: Array<ReferralLink> = [];

  public payoutLedger: Array<number> = [];

  public RI_SampleData: boolean = true;
  public RT_SampleData: boolean = true;

  public RT_loading: boolean = true;
  public RI_loading: boolean = true;

  

  constructor(
    private navigatorService: NavigatorService, 
    private quickActionService: VendorQuickActionMenuService, 
    private snackBar: MatSnackBar, private http: HttpClient) { 
        
  }

  async ngOnInit(): Promise<void> {

    let profile: Profile = this.navigatorService.getCurrentProfile();

    if(profile.referral_links != undefined && profile.referral_links != null) {

      this.syncDirectSales();
    }

    this.PL_missed_earnings = this.calcDailyMissedEarnings();
    this.getPL_Profits();
    this.getRT_Data();

    this.update_PL_Data();

    this.quickActionMenuExpanded = this.quickActionService.getQuickActionMenuToggle();
    this.listeners();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
    
    if(this.quickActionSubscriber) {
      this.quickActionSubscriber.unsubscribe();
    }

  }  

  private listeners(): void {

    this.quickActionSubscriber = this.quickActionService.quickActionMenuToggleBroadcaster().subscribe( (expanded: boolean) => {

      this.quickActionMenuExpanded = expanded;

    });

  }

  private update_PL_Data(): void {
    this.PL_data = [ 
      [
        this.PL_profits, 
        this.PL_profjected, 
        this.PL_missed_earnings
      ] ];
  }

  private update_RT_Data(): void {

    this.RT_labels = [];

    if(this.RT_data.data.length > 0) {

      let monthDuration = this.RT_data.data[0].data.length;

      for(let monthIndex: number = 0; monthIndex < monthDuration; monthIndex++) {
        let currentDate: Date = new Date();

        let startDate: Date = new Date();
        startDate.setMonth(currentDate.getMonth() - monthIndex);
        startDate.setDate(1);

        let monthTitle: string = startDate.toLocaleString('default', { month: 'long' });

        this.RT_labels.unshift(monthTitle);
      }

    }

  }

  private update_RI_Data(company_ids: Array<string>): void {
    let index: number = 0;

    if(this.payoutLedger.length > 0) {

      for(let company_id of company_ids) {

        let referral: ReferralLink = this.referralLinks.find( (link: ReferralLink) => { return link.company_id == company_id  });

        if(referral != undefined && referral != null) {

          if(referral.status == this.referralStatus.ACCEPTED) {
            this.RI_data = null;
            this.RI_labels = null;

            this.RI_data = [];
            this.RI_labels = [];
            this.RI_SampleData = false;

            let payout: number = this.payoutLedger[index];

            this.RI_data.push({
              data: payout,
              label: 'Payout'
            });
            
          

            if(referral.company_title != undefined) {
              this.RI_labels.push(referral.company_title);
            } else {
              this.RI_labels.push("Unknown");
            }

          }

        }

        index++;
      }

    }

  }

  private calcDailyMissedEarnings(): number {
    let sm_avg_rev: number = 1000000;
    let platformFee: number = .01;
    let partnerFee: number = .05;

    let missedEarnings: number = (sm_avg_rev * platformFee) * partnerFee;
    let dailyMissedEarnings: number = parseFloat(( missedEarnings / 365 ).toFixed(2));
    let annualMissedEarnings: number = parseFloat(( dailyMissedEarnings * this.getDayOfYear(new Date()) ).toFixed(2));

    return annualMissedEarnings;
  }

  private getDayOfYear(date: Date): number {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;

  }

  private async syncDirectSales(): Promise<void> {
    this.referralLinks = [];
    let profile: Profile = this.navigatorService.getCurrentProfile();

    if(profile.referral_links == undefined) {
      profile.referral_links = [];
    }

    for(let referral of profile.referral_links) {

      await this.navigatorService.getCompanyProfile(referral.company_id).then( (company: CompanyProfile) => {

        referral.company_title = company.company_name;
        referral.accepted_date = referral.accepted_date;
        referral.status = referral.status;

        if(referral.profile_id != this.navigatorService.getProfileId()) {
          referral.status = this.referralStatus.ERROR;
        }

      });

    }

    this.referralLinks = profile.referral_links;
    console.log("Direct Sales Links:", this.referralLinks);

  }

  private async syncReferrals(): Promise<void> {
    this.referralLinks = [];
    let profile: Profile = this.navigatorService.getCurrentProfile();

    if(profile.referral_links == undefined) {
      profile.referral_links = [];
    }

    for(let referral of profile.referral_links) {

      await this.navigatorService.getCompanyProfile(referral.company_id).then( (company: CompanyProfile) => {

        let liveReferral: ReferralLink = company.referral_link_requests.find( (ref: ReferralLink) => { return ref.profile_id == profile.id });

        if(liveReferral != undefined && liveReferral != null) {
          referral.company_title = company.company_name;
          referral.accepted_date = liveReferral.accepted_date;
          referral.status = liveReferral.status;
        } else {
          referral.status = this.referralStatus.REJECTED
        }

      });

    }

    this.referralLinks = profile.referral_links;

    this.navigatorService.updateOneProfile(profile).then( (success: boolean) => {

      if(success) {
        
      }

    });

  }

  public async referralTitleLookup(companyId: string): Promise<string> {
    let title: string = "Loading";
    let profile: Profile = this.navigatorService.getCurrentProfile();

    let referral: ReferralLink = profile.referral_links.find( (referral: ReferralLink) => { return referral.company_id == companyId });

    if(referral != undefined && referral != null) {

      await this.navigatorService.getCompanyProfile(referral.company_id).then( (companyProfile: CompanyProfile) => {

        if(companyProfile != undefined && companyProfile != null) {
          title = companyProfile.company_name;
        }

      });

      
    }

    return title;
  }

  public referralStatusTitleLookup(status: number): string {
    let title: string = "";

    let referralStatus: any = this.referralStatusLookup.find( referralStatus => { return referralStatus.status == status });

    if(referralStatus != undefined && referralStatus != null) {
      title = referralStatus.title;
    }

    return title;
  }

  public updateFilterText(filterText: string): void {
    this.referralLookupText = filterText;
  }

  public filterCompany(filterText: string): void {
    this.referralLookupText = filterText;
    this.filteredCompaniesOptions = [];

    this.navigatorService.getPagedDateRangeSearchCompanies(filterText, 0, 10).then( (filteredCompanies: Array<CompanyProfile>) => {
      this.filteredCompanyProfiles = [];

      if(filteredCompanies != undefined && filteredCompanies != null) {
        
        this.filteredCompanyProfiles = filteredCompanies;

        for(let company of filteredCompanies) {
          this.filteredCompaniesOptions.push(company.company_name);
        }

      }

    });

  }

  public sendReferralRequestLink(): void {
    

    let referral: CompanyProfile = this.filteredCompanyProfiles.find( (profile: CompanyProfile) => { return profile.company_name == this.referralLookupText });

    if(referral != undefined && referral != null) {

      let profileId: string = this.navigatorService.getProfileId();

      this.http.post('/platform/api/sendReferralConfirmation', { referralDetails: { companyId: referral.id, requestorId: profileId } } ).subscribe( (referralLink: ReferralLink) => {
        console.log("Referral Confirmation Server Return", referralLink );

        if(referralLink != null && referralLink != undefined) {

          let profile: Profile = this.navigatorService.getCurrentProfile();

          if(profile.referral_links == undefined) {
            profile.referral_links = [];
          }

          profile.referral_links.push(referralLink);

          this.navigatorService.updateOneProfile(profile).then( (success: boolean) => {

            if(success) {
              this.snackBar.open('Referral Confirmation Requested Successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

              this.syncReferrals();
            }

          });
          
        }

      });

    }

  }

  public getPL_Profits(): void {

    let profile: Profile = this.navigatorService.getCurrentProfile();

    if(profile.referral_links != undefined && profile.referral_links != null) {

        let companyIds: Array<string> = [];

        for(let referral of profile.referral_links) {
          companyIds.push(referral.company_id);
        }


        let requestReferralPayoutDetails: RequestReferralPayoutDetails = {
          company_ids: companyIds,
          profile_id: this.navigatorService.getProfileId()
        }

        this.http.post('/platform/api/getReferralEntitlements', { requestReferralPayoutDetails: requestReferralPayoutDetails } ).subscribe( (payoutLedger: Array<number>) => {
          console.log("Referral Entitlements Server Return", payoutLedger );

          this.RI_loading = false;

          this.payoutLedger = payoutLedger;
          this.PL_profits = 0;

          for(let payout of payoutLedger) {
            this.PL_profits += payout;
          }

          this.update_PL_Data();
          this.update_RI_Data(companyIds);
        });
        
    } else {
      this.RI_loading = false;
    }

  }

  public getRT_Data(): void {

    let profile: Profile = this.navigatorService.getCurrentProfile();

    if(profile.referral_links != undefined && profile.referral_links != null) {

        let companyIds: Array<string> = [];

        for(let referral of profile.referral_links) {
          companyIds.push(referral.company_id);
        }


        let requestReferralPayoutDetails: RequestReferralPayoutDetails = {
          company_ids: companyIds,
          profile_id: this.navigatorService.getProfileId()
        }

        this.http.post('/platform/api/getReferralHistoricalEntitlements', { requestReferralPayoutDetails: requestReferralPayoutDetails } ).subscribe( (payoutLedger: Array<number>) => {
          console.log("Referral Historical Entitlements Server Return", payoutLedger );

          this.RT_loading = false;
          

          if(payoutLedger != undefined && payoutLedger != null && payoutLedger.length > 0) {

            this.RT_SampleData = false;

            this.RT_data = {
              data: payoutLedger
            }

          }

          this.update_RT_Data();
        });
        
    } else {
      this.RT_loading = false;
    }

  }

  public expandGraphContainer(): void {
    this.graphContainerExpanded = true;
  }

  public collapseGraphContainer(): void {
    this.graphContainerExpanded = false;
  }

  public expandSalesTracker(): void {
    this.salesTrackerExpanded = true;
  }

  public collapseSalesTracker(): void {
    this.salesTrackerExpanded = false;
  }

}