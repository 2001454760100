import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';

import { NavigatorService } from '../vendor/navigator.service'
import { UserMenuService } from '../pages/user-menu.service'
import { GuidedWalkthroughPage, GuidedWalkthroughStep } from 'src/app/data-models/models';
import { String } from 'aws-sdk/clients/apigateway';

@Injectable({
  providedIn: 'root'
})

export class GuidedWalkthroughService {

  stepListener: any = null;
  isWalkthroughRunning: boolean = false;



  guidedWalkthrough: GuidedWalkthroughPage = {
    page_id: 0,
    title: 'Loading',
    page_walkthrough_completed: false,
    steps: [ ]
  };

  guidedPlatformWalkthrough: GuidedWalkthroughPage = {
    page_id: 0,
    title: 'Loading',
    page_walkthrough_completed: false,
    steps: [ ]
  };

  private activeWalkthrough: GuidedWalkthroughPage = {
    page_id: 0,
    title: 'Loading',
    page_walkthrough_completed: false,
    steps: [ ]
  };

  constructor(
    public http:HttpClient, 
    public realm:MongoService, 
    public router:Router, 
    private userMenuService: UserMenuService,
    private navigationService: NavigatorService) { 

  }

  public setGuidedWalkthrough(walkthrough: GuidedWalkthroughPage): void {
    this.guidedWalkthrough = walkthrough;
  }

  public setGuidedPlatformWalkthrough(walkthrough: GuidedWalkthroughPage): void {
    this.guidedPlatformWalkthrough = walkthrough;
  }

  public initiateGuidedStep(walkthrough?: GuidedWalkthroughPage): void {
    let self: any = this;

    if(!this.isWalkthroughRunning) {

      if(walkthrough != undefined && walkthrough != null) {
        this.guidedWalkthrough = walkthrough;
      }

      this.activeWalkthrough = this.guidedWalkthrough;

      setTimeout(
        function() {
          self.continueGuidedStep(0);
        }, 
      1000);

    }
    
  }

  public initiateGuidedPlatformStep(walkthrough?: GuidedWalkthroughPage): void {
    let self: any = this;

    if(!this.isWalkthroughRunning) {

      if(walkthrough != undefined && walkthrough != null) {
        this.guidedPlatformWalkthrough = walkthrough;
      }

      this.activeWalkthrough = this.guidedPlatformWalkthrough;

      setTimeout(
        function() {
          
          self.continueGuidedStep(0);
        }, 
      1000);

    }
    
  }

  private continueGuidedStep(guidedStep: number): void {
    this.isWalkthroughRunning = true;

    let self = this;
    let stepInstruction: GuidedWalkthroughStep = this.activeWalkthrough.steps[guidedStep];

    if(guidedStep > 0) {
      let previousStepInstruction: GuidedWalkthroughStep = this.activeWalkthrough.steps[guidedStep - 1];

      if(previousStepInstruction.isTopLevel) {
        this.closeTopLevelOverlays();
      }
    }

    if(stepInstruction == undefined || stepInstruction == null) {
      closeGuidedWalkthrough();
      return;
    }

    let guidedWalkthroughOverlayRef: any = document.querySelector("#guidedWalkthroughOverlay");
    let guidedWalkthroughOverlayCloseBtnRef: any = document.querySelector("#closeWalkthrough #clsBtn");
    let guidedWalkthroughMessageContainerRef: any = document.querySelector("#guidedWalkthroughMessageContainer");
    let guidedWalkthroughTitleRef: any = document.querySelector("#guidedWalkthroughMessageTitle");
    let guidedWalkthroughMessageRef: any = document.querySelector("#guidedWalkthroughMessage");
    let guidedWalkthroughOverlayCloseContainerRef: any = document.querySelector("#closeWalkthrough");

    let guidedWalkthroughNextBtn: any = document.querySelector("#nextBtn");
    
    function closeGuidedWalkthrough() {
      if(self.isWalkthroughRunning) {
        console.log("Closing Walkthrough");
        let guidedWalkthroughOverlayRef: any = document.querySelector("#guidedWalkthroughOverlay");

        // let currentElementFocus: any = document.querySelector(".guidedWalkthroughFocus");
        let elementRefs: NodeListOf<any> = document.querySelectorAll(".guidedWalkthroughFocus");
        let elevateParentElementRefs: NodeListOf<any> = document.querySelectorAll(".guidedWalkthroughParentFocus");

        let guidedWalkthroughOverlayCloseBtnRef: any = document.querySelector("#closeWalkthrough #clsBtn");
        let guidedWalkthroughMessageContainerRef: any = document.querySelector("#guidedWalkthroughMessageContainer");
        let guidedWalkthroughTitleRef: any = document.querySelector("#guidedWalkthroughMessageTitle");
        let guidedWalkthroughMessageRef: any = document.querySelector("#guidedWalkthroughMessage");
        let guidedWalkthroughOverlayCloseContainerRef: any = document.querySelector("#closeWalkthrough");
        let guidedWalkthroughNextBtnRef: any = document.querySelector("#nextBtn");
        
        self.isWalkthroughRunning = false;
  
        guidedWalkthroughOverlayCloseBtnRef.removeEventListener("click", closeGuidedWalkthrough, true);
        guidedWalkthroughOverlayRef.classList.remove("d-block");
        guidedWalkthroughMessageContainerRef.classList.remove("d-block");
        guidedWalkthroughOverlayCloseContainerRef.classList.remove("d-block");

        self.eventFire(guidedWalkthroughNextBtnRef, "click");

        for(let elementIndex: number = 0; elementIndex < elementRefs.length; elementIndex++) {

          if (elementRefs[elementIndex].classList.contains("guidedWalkthroughFocus")) {
            elementRefs[elementIndex].classList.remove("guidedWalkthroughFocus");

            if(elementRefs[elementIndex].classList.contains("guidedWalkthroughYellowFocus")) {
              elementRefs[elementIndex].classList.remove("guidedWalkthroughYellowFocus");
            }

            self.eventFire(elementRefs[elementIndex], "click");
          }

        }

        for(let elementIndex: number = 0; elementIndex < elevateParentElementRefs.length; elementIndex++) {

          if (elevateParentElementRefs[elementIndex].classList.contains("guidedWalkthroughParentFocus")) {
            elevateParentElementRefs[elementIndex].classList.remove("guidedWalkthroughParentFocus");
            self.eventFire(elevateParentElementRefs[elementIndex], "click");
          }

        }

        
      }
    }

    if(stepInstruction == undefined || stepInstruction == null && guidedWalkthroughOverlayRef.classList.contains("d-block")) {
      guidedWalkthroughOverlayRef.classList.remove("d-block");
      guidedWalkthroughMessageContainerRef.classList.remove("d-block");
      guidedWalkthroughOverlayCloseContainerRef.classList.remove("d-block");
      guidedWalkthroughOverlayCloseBtnRef.removeEventListener("click", closeGuidedWalkthrough, true);

      if(guidedWalkthroughNextBtn.classList.contains("d-block")) {
        guidedWalkthroughNextBtn.classList.remove("d-block");
      }

    } else {
      guidedWalkthroughTitleRef.textContent = stepInstruction.step_message.title;
      guidedWalkthroughMessageRef.textContent = stepInstruction.step_message.message;

      guidedWalkthroughOverlayRef.classList.add("d-block");
      guidedWalkthroughMessageContainerRef.classList.add("d-block");
      guidedWalkthroughOverlayCloseContainerRef.classList.add("d-block");
      guidedWalkthroughOverlayCloseBtnRef.addEventListener("click", closeGuidedWalkthrough, true);

      if(stepInstruction.isInformational) {
        guidedWalkthroughNextBtn.classList.add("d-block");
      } else {
        if(guidedWalkthroughNextBtn.classList.contains("d-block")) {
          guidedWalkthroughNextBtn.classList.remove("d-block");
        }
      }
    }
    
    if(stepInstruction != undefined && stepInstruction != null) {
      let elevateParentElementRefs: NodeListOf<any> = null;
      let elementRefs: NodeListOf<any> = null;

      if(stepInstruction.openUserMenu) {
        self.openUserMenu();
      }


      if(stepInstruction.elevateParentElement != undefined && stepInstruction.elevateParentElement != null) {
        elevateParentElementRefs = document.querySelectorAll(stepInstruction.elevateParentElement);
      }

      if(stepInstruction.secondary_query_path != undefined && stepInstruction.secondary_query_path != null) {
        elementRefs = document.querySelectorAll(stepInstruction.secondary_query_path);

        if(elementRefs == undefined || elementRefs.length == 0) {
          elementRefs = document.querySelectorAll(stepInstruction.target_query_path);
        }

      } else {
        elementRefs = document.querySelectorAll(stepInstruction.target_query_path);
      }

      if(elementRefs != undefined && elementRefs != null) {

        if(!stepInstruction.isSingleSelection) {
          for(let elementIndex: number = 0; elementIndex < elementRefs.length; elementIndex++) {
            elementRefs[elementIndex].classList.add("guidedWalkthroughFocus");

            if(stepInstruction.highlightYellow) {
              elementRefs[elementIndex].classList.add("guidedWalkthroughYellowFocus");
            }

          }
        } else {
          if(elementRefs.length >= 1) {
            elementRefs[0].classList.add("guidedWalkthroughFocus");

            if(stepInstruction.highlightYellow) {
              elementRefs[0].classList.add("guidedWalkthroughYellowFocus");
            }

          }
        }

        if(elementRefs.length > 0) {

          let elementCoordinates: any = this.getElementCoordinates(elementRefs[0]);

          if(stepInstruction.parentMessageLocation != undefined && stepInstruction.parentMessageLocation != null) {
            let parentElementRef = document.querySelector(stepInstruction.parentMessageLocation);
            elementCoordinates = this.getElementCoordinates(parentElementRef);
          }

          let messageWindowCoordinates: any = this.getElementCoordinates(guidedWalkthroughMessageContainerRef);

          let body = document.body;
          let html = document.documentElement;

          let windowhHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                        html.clientHeight, html.scrollHeight, html.offsetHeight );

          let windowWidth = Math.max( body.scrollWidth, body.offsetWidth, 
                        html.clientWidth, html.scrollWidth, html.offsetWidth );
          
          let left: number = ( (elementCoordinates.position.left - messageWindowCoordinates.position.width) - 32);
          let top: number = elementCoordinates.position.top;

          this.smoothScroll(stepInstruction.target_query_path);

          if(left <= 0) {
            left = elementCoordinates.position.right + 32;

            if(left >= windowWidth || (left + messageWindowCoordinates.position.width) >= windowWidth) {
              left = (windowWidth - messageWindowCoordinates.position.width) - 80;
            }
            
          }

          else if(left >= windowWidth || (left + messageWindowCoordinates.position.width) >= windowWidth) {
            left = (windowWidth - messageWindowCoordinates.position.width) - 80;
          }


          if(top <= 0) {
            top = 80;
          }

          else if(top >= windowhHeight|| (top + messageWindowCoordinates.position.height) >= windowhHeight) {
            top = (windowhHeight - messageWindowCoordinates.position.height) - 80;
          }  

          guidedWalkthroughMessageContainerRef.style.top = top+ "px";
          guidedWalkthroughMessageContainerRef.style.left = left + "px";
        }
      }

      if(elevateParentElementRefs != null && elevateParentElementRefs.length > 0) {
        self.elevateParentElements(elevateParentElementRefs);
      }

      let elementRef: NodeListOf<any> = null;

      if(stepInstruction.secondary_query_path != undefined && stepInstruction.secondary_query_path != null) {
        elementRef = document.querySelectorAll(stepInstruction.secondary_query_path);

        if(elementRef == undefined || elementRef.length == 0) {
          elementRef = document.querySelectorAll(stepInstruction.target_query_path);
        }
      } else {
        elementRef = document.querySelectorAll(stepInstruction.target_query_path);
      }
      

      if(stepInstruction.isInformational) {
        guidedWalkthroughNextBtn.addEventListener("click", waitListen, true);
      } else {
        for(let refIndex: number = 0; refIndex < elementRef.length; refIndex++) {
          elementRef[refIndex].addEventListener("click", waitListen, true);
        }
      }

      

      function waitListen (event) {
        console.log("Guided Walkthrough Listener");
        if(!self.isWalkthroughRunning) {
          event.stopPropagation();
          guidedWalkthroughNextBtn.removeEventListener("click", waitListen, true);
        }

        if(stepInstruction.isInformational) {
          guidedWalkthroughNextBtn.removeEventListener("click", waitListen, true);
        } else {
          for(let refIndex: number = 0; refIndex < elementRef.length; refIndex++) {
            elementRef[refIndex].removeEventListener("click", waitListen, true);
          }
        }


        if(elevateParentElementRefs != undefined && elevateParentElementRefs != null) {

          for(let elementIndex: number = 0; elementIndex < elevateParentElementRefs.length; elementIndex++) {

            if (elevateParentElementRefs[elementIndex].classList.contains("guidedWalkthroughParentFocus")) {
              elevateParentElementRefs[elementIndex].classList.remove("guidedWalkthroughParentFocus");
            }

          }

        }
        

        if(elementRefs != undefined && elementRefs != null) {

          if(!stepInstruction.isSingleSelection) {
            for(let elementIndex: number = 0; elementIndex < elementRefs.length; elementIndex++) {

              if (elementRefs[elementIndex].classList.contains("guidedWalkthroughFocus")) {
                elementRefs[elementIndex].classList.remove("guidedWalkthroughFocus");

                if(elementRefs[elementIndex].classList.contains("guidedWalkthroughYellowFocus")) {
                  elementRefs[elementIndex].classList.remove("guidedWalkthroughYellowFocus");
                }

              }
  
            }
          } else {
            if(elementRefs.length >= 1) {
              if (elementRefs[0].classList.contains("guidedWalkthroughFocus")) {
                elementRefs[0].classList.remove("guidedWalkthroughFocus");

                if(elementRefs[0].classList.contains("guidedWalkthroughYellowFocus")) {
                  elementRefs[0].classList.remove("guidedWalkthroughYellowFocus");
                }

              }
            }
          }

        }

        if(self.isWalkthroughRunning) {
          if(stepInstruction.secondary_query_path != undefined && stepInstruction.secondary_query_path != null) {

            let elementRef: NodeListOf<any> = document.querySelectorAll(stepInstruction.secondary_query_path);

            if(elementRef == undefined || elementRef.length == 0) {
              guidedStep++;
            } else {
              self.activeWalkthrough.steps[guidedStep].secondary_query_path = null;
            }
          } else {
            guidedStep++;
          }

          setTimeout(
          function() {
            let stepInstruction: GuidedWalkthroughStep = self.activeWalkthrough.steps[guidedStep];

            if(stepInstruction == undefined || stepInstruction == null) {
              closeGuidedWalkthrough();
              return;
            }

            if(stepInstruction.isTopLevel) {
              self.continueGuidedStepTopLevel(guidedStep);
            } else {
              self.continueGuidedStep(guidedStep);
            }
          }, 
          200);
        }
      }

      
    }
  }

  private elevateParentElements(elevateParentElementRefs: NodeListOf<any>): void {

    for(let elementIndex: number = 0; elementIndex < elevateParentElementRefs.length; elementIndex++) {
      elevateParentElementRefs[elementIndex].classList.add("guidedWalkthroughParentFocus");
    }

  }

  private clearElevateParentElements(elevateParentElementRefs: NodeListOf<any>): void {

    for(let elementIndex: number = 0; elementIndex < elevateParentElementRefs.length; elementIndex++) {
      elevateParentElementRefs[elementIndex].classList.remove("guidedWalkthroughParentFocus");
    }
    
  }

  private continueGuidedStepTopLevel(guidedStep: number): void {
    this.isWalkthroughRunning = true;

    let self = this;
    let stepInstruction: GuidedWalkthroughStep = this.activeWalkthrough.steps[guidedStep];

    if(stepInstruction == undefined || stepInstruction == null) {
      return;
    }

    let guidedWalkthroughOverlayRef: any = document.querySelector("#guidedWalkthroughOverlayTopLevel");
    let guidedWalkthroughOverlayCloseBtnRef: any = document.querySelector("#closeWalkthroughTopLevel #clsBtn");
    let guidedWalkthroughMessageContainerRef: any = document.querySelector("#guidedWalkthroughMessageContainerTopLevel");
    let guidedWalkthroughTitleRef: any = document.querySelector("#guidedWalkthroughMessageTitleTopLevel");
    let guidedWalkthroughMessageRef: any = document.querySelector("#guidedWalkthroughMessageTopLevel");
    let guidedWalkthroughOverlayCloseContainerRef: any = document.querySelector("#closeWalkthroughTopLevel");


    let dialogOverlayWrapperRef: any = document.querySelector(".cdk-global-overlay-wrapper");
    let dialogOverlayPaneRef: any = document.querySelector(".cdk-overlay-pane");
    
    let guidedWalkthroughNextBtn: any = document.querySelector("#nextBtnTopLevel");
    
    function closeGuidedWalkthrough() {
      if(self.isWalkthroughRunning) {
        let guidedWalkthroughOverlayRefTopLevel: any = document.querySelector("#guidedWalkthroughOverlayTopLevel");

        let elementRefs: NodeListOf<any> = document.querySelectorAll(".guidedWalkthroughFocus");;

        let guidedWalkthroughOverlayCloseBtnRefTopLevel: any = document.querySelector("#closeWalkthroughTopLevel #clsBtn");
        let guidedWalkthroughMessageContainerRefTopLevel: any = document.querySelector("#guidedWalkthroughMessageContainerTopLevel");
        let guidedWalkthroughTitleRefTopLevel: any = document.querySelector("#guidedWalkthroughMessageTitleTopLevel");
        let guidedWalkthroughMessageRefTopLevel: any = document.querySelector("#guidedWalkthroughMessageTopLevel");
        let guidedWalkthroughOverlayCloseContainerRefTopLevel: any = document.querySelector("#closeWalkthroughTopLevel");


        let guidedWalkthroughOverlayRef: any = document.querySelector("#guidedWalkthroughOverlay");
        let guidedWalkthroughOverlayCloseBtnRef: any = document.querySelector("#closeWalkthrough #clsBtn");
        let guidedWalkthroughMessageContainerRef: any = document.querySelector("#guidedWalkthroughMessageContainer");
        let guidedWalkthroughTitleRef: any = document.querySelector("#guidedWalkthroughMessageTitle");
        let guidedWalkthroughMessageRef: any = document.querySelector("#guidedWalkthroughMessage");
        let guidedWalkthroughOverlayCloseContainerRef: any = document.querySelector("#closeWalkthrough");
        let guidedWalkthroughNextBtnRef: any = document.querySelector("#nextBtn");
  
  
        guidedWalkthroughOverlayCloseBtnRefTopLevel.removeEventListener("click", closeGuidedWalkthrough, true);
        guidedWalkthroughOverlayRefTopLevel.classList.remove("d-block");
        guidedWalkthroughMessageContainerRefTopLevel.classList.remove("d-block");
        guidedWalkthroughOverlayCloseContainerRefTopLevel.classList.remove("d-block");
  
        self.isWalkthroughRunning = false;
  
        guidedWalkthroughOverlayCloseBtnRef.removeEventListener("click", closeGuidedWalkthrough, true);
        guidedWalkthroughOverlayRef.classList.remove("d-block");
        guidedWalkthroughMessageContainerRef.classList.remove("d-block");
        guidedWalkthroughOverlayCloseContainerRef.classList.remove("d-block");

        self.eventFire(guidedWalkthroughNextBtnRef, "click");

        for(let elementIndex: number = 0; elementIndex < elementRefs.length; elementIndex++) {

          if (elementRefs[elementIndex].classList.contains("guidedWalkthroughFocus")) {
            elementRefs[elementIndex].classList.remove("guidedWalkthroughFocus");
            self.eventFire(elementRefs[elementIndex], "click");
          }

        }
      }
    }

    if(stepInstruction == undefined || stepInstruction == null && guidedWalkthroughOverlayRef.classList.contains("d-block")) {
      guidedWalkthroughOverlayRef.classList.remove("d-block");
      guidedWalkthroughMessageContainerRef.classList.remove("d-block");
      guidedWalkthroughOverlayCloseContainerRef.classList.remove("d-block");

      if(dialogOverlayWrapperRef != undefined && dialogOverlayPaneRef != undefined) {
        dialogOverlayWrapperRef.classList.remove("z-index-auto");
        dialogOverlayPaneRef.classList.remove("z-index-auto");
      }

      guidedWalkthroughOverlayCloseBtnRef.removeEventListener("click", closeGuidedWalkthrough, true);

      if(guidedWalkthroughNextBtn.classList.contains("d-block")) {
        guidedWalkthroughNextBtn.classList.remove("d-block");
      }

    } else {
      guidedWalkthroughTitleRef.textContent = stepInstruction.step_message.title;
      guidedWalkthroughMessageRef.textContent = stepInstruction.step_message.message;

      guidedWalkthroughOverlayRef.classList.add("d-block");
      guidedWalkthroughMessageContainerRef.classList.add("d-block");
      guidedWalkthroughOverlayCloseContainerRef.classList.add("d-block");

      if(dialogOverlayWrapperRef != undefined && dialogOverlayPaneRef != undefined) {
        dialogOverlayWrapperRef.classList.add("z-index-auto");
        dialogOverlayPaneRef.classList.add("z-index-auto");
      }

      guidedWalkthroughOverlayCloseBtnRef.addEventListener("click", closeGuidedWalkthrough, true);

      if(stepInstruction.isInformational) {
        guidedWalkthroughNextBtn.classList.add("d-block");
      } else {
        if(guidedWalkthroughNextBtn.classList.contains("d-block")) {
          guidedWalkthroughNextBtn.classList.remove("d-block");
        }
      }
    }
    
    if(stepInstruction != undefined && stepInstruction != null) {
      let elementRefs: NodeListOf<any> = null;

      if(stepInstruction.secondary_query_path != undefined && stepInstruction.secondary_query_path != null) {
        elementRefs = document.querySelectorAll(stepInstruction.secondary_query_path);
      } else {
        elementRefs = document.querySelectorAll(stepInstruction.target_query_path);
      }

      if(elementRefs != undefined && elementRefs != null) {

        if(!stepInstruction.isSingleSelection) {
          for(let elementIndex: number = 0; elementIndex < elementRefs.length; elementIndex++) {
            elementRefs[elementIndex].classList.add("guidedWalkthroughFocus");
          }
        } else {
          if(elementRefs.length >= 1) {
            elementRefs[0].classList.add("guidedWalkthroughFocus");
          }
        }

        if(elementRefs.length > 0) {
          let elementCoordinates: any = this.getElementCoordinates(elementRefs[0]);
          let messageWindowCoordinates: any = this.getElementCoordinates(guidedWalkthroughMessageContainerRef);

          let windowhHeight = window.innerHeight;
          
          let left: number = ( (elementCoordinates.position.left - messageWindowCoordinates.position.width) - 32);
          let top: number = elementCoordinates.position.top;

          this.smoothScroll(stepInstruction.target_query_path);

          if(left <= 0) {
            left = elementCoordinates.position.right + 32;
          }

          if(top <= 0) {
            top = 80;
          }

          else if(top >= windowhHeight || (top + messageWindowCoordinates.position.height) >= windowhHeight) {
            top = (windowhHeight - messageWindowCoordinates.position.height) - 80;
          }  

          guidedWalkthroughMessageContainerRef.style.top = top + "px";
          guidedWalkthroughMessageContainerRef.style.left = left + "px";
        }
      }

      let elementRef: any = null;

      if(stepInstruction.secondary_query_path != undefined && stepInstruction.secondary_query_path != null) {
        elementRef = document.querySelector(stepInstruction.secondary_query_path);
      } else {
        elementRef = document.querySelector(stepInstruction.target_query_path);
      }
      

      if(stepInstruction.isInformational) {
        guidedWalkthroughNextBtn.addEventListener("click", waitListen, true);
      } else {
        elementRef.addEventListener("click", waitListen, true);
      }

      

      function waitListen (event) {
        if(!self.isWalkthroughRunning) {
          event.stopPropagation();
          guidedWalkthroughNextBtn.removeEventListener("click", waitListen, true);
        }

        if(stepInstruction.isInformational) {
          guidedWalkthroughNextBtn.removeEventListener("click", waitListen, true);
        } else {
          elementRef.removeEventListener("click", waitListen, true);
        }

        

        if(elementRefs != undefined && elementRefs != null) {

          if(!stepInstruction.isSingleSelection) {
            for(let elementIndex: number = 0; elementIndex < elementRefs.length; elementIndex++) {

              if (elementRefs[elementIndex].classList.contains("guidedWalkthroughFocus")) {
                elementRefs[elementIndex].classList.remove("guidedWalkthroughFocus");
              }
  
            }
          } else {
            if(elementRefs.length >= 1) {
              if (elementRefs[0].classList.contains("guidedWalkthroughFocus")) {
                elementRefs[0].classList.remove("guidedWalkthroughFocus");
              }
            }
          }

        }

        if(self.isWalkthroughRunning) {

          if(stepInstruction.secondary_query_path != undefined && stepInstruction.secondary_query_path != null) {
            self.activeWalkthrough.steps[guidedStep].secondary_query_path = null;
          } else {
            guidedStep++;
          }

          setTimeout(
          function() {
            let stepInstruction: GuidedWalkthroughStep = self.activeWalkthrough.steps[guidedStep];

            if(stepInstruction == undefined || stepInstruction == null) {
              closeGuidedWalkthrough();
              return;
            }

            if(stepInstruction.isTopLevel) {
              self.continueGuidedStepTopLevel(guidedStep);
            } else {
              self.continueGuidedStep(guidedStep);
            }
          }, 
          200);
        }
      }

      
    }
  }

  private closeTopLevelOverlays(): void {
      let guidedWalkthroughOverlayRefTopLevel: any = document.querySelector("#guidedWalkthroughOverlayTopLevel");
      let guidedWalkthroughMessageContainerRefTopLevel: any = document.querySelector("#guidedWalkthroughMessageContainerTopLevel");
      let guidedWalkthroughOverlayCloseContainerRefTopLevel: any = document.querySelector("#closeWalkthroughTopLevel");


      guidedWalkthroughOverlayRefTopLevel.classList.remove("d-block");
      guidedWalkthroughMessageContainerRefTopLevel.classList.remove("d-block");
      guidedWalkthroughOverlayCloseContainerRefTopLevel.classList.remove("d-block");
  }

  private smoothScroll(elementQuery: String) {
    document.querySelector(elementQuery).scrollIntoView({ behavior: 'smooth' });
  }

  private getElementViewPortCoordinates(element: any): any {
    var position = element.getBoundingClientRect();
    let x = position.left;
    let y = position.top;

    let coordinates = {
      position: position,
      x: x,
      y: y
    };

    return coordinates;
  }

  private getElementCoordinates(element: any): any {
    let rect = element.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { 
      position: {
        top: rect.top + scrollTop, 
        left: rect.left + scrollLeft,
        right: rect.right,
        bottom: rect.bottom,

        width: rect.width,
        height: rect.height
      }
    }
  }

  private openUserMenu(): void {
    this.userMenuService.openUserMenu();
  }

  private closeUserMenu(): void {
    this.userMenuService.closeUserMenu();
  }

  private eventFire(el, etype) {

    if(el == undefined && el == null) {
      return;
    }

    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

}