import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-under-development-section-blur",
  templateUrl: "./under-development-section-blur.component.html",
  styleUrls: ["./under-development-section-blur.component.scss"]
})

export class UnderDevelopmenSectionBlurComponent implements OnInit {

  @Input() title: string = "";
  @Input() message: string = "This section is under development and will be available soon!";
  public devModeEnabled: boolean = false;

  constructor() {}

  ngOnInit() {

  }
}