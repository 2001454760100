import { Component, OnInit, ViewChild, AfterViewInit, Input, Output  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ImageHandlerService } from '../../../services/vendor/image-handler.service';
import { NavigatorService, keyTypesEnum } from '../../../services/vendor/navigator.service';
import { InventoryItem, DialogNewAssetWorkOrder, DialogNewAsset, Address, MeterRecording, CMMSLocation, CMMSPartRequest, Page } from '../../../data-models/models';

export enum viewType {
  STANDARD = 0,
  ADMIN = 1
}

@Component({
    selector: 'app-vendor-add-part-request-dialog',
    templateUrl: 'vendor-add-part-request-dialog.component.html',
    styleUrls: ['vendor-add-part-request-dialog.component.scss']
  })

  export class VendorAddPartRequestDialogComponent implements OnInit {
    @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 

    public viewTypes: any = viewType;

    @Input() viewType: number = viewType.STANDARD;
    @Input() userPermissions: Page = null;
   
    constructor(
      private navigatorService: NavigatorService, 
      private imageHandlerService: ImageHandlerService, 
      private fb: FormBuilder, 
      private datePipe: DatePipe, 
      @Inject(MAT_DIALOG_DATA) public partRequestData: any,

      public matDialogRef: MatDialogRef<VendorAddPartRequestDialogComponent>
      ) {
      
    }
  
    ngOnInit() {
      this.matDialogRef.disableClose = true;

      this.viewType = this.partRequestData.view;
      this.userPermissions = this.partRequestData.permissions
    }

    close(): void {
      this.matDialogRef.close();
    }

    public reset() {
   
    }

  }