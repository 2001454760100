import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable, Subscription } from 'rxjs'; 
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'; 
import { Settings, AppSettings } from '../../app.settings';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import { Property, Pagination } from '../../app.models'; 
import { AssetItem, CMMSLocation, InventoryItem, PartAttachBundle } from 'src/app/data-models/models';

import { ImageHandlerService } from '../../services/vendor/image-handler.service';
import { NavigatorService } from '../../services/vendor/navigator.service';
import { VendorSidebarMenuService } from 'src/app/services/vendor/vendor-sidebar-menu.service';


@Component({
  selector: 'app-vendor-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})

export class InventoryComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  public sidenavOpen:boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation:true
  };

  @Input() isPartRequest: boolean = false;
  @Input() pickerView: boolean = false;
  @Input() isPartAttacher: boolean = false;
  @Input() workOrderId: string = "";
  @Input() projectId: string = "";
  @Output() focusedAsset: EventEmitter<any> = new EventEmitter<any>();
  @Output() partAttacherDetailEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() partAttacherAttachEmitter: EventEmitter<PartAttachBundle> = new EventEmitter<PartAttachBundle>();

  
  // Set as any to deal with extra server meta data being thrown on the data
  public assets: Array<any> = [];
  public inventoryImgs: Array<any> = [];

  public viewType: string = 'grid';
  public viewCol: number = 33.3;
  public count: number = 12;
  public sort: string;
  public searchFields: any;
  public removedSearchField: string;
  public pagination:Pagination = new Pagination(1, this.count, null, 2, 0, 0); 
  public message:string;
  public pageId: number = 0;

  public settings: Settings
  public filteredLocations: Array<CMMSLocation> = null;

  public watcher: Subscription;
  private imgSubscription: Subscription;
  private inventorySubscriber: Subscription;
  private inventoryMeterSubscriber: Subscription;
  
  constructor(
    public appSettings:AppSettings, 
    private navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService,
    private sideMenu: VendorSidebarMenuService,
    public mediaObserver: MediaObserver,
    private activatedRoute: ActivatedRoute) {

    this.settings = this.appSettings.settings;    
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => { 
      if (change.mqAlias == 'xs') {
        this.sidenavOpen = false;
        this.viewCol = 100;
      }
      else if(change.mqAlias == 'sm'){
        this.sidenavOpen = false;
        this.viewCol = 50;
      }
      else if(change.mqAlias == 'md'){
        this.viewCol = 50;
        this.sidenavOpen = true;
      }
      else{
        this.viewCol = 33.3;
        this.sidenavOpen = true;
      }
    });

  }

  ngOnInit() {

    this.filteredLocations = this.sideMenu.getFocusedCMMSLocations();

    this.activatedRoute.params.subscribe(params => {   
      if(params['id']) {
        this.pageId = params['id']; 
        console.log("Inventory ID: " + params['id']);
      }
    });

    if(!this.pickerView) {
      this.subscribeToInventory();
    } else {
      this.subscribeToMeteredInventory();
    }
  }

  ngOnDestroy(){ 
    this.watcher.unsubscribe();

    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }

    if(this.inventorySubscriber) {
      this.inventorySubscriber.unsubscribe();
    }

    if(this.inventoryMeterSubscriber) {
      this.inventoryMeterSubscriber.unsubscribe();
    }
  }

  public subscribeToInventory() {   
    this.inventorySubscriber = this.navigatorService.getPagedSearchDistinctBaseInventory("", this.filteredLocations, 0, this.count).subscribe(data => { 
      let result = this.filterData(data); 
      if(!result || result.data.length == 0) {
        this.assets.length = 0;
        this.pagination = new Pagination(1, this.count, null, 2, 0, 0);  
        this.message = 'No Results Found';
        return false;
      } 

      console.log("Inventory Subscriber: ", this.assets);

      this.assets = result.data; 
      this.pagination = result.pagination;
      this.message = null;

      this.getImgs();
    });
  }

  public getInventory() {   
    this.navigatorService.getBaseInventory();
  }

  public subscribeToMeteredInventory() {   
    this.inventoryMeterSubscriber = this.navigatorService.getDistinctMeteredInventory().subscribe(data => { 
      let result = this.filterData(data); 
      if(!result || result.data.length == 0) {
        this.assets.length = 0;
        this.pagination = new Pagination(1, this.count, null, 2, 0, 0);  
        this.message = 'No Results Found';
        return false;
      } 

      this.assets = result.data; 
      this.pagination = result.pagination;
      this.message = null;

      this.getImgs();
      
    })
  }

  public getMeteredInventory() {   
    this.navigatorService.getDistinctMeteredInventory();
  }

  // public getLocalAssets() {   
  //   this.navigatorService.getInitialAssets().subscribe(data => { 
  //     let result = this.filterData(data); 
  //     if(result.data.length == 0) {
  //       this.assets.length = 0;
  //       this.pagination = new Pagination(1, this.count, null, 2, 0, 0);  
  //       this.message = 'No Results Found';
  //       return false;
  //     } 

  //     this.assets = result.data; 
  //     this.pagination = result.pagination;
  //     this.message = null;
  //   })
  // }

  // public updateGalleryLog(): void {
  //   for(let galleryIndex: number = 0; galleryIndex < this.item.lockedFields.gallery.length; galleryIndex++) {
  //     this.gallery.push("");
  //   }
  // }


  public getImgs(): void {
    let imgIds: Array<string> = [];
    this.inventoryImgs = [];

    for(let assetIndex: number = 0; assetIndex < this.assets.length; assetIndex++) {
      imgIds.push(this.assets[assetIndex].lockedFields.gallery[0]);
    }

    this.imageHandler.getAsyncFiles(imgIds).then(imgs => {
      // this.gallery[]

      if(imgs) {
        for(let imgIdIndex: number = 0; imgIdIndex < imgs.length; imgIdIndex++) {
          this.inventoryImgs.push(imgs[imgIdIndex]);
          // this.assets[imgIdIndex].lockedFields.gallery[0] = imgs[imgIdIndex];
        }
      }
    });
    
  }

  public resetPagination(){ 
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.pagination = new Pagination(1, this.count, null, null, this.pagination.total, this.pagination.totalPages);
  }

  public filterData(data){
    return this.navigatorService.filterData(data, this.searchFields, this.sort, this.pagination.page, this.pagination.perPage);
  }

  public focusedOnAsset(barcode: string) {
    if(this.pickerView) {
      this.focusedAsset.emit(barcode);
    }
  }

  public searchClicked(){ 
    this.assets.length = 0;

    if(!this.pickerView) {
      this.getInventory();
    } else {
      this.getMeteredInventory();
    } 

    window.scrollTo(0,0);  
  }

  public searchChanged(event){
    event.valueChanges.subscribe(() => {   
      this.resetPagination(); 
      this.searchFields = event.value;
      setTimeout(() => {      
        this.removedSearchField = null;
      });
      if(!this.settings.searchOnBtnClick){     
        this.assets.length = 0;  
      }            
    }); 
    
    event.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => { 
      if(!this.settings.searchOnBtnClick){     
        if(!this.pickerView) {
          this.getInventory();
        } else {
          this.getMeteredInventory();
        } 
      }
    });       
  } 
  
  public removeSearchField(field){ 
    this.message = null;   
    this.removedSearchField = field; 
  } 


  public changeCount(count){
    this.count = count;   
    this.assets.length = 0;
    this.resetPagination();

    if(!this.pickerView) {
      this.getInventory();
    } else {
      this.getMeteredInventory();
    } 
  }
  public changeSorting(sort){    
    this.sort = sort; 
    this.assets.length = 0;
    
    if(!this.pickerView) {
      this.getInventory();
    } else {
      this.getMeteredInventory();
    } 
  }
  public changeViewType(obj){ 
    this.viewType = obj.viewType;
    this.viewCol = obj.viewCol; 
  } 


  public onPageChange(e){ 
    this.pagination.page = e.pageIndex + 1;
    
    if(!this.pickerView) {
      this.getInventory();
    } else {
      this.getMeteredInventory();
    } 
    
    window.scrollTo(0,0);  
  }

  public openDetailView(barcode: string) {

    if(barcode != null && barcode != undefined) {
      this.partAttacherDetailEmitter.emit(barcode);
    }
  }

  public attachPart(inventoryItemBundle: PartAttachBundle) {
    this.navigatorService.getAsyncSelectBaseInventory(inventoryItemBundle.partBarcode).then(data => {

      if(data != null && data != undefined) {
        let inventoryItem: InventoryItem = data[0];

        if(inventoryItem != null && inventoryItem != undefined) {

          if(!this.isPartRequest) {
            inventoryItem.lockedFields.quantity -= inventoryItemBundle.quantity;
            inventoryItem.lockedFields.reservationList = inventoryItemBundle.reservation;
            
            this.navigatorService.upsertInventory(inventoryItem);
            this.navigatorService.upsertInventoryBase(inventoryItem);
          }
    
          this.partAttacherAttachEmitter.emit(inventoryItemBundle);
        }
      }
    });

    
  }

}