import { Component, OnInit, ViewChild, HostListener, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Settings, AppSettings } from '../app.settings';

import { VendorSidebarMenuService } from '../services/vendor/vendor-sidebar-menu.service'
import { NavigatorService } from '../services/vendor/navigator.service';
import { AutomationsService } from '../services/vendor/automations.service';

// Page Services
import { DispatchConsoleService } from '../services/pages/dispatch-console.service';
import { WorkOrderSchedulerService } from '../services/pages/work-order-scheduler.service';
import { InvoiceService } from '../services/pages/invoice.service';
import { CMMSService } from '../services/pages/cmms.service';
import { FieldOperatorService } from '../services/pages/field-operator.service';

import { VendorAddCMMSLocationDialogComponent } from '../shared/professional/vendor-add-cmms-location-dialog/vendor-add-cmms-location-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CMMSLocation, Profile } from '../data-models/models';
import { HeartBeatService } from '../services/vendor/heartbeat.service';
import { NotificationService } from '../services/vendor/notification.service';


@Component({
  selector: 'app-foreign-viewer-pages',
  templateUrl: './foreign-viewer-pages.component.html',
  styleUrls: ['./foreign-viewer-pages.component.scss']
})
export class ForeignViewPagesComponent implements OnInit {
  public viewMode: number = 0;
  public pageMetaData: Array<any> = [];
  public toolbarTypes = [1, 2];
  public toolbarTypeOption:number;
  public headerTypes = ['default', 'image', 'carousel'];
  public headerTypeOption:string;
  public searchPanelVariants = [1, 2, 3];
  public searchPanelVariantOption:number;
  public headerFixed: boolean = false;
  public showBackToTop: boolean = false;
  public scrolledCount = 0;
  public firstActive: boolean = false;
  public isActiveMessaging: boolean = false;
  public openedChats: number = 0;

  public foreignViewer: boolean = true;

  public footerPageTitle: string;
  public navPage: number = 2;

  public altAppearance: boolean = false;
  
  public settings: Settings;
  
  
  constructor(
    public appSettings:AppSettings, 
    public router:Router,
    private titleService: Title
    
  ) {
    
    this.settings = this.appSettings.settings;  

    // Required in the constructor or the data isn't ready in time for the creation of the DOM to build the necessary elements
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {

        var id = this.getId(this.router.routerState, this.router.routerState.root)[0];
        var title = this.getTitle(this.router.routerState, this.router.routerState.root)[0];

        this.titleService.setTitle(title);

        this.footerPageTitle = title;
        this.navPage = id;

        this.firstActive = true;
      }
    });
  }

  ngOnInit() {


  } 

  ngAfterViewInit() {

    document.getElementById('preloader').classList.add('hide');  

  }  

  ngAfterContentInit() {
    this.initialFooterTitle();
  }

  ngOnChanges() {

  }

  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }

    this.pageMetaData = JSON.parse( JSON.stringify(data) );
    console.log("Router Page Meta Data: ", this.pageMetaData);

    return data;
  }

  getId(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.id) {
      data.push(parent.snapshot.data.id);
    }

    else if(state && parent) {
      data.push(... this.getId(state, state.firstChild(parent)));
    }

    else {
      data.push(0);
    }

    return data;
  }

  public initialFooterTitle() {
      var title = this.getTitle(this.router.routerState, this.router.routerState.root)[0];
      this.titleService.setTitle(title);

      this.footerPageTitle = title;
  }

}