import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html'
})

export class LogoComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() customLogo: SafeUrl = null;


  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    console.log("Logo: ", this.customLogo);

  }

}
