import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords, emailValidator } from 'src/app/theme/utils/app-validators';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Address, CompanyProfile, Contact } from 'src/app/data-models/models';
import { Subscription } from 'rxjs';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';

@Component({
  selector: 'app-vendor-contact',
  templateUrl: 'vendor-contact.component.html',
  styleUrls: ['vendor-contact.component.scss']
})
export class VendorContactComponent implements OnInit, OnChanges {
  @Output('contactUpdate') contactUpdateEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input('contact') contact: Contact | null = null;

  public contactForm: FormGroup;
  public detailsExpanded: boolean = false;
  private keyTypes:any = keyTypesEnum;

  public callInProgress:boolean = false;

  private imgSubscription: Subscription;
  private callInProgressSubscription: Subscription;
  private callIncomingSubscription: Subscription;
  private callOutboundSubscription: Subscription;

  public imgId: string = null;
  public image: any;
  public imageDeleteAction: boolean = false;

  public lockedMode:boolean = true;

  constructor(
    public formBuilder: FormBuilder, 
    public router:Router, 
    public snackBar: MatSnackBar, 
    public navigatorService: NavigatorService, 
    private twilioHandler: TwilioHandlerService,
    private imageHandler: ImageHandlerService) { 

  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      first_name: [ {value: '', disabled: this.lockedMode}, Validators.compose([Validators.required, Validators.minLength(3)])],
      last_name: [ {value: '', disabled: this.lockedMode}],

      company_name: [ {value: '', disabled: this.lockedMode}],
      email: [ {value: '', disabled: this.lockedMode}],
      phone: [ {value: '', disabled: this.lockedMode}, Validators.required],
  
      facebook: [{value: '', disabled: this.lockedMode}],
      twitter: [{value: '', disabled: this.lockedMode}],
      linkedin: [{value: '', disabled: this.lockedMode}],
      instagram: [{value: '', disabled: this.lockedMode}],
      website: [{value: '', disabled: this.lockedMode}],

      street: [ {value: '', disabled: this.lockedMode}],
      city: [ {value: '', disabled: this.lockedMode}],
      state: [ {value: '', disabled: this.lockedMode}],
      zip: [ {value: '', disabled: this.lockedMode}]
    });

    this.initializeForm();

    this.callInProgress = this.twilioHandler.getIncomingCall();
    
    if(!this.callInProgress) {
      this.callInProgress = this.twilioHandler.getCallInitiated();
    }

    this.listeners();

  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.contactForm != undefined) {
      this.initializeForm();
    }

  }

  ngOnDestroy() {

    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }

    if(this.callInProgressSubscription) {
      this.callInProgressSubscription.unsubscribe();
    }

    if(this.callIncomingSubscription) {
      this.callIncomingSubscription.unsubscribe();
    }

    if(this.callOutboundSubscription) {
      this.callOutboundSubscription.unsubscribe();
    }

  }  

  private listeners(): void {

    this.callIncomingSubscription = this.twilioHandler.getIncomingCallTrigger().subscribe( inboundCall => {
      this.callInProgress = inboundCall;
    });

    this.callOutboundSubscription = this.twilioHandler.getOutboundCallTrigger().subscribe( outboundCall => {
      this.callInProgress = outboundCall;
    });

    this.callInProgressSubscription = this.twilioHandler.getCallInitiatedTrigger().subscribe( initiated => {
      this.callInProgress = initiated;
    });

  }

  public initializeForm(): void {

    if(this.contact == undefined || this.contact == null) {
      this.initializeBlankForm();
      return;
    }

    this.contactForm.controls["first_name"].setValue(this.contact.firstName);
    this.contactForm.controls["last_name"].setValue(this.contact.lastName);

    this.contactForm.controls["company_name"].setValue(this.contact.companyName);
    this.contactForm.controls["email"].setValue(this.contact.email);
    this.contactForm.controls["phone"].setValue(this.contact.phone);

    this.contactForm.controls["facebook"].setValue(this.contact.facebook);
    this.contactForm.controls["twitter"].setValue(this.contact.twitter);
    this.contactForm.controls["linkedin"].setValue(this.contact.linkedin);
    this.contactForm.controls["instagram"].setValue(this.contact.instagram);
    this.contactForm.controls["website"].setValue(this.contact.website);

    this.contactForm.controls["street"].setValue(this.contact.address.street);
    this.contactForm.controls["city"].setValue(this.contact.address.city);
    this.contactForm.controls["state"].setValue(this.contact.address.state);
    this.contactForm.controls["zip"].setValue(this.contact.address.zip);

    if(this.lockedMode) {
      this.contactForm.controls["first_name"].disable();
      this.contactForm.controls["last_name"].disable();

      this.contactForm.controls["company_name"].disable();
      this.contactForm.controls["email"].disable();
      this.contactForm.controls["phone"].disable();

      this.contactForm.controls["facebook"].disable();
      this.contactForm.controls["twitter"].disable();
      this.contactForm.controls["linkedin"].disable();
      this.contactForm.controls["instagram"].disable();
      this.contactForm.controls["website"].disable();

      this.contactForm.controls["street"].disable();
      this.contactForm.controls["city"].disable();
      this.contactForm.controls["state"].disable();
      this.contactForm.controls["zip"].disable();
    } else {
      this.contactForm.controls["first_name"].enable();
      this.contactForm.controls["last_name"].enable();

      this.contactForm.controls["company_name"].enable();
      this.contactForm.controls["email"].enable();
      this.contactForm.controls["phone"].enable();

      this.contactForm.controls["facebook"].enable();
      this.contactForm.controls["twitter"].enable();
      this.contactForm.controls["linkedin"].enable();
      this.contactForm.controls["instagram"].enable();
      this.contactForm.controls["website"].enable();

      this.contactForm.controls["street"].enable();
      this.contactForm.controls["city"].enable();
      this.contactForm.controls["state"].enable();
      this.contactForm.controls["zip"].enable();
    }

  }

  public initializeBlankForm(): void {
    this.contactForm.controls["first_name"].setValue(null);
    this.contactForm.controls["last_name"].setValue(null);

    this.contactForm.controls["company_name"].setValue(null);
    this.contactForm.controls["email"].setValue(null);
    this.contactForm.controls["phone"].setValue(null);

    this.contactForm.controls["facebook"].setValue(null);
    this.contactForm.controls["twitter"].setValue(null);
    this.contactForm.controls["linkedin"].setValue(null);
    this.contactForm.controls["instagram"].setValue(null);
    this.contactForm.controls["website"].setValue(null);

    this.contactForm.controls["street"].setValue(null);
    this.contactForm.controls["city"].setValue(null);
    this.contactForm.controls["state"].setValue(null);
    this.contactForm.controls["zip"].setValue(null);

    this.contactForm.controls["first_name"].enable();
    this.contactForm.controls["last_name"].enable();

    this.contactForm.controls["company_name"].enable();
    this.contactForm.controls["email"].enable();
    this.contactForm.controls["phone"].enable();

    this.contactForm.controls["facebook"].enable();
    this.contactForm.controls["twitter"].enable();
    this.contactForm.controls["linkedin"].enable();
    this.contactForm.controls["instagram"].enable();
    this.contactForm.controls["website"].enable();

    this.contactForm.controls["street"].enable();
    this.contactForm.controls["city"].enable();
    this.contactForm.controls["state"].enable();
    this.contactForm.controls["zip"].enable();
    
  }

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {

      if(img) {
        this.image = img;

        console.log("Image: ", this.image);
      }

    });
    
  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getFile(this.imgId);
    }
  }

  public uploadImage(image: any, folder: string): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique, folder).then(data => {
          this.imgId = imgIdUnique;
          this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
  }

  public deleteImg(): void {
    this.contactForm.controls["image"].setValue("");
    this.image = null;
    this.imageDeleteAction = true;
  }

  public expandDetailsToggle(): void {

    if(this.detailsExpanded) {
      this.detailsExpanded = false;
    } else {
      this.detailsExpanded = true;
    }
  
  }

  public editToggle(): void {
    if(this.lockedMode) {
      this.lockedMode = false;
    } else {
      this.lockedMode = true;
    }

    this.initializeForm();
  }

  public makeOutboundCall(): void {

    let phone: string = this.contactForm.get("phone").value;

    this.twilioHandler.setPhoneNumber(phone);
    this.twilioHandler.makeOutboundCall(phone);
  }

  public disconnectOutboundCall(): void {
    this.twilioHandler.disconnectCall()
  }

  public onRegisterFormSubmit():void {
    if (this.contactForm.valid) {
      let contactId: string = this.navigatorService.generateKey(this.keyTypes.COMPANY_ID);

      if(this.contact != undefined && this.contact != null) {
        contactId = this.contact.id;
      }

      let address: Address = {
        street: this.contactForm.get("street").value,
        city: this.contactForm.get("city").value,
        state: this.contactForm.get("state").value,
        zip: this.contactForm.get("zip").value
      }

      let contact: Contact = {
        id: contactId,

        firstName: this.contactForm.get("first_name").value,
        lastName: this.contactForm.get("last_name").value,

        companyName: this.contactForm.get("company_name").value,

        email: this.contactForm.get("email").value,
        phone: this.contactForm.get("phone").value,
        address: address,
        linkedin: this.contactForm.get("linkedin").value,
        twitter: this.contactForm.get("twitter").value,
        instagram: this.contactForm.get("instagram").value,
        facebook: this.contactForm.get("facebook").value,
        website: this.contactForm.get("website").value,

        createdBy: this.navigatorService.getProfileId(),
        createdDate: new Date
      }

      this.navigatorService.upsertContact(contact).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Contact Updated!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.contactUpdateEmitter.emit();
        }

      });

    }
  }

}
