import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AgmOverlays } from "agm-overlays"
import { AgmCoreModule } from '@agm/core';
import { EmbedVideo } from 'ngx-embed-video'; 
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
const config: InputFileConfig = {
  fileAccept: '*'
};

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';


// import { GoldenLayoutModule } from './shared/golden-layout/golden-layout.module'
// import { GoldenLayoutBaseComponent } from './shared/golden-layout/golden-layout-base.component'

import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
// import { VendorModule } from './vendor/vendor.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { AppInterceptor } from './theme/utils/app-interceptor';


import { PagesComponent } from './base/pages.component';
import { VendorPagesComponent } from './vendor/vendor-pages.component';
import { ForeignViewPagesComponent } from './foreign-viewer/foreign-viewer-pages.component';
import { NotFoundComponent } from './base/not-found/not-found.component';
import { VendorToolbar1Component } from './theme/components/vendor-toolbar1/vendor-toolbar1.component';
import { VendorShiftMenuComponent } from './theme/components/vendor-shift-menu/vendor-shift-menu.component';
import { VendorInventoryMenuComponent } from './theme/components/vendor-inventory-menu/vendor-inventory-menu.component';
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { Toolbar2Component } from './theme/components/toolbar2/toolbar2.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { VendorMenuComponent } from './theme/components/vendor-menu/vendor-menu.component';
import { CurrencyComponent } from './theme/components/currency/currency.component';
import { LangComponent } from './theme/components/lang/lang.component';
import { SocialIconsComponent } from './theme/components/social-icons/social-icons.component';
import { ContactsComponent } from './theme/components/contacts/contacts.component'; 
import { VendorHorizontalMenuComponent } from './theme/components/menu/vendor-horizontal-menu/vendor-horizontal-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { VendorFooterComponent } from './theme/components/vendor-footer/vendor-footer.component';
import { LockScreenComponent } from './base/lock-screen/lock-screen.component';

// CALENDAR IMPORTS, MAY NEED TO MOVE THIS OUT
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    VendorPagesComponent,
    ForeignViewPagesComponent,
    NotFoundComponent,
    UserMenuComponent,
    VendorMenuComponent,
    CurrencyComponent,
    LangComponent,
    SocialIconsComponent,
    ContactsComponent, 
    VendorToolbar1Component,
    VendorShiftMenuComponent,
    VendorInventoryMenuComponent,
    Toolbar1Component,
    Toolbar2Component,
    VendorHorizontalMenuComponent,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    FooterComponent,
    VendorFooterComponent,
    LockScreenComponent
  ],

  imports: [
    BrowserModule, 
    BrowserAnimationsModule, 
    FormsModule, 
    HttpClientModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      // Testing Key
      // AIzaSyA1rF9bttCxRmsNdZYjW7FzIoyrul5jb-s
      // apiKey: '',

      // Commercial Key
      apiKey: environment.googleMapAPI,
      libraries: ["places"]
    }),

    EmbedVideo.forRoot(),
    NgProgressModule,
    NgProgressHttpModule, 
    InputFileModule.forRoot(config),
    AppRoutingModule,
    SharedModule,
    UserModule
    
    // CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    // NgbModule
    // VendorModule
  ],

  providers: [
    AppSettings,
    DatePipe,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
