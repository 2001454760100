import { DOCUMENT } from '@angular/common';
import { Component, ComponentRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DomService } from '../../services/dom-service'
import { VendorCrossModuleReportContainerComponent } from '../professional/vendor-cross-module-report-container/vendor-cross-module-report-container.component';
// import * as 'golden-layout'
// import * as $ from 'jquery'
declare var GoldenLayout: any
declare var $: any

@Component({
  selector: 'app-golden-layout-base',
  templateUrl: './golden-layout-base.component.html',
  styleUrls: ['./golden-layout-base.component.scss']
})
export class GoldenLayoutBaseComponent implements OnInit {
  componentRef: ComponentRef<any>;

  public config = {
    content: [{
        type: 'row',
        content:[
          {
            type: 'component',
            componentName: 'Report',
            componentState: { label: 'A' }
          },
          {
            type: 'column',
            content:[
              {
                type: 'component',
                componentName: 'Report',
                componentState: { label: 'B' }
              },
              {
                type: 'column',
                content:[
                  {
                    type: 'component',
                    componentName: 'Report',
                    componentState: { label: 'C' }
                  },
                  {
                    type: 'row',
                    content:[
                      {
                        type: 'component',
                        componentName: 'Report',
                        componentState: { label: 'D' }
                      },
                      {
                        type: 'component',
                        componentName: 'Report',
                        componentState: { label: 'E' }
                      },
                      {
                        type: 'component',
                        componentName: 'Report',
                        componentState: { label: 'F' }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }]
  };

  public myLayout = null;
  
  constructor(private domService: DomService) { }

  ngOnInit() {

    
  }

  ngAfterViewInit() {  
    let self = this;

    this.myLayout = new GoldenLayout( this.config, $('#goldenLayoutContainer') );

    this.myLayout.registerComponent( 'Report', function( container, componentState ) {

      let element = container.getElement();

      self.componentRef = self.domService
         .createComponent(
          VendorCrossModuleReportContainerComponent,
              element[0],
              { name: componentState.label },
              'app-vendor-cross-module-report'
            );

    });

    this.myLayout.init();

  }

  ngOnDestroy(): void {
    this.domService.detachComponent(this.componentRef);
  }

}