import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address, CRMActivity, CRMDeal, CRMPOC } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { ActivityLogCategories, ActivityTypes } from '../professional/vendor-crm-deal/vendor-crm-deal.component';

enum TimePickerTypes {
  SCHEDULED = 0,
  EST_COMPLETE = 1
}

@Component({
  selector: 'app-new-deal-activity',
  templateUrl: './new-deal-activity.component.html',
  styleUrls: ['./new-deal-activity.component.scss']
})

export class NewDealActivityComponent implements OnInit {
  @Input() deal: CRMDeal = null;
  @Input() activity: CRMActivity = null;
  @Output() publishLog: EventEmitter<CRMActivity> = new EventEmitter<CRMActivity>();


  public activityTypes: any = ActivityTypes;
  public activityLogCategories: any = ActivityLogCategories;

  public test: string = "Hello";
  public activeMeetingType: number = this.activityTypes.MEETING;
  public assignedDealId: string = "";
  public assignedStaff: Array<string> = [];
  public assignedContacts: Array<CRMPOC> = [];

  public activeNoteContent: string = "";
  public contactPanelExpanded: boolean = false;

  public timePickerTypes: any = TimePickerTypes;
  public timePickerFocus: number = this.timePickerTypes.SCHEDULED;
  public startTimeScheduled: any;
  public endTimeScheduled: any;
  public dateScheduled: Date;
  public endDateScheduled: Date;

  public autoMinuteAdjusted: boolean = false;

  public activityForm: FormGroup;

  public quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link', 'image', 'video']                         // link and image, video
    ],
    mention: {
      mentionListClass: "ql-mention-list mat-elevation-z8",
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: false,
      spaceAfterInsert: false,
      onSelect: (item, insertItem) => {
        // const editor = this.editor.quillEditor;
        insertItem(item);

        console.log("Quil Item: ", item);
        console.log("Quil Item: ", insertItem);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        // editor.insertText(editor.getLength() - 1, "", "user");
      },
      source: (searchTerm, renderList) => {
        const values = [
          { id: 1, value: "Fredrik Sundqvist", age: 5 },
          { id: 2, value: "Patrik Sjölin", age: 20 }
        ];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach(entry => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      }
    }

  };

  constructor(private navigatorService: NavigatorService, public formBuilder: FormBuilder) {

  }

  ngOnInit() {

    let start: Date = new Date();
    let end: Date = new Date();

    this.activityForm = this.formBuilder.group({

      deal: [ {value: this.deal?.id, disabled: true}, Validators.compose([Validators.required, Validators.minLength(3)])],

      first_name: [ {value: '', disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)])],
      last_name: [ {value: '', disabled: false}],

      poc_company_name: [ {value: '', disabled: false}],
      email: [ {value: '', disabled: false}],
      phone: [ {value: '', disabled: false}, Validators.required],

      title: [ {value: '', disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)]) ],
      type: [ {value: null, disabled: false} ],
      organization: [ {value: '', disabled: false}],

      startDate: [ { value: start, disabled: true }, Validators.required ],
      endDate: [ { value: end, disabled: true }, Validators.required ],
      startTime: [ { value: "", disabled: false }, Validators.required ],
      endTime: [ { value: "", disabled: false }, Validators.required ],
      timeStretch: [ { value: 0, disabled: true }, Validators.required ],
      allDay: [ { value: false, disabled: false } ],

      street: [ {value: '', disabled: false} ],
      city: [ {value: '', disabled: false} ],
      state: [ {value: '', disabled: false} ],
      zip: [ {value: '', disabled: false} ],

      createdDate: [ {value: new Date, disabled: false} ],
      lastUpdatedDate: [ {value: new Date, disabled: false} ]

    });
    

    if(this.activity != null) {
      this.initializeActivity();
    }

  }

  private initializeActivity(): void {
    this.activeMeetingType = this.activity.data.content.type;
    this.assignedDealId = this.activity.data.content.id;
    this.assignedStaff = this.activity.data.content.assignedStaff;
    this.assignedContacts = this.activity.data.content.assignedContacts;
    this.activeNoteContent = this.activity.data.content.notes;

    this.startTimeScheduled = this.activity.data.content.startTime;
    this.endTimeScheduled = this.activity.data.content.endTime;
    this.dateScheduled = this.activity.data.content.start;
    this.endDateScheduled = this.activity.data.content.end;

    this.activityForm.get("deal").setValue(this.deal);
    this.activityForm.get("title").setValue(this.activity.data.content.title);

    this.activityForm.get("startDate").setValue(this.activity.data.content.start);
    this.activityForm.get("endDate").setValue(this.activity.data.content.end);
    this.activityForm.get("startTime").setValue(this.activity.data.content.startTime);
    this.activityForm.get("endTime").setValue(this.activity.data.content.endTime);
    this.activityForm.get("timeStretch").setValue(this.activity.data.content.timeStretch);
    this.activityForm.get("allDay").setValue(this.activity.data.content.allDay);

    this.activityForm.get("organization").setValue(this.activity.data.content.organization);
  }

  public editorChanged(event: any) {
    console.log("Editor Changed: ", event);

    if(event.event == 'selection-change') {

    } else {
      this.activeNoteContent = event.html;
    }

  }


  public expandContactPanel(): void {
    this.contactPanelExpanded = true;
  }

  public collapseContactPanel(): void {
    this.contactPanelExpanded = false;
  }

  public assignNewContact(): void {

    let address: Address = {
      street: this.activityForm.get("street").value,
      city: this.activityForm.get("city").value,
      state: this.activityForm.get("state").value,
      zip: this.activityForm.get("zip").value
    }

    let contact: CRMPOC = {
      id: this.navigatorService.generateKey(),
      first_name: this.activityForm.get("first_name").value,
      last_name: this.activityForm.get("last_name").value,
      company_name: this.activityForm.get("poc_company_name").value,

      email: this.activityForm.get("email").value,
      phone: this.activityForm.get("phone").value,
      address: address,
      tags: []
    }

    this.activityForm.get("first_name").setValue("");
    this.activityForm.get("last_name").setValue("");
    this.activityForm.get("poc_company_name").setValue("");

    this.activityForm.get("email").setValue("");
    this.activityForm.get("phone").setValue("");
    this.activityForm.get("street").setValue("");
    this.activityForm.get("city").setValue("");
    this.activityForm.get("state").setValue("");
    this.activityForm.get("zip").setValue(null);

    console.log("Assigned Contact: ", contact);
    this.assignedContacts.push(contact);
    this.collapseContactPanel();
  }

  public cancelNewContact(): void {

    this.activityForm.get("first_name").setValue("");
    this.activityForm.get("last_name").setValue("");
    this.activityForm.get("poc_company_name").setValue("");

    this.activityForm.get("email").setValue("");
    this.activityForm.get("phone").setValue("");
    this.activityForm.get("street").setValue("");
    this.activityForm.get("city").setValue("");
    this.activityForm.get("state").setValue("");
    this.activityForm.get("zip").setValue(null);

    this.collapseContactPanel();
  }

  public changeActivityType(type: number): void {
    this.activeMeetingType = type;
  }

  stringTimeToDate(time: string, date: Date): Date {

    if(
      date == undefined || 
      date == null) {
      return null;
    }

    if(
      time == undefined || 
      time == null) {

        time = "";

    }

    let reconstructedDate: Date = date;

    if(time.length > 0) {
      let parts, hours, minutes,
      timeReg = /(\d+)\:(\d+) (\w+)/;
      parts = time.match(timeReg);
      
      hours = /am/i.test(parts[3]) ?
          function(am) {return am < 12 ? am : 0}(parseInt(parts[1], 10)) :
          function(pm) {return pm < 12 ? pm + 12 : 12}(parseInt(parts[1], 10));
      
      minutes = parseInt(parts[2], 10);

      reconstructedDate.setHours(hours);
      reconstructedDate.setMinutes(minutes);
      
      console.log(time + ' => ' + reconstructedDate);
    }

    return reconstructedDate;
  }

  updateScheduleDate(): void {

    if(this.activityForm.get("startDate").value != undefined && !this.autoMinuteAdjusted) {
      let startDate: Date = new Date(this.activityForm.get("startDate").value);

      this.dateScheduled = this.stringTimeToDate(this.startTimeScheduled, startDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  updateEndDate(): void {

    if(this.activityForm.get("endDate").value != undefined && !this.autoMinuteAdjusted) {
      let endDate: Date = new Date(this.activityForm.get("endDate").value.setHours(0, 0, 0, 0));

      this.endDateScheduled = this.stringTimeToDate(this.endTimeScheduled, endDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  public changeTimePicker(picker: number): void {

    if(this.timePickerFocus != picker) {
      this.timePickerFocus = picker;
    }

  }

  updateScheduleTime(scheduledTime): void {
    this.activityForm.get("startTime").setValue(scheduledTime);
    this.startTimeScheduled = scheduledTime;
    this.updateScheduleDate();

    console.log("Date Scheduled: ", this.dateScheduled);
  }

  updateEndTime(endTime): void {
    this.activityForm.get("endTime").setValue(endTime);
    this.endTimeScheduled = endTime;
    this.updateEndDate();

    console.log("Date End: ", this.endTimeScheduled);
  }

  setEndTime(): void {

    let hours: number = this.endDateScheduled.getHours();
    let minutes: number = this.endDateScheduled.getMinutes();
    let ampm: string = "AM";

    if(hours > 12) {
      ampm = "PM";
      hours -= 12;
    }

    this.endTimeScheduled = hours + ":" + minutes + " " + ampm;
    console.log("End Time: ", this.endTimeScheduled);

  }

  minutesRequiredChange(minutes: number): void {
    
    this.activityForm.get("timeStretch").setValue(minutes);
    console.log("Minutes Req. Update: ", minutes);

    this.autoUpdateEndDateByMinutes();

  }

  autoUpdateEndDateByMinutes(): void {
    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null) {
        this.dateScheduled = new Date();
    }

    if(
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        this.endDateScheduled = new Date();
    }


    let startMili: number = this.dateScheduled.getTime();
    let minutes: number = Number.parseInt(this.activityForm.get("timeStretch").value);
    let minutesRegMili: number = (minutes * 1000) * 60;

    let diffMili: number = minutesRegMili + startMili;

    this.endDateScheduled.setTime(diffMili);

    console.log("Auto Date Start: ", this.dateScheduled); 
    console.log("Auto Date End: ", this.endDateScheduled);   

    this.autoMinuteAdjusted = true;
    this.activityForm.get("startDate").setValue(this.dateScheduled);

    this.autoMinuteAdjusted = true;
    this.activityForm.get("endDate").setValue(this.endDateScheduled);

    this.setEndTime();
  }

  updateMinutesRequired(): void {

    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null || 
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        return;
    }


    let startMili: number = this.dateScheduled.getTime();
    let endMili: number = this.endDateScheduled.getTime();

    let diffMili: number = endMili - startMili;

    let seconds: number = diffMili / 1000;
    let minutes: number = seconds / 60;

    this.activityForm.get("timeStretch").setValue(minutes);

    console.log("Minute Diff: ", minutes);
  }

  public publishActivity(): void {

    let id: string = this.navigatorService.generateKey();

    this.dateScheduled = this.stringTimeToDate(this.activityForm.get("startTime").value, this.activityForm.get("startDate").value);

    if(this.activity != undefined && this.activity != null) {
      id = this.activity.id;
    }

    let content: any = {
      deal: this.activityForm.get("first_name").value,
      type: this.activeMeetingType,
      assignedContacts: this.assignedContacts,
      assignedStaff: this.assignedStaff,

      title: this.activityForm.get("title").value,
      organization: this.activityForm.get("organization").value,
      desc: "",
      notes: this.activeNoteContent,

      start: this.activityForm.get("startDate").value,
      end: this.activityForm.get("endDate").value,
      startTime: this.activityForm.get("startTime").value,
      endTime: this.activityForm.get("endTime").value,
      timeStretch: this.activityForm.get("timeStretch").value,
      allDay: this.activityForm.get("allDay").value,

      address: {
        street: this.activityForm.get("street").value,
        city: this.activityForm.get("city").value,
        state: this.activityForm.get("state").value,
        zip: this.activityForm.get("zip").value
      },

      createdDate: this.activityForm.get("createdDate").value,
      lastUpdatedDate: this.activityForm.get("lastUpdatedDate").value
    }

    let activity: CRMActivity = {
      id: id,
      type: this.activityLogCategories.ACTIVITY,
      data: {
        content: content
      },
      scheduleDate: this.dateScheduled,
      lastUpdated: new Date()
    }


    console.log("Activity: ", activity);
    this.publishLog.emit(activity);
  }

}
