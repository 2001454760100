import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterNeighborhoodsPipe } from './filter-neighborhoods';
import { FilterStreetsPipe } from './filter-streets.pipe';
import { PhoneMaskPipe } from './phone-mask.pipe'
import { WorkOrderIdMaskPipe } from './work-order-id-mask.pipe'
import { SanitizeHTMLPipe } from './sanitizeHTML.pipe'

@NgModule({
    imports: [ 
        CommonModule 
    ],
    declarations: [
        FilterByIdPipe,
        FilterNeighborhoodsPipe,
        FilterStreetsPipe,
        PhoneMaskPipe,
        WorkOrderIdMaskPipe,
        SanitizeHTMLPipe
    ],
    exports: [
        FilterByIdPipe,
        FilterNeighborhoodsPipe,
        FilterStreetsPipe,
        PhoneMaskPipe,
        WorkOrderIdMaskPipe,
        SanitizeHTMLPipe
    ]
})
export class PipesModule { }
