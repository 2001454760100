import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, Input, EventEmitter  } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { GroupPermissions, Profile, TechWorkOrders, WorkOrder } from '../../../data-models/models';

import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';

export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
  }
  
  /** Constants used to fill up our data base. */
  const COLORS: string[] = [
    'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
    'aqua', 'blue', 'navy', 'black', 'gray'
  ];
  const NAMES: string[] = [
    'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
    'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
  ];

@Component({
  selector: 'app-vendor-employee-expandable-table',
  templateUrl: 'vendor-employee-expandable-table.component.html',
  styleUrls: ['vendor-employee-expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class VendorEmployeeExpandableTableComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['name', 'description', 'priority', 'status'];
    dataSource: MatTableDataSource<Profile>;
    expandedElement: UserData | null;
    public statusOptions: Array<any> = [];
    public priorityOptions: Array<any> = [];

    public employees: Array<any> = [];
    public employeeIds: Array<string> = [];
    public workOrders: Array<any> = [];
    public groupFocusWorkOrders: Array<any> = [];

    public imgId: string = null;
    public image: any;

    public activeWorkOrders: Array<any> = [];

    public technicianWorkOrderColumns: string[] = ['workOrderId', 'description', 'priority', 'status'];

    private dropDownValueKeys: Array<any> = [
      { key: "isPaid", function: this.navigatorService.getPaidOptions() },
      { key: "status", function: this.navigatorService.getStatusOptions() },
      { key: "priority", function: this.navigatorService.getPriorityOptions() },
      { key: "skillLvl", function: this.navigatorService.getSkillLvlOptions() },
      { key: "isCompleted", function: this.navigatorService.getWorkCompletedOptions() }
    ];
  
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Output() groupWorkOrderFocus: EventEmitter<Array<WorkOrder>> = new EventEmitter<Array<WorkOrder>>();
    @Output() groupEmployeeFocus: EventEmitter<Array<Profile>> = new EventEmitter<Array<Profile>>();
    @Output() groupEmployeeIdsFocus: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;

    employeesSubscription: Subscription;
    workOrderSubscription: Subscription;
    imgSubscription: Subscription;

    //============== Groups/Permissions =================//
    private groupPermissionSubscriber: Subscription;
    public groupPermissions: Array<GroupPermissions> = [];
    
    groupOptionSelected: number = 0;
    public groupFocusIndex = 0;
    public groupOptions: Array<any> = [];

    public employeeGroup: Array<Profile> = [];
    public employeeGroupProfileImgs: Array<string> = [];
    //------------ End Groups/Permissions ---------------//
  
    constructor(
      private changeDetectorRef: ChangeDetectorRef, 
      private navigatorService: NavigatorService, 
      public dialog: MatDialog,
      private datePipe: DatePipe, 
      private imageHandler: ImageHandlerService
    ) {
        // Create 100 users
      // const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

  
      
     }
  
    ngOnInit(): void {
      // Assign the data to the data source for the table to render
      // this.dataSource = new MatTableDataSource([]);
      this.employeeIds.push(this.navigatorService.getProfileId());

      this.imgSubscriber();
      this.subscribeToEmployees();
      
      
      this.statusOptions = this.navigatorService.getStatusOptions();
      this.priorityOptions = this.navigatorService.getPriorityOptions();
    }
  
    ngAfterViewInit(): void {
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    }

    ngOnDestroy() {
      if(this.workOrderSubscription) {
        this.workOrderSubscription.unsubscribe();
      }

      if(this.imgSubscription) {
        this.imgSubscription.unsubscribe();
      }

      if(this.employeesSubscription) {
        this.employeesSubscription.unsubscribe();
      }

      if(this.groupPermissionSubscriber) {
        this.groupPermissionSubscriber.unsubscribe();
      }
    }  

    subscribeToEmployees(): void {
      this.navigatorService.getAsyncAllEmployees().then(data => { 
        // let result = this.filterData(data); 
        let employees: any = data;

        // Resetting array
        this.employees = [];
  
        if(data != null) {    
          for(let index = 0; index < data.length; index++) {   
            this.employees.push(employees[index]);
          }
          console.log("expandable table employees: ", this.employees);

          this.subscribeToGroupPermissions();

          this.refresh();
        }
      });
    }

    subscribeToDailyWorkOrders(): void {
      // this.navigatorService.getDateRangeOpenWorkOrders(new Date, new Date).subscribe(data => { 
        this.workOrderSubscription = this.navigatorService.getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders(new Date, new Date, "", this.employeeIds, 0, 100).subscribe(data => { 
        
        // Resetting array
        this.workOrders = [];
  
        if(data != null) {
          for(let index = 0; index < data.length; index++) {
            this.workOrders.push(data[index]);
          }

          this.initializeActiveWorkOrders();
        }     
  
        this.setGroupFilter(0);
        this.changeDetectorRef.detectChanges();
      });
    }

    getDailyWorkOrders(): void {
      this.navigatorService.getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders(new Date, new Date, "", this.employeeIds, 0, 100);
    }

    subscribeToGroupPermissions(): void {
      console.log("Subscribe To Permissions");
  
      this.groupPermissionSubscriber = this.navigatorService.getGroupPermissions().subscribe(data => { 
        // let result = this.filterData(data); 
        let employees: any = data;
  
        if(data != null) {
          console.log("Returned Group Permissions: ", data);
          this.groupPermissions = data;
  
          this.updateGroupOptions();
          this.setGroupFilter(0);

          this.subscribeToDailyWorkOrders();
        }
      });
    }
  
    getGroupPermissions(): void {
      console.log("Get Permissions");
      this.navigatorService.getGroupPermissions();
    }
  
    updateGroupOptions(): void {
      this.groupOptions = [];
  
      for(let group of this.groupPermissions) {
        this.groupOptions.push(group.group);
      }
    }
  
    setGroupFilter(groupIndex: number) {
      console.log("Group Filter: ", groupIndex);
      this.groupFocusIndex = groupIndex;
  
      this.updateGroupFocusedEmployees();
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
      
    }

    updateGroupFocusedEmployees(): void {
      this.employeeGroup = [];
      this.employeeIds = [];
  
      console.log("Update Group Change: ", this.employees);

      for(let employeeIndex: number = 0; employeeIndex < this.employees.length; employeeIndex++) {
  
        // If the focused on group contains the current employee then add it to it's array
        if(this.groupPermissions[this.groupFocusIndex].employeeIds.indexOf(this.employees[employeeIndex].id) > -1) {
          this.employeeGroup.push(this.employees[employeeIndex]);
          this.employeeIds.push(this.employees[employeeIndex].id);
        }
      }

      this.groupEmployeeIdsFocus.emit(this.employeeIds);
      this.groupEmployeeFocus.emit(this.employeeGroup);

      this.updateWorkOrderFocus();
      this.refresh();
  
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }

    updateWorkOrderFocus(): void {

      this.groupFocusWorkOrders = [];

      for(let workOrder of this.workOrders) {

        for(let employee of this.employeeGroup) {

          if(workOrder.assignedStaff.includes(employee.id)) {
            this.groupFocusWorkOrders.push(workOrder);
          }

        }

      }
      
      this.groupWorkOrderFocus.emit(this.groupFocusWorkOrders);
    }

    public imgSubscriber(): void {

      this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {
  
        if(img) {
          this.image = img;
  
          console.log("Image: ", this.image);
        }
  
      });
      
    }
  
    public getImg(): void {
      if(this.imgId != undefined && this.imgId.length > 0) {
        this.imageHandler.getFile(this.imgId);
      }
    }

    refresh() {
      
      // this.dataSource.data = this.employees;

      this.dataSource = new MatTableDataSource(this.employeeGroup);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();

      
    }

    rowClick(expandedElement: any, employee: Profile): any {
      this.imgId = employee.image;
      this.getImg();

      return expandedElement === employee ? null : employee;
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    getEmployeeWorkOrders(employee: Profile): Array<WorkOrder> {
      let employeeId: string = employee.id;
      let employeeFocusedWorkOrders: Array<WorkOrder> = [];

      for(let workOrder of this.workOrders) {
        
        if(workOrder.technicianId == employeeId) {
          employeeFocusedWorkOrders.push(workOrder);
        }

        if(workOrder.assignedStaff != undefined && workOrder.assignedStaff != null) {
          for(let teamMember of workOrder.assignedStaff) {
            if(teamMember == employeeId) {
              employeeFocusedWorkOrders.push(workOrder);
              break;
            }
          }
        }

        // Group Assignment
        // for(let teamMember of workOrder?.assignedTeam) {
        //   if(teamMember == employeeId) {
        //     employeeFocusedWorkOrders.push(workOrder);
        //     break;
        //   }
        // }

      }

      return employeeFocusedWorkOrders;
    }

    initializeActiveWorkOrders(): void {
      for(let workOrder of this.workOrders) {

        if(workOrder.status == 1 || workOrder.status == 2) {
          this.activeWorkOrders.push({
            employeeId: workOrder.technicianId,
            workOrder: workOrder
          });
        }

      }
    }

    getActiveEmployeeWorkOrder(employeeId: string, key: string): any {
      let activeWorkOrder: any = null;
      let data: any = "-";
      let keyIndex: any = null;

      if(this.activeWorkOrders.length > 0) {
        activeWorkOrder = this.activeWorkOrders.find(employee => employee.employeeId === employeeId);

        if(activeWorkOrder != null || activeWorkOrder != undefined) {
          data = activeWorkOrder.workOrder[key];

          if(key == "status") {
            keyIndex = this.statusOptions.find(statusOption => statusOption.id == data)

            if(keyIndex != null) {
              data = this.statusOptions[keyIndex.id].title;
            }
          }

          else if(key == "priority") {
            keyIndex = this.priorityOptions.find(priorityOption => priorityOption.id == data)

            if(keyIndex != null) {
              data = this.priorityOptions[keyIndex.id].title;
            }
          }

        }
      }

      return data;
    }

    translateRecordDropdownValue(record: any, key: string): string {
      let translatedValue: string = "";

      // Checking to see if the record needs to be tranlated based off a value in a dropdown menu
      for(let dropDownKey of this.dropDownValueKeys) {
        if(key == dropDownKey.key) {

          let dropdownKeyObjects: Array<any> = dropDownKey.function;

          for(let keyObject of dropdownKeyObjects) {
            if(record.workOrderData) {
              if(record.workOrderData[key] == keyObject.key) {
                translatedValue = keyObject.title;
                break;
              }
            } else {
              if(record[key] == keyObject.key) {
                translatedValue = keyObject.title;
                break;
              }
            }
          }

          break;
        }
      }

      return translatedValue;
    }

    public openWorkOrderDialog(workOrder: WorkOrder) {
      this.workOrderDialogRef = this.dialog.open(
        VendorWorkOrderDialogComponent,
        { data: workOrder }
      );
  
      this.workOrderDialogRef.beforeClosed().subscribe(data => {
        console.log("Before Closed: ", data);
      })
      
      this.workOrderDialogRef.afterClosed().pipe(
        filter(data => data)
      ).subscribe(data => {
        console.log("Dialog Closed: ", data);
  
        this.navigatorService.updateWorkOrder(data);
        // this.files.push({ name, content: ''});
      })
    }
  }
  
  /** Builds and returns a new User. */
  function createNewUser(id: number): UserData {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';
  
    return {
      id: id.toString(),
      name: name,
      progress: "Description goes here!",
      color: "I'm a status"
    };

}
