import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { CRMActivity, CRMDeal, Profile, WorkOrder } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { VendorProjectDialogComponent } from '../vendor-project-dialog/vendor-project-dialog.component';
import { VendorWorkAuditTrackerDialogComponent } from '../vendor-work-audit-tracker-dialog/vendor-work-audit-tracker-dialog.component';

enum ProjectOptions {
  OVERVIEW = 0,
  ACTIVITIES = 1
}

@Component({
  selector: 'app-vendor-activity-focus',
  templateUrl: './vendor-activity-focus.component.html',
  styleUrls: ['./vendor-activity-focus.component.scss']
})

export class VendorActivityFocusComponent implements OnInit, OnChanges {

  @Input('workOrders') workOrders: Array<WorkOrder> = [];
  @Input('projects') projects: Array<CRMDeal> = [];

  public activities: Array<CRMActivity> = [];

  workAuditTrackerDialogRef: MatDialogRef<VendorWorkAuditTrackerDialogComponent>;
  projectDialogRef: MatDialogRef<VendorProjectDialogComponent>;


  public projectOptions: any = ProjectOptions;
  public activeOption: number = this.projectOptions.OVERVIEW;

  public focusWorkOrders: Array<WorkOrder> = [];
  public focusProjects: Array<CRMDeal> = [];

  private profile: Profile = null;

  constructor(private navigatorService: NavigatorService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getProfile();
    this.getProjects();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterWorkOrders();
    this.filterProjects();
  }

  private getProfile(): void {
    let self = this;

    this.navigatorService.getAsyncProfile().then( (profile: Profile) => {

      if(profile != undefined && profile != null) {

        self.profile = profile;
        self.filterWorkOrders();
        self.filterProjects();

      }

    });

  }

  private filterWorkOrders(): void {
    // this.focusWorkOrders = [];

    if(this.profile != undefined && this.profile != null) {

      for(let workOrder of this.workOrders) {

        if(workOrder.assignedStaff.includes(this.profile.id)) {
          this.focusWorkOrders.push(workOrder);
        }

      }
      
      this.sortWorkOrders();
    }

  }

  private filterProjects(): void {
    this.focusProjects = [];

    if(this.profile != undefined && this.profile != null) {

      for(let project of this.projects) {

        if(project.assignedStaff.includes(this.profile.id)) {
          this.focusProjects.push(project);
        }

      }
      
    }

  }

  private sortWorkOrders(): void {
    this.focusWorkOrders.sort( (a: WorkOrder, b: WorkOrder) => { return a.dateScheduled.getTime() - b.dateScheduled.getTime() });
  }

  private sortProjects(): void {
    this.projects.sort( (a: CRMDeal, b: CRMDeal) => { return a.expectedCloseDate.getTime() - b.expectedCloseDate.getTime() });
  }

  private sortActivities(): void {
    this.activities.sort( (a: CRMActivity, b: CRMActivity) => { return a.scheduleDate.getTime() - b.scheduleDate.getTime() });
  }

  public changeProjectOption(option: number): void {
    this.activeOption = option;
  }


  private getProjects(): void {
    let self = this;

    this.navigatorService.getAsyncAssignedCRMDeals().then( (projects: Array<CRMDeal>) => {

      console.log("Assigned Projects", projects);
      self.projects = projects;

      self.sortProjects();
      self.getActivities();
    });

  }

  private getActivities(): void {
    this.activities = [];

    for(let project of this.projects) {

      if(project?.plannedActivities?.length > 0) {

        for(let activity of project.plannedActivities) {

          activity.id = project.id;
          this.activities.push(activity);

          this.sortActivities();
        }

      }

    }

    console.log("Activities", this.activities);
  }


  public openProjectDialog(id: string): void {

    this.navigatorService.getAsyncCRMDeal(id).then( (project: CRMDeal) => {

      if(project != undefined && project != null) {

        this.projectDialogRef = this.dialog.open(
          VendorProjectDialogComponent,
          { data: project }
        );

        this.projectDialogRef.beforeClosed().subscribe(data => {
          console.log("Before Closed: ", data);
        });
        
        this.projectDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed: ", data);
    
        });

      }

    });

  }


  public openWorkOrderAuditDialog(id: string): void {

    this.navigatorService.getWorkOrder(id).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {

        this.workAuditTrackerDialogRef = this.dialog.open(
          VendorWorkAuditTrackerDialogComponent,
          { data: workOrder }
        );

        this.workAuditTrackerDialogRef.beforeClosed().subscribe(data => {
          console.log("Before Closed: ", data);
        });
        
        this.workAuditTrackerDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed: ", data);
    
        });

      }

    });

  }

}