import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { CRMDeal, CRMPipe, CRMPipeline } from 'src/app/data-models/models';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-vendor-crm-edit-pipelines',
  templateUrl: 'vendor-crm-edit-pipelines.component.html',
  styleUrls: ['vendor-crm-edit-pipelines.component.scss']
})
export class VendorCRMEditPipelinesComponent implements OnInit, AfterViewInit {
  @Input('pipelineId') pipelineId: string = null

  @Output('changesUpdate') changesUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('closeEditMode') closeEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  private keyTypes: any = keyTypesEnum;
  
  public pipeline: CRMPipeline = {
    id: "nopipeline",
    title: "Loading...",
    pipes: []
  };

  public pipes: Array<CRMPipe> = [];
  public sharedPipes: Array<CRMPipe> = [];
  
  public draggingPipe: boolean = false;

  public focusPipe: string = "";
  public editPipelineTitleMode: boolean = false;
  public editPipeTitleMode: boolean = false;

  private changes: boolean = false;

  public expandSharedPanel: boolean = false;
  public expandMinifiedSharedPanel: boolean = false;

  constructor(
    public formBuilder: FormBuilder, 
    public snackBar: MatSnackBar, 
    private navigatorService: NavigatorService) { 

  }

  ngOnInit() {

    this.getPipeline();
    this.getAllPipes();

  }

  ngAfterViewInit(): void {
    
  }

  getPipeline(): void {

    this.navigatorService.getAsyncCRMPipeline(this.pipelineId).then( (pipeline: CRMPipeline) => {

      this.pipeline = pipeline;

      this.getPipes();

    });

  }

  private getAllPipes(): void {

    this.navigatorService.getAsyncAllCRMPipes().then( (pipes: Array<CRMPipe>) => {

      this.sharedPipes = pipes;

    });

  }

  private async getPipes(): Promise<void> {

    this.pipes = [];

    for(let pipe of this.pipeline.pipes) {

      await this.navigatorService.getAsyncCRMPipe(pipe).then( (asyncPipe: CRMPipe) => {

        this.pipes.push(asyncPipe)

      });

    }

  }

  public async savePipeline(): Promise<void> {

    for(let pipe of this.pipes) {

      await this.navigatorService.upsertCRMPipe(pipe).then( (status: boolean) => {

      });

    }

    await this.navigatorService.upsertCRMPipeline(this.pipeline).then( (status: boolean) => {

    });

    this.snackBar.open('Pipeline Saved Successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    this.changes = true;
    this.changesUpdate.emit(true);
  }

  public closePipelineEditMode(): void {
    this.closeEditMode.emit(this.changes);
  }

  public viewMode(): void {
    this.editPipelineTitleMode = false;
    this.editPipeTitleMode = false;

    this.focusPipe = "";
  }

  public editPipelineTitle(): void {
    this.editPipelineTitleMode = true;
  }

  public editPipeTitle(id: string): void {
    this.changeFocusPipe(id);
    this.editPipeTitleMode = true;
  }

  public changeFocusPipe(id: string): void {
    console.log("Focus Pipe: ", id);
    this.focusPipe = id;
  }

  public expandSharedPipePanel(): void {
    this.expandSharedPanel = true;
  }

  public collapseSharedPipePanel(): void {
    this.expandSharedPanel = false;
  }

  public expandMinifiedSharedPipePanel(): void {
    this.expandMinifiedSharedPanel = true;
  }

  public collapseMinifiedSharedPipePanel(): void {
    this.expandMinifiedSharedPanel = false;
  }

  public updatePipelineTitle(title: string): void {

    if(title.length >= 1) {
      this.pipeline.title = title;
    } else {
      this.snackBar.open('The Pipe\'s Title Must Hold One Or More Characters!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public updatePipeTitle(pipeId: string, title: string): void {

    if(title.length >= 1) {
      let pipeIndex: number = this.pipes.findIndex( (pipe: CRMPipe) => { return pipe.id == pipeId; });

      if(pipeIndex > -1) {
        this.pipes[pipeIndex].title = title;
      }

    } else {
      this.snackBar.open('The Pipe\'s Title Must Hold One Or More Characters!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public updateProbability(pipeId: string, probability: string): void {

    if(probability.length >= 1) {
      let pipeIndex: number = this.pipes.findIndex( (pipe: CRMPipe) => { return pipe.id == pipeId; });

      if(pipeIndex > -1) {
        let probNumber: number = Number.parseInt(probability);
        this.pipes[pipeIndex].probability = probNumber;
      }

    } else {
      this.snackBar.open('The Pipe\'s Probability Must Hold One Or More Numbers!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public updateRotting(pipeId: string, rotting: string): void {

    if(rotting.length >= 1) {
      let pipeIndex: number = this.pipes.findIndex( (pipe: CRMPipe) => { return pipe.id == pipeId; });

      if(pipeIndex > -1) {
        let rottingNumber: number = Number.parseInt(rotting);
        this.pipes[pipeIndex].rotting = rottingNumber;
      }

    } else {
      this.snackBar.open('The Pipe\'s Days Rotting Must Hold One Or More Numbers!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  private manualSortPipelinePipes(): void {

    this.pipeline.pipes = [];

    for(let pipe of this.pipes) {
      this.pipeline.pipes.push(pipe.id);
    }

  }

  public addPipe(pipeIndex: number = 0): void {
    let pipe: CRMPipe = {
      id: this.navigatorService.generateKey(this.keyTypes.CRM_PIPELINE),
      title: 'Pipe',
      weight: 0,
      rotting: 3,
      probability: 0,
      deals: []
    };

    this.pipeline.pipes.splice(pipeIndex, 0, pipe.id);
    this.pipes.splice(pipeIndex, 0, pipe);

  }

  public addSharedPipe(pipe: CRMPipe, pipeIndex: number = 0): void {

    this.pipeline.pipes.splice(pipeIndex, 0, pipe.id);
    this.pipes.splice(pipeIndex, 0, pipe);

    this.collapseMinifiedSharedPipePanel();
    this.collapseSharedPipePanel();

  }

  public deletePipe(pipeId: string) {
    let pipeIndex: number = this.pipes.findIndex( (pipe: CRMPipe) => { return pipe.id == pipeId; });

      if(pipeIndex > -1) {
        this.pipes.splice(pipeIndex, 1);
        this.pipeline.pipes.splice(pipeIndex, 1);
      }
  }



  cdkDragStarted(): void {
    console.log("Drag Started");
    this.draggingPipe = true;
  }

  cdkDragStopped(): void {
    console.log("Drag Stopped");
    this.draggingPipe = false;
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } 
    
    else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.manualSortPipelinePipes();
    this.cdkDragStopped();
  }

}
