import { Component, OnInit, ViewEncapsulation, Inject  } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service';

import { MatDialogRef } from '@angular/material/dialog';
import { CRMDeal } from 'src/app/data-models/models';


@Component({
    selector: 'app-vendor-form-builder-dialog.component',
    templateUrl: 'vendor-form-builder-dialog.component.html',
    styleUrls: ['vendor-form-builder-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorFormBuilderDialogComponent implements OnInit {

    public activeDoc: string = null;
    public activeTemplate: string = null;

    public builderMode: boolean = false;
    public woBuilderMode: boolean = false;
    public dealMode: boolean = false;
    public isDetailView: boolean = false;

    public deal: CRMDeal = null;

    constructor(
      @Inject(MAT_DIALOG_DATA) public incomingData: any,
      private navigatorService: NavigatorService, 
      public dialogRef: MatDialogRef<VendorFormBuilderDialogComponent>) {

    }
  
    ngOnInit() {

      console.log("Form Builder Dialog: ", this.incomingData);

      this.activeDoc = this.incomingData.activeDoc;
      this.activeTemplate = this.incomingData.activeTemplate;

      if(this.incomingData?.woBuilderMode != undefined) {
        this.woBuilderMode = this.incomingData.woBuilderMode;
        this.builderMode = this.woBuilderMode;
      }

      if(this.incomingData?.dealMode != undefined) {
        this.dealMode = this.incomingData.dealMode;
      }

      if(this.incomingData?.deal != undefined) {
        this.dealMode = true;
        this.deal = this.incomingData.deal;
      }

      this.dialogRef.beforeClosed().subscribe(data => {
        this.close();
      });

    }

    public close() {
      this.dialogRef.close();
    }


    public documentAttached(id: string): void {
      this.dialogRef.close(id);
    }

  }