import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

import { NavigatorService } from './navigator.service';
import { CompanyProfile, FormattedWorkOrderAuditLog, WorkOrderAuditLog } from 'src/app/data-models/models';
import { DatePipe } from '@angular/common';


@Injectable({
    providedIn: 'root'
})

export class WorkOrderAuditService {

    companyProfile: CompanyProfile = {
        id: '',
        company_name: '',
        email: '',
        phone: '',
        address: undefined,
        linkedin: '',
        twitter: '',
        instagram: '',
        facebook: '',
        website: '',
        created_date: null
    };

    auditTypes: any = this.navigatorService.getJobAuditTypes();
    datePipe = new DatePipe('en-US');


    constructor(public http:HttpClient, public realm:MongoService, public router:Router, private navigatorService: NavigatorService, private sanitizer:DomSanitizer) { 

    }

    async initialize(companyId: string): Promise<void> {

        if(companyId == undefined || companyId == null || companyId.length == 0) {
            return;
        }

        await this.navigatorService.getAsyncCompanyProfileAsForeign(companyId).then( (companyProfile: CompanyProfile) => {
            this.companyProfile = companyProfile;
        });
        
    }

    public getCompanyProfile(): CompanyProfile {
        return this.companyProfile;
    }

    messageFormatLog(auditTrail: Array<WorkOrderAuditLog>): Array<FormattedWorkOrderAuditLog> {
        console.log("Audit Trail: ", auditTrail);
        let formattedMessages: Array<FormattedWorkOrderAuditLog> = [];

        let title: string = "";
        let message: string = "";
        let icon: string = "";

        for(let auditLog of auditTrail) {
            title = "";
            message = "By " + this.companyProfile.company_name + " on " + this.datePipe.transform(auditLog.data.date, 'mediumDate') + " @ " + this.datePipe.transform(auditLog.data.date, 'shortTime');
            icon = "";

            switch(auditLog.type) {

                case this.auditTypes.CREATED:
                    title = "Job Created";
                    icon = "flag_circle";
                    break;

                case this.auditTypes.CONFIRMED:
                    title = "Job Confirmed";
                    icon = "check_circle";
                    break;

                case this.auditTypes.CUSTOMER_CONFIRMED:
                    title = "Job Confirmed";
                    icon = "event_available";
                    break;

                case this.auditTypes.SCHEDULED:
                    title = "Scheduled job for " + this.datePipe.transform(auditLog.data.scheduledDate, 'fullDate') + " at " + this.datePipe.transform(auditLog.data.scheduledDate, 'shortTime');
                    icon = "calendar_month";
                    break;

                case this.auditTypes.RESCHEDULED:
                    title = "Rescheduled job for " + this.datePipe.transform(auditLog.data.scheduledDate, 'fullDate') + " at " + this.datePipe.transform(auditLog.data.scheduledDate, 'shortTime');
                    icon = "calendar_month";
                    break;

                case this.auditTypes.ASSIGNED:
                    title = "Assigned " + this.companyProfile.company_name + " technician";
                    icon = "badge";
                    break;

                case this.auditTypes.REASSIGNED:
                    title = "Reassigned " + this.companyProfile.company_name + " technician";
                    icon = "badge";
                    break;

                case this.auditTypes.WORK_STATUS_CHANGE:
                    title = auditLog.data.status;
                    icon = "diversity_2";
                    break;

                case this.auditTypes.CANCELED:
                    title = "Job Canceled";
                    icon = "event_busy";
                    break;

                case this.auditTypes.COMPLETED:
                    title = "Job Completed";
                    icon = "published_with_changes";
                    break;

                case this.auditTypes.CLOSED:
                    title = "Job Closed";
                    icon = "done_all";
                    break;

            }

            formattedMessages.push({
                id: auditLog.id,
                type: auditLog.type,
                title: title,
                message: message,
                icon: icon
            });

        }

        return formattedMessages;
    }

}