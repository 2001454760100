import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

import { NavigatorService, keyTypesEnum } from './navigator.service';
import { CMMSLocation } from 'src/app/data-models/models';


@Injectable({
    providedIn: 'root'
})

export class VendorSidebarMenuService {

    private inventoryTextFilter: string = "";
    private inventoryPageNumber: number = 0;
    private inventoryPageLimit: number = 12;

    private assetTextFilter: string = "";
    private assetPageNumber: number = 0;
    private assetPageLimit: number = 12;

    // CMMS
    private cmmsLocations: Array<CMMSLocation> = [{
        originId: null,
        originDate: null,
        id: 'all',
        isDisplayed: true,
        title: 'All',
        desc: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: 12345
        },
        images: null,
        type: null

    }];


    constructor(public http:HttpClient, public realm:MongoService, public router:Router, private navigatorService: NavigatorService, private sanitizer:DomSanitizer) { 

    }

    // ==============================================
    //                  CMMS Calls
    // ==============================================

    // Inventory
    public getInventoryTextFilter(): string {
        return this.inventoryTextFilter;
    }

    public setInventoryTextFilter(textFilter: string): void {
        this.inventoryTextFilter = textFilter;
    }

    public getInventoryPageNumber(): number {
        return this.inventoryPageNumber;
    }

    public setInventoryPageNumber(pageNumber: number): void {
        this.inventoryPageNumber = pageNumber;
    }

    public getInventoryPageLimit(): number {
        return this.inventoryPageLimit;
    }

    public setInventoryPageLimit(pageLimit: number): void {
        this.inventoryPageLimit = pageLimit;
    }

    // Inventory Database
    public updateInventoryFilter(): void {
        this.navigatorService.getPagedSearchDistinctBaseInventory(this.inventoryTextFilter, this.cmmsLocations, 0, this.inventoryPageLimit);
    }


    // Assets
    public getAssetTextFilter(): string {
        return this.assetTextFilter;
    }

    public setAssetTextFilter(textFilter: string): void {
        this.assetTextFilter = textFilter;
    }

    public getAssetPageNumber(): number {
        return this.assetPageNumber;
    }

    public setAssetPageNumber(pageNumber: number): void {
        this.assetPageNumber = pageNumber;
    }

    public getAssetPageLimit(): number {
        return this.assetPageLimit;
    }

    public setAssetPageLimit(pageLimit: number): void {
        this.assetPageLimit = pageLimit;
    }

    // Asset Database
    public updateAssetsFilter(): void {
        this.navigatorService.getPagedSearchDistinctAssets(this.assetTextFilter, this.cmmsLocations, 0, this.assetPageLimit);
    }


    // Misc.
    public getFocusedCMMSLocations(): Array<CMMSLocation> {
        return this.cmmsLocations;
    }

    public setFocusedCMMSLocations(cmmsLocations: Array<CMMSLocation>): void {
        this.cmmsLocations = cmmsLocations;

        this.updateInventoryFilter();
        this.updateAssetsFilter();
    }

}