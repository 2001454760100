import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, HostListener, ElementRef, Input, Output, EventEmitter  } from '@angular/core';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { Profile, WorkOrder, WorkOrderFilter } from '../../../data-models/models';

import { VendorAddWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';

import { Observable, Subscription } from 'rxjs'; 
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ganttViewTypes } from '../../professional/vendor-work-order-gantt/vendor-work-order-gantt.component';


export enum ViewMode {
  TABLE = 0,
  CALENDAR = 1
}


@Component({
  selector: 'app-vendor-work-order-management',
  templateUrl: 'vendor-work-order-management.component.html',
  styleUrls: ['vendor-work-order-management.component.scss']
})

export class VendorWorkOrderManagementComponent implements OnInit, AfterViewInit {
  public ganttViewTypes: any = ganttViewTypes;
  public viewModes: any = ViewMode;

  @ViewChild('dailyFilter', {static: false}) public dailyFilter: any;
  @ViewChild('textFilterInput') overallTextFilter: ElementRef;
  @HostListener('window:resize')


  @Input('employees') employees: Array<Profile> = [];
  @Input('ganttViewMode') ganttViewMode: number = this.ganttViewTypes.DISCREET_VIEW;
  @Input("expandCollapse") expandCollapse: boolean = true;

  @Output('ganttExpandCollapseUpdated') ganttExpandCollapseUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('ganttViewModeUpdated') ganttViewModeUpdated: EventEmitter<number> = new EventEmitter<number>();
  @Output('managedWorkOrdersUpdated') managedWorkOrdersUpdated: EventEmitter<Array<WorkOrder>> = new EventEmitter<Array<WorkOrder>>();

  public onWindowResize():void {
    (document.body.clientWidth < 1040) ? this.isMobile = true : this.isMobile = false; 
  }

  public viewMode: number = this.viewModes.TABLE;

  private workOrders: Array<WorkOrder> = [];
  private generalWorkOrders: Array<WorkOrder> = [];
  public routineScheduledData: Array<WorkOrder> = [];
  public routineUnscheduledData: Array<WorkOrder> = [];
  public emergencyScheduledData: Array<WorkOrder> = [];
  public emergencyUnscheduledData: Array<WorkOrder> = [];
  public textFilter: string = "";
  public isDaily: boolean = true;
  public isMobile = false;

  private latestUpdateTable: number = 0;
  public tableOpenClosedTracker: Array<any> = [ 
    { filter: "", openOrClosed: 0, startDate: null, endDate: null },
    { filter: "", openOrClosed: 0, startDate: null, endDate: null },
    { filter: "", openOrClosed: 0, startDate: null, endDate: null },
    { filter: "", openOrClosed: 0, startDate: null, endDate: null }
  ];

  private openWorkOrderSubscriber: Subscription;
  private closedWorkOrderSubscriber: Subscription;

  private startDate: Date = new Date();
  private endDate: Date = new Date();


  addWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;
  isChecked: false;

  constructor(
    public dialog: MatDialog,
    private navigatorService: NavigatorService,
    private changeDetectorRef: ChangeDetectorRef) {
               
  }
  
  ngOnInit(): void {

    for(let tableFilter of this.tableOpenClosedTracker) {
      tableFilter.startDate = this.startDate;
      tableFilter.endDate = this.endDate;
    }
    
    this.subscribeToClosedWorkOrders();
  }
  
  ngAfterViewInit(): void {
    this.initializeFilterSize();
  }

  ngOnDestroy() {
    if(this.openWorkOrderSubscriber) {
      this.openWorkOrderSubscriber.unsubscribe();
    }

    if(this.closedWorkOrderSubscriber) {
      this.closedWorkOrderSubscriber.unsubscribe();
    }
  }  

  initializeFilterSize(): void {
    (document.body.clientWidth < 480) ? this.isMobile = true : this.isMobile = false; 
  }

  setMonthDateRange(): void {
    this.startDate.setDate(1);

    this.endDate = new Date(this.startDate);
    this.endDate.setMonth(this.startDate.getMonth() + 1);
    this.endDate.setDate(0);
  }

  applyFilter(textFilter: string): void {
    this.latestUpdateTable = 0;
    this.textFilter = textFilter;

    this.getOpenWorkOrders(this.textFilter, this.startDate, this.endDate);
  }

  subscribeToOpenWorkOrders(): void {
    this.navigatorService.getAsyncPagedDateRangeSearchOpenWorkOrders(this.startDate, this.endDate, "", 0, 100).then(data => { 
      this.openWorkOrderSorting(data)
    });
  }

  subscribeToClosedWorkOrders(): void {
    this.navigatorService.getAsyncPagedDateRangeSearchClosedWorkOrders(this.startDate, this.endDate, "", 0, 100).then(data => { 

      if(this.openWorkOrderSubscriber == null || this.openWorkOrderSubscriber == undefined) {
        this.subscribeToOpenWorkOrders();
      }

      this.closedWorkOrderSorting(data);
    });
  }

  getOpenWorkOrders(searchFilter: string, startDate: Date, endDate: Date): void {
    this.navigatorService.getAsyncPagedDateRangeSearchOpenWorkOrders(startDate, endDate, searchFilter, 0, 100).then(data => {
      this.openWorkOrderSorting(data);
    });
  }

  getClosedWorkOrders(searchFilter: string, startDate: Date, endDate: Date): void {
    this.navigatorService.getAsyncPagedDateRangeSearchClosedWorkOrders(startDate, endDate, searchFilter, 0, 100).then(data => {
      this.closedWorkOrderSorting(data);
    });
  }

  refreshGeneralWorkOrders(): void {
    this.generalWorkOrders = [];

    for(let workOrder of this.routineScheduledData) {
      this.generalWorkOrders.push(workOrder);
    }

    for(let workOrder of this.routineUnscheduledData) {
      this.generalWorkOrders.push(workOrder);
    }

    for(let workOrder of this.emergencyScheduledData) {
      this.generalWorkOrders.push(workOrder);
    }

    for(let workOrder of this.emergencyUnscheduledData) {
      this.generalWorkOrders.push(workOrder);
    }

    this.updateManagedWorkOrders();
  }

  openWorkOrderSorting(workOrders: Array<WorkOrder>): void {
    this.workOrders = [];

    if(workOrders != null) {
      this.workOrders = workOrders;

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 1) && this.tableOpenClosedTracker[0].openOrClosed == 0) {
        this.routineScheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority != 5 &&
                element.dateScheduled;
        });
      }

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 2) && this.tableOpenClosedTracker[1].openOrClosed == 0) {
        this.routineUnscheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority != 5 &&
                !element.dateScheduled;
        });
      }

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 3) && this.tableOpenClosedTracker[2].openOrClosed == 0) {
        this.emergencyScheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }
          
          return element.priority == 5 &&
                element.dateScheduled;
        });
      }

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 4) && this.tableOpenClosedTracker[3].openOrClosed == 0) {
        
        this.emergencyUnscheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority == 5 &&
                !element.dateScheduled;
        });
      }
    } 

    this.refreshGeneralWorkOrders();
    this.changeDetectorRef.detectChanges();
  }

  closedWorkOrderSorting(workOrders: Array<WorkOrder>): void {
    
    this.workOrders = [];

    if(workOrders != null) {
      this.workOrders = workOrders;

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 1) && this.tableOpenClosedTracker[0].openOrClosed == 1) {
        this.routineScheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority != 5 &&
                element.dateScheduled;
        });
      }

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 2) && this.tableOpenClosedTracker[1].openOrClosed == 1) {
        this.routineUnscheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority != 5 &&
                !element.dateScheduled;
        });
      }

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 3) && this.tableOpenClosedTracker[2].openOrClosed == 1) {
        this.emergencyScheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority == 5 &&
                element.dateScheduled;
        });
      }

      if((this.latestUpdateTable == 0 || this.latestUpdateTable == 4) && this.tableOpenClosedTracker[3].openOrClosed == 1) {
        this.emergencyUnscheduledData = this.workOrders.filter(function (element) {

          if(element == null || element == undefined) {
            return false;
          }

          return element.priority == 5 &&
                !element.dateScheduled;
        });
      }
    } 

    this.refreshGeneralWorkOrders();
    this.changeDetectorRef.detectChanges();
  }
  
  openOrClosedWorkOrderSwitch(openOrClosedWorkOrders: number, table: number): void {
    let startDate: Date = this.startDate;
    let endDate: Date = this.endDate;
    let textFilter: string = this.textFilter;

    this.latestUpdateTable = table;
    this.tableOpenClosedTracker[table - 1].openOrClosed = openOrClosedWorkOrders;

    if(!this.isDaily) {
      textFilter = this.tableOpenClosedTracker[table - 1].filter;
      startDate = this.tableOpenClosedTracker[table - 1].startDate;
      endDate = this.tableOpenClosedTracker[table - 1].endDate;
    }

    if(openOrClosedWorkOrders == 0) {
      this.getOpenWorkOrders(textFilter, startDate, endDate);
    } else {
      this.getClosedWorkOrders(textFilter, startDate, endDate);
    }
  }

  updateTableWorkOrders(table: number): void {
    this.latestUpdateTable = table;

    let openOrClosedWorkOrders: number = this.tableOpenClosedTracker[this.latestUpdateTable - 1].openOrClosed;

    if(openOrClosedWorkOrders == 0) {
      this.getOpenWorkOrders(this.tableOpenClosedTracker[this.latestUpdateTable - 1].filter, this.tableOpenClosedTracker[this.latestUpdateTable - 1].startDate, this.tableOpenClosedTracker[this.latestUpdateTable - 1].endDate);
    } else {
      this.getClosedWorkOrders(this.tableOpenClosedTracker[this.latestUpdateTable - 1].filter, this.tableOpenClosedTracker[this.latestUpdateTable - 1].startDate, this.tableOpenClosedTracker[this.latestUpdateTable - 1].endDate);
    }
  }

  filterWorkOrders(filter: WorkOrderFilter, table: number): void {
    this.latestUpdateTable = table;
    this.isDaily = false;
    this.textFilter = "";
    this.overallTextFilter.nativeElement.value = "";

    this.tableOpenClosedTracker[table - 1].filter = filter.searchFilter;
    this.tableOpenClosedTracker[table - 1].startDate = filter.dateFilter.startDate;
    this.tableOpenClosedTracker[table - 1].endDate = filter.dateFilter.endDate;

    if(this.tableOpenClosedTracker[table - 1].openOrClosed == 0) {
      this.getOpenWorkOrders(this.tableOpenClosedTracker[table - 1].filter, this.tableOpenClosedTracker[table - 1].startDate, this.tableOpenClosedTracker[table - 1].endDate);
    } else {
      this.getClosedWorkOrders(this.tableOpenClosedTracker[table - 1].filter, this.tableOpenClosedTracker[table - 1].startDate, this.tableOpenClosedTracker[table - 1].endDate);
    }
  }

  toggleDailySwitch(): void {
    
    if(this.isDaily) {
      this.isDaily = false;
      this.latestUpdateTable = 0;
      this.setMonthDateRange();
      this.getOpenWorkOrders(this.textFilter, this.startDate, this.endDate);
      this.getClosedWorkOrders(this.textFilter, this.startDate, this.endDate);
    } else {
      this.isDaily = true;
      this.latestUpdateTable = 0;
      this.startDate = new Date();
      this.endDate = new Date();
      this.getOpenWorkOrders(this.textFilter, new Date(), new Date());
      this.getClosedWorkOrders(this.textFilter, this.startDate, this.endDate);
    }
  }

  public updateGanttViewMode(viewMode: number): void {
    this.ganttViewMode = viewMode;

    this.ganttViewModeUpdated.emit(this.ganttViewMode);
  }

  public updateGanttExpandCollapse(expandCollapse: boolean): void {
    this.expandCollapse = expandCollapse;

    this.ganttExpandCollapseUpdated.emit(this.expandCollapse);
  }

  public updateManagedWorkOrders(): void {
    this.managedWorkOrdersUpdated.emit(this.generalWorkOrders);
  }

  public changeView(view: number): void {
    this.viewMode = view;
  }

  public openAddWorkOrderDialog() {
    this.addWorkOrderDialogRef = this.dialog.open(VendorAddWorkOrderDialogComponent);

    this.addWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed", data.workOrderData);

      this.navigatorService.updateWorkOrder(data.workOrderData).then(data => {
        this.updateTableWorkOrders(this.latestUpdateTable);
      });
    })
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}