import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profile } from '../../../data-models/models'

import { Observable, Subscription } from 'rxjs'; 


@Component({
  selector: 'app-vendor-efficiency-and-workload-chart',
  templateUrl: 'vendor-efficiency-and-workload-chart.component.html',
  styleUrls: ['vendor-efficiency-and-workload-chart.component.scss']
})
export class VendorEfficiencyAndWorkloadChartComponent implements OnInit, AfterViewInit {
  componentId = "efficiencyAndWorkLoadChartContainer";

  public employees: Array<Profile> = [];
  private employeeSubscriber: Subscription;

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showLines: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{ }], yAxes: [{ }] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    aspectRatio: 12
  };

  public barChartLabels: Label[] = 
  [
    'Tech 1', 'Tech 2', 'Tech 3', 'Tech 4', 'Tech 5', 'Tech 6', 'Tech 7', 'Tech 8', 'Tech 9', 'Tech 10', 
    'Tech 11', 'Tech 12', 'Tech 13', 'Tech 14', 'Tech 15', 'Tech 16', 'Tech 17', 'Tech 18', 'Tech 19', 'Tech 20', 
    'Tech 21' , 'Tech 22', 'Tech 23', 'Tech 24', 'Tech 25', 'Tech 26', 'Tech 27', 'Tech 28'
  ];

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { 
      data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], 
      label: 'Work Load',
      backgroundColor: "rgba(0, 0, 0, 0.25)"
    },
    { 
      data: [28, 48, 40, 19, 86, 27, 90, 28, 48, 40, 19, 86, 27, 60, 28, 48, 40, 19, 86, 27, 90, 28, 48, 40, 19, 86, 27, 90], 
      label: 'Efficiency',
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    }
  ];
    
  constructor(public snackBar: MatSnackBar, public navigatorService: NavigatorService) {
      
  }
  
  ngOnInit(): void {
    this.subscribeToAllEmployees();
  }
  
  ngAfterViewInit(): void {
      
  }

  ngOnDestroy() {
    if(this.employeeSubscriber) {
      this.employeeSubscriber.unsubscribe();
    }
  }  

  subscribeToAllEmployees(): void {
    this.employeeSubscriber = this.navigatorService.getAllEmployees().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;

      if(data != null) {
        this.barChartLabels = [];

        //Work Load
        this.barChartData[0].data = [];

        //Efficiency Score
        this.barChartData[1].data = [];

        for(let index = 0; index < data.length; index++) {
          this.employees.push(employees[index]);

          this.barChartLabels.push(employees[index].first_name + " " + employees[index].last_name);

          //Work Load
          this.barChartData[0].data.push(Math.round(Math.random() * 100));

          //Efficiency Score
          this.barChartData[1].data.push(Math.round(Math.random() * 100));
        }
      }
    });
  }

  getAllEmployees(): void {
    this.navigatorService.getAllEmployees();
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(event: any): void {

    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let value = idAttr.nodeValue;

    document.querySelector("li.active").classList.remove("active");
    document.querySelector("#" + value).classList.add("active");



    for(let chartIndex = 0; chartIndex < this.barChartData.length; chartIndex++) {
      this.barChartData[chartIndex].data = [];

      for(let dataIndex = 0; dataIndex < this.barChartLabels.length; dataIndex++) {
        this.barChartData[chartIndex].data.push( Math.round(Math.random() * 100));
      }
    }
  }
}
