import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef, HostListener } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs'; 
import { ActivatedRoute } from '@angular/router';


import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { keyTypesEnum } from '../../../services/vendor/navigator.service';
import { NavigatorService } from '../../../services/vendor/navigator.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetWorkOrder, ExpandableTableDisplayColumns, AssetItem, TableHeaderKeys, WorkOrder, WorkOrderFilter, DynamicData, DynamicDataFilter, ActionIcon, TableActionIconGroup, RowActionIconGroup } from '../../../data-models/models';

import { workOrderTypesEnum } from '../../../services/vendor/navigator.service';

import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { VendorAddAssetWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-asset-work-order-dialog/vendor-add-asset-work-order-dialog.component'
import { InputFileRejectedReason } from 'ngx-input-file/src/lib/enums/input-file-rejected-reason';
import { VendorAddWorkOrderDialogComponent } from '../../professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];

@Component({
  selector: 'app-vendor-dynamic-data-table',
  templateUrl: 'vendor-dynamic-data-table.component.html',
  styleUrls: ['vendor-dynamic-data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class VendorDynamicDataTableComponent implements OnInit, AfterViewInit {

  @Input() dynamicData: DynamicData = { data: [] };
  @Input() dataModel: any = null;
  @Input() keyExclusion: Array<string> = [
    "workOrderId",
    "visibleShift",
    "gallery",
    "dateContactLog",
    "isAutoGenerated",
    "customFields",
    "custAddress",
    "custBillingAddress",
    "latLng",
    "signature",
    "servicesRendered",
    "attachedParts",
    "parts",
    "images"
  ];

  @Input() initializeOpenOrClosed: number = null;
  @Input() tableActionIcons: TableActionIconGroup = { actionIcons: [] };
  @Input() rowLeadingActionIcons: Array<RowActionIconGroup> = [];
  @Input() rowTrailingActionIcons: Array<RowActionIconGroup> = [];
  

  @Output() actionButtonStyleEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionButtonEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangedEmitter: EventEmitter<any> = new EventEmitter<DynamicDataFilter>();
  @Output() invoiceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() invoiceScrollActionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openOrClosedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEmitter: EventEmitter<any> = new EventEmitter<DynamicDataFilter>();
  @Output() dataDialogUpdateEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("dynamicTableContainer") tableContainer;

  @HostListener('window:resize')
  public onWindowResize():void {
    (this.tableContainer.nativeElement.clientWidth > 960) ? this.largeFilterOptions = true : this.largeFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 960 && this.tableContainer.nativeElement.clientWidth > 480) ? this.mediumFilterOptions = true : this.mediumFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 480) ? this.smallFilterOptions = true : this.smallFilterOptions = false; 
  }

  workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  addAssetWorkOrderDialogRef: MatDialogRef<VendorAddAssetWorkOrderDialogComponent>;
  addFieldWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;

  private keyTypes: any = keyTypesEnum;

  private filterValue: string = "";
  private startMonthDate: Date = new Date;

  private endDate: Date = new Date;

  public filterForm = new FormGroup({
    startDate: new FormControl(new Date),
    endDate: new FormControl(new Date),
  });

  public keyLookup: any
  public dataKeys: Array<string> = [];
  public displayHeaderMenu: boolean = false;
  public displayDateFilterMenu: boolean = false;
  public isSmallMenuExpanded: boolean = false;

  private dropDownValueKeys: Array<any> = [
    { key: "isPaid", function: this.navigatorService.getPaidOptions() },
    { key: "status", function: this.navigatorService.getStatusOptions() },
    { key: "priority", function: this.navigatorService.getPriorityOptions() },
    { key: "skillLvl", function: this.navigatorService.getSkillLvlOptions() },
    { key: "isCompleted", function: this.navigatorService.getWorkCompletedOptions() }
  ];

  previousIndex: number;
  columns: string[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<AssetWorkOrder>;
  expandedElement: UserData | null;

  public largeFilterOptions: boolean = false;
  public mediumFilterOptions: boolean = false;
  public smallFilterOptions: boolean = false;

  public openOrClosedWorkOrders: number = 0;

  // displayedRows$: Observable<ShipData[]>;
  // totalRows$: Observable<number>;

  constructor(public formBuilder: FormBuilder, public dialog: MatDialog, private navigatorService: NavigatorService, private changeDetectorRef: ChangeDetectorRef) {

    // Assign the data to the data source for the table to render
    this.displayedColumns = [];
    this.dataSource = new MatTableDataSource(this.dynamicData.data);

  }

  ngOnInit(): void {
    console.log("Dynamic Data Table Data: ", this.dynamicData);

    console.log("Trailing Icons: ", this.rowTrailingActionIcons);

    if(this.dataModel != null) {
      this.createKeys(this.dataModel);
      this.createKeyLookup(this.dataModel);
    }

    this.excludeActionButtonKeys();
    this.openOrClosedWorkOrders = this.initializeOpenOrClosed;
    this.startMonthDate.setDate(1);

    this.endDate.setMonth(this.startMonthDate.getMonth() + 1);
    this.endDate.setDate(0);

    this.filterForm.controls["startDate"].setValue(this.startMonthDate);
    this.filterForm.controls["endDate"].setValue(this.endDate);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.initializeFilterSize();
  }

  ngOnDestroy() {

  }  

  ngOnChanges(changes: SimpleChanges) {
    
    console.log("Dynamic Data Table Data: ", this.dynamicData);

    //Default
    if(this.dynamicData.data != undefined && this.dynamicData.data.length > 0) {   
      this.dataSource.data = this.dynamicData.data;

      this.excludeActionButtonKeys();
      this.createKeys(this.dynamicData.data[0]);
      this.createKeyLookup(this.dynamicData.data[0]);

    } else {
      this.dataSource.data = [];

      this.excludeActionButtonKeys();
      this.createKeys(this.dataModel);
      this.createKeyLookup(this.dataModel);
    }

  } 

  setDisplayedColumns() {
    this.columns.forEach(( colunm, index) => {
      this.displayedColumns[index] = colunm;
    });
  }

  dragStarted(event: CdkDragStart, index: number ) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  initializeFilterSize(): void {
    (this.tableContainer.nativeElement.clientWidth > 960) ? this.largeFilterOptions = true : this.largeFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 960 && this.tableContainer.nativeElement.clientWidth > 480) ? this.mediumFilterOptions = true : this.mediumFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 480) ? this.smallFilterOptions = true : this.smallFilterOptions = false; 
  }

  excludeActionButtonKeys(): void {
    this.keyExclusion = [];

    for(let actionIcon of this.rowLeadingActionIcons) {

      if(actionIcon.key != null && actionIcon.key != undefined) {
        this.keyExclusion.push(actionIcon.key);
      }

    }

    for(let actionIcon of this.rowTrailingActionIcons) {

      if(actionIcon.key != null && actionIcon.key != undefined) {
        this.keyExclusion.push(actionIcon.key);
      }

    }
  }

  public recordValueLookup(record: any, key: string): any {
    let returnData: any = "";
    let isDropDownValue: boolean = false;

    // Checking to see if the record needs to be tranlated based off a value in a dropdown menu
    for(let dropDownKey of this.dropDownValueKeys) {
      if(key == dropDownKey.key) {
        isDropDownValue = true;

        let dropdownKeyObjects: Array<any> = dropDownKey.function;

        for(let keyObject of dropdownKeyObjects) {
          if(record.workOrderData) {
            if(record.workOrderData[key] == keyObject.key) {
              returnData = keyObject.title;
              break;
            }
          } else {
            if(record[key] == keyObject.key) {
              returnData = keyObject.title;
              break;
            }
          }
        }

        break;
      }
    }

    if(!isDropDownValue) {
      if(key.indexOf(".") > -1) {
        // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
        let key1 = key.substring(0, key.indexOf('.'));
        let key2 = key.substring(key.indexOf('.') + 1, key.length);

        if(record.workOrderData) {
          if(record.workOrderData[key1] && record.workOrderData[key1][key2]) {
            returnData = record.workOrderData[key1][key2];
          }
        } else {
          if(record[key1] != undefined && record[key1][key2] != undefined) {
            returnData = record[key1][key2];
          }
        }
      } else {

        if(key != "workOrderId" && key != "technicianId" && key != "originatorId") {
          returnData = record[key];
        } else {
          let id: string = record[key];
          returnData = id.substring( (id.length - 5), id.length);
        }
      }
    } 

    return returnData;
  }

  public headerTitleLookup(key: string): any {
    let title: string = "...failed...";

    if(this.keyLookup[key] && this.keyLookup[key].title) {
      title = this.keyLookup[key].title;
    } else {
      console.log("Failed Lookup: ", key);
    }

    return title
  }

  applyFilter(event: any) {
    let filterValue: string = "";

    if(event.target != null && event.target != undefined) {
      filterValue = (event.target as HTMLInputElement).value;
    } else {
      filterValue = event;
    }

    this.filterValue = filterValue.trim().toLowerCase();

    let workOrderFilter: WorkOrderFilter = {
      searchFilter: this.filterValue,
      dateFilter: {
        startDate: this.filterForm.controls["startDate"].value,
        endDate: this.filterForm.controls["endDate"].value
      }
    }

    // Filtering should be done on the server
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filterEmitter.emit(workOrderFilter);

    if(this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public updateDisplayedHeaders(headerOption: string) {
    this.displayedColumns = [];
    this.columns = [];

    for(let actionIcon of this.rowLeadingActionIcons) {

      if(actionIcon.key != null && actionIcon.key != undefined) {
        this.displayedColumns.push(actionIcon.key);
        this.columns.push(actionIcon.key);
      }

    }

    for(let keyIndex: number = 0; keyIndex < this.dataKeys.length; keyIndex++) {
      let key = this.dataKeys[keyIndex];

      if(this.keyLookup[key].display || key == headerOption) {

        if(key == headerOption) {
          if(!this.keyLookup[key].display) {
            this.displayedColumns.push(key);
            this.columns.push(key);
          }
        } else {
          this.displayedColumns.push(key);
          this.columns.push(key);
        }
      }
    }

    for(let actionIcon of this.rowTrailingActionIcons) {

      if(actionIcon.key != null && actionIcon.key != undefined) {
        this.displayedColumns.push(actionIcon.key);
        this.columns.push(actionIcon.key);
      }

    }
  }

  public initializeDisplayedHeaders() {
    this.displayedColumns = [];
    this.columns = []

    for(let actionIcon of this.rowLeadingActionIcons) {

      if(actionIcon.key != null && actionIcon.key != undefined) {
        this.displayedColumns.push(actionIcon.key);
        this.columns.push(actionIcon.key);
      }

    }

    for(let keyIndex: number = 0; keyIndex < this.dataKeys.length; keyIndex++) {
      let key = this.dataKeys[keyIndex];

      if(this.keyLookup != undefined && this.keyLookup[key] != undefined && this.keyLookup[key].display) {
        this.displayedColumns.push(key);
        this.columns.push(key);
      } 
    }

    for(let actionIcon of this.rowTrailingActionIcons) {

      if(actionIcon.key != null && actionIcon.key != undefined) {
        this.displayedColumns.push(actionIcon.key);
        this.columns.push(actionIcon.key);
      }

    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  public openWorkOrderDialog(workOrder: AssetWorkOrder) {
    this.workOrderDialogRef = this.dialog.open(
      VendorWorkOrderDialogComponent,
      { data: workOrder }
    );

    this.workOrderDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    })
    
    this.workOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed: ", data);

      this.dataDialogUpdateEmitter.emit(data);

    })
  }

  // public openFilledDialog() {
  //   this.addAssetWorkOrderDialogRef = this.dialog.open(
  //     VendorAddAssetWorkOrderDialogComponent,
  //     { data: this.workOrderModel }
  //   );

  //   this.addAssetWorkOrderDialogRef.afterClosed().pipe(
  //     filter(data => data)
  //   ).subscribe(data => {
  //     console.log("Dialog Closed");
  //     console.log("Asset Work Order: " + data);

  //     this.navigatorService.upsertAssetWorkOrder(data.workOrderData);
  //   });

  // }


  // public openBlankDialog() {
  //   switch(this.workOrderType) {

  //     case this.tableTypes.ASSET:
  //       this.addAssetWorkOrderDialogRef = this.dialog.open(
  //         VendorAddAssetWorkOrderDialogComponent,
  //         { data: this.workOrderModel });

  //         this.addAssetWorkOrderDialogRef.afterClosed().pipe(
  //           filter(data => data)
  //         ).subscribe(data => {
  //           console.log("Dialog Closed");
  //           console.log("Asset Work Order: " + data);
      
  //           this.navigatorService.upsertAssetWorkOrder(data.workOrderData);
  //         });
  //       break;

  //     case this.tableTypes.INVENTORY:
  //       break;

  //     case this.tableTypes.FACILITY:
  //       break;

  //     case this.tableTypes.FIELD_TECH:
  //       this.addFieldWorkOrderDialogRef = this.dialog.open(VendorAddWorkOrderDialogComponent);

  //       this.addFieldWorkOrderDialogRef.afterClosed().pipe(
  //         filter(data => data)
  //       ).subscribe(data => {
  //         console.log("Dialog Closed", data.workOrderData);
    
  //         this.navigatorService.updateWorkOrder(data.workOrderData).then(incomingData => {
  //           this.workOrderUpdatedEmitter.emit(data.workOrderData);
  //         });
  //       });
  //       break;

  //     case this.tableTypes.INVOICE:
  //       break;
  //   }

  // }

  public OpenClosedSwitch(openOrClosedWorkOrders: number): void {
    this.openOrClosedWorkOrders = openOrClosedWorkOrders;

    this.openOrClosedEmitter.emit(this.openOrClosedWorkOrders);
  }

  public emitActionButton(actionId: string, record: any): void {
    let bundle: any = {
      action: actionId,
      record: record
    };

    this.actionButtonEmitter.emit(bundle);
  }

  public getActionButtonColor(actionId: string, record: any): void {
    let bundle: any = {
      action: actionId,
      record: record
    };

    this.actionButtonStyleEmitter.emit(bundle);
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  showHeaderMenu(): void {
    this.displayHeaderMenu = true;
  }

  hideHeaderMenu(): void {
    this.displayHeaderMenu = false;
  }

  showDateFilterMenu(): void {
    this.displayDateFilterMenu = true;
  }

  hideDateFilterMenu(): void {
    this.displayDateFilterMenu = false;
  }

  expandActionMenu(): void {
    this.isSmallMenuExpanded = true;
  }

  collapseActionMenu(): void {
    this.isSmallMenuExpanded = false;
  }

  

  dateFilterChange(): void {
    if(this.filterForm.valid && this.filterForm.controls["endDate"].value != null) {

      let workOrderFilter: WorkOrderFilter = {
        searchFilter: this.filterValue,
        dateFilter: {
          startDate: this.filterForm.controls["startDate"].value,
          endDate: this.filterForm.controls["endDate"].value
        }
      }
      this.dateChangedEmitter.emit(workOrderFilter);
    } 
  }


  private createKeyLookup(dynamicElement: any): Array<any> {
    let keyLookup: any = { };
    this.keyLookup = null;

    for(const key in dynamicElement) {
      // Exclude Fields That Can't Be Headers
      if(!this.keyExclusion.includes(key) && key) {
        keyLookup[key] = {
          title: key.replace("_", " "),
          data: key,
          display: true
        }

        keyLookup[key].title = keyLookup[key].title.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      }
    }

    this.keyLookup = keyLookup;

    this.initializeDisplayedHeaders();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();

    return keyLookup;
  }

  private createKeys(data: any): void {
    this.dataKeys = [];

    if(!data) {
      data = this.createTestData(0);
    }

    for(const key in data) {
      // Exclude Fields That Can't Be Headers
      if(!this.keyExclusion.includes(key) && key) {
        this.dataKeys.push(key);
      }
    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }


    /** Builds and returns a new Work Order. */
  private createTestData(id: number): AssetWorkOrder {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

    let custPhone: Array<number> = [1234567890, 1234567891];
    let dateContactLog: Array<Date> = [new Date];
    let followOnMaintence: Array<string> = ["Fix Broken Stuff", "Refix Broken Stuff", "Try Not to Break New Stuff"];
    // let workOrderNumber:number = id + 123;
  
    return {
      technicianId: "1a23bcff",
      originatorId: this.navigatorService.getProfileId(),
      workOrderId: this.navigatorService.generateKey(this.keyTypes.WORD_ORDER_ID),
      companyId: this.navigatorService.getCompanyId(),

      originationDate: new Date,
      custPocName: name,
      custPocPhone: 1234567890,
      custPocEmail: 'null@gmail.com',
      custFirstName: name,
      custMiddleName: '',
      custLastName: 'Last Name',
      custEmail: 'null@gmail.com',
      custPhone: custPhone,
      custAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillingAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillAmount: id,
      description: 'Customer Tales of Broken Stuff. Customer Tales of Broken Stuff. Customer Tales of Broken Stuff',
      dateContactLog: dateContactLog,
      dateInitialContact: new Date,
      dateScheduled: new Date,
      priority: 3,
      skillLvl: 2,
      specialNotes: "",
      status: 1,
      foreign_work_orders: followOnMaintence,
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: [],

      // Asset Specific
      operational_status: null,
      id: null,
      title: null,
      barcode: null,
      located_at: null,
      area: null,
      model: null,
      serial_number: null,
      recurring: null,
      recurring_option: null,
      custom_recurring_frequency: null,
      custom_recurring_period: null
    };

  }
}