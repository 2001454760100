import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-animated-scenes",
  templateUrl: "./animated-scenes.component.html",
  styleUrls: ["./animated-scenes.component.scss"]
})

export class AnimatedScenesComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }
}