import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation, Input, SimpleChange, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addWeeks, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import { FormControl } from '@angular/forms';
import { GroupedObservable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { groups } from './groups/vendor-calendar-groups';
import { Subscription } from 'rxjs';

import { workOrderTypesEnum } from '../../../services/vendor/navigator.service';
import { NavigatorService } from '../../../services/vendor/navigator.service';

import { Address, AssetWorkOrder, CustomCalendarEvent, GroupPermissions, Profile, WorkOrder } from '../../../data-models/models'
import { VendorAddWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';
import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendorWorkOrderFormDialogComponent } from '../../professional/vendor-work-order-form-dialog/vendor-work-order-form-dialog.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

export enum EventTypes {
  PERSONAL = 0,
  EVENT = 1,
  WORK_ORDER = 2
}

export enum EventStatus {
  BUSY = 0,
  FREE = 1
}

export interface Filter {
  id: string,
  icon: string,
  tooltip: string,
  active: boolean
}

@Component({
  selector: 'app-vendor-multi-purpose-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-multi-purpose-calendar.component.html',
  styleUrls: ['vendor-multi-purpose-calendar.component.scss']
})

export class VendorMultiPurposeCalendarComponent implements OnInit, AfterViewInit {
  public calendarTypes: any = workOrderTypesEnum;
  public eventTypes: any = EventTypes;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() workOrders: Array<WorkOrder> = null;
  @Input() calendarEvents: Array<CustomCalendarEvent> = [];
  @Input() calendarType: number = this.calendarTypes.FIELD_TECH;
  @Input() incomingAddress: Address;
  @Input() filteredEmployees: Array<Profile> = null;

  @Output() workOrderDateChangedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() workOrderDataChangedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangeNextMonthEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangePreviousMonthEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() eventFocusEmitter: EventEmitter<Event> = new EventEmitter<Event>();

  public largeContainerOptions: boolean = false;
  public mediumContainerOptions: boolean = false;
  public smallContainerOptions: boolean = false;

  @ViewChild("eventCalendar") calendarContainer;

  @HostListener('window:resize')
  public onWindowResize():void {
    (this.calendarContainer.nativeElement.clientWidth > 960) ? this.largeContainerOptions = true : this.largeContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 960 && this.calendarContainer.nativeElement.clientWidth > 480) ? this.mediumContainerOptions = true : this.mediumContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 480) ? this.smallContainerOptions = true : this.smallContainerOptions = false; 
  }

  private selfGovernered: boolean = false;
  private focusDate: Date = new Date();
  public focusEvent: CustomCalendarEvent = null;

  employeesSubscription: Subscription;
  groupPermissionSubscriber: Subscription;

  groupFocusIndex = 0;
  groupOptions: Array<any> = [];
  filteredWorkOrders: Array<WorkOrder> = [];
  filteredCalendarEvents: Array<CustomCalendarEvent> = [];

  addWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;
  workOrderDialogRef: MatDialogRef<VendorWorkOrderFormDialogComponent>;

  employees: Array<Profile> = [];
  employeeGroup: Array<Profile> = [];
  public groupPermissions: Array<GroupPermissions> = [];

  activeFocusId: string = "all";

  view: CalendarView = CalendarView.Month;
  viewMobileSwitchMenu: boolean = false;
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  activeDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<span class="material-icons-outlined actionIcon">description</span>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log("Work Order Clicked: ", event);
        this.openWorkOrderDialog(event.meta.data);
        // this.handleEvent('Edited', event);
      }
    }
  ];

  calendarEventActions: CalendarEventAction[] = [
    {
      label: '<span class="material-icons-outlined actionIcon">description</span>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log("Work Order Clicked: ", event);
        this.editCalendarEvent(event.meta.data);
        // this.handleEvent('Edited', event);
      }
    }
  ];

  calendarFilters: Array<Filter> = [

    {
      id: "today",
      icon: "today",
      tooltip: "Personal Events",
      active: true
    },
    {
      id: "events",
      icon: "calendar_month",
      tooltip: "Events",
      active: true
    },
    {
      id: "work_order",
      icon: "article",
      tooltip: "Work Orders",
      active: true
    },

  ]

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    // LEFT FOR EXAMPLES DURING DEVELOPMENT
    // DELETE FOR PRODUCTION
    // {
    //   start: new Date(2021, 11, 1, 0,0,0),
    //   end: new Date(2021, 11, 1, 2,0,0),
    //   title: 'A 3 day event',
    //   color: colors.red,
    //   actions: this.actions,
    //   allDay: false,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
    // {
    //   start: startOfDay(new Date()),
    //   title: 'An event with no end date',
    //   color: colors.yellow,
    //   actions: this.actions,
    // },
    // {
    //   start: subDays(endOfMonth(new Date()), 3),
    //   end: addDays(endOfMonth(new Date()), 3),
    //   title: 'A long event that spans 2 months',
    //   color: colors.blue,
    //   allDay: true,
    // },
    // {
    //   start: addHours(startOfDay(new Date()), 2),
    //   end: addHours(new Date(), 2),
    //   title: 'A draggable and resizable event',
    //   color: colors.yellow,
    //   actions: this.actions,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // }
  ];

  activeDayIsOpen: boolean = false;

  public filterPanelExpanded: boolean = false;
  public eventPanelExpanded: boolean = false;

  constructor(
    private modal: NgbModal, 
    public dialog: MatDialog, 
    private navigatorService: NavigatorService, 
    public snackBar: MatSnackBar, 
    private changeDetectorRef: ChangeDetectorRef) { 
    
  }

  ngOnInit(): void {
    if(this.workOrders == null) {
      this.selfGovernered = true;
      this.workOrders = [];
      this.getWorkOrders();
    }

    this.subscribeToEmployees();
    this.subscribeToGroupPermissions();
    this.getCalendarEvents();
    this.initializeFilterSize();
    this.activeDate = new Date();

    this.dateChangeNextMonthEmitter.emit(this.activeDate);
  }

  ngAfterViewInit(): void {
    this.initializeFilterSize();
  }

  ngOnDestroy() {

    if(this.employeesSubscription) {
      this.employeesSubscription.unsubscribe();
    }

    if(this.groupPermissionSubscriber) {
      this.groupPermissionSubscriber.unsubscribe();
    }

  } 

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {  
    console.log("Asset Work Order Table Calendar Dynamic Data: ", changes);

    // if(changes && changes.workOrders)  {
    //   this.workOrders = changes.workOrders.currentValue;
    // } else {
    //   this.workOrders = [];
    // }

    console.log("Calendar Work Order Type: ", this.calendarType);
    console.log("Calendar Work Orders: ", this.workOrders);

    this.getCalendarEvents();

    this.filterWorkOrders();
    this.updateEvents();
  }

  private initializeFilterSize(): void {
    (this.calendarContainer.nativeElement.clientWidth > 960) ? this.largeContainerOptions = true : this.largeContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 960 && this.calendarContainer.nativeElement.clientWidth > 480) ? this.mediumContainerOptions = true : this.mediumContainerOptions = false; 
    (this.calendarContainer.nativeElement.clientWidth < 480) ? this.smallContainerOptions = true : this.smallContainerOptions = false; 
  }

  private getWorkOrders(): void {

    this.navigatorService.getAsyncDualMonthOfWorkOrders(this.focusDate).then( (workOrders: Array<WorkOrder>) => {

      this.workOrders = workOrders;
      
      this.filterWorkOrders();
      this.updateEvents();

    });

  }

  subscribeToEmployees(): void {
    this.employeesSubscription = this.navigatorService.getAllEmployees().subscribe(data => { 
      
      if(data != undefined) {
        this.employees = [];

        this.employees = data
      }

      this.updateGroupFocusedEmployees();

      this.changeDetectorRef.markForCheck();
    });
  }

  getEmployees(): void {
    this.navigatorService.getAllEmployees();
  }

  private getCalendarEvents(): void {
    this.focusDate.setDate(1);
    this.focusDate.setHours(0, 0, 0, 0);
    this.focusDate.setHours(24, 0, 0, 0);

    this.navigatorService.getAsyncAllCalendarEvents(this.focusDate).then( (events: Array<CustomCalendarEvent>) => { 

      this.calendarEvents = events;
      this.filterCalendarEvents();

    });

  }

  subscribeToGroupPermissions(): void {
    this.groupPermissionSubscriber = this.navigatorService.getGroupPermissions().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;

      if(data != null) {
        console.log("Returned Group Permissions: ", data);
        this.groupPermissions = data;

        this.updateGroupOptions();
        this.updateGroupFocusedEmployees();
      }
    });
  }

  getGroupPermissions(): void {
    this.navigatorService.getGroupPermissions();
  }

  changeEmployeeFocus(employeeId: string): void {
    this.activeFocusId = employeeId;

    this.filterCalendarEvents();
    this.filterWorkOrders();
  }

  addGroupsToCalendarEvents(): void {
    for(let index = 0; index < groups.length; index++) {
      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events.push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date
        });
      }
    }
  }

  updateGroupOptions(): void {
    this.groupOptions = [];

    for(let group of this.groupPermissions) {
      this.groupOptions.push(group.group);
    }
  }

  updateEvents(): void {
    this.events = [...[]];

    let todayFilter: Filter = this.calendarFilters.find( (filter: Filter) => { return filter.id == "today" });
    let eventsFilter: Filter = this.calendarFilters.find( (filter: Filter) => { return filter.id == "events" });
    let workOrderFilter: Filter = this.calendarFilters.find( (filter: Filter) => { return filter.id == "work_order" });
    let addEvent: boolean = false;

    if(workOrderFilter != undefined && workOrderFilter.active) {
    
      for(let workOrderIndex: number = 0; workOrderIndex < this.filteredWorkOrders.length; workOrderIndex++) {

        let startDate: Date = new Date(this.filteredWorkOrders[workOrderIndex].dateScheduled);
        let timeRequired: number = this.filteredWorkOrders[workOrderIndex].timeStretch;
        let endDateMiliseconds: number = startDate.getTime() + (timeRequired * 60 * 1000);

        let endDate: Date = new Date(endDateMiliseconds);

        // endDate.setMinutes(endDate.getMinutes() + this.filteredWorkOrders[workOrderIndex].timeStretch);

        if(this.calendarType != this.calendarTypes.FIELD_TECH) {
          this.events.push({
            title: this.filteredWorkOrders[workOrderIndex].title + ": " + this.filteredWorkOrders[workOrderIndex].description,
            color: {
              primary: '#ad2121',
              secondary: '#FAE3E3',
            },
            start: new Date(this.filteredWorkOrders[workOrderIndex].originationDate),
            end: endDate,
            actions: this.actions,
            allDay: false,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
            meta: {
              type: this.eventTypes.WORK_ORDER,
              data: this.filteredWorkOrders[workOrderIndex]
            }
            
          });
        } else {
          this.events.push({
            title: 
              this.filteredWorkOrders[workOrderIndex].custFirstName + " " + this.filteredWorkOrders[workOrderIndex].custLastName + "\n" + 
              this.filteredWorkOrders[workOrderIndex].custPhone + "\n" + 
              this.filteredWorkOrders[workOrderIndex].custAddress.street + ", " + 
              this.filteredWorkOrders[workOrderIndex].custAddress.city + ", " + this.filteredWorkOrders[workOrderIndex].custAddress.state + ", " + this.filteredWorkOrders[workOrderIndex].custAddress.zip,
            color: {
              primary: '#ad2121',
              secondary: '#FAE3E3',
            },
            start: new Date(this.filteredWorkOrders[workOrderIndex].dateScheduled),
            end: endDate,
            actions: this.actions,
            allDay: false,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
            meta: {
              type: this.eventTypes.WORK_ORDER,
              data: this.filteredWorkOrders[workOrderIndex]
            }
          });
        }
      }

    }

    console.log("Filtered Calendar Events: ", this.filteredCalendarEvents);
    for(let event of this.filteredCalendarEvents) {
      addEvent = false;

      if(todayFilter != undefined && todayFilter.active && event.type == this.eventTypes.PERSONAL) {
        addEvent = true;
      }

      else if(eventsFilter != undefined && eventsFilter.active && event.type == this.eventTypes.EVENT) {
        addEvent = true;
      }

      if(addEvent) {

        this.events.push({
          title: event.title,
          color: event.color,
          start: event.start,
          end: event.end,
          actions: this.calendarEventActions,
          allDay: event.allDay,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
          meta: {
            type: this.eventTypes.EVENT,
            data: event
          }

        });

      }

    }

    this.refresh.next();
  }

  editCalendarEvent(event: CustomCalendarEvent): void {
    this.focusEvent = event;
    this.expandEventPanel();
  }

  toggleMobileSwitchMenu(): void {
    if(this.viewMobileSwitchMenu)
      this.viewMobileSwitchMenu = false;
    else {
      this.viewMobileSwitchMenu = true;
    }
  }

  nextMonth(): void {
    if(this.view === CalendarView.Month) {
      console.log("Previous Date: ", this.activeDate);
      this.activeDate = addMonths(this.activeDate, 1);
      console.log("Change Date: ", this.activeDate);

      this.focusDate = this.activeDate;

      if(this.selfGovernered) {
        this.getWorkOrders();
      }

      this.dateChangeNextMonthEmitter.emit(this.activeDate);
    }

    else if(this.view === CalendarView.Week) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addWeeks(this.activeDate, 1);
      console.log("Change Date: ", this.activeDate);
      
      this.focusDate = this.activeDate;
      if(tempMonth != this.activeDate.getMonth()) {
        if(this.selfGovernered) {
          this.getWorkOrders();
        }

        this.dateChangeNextMonthEmitter.emit(this.activeDate);
      }
    }

    else if(this.view === CalendarView.Day) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addDays(this.activeDate, 1);
      console.log("Change Date: ", this.activeDate);
      

      this.focusDate = this.activeDate;
      if(tempMonth != this.activeDate.getMonth()) {
        if(this.selfGovernered) {
          this.getWorkOrders();
        }

        this.dateChangeNextMonthEmitter.emit(this.activeDate);
      }
    }

  }

  previousMonth(): void {
    if(this.view === CalendarView.Month) {
      console.log("Previous Date: ", this.activeDate);
      this.activeDate = addMonths(this.activeDate, -1);
      console.log("Change Date: ", this.activeDate);
      
      this.focusDate = this.activeDate;

      if(this.selfGovernered) {
        this.getWorkOrders();
      }

      this.dateChangePreviousMonthEmitter.emit(this.activeDate);
    }

    else if(this.view === CalendarView.Week) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addWeeks(this.activeDate, -1);
      console.log("Change Date: ", this.activeDate);
      
      this.focusDate = this.activeDate;
      if(tempMonth != this.activeDate.getMonth()) {
        if(this.selfGovernered) {
          this.getWorkOrders();
        }

        this.dateChangePreviousMonthEmitter.emit(this.activeDate);
      }
    }

    else if(this.view === CalendarView.Day) {
      let tempMonth = this.activeDate.getMonth();

      this.activeDate = addDays(this.activeDate, -1);
      console.log("Change Date: ", this.activeDate);
      
      this.focusDate = this.activeDate;
      if(tempMonth != this.activeDate.getMonth()) {

        if(this.selfGovernered) {
          this.getWorkOrders();
        }

        this.dateChangePreviousMonthEmitter.emit(this.activeDate);
      }
    }

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {

    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });

    switch(event.meta.type) {

      case this.eventTypes.EVENT:
        this.updateCalendarEvent(event.meta.data, newStart, newEnd);
        break;

      case this.eventTypes.WORK_ORDER:
        this.updateWorkOrder(event.meta.data, newStart, newEnd);
        break;

    }

    
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  updateCalendarEvent(event: CustomCalendarEvent, startDate: Date, endDate: Date) {

    event.start = startDate;
    event.end = endDate

    this.navigatorService.upsertCalendarEvent(event);
  }

  saveWorkOrder(workOrder: WorkOrder): void {

    if(this.selfGovernered) {

      this.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Work order Saved Successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.snackBar.open('Work order failed to save, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }

      });

    }

  }

  updateWorkOrderDate(workOrderChanged: any): void {

    if(workOrderChanged.endDate != undefined) {
      let dateDiff: number = Math.abs(Math.floor((workOrderChanged.startDate - workOrderChanged.endDate) / ( 1000 * 60 )));

      workOrderChanged.workOrder.timeStretch = dateDiff;
    }

    workOrderChanged.workOrder.dateScheduled = workOrderChanged.startDate;
    workOrderChanged.workOrder.endDate = workOrderChanged.endDate;

    this.saveWorkOrder(workOrderChanged.workOrder);
  }

  updateWorkOrder(workOrder: any, startDate: Date, endDate: Date) {

    this.focusDate = startDate;

    let update = ({
      workOrder: workOrder,
      startDate: startDate,
      endDate: endDate
    });

    console.log("Work Order Emitter");

    this.updateWorkOrderDate(update);
    this.workOrderDateChangedEmitter.emit(update);
  }

  setGroupFilter(groupIndex: number) {
    console.log("Group Filter: ", groupIndex);
    this.groupFocusIndex = groupIndex;

    this.updateGroupFocusedEmployees();
  }

  updateGroupFocusedEmployees(): void {
    this.employeeGroup = [];

    for(let employeeIndex: number = 0; employeeIndex < this.employees.length; employeeIndex++) {

      // If the focused on group contains the current employee then add it to it's array
      if(this.groupPermissions.length > 0 && this.groupPermissions[this.groupFocusIndex].employeeIds.indexOf(this.employees[employeeIndex].id) > -1) {
        this.employeeGroup.push(this.employees[employeeIndex]);
      }
    }

    this.filterCalendarEvents();
    this.filterWorkOrders();
  }

  async filterCalendarEvents(): Promise<void> {
    this.filteredCalendarEvents = [...[]];

    if(this.activeFocusId == "all") {

      for(let event of this.calendarEvents) {

        if(this.groupPermissions[this.groupFocusIndex].id == 'locked-all') {

          this.filteredCalendarEvents.push(event);
          console.log("Found Team");

        } else {

          for(let employeeIndex: number = 0; employeeIndex < this.employeeGroup.length; employeeIndex++) {

            //Teams
            for(let team of event.assignedTeams) {

              if(team == this.groupPermissions[this.groupFocusIndex].id) {
                this.filteredCalendarEvents.push(event);
                console.log("Found Team");
                break;
              }

            }

          }

        }

      }

    } else {
      let isContinue: boolean = true;

      for(let event of this.calendarEvents) {
        isContinue = true;

        if(event.assignedStaff == undefined) {
          event.assignedStaff = [];
        }

        if(event.assignedTeams == undefined) {
          event.assignedTeams = [];
        }

        for(let staff of event?.assignedStaff) {

          if(staff == this.activeFocusId) {
            this.filteredCalendarEvents.push(event);
            isContinue = false;
            break;
          }

        }
        
        for(let team of event?.assignedTeams) {

          if(isContinue) {

            await this.navigatorService.getGroupPermission(team).then( (group: GroupPermissions) => {

              for(let staff of group.employeeIds) {

                if(staff == this.activeFocusId) {
                  this.filteredCalendarEvents.push(event);
                  isContinue = false;
                  break;
                }

              }

            });

          }

        }
        
        
      }

    }

    this.updateEvents();
  }

  async filterWorkOrders(): Promise<void> {
    this.filteredWorkOrders = [...[]];

    if(this.activeFocusId == "all") {
      for(let employeeIndex: number = 0; employeeIndex < this.employeeGroup.length; employeeIndex++) {

        for(let workOrder of this.workOrders) {

          if(this.activeFocusId == "all") {
            if(workOrder.technicianId == this.employeeGroup[employeeIndex].id) {
              // this.employeeGroup.push(this.employees[employeeIndex]);
              this.filteredWorkOrders.push(workOrder);
            }
          }
        }

      }

      // Unassigned Work Orders
      for(let workOrder of this.workOrders) {

        if(

          (workOrder?.technicianId == undefined || 
          workOrder?.technicianId == null) &&

          (workOrder?.assignedStaff == undefined || 
            workOrder?.assignedStaff == null || 
            workOrder?.assignedStaff.length >= 0) &&

          (workOrder?.assignedTeams == undefined || 
            workOrder?.assignedTeams == null || 
            workOrder?.assignedTeams.length >= 0)
        
        ) {
          this.filteredWorkOrders.push(workOrder);
        }

      }

    } else {
      let isContinue: boolean = true;

      for(let workOrder of this.workOrders) {
        isContinue = true;

        if(workOrder.assignedStaff == undefined) {
          workOrder.assignedStaff = [];
        }

        if(workOrder.assignedTeams == undefined) {
          workOrder.assignedTeams = [];
        }

        if(workOrder.technicianId == this.activeFocusId) {
          // this.employeeGroup.push(this.employees[employeeIndex]);
          this.filteredWorkOrders.push(workOrder);
          isContinue = false;
        }

        if(isContinue) {
          for(let staff of workOrder?.assignedStaff) {

            if(staff == this.activeFocusId) {
              this.filteredWorkOrders.push(workOrder);
              isContinue = false;
              break;
            }

          }
        }

        if(isContinue) {
          for(let team of workOrder?.assignedTeams) {

            if(isContinue) {

              await this.navigatorService.getGroupPermission(team).then( (group: GroupPermissions) => {

                for(let staff of group.employeeIds) {

                  if(staff == this.activeFocusId) {
                    this.filteredWorkOrders.push(workOrder);
                    isContinue = false;
                    break;
                  }

                }

              });

            }

          }
        }
        
      }
    }

    this.updateEvents();
  }

  addWorkOrderEvent(date: Date): void {
    let startDate = new Date(date);
    let endDate = new Date(date);
    endDate.setHours(endDate.getHours() + 1);

    this.events.push(
      {
        title: 'Admin',
        start: startDate,
        end: endDate,
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    )
  }

  addEvent(date: Date): void {
    let startDate = new Date(date);
    let endDate = new Date(date);
    endDate.setHours(endDate.getHours() + 1);

    // this.calendarEvents.push(
    //   {
    //     id: this.navigatorService.generateKey(),
    //     type: this.eventTypes.PERSONAL,
    //     assignedStaff: [ this.navigatorService.getProfileId() ],
    //     assignedTeams: [],
    //     start: startDate,
    //     end: endDate,
    //     title: "Test Event",
    //     color: colors.yellow,
    //     actions: [],
    //     allDay: false,
    //     meta: null
    //   },
    // )

    this.filterCalendarEvents();
    console.log("Events: ", this.events);
  }

  contextMenuAddWorkOrder(date: Date, option): void {
    // this.events = [];

    
    this.openAddWorkOrderDialog(date);

    this.refresh.next();
  }

  // LEFT FOR EXAMPLES DURING DEVELOPMENT
  // DELETE FOR PRODUCTION
  // contextMenuAddEvent(date: Date): void {
  //   this.events.push({
  //     start: date,
  //     title: 'New event',
  //     color: colors.red,
  //   });
  //   this.refresh.next();
  // }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
      // Do Something
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
      // Do Something
    }

    this.activeDayIsOpen = false;
  }

  public expandFilterPanel(): void {
    this.filterPanelExpanded = true;
  }

  public collapseFilterPanel(): void {
    this.filterPanelExpanded = false;
  }

  public expandEventPanel(): void {
    this.eventPanelExpanded = true;
    this.refresh.next();
  }

  public collapseEventPanel(): void {
    this.eventPanelExpanded = false;
    this.focusEvent = null;
    this.refresh.next();
  }

  public toggleFilter(filter: Filter): void {

    if(filter.active) {
      filter.active = false;
    } else {
      filter.active = true;
    }

    this.updateEvents();
  }

  public openNewEventPanel(date: Date): void {
    this.focusEvent = null;
    this.focusDate = date;
    this.expandEventPanel();
  }

  public openWorkOrderDialog(workOrder: WorkOrder) {

    console.log("Work Order: ", workOrder);

    this.workOrderDialogRef = this.dialog.open(
      // VendorWorkOrderDialogComponent,
      VendorWorkOrderFormDialogComponent,
      { 
        data: {
          workOrder: workOrder.workOrderId
        }
      
      }
    );

    this.workOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      this.saveWorkOrder(data);
      this.workOrderDataChangedEmitter.emit(data);

      // this.files.push({ name, content: ''});
    })
  }

  public openAddWorkOrderDialog(date: any) {
    this.addWorkOrderDialogRef = this.dialog.open(
      VendorAddWorkOrderDialogComponent,
      {
        data: {
          date: date,
          address: this.incomingAddress
        }
      }
    );

    this.addWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed", data.workOrderData);

      this.navigatorService.updateWorkOrder(data.workOrderData);

      if(data) {
        // this.addWorkOrderEvent(data.workOrderData.originationDate);
        this.filterWorkOrders();
      }

      // this.files.push({ name, content: ''});
    })
  }
}
