import { Component, OnInit, ViewChild, AfterViewInit, Input, SimpleChanges  } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { keyTypesEnum } from '../../../services/vendor/navigator.service';
import { NavigatorService } from '../../../services/vendor/navigator.service';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {WorkOrder, ExpandableTableDisplayColumns} from '../../../data-models/models';

import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';

export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
  }
  
  /** Constants used to fill up our data base. */
  const COLORS: string[] = [
    'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
    'aqua', 'blue', 'navy', 'black', 'gray'
  ];
  const NAMES: string[] = [
    'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
    'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
  ];

@Component({
  selector: 'app-vendor-follow-on-expandable-table',
  templateUrl: 'vendor-follow-on-expandable-table.component.html',
  styleUrls: ['vendor-follow-on-expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class VendorFollowOnExpandableTableComponent implements OnInit, AfterViewInit {
  workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  private keyTypes: any = keyTypesEnum;

  public workOrderData: WorkOrder;
  public workOrderKeys: Array<string> = [];


  displayedColumns: string[];
  dataSource: MatTableDataSource<WorkOrder>;
  expandedElement: UserData | null;

  @Input() data: Array<WorkOrder> = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // displayedRows$: Observable<ShipData[]>;
  // totalRows$: Observable<number>;

  constructor(public dialog: MatDialog, private navigatorService: NavigatorService) {

    // Assign the data to the data source for the table to render
    this.displayedColumns = [];
    this.dataSource = new MatTableDataSource(this.data);
    }

  ngOnInit(): void {
    
    
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Work Order: " + changes.prop);
    
    if(changes.data != undefined && changes.data.currentValue != undefined) {

      if(changes.data.currentValue.length > 0) {
        for(const key in changes.data.currentValue[0]) {
          this.workOrderKeys.push(key);
        }
      } else {
        this.createDefaultKeys();
      }

      this.data = changes.data.currentValue;
      this.dataSource.data = this.data;

      this.displayedColumns = ['workOrderNumber', 'description', 'status', 'priority'];
    } else {
      this.createDefaultKeys();
    }
  } 

  recordValueLookup(record: any, key: string): any {
    return record[key];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public openWorkOrderDialog() {
    this.workOrderDialogRef = this.dialog.open(VendorWorkOrderDialogComponent);

    this.workOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  private createDefaultKeys(): void {
    let defaultWorkOrder = this.createTestData(0);

    for(const key in defaultWorkOrder) {
      this.workOrderKeys.push(key);
    }
  }


    /** Builds and returns a new User. */
  private createTestData(id: number): WorkOrder {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

    let custPhone: string = null;
    let dateContactLog: Array<Date> = [new Date];
    let followOnMaintence: Array<string> = ["Fix Broken Stuff", "Refix Broken Stuff", "Try Not to Break New Stuff"];
    // let workOrderNumber:number = id + 123;
  
    return {
      technicianId: "1a23bcff",
      originatorId: this.navigatorService.getProfileId(),
      workOrderId: this.navigatorService.generateKey(this.keyTypes.WORD_ORDER_ID),
      companyId: this.navigatorService.getCompanyId(),

      originationDate: new Date,
      custPocName: name,
      custPocPhone: 1234567890,
      custPocEmail: 'null@gmail.com',
      custFirstName: name,
      custMiddleName: '',
      custLastName: 'Last Name',
      custEmail: 'null@gmail.com',
      custPhone: custPhone,
      custAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillingAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillAmount: id,
      description: 'Customer Tales of Broken Stuff. Customer Tales of Broken Stuff. Customer Tales of Broken Stuff',
      dateContactLog: dateContactLog,
      dateInitialContact: new Date,
      dateScheduled: new Date,
      priority: 3,
      skillLvl: 2,
      specialNotes: "",
      status: 1,
      foreign_work_orders: followOnMaintence,
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: []
    };

}
  }
