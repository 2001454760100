import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator, matchingPasswords } from 'src/app/theme/utils/app-validators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NavigatorService, workOrderStatusTypes } from 'src/app/services/vendor/navigator.service';
import { Automation, AutomationTag } from 'src/app/data-models/models';

export enum TagTypes {
  WO_STATUS = 0,
  WORK_ORDER = 1,
  DEAL = 2,
  PIPE = 3,
  INVOICE = 4
}

export enum NotificationMethodsEnum {
  SMS = 0,
  EMAIL = 1
}

@Component({
  selector: 'app-automation-tags',
  templateUrl: './automation-tags.component.html',
  styleUrls: ['./automation-tags.component.scss']
})

export class AutomationTagsComponent implements OnInit {

  public tagTypes: any = TagTypes;
  public jobStatusTypes: any = workOrderStatusTypes;
  public notificationMethods: any = NotificationMethodsEnum;
  public workOrderStatusOptions: Array<any> = [];

  public focusTag: AutomationTag = null;
  public tags: Array<AutomationTag> = [];

  public wo_status: Array<AutomationTag> = [];
  public work_order: Array<AutomationTag> = [];
  public deal: Array<AutomationTag> = [];
  public pipe: Array<AutomationTag> = [];
  public invoice: Array<AutomationTag> = [];

  public tagOptionFocus: number = this.tagTypes.WO_STATUS;

  constructor(
    public formBuilder: FormBuilder, 
    public snackBar: MatSnackBar, 
    private navigatorService: NavigatorService) { }

  ngOnInit() {
    this.getAutomationTags();
    this.workOrderStatusOptions = this.navigatorService.getStatusOptions();
  }

  private getAutomationTags(): void {

    this.navigatorService.getAsyncAutomationTags().then( (tags: Array<AutomationTag>) => {
      this.tags = tags;

      this.filterTags();
    });

  }

  public saveTag(tag: AutomationTag): void {

    console.log("Save Tag: ", tag);

    this.navigatorService.updateAutomationTag(tag).then( (status: boolean) => {

      if(status) {
        this.snackBar.open('Tag Saved Successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open('Tag failed to save, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  private deleteTag(tag: AutomationTag): void {

    this.navigatorService.deleteAutomationTag(tag).then( (status: boolean) => {

      if(status) {
        // Do Nothing
      }

    });

  }

  private filterTags(): void {

    this.wo_status = [];
    this.work_order = [];
    this.deal = [];
    this.pipe = [];
    this.invoice = [];

    for(let tag of this.tags) {

      switch(tag.tagType) {

        case this.tagTypes.WO_STATUS:
          this.wo_status.push(tag);
          break;

        case this.tagTypes.WORK_ORDER:
          this.work_order.push(tag);
          break;

        case this.tagTypes.DEAL:
          this.deal.push(tag);
          break;

        case this.tagTypes.PIPE:
          this.pipe.push(tag);
          break;

        case this.tagTypes.INVOICE:
          this.invoice.push(tag);
          break;

      }

    }

    // for(let index: number = 0; index < 10; index++) {
    //   this.wo_status.push({
    //     id: this.navigatorService.generateKey(),
    //     companyId: this.navigatorService.getCompanyId(),
    //     title: "Test " + (index + 1),
    //     description: "",
    //     tagType: this.tagTypes.WO_STATUS,
    //     baseType: this.jobStatusTypes.SCHEDULED,

    //     automation: null,

    //     data: null,

    //     color: "#000000",
    //     border: "#b00e8d",
    //     background: "#56fc03"
    //   });
    // }

  }

  public addNewTag(type: number, base: number): void {
    let newTag: AutomationTag = {
      id: this.navigatorService.generateKey(),
      companyId: this.navigatorService.getCompanyId(),
      title: 'New Tag',
      tagType: type,
      baseType: base, 
      automation: undefined,
      border: '#000000',
      background: '#ffffff',
      color: '#000000',

      extNotificationAutomation: {
        id: this.navigatorService.generateKey(),

        allowEmail: false,
        allowSms: false,

        emailMsg: "",
        smsMsg: ""
      },

      description: ''
    }

    this.tags.unshift(newTag);
    this.filterTags();

    this.focusTag = this.tags[0];

    this.saveTag(this.focusTag);
  }

  public changeOptionFocus(option: number): void {
    this.tagOptionFocus = option;
  }

  public changeStatusFocus(tag: AutomationTag): void {

    this.focusTag = tag;

  }

  public deleteStatus(tag: AutomationTag): void {
    let tagIndex: number = this.tags.findIndex( (el: AutomationTag) => { return  el.id == tag.id });

    if(tag != undefined && tag != null) {
      this.tags.splice(tagIndex, 1);
      this.filterTags();

      this.focusTag = null;

      this.deleteTag(tag);
    }

  }




  public colorChange(): void {
    // Do nothing
  }

  public tagTitleChange(title: string, tag: AutomationTag): void {

    if(title.length > 0) {
      tag.title = title;
    } 

  }

  public tagDescriptionChange(desc: string, tag: AutomationTag): void {

    tag.description = desc;

  }

  public activateNotificationMethod(method: number, tag: AutomationTag): void {

    if(tag.extNotificationAutomation == undefined || tag.extNotificationAutomation == null) {
      tag.extNotificationAutomation = {
        id: this.navigatorService.generateKey(),

        allowEmail: false,
        allowSms: false,

        emailMsg: "",
        smsMsg: ""
      }

    }

    switch(method) {

      case this.notificationMethods.SMS:

        if(tag.extNotificationAutomation.allowSms) {
          tag.extNotificationAutomation.allowSms = false;
        } else {
          tag.extNotificationAutomation.allowSms = true;
        }

        break;

      case this.notificationMethods.EMAIL:

        if(tag.extNotificationAutomation.allowEmail) {
          tag.extNotificationAutomation.allowEmail = false;
        } else {
          tag.extNotificationAutomation.allowEmail = true;
        }

        break;
    }

  }

  public toggleAutoSendEmailChange(tag: AutomationTag): void {

    if(tag.extNotificationAutomation.allowEmail) {
      tag.extNotificationAutomation.allowEmail = false;
    } else {
      tag.extNotificationAutomation.allowEmail = true;
    }
    
  }

  public toggleAutoSendSmsChange(tag: AutomationTag): void {

    if(tag.extNotificationAutomation.allowSms) {
      tag.extNotificationAutomation.allowSms = false;
    } else {
      tag.extNotificationAutomation.allowSms = true;
    }

  }

  public emailMsgChange(msg: string, tag: AutomationTag): void {
    
    if(tag.extNotificationAutomation == undefined || tag.extNotificationAutomation == null) {
      tag.extNotificationAutomation = {
        id: this.navigatorService.generateKey(),

        allowEmail: false,
        allowSms: false,

        emailMsg: "",
        smsMsg: ""
      }

    }

    tag.extNotificationAutomation.emailMsg = msg;

  }

  public smsMsgChange(msg: string, tag: AutomationTag): void {

    if(tag.extNotificationAutomation == undefined || tag.extNotificationAutomation == null) {
      tag.extNotificationAutomation = {
        id: this.navigatorService.generateKey(),

        allowEmail: false,
        allowSms: false,

        emailMsg: "",
        smsMsg: ""
      }

    }

    tag.extNotificationAutomation.smsMsg = msg;

  }

}
