import { Component, OnInit, AfterViewInit, OnDestroy, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Color } from 'ng2-charts';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { CrossModuleChart, CrossModuleChartTypes } from '../../professional/vendor-cross-module-report/vendor-cross-module-report.component';


@Component({
  selector: 'app-vendor-cross-module-report-creation',
  templateUrl: 'vendor-cross-module-report-creation.component.html',
  styleUrls: ['vendor-cross-module-report-creation.component.scss']
})

export class VendorCrossModuleReportCreationComponent implements OnInit, AfterViewInit {

  @Input() chart: CrossModuleChart = null;

  public chartTypes: any = CrossModuleChartTypes;
  public chartType: number = this.chartTypes.LINE;

  public detailForm: FormGroup;

  // Bar Chart
  public RI_labels: Array<string> = ['Extreme Heating & Cooling', 'Bob\'s Roofing', 'Arctic Air'];
  public RI_data: any = [

      { data: 350, label: '' }, 
      { data: 450, label: '' }, 
      { data: 100, label: '' }

  ];

  public RI_colors: Color[] = [
    {
      backgroundColor:["rgba(75, 161, 44, 0.5)", "rgba(210, 164, 25, 0.5)", "rgba(208, 23, 23, 0.5)", "rgba(208, 23, 23, 0.5)"]
    }
  ];


  // Line Chart
  public RT_labels: Array<string> = ['February', 'March', 'April', 'May', 'June', 'July'];
  public RT_data: any = {
    data: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Extreme Heating & Cooling' },
      { data: [192, 134, 240, 181, 265, 255, 174], label: 'Bob\'s Roofing' },
      { data: [77, 23, 45, 60, 65, 40, 81], label: 'Arctic Air' }
    ]
  };

  public RT_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    },
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    },
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];


  private PL_profits: number = 0;
  private PL_profjected: number = 0;
  private PL_missed_earnings: number = 0;


  // Doughnut Chart
  public PL_labels: Array<string> = ['Profit', 'Projected', 'Missed Earnings'];
  public PL_data: Array< Array<any> > | Array<any> = [ 
    // [0, 0, 0]
    [50, 150, 120],
    // [250, 130, 70],
  ];

  public PL_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];

  constructor(
    private navigatorService: NavigatorService,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({
  
      title: [ {value: '', disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)]) ],
      assignedGuests: [ { value: [], disabled: false } ],
      organization: [ { value: '', disabled: false } ],
      desc: [ { value: null, disabled: false }],
      notes: [ { value: '', disabled: false }],
      startDate: [ { value: new Date(), disabled: true }, Validators.required ],
      endDate: [ { value: new Date(), disabled: true }, Validators.required ],
      startTime: [ { value: "", disabled: false }, Validators.required ],
      endTime: [ { value: "", disabled: false }, Validators.required ],
      timeStretch: [ { value: 0, disabled: true }, Validators.required ],
      allDay: [ { value: false, disabled: false } ],

      attachments: [ { value: [], disabled: false } ],
      status: [ { value: 0, disabled: true } ],

      notification: [ { value: new Date, disabled: false } ]
    });

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }


  public chartSwitchLeft(): void {

    if(this.chartType == this.chartTypes.LINE) {
      this.chartType = this.chartTypes.DOUGHNUT;
    } else {
      this.chartType--;
    }

  }

  public chartSwitchRight(): void {

    if(this.chartType == this.chartTypes.DOUGHNUT) {
      this.chartType = this.chartTypes.LINE;
    } else {
      this.chartType++;
    }

  }

  public saveEvent(): void {
    
  }

}