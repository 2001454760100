import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
  styleUrls: ['./toolbar1.component.scss']
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();

  public loggedIn: boolean = false;


  constructor(public appService:AppService, private vendorService: NavigatorService) { }

  ngOnInit() { 
  //   this.vendorService.isLoggedInMonitor().subscribe(profile => {
  //     if(profile) {
  //       this.loggedIn = true;
  //     } else {
  //       this.loggedIn = false
  //     }
  //   });

    this.loggedIn = this.vendorService.getProfileId().length > 0;

    this.vendorService.monitorLoggedInProfileId().subscribe(id => {
      if(id.length > 0) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false
      }
    });
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}