import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Address, ApplicationNotificationMessage, CompanyProfile, CRMActivity, CRMDeal, CRMDealPipeAuditLog, CRMPipe, CRMPOC, Budget, DocumentedDoc, DocumentedImage, FileObject, LatLng, Profile, ReferralLink, RevSubscription, WorkOrder, WorkOrderFilter, PartAttachBundle, DocumentedForm } from 'src/app/data-models/models';
import { keyTypesEnum, NavigatorService, notificationCategoriesEnum, notificationStatusEnum } from 'src/app/services/vendor/navigator.service';
import { Settings, AppSettings } from '../../../app.settings';
import { ProgressTick } from '../../professional/vendor-crm-add-deal-dialog/vendor-crm-add-deal-dialog.component';

import "quill-mention";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorCRMActivityDialogComponent } from '../../professional/vendor-crm-activity-dialog/vendor-crm-activity-dialog.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Serviceabletypes } from '../../professional/vendor-service-area-map/vendor-service-area-map.component';

import { Color } from 'ng2-charts';
import { VendorFormCreateDocumentDialogComponent } from '../../professional/vendor-form-create-document-dialog/vendor-form-create-document-dialog.component';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NotificationService } from 'src/app/services/vendor/notification.service';
import { id } from 'date-fns/locale';
import { VendorProjectBudgetDialogComponent } from '../../professional/vendor-project-budget-dialog/vendor-project-budget-dialog.component';
import { BoardTypes } from 'src/app/vendor/crm/job-board/job-board.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { referralStatusTypes } from 'src/app/vendor/swarm-internal-tools/sales-portal/sales-portal.component';
import { HttpClient } from '@angular/common/http';
import { PageChangeEvent } from 'src/app/vendor/forms/forms.component';
import { VendorFormBuilderDialogComponent } from '../vendor-form-builder-dialog/vendor-form-builder-dialog.component';

export enum ActivityLogCategories {
  ALL = 0,
  NOTES = 1,
  ACTIVITY = 2,
  CALL = 3,
  EMAIL = 4,
  FILES = 5,
  DOCUMENTS = 6,
  INVOICE = 7,

  BLANK = 999
}

export enum ActivityTypes {
  MEETING = 0,
  CALL = 1,
  DRIVE = 2,
  SCHEDULE = 3,
  COMPLETE = 4,
  MAIL = 5,
  FOOD = 6
}

export enum NoteTypes {
  NOTES = 0,
  SCOPE = 1,
  DESCRIPTION = 2
}

export enum RevenueViewTypes {
  SUBSCRIPTION = 0,
  DIRECT = 1
}

export enum DetailEnum {
  DESCRIPTION = 0,
  DEAL_SCOPE = 1
}

export enum DocumentTypesEnum {
  DOCUMENTS = 0,
  IMGS = 1,
  DEAL = 2
}

enum SidebarTypes {
  CONTACT = 0,
  COMPANY = 1,
  SALES_LINK = 2
}

@Component({
  selector: 'app-vendor-crm-deal',
  templateUrl: 'vendor-crm-deal.component.html',
  styleUrls: ['vendor-crm-deal.component.scss']
})

export class CRMDealComponent implements OnInit {
  public boardTypes: any = BoardTypes;
  
  @Input('deal') deal: CRMDeal = {
    id: 'nodeal',
    assignedStaff: [],
    title: 'Loading...',
    description: '',
    notes: '',
    price: 0,
    probability: 0,
    poc: [],
    tags: [],
    leadSource: '',
    auditTrail: [],
    activities: [],
    pipeline: '',
    pipe: '',
    priority: 0,
    expectedCloseDate: new Date,

    last_update: new Date,
    created_by: "swarm"
  }

  @Input('pipeline') pipeline: string = '';
  @Input('boardType') boardType: number = this.boardTypes.PROJECTS;

  @Output('referralSent') referralSent: EventEmitter<any> = new EventEmitter<any>(); 

  public activityLogCategories: any = ActivityLogCategories;
  public activityTypes: any = ActivityTypes;
  public revenueViewTypes: any = RevenueViewTypes;
  public noteTypes: any = NoteTypes;
  public serviceabletypes: any = Serviceabletypes;
  public detailTypes: any = DetailEnum;
  public documentTypes: any = DocumentTypesEnum;
  public sidebarTypes: any = SidebarTypes;
  private keyTypes: any = keyTypesEnum;
  private notificationCategories: any = notificationCategoriesEnum;
  private notificationStatus: any = notificationStatusEnum;

  private activityDialogRef: MatDialogRef<VendorCRMActivityDialogComponent>;
  private createDocumentDialogRef: MatDialogRef<VendorFormCreateDocumentDialogComponent>;
  private projectBudgetDialogRef: MatDialogRef<VendorProjectBudgetDialogComponent>;
  private formDialogRef: MatDialogRef<VendorFormBuilderDialogComponent>;

  private pushNotificatons: boolean = false;

  public editTitleMode: boolean = false;
  public editPriceMode: boolean = false;
  public editScopeMode: boolean = false;
  public sidebarType: number = null;

  public sidebarPanelExpanded: boolean = false;

  private scope: string = "";
  public scopeRefresh: boolean = false;

  public addressPanelExpanded: boolean = false;

  public isInServiceArea: number = null;
  public serviceable: number = null;
  public incomingAddress: Address = null;
  public assignedAddress: Address = null;
  public addressLatLng: LatLng = null;
  public incomingLatLng: LatLng = null;

  private openWorkOrderSubscriber: Subscription;
  private closedWorkOrderSubscriber: Subscription;
  private addressToLatLngSubscription: Subscription;

  public pocForm: FormGroup;
  public locationForm: FormGroup;

  public swimLanes: Array<CRMPipe> = [];

  public pipes: Array<CRMPipe> = [];
  public pipeAudit: Array<number> = [];
  public workOrders: Array<WorkOrder> = [];
  public completedPipeIndex: number = 999;
  public assignedPOC: CRMPOC = null;
  public attachedParts: Array<PartAttachBundle> = (this.deal.hasOwnProperty('attachedParts')? this.deal.attachedParts : [ ] );

  public activeDoc: string = null;
  public activeTemplate: string = null;

  public subscriptionFocus: RevSubscription = {
    id: this.navigatorService.generateKey(),
    invoice: undefined,
    price: 500,
    status: 0,
    recurring_cycle: 15,
    startDate: new Date(),
    stopDate: new Date(),
    desc: 'Fuel',
    tags: []
  };
  
  public revenueViewFocus: number = this.revenueViewTypes.DIRECT;
  public activityLoggingFocus: number = this.activityLogCategories.BLANK;
  public activityLoggingFilterFocus: number = this.activityLogCategories.ALL;

  public activeNoteContent: string = "";
  public activeNoteText: string = "";
  public progressTicker: Array<ProgressTick> = [];

  public quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link', 'image', 'video']                         // link and image, video
    ],
    mention: {
      mentionListClass: "ql-mention-list mat-elevation-z8",
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      showDenotationChar: false,
      spaceAfterInsert: false,
      onSelect: (item, insertItem) => {
        // const editor = this.editor.quillEditor;
        insertItem(item);

        console.log("Quil Item: ", item);
        console.log("Quil Item: ", insertItem);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        // editor.insertText(editor.getLength() - 1, "", "user");
      },

      source: (searchTerm, renderList) => {
        // let values = [
        //   { id: 1, value: "Fredrik Sundqvist", age: 5 },
        //   { id: 2, value: "Patrik Sjölin", age: 20 }
        // ];

        console.log("Source Check: ", searchTerm);

        this.filterEmployee(searchTerm).then( (filteredProfiles: Array<Profile>) => {
          let values = [];

          if (searchTerm.length === 0) {
            for(let profile of filteredProfiles) {
              values.push({
                id: profile.id,
                value: profile.first_name + " " + profile.last_name
              });
            }

            renderList(values, searchTerm);
          } else {
            let matches = [];

            // values.forEach(entry => {
            //   if (
            //     entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            //   ) {
            //     matches.push(entry);
            //   }
            // });
            for(let profile of filteredProfiles) {
              matches.push({
                id: profile.id,
                value: profile.first_name + " " + profile.last_name
              });
            }

            renderList(matches, searchTerm);
          }

        });

      }

    }

  };

  public activities: Array<CRMActivity> = [ ];
  public plannedActivities: Array<CRMActivity> = [ ]

  public completedActivities: Array<CRMActivity> = [ ];

  private tableFilter: WorkOrderFilter = null;
  private openOrClosedFilter: number = 0;
  private startDate: Date = new Date();
  private endDate: Date = new Date();
  private linkNewWorkOrders: boolean = false;

  // Images & Documents
  public imageDeleteAction: boolean = false;
  public jobAuditFocus: number = null;
  public documentAuditFocus: number = null;
  public dealAuditFocus: number = null;

  public jobImages: Array<DocumentedImage> = [];
  public documents: Array<DocumentedDoc> = [];
  public forms: Array<DocumentedForm> = [];

  public focusImg: any = null;
  public focusDocument: any = null;
  public focusDealDocument: any = null;

  public uploadedJobImages: any = [];
  public uploadedDocuments: any = [];

  public detailFocus: number = this.detailTypes.DESCRIPTION;
  public documentTrailFocus: number = this.documentTypes.IMGS;

  // Company Linking
  public linkedCompany: CompanyProfile = null;
  private referralLookupText: string = "";
  public filteredCompaniesOptions: Array<CompanyProfile> = [];
  public filteredCompanyProfiles: Array<CompanyProfile> = [];

  public referralStatus: any = referralStatusTypes;
  
  public referralStatusLookup: any = [
    {
      title: "Sent",
      status: this.referralStatus.SENT
    },
    {
      title: "Accepted",
      status: this.referralStatus.ACCEPTED
    },
    {
      title: "Expired",
      status: this.referralStatus.EXPIRED
    },
    {
      title: "Rejected",
      status: this.referralStatus.REJECTED
    }
  ]

  // Charts & Reporting
  private PL_profits: number = 0;
  private PL_profjected: number = 0;
  private PL_missed_earnings: number = 0;

  public PL_legend: boolean = false;
  public PL_labels: Array<string> = ['Profit', 'Projected', 'Loss'];
  public PL_data: Array< Array<any> > | Array<any> = [ 
    // [0, 0, 0]
    [
      (this.deal.price != undefined && this.deal.price != null ? this.deal.price : 0),
      150, 
      (this.deal.budget != undefined && this.deal.budget != null ? this.deal.budget.total : 0)
    ]
    // [250, 130, 70],
  ];
  
  public PL_margin: number | string = 21;

  public PL_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];

  constructor(
    private navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService, 
    public dialog: MatDialog, 
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public notificationService: NotificationService,
    private http: HttpClient) {
      
  }

  ngOnInit() {

    this.pocForm = this.formBuilder.group({
      first_name: [ {value: '', disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)]) ],
      last_name: [ {value: '', disabled: false}],

      poc_company_name: [ {value: '', disabled: false}],
      email: [ {value: '', disabled: false} ],
      phone: [ {value: '', disabled: false} ]
    });

    this.locationForm = this.formBuilder.group({
      street: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      city: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      state: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(2)])],
      zip: ['', Validators.compose([
        Validators.required, 
        Validators.minLength(5),
        Validators.pattern('[0-9]*') ])]
    });

    this.subscribeAddressToLatLng();
    this.getDeal(); // Getting the latest copy of the deal
  }

  ngOnDestroy() {

    if(this.openWorkOrderSubscriber) {
      this.openWorkOrderSubscriber.unsubscribe();
    }

    if(this.closedWorkOrderSubscriber) {
      this.closedWorkOrderSubscriber.unsubscribe();
    }

    if(this.addressToLatLngSubscription) {
      this.addressToLatLngSubscription.unsubscribe();
    }
    
  } 

  private initializeDealPanelVariables(): void {

    if(this.deal.documentedImgTrail == undefined || this.deal.documentedImgTrail == null) {
      this.deal.documentedImgTrail = [];
    }

    if(this.deal.documentedDocTrail == undefined || this.deal.documentedDocTrail == null) {
      this.deal.documentedDocTrail = [];
    }

    if(this.deal.attachedParts == undefined || this.deal.attachedParts == null) {
      this.deal.attachedParts = [];
    } else {
      this.initAttachedParts();
    }


    this.jobImages = this.deal.documentedImgTrail;
    this.documents = this.deal.documentedDocTrail;
    this.forms = this.deal.documentedFormTrail;

    if(this.forms == undefined || this.forms == null) {
      this.forms = [ ];
    }

    if(this.documents == undefined || this.documents == null) {
      this.documents = [ ];
    }

  }

  private initAttachedParts(): void {

    this.attachedParts = this.deal.attachedParts;

    // Dev Note: Need to loop through all linked work orders attached parts
    // and assign them here.

  }

  subscribeAddressToLatLng(): void {
    this.addressToLatLngSubscription = this.navigatorService.addressToLatLong(null).subscribe(data => { 

      if(data != null) {
        console.log("GEO Lat/Lng: ", data);

        this.incomingLatLng = data;
      } 
    });
  }

  getAddressToLatLng(address: Address): void {
    this.navigatorService.addressToLatLong(address);
  }


  // Used to update a deal to any database changes or updates
  private versionControlUpdate(): void {

    for(let poc of this.deal.poc) {

      if(poc.id == undefined || poc.id == null) {
        poc.id = this.navigatorService.generateKey();
      }

    }

  }

  private getDeal(): void {

    this.navigatorService.getAsyncCRMDeal(this.deal.id).then( (deal: CRMDeal) => {
      this.deal = deal;
      this.scope = this.deal.scope;

      this.activities = this.deal.activities;

      this.startDate.setDate(1);

      this.endDate = new Date(this.startDate);
      this.endDate.setMonth(this.startDate.getMonth() + 1);
      this.endDate.setDate(0);

      this.tableFilter = {
        searchFilter: "",
        dateFilter: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      };

      // Budgeting Chart Data
      this.PL_data = [ 
        
        [
          (this.deal.budget != undefined && this.deal.budget != null ? this.deal.budget.chargeTotal : 0),
          (this.deal.budget != undefined && this.deal.budget != null ? this.deal.price - (this.deal.budget.chargeTotal + this.deal.budget.total) : this.deal.price), 
          (this.deal.budget != undefined && this.deal.budget != null ? this.deal.budget.total : 0)
        ]

      ];

      if(this.PL_data[0][1] < 0) {
        this.PL_data[0][1] = 0
      }

      this.calculateProfitMargin();

      if(this.deal?.companyId != undefined && this.deal?.companyId != null) {
        this.getCompanyProfile(this.deal.companyId);
      }

      this.getPipes();
      this.getFilteredOpenWorkOrders( this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
      this.initializeTickerVariables();
      this.activityPlannerCheck();
      this.initializeDealPanelVariables();

      this.versionControlUpdate();
    });

  }

  private getCompanyProfile(id: string): void {
    let self = this;

    this.navigatorService.getAsyncCompanyProfile(id).then( (companyProfile: CompanyProfile) => {

      self.linkedCompany = companyProfile;

    });

  }

  private getPipes(): void {

    this.navigatorService.getAsyncAllCRMPipelinePipes(this.deal.pipeline).then( (pipes: Array<CRMPipe>) => {

      this.pipes = pipes;
      this.completedPipeIndex = this.pipes.findIndex( (pipe: CRMPipe) => { return pipe.id == this.deal.pipe;});

      this.initializePipeAuditTrail();

    });

  }

  public async filterEmployee(filterText: string): Promise<Array<Profile>> {
    let filteredEmployeeOptions: Array<Profile> = [];

    await this.navigatorService.getPagedSearchProfiles(filterText, 0, 10).then( (filteredProfiles: Array<Profile>) => {

      if(filteredProfiles != undefined && filteredProfiles != null) {

        for(let profile of filteredProfiles) {
          // let name: string = company.first_name + ' ' + company.last_name;
          filteredEmployeeOptions.push(profile);
        }

      }

    });

    return filteredEmployeeOptions;
  }

  getFilteredOpenWorkOrders(searchFilter: string, startDate: Date, endDate: Date): void {
    let dealId: string = this.deal.id;

    if(this.linkNewWorkOrders) {
      dealId = null;
    }

    this.navigatorService.getAsyncPagedDateRangeSearchOpenWorkOrders(startDate, endDate, searchFilter, 0, 100, dealId).then( (workOrders: Array<WorkOrder>) => {

      this.workOrders = [];

      console.log("CRM Attached WO: ", workOrders);

      if(workOrders != null) {
        for(let index = 0; index < workOrders.length; index++) {
          this.workOrders.push(workOrders[index]);
        }
      }

    });
  }

  getFilteredClosedWorkOrders(searchFilter: string, startDate: Date, endDate: Date): void {
    let dealId: string = this.deal.id;

    if(this.linkNewWorkOrders) {
      dealId = null;
    }

    this.navigatorService.getAsyncPagedDateRangeSearchClosedWorkOrders(startDate, endDate, searchFilter, 0, 100, dealId).then( (workOrders: Array<WorkOrder>) => {

      this.workOrders = [];

      if(workOrders != null) {
        for(let index = 0; index < workOrders.length; index++) {
          this.workOrders.push(workOrders[index]);
        }
      }

    });
  }

  private update_PL_Data(): void {
    this.PL_data = [ 
      [
        this.PL_profits, 
        this.PL_profjected, 
        this.PL_missed_earnings
      ] ];
  }

  private calculateProfitMargin(): void {
    console.log("Calculate P&L", this.PL_data);
    let profit: number = this.PL_data[0][0];
    let loss: number = this.PL_data[0][2];

    let pl: number = profit - loss;
    let margin: number = (profit > 0)? (pl / profit) * 100 : 0 ;

    this.PL_margin = Math.round( margin );

    // this.PL_margin = "Calc..."
  }

  private initializeTickerVariables(): void {

    for(let tickIndex: number = 0; tickIndex < this.swimLanes.length; tickIndex++) {

      this.progressTicker.push({
        passed: false
      });
      
    }

  }

  private initializePipeAuditTrail(): void {
    let pipeIndex: number = -1;
    let days: number = 0;
    let currentDate: Date = new Date();
    let ms: number = 86400000;

    for(let pipe of this.pipes) {
      days = 0;

      if(this.deal.pipeAuditTrail != undefined && this.deal.pipeAuditTrail != null) {
        pipeIndex = this.deal.pipeAuditTrail.findIndex( (audit: CRMDealPipeAuditLog) => {return audit.pipe == pipe.id;});

        // If the pipe has been found then we require the minimum recorded days to be 1
        if(pipeIndex > -1) {

          for(let auditLog of this.deal.pipeAuditTrail[pipeIndex].audit) {

            if(auditLog.out != undefined && auditLog.out != null) {

              days += Math.abs( auditLog.out.getTime() - auditLog.in.getTime() );

            } else {

              days += Math.abs( currentDate.getTime() - auditLog.in.getTime() );

            }

          }

          days = Math.floor( days / ms );

          if(days == 0) {
            days += 1;
          }

        } 

      }

      this.pipeAudit.push(days);

    }

    console.log("Pipe Audit: ", this.pipeAudit);
  }

  private updateDeal(): void {
    this.deal.last_update = new Date();

    this.navigatorService.upsertCRMDeal(this.deal).then( (status: boolean) => {
      this.activityPlannerCheck();
    });

  }

  public assignedStaffUpdate(staff: Array<string>): void {
    this.deal.assignedStaff = staff;
    this.updateDeal();
  }

  public assignedTeamsUpdate(teams: Array<string>): void {
    this.deal.assignedTeams = teams;
    this.updateDeal();
  }

  public expandSidebarPanel(type: number, data?: any): void {

    switch(type) {

      case this.sidebarTypes.CONTACT:
        this.sidebarType = type;
        this.initializePOCEditable(data);
        break;

      case this.sidebarTypes.SALES_LINK:
        this.sidebarType = type;
        break;

    }

    this.cancelNewAddress();
    this.sidebarPanelExpanded = true;
  }

  public collapseSidebarPanel(): void {
    this.sidebarPanelExpanded = false;
    this.sidebarType = null;
  }

  private initializePOCEditable(poc: CRMPOC): void {
    this.assignedPOC = poc;

    if(this.assignedPOC != undefined && this.assignedPOC != null) {

      this.pocForm.get("first_name").setValue(this.assignedPOC.first_name);
      this.pocForm.get("last_name").setValue(this.assignedPOC.last_name);
      this.pocForm.get("poc_company_name").setValue(this.assignedPOC.company_name);
  
      this.pocForm.get("email").setValue(this.assignedPOC.email);
      this.pocForm.get("phone").setValue(this.assignedPOC.phone);

    }
  }

  public assignNewContact(): void {

    let contact: CRMPOC = {
      id: this.navigatorService.generateKey(),
      first_name: this.pocForm.get("first_name").value,
      last_name: this.pocForm.get("last_name").value,
      company_name: this.pocForm.get("poc_company_name").value,

      email: this.pocForm.get("email").value,
      phone: this.pocForm.get("phone").value,
      address: this.deal.address,
      tags: []
    }

    this.pocForm.get("first_name").setValue("");
    this.pocForm.get("last_name").setValue("");
    this.pocForm.get("poc_company_name").setValue("");

    this.pocForm.get("email").setValue("");
    this.pocForm.get("phone").setValue("");


    console.log("Assigned Contact: ", contact);

    if(this.deal.poc == undefined || this.deal.poc == null) {
      this.deal.poc = [];
    }

    if(this.assignedPOC != undefined && this.assignedPOC != null) {

      contact.id = this.assignedPOC.id;

      let assignedPOCEditIndex: number = this.deal.poc.findIndex( (poc: CRMPOC) => { return poc.id == contact.id });

      if(assignedPOCEditIndex > -1) {

        this.deal.poc[assignedPOCEditIndex] = contact;

      }

    } else {
      this.deal.poc.push(contact);
    }

    this.collapseSidebarPanel();
    this.assignedPOC = null;
    this.updateDeal();
  }

  assignCompanyLink(): void {

    if(this.linkedCompany != undefined && this.linkedCompany != null) {

      this.deal.companyId = this.linkedCompany.id;

      this.sendReferralRequestLink();
      this.collapseSidebarPanel();
      this.updateDeal();
      this.linkedCompany = null;

    }

  }

  deleteCompanyLink(): void {

    this.deal.companyId = null;

    this.collapseSidebarPanel();
    this.linkedCompany = null;
    this.updateDeal();

  }

  public deleteContact(id: string): void {

    let assignedPOCEditIndex: number = this.deal.poc.findIndex( (poc: CRMPOC) => { return poc.id == id });

    if(assignedPOCEditIndex > -1) {

      this.deal.poc.splice(assignedPOCEditIndex, 1);
      
      this.collapseSidebarPanel();
      this.assignedPOC = null;
      this.updateDeal();

    }

  }

  public cancelCompanyLink(): void {
    this.linkedCompany = null;

    this.collapseSidebarPanel();
  }

  public cancelNewContact(): void {
    this.pocForm.get("first_name").setValue("");
    this.pocForm.get("last_name").setValue("");
    this.pocForm.get("poc_company_name").setValue("");

    this.pocForm.get("email").setValue("");
    this.pocForm.get("phone").setValue("");

    this.assignedPOC = null;

    this.collapseSidebarPanel();
  }

  public closeNewActivityPanel(): void {
    this.activityLoggingFocus = this.activityLogCategories.BLANK;
    this.resetActivityLog();
  }

  public resetActivityLog(): void {
    this.uploadedDocuments = [];
  }

  public editorChanged(event: any, type: number = this.noteTypes.NOTE) {

    if(event.event == 'selection-change') {

    } else {

      switch(type) {

        case this.noteTypes.NOTE:
          this.activeNoteContent = event.html;
          this.activeNoteText = event.text.replace('\\n', ' ');
          break;

        case this.noteTypes.SCOPE:
          this.scope = event.html;
          break;

        case this.noteTypes.DESCRIPTION:
          // this.activeNoteContent = event.html;
          break;
      }

      console.log("Active Note Text: ", this.activeNoteText);
    }

  }

  public saveScopeChange(): void {
    this.deal.scope = this.scope;
    this.scopeReadMode();
    this.updateDeal();
  }

  public cancelScopeChange(): void {
    let self = this;

    this.scope = this.deal.scope;
    this.scopeRefresh = true;
    this.scopeReadMode();

    // setTimeout(function() {
    //   self.scopeRefresh = false;
    // }, 25);
  }

  public onLocationFormSubmit(locationForm: Address): void {
    if(this.locationForm.valid) {
      console.log("Location Form: ", locationForm);

      this.incomingAddress = locationForm;

      this.getAddressToLatLng(this.incomingAddress);
    } else {
      this.isInServiceArea = null;
      this.incomingLatLng = null;
    }
  }

  public serviceAreaCheck(event): void {
    console.log("Incoming Service Check: ", event);
    this.isInServiceArea = event;
  }

  public expandAddressPanel(): void {
    this.addressPanelExpanded = true;
  }

  public collapseAddressPanel(): void {
    this.addressPanelExpanded = false;
  }

  public readAllMode(): void {
    this.titleReadMode();
    this.priceReadMode();
    this.scopeReadMode();
  }

  public titleEditable(): void {
    this.editTitleMode = true;
  }

  public titleReadMode(): void {
    this.editTitleMode = false;
  }

  public priceEditable(): void {
    this.editPriceMode = true;
  }

  public priceReadMode(): void {
    this.editPriceMode = false;
  }

  public scopeEditable(): void {
    this.editScopeMode = true;
  }

  public scopeReadMode(): void {
    this.editScopeMode = false;
  }

  public 

  public updateDealTitle(title: string): void {

    if(title.length > 0) {
      this.deal.title = title;
      this.updateDeal();
    }

  }

  public updateDealPrice(price: string): void {

    if(price.length > 0) {

      let structuredPrice: string = price.replace(/,/g, '');
      let parsedPrice: number = Number.parseInt(structuredPrice);

      this.deal.price = parsedPrice;
      this.updateDeal();
    }

  }

  public nudgeActivityStaff(activity: CRMActivity): void {
    this.snackBar.open('Assigned Staff Have Been Notified To Check In!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
  }

  public assignNewAddress(): void {

    if(this.locationForm.valid) {
      let address: Address = {
        street: this.locationForm.get("street").value,
        city: this.locationForm.get("city").value,
        state: this.locationForm.get("state").value,
        zip: this.locationForm.get("zip").value
      }

      this.deal.address = address;
      this.deal.serviceable = this.isInServiceArea;
      this.deal.latLng = this.incomingLatLng;

      this.updateDeal();

      this.collapseAddressPanel();
    } else {
      this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public cancelNewAddress(): void {
    this.locationForm.reset();
    this.collapseAddressPanel();
  }

  private activityPlannerCheck(): void {
    this.completedActivities = [];
    this.plannedActivities = [];

    let currentDate: Date = new Date();

    for(let activity of this.activities) {

      if(
        (activity.scheduleDate != undefined && activity.scheduleDate != null) && 
        (typeof activity.scheduleDate.getMonth === 'function') 
      ) {

        if( (activity.scheduleDate.getTime() > currentDate.getTime() || !activity.completed) && activity.type == this.activityLogCategories.ACTIVITY) {
          this.plannedActivities.push(activity);
        } else {
          this.completedActivities.push(activity);
        }
      } else {
        this.completedActivities.push(activity);
      }

    }

  }

  private sendPlatformUserNotifications(ids: Array<string>): void {

    let notification: ApplicationNotificationMessage = {
      id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
      message: 'You\'ve been mentioned in the following project: ' + this.deal.title,
      category: this.notificationCategories.PROJECTS,
      status: this.notificationStatus.INFORMATIONAL
    }

    for(let id of ids) {
      this.notificationService.addForeignNotification(id, notification);
    }

  }

  public publishLog(type: number): void {

    this.activities.unshift({
      id: this.navigatorService.generateKey(),
      type: type,
      data: {
        content: this.activeNoteContent
      },
      scheduleDate: new Date(),
      lastUpdated: new Date()
    });

    this.deal.last_update = new Date();
    this.deal.activities = this.activities;

    if(type == this.activityLogCategories.NOTES) {
      let mentions: any = document.querySelectorAll("#createNoteActivityContainer .mention");
      let notifyIds: Array<string> = [];

      console.log("Mentions: ", mentions);

      if(mentions.length > 0) {

        if(this.deal.teamMessages == undefined || this.deal.teamMessages == null) {
          this.deal.teamMessages = [];
        }

        this.deal.teamMessages.push({
          id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
          senderId: this.navigatorService.getProfileId(),
          message: this.activeNoteText,
          date: new Date()
        });

        for(let mention of mentions) {
          notifyIds.push(mention.attributes["data-id"].nodeValue);
        }

        this.sendPlatformUserNotifications(notifyIds);

      }

    }

    this.updateDeal();

    this.activeNoteContent = "";
    this.activeNoteText = "";
    this.activityLoggingFocus = this.activityLogCategories.BLANK

    this.activityPlannerCheck();

    console.log("Publish: ", this.activities);
  }

  public publishExtLog(activity: CRMActivity): void {
    let activityIndex: number = this.activities.findIndex( (element: CRMActivity) => { return element.id == activity.id });

    if(activityIndex > -1) {
      this.activities[activityIndex] = activity;
    } else {
      this.activities.unshift(activity);
    }

    this.deal.last_update = new Date();
    this.deal.activities = this.activities;

    this.updateDeal();
    
    this.activityLoggingFocus = this.activityLogCategories.BLANK

    this.activityPlannerCheck();

    console.log("Publish: ", this.activities);
  }

  public toggleActivityCompleted(activityId: string): void {
    let activityIndex: number = this.deal.activities.findIndex( (element: CRMActivity) => { return element.id == activityId });

    if(activityIndex > -1) {

      if(this.deal.activities[activityIndex].completed) {
        this.deal.activities[activityIndex].completed = false;
      } else {
        this.deal.activities[activityIndex].completed = true;
      }

      this.updateDeal();

    }

  }

  public changeActivityLoggingFocus(logFocus: number): void {
    this.activityLoggingFocus = logFocus;
  }

  public changeActivityLoggingFilterFocus(logFocus: number): void {
    this.activityLoggingFilterFocus = logFocus;
  }

  public changeRevenueView(view: number): void {
    this.revenueViewFocus = view;
  }

  public updateWorkOrders(): void {
    if(this.openOrClosedFilter == 0) {
      this.getFilteredOpenWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    } else {
      this.getFilteredClosedWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    }
  }

  public filterWorkOrders(filter: WorkOrderFilter): void {
    this.tableFilter = filter;

    if(this.openOrClosedFilter == 0) {
      this.getFilteredOpenWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    } else {
      this.getFilteredClosedWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    }
  }

  public openOrClosedWorkOrderSwitch(openOrClosedWorkOrders: number): void {
    this.openOrClosedFilter = openOrClosedWorkOrders;

    if(this.openOrClosedFilter == 0) {
      this.getFilteredOpenWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    } else {
      this.getFilteredClosedWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    }
  }

  public linkWorkOrderMode(mode: boolean): void {
    this.linkNewWorkOrders = mode;

    if(this.openOrClosedFilter == 0) {
      this.getFilteredOpenWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    } else {
      this.getFilteredClosedWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    }
    
  }

  // Needs adjustments to put objects in correct array
  public getDocument(imgIndex: number): void {
    if(this.jobAuditFocus[imgIndex].imgs[imgIndex] != undefined) {
      this.imageHandler.getFile(this.jobAuditFocus[imgIndex].imgs[imgIndex].id);
    }
  }

  // Needs adjustments to put objects in correct array
  public getAllDocuments(): void {
    let imgIds: Array<string> = [];

    for(let img of this.jobImages[this.jobAuditFocus].imgs) {
      imgIds.push(img.id);
    }

    this.imageHandler.getAsyncFiles(imgIds).then( (imgs: Array<any>) => {
      // this.gallery[]

      if(imgs) {
        let index: number = 0;

        for(let img of this.jobImages[this.jobAuditFocus].imgs) {
          img.file = imgs[index];
          index++;
        }

      }

      console.log("Focused Job: ", this.jobImages[this.jobAuditFocus]);

    });
  }

  public getImg(imgIndex: number): void {
    if(this.jobAuditFocus[imgIndex].imgs[imgIndex] != undefined) {
      this.imageHandler.getFile(this.jobAuditFocus[imgIndex].imgs[imgIndex].id);
    }
  }

  public getAllImgs(): void {
    let imgIds: Array<string> = [];
    let folder: string = "";

    for(let img of this.jobImages[this.jobAuditFocus].imgs) {
      imgIds.push(img.id);
    }

    folder = this.navigatorService.getCompanyId();

    this.imageHandler.getAsyncFiles(imgIds, folder).then( (imgs: Array<any>) => {
      // this.gallery[]

      if(imgs) {
        let index: number = 0;

        for(let img of this.jobImages[this.jobAuditFocus].imgs) {
          img.file = imgs[index];
          index++;
        }

      }

      console.log("Focused Job: ", this.jobImages[this.jobAuditFocus]);

    });
  }

  public uploadImages(images: Array<any>): Array<FileObject> {

    let imgIds: Array<FileObject> = [];

    if(images != null && images != undefined && images.length > 0) {

      for(let imageIndex: number = 0; imageIndex < images.length; imageIndex++) {
        let file: File = images[imageIndex].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        imgIds.push( { 
          id: imgIdUnique,
          title: file.name
        } );

        if(imageIndex == images.length) {
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
            // Last Image Uploaded
          });
        } else {
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

          });
        }

      }
    }

    return imgIds;
  }

  // public deleteImg(imgIndex: number): void {
  //   this.images.splice(imgIndex, 1);
  //   this.imgIds.splice(imgIndex, 1);
  //   this.imageDeleteAction = true;
  // }
  
  public uploadJobImages(desc: string): void {
    console.log("Img desc: ", desc);
    console.log("Img Upload: ", this.uploadedJobImages);

    let imgIds: Array<FileObject> = this.uploadImages(this.uploadedJobImages);

    this.jobImages.unshift({
      id: this.navigatorService.generateKey(),
      imgs: imgIds,
      desc: desc,
      created_date: new Date(),
      created_by: this.navigatorService.getProfileId()
    });

    this.deal.documentedImgTrail = [...this.jobImages];

    this.uploadedJobImages = [];

    this.updateDeal();
  }
  
  public uploadDocuments(desc: string): void {
    console.log("Document desc: ", desc);
    console.log("Document Upload: ", this.uploadedDocuments);

    let docIds: Array<FileObject> = this.uploadImages(this.uploadedDocuments);

    this.documents.unshift({
      id: this.navigatorService.generateKey(),
      title: '',
      documents: docIds,
      desc: desc,
      created_date: new Date(),
      created_by: this.navigatorService.getProfileId()
    });

    this.deal.documentedDocTrail = [...this.documents];

    this.uploadedDocuments = [];

    this.updateDeal();
  }

  public createDocument(id: string): void {
    console.log("Form Template: ", id);
  }

  public dealWon(): void {

    if(this.deal.lost != undefined && this.deal.lost != null) {
      this.deal.lost = null;
    }

    switch(this.boardType) {

      case this.boardTypes.PROJECTS:
        this.deal.won = new Date();
        console.log("Projects Winning");
        break;

      case this.boardTypes.SALES:
        this.deal.won = new Date();
        console.log("Sales Winning");
        break;

      case this.boardTypes.INTERNAL_TOOLS_SALES:
        this.deal.won = new Date();
        console.log("Internal Sales Winning");
        break;

      default:
        this.deal.won = new Date();
        console.log("Default Winning");
        break;

    }

    this.updateDeal();

  }

  public dealLost(): void {

    if(this.deal.won != undefined && this.deal.won != null) {
      this.deal.won = null;
    }

    switch(this.boardType) {

      case this.boardTypes.PROJECTS:
        this.deal.lost = new Date();
        console.log("Projects Losing");
        break;

      case this.boardTypes.SALES:
        this.deal.lost = new Date();
        console.log("Sales Losing");
        break;

      case this.boardTypes.INTERNAL_TOOLS_SALES:
        this.deal.lost = new Date();
        console.log("Internal Sales Losing");
        break;

      default:
        this.deal.lost = new Date();
        console.log("Default Losing");
        break;

    }

    this.updateDeal();

  }

  public attachedPartsChange(parts: Array<PartAttachBundle>): void {
    this.deal.attachedParts = parts;

    this.updateDeal();
  }

  // ===============================
  //        Company Linking
  // ===============================

  public sendReferralRequestLink(): void {

    if(this.linkedCompany != undefined && this.linkedCompany != null) {

      let profileId: string = this.navigatorService.getProfileId();

      this.http.post('/platform/api/directReferralLinkConnect', { referralDetails: { companyId: this.linkedCompany.id, requestorId: profileId } } ).subscribe( (referralLink: ReferralLink) => {
        console.log("Referral Confirmation Server Return", referralLink );

        if(referralLink != null && referralLink != undefined) {

          let profile: Profile = this.navigatorService.getCurrentProfile();

          if(profile.referral_links == undefined) {
            profile.referral_links = [];
          }

          referralLink.status = referralStatusTypes.ACCEPTED;

          profile.referral_links.push(referralLink);

          this.navigatorService.updateOneProfile(profile).then( (success: boolean) => {

            if(success) {
              this.snackBar.open('Referral Confirmation Requested Successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

              this.referralSent.emit();
            }

          });
          
        }

      });

    }

  }

  public async referralTitleLookup(companyId: string): Promise<string> {
    let title: string = "Loading";
    let profile: Profile = this.navigatorService.getCurrentProfile();

    let referral: ReferralLink = profile.referral_links.find( (referral: ReferralLink) => { return referral.company_id == companyId });

    if(referral != undefined && referral != null) {

      await this.navigatorService.getCompanyProfile(referral.company_id).then( (companyProfile: CompanyProfile) => {

        if(companyProfile != undefined && companyProfile != null) {
          title = companyProfile.company_name;
        }

      });

      
    }

    return title;
  }

  public referralStatusTitleLookup(status: number): string {
    let title: string = "";

    let referralStatus: any = this.referralStatusLookup.find( referralStatus => { return referralStatus.status == status });

    if(referralStatus != undefined && referralStatus != null) {
      title = referralStatus.title;
    }

    return title;
  }

  public updateFilterText(companyProfile: CompanyProfile): void {
    this.linkedCompany = companyProfile;
    this.referralLookupText = companyProfile.company_name;
  }

  public filterCompany(filterText: string): void {
    this.referralLookupText = filterText;
    this.filteredCompaniesOptions = [];

    this.navigatorService.getPagedDateRangeSearchCompanies(filterText, 0, 10).then( (filteredCompanies: Array<CompanyProfile>) => {
      this.filteredCompanyProfiles = [];

      if(filteredCompanies != undefined && filteredCompanies != null) {
        
        this.filteredCompanyProfiles = filteredCompanies;

        for(let company of filteredCompanies) {
          this.filteredCompaniesOptions.push(company);
        }

      }

    });

  }


  private attachForm(documentedForm: DocumentedForm): void {

    this.deal.documentedFormTrail.unshift(documentedForm);
    console.log("Assigned Documents: ", this.documents);

    this.updateDeal();

  }


  public openCreateNewDocumentDialog() {
    this.createDocumentDialogRef = this.dialog.open(
      VendorFormCreateDocumentDialogComponent,
      { 
        data: {
          deal: this.deal.id 
        }
       }
    );

    this.createDocumentDialogRef.beforeClosed().subscribe(data => {

    })
    
    this.activityDialogRef.afterClosed().subscribe(data => {
      console.log("Dialog Closed: ", data);
      this.publishExtLog(data);
    });

  }

  public openWorkOrderAuditDialog(activity: CRMActivity) {
    this.activityDialogRef = this.dialog.open(
      VendorCRMActivityDialogComponent,
      { 
        data: {
          activity: activity,
          deal: null 
        }
       }
    );

    this.activityDialogRef.beforeClosed().subscribe(data => {

    })
    
    this.activityDialogRef.afterClosed().subscribe(data => {
      console.log("Dialog Closed: ", data);
      this.publishExtLog(data);
    });

  }

  public openProjectBudgetDialog() {
    
    if(this.deal == undefined || this.deal == null) {
      this.snackBar.open('There was an error retrieving budget information, please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.projectBudgetDialogRef = this.dialog.open(
      // VendorWorkOrderDialogComponent,
      VendorProjectBudgetDialogComponent,
      { 
        data: {
          project: this.deal.id
        }
      
      }
    );

    this.projectBudgetDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    })
    
    this.projectBudgetDialogRef.afterClosed().subscribe((budget: Budget) => {
      console.log("Dialog Closed: ", budget);

      this.getDeal();

      // this.navigatorService.updateWorkOrder(data);
      // this.files.push({ name, content: ''});
    })
    
  }


  public openDocument(pageChangeEvent: PageChangeEvent): void {

    console.log("Open Doc: ", pageChangeEvent);

    this.activeTemplate = pageChangeEvent.value.template
    this.activeDoc =  pageChangeEvent.value.document;

    this.openFormBuilderDialog();
  }

  public openFormBuilderDialog(): void {
    let self = this;

    this.formDialogRef = this.dialog.open(
      VendorFormBuilderDialogComponent,
      { 
        data: {
          dealMode: true,
          activeTemplate: this.activeTemplate,
          activeDoc: this.activeDoc
        }
      
      }
    );

    this.formDialogRef.beforeClosed().subscribe( (document: DocumentedForm) => {
      console.log("Before Closed: ", document);

      if(document != undefined && document != null) {
        self.attachForm(document);
      }

    })
    
    this.formDialogRef.afterClosed().subscribe(document => {
      console.log("Dialog Closed: ", document);

    })
  
  }

}