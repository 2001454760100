import {
  Component,
  Directive, 
  Input, 
  OnChanges, 
  OnInit,
  AfterViewInit,
  ElementRef, 
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ViewRef,
  HostBinding,
  Output,
  ViewEncapsulation
} from '@angular/core';



@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TextEditorComponent implements OnInit, AfterViewInit {

  constructor() { 
    
 }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {
    
  }

  initializeListMenu(): void {

  }
}
