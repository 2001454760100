import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

import { keyTypesEnum, NavigatorService } from './navigator.service';
import { CompanyProfile, CompanyTimeChart, FormattedWorkOrderAuditLog, LatLng, Timesheet, TimeTracker, WorkOrderAuditLog } from 'src/app/data-models/models';
import { DatePipe } from '@angular/common';


@Injectable({
    providedIn: 'root'
})

export class CompanyTimesheetsService {

    private keytypes: any = keyTypesEnum;
    datePipe = new DatePipe('en-US');


    constructor(
        private http:HttpClient, 
        private realm:MongoService, 
        public router:Router, 
        private navigatorService: NavigatorService, 
        private sanitizer:DomSanitizer) { 

    }

    async initialize(): Promise<void> {

        
    }

    private getCloudflareIPInfo(): Promise<any> {
        return this.http.get('https://www.cloudflare.com/cdn-cgi/trace', {responseType: 'text'}).toPromise();
    }

    public async getIP(): Promise<void> {
        let IP_data = null;

        await this.getCloudflareIPInfo().then( (data: any) => {
  
          if(data != undefined && data != null) {
            // Convert key-value pairs to JSON
            // https://stackoverflow.com/a/39284735/452587
            data = data.trim().split('\n').reduce(function(obj, pair) {
              pair = pair.split('=');
              return obj[pair[0]] = pair[1], obj;
            }, {});
  
            IP_data = data;
            console.log("IP After Data (Timesheet): ", data);
          }
  
        });

        return IP_data;
    }

    public createDailyCompanyTimeChart(): void {

        this.navigatorService.getAsyncDailyCompanyTimeChart().then( (timeChart: CompanyTimeChart) => {
            
            if(timeChart == undefined || timeChart == null) {
                let newTimeChart: CompanyTimeChart = {
                    chart_id: this.navigatorService.generateKey(this.keytypes.TIME_CHART),
                    company_id: this.navigatorService.getCompanyId(),
                    date: new Date(),
                    employee_time_sheets: []
                };
                
                this.navigatorService.upsertDailyCompanyTimeChart(newTimeChart).then( (status: boolean) => {

                });
            }

        });

    }

    public async getDailyTimesheet(employeeId: string): Promise<Timesheet>{
        let timesheet: Timesheet = {
            sheet_id: '',
            employee_id: '',
            company_id: '',
            hourly_payrate: 0,
            day_clock_in: [],
            day_clock_out: [],
            break_clock_in: [],
            break_clock_out: []
        };

        await this.navigatorService.getAsyncDailyCompanyTimeChart().then( (timeChart: CompanyTimeChart) => {
                    
            if(timeChart != undefined && timeChart != null) {

                let employeeTimesheetIndex: number = timeChart.employee_time_sheets.findIndex( (timesheet: Timesheet) => { return timesheet.employee_id == employeeId });

                if(employeeTimesheetIndex > -1) {   
                    timesheet = timeChart.employee_time_sheets[employeeTimesheetIndex];
                }

            }

        });

        return timesheet;
    }

    public dayClockIn(employeeId: string): void {

        this.navigatorService.getGeoLocation().then( (latLng: LatLng) => {

            this.getIP().then( (ip_data: any) => {
                let ip_address: string = "";
                
                if(ip_data != undefined && ip_data.ip != undefined) {
                    ip_address = ip_data.ip;
                }

                this.navigatorService.getAsyncDailyCompanyTimeChart().then( (timeChart: CompanyTimeChart) => {
                    
                    if(timeChart != undefined && timeChart != null) {

                        let employeeTimesheetIndex: number = timeChart.employee_time_sheets.findIndex( (timesheet: Timesheet) => { return timesheet.employee_id == employeeId });
                        
                        let timeTracker: TimeTracker = {
                            date: new Date(),
                            geo_location: latLng,
                            ip_address: ip_address
                        }

                        if(employeeTimesheetIndex <= -1) {   

                            let timesheet: Timesheet = {
                                sheet_id: this.navigatorService.generateKey(this.keytypes.TIME_CHART),
                                employee_id: employeeId,
                                company_id: this.navigatorService.getCompanyId(),
                                hourly_payrate: 0,
                                day_clock_in: [],
                                day_clock_out: [],
                                break_clock_in: [],
                                break_clock_out: []
                            }

                            timesheet.day_clock_in.push(timeTracker);
                            timeChart.employee_time_sheets.push(timesheet);

                        } else {
                            timeChart.employee_time_sheets[employeeTimesheetIndex].day_clock_in.push(timeTracker);
                        }

                        this.navigatorService.upsertDailyCompanyTimeChart(timeChart).then( (status: boolean) => {

                        });
                    }

                });

            });
            
        });

    }

    public breakClockIn(employeeId: string): void {

        this.navigatorService.getGeoLocation().then( (latLng: LatLng) => {

            this.getIP().then( (ip_data: any) => {
                let ip_address = "";
                
                if(ip_data != undefined && ip_data.ip != undefined) {
                    ip_address = ip_data.ip;
                }

                this.navigatorService.getAsyncDailyCompanyTimeChart().then( (timeChart: CompanyTimeChart) => {
                    
                    if(timeChart != undefined && timeChart != null) {

                        let employeeTimesheetIndex: number = timeChart.employee_time_sheets.findIndex( (timesheet: Timesheet) => { return timesheet.employee_id == employeeId });
                        
                        let timeTracker: TimeTracker = {
                            date: new Date(),
                            geo_location: latLng,
                            ip_address: ip_address
                        }

                        if(employeeTimesheetIndex <= -1) { 

                            let timesheet: Timesheet = {
                                sheet_id: this.navigatorService.generateKey(this.keytypes.TIME_CHART),
                                employee_id: employeeId,
                                company_id: this.navigatorService.getCompanyId(),
                                hourly_payrate: 0,
                                day_clock_in: [],
                                day_clock_out: [],
                                break_clock_in: [],
                                break_clock_out: []
                            }

                            timesheet.break_clock_in.push(timeTracker);
                            timeChart.employee_time_sheets.push(timesheet);

                        } else {
                            timeChart.employee_time_sheets[employeeTimesheetIndex].break_clock_in.push(timeTracker);
                        }

                        this.navigatorService.upsertDailyCompanyTimeChart(timeChart).then( (status: boolean) => {

                        });
                    }

                });

            });
            
        });

    }

    public dayClockOut(employeeId: string): void {

        this.navigatorService.getGeoLocation().then( (latLng: LatLng) => {

            this.getIP().then( (ip_data: any) => {
                let ip_address = "";
                
                if(ip_data != undefined && ip_data.ip != undefined) {
                    ip_address = ip_data.ip;
                }

                this.navigatorService.getAsyncDailyCompanyTimeChart().then( (timeChart: CompanyTimeChart) => {
                    
                    if(timeChart != undefined && timeChart != null) {

                        let employeeTimesheetIndex: number = timeChart.employee_time_sheets.findIndex( (timesheet: Timesheet) => { return timesheet.employee_id == employeeId });
                        
                        let timeTracker: TimeTracker = {
                            date: new Date(),
                            geo_location: latLng,
                            ip_address: ip_address
                        }

                        if(employeeTimesheetIndex <= -1) { 

                            let timesheet: Timesheet = {
                                sheet_id: this.navigatorService.generateKey(this.keytypes.TIME_CHART),
                                employee_id: employeeId,
                                company_id: this.navigatorService.getCompanyId(),
                                hourly_payrate: 0,
                                day_clock_in: [],
                                day_clock_out: [],
                                break_clock_in: [],
                                break_clock_out: []
                            }

                            timesheet.day_clock_out.push(timeTracker);
                            timeChart.employee_time_sheets.push(timesheet);

                        } else {
                            timeChart.employee_time_sheets[employeeTimesheetIndex].day_clock_out.push(timeTracker);
                        }

                        this.navigatorService.upsertDailyCompanyTimeChart(timeChart).then( (status: boolean) => {

                        });
                    }

                });

            });
            
        });

    }

    public breakClockOut(employeeId: string): void {

        this.navigatorService.getGeoLocation().then( (latLng: LatLng) => {

            this.getIP().then( (ip_data: any) => {
                let ip_address = "";
                
                if(ip_data != undefined && ip_data.ip != undefined) {
                    ip_address = ip_data.ip;
                }

                this.navigatorService.getAsyncDailyCompanyTimeChart().then( (timeChart: CompanyTimeChart) => {
                    
                    if(timeChart != undefined && timeChart != null) {

                        let employeeTimesheetIndex: number = timeChart.employee_time_sheets.findIndex( (timesheet: Timesheet) => { return timesheet.employee_id == employeeId });
                        
                        let timeTracker: TimeTracker = {
                            date: new Date(),
                            geo_location: latLng,
                            ip_address: ip_address
                        }

                        if(employeeTimesheetIndex <= -1) { 

                            let timesheet: Timesheet = {
                                sheet_id: this.navigatorService.generateKey(this.keytypes.TIME_CHART),
                                employee_id: employeeId,
                                company_id: this.navigatorService.getCompanyId(),
                                hourly_payrate: 0,
                                day_clock_in: [],
                                day_clock_out: [],
                                break_clock_in: [],
                                break_clock_out: []
                            }

                            timesheet.break_clock_out.push(timeTracker);
                            timeChart.employee_time_sheets.push(timesheet);

                        } else {
                            timeChart.employee_time_sheets[employeeTimesheetIndex].break_clock_out.push(timeTracker);
                        }

                        this.navigatorService.upsertDailyCompanyTimeChart(timeChart).then( (status: boolean) => {

                        });
                    }

                });

            });
            
        });

    }

}