import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';

import { NavigatorService } from '../vendor/navigator.service'

// Base 
import { CompanyProfile, Profile, Address, Query, ScheduleSwap, AssetWorkOrder, FacilityWorkOrder, WorkOrderCustomField, GroupPermissions, InventoryWorkOrder, CMMSLocationType, CMMSLocation, InvoiceSettings, PaymentSettings, Page, NotificationCategory, CMMSPartRequest, CMMSVendor, PageSection, InstantMessage, SessionDataSync, UserDataSync, GeneralDataSync } from '../../data-models/models';

// Shift Scheduling
import { EmployeeSchedule, Shift, Group } from '../../data-models/models';

// Inventory
import { InventoryTrackedFields, InventoryItem } from '../../data-models/models';

// Inventory
import { AssetTrackedFields, AssetItem } from '../../data-models/models';

// Work Order Builder
import { WorkOrder, WorkOrderTemplate, WorkOrderRow } from '../../data-models/models';

// Geo
import { LatLng } from '../../data-models/models';



@Injectable({
  providedIn: 'root'
})

export class HeartBeatService {

  public url = "assets/data/vendor/";
  public apiKey = 'AIzaSyA1rF9bttCxRmsNdZYjW7FzIoyrul5jb-s';
  private geoApiKey = "AIzaSyD50gI2gregFXPOn_uVHBQRT97_MS1s0Vg";
  private weatherApiKey = "8641f6e1f17359651c06de0eec5e63fa";

  // private newTimeShiftSubject = new Subject<TimeShiftTemplateSubject>();
  // private timeKeeperSubject = new Subject<TimeKeeper[]>();
  
  private sessionDataSyncSubject = new Subject<Array<SessionDataSync>>();
  private generalDataSyncSubject = new Subject<GeneralDataSync>();
  private userDataSyncSubject = new Subject<UserDataSync>();
  
  private instantMessagingSubject = new Subject<Array<InstantMessage>>();
  private filteredPagedInstantMessagesSubject = new Subject<Array<InstantMessage>>();


  constructor(public http:HttpClient, public realm:MongoService, public router:Router, private navigationService: NavigatorService) { 

  }

// ===========================================
// Company Session Sync
// ===========================================

public getCompanySessionSync(): Observable<Array<SessionDataSync>> {
  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.session_timeout_date": { $gte: new Date() }
  };

  this.realm.mongoGet(query, "swarm-platform-sync", "session", "admin", this.navigationService.getCompanyId(), false).then(data => {
    console.log("Session Heartbeat", data);

    if(data != undefined) {
      let sessionHeartbeatData: Array<SessionDataSync> = [];

      if(data != null) {

        for(let session of data) {
          sessionHeartbeatData.push(session.data);
        }

      }

      this.sessionDataSyncSubject.next(sessionHeartbeatData);
      
    }
  });

  return this.sessionDataSyncSubject.asObservable();
}

public async getAsyncCompanySessionSync(): Promise<Array<SessionDataSync>> {
  let sessionHeartbeatData: Array<SessionDataSync> = [];

  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.session_timeout_date": { $gte: new Date() }
  };

  await this.realm.mongoGet(query, "swarm-platform-sync", "session", "admin", this.navigationService.getCompanyId(), false).then( data => {
    console.log("Session Heartbeat", data);

    if(data != null) {

        for(let session of data) {
          sessionHeartbeatData = session.data;
        }
        
    }
  });

  return sessionHeartbeatData;
}

public async updateCompanySessionSync(sessionDataSync: SessionDataSync): Promise<void> {
  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.id": sessionDataSync.id
  };

  await this.realm.mongoUpsertOne(query, "swarm-platform-sync", "session", sessionDataSync, "admin", this.navigationService.getCompanyId(), false).then( data => {
    
  });
}

public async updateAuthenticatedUserSessionSync(): Promise<void> {
  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.id": this.navigationService.getProfileId()
  };

  let currentDate: Date = new Date();
  let sessionAliveInterval: number = 30; // Minutes the session is extended

  let sessionDataSync: SessionDataSync = {
    id: this.navigationService.getProfileId(),
    session_timeout_date: new Date(currentDate.getTime() + (sessionAliveInterval * 60000) )
  };

  await this.realm.mongoUpsertOne(query, "swarm-platform-sync", "session", sessionDataSync, "admin", this.navigationService.getCompanyId(), false).then( data => {
    
  });

}

// ===========================================
// User Data Sync
// ===========================================

public getUserDataSyncTrigger(): Observable<UserDataSync> {
  return this.userDataSyncSubject.asObservable();
}

public getUserDataSync(): Observable<UserDataSync> {
  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.userId": this.navigationService.getProfileId()
  };

  this.realm.mongoGetOne(query, "swarm-platform-sync", "user", "admin", this.navigationService.getCompanyId()).then(data => {
    console.log("Heartbeat", data);

    let heartbeatData: UserDataSync = null;

    if(data == undefined || data == null || data.data == undefined || data.data == null) {
      heartbeatData = {
        userId: this.navigationService.getProfileId(),
        categories: {
          init_user_sync: new Date,
          notification: new Date,
          messaging: new Date,
          email_verified: null,
          phone_verified: null
        }
      };

      this.updateUserDataSync(heartbeatData);
    } else {
      heartbeatData = data.data;
      this.userDataSyncSubject.next(heartbeatData);
    }
    
  });

  return this.userDataSyncSubject.asObservable();
}

public async getUserAsyncDataSync(): Promise<UserDataSync> {
  let dataSync: UserDataSync = null;

  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.userId": this.navigationService.getProfileId()
  };

  await this.realm.mongoGetOne(query, "swarm-platform-sync", "user", "admin", this.navigationService.getCompanyId()).then( data => {
    console.log("Heartbeat", data);

    if(data == undefined || data == null) {
      dataSync = {
        userId: this.navigationService.getProfileId(),
        categories: {
          init_user_sync: new Date,
          notification: new Date,
          messaging: new Date,
          email_verified: null,
          phone_verified: null
        }
      };

      this.updateUserDataSync(dataSync);
    } else {
    
      dataSync = (data.data);
    }

  });

  this.userDataSyncSubject.next(dataSync);
  return dataSync;
}

public async updateUserDataSync(dataSync: UserDataSync): Promise<void> {
  let query: any = {
    company_id: this.navigationService.getCompanyId(),
    role: "admin",
    "data.userId": this.navigationService.getProfileId()
  };

  this.realm.mongoUpsertOne(query, "swarm-platform-sync", "user", dataSync, "admin", this.navigationService.getCompanyId(), false).then( data => {
    this.getUserDataSync();
  });
}

// ===========================================
// General Data Sync
// ===========================================

  public getGeneralDataSyncTrigger(): Observable<GeneralDataSync> {
    return this.generalDataSyncSubject.asObservable();
  }

  public getGeneralDataSync(): Observable<GeneralDataSync> {
    let query: any = {
      company_id: this.navigationService.getCompanyId(),
      role: "admin"
    };
  
    this.realm.mongoGet(query, "swarm-platform-sync", "general", "admin", this.navigationService.getCompanyId(), false).then(data => {
      console.log("Heartbeat", data);
  
      if(data != undefined) {
        let heartbeatData: GeneralDataSync = null;

        heartbeatData = data[0].data;
  
        if(heartbeatData == null) {
          heartbeatData = {
            categories: {
              init_sync: new Date,
              dispatch: new Date,
              work_orders_open: new Date,
              work_orders_closed: new Date,
              invoice_open: new Date,
              invoice_closed: new Date,
              shift_schedule_published: new Date,
              inventory: new Date,
              inventory_work_order: new Date,
              asset: new Date,
              asset_work_order: new Date,
              employee_management: new Date,
              employees: new Date,
              automation: new Date,
              admin: new Date,
              notification: new Date,
              messaging: new Date,
              phone_call: new Date,
              text_message: new Date
            }
          };

          this.updateGeneralDataSync(heartbeatData);
        } else {
          this.generalDataSyncSubject.next(heartbeatData);
        }
      }
    });
  
    return this.generalDataSyncSubject.asObservable();
  }

  public async getGeneralAsyncDataSync(): Promise<GeneralDataSync> {
    let dataSync: GeneralDataSync = null;
  
    let query: any = {
      company_id: this.navigationService.getCompanyId(),
      role: "admin"
    };
  
    await this.realm.mongoGetOne(query, "swarm-platform-sync", "general", "admin", this.navigationService.getCompanyId()).then( data => {
      console.log("Heartbeat", data);
  
      if(data != undefined) {

        if(data == null) {
          dataSync = {
            categories: {
              init_sync: new Date,
              dispatch: new Date,
              work_orders_open: new Date,
              work_orders_closed: new Date,
              invoice_open: new Date,
              invoice_closed: new Date,
              shift_schedule_published: new Date,
              inventory: new Date,
              inventory_work_order: new Date,
              asset: new Date,
              asset_work_order: new Date,
              employee_management: new Date,
              employees: new Date,
              automation: new Date,
              admin: new Date,
              notification: new Date,
              messaging: new Date,
              phone_call: new Date,
              text_message: new Date
            }
          };

          this.updateGeneralDataSync(dataSync);
        } else {
        
          dataSync = (data.data);
        }
  
      }
    });
  
    this.generalDataSyncSubject.next(dataSync);
    return dataSync;
  }

  public async updateGeneralDataSync(dataSync: GeneralDataSync): Promise<void> {
    let query: any = {
      company_id: this.navigationService.getCompanyId(),
      role: "admin"
    };
  
    this.realm.mongoUpsertOne(query, "swarm-platform-sync", "general", dataSync, "admin", this.navigationService.getCompanyId(), false).then( data => {
      this.getGeneralDataSync();
    });
  }

// ===========================================
// Instant Messaging
// ===========================================

  public getInstantMessages(): Observable<Array<InstantMessage>> {
    let query: any = {
      company_id: this.navigationService.getCompanyId(),
      role: "admin"
    };
  
    this.realm.mongoGet(query, "swarm-platform-sync", "instant-messaging", "admin", this.navigationService.getCompanyId(), false).then(data => {
      console.log("Instant Messages", data);
  
      if(data != undefined) {
        let messagingData: Array<InstantMessage> = [];
  
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          messagingData.push(data[curserIndex].data);
        }
  
        this.instantMessagingSubject.next(messagingData);
      }
    });
  
    return this.instantMessagingSubject.asObservable();
  }

  public getPagedInstantMessages(pageNumber: number, limit: number): Observable<Array<InstantMessage>> {

    let sortQuery: string = "data.dateSent"
    let matchQuery: any = 
    {
      $match : {
        company_id: this.navigationService.getCompanyId(),
        role: "admin",
        $or: [
          {
            "data.fromId": this.navigationService.getProfileId(),
          },
          {
            "data.toId": this.navigationService.getProfileId(),
          }
        ]
      }

    };

    this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "swarm-platform-sync", "instant-messaging", "admin", this.navigationService.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered instant messages", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedInstantMessagesSubject.next(returnData);
        }
      }
    );

    return this.filteredPagedInstantMessagesSubject.asObservable();
  }

  public async getAsyncPagedInstantMessages(conversationId: string, pageNumber: number, limit: number): Promise<Array<InstantMessage>> {
    let messages: Array<InstantMessage> = [];

    let sortQuery: string = "data.dateSent"
    let matchQuery: any = 
    {
      $match : {
        company_id: this.navigationService.getCompanyId(),
        role: "admin",
        $or: [
          {
            "data.toId": this.navigationService.getProfileId(),
            "data.fromId": conversationId,
          },
          {
            "data.toId": conversationId,
            "data.fromId": this.navigationService.getProfileId(),
          }
        ]
      }

    };

    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "swarm-platform-sync", "instant-messaging", "admin", this.navigationService.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered instant messages", data);
  
        if(data != undefined) {
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            messages.push(data[curserIndex].data);
          }

        }
      }
    );

    return messages;
  }

  public async getAsyncInstantMessages(): Promise<InstantMessage[]> {
    let returnData: Array<InstantMessage> = [];
  
    let query: any = {
      company_id: this.navigationService.getCompanyId(),
      role: "admin"
    };
  
    await this.realm.mongoGet(query, "swarm-platform-sync", "instant-messaging", "admin", this.navigationService.getCompanyId(), false).then(data => {
      console.log("Instant Messages", data);
  
      if(data != undefined) {
        
  
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }
  
      }
    });
  
    return returnData;
  }

  public updateInstantMessaging(instantMessage: InstantMessage): void {

    this.realm.mongoInsertOne("swarm-platform-sync", "instant-messaging", instantMessage, "admin", this.navigationService.getCompanyId()).then( data => {
      this.getInstantMessages();
    });

  }
}
