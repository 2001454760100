import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject, SimpleChanges  } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { environment } from 'src/environments/environment';


enum VerificationStatusCodes {
  VERIFYING = 0,
  INCORRECT_CODE = 1,
  EXPIRED_CODE = 2,
  VERIFIED = 3,
  SERVER_ERROR = 4,
  UNVERIFIED = 5
}

@Component({
    selector: 'app-payment-verified',
    templateUrl: 'payment-verified.component.html',
    styleUrls: ['payment-verified.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class PaymentVerifiedComponent implements OnInit {
    @Input('invoiceId') invoiceId: string = "";
    @Input('companyId') companyId: string = "";
    @Input('token') token: string = "";

    public verificationStatusCodes: any = VerificationStatusCodes;

    private emailVerifiedSubscription: Subscription;

    public isPaid: boolean = false;

    public verifiedCategory: number = this.verificationStatusCodes.VERIFYING;
    
    constructor(
      private navigatorService: NavigatorService,
      private paymentHandlerService: PaymentHandlerService,
      private twilioHandlerService: TwilioHandlerService) {
        
    }
  
    ngOnInit() {
      this.paymentVerification();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy() {

    } 

    private paymentVerification(): void {

      this.paymentHandlerService.platformInvoicePaidAsForeign(this.companyId, this.invoiceId, this.token).then( (paid: boolean) => {

        this.isPaid = paid;

        switch(this.isPaid) {

          case true:
            this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
            this.verifiedPaymentAlert();
            break;

          case false:
            this.verifiedCategory = this.verificationStatusCodes.UNVERIFIED;
            break;

        }

      } )

    }

    private registerListeners(): void {

      this.emailVerifiedSubscription = this.twilioHandlerService.getEmailVerifiedListener().subscribe( (verificationCode: number) => {

        switch(verificationCode) {

          case this.verificationStatusCodes.VERIFYING:
            this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
            break;
          
          case this.verificationStatusCodes.INCORRECT_CODE:
            this.verifiedCategory = this.verificationStatusCodes.INCORRECT_CODE;
            break;

          case this.verificationStatusCodes.EXPIRED_CODE:
            this.verifiedCategory = this.verificationStatusCodes.EXPIRED_CODE;
            break;

          case this.verificationStatusCodes.VERIFIED:
            this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
            break;

          case this.verificationStatusCodes.SERVER_ERROR:
            this.verifiedCategory = this.verificationStatusCodes.SERVER_ERROR;
            break;

        }

      });

    }

    private unregisterListeners(): void {
  
      if(this.emailVerifiedSubscription) {
        this.emailVerifiedSubscription.unsubscribe();
      }

    } 


    private verifiedPaymentAlert(): void {

      let message: string = "Paid Company: " + this.navigatorService.getCompanyName();
      let phone: string = environment.adminPhone;

      this.twilioHandlerService.sendTextMessage(message, phone);

    }

  }