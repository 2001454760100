import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-parallax-woods",
  templateUrl: "./parallax-woods.component.html",
  styleUrls: ["./parallax-woods.component.scss"]
})

export class ParallaxWoodsComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }
}