import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import imageCompression from 'browser-image-compression';

import { 
    NavigatorService, 
    keyTypesEnum, 
    automationCategoriesEnum, 
    automationActionCategoryEnum, 
    automationCMMSActionEnum, 
    automationCMMSTriggerEnum, 
    automationGeneralActionEnum, 
    automationInvoiceActionEnum, 
    automationInvoiceTriggerEnum, 
    automationWorkOrderTriggerEnum ,
    workOrderStatusTypes,
    automationWorkOrderActionEnum
} from './navigator.service';

import { inherits } from 'util';
import { Automation, AutomationAction, AutomationCategory, AutomationTriggerEvent, AutomationType, GeneralDataSync, WorkOrder } from 'src/app/data-models/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorAutomationDialogComponent } from 'src/app/shared/professional/vendor-automation-dialog/vendor-automation-dialog.component';
import { filter } from 'rxjs/operators';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { HeartBeatService } from './heartbeat.service';


@Injectable({
    providedIn: 'root'
})

export class AutomationsService {
    private automationsSubject = new Subject<Array<Automation>>();

    private automationCategories: any = automationCategoriesEnum;
    private automationActionTypes: any = automationActionCategoryEnum;

    private automationGeneralActions: any = automationGeneralActionEnum;
    private automationWorkOrderActions: any = automationWorkOrderActionEnum;
    private automationInvoiceActions: any = automationInvoiceActionEnum;
    private automationCMMSActions: any = automationCMMSActionEnum;
    
    private automationWorkOrderTriggers: any = automationWorkOrderTriggerEnum;
    private automationInvoiceTriggers: any = automationInvoiceTriggerEnum;
    private automationCMMSTriggers: any = automationCMMSTriggerEnum;

    private workOrderStatusTypes: any = workOrderStatusTypes;

    // Action Broadcasts
    private automationCreateWorkOrder = new Subject<any>();
    private automationCreatePartRequest = new Subject<any>();
    private automationCreateWorkInvoice = new Subject<any>();
    private automationCreateWorkTaskList = new Subject<any>();

    private automationCreateNotification = new Subject<any>();
    private automationCreateConfirmation = new Subject<any>();

    private automationTrigger = new Subject<AutomationTriggerEvent>();

    private automations: Array<Automation> = [];

    private automationDialogRef: MatDialogRef<VendorAutomationDialogComponent>;

    constructor(
        private navigator: NavigatorService,
        private dialog: MatDialog,
        private heartbeat: HeartBeatService) { 

    }

    // To be used by the main parent component of the application as this service will not be destroyed
    init() {
        this.getServerAutomations();
        this.triggers();
        this.daysPassedAutomation();
    }

    ngOnDestroy() {
        console.log("Automation Destroy");
    }

    private triggers() {
        this.navigator.getWorkOrderCreatedTrigger().subscribe( (status: number) => {
            for(let automation of this.automations) {

                if(
                    (automation.automationCategory == this.automationCategories.WORK_ORDER &&
                        automation.trigger == this.automationWorkOrderTriggers.WORK_ORDER_CREATED) ||

                    (automation.automationCategory == this.automationCategories.INVOICE && 
                        automation.trigger == this.automationInvoiceTriggers.WORK_ORDER_CREATED)
                ) {
    
                        let automationTriggerEvent: AutomationTriggerEvent = {
                            data: null,
                            automation: automation
                        }

                        // Trigger Action
                        this.automationTrigger.next(automationTriggerEvent);
    
                }
    
            }
        });

        this.navigator.getWorkOrderStatusChangedTrigger().subscribe( (status: number) => {
            for(let automation of this.automations) {

                if(
                    (automation.automationCategory == this.automationCategories.WORK_ORDER &&
                        automation.trigger == this.automationWorkOrderTriggers.WORK_ORDER_STATUS_CHANGED) ||

                    (automation.automationCategory == this.automationCategories.INVOICE && 
                        automation.trigger == this.automationInvoiceTriggers.WORK_ORDER_STATUS_CHANGED)
                ) {
    
                    let automationTriggerEvent: AutomationTriggerEvent = {
                        data: null,
                        automation: automation
                    }

                    // Trigger Action
                    this.automationTrigger.next(automationTriggerEvent);
    
                }
    
            }
        });

        this.navigator.getInventoryItemWorkOrderCreatedTrigger().subscribe( (status: number) => {
            for(let automation of this.automations) {

                if(
                    (automation.automationCategory == this.automationCategories.CMMS && 
                        automation.trigger == this.automationCMMSTriggers.INVENTORY_ITEM_WORK_ORDER_CREATED)
                ) {
    
                    let automationTriggerEvent: AutomationTriggerEvent = {
                        data: null,
                        automation: automation
                    }

                    // Trigger Action
                    this.automationTrigger.next(automationTriggerEvent);
    
                }
    
            }
        });

        this.navigator.getInventoryItemWorkOrderStatusChangedTrigger().subscribe( (status: number) => {
            for(let automation of this.automations) {

                if(
                    (automation.automationCategory == this.automationCategories.CMMS && 
                        automation.trigger == this.automationCMMSTriggers.INVENTORY_ITEM_WORK_ORDER_STATUS_CHANGED)
                ) {
    
                    let automationTriggerEvent: AutomationTriggerEvent = {
                        data: null,
                        automation: automation
                    }

                    // Trigger Action
                    this.automationTrigger.next(automationTriggerEvent);
    
                }
    
            }
        });

        this.navigator.getAssetWorkOrderCreatedTrigger().subscribe( (status: number) => {
            for(let automation of this.automations) {

                if(
                    (automation.automationCategory == this.automationCategories.CMMS && 
                        automation.trigger == this.automationCMMSTriggers.ASSET_WORK_ORDER_CREATED)
                ) {
    
                    let automationTriggerEvent: AutomationTriggerEvent = {
                        data: null,
                        automation: automation
                    }

                    // Trigger Action
                    this.automationTrigger.next(automationTriggerEvent);
    
                }
    
            }
        });

        this.navigator.getAssetWorkOrderStatusChangedTrigger().subscribe( (status: number) => {
            for(let automation of this.automations) {

                if(
                    (automation.automationCategory == this.automationCategories.CMMS && 
                        automation.trigger == this.automationCMMSTriggers.ASSET_WORK_ORDER_STATUS_CHANGED)
                ) {
    
                    let automationTriggerEvent: AutomationTriggerEvent = {
                        data: null,
                        automation: automation
                    }

                    // Trigger Action
                    this.automationTrigger.next(automationTriggerEvent);
    
                }
    
            }
        });

    }

    getGeneralTrigger(): Observable<AutomationTriggerEvent> {
        return this.automationTrigger.asObservable();
    }

    private daysPassedAutomation(): void {
        this.navigator.getAsyncOpenWorkOrders().then( (workOrders: Array<WorkOrder>) => {

            if(workOrders != undefined && workOrders != null) {

                this.heartbeat.getGeneralAsyncDataSync().then( (dataSync: GeneralDataSync) => {

                    let currentDate: Date = new Date;
                    let secondMili: number = 1000;
                    let minuteMili: number = secondMili * 60;
                    let hourMili: number = minuteMili * 60;
                    let dayMili: number = hourMili * 24;

                    if(
                        dataSync != null && (
                            dataSync.categories.init_sync == undefined ||
                            dataSync.categories.init_sync == null || 
                            dataSync.categories.init_sync.getTime() + dayMili < currentDate.getTime()
                        )
                    ) {

                        for(let workOrder of workOrders) {
                            this.workOrderDatePassedChecker(workOrder);
                        }

                        dataSync.categories.init_sync = new Date();

                        this.heartbeat.updateGeneralDataSync(dataSync);
                        console.log("Automations Days Passed Checker Completed");
                    }

                });

            }

        });
    }


    // Checks all existing automations for a days passed trigger
    public workOrderDatePassedChecker(workOrder: WorkOrder): boolean {
        let checker: boolean = false;

        if(
            workOrder.dateLastUpdated == undefined ||
            workOrder.status == this.workOrderStatusTypes.CLOSED
        ) {
            return checker;
        }

        for(let automation of this.automations) {

            if(
                (automation.automationCategory == this.automationCategories.WORK_ORDER && 
                    automation.trigger == this.automationWorkOrderTriggers.WORK_ORDER_DATE_LIMIT) ||

                (automation.automationCategory == this.automationCategories.INVOICE && 
                    automation.trigger == this.automationInvoiceTriggers.WORK_ORDER_DATE_LIMIT) ||

                (automation.automationCategory == this.automationCategories.CMMS && 
                    automation.trigger == this.automationCMMSTriggers.INVENTORY_ITEM_WORK_ORDER_DATE_LIMIT) ||

                (automation.automationCategory == this.automationCategories.CMMS && 
                    automation.trigger == this.automationCMMSTriggers.ASSET_WORK_ORDER_DATE_LIMIT)
            ) {

                const currentDate = new Date();

                if(workOrder.dateLastUpdated == undefined || workOrder.dateLastUpdated == null) {
                    workOrder.dateLastUpdated = new Date();

                    this.navigator.updateWorkOrder(workOrder);
                }

                const diffDays = this.dateDiffInDays(workOrder.dateLastUpdated, currentDate); 

                if(
                    diffDays >= automation.daysPassed
                ) {
                    checker = true;

                    let automationTriggerEvent: AutomationTriggerEvent = {
                        data: workOrder,
                        automation: automation
                    }

                    
                    // Trigger Action
                    console.log("Automation Date Passed Trigger");
                    this.automationTrigger.next(automationTriggerEvent);
                }

            }

        }
        
        return checker;
    }

    dateDiffInDays(a: Date, b: Date) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    public automationsBroadcaster(): Observable<Array<Automation>> {
        return this.automationsSubject.asObservable();
    }

    private getServerAutomations(): void {
        this.navigator.getAsyncAutomations().then( (automations: Array<Automation>) => {

            if(automations != undefined && automations != null) {
                this.automations = automations;
                this.automationsSubject.next(this.automations);
            }

        });
    }

    // private updateDateAutomations(): void {

    //     this.navigator.updateAutomations(this.automations).then( (status: boolean) => {

    //         if(status) {
    //             this.getServerAutomations();
    //         }

    //     });

    // }

    // public addNewAutomation(): void {


    //     this.updateDateAutomations();
    // }

    // public editAutomation(automationCategory: number, automationType: number, automationId: number): void {
    //     let automationCategoryIndex: number = this.automations.findIndex( (category: AutomationCategory) => { return category.category == automationCategory; });

    //     if(automationCategoryIndex > -1) {
    //         let automationTypeIndex: number = this.automations[automationCategoryIndex].actionTypes.findIndex( (actionType: AutomationType) => { return actionType.id == automationType; });

    //         if(automationTypeIndex > -1) {
    //             let automationIndex: number = this.automations[automationCategoryIndex].actionTypes[automationTypeIndex].actions.findIndex( (action: AutomationAction) => { return action.id == automationId; });
            
    //             if(automationIndex > -1) {
                    
    //                 this.updateDateAutomations();
    //             }

    //         }

    //     }

    // }

    // public deleteAutomation(automationCategory: number, automationType: number, automationId: number): void {
    //     let automationCategoryIndex: number = this.automations.findIndex( (category: AutomationCategory) => { return category.category == automationCategory; });

    //     if(automationCategoryIndex > -1) {
    //         let automationTypeIndex: number = this.automations[automationCategoryIndex].actionTypes.findIndex( (actionType: AutomationType) => { return actionType.id == automationType; });

    //         if(automationTypeIndex > -1) {
    //             let automationIndex: number = this.automations[automationCategoryIndex].actionTypes[automationTypeIndex].actions.findIndex( (action: AutomationAction) => { return action.id == automationId; });
            
    //             if(automationIndex > -1) {
    //                 this.automations[automationCategoryIndex].actionTypes[automationTypeIndex].actions.splice(automationIndex, 1);

    //                 if(this.automations[automationCategoryIndex].actionTypes[automationTypeIndex].actions.length == 0) {
    //                     this.automations[automationCategoryIndex].actionTypes.splice(automationTypeIndex, 1);
    //                 }

    //                 this.updateDateAutomations();
    //             }

    //         }

    //     }

    // }




    workOrderCreatedTrigger(): void {

    }

    public openAddWorkOrderDialog() {
        this.automationDialogRef = this.dialog.open(VendorAutomationDialogComponent);
    
        this.automationDialogRef.afterClosed().subscribe(data => {
            console.log("Automation Dialog Closed");

            if(data.automation != undefined && data.automation != null) {
                this.navigator.updateAutomations(data.automation).then( (status: boolean) => {

                    if(status) {
                        this.getServerAutomations();
                    }

                });
            }

        })
    }

    public openEditWorkOrderDialog(automation: Automation) {
        this.automationDialogRef = this.dialog.open(
            VendorAutomationDialogComponent,
            { 
                data: 
                {
                  automation: automation
                }
            }
        );
    
        this.automationDialogRef.afterClosed().pipe(
            filter(data => data)
          ).subscribe(data => {
            console.log("Automation Dialog Closed");

            if(data.automation != undefined && data.automation != null) {
                this.navigator.updateAutomations(data.automation).then( (status: boolean) => {

                    if(status) {
                        this.getServerAutomations();
                    }

                });
            }

        })
    }

}