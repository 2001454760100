import { Component, OnInit, ViewChild, AfterViewInit, Input, Output  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ImageHandlerService } from '../../../services/vendor/image-handler.service';
import { NavigatorService, keyTypesEnum } from '../../../services/vendor/navigator.service';
import { InventoryItem, DialogNewAssetWorkOrder, DialogNewAsset, Address, MeterRecording, CMMSLocation, CMMSPartRequest, GroupPermissions, Page, PageSection } from '../../../data-models/models';

export enum viewType {
  STANDARD = 0,
  ADMIN = 1
}

@Component({
  selector: 'app-vendor-part-request-dialog',
  templateUrl: 'vendor-part-request-dialog.component.html',
  styleUrls: ['vendor-part-request-dialog.component.scss']
})

export class VendorPartRequestDialogComponent implements OnInit {
  @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 

  public viewTypes: any = viewType;

  @Input() viewType: number = viewType.STANDARD;
  @Input() userPermissions: Page = null;

  purchaseOrderDetailsForm: FormGroup;
  public dialogClosing: boolean = false;
  public partRequest: CMMSPartRequest = null;
  private syncedPartRequest: CMMSPartRequest = null;
  
  constructor(
    private navigatorService: NavigatorService, 
    private imageHandlerService: ImageHandlerService, 
    private fb: FormBuilder, 
    private datePipe: DatePipe, 
    @Inject(MAT_DIALOG_DATA) public partRequestData: any,
    public matDialogRef: MatDialogRef<VendorPartRequestDialogComponent>
    ) {
    
  }

  ngOnInit() {
    this.matDialogRef.disableClose = true;

    this.partRequest = this.partRequestData.request;
    this.syncedPartRequest = this.partRequestData.request;

    this.viewType = this.partRequestData.view;
    this.userPermissions = this.partRequestData.permissions;

    this.initializeForm();
  }

  initializeForm(): void {
    let permissionDisable: boolean = true;

    // Request Management
    let sectionIndex: number = this.userPermissions.permission.sections.findIndex( (section: PageSection) => { return section.id == "lkj123r" });
    
    if(this.userPermissions.permission.sections[sectionIndex].permissions.update) {
      permissionDisable = false;
    }

    this.purchaseOrderDetailsForm = this.fb.group({
      active: { value: this.partRequest.active, disabled: permissionDisable },
      status: { value: this.partRequest.status, disabled: permissionDisable },
      approval_status: { value: this.partRequest.approval_status, disabled: permissionDisable },
      admin_notes: { value: this.partRequest.admin_notes, disabled: permissionDisable },
    });
  }

  close(): void {
    this.dialogClosing = true;
  }

  resetPartRequest(): void {
    this.partRequest = {
      id: '',
      due_date: undefined,
      origination_date: undefined,
      requisitioner: '',
      active: false,
      status: 0,
      approval_status: 0,
      vendor_id: '',
      company_title: '',
      parts: [],
      work_order: '',
      additional_details: '',
      subtotal: 0,
      sales_tax: 0,
      shipping_cost: 0,
      other_cost: 0,
      total: 0,
      priority: 0,
      street: '',
      city: '',
      state: '',
      zip: 0,
      billing_street: '',
      billing_city: '',
      billing_state: '',
      billing_zip: 0
    };
  }
  
  updatePartRequestActive(active: boolean): void {
    this.resetPartRequest();
    
    for(const key in this.syncedPartRequest) {

      if(key != "active") {
        this.partRequest[key] = this.syncedPartRequest[key];
      } else {
        this.partRequest[key] = active;
      }
    }

    this.syncedPartRequest = JSON.parse( JSON.stringify(this.partRequest) );
  }

  updatePartRequestStatus(status: number): void {
    this.resetPartRequest();
    
    for(const key in this.syncedPartRequest) {

      if(key != "status") {
        this.partRequest[key] = this.syncedPartRequest[key];
      } else {
        this.partRequest[key] = status;
      }
    }

    this.syncedPartRequest = JSON.parse( JSON.stringify(this.partRequest) );
  }

  updatePartRequestApprovalStatus(approval_status: number): void {
    this.resetPartRequest();
    
    for(const key in this.syncedPartRequest) {

      if(key != "approval_status") {
        this.partRequest[key] = this.syncedPartRequest[key];
      } else {
        this.partRequest[key] = approval_status;
      }
    }

    this.syncedPartRequest = JSON.parse( JSON.stringify(this.partRequest) );
  }

  updatePartRequestAdminNotes(admin_notes: string): void {
    this.resetPartRequest();
    
    for(const key in this.syncedPartRequest) {

      if(key != "admin_notes") {
        this.partRequest[key] = this.syncedPartRequest[key];
      } else {
        this.partRequest[key] = admin_notes;
      }
    }

    this.syncedPartRequest = JSON.parse( JSON.stringify(this.partRequest) );
  }

  partRequestCompleted(partRequest: CMMSPartRequest): void {
    if(partRequest != null) {
      this.matDialogRef.close();
    } else {
      this.dialogClosing = false;
    }
  }

  public reset() {
  
  }

}