import { Component, OnInit, AfterViewInit, Input, SimpleChanges  } from '@angular/core';

import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChartDataSets, ChartOptions, ChartType, ChartData } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label, MultiDataSet } from 'ng2-charts';


@Component({
  selector: 'app-vendor-chart-doughnut',
  templateUrl: 'vendor-chart-doughnut.component.html',
  styleUrls: ['vendor-chart-doughnut.component.scss']
})
export class VendorChartDoughnutComponent implements OnInit, AfterViewInit {

  @Input() labels: Array<string> = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  @Input() data: Array< Array<any> > | Array<any> = [
    [350, 450, 100],
    // [50, 150, 120],
    // [250, 130, 70],
  ];
  @Input() colors: Color[] = [ ];
  @Input() displayLegend: boolean = true;

  public doughnutChartLabels: Label[];
  public doughnutChartData: MultiDataSet;
  public doughnutChartColors: Color[];

  public options: any = {

          legend: {
              display: this.displayLegend,
              labels: {
                fontColor: '#555555'
              }
          },

          plugins: {
            datalabels: {
              display: false,
              color: ['#555555', '#555555', '#ffffff']
            }

          },

          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 12

  }

   // Doughnut
  // public doughnutChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  // public doughnutChartData: MultiDataSet = [
  //   [350, 450, 100],
  //   [50, 150, 120],
  //   [250, 130, 70],
  // ];

  public doughnutChartType: ChartType = 'doughnut';

  // public doughnutChartColors: Color[] = [
  //   {
  //     backgroundColor:["#9E120E","#FF5800","#FFB414"],
  //     borderColor: 'rgba(225,10,24,0.2)',
  //     pointBackgroundColor: 'rgba(225,10,24,0.2)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(225,10,24,0.2)'
  //   }
  // ];
    
  constructor(public snackBar: MatSnackBar, public navigatorService: NavigatorService) {
      
  }
  
  ngOnInit(): void {
    this.doughnutChartLabels = this.labels;
    this.doughnutChartData = this.data;
    this.doughnutChartColors = this.colors;

    this.options.legend.display = this.displayLegend;
  }
  
  ngAfterViewInit(): void {
      
  }

  ngOnDestroy() {
    
  }  

  ngOnChanges(changes: SimpleChanges): void {

    this.doughnutChartData = this.data;
    
  }

  // ===============================
  // Charts Logic
  // ===============================

  // Doughnut Events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
