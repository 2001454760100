import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import  * as model from '../data-models/models';
import * as Realm from "realm-web";
import { stringify } from '@angular/compiler/src/util';
import { Router, NavigationEnd, RouteConfigLoadEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data } from '../app.service';



const app: Realm.App = new Realm.App({ id: "hive-gpjgw" });

@Injectable({
  providedIn: 'root'
})

export class MongoService {
    private user: Realm.User | undefined;

    constructor(public router:Router, public snackBar: MatSnackBar) { 

    }

    public getUser(): Realm.User {
      return this.user;
    }

    public async testDBConnection(): Promise<any> {

      console.log("Attempting Connection");
  
      // Create an anonymous credential
      const credentials = Realm.Credentials.anonymous();
  
      try {
        // Authenticate the user
        this.user = await app.logIn(credentials);
        // `App.currentUser` updates to match the logged in user
        // assert(user.id === app.currentUser.id)
        console.log("Connection Successful");
        this.testDBServerSideFunction();
        this.testDBRegisterNewUser();
        this.testDBCollectionInsert();
        return this.user
  
      } catch(err) {
        console.error("Failed to log in", err);
      }
  
    }
    
    public async testLoginEmailPassword(email: string, password: string): Promise<any> {
  
      console.log("Attempting Connection");
        // Create an anonymous credential
      const credentials = Realm.Credentials.emailPassword(email, password);
  
      try {
        // Authenticate the user
        this.user= await app.logIn(credentials);
        console.log("Connection Successful");
        // `App.currentUser` updates to match the logged in user
        // assert(user.id === app.currentUser.id)
  
        return this.user
      } catch(err) {
        console.error("Failed to log in", err);
      }
    }
  
    public async testDBServerSideFunction(): Promise<void> {
  
      console.log("Attempting Function");
  
      try {
        let helloWorld: string = await this.user.functions.hive_test_function2("I is a Function");
  
        console.log("Function Successful");
        console.log(helloWorld);
  
      } catch(err) {
        console.error("Failed to call function", err);
      }
  
    }
  
    public async testDBCollectionInsert(): Promise<void> {
  
      console.log("Attempting A Collection Insert");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let db = mongo.db("hive_test");
        let userCollection = db.collection("users");
  
        let result = await userCollection.insertOne({
          owner_id: this.user.id,
          first_name: "Josh",
          last_name: "Kirk",
          email: "joshkirk@honeydolisting.com",
          phone: "4696904591",
          username: "Legendary Janitor",
          organization: "Honey Do Listing",
          linkedin: "",
          instagram: "",
          facebook: "",
          website: "honeydolisting.com",
        });
  
        console.log(result);
        console.log("Collection Insert Successful");
  
      } catch(err) {
        console.error("Failed Collection Insert", err);
      }
  
    }
  
    public async testDBRegisterNewUser(): Promise<void> {
  
      console.log("Attempting New User Registration");
  
      try {
        const email = "09kirk21@gmail.com";
        const password = "Pa55w0rd";
        await app.emailPasswordAuth.registerUser(email, password);
  
        // let helloWorld: string = await this.user.functions.hive_test_function2("I is a Function");
  
        console.log("New User Added Successfully");
  
      } catch(err) {
        console.error("Failed to add new user", err);
      }
  
    }

    public async mongoAnonymousLogin(): Promise<any> {
  
      console.log("Attempting Anonymous Connection");
        // Create an anonymous credential
      const credentials = Realm.Credentials.anonymous();
  
      try {
        // Authenticate the user
        this.user= await app.logIn(credentials);
        console.log("Anonymous Connection Successful");

        return this.user;
      } catch(err) {
        this.snackBar.open('Failed Anonymous Login', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        console.error("Failed to log in anonymously", err);
      }
    }

    public async mongoLogin(email: string, password: string): Promise<any> {
  
      console.log("Attempting Connection");
      const credentials = Realm.Credentials.emailPassword(email.toLowerCase(), password);
  
      try {
        // Authenticate the user
        this.user= await app.logIn(credentials);
        console.log("Connection Successful");

        return this.user;
      } catch(err) {
        this.snackBar.open('Failed Login', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        console.error("Failed to log in", err);
      }
    }

    public async mongoAdminResetPassword(email: string, newPassword: string): Promise<boolean> {
  
      console.log("Attempting Admin Password Reset");
  
      try {
        await app.emailPasswordAuth.callResetPasswordFunction(
          email, newPassword
        );

        console.log("Password Changed Successfully");

        return true
      } catch(err) {
        this.snackBar.open('Failed Admin Password Reset', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        console.error("Failed to reset password", err);

        return false;
      }
    }

    public async mongoSwitchUser(adminUser?: Realm.User): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      try {
        // const authenticatedUsers = app.allUsers.filter(user => user.isLoggedIn);

        if(!adminUser) {
          // FILTER IS NOT WORKING
          const authenticatedUsers = app.allUsers;
          const user1 = authenticatedUsers[0];
          const user2 = authenticatedUsers[1];
          // Switch to user1
          app.switchUser(user1);
          // The active user is now user1
          // assert(app.currentUser.id === user1.id);

          // Switch to user2
          // app.switchUser(user2);
          // The active user is now user2
          // assert(app.currentUser.id === user2.id);
        } else {
          // this.mongoRemoveTempLoggedUser();
          app.switchUser(adminUser);
        }

      } catch(err) {
        this.snackBar.open('Failed to switch to user', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        console.error("Failed to switch to user", err);
      }
    }

    public async mongoLogout(): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }
  
      console.log("Attempting Logout");
  
      try {
        // Authenticate the user
        this.mongoRemoveTempLoggedUser(this.user);
        this.user.logOut();
        console.log("Logout Successful");
  
      } catch(err) {
        console.error("Failed to logout", err);
      }
    }

    public async mongoRefreshUserCustomData(): Promise<boolean> {
      let completionStatus: boolean = false;

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }
  
      console.log("Attempting Custom Data Refresh");
  
      try {
        await app.currentUser.refreshCustomData();
        completionStatus = true;
        
        console.log("Refresh Custom Data Successful");
      } catch(err) {
        console.error("Failed to Refresh Custom Data", err);
      }

      return completionStatus;
    }

    public async mongoRegisterNewUser(email: string, password: string): Promise<boolean> {

      // DEV NOTE: Disabled the ability to register new companies until launch day
      if(false) {
        this.snackBar.open('Platform Launch hasn\'t taken off just yet. Sit Back, relax, and subscribe to be fully prepared and to recieve 25% off you first month.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 5000 });
        return false;
      }

      console.log("Attempting New User Registration");
  
      try {
        await app.emailPasswordAuth.registerUser(email.toLowerCase(), password);

        this.snackBar.open('You registered successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
  
        // this.router.navigate(['/login']);

        console.log("New User Added Successfully");

        return true;
  
      } catch(err) {
        this.snackBar.open('Failed registration', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        console.error("Failed to add new user", err);
      }
  
    }

    public async mongoAdminRegisterNewUser(email: string, password: string): Promise<boolean> {

      console.log("Attempting New User Registration");
  
      try {
        await app.emailPasswordAuth.registerUser(email.toLowerCase(), password);

        this.snackBar.open('You registered successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
  
        // this.router.navigate(['/login']);

        console.log("New User Added Successfully");

        return true;
  
      } catch(err) {
        this.snackBar.open('Failed registration', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
        console.error("Failed to add new user", err);

        return false
      }
  
    }

    public async mongoRemoveTempLoggedUser(user?: Realm.User) {  
      if(!user) {
        user = app.currentUser;
      }

      await app.removeUser(user);
    }

    public async mongoRemoveCompanyID(query: any, db: string, collection: string, data: any, role: string): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Update");
  
      try {
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: this.user.id,
              role: role,
              data
            }
        );
  
        console.log(result);
        console.log("Collection Update Successful");
        return true;
  
      } catch(err) {
        console.error("Failed Collection Update", err);
        return false;
      }
  
    }

    public async mongoRegister(db: string, collection: string, data: any, action: string, company_id: string): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      console.log("Attempting A Collection Insert");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let result = await userCollection.insertOne({
          company_id: company_id,
          role: "admin",
          action: action,
          data
        });
  
        console.log(result);
        console.log("Collection Insert Successful");
        return true;

      } catch(err) {
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    public async mongoInsertOne(db: string, collection: string, data: any, role: string, company_id: string): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      console.log("Attempting A Collection Insert");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let result = await userCollection.insertOne({
          company_id: company_id,
          role: role,
          data
        });
  
        console.log(result);
        console.log("Collection Insert Successful");
        return true;

      } catch(err) {
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    // public async mongoUpdateOne(query: any, db: string, collection: string, data: any, role: string, company_id: string): Promise<void> {
    //   if(this.user == undefined || !this.user.isLoggedIn) {
    //     this.router.navigate(['/login']);
    //     return;
    //   }

    //   console.log("Attempting A Collection Insert");

    //   if(query.length == 0) {
    //     query = this.user.id;
    //   }
  
    //   try {
    //     // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
    //     // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
    //     let mongo = this.user.mongoClient("mongodb-atlas");
    //     let mongodb = mongo.db(db);
    //     let userCollection = mongodb.collection(collection);
  
    //     let result = await userCollection.updateOne(
    //         {
    //           owner_id: query
    //         },
    //         {
    //           role: role,
    //           data
    //         },
    //         {
    //           upsert: false
    //         }
    //     );
  
    //     console.log(result);
    //     console.log("Collection Insert Successful");
  
    //   } catch(err) {
    //     this.snackBar.open('Failed To Update', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    //     console.error("Failed Collection Insert", err);
    //   }
  
    // }

    public async mongoBasicInsertOne(query: any, db: string, collection: string, data: any, isIdQuery: boolean): Promise<void> {

      const credentials = Realm.Credentials.anonymous();

      // Authenticatin Anon User
      const anonUser: Realm.User = await app.logIn(credentials);

      console.log("Attempting A Collection Insert");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = anonUser.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.insertOne(
            data
        );
  
        console.log(result);
        console.log("Collection Insert Successful");
  
      } catch(err) {
        console.error("Failed Collection Insert", err);
      }
  
    }

    public async mongoUpdateOneUser(query: any, db: string, collection: string, data: any, role: string, company_id: string): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Update");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: this.user.id,
              company_id: company_id,
              role: role,
              data
            },
            {
              upsert: false
            }
        );
  
        console.log(result);
        console.log("Collection Update Successful");
        return true;
  
      } catch(err) {
        console.error("Failed Collection Update", err);
        return false;
      }
  
    }

    public async mongoUpdateOne(query: any, db: string, collection: string, data: any, role: string, company_id: string, isIdQuery: boolean): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Insert");

      if(isIdQuery) {
        query = {
          "data.id": this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: this.user.id,
              company_id: company_id,
              role: role,
              data
            },
            {
              upsert: false
            }
        );
  
        console.log(result);
        console.log("Collection Insert Successful");
        return true;
  
      } catch(err) {
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    public async mongoUpsertOneUserLevel(query: any, db: string, collection: string, data: any, role: string): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Insert");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: this.user.id,
              role: role,
              data
            },
            {
              upsert: true
            }
        );
  
        console.log(result);
        console.log("Collection Insert Successful");
        return true;
  
      } catch(err) {
        console.error("Failure Database: ", db);
        console.error("Failure Collection: ", collection);
        console.error("Failure Query: ", query);
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    public async mongoUpsertOne(query: any, db: string, collection: string, data: any, role: string, company_id: string, isIdQuery: boolean): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Insert");

      if(isIdQuery) {
        query = {
          "data.id": this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: this.user.id,
              company_id: company_id,
              role: role,
              data
            },
            {
              upsert: true
            }
        );
  
        console.log(result);
        console.log("Collection Insert Successful");
        return true;
  
      } catch(err) {
        console.error("Failure Database: ", db);
        console.error("Failure Collection: ", collection);
        console.error("Failure Query: ", query);
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    public async mongoUpsertOneProfile(query: any = {}, db: string, collection: string, data: any, role: string, company_id: string, isIdQuery: boolean): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Insert");

      if(isIdQuery) {
        query = {
          "data.id": this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: data.id,
              company_id: company_id,
              role: role,
              data
            },
            {
              upsert: true
            }
        );
  
        // console.log(result);
        console.log("Collection Insert Successful");
        return true;

      } catch(err) {
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    public async mongoUpdateOneProfile(query: any = {}, db: string, collection: string, data: any, role: string, company_id: string, isIdQuery: boolean): Promise<boolean> {
      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Collection Insert");

      if(isIdQuery) {
        query = {
          "data.id": this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
 
        let result = await userCollection.updateOne(
            query,
            {
              profile_id: data.id,
              company_id: company_id,
              role: role,
              data
            },
            {
              upsert: false
            }
        );
  
        // console.log(result);
        console.log("Collection Insert Successful");
        return true;
  
      } catch(err) {
        console.error("Failed Collection Insert", err);
        return false;
      }
  
    }

    public async mongoGetLoggedInProfile(query: any, db: string, collection: string, role: string, isIdQuery: boolean): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Find Record");

      if(isIdQuery) {
        query = {
          "data.id": this.user.id,
          role: role
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor = await userCollection.findOne(
            query
            // {
            //   company_id: company_id,
            //   role: role
            // }
        );
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoGetOne(query: any, db: string, collection: string, role: string, company_id: string): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting A Find Record");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor = await userCollection.findOne(
            query
            // {
            //   company_id: company_id,
            //   role: role
            // }
        );
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoPagedFilteredGet(query: any, sortQuery: string, db: string, collection: string, role: string, company_id: string, page: number, limit: number): Promise<any> {

      //Pages in will be offset by one so decrementing to properly realign
      let skip: number = (page) * limit;

      console.log("Skip: ", skip);
      console.log("Limit: ", limit);

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      console.log("Attempting To Find Search Records");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);


        let cursor  = (await userCollection.aggregate(
          [
            query,
            {
              $sort : {
                sortQuery : -1
              }
            },
            {
              $skip: skip
            },
            {
              $limit: limit
            }
          ]
        )).sort( );
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoPagedSearchDistinct(query: Array<any>, db: string, collection: string, role: string, company_id: string, page: number, limit: number): Promise<any> {

      //Pages in will be offset by one so decrementing to properly realign
      let skip: number = (page) * limit;

      console.log("Skip: ", skip);
      console.log("Limit: ", limit);

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting To Find Distinct Search Records");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);


        let cursor  = (await userCollection.aggregate(
          [
            {
              $match : {
                company_id: company_id,
                role: role,
                $or: query
              }
            },
            {
              $group : {
                _id: "$data.lockedFields.barcode",
                data: { $addToSet : "$data" }
                
              }
            },
            {
              $sort : {
                "data.lockedFields.title" : +1
              }
            },
            {
              $skip: skip
            },
            {
              $limit: limit
            }
          ]
        )).sort( );
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoGetDistinct(query: Array<any>, distinctKey: string, db: string, collection: string, role: string, company_id: string, page: number, limit: number, isIdQuery: boolean): Promise<any> {
      //Pages in will be offset by one so decrementing to properly realign
      let skip: number = (page) * limit;
      let localDistinctKey: string = "$" + distinctKey;

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting To Find Distinct Records: ", query);

      // if(isIdQuery) {
      //   query = {
      //     user_id: this.user.id
      //   };
      // }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);


        let cursor  = (await userCollection.aggregate(
          [
            {
              $match : {
                company_id: company_id,
                role: role,
                $or: query
              }
            },
            {
              $group : {
                // _id: "$data.lockedFields.barcode",
                _id: localDistinctKey,
                data: { $addToSet : "$data" }
                
              }
            },
            {
              $sort : {
                "data.lockedFields.title" : +1
              }
            },
            {
              $skip: skip
            },
            {
              $limit: limit
            }
          ]
        )).sort( );
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoGet(query: any, db: string, collection: string, role: string, company_id: string, isIdQuery?: boolean): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting To Find Records");

      if(isIdQuery) {
        query = {
          user_id: this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor  = (await userCollection.find(
          query

        )).sort( );

        // let cursor: Promise<any> = await userCollection.aggregate([
        //   {
        //     $group: {
        //       query
        //     }
        //   }
        //   // { $sort: { _id: 1 } }
        // ]);


        // cursor.sort( )
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoDeleteMany(query: any, db: string, collection: string, role: string, company_id: string, isIdQuery: boolean): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting To Find Records");

      if(isIdQuery) {
        query = {
          user_id: this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor  = (await userCollection.deleteMany(
          query

        ));
  
        console.log(cursor);
        console.log("Collection Delete Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Deletion", err);
      }
  
    }

    public async mongoDeleteOne(query: any, db: string, collection: string, role: string, company_id: string): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting To Find Records");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor = (await userCollection.deleteOne(
          query

        ));
  
        console.log(cursor);
        console.log("Collection Delete Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Deletion", err);
      }
  
    }

    public async mongoGetWorkOrders(query: any, db: string, collection: string, role: string, company_id: string, isIdQuery: boolean): Promise<any> {

      if(this.user == undefined || !this.user.isLoggedIn) {
        this.router.navigate(['/login']);
        return;
      }

      // Not Working Properly Yet

      // if(query instanceof Object) {
      //   console.error("Object query is required to update");
      //   return;
      // }

      console.log("Attempting To Find Records");

      if(isIdQuery) {
        query = {
          user_id: this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
  
        let mongo = this.user.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor = (await userCollection.find(
          query

        )).sort( 
          function(a, b){
            return a.data.dateScheduled - b.data.dateScheduled 
          } );

        // let cursor: Promise<any> = await userCollection.aggregate([
        //   {
        //     $group: {
        //       query
        //     }
        //   }
        //   // { $sort: { _id: 1 } }
        // ]);


        // cursor.sort( )
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoGetWorkOrdersAsForeign(query: any, db: string, collection: string, role: string, company_id: string, isIdQuery: boolean): Promise<any> {

      console.log("Attempting To Find Records");

      if(isIdQuery) {
        query = {
          user_id: this.user.id
        };
      }
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
        const credentials = Realm.Credentials.anonymous();

        // Authenticatin Anon User
        const anonUser: Realm.User = await app.logIn(credentials);

  
        let mongo = anonUser.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor  = (await userCollection.find(
          query

        )).sort( 
          function(a, b){
            return a.data.dateScheduled - b.data.dateScheduled 
          } );

        // let cursor: Promise<any> = await userCollection.aggregate([
        //   {
        //     $group: {
        //       query
        //     }
        //   }
        //   // { $sort: { _id: 1 } }
        // ]);


        // cursor.sort( )
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoGetAsForeign(query: any, db: string, collection: string, role: string, company_id: string): Promise<any> {

      console.log("Attempting To Find Records");
  
      try {
        // let mongodb = app.currentUser.mongoClient("mongodb-atlas");
        // let userCollection = mongodb.db("hive_test").collection<any>("users");
        const credentials = Realm.Credentials.anonymous();

        // Authenticatin Anon User
        const anonUser: Realm.User = await app.logIn(credentials);

  
        let mongo = anonUser.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor  = (await userCollection.find(
          query

        ))
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }

    public async mongoUpdateAsForeign(query: any, db: string, collection: string, data: any, role: string, company_id: string): Promise<boolean> {
      const credentials = Realm.Credentials.anonymous();

      // Authenticatin Anon User
      const anonUser: Realm.User = await app.logIn(credentials);

        console.log("Attempting A Collection Insert");
    
        try {
    
          let mongo = anonUser.mongoClient("mongodb-atlas");
          let mongodb = mongo.db(db);
          let userCollection = mongodb.collection(collection);
   
          let result = await userCollection.updateOne(
              query,
              {
                profile_id: "anon",
                company_id: company_id,
                role: role,
                data
              },
              {
                upsert: false
              }
          );
    
          console.log(result);
          console.log("Collection Insert Successful");
          return true;
    
        } catch(err) {
          console.error("Failure Database: ", db);
          console.error("Failure Collection: ", collection);
          console.error("Failure Query: ", query);
          console.error("Failed Collection Insert", err);
          return false;
        }
  
    }

    public async mongoGetOneAsForeign(query: any, db: string, collection: string, role?: string, company_id?: string): Promise<any> {

      console.log("Attempting A Find Record");
  
      try {
        const credentials = Realm.Credentials.anonymous();

        // Authenticatin Anon User
        const anonUser: Realm.User = await app.logIn(credentials);
  
        let mongo = anonUser.mongoClient("mongodb-atlas");
        let mongodb = mongo.db(db);
        let userCollection = mongodb.collection(collection);
  
        let cursor = await userCollection.findOne(
            query
            // {
            //   company_id: company_id,
            //   role: role
            // }
        );
  
        console.log(cursor);
        console.log("Collection Find Successful");

        return cursor;
  
      } catch(err) {
        console.error("Failed Collection Find", err);
      }
  
    }
}