import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { BasePages } from '../menu';

enum SolutionCategories {
  COMMERCIAL = 0,
  RESIDENTIAL = 1
}

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;

  public basePages: any = BasePages;
  public solutionCategories: any = SolutionCategories;

  public activeSolution: number = this.solutionCategories.COMMERCIAL;

  constructor(public menuService:MenuService) { }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }

  onClick(menuId){
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuService.getVerticalMenuItems(), menuId);    
  }

  public changeSolutionCategory(category: number): void {
    this.activeSolution = category
  }

}
