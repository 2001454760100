import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, Inject  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NavigatorService, keyTypesEnum, automationActionRequirementEnum, automationTriggerRequirementEnum } from '../../../services/vendor/navigator.service';
import { Automation, AutomationAction, AutomationCategory, AutomationTrigger, AutomationType, CMMSLocation, DialogNewInventory, Group, GroupPermissions, InventoryItem, Profile, TaskList } from '../../../data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NullTemplateVisitor } from '@angular/compiler';

export enum automationDropdownEnum {
  BLANK = -1,
  CATEGORY = 0,
  TRIGGER = 1,
  TYPE = 2,
  ACTION = 3
  
}

@Component({
  selector: 'vendor-automation-dialog',
  templateUrl: 'vendor-automation-dialog.component.html',
  styleUrls: ['vendor-automation-dialog.component.scss']
})

export class VendorAutomationDialogComponent implements OnInit {

  private keytypes: any = keyTypesEnum;
  public automationActionRequirement: any = automationActionRequirementEnum; 
  public automationTriggerRequirement: any = automationTriggerRequirementEnum; 

  public isAutomationComplete: boolean = false;
  public editAutomation: Automation = null;
  public automations: Array<AutomationCategory> = [];
  
  public automationCategory: number = 0;
  public automationType: number = 0;
  public automationAction: number = 0;
  public automationTrigger: number = 0;

  public dropdownTypes: any = automationDropdownEnum;
  public completionStep: number = this.dropdownTypes.BLANK;

  public employees: Array<Profile> = [];
  public taskLists: Array<TaskList> = [];
  public groups: Array<GroupPermissions> = [];

  // Stati instead of status' because it's plural and I'm attempting to be funny (It's lonely here)
  public workOrderStati: Array<any> = this.navigatorService.getStatusOptions();

  public assignedEmployees: Array<string> = [];
  public assignedTaskLists: Array<string> = [];
  public assignedGroups: Array<string> = [];
  public assignedWorkOrderStati: Array<number> = [];
  public dayTracker: number = 1;

  @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
  public submitForm:FormGroup; 

  constructor(
    private navigatorService: NavigatorService, 
    private fb: FormBuilder,
    public matDialogRef: MatDialogRef<VendorAutomationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public editAutomationData: any) {

  }

  ngOnInit() {
    this.getAutomations();
    this.getEmployees();
    this.getTaskLists();
    this.getGroups();

    this.submitForm = this.fb.group({
      automation: this.fb.group({
        id: this.navigatorService.generateKey(this.keytypes.AUTOMATION),
        companyId: this.navigatorService.getCompanyId(),

        title: [ "", Validators.required ],
        description: [ "" ],

        originatorId: [ this.navigatorService.getProfileId(), Validators.required ],
        originationDate: [ new Date, Validators.required ],

        automationCategory: [ null, Validators.required ],
        automationType: [ this.dropdownTypes.CATEGORY, Validators.required ],
        automationAction: [ this.dropdownTypes.CATEGORY, Validators.required ],
        automationTrigger: [ this.dropdownTypes.CATEGORY, Validators.required ],

        taskLists: this.fb.array([]),
        employees: this.fb.array([]),
        groups: this.fb.array([])

      }),

    }); 

    if(this.editAutomationData != undefined && this.editAutomationData != null) {
      this.editAutomation = this.editAutomationData.automation;

      this.initializeEditForm();
    }

    this.matDialogRef.disableClose = true; //disable default close operation
  }

  private initializeEditForm(): void {
    this.submitForm.controls.automation.get('id').setValue(this.editAutomation.id);
    this.submitForm.controls.automation.get('companyId').setValue(this.editAutomation.companyId);

    this.submitForm.controls.automation.get('title').setValue(this.editAutomation.title);
    this.submitForm.controls.automation.get('description').setValue(this.editAutomation.description);

    this.submitForm.controls.automation.get('originatorId').setValue(this.editAutomation.originatorId);
    this.submitForm.controls.automation.get('originationDate').setValue(this.editAutomation.originationDate);

    this.submitForm.controls.automation.get('automationCategory').setValue(this.editAutomation.automationCategory);
    this.submitForm.controls.automation.get('automationType').setValue(this.editAutomation.automationType);
    this.submitForm.controls.automation.get('automationAction').setValue(this.editAutomation.automationAction);
    this.submitForm.controls.automation.get('automationTrigger').setValue(this.editAutomation.trigger);

    this.automationCategory = this.editAutomation.automationCategory;
    this.automationType = this.editAutomation.automationType;
    this.automationAction = this.editAutomation.automationAction;
    this.automationTrigger = this.editAutomation.trigger;

    this.assignedTaskLists = this.editAutomation.taskList
    this.assignedEmployees = this.editAutomation.employees
    this.assignedGroups = this.editAutomation.groups

    this.assignedWorkOrderStati = this.editAutomation.workOrderStatus
    this.dayTracker = this.editAutomation.daysPassed

    this.completionStep = this.dropdownTypes.ACTION;
  }

  private getAutomations(): void {   
    this.automations = this.navigatorService.getAutomationMenu(); 
    // this.navigatorService.getAsyncAutomations().then( (automations: Array<AutomationCategory>) => {

    //     if(automations != undefined && automations != null) {
    //         this.automations = automations;
    //     }

    // });
  }

  selectionChange(selection: number): void {

    console.log("Automation Selection Change: ", this.submitForm.get("automation")['controls'].automationCategory.value);

    switch(selection) {
      case this.dropdownTypes.CATEGORY:
        let categoryIndex: number =  this.automations.findIndex( (category: AutomationCategory) => { return category.category == this.submitForm.get("automation")['controls'].automationCategory.value;});
        
        if(categoryIndex > -1) {
          this.automationCategory = categoryIndex;

          this.automationType = 0;
          this.automationAction = 0;
          this.automationTrigger = 0;
          this.submitForm.controls.automation.get('automationType').setValue(null);
          this.submitForm.controls.automation.get('automationAction').setValue(null);
          this.submitForm.controls.automation.get('automationTrigger').setValue(null);
        }
        
        break;
      
      case this.dropdownTypes.TYPE:
        let typeIndex: number =  this.automations[this.automationCategory].actionTypes.findIndex( (type: AutomationType) => { return type.id == this.submitForm.get("automation")['controls'].automationType.value; });
        
        if(typeIndex > -1) {
          this.automationType = typeIndex;

          this.automationAction = 0;
          this.submitForm.controls.automation.get('automationAction').setValue(null);
        }

        break;

      case this.dropdownTypes.ACTION:
        let actionIndex: number = this.automations[this.automationCategory].actionTypes[this.automationType].actions.findIndex( (action: AutomationAction) => { return action.id == this.submitForm.get("automation")['controls'].automationAction.value; });
        
        if(actionIndex > -1) {
          this.automationAction = actionIndex;
        }

        break;
      
      case this.dropdownTypes.TRIGGER:
        let triggerIndex: number =  this.automations[this.automationCategory].triggers.findIndex( (trigger: AutomationTrigger) => { return trigger.id == this.submitForm.get("automation")['controls'].automationTrigger.value;});
        
        if(triggerIndex > -1) {
          this.automationTrigger = triggerIndex;
        }

        // Returning because we don't want completionStep to change based on TRIGGER code
        return;
    }

    this.completionStep = selection;
  }

  addDay(): void {
    this.dayTracker++;
  }

  subtractDay(): void {

    if(this.dayTracker >= 2) {
      this.dayTracker--;
    }
  }

  automationSelectionCompare(o1: any, o2: any) {

    // if(o1.id != undefined) {
    //   return (o1.id == o2.id);
    // } else {
    //   return (o1.category == o2.category);
    // }

    return o1 == o2;

  }

  completedAutomationSteps(): void {
    this.isAutomationComplete = true;
  }

  submit() {

    if(this.isAutomationComplete) {
      let automation: Automation = {
        originatorId: this.submitForm.get("automation")['controls'].originatorId.value,
        originationDate: this.submitForm.get("automation")['controls'].originationDate.value,
        id: this.submitForm.get("automation")['controls'].id.value,
        companyId: this.submitForm.get("automation")['controls'].companyId.value,

        title: this.submitForm.get("automation")['controls'].title.value,
        description: this.submitForm.get("automation")['controls'].description.value,

        automationCategory: this.submitForm.get("automation")['controls'].automationCategory.value,
        automationType: this.submitForm.get("automation")['controls'].automationType.value,
        automationAction: this.submitForm.get("automation")['controls'].automationAction.value,
        trigger: this.submitForm.get("automation")['controls'].automationTrigger.value,

        taskList: this.assignedTaskLists,
        employees: this.assignedEmployees,
        groups: this.assignedGroups,

        workOrderStatus: this.assignedWorkOrderStati,
        daysPassed: this.dayTracker
      };

      let dialogObject: any = {
        isLoopBack: false,
        isCompleted: this.isAutomationComplete,
        automation: automation
      }

      console.log("New Automation: ", dialogObject);
      this.matDialogRef.close(dialogObject);
    } else {
      this.close();
    }

  }

  close() {
    this.matDialogRef.close();
  }

  public reset() {
    this.horizontalStepper.reset(); 
    
    this.submitForm.reset({
      
    });   
  }

  public onSelectionChange(e:any) { 
    console.log("Forward");
    if(e.selectedIndex == 4){   
      this.horizontalStepper._steps.forEach(step => step.editable = false);
      console.log(this.submitForm.value);      
    }
  }

  public isEmployeeActive(employeeId: string): boolean {
    let isChecked: boolean = false;

    if(this.editAutomation != undefined) {
      let employeeIndex: number = this.editAutomation.employees.findIndex( (id: string) => { return id == employeeId; });

      if(employeeIndex > -1) {
        isChecked = true;
      }
    }

    return isChecked;
  }

  public isGroupActive(groupId: string): boolean {
    let isChecked: boolean = false;

    if(this.editAutomation != undefined) {
      let groupIndex: number = this.editAutomation.groups.findIndex( (id: string) => { return id == groupId; });

      if(groupIndex > -1) {
        isChecked = true;
      }
    }

    return isChecked;
  }

  public isTaskListActive(listId: string): boolean {
    let isChecked: boolean = false;

    if(this.editAutomation != undefined) {
      let listIndex: number = this.editAutomation.taskList.findIndex( (id: string) => { return id == listId; });

      if(listIndex > -1) {
        isChecked = true;
      }
    }

    return isChecked;
  }

  public isWorkStatusActive(statusId: number): boolean {
    let isChecked: boolean = false;

    if(this.editAutomation != undefined) {
      let statusIndex: number = this.editAutomation.workOrderStatus.findIndex( (id: number) => { return id == statusId; });

      if(statusIndex > -1) {
        isChecked = true;
      }
    }

    return isChecked;
  }

  private getTaskLists(): void {
    this.navigatorService.getAsyncTaskLists().then( (taskLists: Array<TaskList>) => {

      if(taskLists != undefined && taskLists != null) {

        this.taskLists = taskLists;

        for(let taskList of taskLists) {
          this.fb_add_task_list(taskList);
        }

      }

    });
  }

  private getGroups(): void {
    this.navigatorService.getAsyncGroups().then( (groups: Array<GroupPermissions>) => {

      if(groups != undefined && groups != null) {

        this.groups = groups;

        for(let group of groups) {
          this.fb_add_group(group);
        }

      }

    });
  }

  private getEmployees(): void {
    this.navigatorService.getAsyncAllEmployees().then( (employees: Array<Profile>) => {

      if(employees != undefined && employees != null) {

        this.employees = employees;

        for(let employee of employees) {
          this.fb_add_employee(employee);
        }

      }

    });
  }

  public employeeSelection(employeeId: string, checked: boolean) {
    
    if(checked) {
      this.assignedEmployees.push(employeeId);
    } else {
      let employeeIndex: number = this.assignedEmployees.findIndex( (employee: string) => { return employeeId == employee; });

      if(employeeIndex > -1) {
        this.assignedEmployees.splice(employeeIndex, 1);
      }
    }

  }

  public groupSelection(groupId: string, checked: boolean) {

    if(checked) {
      this.assignedGroups.push(groupId);
    } else {
      let groupIndex: number = this.assignedGroups.findIndex( (group: string) => { return groupId == group ; });

      if(groupIndex > -1) {
        this.assignedGroups.splice(groupIndex, 1);
      }
    }

  }

  public taskListSelection(taskListId: string, checked: any) {
    
    if(checked) {
      this.assignedTaskLists.push(taskListId);
    } else {
      let taskListIndex: number = this.assignedTaskLists.findIndex( (taskList: string) => { return taskListId == taskList; });

      if(taskListIndex > -1) {
        this.assignedTaskLists.splice(taskListIndex, 1);
      }
    }

  }

  public statusSelection(statusId: number, checked: any) {
    
    if(checked) {
      this.assignedWorkOrderStati.push(statusId);
    } else {
      let statusIndex: number = this.assignedWorkOrderStati.findIndex( (status: number) => { return statusId == status; });

      if(statusIndex > -1) {
        this.assignedWorkOrderStati.splice(statusIndex, 1);
      }
    }

  }

  // Task List
  fb_task_list() : FormArray {  
    return this.submitForm.get("automation")['controls'].taskLists as FormArray  
  }  
     
  private fb_new_task_list(tasklist: TaskList): FormGroup {  
    return this.fb.group({  
      id: tasklist.id, 
      title: tasklist.title,
    })  
  }  
     
  private fb_add_task_list(tasklist: TaskList) {  
    this.fb_employees().push(this.fb_new_task_list(tasklist));  
  } 

  // Employees
  fb_employees() : FormArray {  
    return this.submitForm.get("automation")['controls'].employees as FormArray  
  }  
     
  private fb_new_employee(profile: Profile): FormGroup {  
    return this.fb.group({  
      id: profile.id, 
      name: profile.first_name + " " + profile.last_name,
      job_title: profile.job_title,
      img: profile.image
    })  
  }  
     
  private fb_add_employee(profile: Profile) {  
    this.fb_employees().push(this.fb_new_employee(profile));  
  } 

  // Groups
  fb_group() : FormArray {  
    return this.submitForm.get("automation")['controls'].groups as FormArray  
  }  
     
  private fb_new_group(group: GroupPermissions): FormGroup {  
    return this.fb.group({  
      id: group.id, 
      title: group.group
    })  
  }  
     
  private fb_add_group(group: GroupPermissions) {  
    this.fb_group().push(this.fb_new_group(group));  
  } 
  
}