import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper'; 
import { Property } from '../../app.models';
import { Settings, AppSettings } from '../../app.settings';
import { Observable, Subscription } from 'rxjs'; 

import { AppService } from '../../app.service'; 
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component'; 
import { AssetItem, CMMSLocation } from 'src/app/data-models/models';

import { ImageHandlerService } from '../../services/vendor/image-handler.service';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-asset-item',
  templateUrl: './asset-item.component.html',
  styleUrls: ['./asset-item.component.scss'] 
})
export class AssetItemComponent implements OnInit {
  @Input() item: AssetItem | any;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  @Input() pickerView: boolean = false;   

  public column:number = 4;
  // public address:string; 
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  public settings: Settings;
  public maxDisplayedFields: number = 4;
  public QuanityWarning: number = 0;

  public gallery: Array<string> = [];
  private imgSubscription: Subscription;


  constructor(public appSettings:AppSettings, public appService:AppService, private imageHandler: ImageHandlerService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() { 
    this.setWarningTag();
  }

  ngAfterViewInit(){
    this.initCarousel();
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  } 

  ngOnDestroy(){ 
    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }
  }
 
  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue);
      if(!changes.viewColChanged.isFirstChange()){
        if(this.item.lockedFields.gallery.length > 1){     
          if(this.directiveRef) {
            this.directiveRef.update();  
          }
        } 
      }
    } 

    for (let propName in changes) {      
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.property.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;       
      //     this.directiveRef.update();  
      //   }       
      // }      
    }  

    if(this.item) {
      // this.getImgs();
    }
  }

  // Old data type was string. There currently isn't a clean platform update setup so two data types are allowed.
  // This requires adjustment.
  translateLocation(locatedAt: CMMSLocation | string): string {
    let locationTitle: string = "";

    if(typeof locatedAt === 'string') {
      return locationTitle;
    } else {
      locationTitle = locatedAt.title;
    }

    return locationTitle
  }

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.item.lockedFields.gallery[0]).subscribe(img => {
      // this.gallery[]

      if(img) {
        this.item.lockedFields.gallery[0] = img;
      }

      // this.imgSubscription.unsubscribe();
    });

  }

  public getImgs(): void {

    this.imgSubscription = this.imageHandler.getFile(this.item.lockedFields.gallery[0]).subscribe(img => {
      // this.gallery[]

      if(img) {
        this.item.lockedFields.gallery[0] = img;
      }

      // this.imgSubscription.unsubscribe();
    });

  }

  public setWarningTag() {
    let quantity: number = this.item.lockedFields.quantity;

    if(quantity == 0) {
      this.QuanityWarning = 0
    } 

    else if(quantity <= 2) {
      this.QuanityWarning = 1
    } 

    else if(quantity <= 5) {
      this.QuanityWarning = 2
    } 
  }

  public getColumnCount(value){
    if(value == 25) {
      this.column = 4;
    }
    else if(value == 33.3) {
      this.column = 3;
    }
    else if(value == 50) {
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }

  public getStatusBgColor(status){
    switch (status) {
      case 'Urgent':
        return '#558B2F';  
      case 'For Bid':
        return '#1E88E5'; 
      case 'Pending Bid':
        return '#009688';
      case 'Closing Soon':
        return '#FFA000';
      case 'Quote':
        return '#F44336';
      case 'Closed':
        return '#000';
      default: 
        return '#01579B';
    }
  }


  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,  
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: "slide"
    }
  }
  
  public details(){
    // this.appService.addToCompare(this.item, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public addToCompare(){
    // this.appService.addToCompare(this.item, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
    // return this.appService.Data.compareList.filter(item=>item.id == this.item.id)[0];
  }

  public addToFavorites(){
    // this.appService.addToFavorites(this.item, (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
    // return this.appService.Data.favorites.filter(item=>item.id == this.item.id)[0];
  }


}
