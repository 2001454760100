import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

import { NavigatorService } from './navigator.service';

import { StripePaymentDetails, PaymentSettings, Profile, PlatformInvoice } from 'src/app/data-models/models';


@Injectable({
    providedIn: 'root'
})

export class PaymentHandlerService {
    private returnedImageSubject = new Subject<any>();
    private returnedImagesSubject = new Subject<any>();

    private rootURL: string = '/stripe/api';


    constructor(public http:HttpClient, public realm:MongoService, public router:Router, private navigatorService: NavigatorService, private sanitizer:DomSanitizer) { 

    }

    public async createNewOnboardingProfile(): Promise<void> {
        
        this.http.get(this.rootURL + '/createNewStripeAccount').subscribe( (stripeData: any) => {
            console.log("Server Return", stripeData );

            if(stripeData != null && stripeData != undefined) {
                let profile: Profile = this.navigatorService.getCurrentProfile();

                let paymentSettings: PaymentSettings = {
                    stripe_account_id: stripeData.id,
                    allow_customer_credit: false
                };


                profile.payment_settings = paymentSettings;

                this.navigatorService.updateProfile(profile);
                this.createNewOnboardingAccountLink(stripeData.id);
            }
        });

    }

    public async createNewOnboardingAccount(): Promise<void> {
        
        this.http.get(this.rootURL + '/createNewStripeAccount').subscribe( (stripeData: any) => {
            console.log("Server Test", stripeData );

            if(stripeData != null && stripeData != undefined) {
                this.navigatorService.getCompanyLevelPaymentSettings().then(paymentSettingData => {

                    let paymentSettings: PaymentSettings = {
                        stripe_account_id: null,
                        allow_customer_credit: false
                    };

                    if(paymentSettingData != null && paymentSettingData != undefined) {
                        paymentSettings = paymentSettingData;
                    }

                    paymentSettings.stripe_account_id = stripeData.id;

                    this.navigatorService.updateCompanyLevelPayment(paymentSettings);
                    this.createNewOnboardingAccountLink(stripeData.id);
                });
            }
        });

    }

    public async createNewOnboardingAccountLink(accountId: string): Promise<void> {

        this.http.post(this.rootURL + '/createNewStripeConnectedLink', { account: accountId }).subscribe( (data: any) => {
            console.log("Server Test", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                window.open(data.url);
            }
        });

    }

    public async getStripeAccount(accountId: string): Promise<void> {
        let account: any = null;

        await this.http.post(this.rootURL + '/getStripeCustomerAccount', { account: accountId }).toPromise().then( (data: any) => {
            console.log("Server Test", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                // window.open(data.url);
                console.log("Stripe Account: ", data);

                account = data;
            }
        });

        return account;
    }

    public async createPlatformPaymentInvoice(invoiceDetails: PlatformInvoice): Promise<void> {

        this.http.post(this.rootURL + '/createPlatformPaymentInvoice', { platformInvoice: invoiceDetails } ).subscribe( (status: boolean) => {

            console.log("Create Platform Invoice", status );

        });

    }
    
    public async makePlatformStripePayment(paymentDetails: StripePaymentDetails, invoiceId: string, companyId: string = null): Promise<void> {

        let companyDetails: any = {
            invoiceId: invoiceId,
            companyId: companyId,
            companyName: this.navigatorService.getCompanyName()
        };

        if(companyId == null) {
            companyDetails.companyId = this.navigatorService.getCompanyId();
        }

        this.http.post(this.rootURL + '/stripePlatformPaymentForm', { paymentDetails: paymentDetails, companyDetails: companyDetails } ).subscribe( (data: any) => {
            console.log("Server Return", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                window.open(data.url);
            }

        });

    }

    public async cleanDeadPlatformInvoices(): Promise<void> {

        this.http.post(this.rootURL + '/cleanDeadPlatformInvoices', { } ).subscribe( (success: any) => {

            console.log("Invoices and Tokens Cleaned", success);

        });

    }

    public async platformInvoicePaidAsForeign(companyId: string, invoiceId: string, token: string): Promise<boolean> {

        let success: boolean = false;

        await this.http.post(this.rootURL + '/paidPlatformInvoice', { paymentDetails: 
            { 
                invoiceId: invoiceId, 
                companyId: companyId, 
                token: token 
            } } ).toPromise().then( (status: boolean) => {
            
            console.log("Platform Invoice Paid", status );
            success = status;

        });

        return success;
    }

    public async makeStripePayment(paymentDetails: StripePaymentDetails): Promise<void> {

        this.navigatorService.getCompanyLevelPaymentSettings().then( (companyPaymentInfo: PaymentSettings) => {

            if(companyPaymentInfo != undefined && companyPaymentInfo != null && companyPaymentInfo.stripe_account_id != undefined) {
                this.http.post(this.rootURL + '/stripePaymentForm', { paymentDetails: paymentDetails, companyPaymentInfo: companyPaymentInfo } ).subscribe( (data: any) => {
                    console.log("Server Test", data );
        
                    if(data != null && data != undefined) {
                        // this.http.get(data.url);
                        window.open(data.url);
                    }
        
                });
            }

        });

    }

    public async makeStripePaymentAsForeign(companyId: string, paymentDetails: StripePaymentDetails): Promise<void> {

        this.navigatorService.getCompanyLevelPaymentSettingsAsForeign(companyId).then( (companyPaymentInfo: PaymentSettings) => {

            if(companyPaymentInfo != undefined && companyPaymentInfo != null && companyPaymentInfo.stripe_account_id != undefined) {
                this.http.post(this.rootURL + '/stripePaymentForm', { paymentDetails: paymentDetails, companyPaymentInfo: companyPaymentInfo } ).subscribe( (data: any) => {
                    console.log("Server Test", data );
        
                    if(data != null && data != undefined) {
                        // this.http.get(data.url);
                        window.open(data.url);
                    }
        
                });
            }

        });

    }

    public async makeAfterpayPayment(paymentDetails: StripePaymentDetails): Promise<void> {

        this.http.post(this.rootURL + '/afterpayForm', { paymentDetails: paymentDetails } ).subscribe( (data: any) => {
            console.log("Server Test", data );

            if(data != null && data != undefined) {
                // this.http.get(data.url);
                window.open(data.next_action.redirect_to_url.url);
            }

        });

    }

}