import { Component, OnInit, AfterViewInit, OnDestroy, Input, Inject } from '@angular/core';
import { CrossModuleChart } from '../../professional/vendor-cross-module-report/vendor-cross-module-report.component';


enum Modes {
  EDIT = 0,
  REPORT = 1
}

@Component({
  selector: 'app-vendor-cross-module-report-container',
  templateUrl: 'vendor-cross-module-report-container.component.html',
  styleUrls: ['vendor-cross-module-report-container.component.scss']
})

export class VendorCrossModuleReportContainerComponent implements OnInit, AfterViewInit {

  @Input() chart: CrossModuleChart = null;

  public modes: any = Modes;
  public mode: number = this.modes.REPORT;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }


  public toggleOnEditMode(): void {
    this.mode = this.modes.EDIT;
  }

  public toggleOffEditMode(): void {
    this.mode = this.modes.REPORT;
  }

  public saveReport(): void {

  }

}