import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject, SimpleChanges, EventEmitter  } from '@angular/core';
import { ThemeService } from 'ng2-charts';

import { Subscription } from 'rxjs/internal/Subscription';
import { PlatformInvoice, StripePaymentDetails } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { environment } from 'src/environments/environment';


enum VerificationStatusCodes {
  VERIFYING = 0,
  VERIFIED = 1,
  SUSPENDED = 2,
  FAILED = 3
}

@Component({
    selector: 'app-membership-portal',
    templateUrl: 'membership-portal.component.html',
    styleUrls: ['membership-portal.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MembershipPortalComponent implements OnInit, AfterViewInit {
    @Input('invoiceId') invoiceId: string = "";
    @Input('companyId') companyId: string = this.navigatorService.getCompanyId();
    @Input('token') token: string = "";

    @Output('validMembership') validMembership: EventEmitter<boolean> = new EventEmitter<boolean>(); 

    public verificationStatusCodes: any = VerificationStatusCodes;

    public membershipInvoice: PlatformInvoice = null;
    public isValidMembership: boolean = false;

    public verifiedCategory: number = this.verificationStatusCodes.VERIFYING;
    
    constructor(
      private navigatorService: NavigatorService,
      private paymentHandlerService: PaymentHandlerService,
      private twilioHandlerService: TwilioHandlerService) {
        
    }
  
    ngOnInit() {
      

    }

    ngAfterViewInit(): void {
      
      let self = this;

      // Dev Note: 
      // If there is no companyId then we assume it's a new user who must register a company. 
      // Therefore, we return with a valid membership.
      if(this.companyId != undefined && this.companyId != null && this.companyId.length > 0) {

        this.checkPlatformMembership().then( (validMembership: boolean) => {

          self.isValidMembership = validMembership;
          self.validMembership.emit(self.isValidMembership);

          if(validMembership) {

            self.verifiedCategory = self.verificationStatusCodes.VERIFIED;

          } else {

            self.verifiedCategory = self.verificationStatusCodes.SUSPENDED;

          }

        })

      } else {

        this.isValidMembership = true;
        this.validMembership.emit(this.isValidMembership);

      }

    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy() {

    } 

    private async checkPlatformMembership(membershipInvoiceCreated: boolean = false): Promise<boolean> {
      let self = this;
      let validMembership: boolean = false;
  
      if(this.companyId != undefined && this.companyId != null && this.companyId.length > 0) {

        await this.navigatorService.getLatestPlatformMembershipInvoice().then( async (invoice: PlatformInvoice) => {
    
          console.log("Platform Invoice: ", invoice);

          if(invoice != undefined && invoice != null) {

            this.membershipInvoice = invoice;
    
            if(invoice?.paid != undefined && invoice?.paid != null) {
    
              validMembership = true;
    
            }
    
          } else {

            if(!membershipInvoiceCreated) {
              membershipInvoiceCreated = true;
              await this.createAnnualMemberInvoice();
            } else {

              setTimeout(function() {
                self.checkPlatformMembership(membershipInvoiceCreated);
              }, 250);

            }

          }
          
        });

      }
  
      return validMembership;
  
    }

    private async createAnnualMemberInvoice(): Promise<void> {

      let creationDate: Date = new Date();

      let invoice: PlatformInvoice = {
        id: this.navigatorService.generateKey(),
        companyId: this.companyId,
        discountCode: null,
        discountPerc: 0,
        discountTotal: 0,
        quantity: 1,
        price: 9900,
        dueDate: creationDate,
        paid: null,
        desc: 'Swarm Operative Annual Member Fee',
        annualMembership: true,
        tag: [],
        last_update: creationDate,
        created_date: creationDate,
        created_by: 'swarm_operative'
      };
  
      await this.paymentHandlerService.createPlatformPaymentInvoice(invoice).then( async () => {

        await this.checkPlatformMembership(true);

        let message: string = "New Company: " + this.navigatorService.getCompanyName();
        let phone: string = environment.adminPhone;

        this.twilioHandlerService.sendTextMessage(message, phone);

      });
  
    }

    public memberPay(platformInvoice: PlatformInvoice = this.membershipInvoice): void {

      let invoice: StripePaymentDetails = {
        stripeAccount: '',
        paymentDetails: [
          {
            name: platformInvoice.desc,
            currency: "usd",
            quantity: platformInvoice.quantity,
        
            // tax_rates: number;
            amount: platformInvoice.price
          }
        ]
      };
  
      this.paymentHandlerService.makePlatformStripePayment(invoice, this.membershipInvoice.id, this.companyId);
  
    }

    public cleanDb(): void {

      this.paymentHandlerService.cleanDeadPlatformInvoices();

    }

}