import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkOrder, CRMDeal } from 'src/app/data-models/models';

import { NavigatorService } from '../../../services/vendor/navigator.service';


@Component({
    selector: 'app-vendor-invoice-generator-dialog.component',
    templateUrl: 'vendor-invoice-generator-dialog.component.html',
    styleUrls: ['vendor-invoice-generator-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorInvoiceGeneratorDialogComponent implements OnInit {

    private workOrderId: string = null;
    private dealId: string = null;

    public workOrder: WorkOrder;
    public deal: CRMDeal;

    constructor(
      private navigatorService: NavigatorService,
      @Inject(MAT_DIALOG_DATA) public linkingData: any) {

    }
  
    ngOnInit() {

      if(this.linkingData != undefined && this.linkingData != null) {
        this.workOrderId = this.linkingData.workOrder;
        this.dealId = this.linkingData.deal;

        this.getWorkOrder();
        this.getDeal();
      }

      console.log("Linking Data: ", this.linkingData);
    }


    async getWorkOrder(): Promise<void> {

      if(this.workOrderId != undefined && this.workOrderId != null) {

        await this.navigatorService.getWorkOrder(this.workOrderId).then( (workOrder: WorkOrder) => {
          this.workOrder = workOrder;
        });

      }

    }

    getDeal(): void {

      if(this.dealId != undefined && this.dealId != null) {

        this.navigatorService.getAsyncCRMDeal(this.dealId).then( (deal: CRMDeal) => {
          this.deal = deal;
        });

      }

    }
}