import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, Inject, ElementRef, EventEmitter  } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { WorkOrder } from '../../../data-models/models';


@Component({
    selector: 'app-work-order-form',
    templateUrl: 'vendor-work-order-form.component.html',
    styleUrls: ['vendor-work-order-form.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorWorkOrderFormComponent implements OnInit, AfterViewInit {

    @ViewChild('docPreview') docPreviewRef: ElementRef;  

    @Input('woId') woId: string = null;
    @Output('woChange') woChange: EventEmitter<any> = new EventEmitter<any>();

    public documentId: string = "default_wo_version";
    public workOrder: WorkOrder = null;
    public builderMode: boolean = false;
    public detailMode: boolean = true;
    public previewZoomLvl: number = 100;

    constructor(
      private navigatorService: NavigatorService,
      public datePipe: DatePipe) {

    }
  
    ngOnInit() {
      this.initialize();
    }

    ngAfterViewInit() { 
      this.calcDocPreviewZoomLvl();
    }

    private initialize(): void {
      
      if(this.woId != undefined && this.woId != null) {

        this.navigatorService.getWorkOrder(this.woId).then( (workOrder: WorkOrder) => {

          if(workOrder != undefined && workOrder != null) {

            this.workOrder = workOrder;

          }

        });

      }

    }

    private calcDocPreviewZoomLvl(): void {
      let self = this;
  
      this.previewZoomLvl = null;
      let previewWidth: number = this.docPreviewRef.nativeElement.clientWidth;
      let defaultDocWidth: number = 75 * 16;
      let padding: number = 5;
  
      let zoomLvl = Math.round( (previewWidth / defaultDocWidth) * 100 );
      zoomLvl -= padding;
  
      setTimeout(function() {
        self.previewZoomLvl = zoomLvl;
      }, 10);
      
    }
  
    submit() {
    }

    public reset(): void {

    }

  }