import { Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';


import { Subscription } from 'rxjs'; 
import { VendorQuickActionMenuService } from 'src/app/services/vendor/vendor-quick-action-menu.service';
import { GuidedWalkthroughService } from 'src/app/services/vendor/guided-walkthrough.service';
import { FormWalkthrough } from './forms-walkthrough'
import { PlatformService, PlatformViewStates } from 'src/app/services/vendor/platform.service';


export enum FormPages {
  TEMPLATES = 0,
  SIGNED = 1,
  BUILDER = 2,
  ACTIVE = 3,
  REPORTS = 4,
  OVERVIEW = 5
}

export enum FormStatusCategories {
  DRAFT = 0,
  FOR_APPROVAL = 1,
  SENT = 2,
  VIEWED = 3,
  COMPLETED = 4,
  EXPIRED = 5,
  WAITING_PAYMENT = 6,
  PAID = 7,
  DECLINED = 8
}

export interface PageChangeEvent {
  page: number,
  value: any
}

@Component({
  selector: 'app-vendor-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
//   encapsulation: ViewEncapsulation.Emulated

})


export class FormsComponent implements OnInit, AfterViewInit, OnDestroy {
  public formPages: any = FormPages;

  public viewStates: any = PlatformViewStates;
  public platformViewState: number = this.viewStates.DESKTOP;

  public activePage: number = this.formPages.OVERVIEW;
  public activeDoc: string = null;
  public activeTemplate: string = null;
  private quickActionSubscriber: Subscription;
  public quickActionMenuExpanded: boolean = true;

  private advancedVersion: string = "2.0.0";
  public authorizedLevelV2Features: boolean = this.platformService.versionChecker(this.advancedVersion);

  private guidedWalkthrough: FormWalkthrough = new FormWalkthrough();

  constructor(
    private platformService: PlatformService,
    private quickActionService: VendorQuickActionMenuService,
    private guidedWalkthroughService: GuidedWalkthroughService) { 
        
  }

  ngOnInit(): void {
    this.quickActionMenuExpanded = this.quickActionService.getQuickActionMenuToggle();

    this.getViewState();
    this.listeners();
  }

  ngAfterViewInit(): void {
    this.guidedWalkthroughService.setGuidedWalkthrough(this.guidedWalkthrough.getWalkthrough());
  }

  ngOnDestroy() {
    
    if(this.quickActionSubscriber) {
      this.quickActionSubscriber.unsubscribe();
    }

  }  

  private getViewState(): void {

    this.platformViewState = this.platformService.getPlatformViewState();

  }

  private listeners(): void {

    this.quickActionSubscriber = this.quickActionService.quickActionMenuToggleBroadcaster().subscribe( (expanded: boolean) => {

      this.quickActionMenuExpanded = expanded;

    });

  }

  public navPageSwitch(page: number): void {
    this.activePage = page;
  }

  public openDocument(pageChangeEvent: PageChangeEvent): void {
    this.activeTemplate = pageChangeEvent.value.template
    this.activeDoc =  pageChangeEvent.value.document;
    this.activePage = pageChangeEvent.page;
  }

}