import { Menu } from './menu.model';
import { pageEnum } from 'src/app/services/vendor/navigator.service';

export enum BasePages {
    BASE = 0,
    HOME = 1,
    TRADES = 20,
    SOLUTIONS = 30,
    RESOURCES = 40,
    HIVE = 50,
    ABOUT = 60,
    CONTACT = 61,
    SIGNUP = 70,
    LOGIN = 80
}

// ===========================================================
// User Menus
// ===========================================================

export const horizontalMenuItems = [ 
    new Menu (BasePages.HOME, 'Home', '/', null, null, false, BasePages.BASE),
    new Menu (BasePages.HIVE, 'HIVE', null, "https://hive.swarmoperative.com", null, false, BasePages.BASE),

    new Menu (BasePages.TRADES, 'Trades', null, null, null, true, 0),
        new Menu (21, 'Login', '/login', null, null, false, BasePages.TRADES), 
        new Menu (22, 'Register', '/register', null, null, false, BasePages.TRADES), 
        // new Menu (23, 'FAQs', '/faq', null, null, false, BasePages.TRADES),
        new Menu (24, 'Pricing', '/pricing', null, null, false, BasePages.TRADES), 
        new Menu (26, 'Terms & Conditions', '/terms-conditions', null, null, false, BasePages.TRADES), 
        new Menu (27, 'Esign Consent', '/esign-consent', null, null, false, BasePages.TRADES),

    new Menu (BasePages.SOLUTIONS, 'Solutions', null, null, null, true, 0),
        new Menu (31, 'Login', '/login', null, null, false, BasePages.SOLUTIONS), 
        new Menu (32, 'Register', '/register', null, null, false, BasePages.SOLUTIONS), 
        // new Menu (33, 'FAQs', '/faq', null, null, false, BasePages.SOLUTIONS),
        new Menu (34, 'Pricing', '/pricing', null, null, false, BasePages.SOLUTIONS), 
        new Menu (36, 'Terms & Conditions', '/terms-conditions', null, null, false, BasePages.SOLUTIONS), 
        new Menu (37, 'Esign Consent', '/esign-consent', null, null, false, BasePages.SOLUTIONS), 

    // new Menu (2, 'Properties', '/properties', null, null, false, 0), 
    new Menu (BasePages.RESOURCES, 'Resources', null, null, null, true, 0),
        new Menu (41, 'Login', '/login', null, null, false, BasePages.RESOURCES), 
        new Menu (42, 'Register', '/register', null, null, false, BasePages.RESOURCES), 
        // new Menu (43, 'FAQs', '/faq', null, null, false, BasePages.EXPLORE),
        new Menu (44, 'Pricing', '/pricing', null, null, false, BasePages.RESOURCES), 
        new Menu (46, 'Terms & Conditions', '/terms-conditions', null, null, false, BasePages.RESOURCES), 
        new Menu (47, 'Esign Consent', '/esign-consent', null, null, false, BasePages.RESOURCES), 
    
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    // new Menu (20, 'About Us', '/about', null, null, false, 0),  

    // new Menu (BasePages.HIVE, 'Hive',  null, null, null, true, 0),
    //     new Menu (51, 'Projects', '/hive/demo/crm', null, null, false, BasePages.HIVE),
    //     new Menu (52, 'Dispatch', '/hive/demo/dispatch-console', null, null, false, BasePages.HIVE),
    //     new Menu (53, 'Work Orders', '/hive/demo/work-orders', null, null, false, BasePages.HIVE),
    //     new Menu (54, 'Invoice', '/hive/demo/invoice', null, null, false, BasePages.HIVE),
    //     new Menu (55, 'Shift Scheduling', '/hive/demo/shift-scheduling', null, null, false, BasePages.HIVE),
    //     new Menu (56, 'CMMS', '/hive/demo/inventory', null, null, false, BasePages.HIVE),
    //     new Menu (57, 'Admin', '/hive/demo/admin-account', null, null, false, BasePages.HIVE),
    //     new Menu (58, 'Field Operator', '/hive/demo/field-operator', null, null, false, BasePages.HIVE),

    // new Menu (30, 'Partner', '/partner', null, null, false, 0),

    new Menu (BasePages.ABOUT, 'About Us', '/about', null, null, false, 0), 

    new Menu (BasePages.CONTACT, 'Contact', '/contact', null, null, false, 0),  

    // Temp Disabled
    // new Menu (40, 'Browse Jobs', '/joblistings', null, null, false, 0), 
    new Menu (BasePages.SIGNUP, 'Sign Up', '/register', null, null, false, 0),  
    // new Menu (140, 'Others', null, null, null, true, 40),
    // new Menu (141, 'External Link', null, 'http://themeseason.com', '_blank', false, 140), 
    // new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    // new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    // new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140)    
]

export const verticalMenuItems = [ 

    new Menu (BasePages.HOME, 'Home', '/', null, null, false, BasePages.BASE),

    new Menu (BasePages.HIVE, 'HIVE', null, "https://hive.swarmoperative.com", null, false, BasePages.BASE),

    new Menu (BasePages.TRADES, 'Trades', null, null, null, true, BasePages.BASE),

    new Menu (BasePages.SOLUTIONS, 'Solutions', null, null, null, true, BasePages.BASE), 

    new Menu (BasePages.RESOURCES, 'Resources', null, null, null, true, BasePages.BASE),
        new Menu (41, 'Login', '/login', null, null, false, BasePages.RESOURCES), 
        new Menu (42, 'Register', '/register', null, null, false, BasePages.RESOURCES), 
        // new Menu (43, 'FAQs', '/faq', null, null, false, BasePages.EXPLORE),
        new Menu (44, 'Pricing', '/pricing', null, null, false, BasePages.RESOURCES), 
        new Menu (46, 'Terms & Conditions', '/terms-conditions', null, null, false, BasePages.RESOURCES), 
        new Menu (47, 'Esign Consent', '/esign-consent', null, null, false, BasePages.RESOURCES), 
    
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    // new Menu (20, 'About Us', '/about', null, null, false, 0),  

    // new Menu (BasePages.HIVE, 'Hive',  null, null, null, true, 0),
    //     new Menu (51, 'Projects', '/hive/demo/crm', null, null, false, BasePages.HIVE),
    //     new Menu (52, 'Dispatch', '/hive/demo/dispatch-console', null, null, false, BasePages.HIVE),
    //     new Menu (53, 'Work Orders', '/hive/demo/work-orders', null, null, false, BasePages.HIVE),
    //     new Menu (54, 'Invoice', '/hive/demo/invoice', null, null, false, BasePages.HIVE),
    //     new Menu (55, 'Shift Scheduling', '/hive/demo/shift-scheduling', null, null, false, BasePages.HIVE),
    //     new Menu (56, 'CMMS', '/hive/demo/inventory', null, null, false, BasePages.HIVE),
    //     new Menu (57, 'Admin', '/hive/demo/admin-account', null, null, false, BasePages.HIVE),
    //     new Menu (58, 'Field Operator', '/hive/demo/field-operator', null, null, false, BasePages.HIVE),

    // new Menu (30, 'Partner', '/partner', null, null, false, 0),

    new Menu (BasePages.ABOUT, 'About Us', '/about', null, null, false, BasePages.BASE), 

    new Menu (BasePages.CONTACT, 'Contact', '/contact', null, null, false, BasePages.BASE),  

    // Temp Disabled
    // new Menu (40, 'Browse Jobs', '/joblistings', null, null, false, 0), 
    new Menu (BasePages.SIGNUP, 'Sign Up', '/register', null, null, false, BasePages.BASE),  


    // OLD VERTICAL MENU
    // new Menu (1, 'Home', '/', null, null, false, 0), 
    // new Menu (2, 'Properties', '/properties', null, null, false, 0), 
    // new Menu (40, 'Pages', null, null, null, true, 0),
    // new Menu (41, 'Agents', '/agents', null, null, false, 40), 
    // new Menu (42, 'Agent', '/agents/1', null, null, false, 40), 
    // new Menu (43, 'Login', '/login', null, null, false, 40), 
    // new Menu (44, 'Register', '/register', null, null, false, 40),   
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'Pricing', '/pricing', null, null, false, 40), 
    // new Menu (47, 'Terms & Conditions', '/terms-conditions', null, null, false, 40),  
    // new Menu (48, 'Landing', '/landing', null, null, false, 40), 
    // new Menu (50, '404 Page', '/404', null, null, false, 40),
    // new Menu (60, 'Contact', '/contact', null, null, false, 0),  
    // new Menu (70, 'About Us', '/about', null, null, false, 0),   
    // new Menu (140, 'Level 1', null, null, null, true, 0),
    // new Menu (141, 'Level 2', null, null, null, true, 140),
    // new Menu (142, 'Level 3', null, null, null, true, 141),
    // new Menu (143, 'Level 4', null, null, null, true, 142),
    // new Menu (144, 'Level 5', null, 'http://themeseason.com', null, false, 143),
    // new Menu (200, 'External Link', null, 'http://themeseason.com', '_blank', false, 0)
]

// ===========================================================
// Vendor Menus
// ===========================================================

export const vendorHorizontalMenuItems = [    
    new Menu (pageEnum.ADMIN, 'settings',  '/hive/professional/admin-account', null, null, false, 0), 
    
    new Menu (20, 'apps',  null, null, null, true, 0),
        new Menu (21, 'Projects', './crm', null, null, false, 20, pageEnum.PROJECTS, "construction"),
        new Menu (22, 'Dispatch', './dispatch-console', null, null, false, 20, pageEnum.DISPATCH, "local_shipping"),
        new Menu (23, 'Scheduling', './work-orders', null, null, false, 20, pageEnum.WORK_ORDER_SCHEDULING, "schedule"),
        new Menu (24, 'Invoice', './invoice', null, null, false, 20, pageEnum.INVOICE, "request_quote"),
        // new Menu (24, 'Shift Scheduling', '/hive/professional/shift-scheduling', null, null, false, 20, pageEnum.SHIFT_SCHEDULING),
        new Menu (26, 'Inventory', './inventory', null, null, false, 20, pageEnum.CMMS, "warehouse"),
        new Menu (28, 'Field Operator', './field-operator', null, null, false, 20, pageEnum.FIELD_OPERATOR, "home_repair_service")
]

export const vendorVerticalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0), 
    new Menu (2, 'Properties', '/properties', null, null, false, 0), 
    new Menu (40, 'Pages', null, null, null, true, 0),
    new Menu (41, 'Agents', '/agents', null, null, false, 40), 
    new Menu (42, 'Agent', '/agents/1', null, null, false, 40), 
    new Menu (43, 'Login', '/login', null, null, false, 40), 
    new Menu (44, 'Register', '/register', null, null, false, 40),   
    new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    new Menu (46, 'Pricing', '/pricing', null, null, false, 40), 
    new Menu (47, 'Terms & Conditions', '/terms-conditions', null, null, false, 40),  
    new Menu (48, 'Landing', '/landing', null, null, false, 40), 
    new Menu (50, '404 Page', '/404', null, null, false, 40),
    new Menu (60, 'Contact', '/contact', null, null, false, 0),  
    new Menu (70, 'About Us', '/about', null, null, false, 0),   
    new Menu (140, 'Level 1', null, null, null, true, 0),
    new Menu (141, 'Level 2', null, null, null, true, 140),
    new Menu (142, 'Level 3', null, null, null, true, 141),
    new Menu (143, 'Level 4', null, null, null, true, 142),
    new Menu (144, 'Level 5', null, 'http://themeseason.com', null, false, 143),
    new Menu (200, 'External Link', null, 'http://themeseason.com', '_blank', false, 0)
]