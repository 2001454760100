import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef, HostListener } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs'; 
import { ActivatedRoute } from '@angular/router';


import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { keyTypesEnum } from '../../../services/vendor/navigator.service';
import { NavigatorService } from '../../../services/vendor/navigator.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetWorkOrder, ExpandableTableDisplayColumns, AssetItem, TableHeaderKeys, WorkOrder, WorkOrderFilter } from '../../../data-models/models';

import { workOrderTypesEnum } from '../../../services/vendor/navigator.service';

import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { VendorAddAssetWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-asset-work-order-dialog/vendor-add-asset-work-order-dialog.component'
import { InputFileRejectedReason } from 'ngx-input-file/src/lib/enums/input-file-rejected-reason';
import { VendorAddWorkOrderDialogComponent } from '../../professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { VendorWorkAuditTrackerDialogComponent } from '../../professional/vendor-work-audit-tracker-dialog/vendor-work-audit-tracker-dialog.component';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];

@Component({
  selector: 'app-vendor-work-order-table',
  templateUrl: 'vendor-work-order-table.component.html',
  styleUrls: ['vendor-work-order-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class VendorWorkOrderTableComponent implements OnInit, AfterViewInit {
  @Output() followOnChange: EventEmitter<any> = new EventEmitter<any>();

  workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  workAuditTrackerDialogRef: MatDialogRef<VendorWorkAuditTrackerDialogComponent>;
  
  addAssetWorkOrderDialogRef: MatDialogRef<VendorAddAssetWorkOrderDialogComponent>;
  // addInventoryWorkOrderDialogRef: MatDialogRef<VendorAddInventoryWorkOrderDialogComponent>;
  // addFacilityWorkOrderDialogRef: MatDialogRef<VendorAddFacilityWorkOrderDialogComponent>;
  addFieldWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;
  // addInvoiceWorkOrderDialogRef: MatDialogRef<VendorAddInvoiceWorkOrderDialogComponent>;

  private keyTypes: any = keyTypesEnum;
  private assetSubscriber: Subscription;

  private filterValue: string = "";
  private startMonthDate: Date = new Date;
  // startMonthDate.setDate(1);

  private endDate: Date = new Date;
  // endDate.setMonth(startMonthDate.getMonth() + 1);
  // endDate.setDate(0);

  public filterForm = new FormGroup({
    startDate: new FormControl(new Date),
    endDate: new FormControl(new Date),
  });

  // public workOrders: Array<AssetWorkOrder> = [];
  tableTypes = workOrderTypesEnum;
  public keyLookup: any
  public workOrderKeys: Array<string> = [];
  public displayHeaderMenu: boolean = false;
  public displayDateFilterMenu: boolean = false;
  public isSmallMenuExpanded: boolean = false;

  private keyExclusion: Array<string> = [
    "workOrderId",
    "visibleShift",
    "gallery",
    "dateContactLog",
    "isAutoGenerated",
    "customFields",
    "custAddress",
    "custBillingAddress",
    "latLng",
    "signature",
    "servicesRendered",
    "attachedParts",
    "parts",
    "images",
    "followOn",
    "foreign_work_orders",
    "auditTrail",
    "b2cMessages",
    "teamMessages",
    "invoices"
  ];

  private dropDownValueKeys: Array<any> = [
    { key: "isPaid", function: this.navigatorService.getPaidOptions() },
    { key: "status", function: this.navigatorService.getStatusOptions() },
    { key: "priority", function: this.navigatorService.getPriorityOptions() },
    { key: "skillLvl", function: this.navigatorService.getSkillLvlOptions() },
    { key: "isCompleted", function: this.navigatorService.getWorkCompletedOptions() }
  ];

  previousIndex: number;
  columns: string[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<AssetWorkOrder>;
  expandedElement: UserData | null;

  public largeFilterOptions: boolean = false;
  public mediumFilterOptions: boolean = false;
  public smallFilterOptions: boolean = false;

  public openOrClosedWorkOrders: number = 0;
  public dealLinkMode: boolean = false;

  @Input() importWorkOrderTemplate: any = null;
  @Input() assetItem: AssetItem;
  @Input() parentWorkOrder: string = null;
  @Input() dealId: string = null;
  @Input() workOrders: Array<any> = []; // Set as any to recieve multiple Work Order Types
  @Input() workOrderType: number = this.tableTypes.FIELD_TECH;
  @Input() workOrderModel: any = null;
  @Input() initializeOpenOrClosed: number = 0;

  @Output() dateChangedEmitter: EventEmitter<any> = new EventEmitter<WorkOrderFilter>();
  @Output() invoiceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() invoiceScrollActionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openOrClosedWorkOrderEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEmitter: EventEmitter<any> = new EventEmitter<WorkOrderFilter>();
  @Output() workOrderUpdatedEmitter: EventEmitter<any> = new EventEmitter<WorkOrder>();
  @Output() dealLinkModeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("workOrderTableContainer") tableContainer;

  @HostListener('window:resize')
  public onWindowResize():void {
    (this.tableContainer.nativeElement.clientWidth > 960) ? this.largeFilterOptions = true : this.largeFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 960 && this.tableContainer.nativeElement.clientWidth > 650) ? this.mediumFilterOptions = true : this.mediumFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 650) ? this.smallFilterOptions = true : this.smallFilterOptions = false; 
  }

  // displayedRows$: Observable<ShipData[]>;
  // totalRows$: Observable<number>;

  constructor(public formBuilder: FormBuilder, public dialog: MatDialog, private navigatorService: NavigatorService, private changeDetectorRef: ChangeDetectorRef) {

    // Assign the data to the data source for the table to render
    this.displayedColumns = [];
    this.dataSource = new MatTableDataSource(this.workOrders);

  }

  ngOnInit(): void {
    // this.filterForm = new FormGroup({
    //   startDate: new FormControl(),
    //   endDate: new FormControl(),
    // });
    this.openOrClosedWorkOrders = this.initializeOpenOrClosed;
    this.startMonthDate.setDate(1);

    this.endDate.setMonth(this.startMonthDate.getMonth() + 1);
    this.endDate.setDate(0);

    this.filterForm.controls["startDate"].setValue(this.startMonthDate);
    this.filterForm.controls["endDate"].setValue(this.endDate);

    this.initializeFilterSize();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {

  }  

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Work Order: " + changes.prop);
    
    if(changes.workOrders != undefined && changes.workOrders.currentValue != undefined) {

      // if(changes.workOrders.currentValue.length > 0) {
        
        
      //   // Used to create dynamic headers
      //   for(const key in changes.workOrders.currentValue[0]) {
      //     // this.workOrderKeys.push(key);
      //   }
      // } else {
      //   this.createDefaultKeys();
      //   this.createDefaultKeyLookup();
      // }

      this.workOrders = []
      this.workOrders = changes.workOrders.currentValue;
      this.dataSource.data = this.workOrders;
      console.log("Follow On W/O: ", this.workOrders);

      this.createKeys(this.workOrders[0]);

      switch(this.workOrderType) {

        case this.tableTypes.ASSET:
          this.createAssetKeyLookup();
          break;

        case this.tableTypes.INVENTORY:
          this.createInventoryKeyLookup();
          break;

        case this.tableTypes.FACILITY:
          this.createFacilityKeyLookup();
          break;

        case this.tableTypes.FIELD_TECH:
          this.createFieldWorkKeyLookup();
          break;

        case this.tableTypes.FOREIGN:
          this.createFieldWorkKeyLookup();
          break;

        case this.tableTypes.INVOICE:
          this.createFieldWorkKeyLookup();
          break;
      }

      
    } else {

      //Default
      if(this.workOrders != undefined && this.workOrders.length > 0) {                                           
        this.createKeys(this.workOrders[0]);
      } else {
        this.createKeys(null);
      }

      this.createFieldWorkKeyLookup();
    }
  } 

  setDisplayedColumns() {
    this.columns.forEach(( colunm, index) => {
      this.displayedColumns[index] = colunm;
    });
  }

  dragStarted(event: CdkDragStart, index: number ) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  initializeFilterSize(): void {
    (this.tableContainer.nativeElement.clientWidth > 960) ? this.largeFilterOptions = true : this.largeFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 960 && this.tableContainer.nativeElement.clientWidth > 650) ? this.mediumFilterOptions = true : this.mediumFilterOptions = false; 
    (this.tableContainer.nativeElement.clientWidth < 650) ? this.smallFilterOptions = true : this.smallFilterOptions = false; 
  }

  public recordValueLookup(record: any, key: string): any {
    let returnData: any = "";
    let isDropDownValue: boolean = false;

    // Checking to see if the record needs to be translated based off a value in a dropdown menu
    for(let dropDownKey of this.dropDownValueKeys) {
      if(key == dropDownKey?.key) {
        isDropDownValue = true;

        let dropdownKeyObjects: Array<any> = dropDownKey?.function;

        for(let keyObject of dropdownKeyObjects) {
          if(record.workOrderData) {
            if(record?.workOrderData[key] == keyObject?.key) {
              returnData = keyObject?.title;
              break;
            }
          } else {
            if(record[key] == keyObject?.key) {
              returnData = keyObject?.title;
              break;
            }
          }
        }

        break;
      }
    }

    if(!isDropDownValue) {

      if(key.indexOf("woId") > -1) {

        if(record.workOrderData != undefined) {
          let id: string = record?.workOrderData?.workOrderId;
          returnData = id?.substring( (id.length - 5), id.length);
        } else {
          let id: string = record.workOrderId;
          returnData = id?.substring( (id.length - 5), id.length);
        }
        
      } else {

        if(key.indexOf(".") > -1) {
          // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
          let key1 = key?.substring(0, key?.indexOf('.'));
          let key2 = key?.substring(key?.indexOf('.') + 1, key?.length);

          if(record?.workOrderData) {
            if(record?.workOrderData[key1] && record?.workOrderData[key1][key2]) {
              returnData = record?.workOrderData[key1][key2];
            }
          } else {
            if(record[key1] != undefined && record[key1][key2] != undefined) {
              returnData = record[key1][key2];
            }
          }
        } else {

          if(
            key != "workOrderId" && 
            key != "technicianId" && 
            key != "originatorId" && 
            key != "dealId" && 
            key != "parent_work_order") {
            returnData = record[key];
          } else {
            let id: string | any = record[key];

            if(!(typeof id === "string") && id?.value != undefined && id?.value != null) {
              returnData = id?.value?.substring( (id?.value?.length - 5), id?.value?.length);
            } else if((typeof id === "string" ) && id != undefined && id != null) {
              returnData = id?.substring( (id?.length - 5), id?.length);
            } else {
              returnData = ""
            }

          }
        }

      }
    } 

    return returnData;
  }

  public headerTitleLookup(key: string): string {
    let title: string = "...failed...";

    if(this.keyLookup[key] != undefined && this.keyLookup[key]?.title != undefined) {
      title = this.keyLookup[key]?.title;

    } else {
      console.log("Failed Lookup: ", key);
    }

    return title
  }

  public dropdownValueLookup(key: string): boolean {
    let display: boolean = false;

    if(this.keyLookup[key] != undefined && this.keyLookup[key].title != undefined) {
      display = this.keyLookup[key].display;

    } else {
      console.log("Failed Lookup: ", key);
    }

    return display
  }

  // insertKey(linkedKey: LinkedData): any {
  //   let data = "";

  //   if(linkedKey && linkedKey.data != null && this.workOrderData) {

  //     if(linkedKey.data.indexOf(".") == -1) {

  //       if(this.workOrderData.workOrderData) {
  //         data = this.workOrderData.workOrderData[linkedKey.data];
  //       } else {
  //         data = this.workOrderData[linkedKey.data];
  //       }
  //     } else {
  //       // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
  //       let key1 = linkedKey.data.substring(0, linkedKey.data.indexOf('.'));
  //       let key2 = linkedKey.data.substring(linkedKey.data.indexOf('.') + 1, linkedKey.data.length);

  //       if(this.workOrderData.workOrderData) {
  //         if(this.workOrderData.workOrderData[key1] && this.workOrderData.workOrderData[key1][key2]) {
  //           data = this.workOrderData.workOrderData[key1][key2];
  //         }
  //       } else {
  //         if(this.workOrderData[key1] && this.workOrderData[key1][key2]) {
  //           data = this.workOrderData[key1][key2];
  //         }
  //       }
  //     }
  //   }

  //   return data;
  // }

  // recordValueLookup(record: AssetItem, meterIndex: number): string {
  //   let value = "";
    
  //   if(record && record.lockedFields.meter_recordings[meterIndex]) {
  //     value = record.lockedFields.meter_recordings[meterIndex].recording;
  //   }

  //   return value;
  // }

  public linkDeal(workOrder: WorkOrder): void {

    if(this.dealId != undefined && this.dealId != null) {
      workOrder.dealId = this.dealId;
      this.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {
        this.workOrderUpdatedEmitter.emit();
      });
    }

  }

  public unlinkDeal(workOrder: WorkOrder): void {

    if(this.dealId != undefined && this.dealId != null) {
      workOrder.dealId = null;
      this.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {
        this.workOrderUpdatedEmitter.emit();
      });
    }

  }

  applyFilter(event: any) {
    let filterValue: string = "";

    if(event.target != null && event.target != undefined) {
      filterValue = (event.target as HTMLInputElement).value;
    } else {
      filterValue = event;
    }

    this.filterValue = filterValue.trim().toLowerCase();

    let workOrderFilter: WorkOrderFilter = {
      searchFilter: this.filterValue,
      dateFilter: {
        startDate: this.filterForm.controls["startDate"].value,
        endDate: this.filterForm.controls["endDate"].value
      }
    }

    // Filtering should be done on the server
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filterEmitter.emit(workOrderFilter);

    if(this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public updateDisplayedHeaders(headerOption: string) {
    this.displayedColumns = [];
    this.columns = [];

    this.displayedColumns.push("workOrderId");
    this.columns.push("workOrderId");

    // this.displayedColumns.push("woId");
    // this.columns.push("woId");

    for(let keyIndex: number = 0; keyIndex < this.workOrderKeys.length; keyIndex++) {
      let key = this.workOrderKeys[keyIndex];

      if(this.keyLookup[key]?.display || key == headerOption) {

        if(key == headerOption) {
          if(!this.keyLookup[key]?.display) {
            this.displayedColumns.push(key);
            this.columns.push(key);
          }
        } else {
          this.displayedColumns.push(key);
          this.columns.push(key);
        }
      }
    }
  }

  public initializeDisplayedHeaders() {
    this.displayedColumns = [];
    this.columns = []

    this.displayedColumns.push("workOrderId");
    this.columns.push("workOrderId");

    // this.displayedColumns.push("woId");
    // this.columns.push("woId");

    for(let keyIndex: number = 0; keyIndex < this.workOrderKeys?.length; keyIndex++) {
      let key = this.workOrderKeys[keyIndex];

      if(this.keyLookup[key] != undefined && this.keyLookup[key]?.display) {
        this.displayedColumns.push(key);
        this.columns.push(key);
      } 
    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  public openWorkOrderDialog(workOrder: AssetWorkOrder) {
    this.workOrderDialogRef = this.dialog.open(
      VendorWorkOrderDialogComponent,
      { data: workOrder }
    );

    this.workOrderDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    })
    
    this.workOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed: ", data);

      switch(this.workOrderType) {

        case this.tableTypes.ASSET:
          this.navigatorService.updateDynamicWorkOrder(data, "hive-cmms", "asset-work-orders");
          break;

        case this.tableTypes.INVENTORY:
          this.navigatorService.updateDynamicWorkOrder(data, "hive-cmms", "inventory-work-orders");
          break;

        case this.tableTypes.FACILITY:
          this.navigatorService.updateDynamicWorkOrder(data, "hive-cmms", "facility-work-orders");
          break;

        case this.tableTypes.FIELD_TECH:
          this.navigatorService.updateWorkOrder(data).then(incomingData => {
            this.workOrderUpdatedEmitter.emit(data);
          });

          break;

        case this.tableTypes.FOREIGN:
          this.navigatorService.updateWorkOrder(data).then(incomingData => {
            this.workOrderUpdatedEmitter.emit(data);
          });

          break;

        case this.tableTypes.INVOICE:
          break;
      }
    })
  }

  public openWorkOrderAuditDialog(workOrder: WorkOrder) {
    this.workAuditTrackerDialogRef = this.dialog.open(
      VendorWorkAuditTrackerDialogComponent,
      { data: workOrder }
    );

    this.workAuditTrackerDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    })
    
    this.workAuditTrackerDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed: ", data);

      switch(this.workOrderType) {

        // case this.tableTypes.ASSET:
        //   this.navigatorService.updateDynamicWorkOrder(data, "hive-cmms", "asset-work-orders");
        //   break;

        // case this.tableTypes.INVENTORY:
        //   this.navigatorService.updateDynamicWorkOrder(data, "hive-cmms", "inventory-work-orders");
        //   break;

        // case this.tableTypes.FACILITY:
        //   this.navigatorService.updateDynamicWorkOrder(data, "hive-cmms", "facility-work-orders");
        //   break;

        // case this.tableTypes.FIELD_TECH:
        //   this.navigatorService.updateWorkOrder(data).then(incomingData => {
        //     this.workOrderUpdatedEmitter.emit(data);
        //   });

        //   break;

        // case this.tableTypes.FOREIGN:
        //   this.navigatorService.updateWorkOrder(data).then(incomingData => {
        //     this.workOrderUpdatedEmitter.emit(data);
        //   });

        //   break;

        // case this.tableTypes.INVOICE:
        //   break;
      }
    })
  }

  public openNewWorkOrderDialog() {
    this.addAssetWorkOrderDialogRef = this.dialog.open(
      VendorAddAssetWorkOrderDialogComponent,
      { data: this.workOrderModel }
    );

    this.addAssetWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("Asset Work Order: " + data);

      this.navigatorService.upsertAssetWorkOrder(data.workOrderData);

      this.followOnChange.emit(data.workOrderData.workOrderId);
    })
  }


  public openNewBlankWorkOrderDialog() {
    switch(this.workOrderType) {

      case this.tableTypes.ASSET:
        this.addAssetWorkOrderDialogRef = this.dialog.open(
          VendorAddAssetWorkOrderDialogComponent,
          { data: this.workOrderModel }
        );

          this.addAssetWorkOrderDialogRef.afterClosed().pipe(
            filter(data => data)
          ).subscribe(data => {
            console.log("Dialog Closed");
            console.log("Asset Work Order: " + data);
      
            this.navigatorService.upsertAssetWorkOrder(data.workOrderData);
          });
        break;

      case this.tableTypes.INVENTORY:
        break;

      case this.tableTypes.FACILITY:
        break;

      case this.tableTypes.FIELD_TECH:
        this.addFieldWorkOrderDialogRef = this.dialog.open(VendorAddWorkOrderDialogComponent,
          { 
            data: {
              parentWorkOrder: this.parentWorkOrder,
              workOrder: null,
              deal: this.dealId
            } 
          }
        );

        this.addFieldWorkOrderDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed", data.workOrderData);
    
          if(data != null && data.isCompleted) {
            this.navigatorService.updateWorkOrder(data.workOrderData).then(incomingData => {
              this.workOrderUpdatedEmitter.emit(data.workOrderData);
              this.followOnChange.emit(data.workOrderData.workOrderId);
            });
          }

        });
        break;

      case this.tableTypes.FOREIGN:
          this.addFieldWorkOrderDialogRef = this.dialog.open(VendorAddWorkOrderDialogComponent,
            { 
              data: {
                parentWorkOrder: this.parentWorkOrder,
                workOrder: this.importWorkOrderTemplate,
                deal: this.dealId
              } 
            }
          );
  
          this.addFieldWorkOrderDialogRef.afterClosed().pipe(
            filter(data => data)
          ).subscribe(data => {
            console.log("Dialog Closed", data.workOrderData);
      
            if(data != null && data.isCompleted) {
              this.navigatorService.updateWorkOrder(data.workOrderData).then(incomingData => {
                this.workOrderUpdatedEmitter.emit(data.workOrderData);
                this.followOnChange.emit(data.workOrderData.workOrderId);
              });
            }
  
          });
          break;

      case this.tableTypes.INVOICE:
        break;
    }

  }

  public OpenClosedSwitch(openOrClosedWorkOrders: number): void {
    this.openOrClosedWorkOrders = openOrClosedWorkOrders;

    this.openOrClosedWorkOrderEmitter.emit(this.openOrClosedWorkOrders);
  }

  public dealLinkModeToggle(): void {

    if(this.dealLinkMode) {
      this.dealLinkMode = false;
    } else {
      this.dealLinkMode = true;
    }

    this.dealLinkModeEmitter.emit(this.dealLinkMode);
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  showHeaderMenu(): void {
    this.displayHeaderMenu = true;
  }

  hideHeaderMenu(): void {
    this.displayHeaderMenu = false;
  }

  showDateFilterMenu(): void {
    this.displayDateFilterMenu = true;
  }

  hideDateFilterMenu(): void {
    this.displayDateFilterMenu = false;
  }

  expandActionMenu(): void {
    this.isSmallMenuExpanded = true;
  }

  collapseActionMenu(): void {
    this.isSmallMenuExpanded = false;
  }

  

  dateFilterChange(): void {
    if(this.filterForm.valid && this.filterForm.controls["endDate"].value != null) {

      let workOrderFilter: WorkOrderFilter = {
        searchFilter: this.filterValue,
        dateFilter: {
          startDate: this.filterForm.controls["startDate"].value,
          endDate: this.filterForm.controls["endDate"].value
        }
      }
      this.dateChangedEmitter.emit(workOrderFilter);
    } 
  }

  invoiceFocus(workOrder: any, scrollTarget: HTMLElement): void {
    if(workOrder) {
      this.invoiceEmitter.emit(workOrder);
      this.invoiceScrollActionEmitter.emit();
    } 
  }

  scrollToTarget(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  private createAssetKeyLookup(): Array<any> {
    let keyLookup: any;
    this.keyLookup = null;

    // W/O Info 
    keyLookup = { 
      "technicianId": { title: "Technician ID", data: "technicianId", display: false },
      "originatorId": { title: "Originator ID", data: "originatorId", display: false },
      "dealId": { title: "Deal ID", data: "dealId", display: false },
      "parent_work_order": { title: "Parent Work Order", data: "parent_work_order", display: false },
      "companyId": { title: "Company ID", data: "companyId", display: false },
      "workOrderId": { title: "W/O ID", data: "workOrderId", display: false },
      "timeStretch": { title: "Minutes Required", data: "timeStretch", display: false },
      "dateInitialContact": { title: "Initial Contact Date", data: "dateInitialContact", display: false },
      "dateScheduled": { title: "Date Scheduled", data: "dateScheduled", display: false },
      "originationDate": { title: "Origin Date", data: "originationDate", display: false },
      
      // POC Contact
      "custPocName": { title: "POC Full Name", data: "custPocName", display: false },
      "custPocPhone": { title: "POC Phone Number", data: "custPocPhone", display: false },
      "custPocEmail": { title: "POC Email", data: "custPocEmail", display: false },

      // Customer Contact
      "custFirstName": { title: "First Name", data: "custFirstName", display: false },
      "custMiddleName": { title: "Middle Name", data: "custMiddleName", display: false },
      "custLastName": { title: "Last Name", data: "custLastName", display: false },
      "custEmail": { title: "Email", data: "custEmail", display: false },
      "custPhone": { title: "Phone Number", data: "custPhone", display: false },

      // Address
      "custAddress": { title: "Address", data: "custAddress", display: false },
      // "custAddress.street": { title: "Street", data: "custAddress.street", display: false },
      // "custAddress.city": { title: "City", data: "custAddress.city", display: false },
      // "custAddress.state": { title: "State", data: "custAddress.state", display: false },
      // "custAddress.zip": { title: "Zip Code", data: "custAddress.zip", display: false },

      // Billing Address
      "custBillingAddress": { title: "Billing Address", data: "custBillingAddress", display: false },
      // "custBillingAddress.street": { title: "Billing Street", data: "custBillingAddress.street", display: false },
      // "custBillingAddress.city": { title: "Billing City", data: "custBillingAddress.city", display: false },
      // "custBillingAddress.state": { title: "Billing State", data: "custBillingAddress.state", display: false },
      // "custBillingAddress.zip": { title: "Billing Zip Code", data: "custBillingAddress.zip", display: false },

      // Billing Amount
      "custBillAmount": { title: "Bill Total", data: "custBillAmount", display: false },

      // Misc.
      "description": { title: "Description", data: "description", display: true },
      "specialNotes": { title: "Notes", data: "specialNotes", display: false },

      "priority": { title: "Priority", data: "priority", display: false },
      "skillLvl": { title: "Skill Level", data: "skillLvl", display: false },
      "status": { title: "Status", data: "status", display: true },
      "isCompleted": { title: "Completed", data: "isCompleted", display: false },
      "isPaid": { title: "Paid", data: "isPaid", display: false },

      "foreign_work_orders": { title: "Follow On W/O's", data: "foreign_work_orders", display: false },

      // Asset Specific
      "operational_status": { title: "Operational Status", data: "operational_status", display: false },
      "id": { title: "Asset ID", data: "id", display: false },
      "title": { title: "Asset Title", data: "title", display: true },
      "barcode": { title: "Barcode", data: "barcode", display: false },
      "located_at": { title: "Located At", data: "located_at", display: false },
      "area": { title: "Area", data: "area", display: false },
      "model": { title: "Model Number", data: "model", display: true },
      "serial_number": { title: "Serial Number", data: "serial_number", display: true },
      "recurring": { title: "Recurring", data: "recurring", display: false },
      "recurring_option": { title: "Recurring Option", data: "recurring_option", display: false },
      "custom_recurring_frequency": { title: "Recurring Frequency", data: "custom_recurring_frequency", display: false },
      "custom_recurring_period": { title: "Recurring Period", data: "custom_recurring_period", display: false },
      "dateLastUpdated": { title: "Last Updated", data: "dateLastUpdated", display: false }
    };

    this.keyLookup = keyLookup;

    this.initializeDisplayedHeaders();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();

    return keyLookup;
  }

  private createInventoryKeyLookup(): Array<any> {
    let keyLookup: any;
    this.keyLookup = null;

    // W/O Info 
    keyLookup = { 
      "technicianId": { title: "Technician ID", data: "technicianId", display: false },
      "originatorId": { title: "Originator ID", data: "originatorId", display: false },
      "dealId": { title: "Deal ID", data: "dealId", display: false },
      "parent_work_order": { title: "Parent Work Order", data: "parent_work_order", display: false },
      "companyId": { title: "Company ID", data: "companyId", display: false },
      "workOrderId": { title: "W/O ID", data: "workOrderId", display: false },
      "timeStretch": { title: "Minutes Required", data: "timeStretch", display: false },
      "dateInitialContact": { title: "Initial Contact Date", data: "dateInitialContact", display: false },
      "dateScheduled": { title: "Date Scheduled", data: "dateScheduled", display: false },
      "originationDate": { title: "Origin Date", data: "originationDate", display: false },
      
      // POC Contact
      "custPocName": { title: "POC Full Name", data: "custPocName", display: false },
      "custPocPhone": { title: "POC Phone Number", data: "custPocPhone", display: false },
      "custPocEmail": { title: "POC Email", data: "custPocEmail", display: false },

      // Customer Contact
      "custFirstName": { title: "First Name", data: "custFirstName", display: false },
      "custMiddleName": { title: "Middle Name", data: "custMiddleName", display: false },
      "custLastName": { title: "Last Name", data: "custLastName", display: false },
      "custEmail": { title: "Email", data: "custEmail", display: false },
      "custPhone": { title: "Phone Number", data: "custPhone", display: false },

      // Address
      "custAddress": { title: "Address", data: "custAddress", display: false },
      // "custAddress.street": { title: "Street", data: "custAddress.street", display: false },
      // "custAddress.city": { title: "City", data: "custAddress.city", display: false },
      // "custAddress.state": { title: "State", data: "custAddress.state", display: false },
      // "custAddress.zip": { title: "Zip Code", data: "custAddress.zip", display: false },

      // Billing Address
      "custBillingAddress": { title: "Billing Address", data: "custBillingAddress", display: false },
      // "custBillingAddress.street": { title: "Billing Street", data: "custBillingAddress.street", display: false },
      // "custBillingAddress.city": { title: "Billing City", data: "custBillingAddress.city", display: false },
      // "custBillingAddress.state": { title: "Billing State", data: "custBillingAddress.state", display: false },
      // "custBillingAddress.zip": { title: "Billing Zip Code", data: "custBillingAddress.zip", display: false },

      // Billing Amount
      "custBillAmount": { title: "Bill Total", data: "custBillAmount", display: false },

      // Misc.
      "description": { title: "Description", data: "description", display: true },
      "specialNotes": { title: "Notes", data: "specialNotes", display: false },

      "priority": { title: "Priority", data: "priority", display: false },
      "skillLvl": { title: "Skill Level", data: "skillLvl", display: false },
      "status": { title: "Status", data: "status", display: true },
      "isCompleted": { title: "Completed", data: "isCompleted", display: false },
      "isPaid": { title: "Paid", data: "isPaid", display: false },

      "foreign_work_orders": { title: "Follow On W/O's", data: "foreign_work_orders", display: false },

      // Asset Specific
      "operational_status": { title: "Operational Status", data: "operational_status", display: false },
      "id": { title: "Inventory ID", data: "id", display: false },
      "title": { title: "Inventory Title", data: "title", display: true },
      "barcode": { title: "Barcode", data: "barcode", display: false },
      "located_at": { title: "Located At", data: "located_at", display: false },
      "area": { title: "Area", data: "area", display: false },
      "model": { title: "Model Number", data: "model", display: true },
      "serial_number": { title: "Serial Number", data: "serial_number", display: true },
      "recurring": { title: "Recurring", data: "recurring", display: false },
      "recurring_option": { title: "Recurring Option", data: "recurring_option", display: false },
      "custom_recurring_frequency": { title: "Recurring Frequency", data: "custom_recurring_frequency", display: false },
      "custom_recurring_period": { title: "Recurring Period", data: "custom_recurring_period", display: false },
      "dateLastUpdated": { title: "Last Updated", data: "dateLastUpdated", display: false }
    };

    this.keyLookup = keyLookup;

    this.initializeDisplayedHeaders();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();

    return keyLookup;
  }

  private createFacilityKeyLookup(): Array<any> {
    let keyLookup: any;
    this.keyLookup = null;

    // W/O Info 
    keyLookup = { 
      "technicianId": { title: "Technician ID", data: "technicianId", display: false },
      "originatorId": { title: "Originator ID", data: "originatorId", display: false },
      "dealId": { title: "Deal ID", data: "dealId", display: false },
      "parent_work_order": { title: "Parent Work Order", data: "parent_work_order", display: false },
      "companyId": { title: "Company ID", data: "companyId", display: false },
      "workOrderId": { title: "W/O ID", data: "workOrderId", display: false },
      "timeStretch": { title: "Minutes Required", data: "timeStretch", display: false },
      "dateInitialContact": { title: "Initial Contact Date", data: "dateInitialContact", display: false },
      "dateScheduled": { title: "Date Scheduled", data: "dateScheduled", display: false },
      "originationDate": { title: "Origin Date", data: "originationDate", display: false },
      
      // POC Contact
      "custPocName": { title: "POC Full Name", data: "custPocName", display: false },
      "custPocPhone": { title: "POC Phone Number", data: "custPocPhone", display: false },
      "custPocEmail": { title: "POC Email", data: "custPocEmail", display: false },

      // Customer Contact
      "custFirstName": { title: "First Name", data: "custFirstName", display: false },
      "custMiddleName": { title: "Middle Name", data: "custMiddleName", display: false },
      "custLastName": { title: "Last Name", data: "custLastName", display: false },
      "custEmail": { title: "Email", data: "custEmail", display: false },
      "custPhone": { title: "Phone Number", data: "custPhone", display: false },

      // Address
      "custAddress": { title: "Address", data: "custAddress", display: false },
      // "custAddress.street": { title: "Street", data: "custAddress.street", display: false },
      // "custAddress.city": { title: "City", data: "custAddress.city", display: false },
      // "custAddress.state": { title: "State", data: "custAddress.state", display: false },
      // "custAddress.zip": { title: "Zip Code", data: "custAddress.zip", display: false },

      // Billing Address
      "custBillingAddress": { title: "Billing Address", data: "custBillingAddress", display: false },
      // "custBillingAddress.street": { title: "Billing Street", data: "custBillingAddress.street", display: false },
      // "custBillingAddress.city": { title: "Billing City", data: "custBillingAddress.city", display: false },
      // "custBillingAddress.state": { title: "Billing State", data: "custBillingAddress.state", display: false },
      // "custBillingAddress.zip": { title: "Billing Zip Code", data: "custBillingAddress.zip", display: false },

      // Billing Amount
      "custBillAmount": { title: "Bill Total", data: "custBillAmount", display: false },

      // Misc.
      "description": { title: "Description", data: "description", display: true },
      "specialNotes": { title: "Notes", data: "specialNotes", display: false },

      "priority": { title: "Priority", data: "priority", display: false },
      "skillLvl": { title: "Skill Level", data: "skillLvl", display: false },
      "status": { title: "Status", data: "status", display: true },
      "isCompleted": { title: "Completed", data: "isCompleted", display: false },
      "isPaid": { title: "Paid", data: "isPaid", display: false },

      "foreign_work_orders": { title: "Follow On W/O's", data: "foreign_work_orders", display: false },

      // Asset Specific
      "operational_status": { title: "Operational Status", data: "operational_status", display: false },
      "id": { title: "Facility ID", data: "id", display: false },
      "title": { title: "Facility Title", data: "title", display: true },
      "barcode": { title: "Barcode", data: "barcode", display: false },
      "located_at": { title: "Located At", data: "located_at", display: false },
      "area": { title: "Area", data: "area", display: false },
      "model": { title: "Model Number", data: "model", display: true },
      "serial_number": { title: "Serial Number", data: "serial_number", display: true },
      "recurring": { title: "Recurring", data: "recurring", display: false },
      "recurring_option": { title: "Recurring Option", data: "recurring_option", display: false },
      "custom_recurring_frequency": { title: "Recurring Frequency", data: "custom_recurring_frequency", display: false },
      "custom_recurring_period": { title: "Recurring Period", data: "custom_recurring_period", display: false },
      "dateLastUpdated": { title: "Last Updated", data: "dateLastUpdated", display: false }
    };

    this.keyLookup = keyLookup;

    this.initializeDisplayedHeaders();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();

    return keyLookup;
  }

  private createFieldWorkKeyLookup(): Array<any> {
    let keyLookup: any;
    this.keyLookup = null;

    // W/O Info 
    keyLookup = { 
      // Artificial Add
      "woId": { title: "Work Order ID", data: "woId", display: true },


      "technicianId": { title: "Technician ID", data: "technicianId", display: false },
      "originatorId": { title: "Originator ID", data: "originatorId", display: false },
      "dealId": { title: "Deal ID", data: "dealId", display: false },
      "parent_work_order": { title: "Parent Work Order", data: "parent_work_order", display: false },
      "companyId": { title: "Company ID", data: "companyId", display: false },
      "workOrderId": { title: "W/O ID", data: "workOrderId", display: false },
      "timeStretch": { title: "Minutes Required", data: "timeStretch", display: false },
      "dateInitialContact": { title: "Initial Contact Date", data: "dateInitialContact", display: false },
      "dateScheduled": { title: "Date Scheduled", data: "dateScheduled", display: false },
      "originationDate": { title: "Origin Date", data: "originationDate", display: false },
      
      // POC Contact
      "custPocName": { title: "POC Full Name", data: "custPocName", display: false },
      "custPocPhone": { title: "POC Phone Number", data: "custPocPhone", display: false },
      "custPocEmail": { title: "POC Email", data: "custPocEmail", display: false },

      // Customer Contact
      "custFirstName": { title: "First Name", data: "custFirstName", display: false },
      "custMiddleName": { title: "Middle Name", data: "custMiddleName", display: false },
      "custLastName": { title: "Last Name", data: "custLastName", display: false },
      "custEmail": { title: "Email", data: "custEmail", display: false },
      "custPhone": { title: "Phone Number", data: "custPhone", display: false },

      // Address
      // "custAddress": { title: "Address", data: "custAddress", display: false },
      "custAddress.street": { title: "Street", data: "custAddress.street", display: false },
      "custAddress.city": { title: "City", data: "custAddress.city", display: false },
      "custAddress.state": { title: "State", data: "custAddress.state", display: false },
      "custAddress.zip": { title: "Zip Code", data: "custAddress.zip", display: false },

      // Billing Address
      // "custBillingAddress": { title: "Billing Address", data: "custBillingAddress", display: false },
      "custBillingAddress.street": { title: "Billing Street", data: "custBillingAddress.street", display: false },
      "custBillingAddress.city": { title: "Billing City", data: "custBillingAddress.city", display: false },
      "custBillingAddress.state": { title: "Billing State", data: "custBillingAddress.state", display: false },
      "custBillingAddress.zip": { title: "Billing Zip Code", data: "custBillingAddress.zip", display: false },

      // Billing Amount
      "custBillAmount": { title: "Bill Total", data: "custBillAmount", display: false },

      // Misc.
      "description": { title: "Description", data: "description", display: true },
      "specialNotes": { title: "Notes", data: "specialNotes", display: false },

      "priority": { title: "Priority", data: "priority", display: false },
      "skillLvl": { title: "Skill Level", data: "skillLvl", display: false },
      "status": { title: "Status", data: "status", display: true },
      "isCompleted": { title: "Completed", data: "isCompleted", display: false },
      "isPaid": { title: "Paid", data: "isPaid", display: false },

      "foreign_work_orders": { title: "Follow On W/O's", data: "foreign_work_orders", display: false },

      // Asset Specific
      "operational_status": { title: "Operational Status", data: "operational_status", display: false },
      "id": { title: "W/O ID", data: "id", display: false },
      "title": { title: "Title", data: "title", display: true },
      "barcode": { title: "Barcode", data: "barcode", display: false },
      "located_at": { title: "Located At", data: "located_at", display: false },
      "area": { title: "Area", data: "area", display: false },
      "model": { title: "Model Number", data: "model", display: true },
      "serial_number": { title: "Serial Number", data: "serial_number", display: true },
      "recurring": { title: "Recurring", data: "recurring", display: false },
      "recurring_option": { title: "Recurring Option", data: "recurring_option", display: false },
      "custom_recurring_frequency": { title: "Recurring Frequency", data: "custom_recurring_frequency", display: false },
      "custom_recurring_period": { title: "Recurring Period", data: "custom_recurring_period", display: false },
      "dateLastUpdated": { title: "Last Updated", data: "dateLastUpdated", display: false }
    };

    this.keyLookup = keyLookup;

    this.initializeDisplayedHeaders();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();

    return keyLookup;
  }

  private createKeys(workOrder: any): void {
    this.workOrderKeys = [];

    if(!workOrder) {
      workOrder = this.createTestData(0);
    }

    this.workOrderKeys.push("woId");

    for(const key in workOrder) {
      // Exclude Fields That Can't Be Headers
      if(!this.keyExclusion.includes(key) && key) {
        this.workOrderKeys.push(key);
      }
    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }


    /** Builds and returns a new Work Order. */
  private createTestData(id: number): any {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

    let custPhone: Array<number> = [1234567890, 1234567891];
    let dateContactLog: Array<Date> = [new Date];
    let followOnMaintence: Array<string> = ["Fix Broken Stuff", "Refix Broken Stuff", "Try Not to Break New Stuff"];
    // let workOrderNumber:number = id + 123;
  
    return {
      technicianId: "1a23bcff",
      originatorId: this.navigatorService.getProfileId(),
      workOrderId: this.navigatorService.generateKey(this.keyTypes.WORD_ORDER_ID),
      companyId: this.navigatorService.getCompanyId(),

      originationDate: new Date,
      custPocName: name,
      custPocPhone: 1234567890,
      custPocEmail: 'null@gmail.com',
      custFirstName: name,
      custMiddleName: '',
      custLastName: 'Last Name',
      custEmail: 'null@gmail.com',
      custPhone: custPhone,
      custAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillingAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillAmount: id,
      description: 'Customer Tales of Broken Stuff. Customer Tales of Broken Stuff. Customer Tales of Broken Stuff',
      dateContactLog: dateContactLog,
      dateInitialContact: new Date,
      dateScheduled: new Date,
      priority: 3,
      skillLvl: 2,
      specialNotes: "",
      status: 1,
      foreign_work_orders: followOnMaintence,
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: [],

      // Asset Specific
      operational_status: null,
      id: null,
      title: null,
      barcode: null,
      located_at: null,
      area: null,
      model: null,
      serial_number: null,
      recurring: null,
      recurring_option: null,
      custom_recurring_frequency: null,
      custom_recurring_period: null
    };

  }
}