import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sitting-dog-animation',
  templateUrl: './sitting-dog-animation.component.html',
  styleUrls: ['./sitting-dog-animation.component.scss']
})
export class SittingDogAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit(){

  }

}