import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { NavigatorService } from '../../../services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { style } from '@angular/animations';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 32.7767;
  public lng: number = -96.7970;
  public zoom: number = 10; 
  public mapStyles: any;

  public keyTypes: any;
  public feedbackForm: FormGroup;
  public subscribeForm: FormGroup;
  constructor(public formBuilder: FormBuilder, public snackBar: MatSnackBar, private navigatorService: NavigatorService) { }

  ngOnInit() {
    this.keyTypes = this.navigatorService.getKeyTypes();
    this.mapStyles = this.navigatorService.getMapStyles();
    
    this.feedbackForm = this.formBuilder.group({ 
      email: ['', Validators.compose([Validators.required, emailValidator])], 
      message: ['', Validators.required]
    });
    this.subscribeForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])]
    })
  }

  onMapReady(map?: google.maps.Map ){
    if(map)
      map.setOptions({
        streetViewControl: false,
        styles: this.mapStyles.retro
      });
  }

  public onFeedbackFormSubmit(values:Object):void {
    if (this.feedbackForm.valid) {

      let message: any = {
        id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
        email: this.feedbackForm.get("email").value,
        message: this.feedbackForm.get("message").value,
      }

      this.navigatorService.sendMessage(message);

      this.snackBar.open('Your message was sent!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    }
  }

  public onSubscribeFormSubmit(values:Object):void {
    if (this.subscribeForm.valid) {
      let subscriber: any = {
        id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
        email: this.subscribeForm.get("email").value
      }

      this.navigatorService.newsletterSubscribe(subscriber);

      this.snackBar.open('You\'re a Subscriber!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    }
  }

}
