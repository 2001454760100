import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutomationTag, CRMDeal, InfoPanelImgSlide } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { NotificationService } from 'src/app/services/vendor/notification.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { AnimationType } from "../../carousel-support/carousel-support.animations";
import { CarouselSupportComponent } from "../../carousel-support/carousel-support.component";

export enum ObjectTypes {
  WORK_ORDER = 0,
  DEAL = 1,
  PIPE = 2,
  INVOICE = 3
}

@Component({
  selector: 'app-vendor-object-messaging',
  templateUrl: 'vendor-object-messaging.component.html',
  styleUrls: ['vendor-object-messaging.component.scss']
})

export class VendorObjectMessagingComponent implements OnInit {
  @ViewChild('messageHistoryContainer') messageHistoryContainerRef: any;  

  @Input() taggedObject: any = null;
  @Input() objectType: number = null;

  @Input() styleBackground: string = "#000000";
  @Input() styleColor: string = "#ffffff";
  @Input() styleHeight: string = "1.25em";
  @Input() styleWidth: string = "1.25em";
  @Input() styleDisplayTitle: boolean = false;
  @Input() styleDisplayTooltip: boolean = false;
  @Input() styleLeftExpand: boolean = false;

  private keyTypes: any = keyTypesEnum;
  public objTypes: any = ObjectTypes;

  public profileId: string = null;
  public customerViewer: boolean = false;

  public textMessage: string = "";
  public teamMessage: string = "";

  public teamChatExpanded: boolean = false;

  constructor(
    public snackBar: MatSnackBar, 
    private navigatorService: NavigatorService,
    private imageHandler: ImageHandlerService,
    private twilioHandler: TwilioHandlerService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.profileId = this.navigatorService.getProfileId();
  }

  ngAfterViewInit() {

    // Auto scroll to the bottom of the message panel
    this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;
  }

  public containerClick(): void {

  }

  public expandTeamChat(): void {
    this.teamChatExpanded = true;
  }

  public collapseTeamChat(): void {
    this.teamChatExpanded = false;
  }

  public assignedStaffUpdate(staff: Array<string>): void {
    this.taggedObject.assignedStaff = staff;
    
    this.navigatorService.getAsyncCRMDeal(this.taggedObject.id).then( async (deal: CRMDeal) => {

      if(deal != undefined && deal != null) {

        deal.assignedStaff = this.taggedObject.assignedStaff;

        await this.navigatorService.upsertCRMDeal(deal).then( (status: boolean) => {

          if(status) {
            this.teamMessage = null;
          }

        });

      }

    });

  }

  public assignedTeamsUpdate(teams: Array<string>): void {
   
    this.taggedObject.assignedTeams = teams;
    
    this.navigatorService.getAsyncCRMDeal(this.taggedObject.id).then( async (deal: CRMDeal) => {

      if(deal != undefined && deal != null) {

        deal.assignedTeams = this.taggedObject.assignedTeams;

        await this.navigatorService.upsertCRMDeal(deal).then( (status: boolean) => {

          if(status) {
            this.teamMessage = null;
          }

        });

      }

    });
  }

  public updateTextMessage(message: string): void {
    this.textMessage = message;
  }

  public updateTeamMessage(message: string): void {
    this.teamMessage = message;
  }

  public async sendTeamMessage(): Promise<void> {

    console.log("Sending Team Message");

    if(this.teamMessage.length > 0) {

      if(this.taggedObject.teamMessages == undefined || this.taggedObject.teamMessages == null) {
        this.taggedObject.teamMessages = [];
      }

      this.taggedObject.teamMessages.push({
        id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
        senderId: this.navigatorService.getProfileId(),
        message: this.teamMessage,
        date: new Date()
      });

      this.navigatorService.getAsyncCRMDeal(this.taggedObject.id).then( async (deal: CRMDeal) => {

        if(deal != undefined && deal != null) {

          deal.teamMessages = this.taggedObject.teamMessages;

          await this.navigatorService.upsertCRMDeal(deal).then( (status: boolean) => {

            if(status) {
              this.teamMessage = null;
            }

          });

        }

      });

      this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;

    }

  }

  public async sendMessage(): Promise<void> {

    if(this.textMessage.length > 0) {

      if(this.taggedObject.b2cMessages == undefined || this.taggedObject.b2cMessages == null) {
        this.taggedObject.b2cMessages = [];
      }

      this.taggedObject.b2cMessages.push({
        id: this.navigatorService.generateKey(this.keyTypes.MESSAGE_ID),
        senderId: this.navigatorService.getProfileId(),
        message: this.textMessage,
        date: new Date()
      });

      if(!this.customerViewer) {
        this.navigatorService.getAsyncCRMDeal(this.taggedObject.id).then( async (deal: CRMDeal) => {

          if(deal != undefined && deal != null) {

            deal.b2cMessages = this.taggedObject.b2cMessages;

            await this.navigatorService.upsertCRMDeal(deal).then( (status: boolean) => {

              if(status) {
                this.twilioHandler.sendTextMessage(this.textMessage);
                this.textMessage = null;
              }

            });

          }

        });
      } else {
        // this.navigatorService.getWorkOrderAsForeign(this.companyId, this.workOrderId).then( async (workOrder: WorkOrder) => {

        //   if(workOrder != undefined && workOrder != null) {

        //     workOrder.b2cMessages = this.workOrder.b2cMessages;

        //     await this.navigatorService.updateWorkOrderAsForeign(workOrder).then( (status: boolean) => {

        //       if(status) {
        //         this.twilioHandler.sendTextMessage(this.textMessage);
        //         this.textMessage = null;
        //       }

        //     });

        //   }

        // });
      }

      this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;

    }

  }

}