import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import {FormControl} from '@angular/forms';
import { GroupedObservable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {groups} from './groups/vendor-calendar-groups';

import { VendorAddWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

let activeDate = new Date();

@Component({
  selector: 'app-vendor-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-calendar.component.html',
  styleUrls: ['vendor-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  componentId = "vendorCalendar";

  groupOptionSelected = "admin";
  selectOptions = groups;
  public addWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    // LEFT FOR EXAMPLES DURING DEVELOPMENT
    // DELETE FOR PRODUCTION
    // {
    //   start: new Date(2021, 11, 1, 0,0,0),
    //   end: new Date(2021, 11, 1, 2,0,0),
    //   title: 'A 3 day event',
    //   color: colors.red,
    //   actions: this.actions,
    //   allDay: false,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
    // {
    //   start: startOfDay(new Date()),
    //   title: 'An event with no end date',
    //   color: colors.yellow,
    //   actions: this.actions,
    // },
    // {
    //   start: subDays(endOfMonth(new Date()), 3),
    //   end: addDays(endOfMonth(new Date()), 3),
    //   title: 'A long event that spans 2 months',
    //   color: colors.blue,
    //   allDay: true,
    // },
    // {
    //   start: addHours(startOfDay(new Date()), 2),
    //   end: addHours(new Date(), 2),
    //   title: 'A draggable and resizable event',
    //   color: colors.yellow,
    //   actions: this.actions,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // }
  ];

  activeDayIsOpen: boolean = false;

  constructor(private modal: NgbModal, public dialog: MatDialog) { 
    for(let index = 0; index < groups.length; index++) {
      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events.push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date
        })
      }
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.rebaseBadgeNumbers(this.groupOptionSelected);
    document.querySelector(".calendarSidebarMenu .mat-form-field-underline").classList.add("groupColor");
    document.querySelector(".calendarSidebarMenu .mat-form-field-ripple").classList.add("groupColor");
    document.querySelector(".calendarSidebarMenu .mat-select-arrow").classList.add("groupColorArrow");
  }

  nextMonth(): void {
    activeDate = addMonths(activeDate, 1);
  }

  previousMonth(): void {
    activeDate = addMonths(activeDate, -1);
  }

  rebaseBadgeNumbers(groupTitle: string): void {
    // This is a shitty thing to do and should be fixed. I had to let the entire component and third party library components render before
    // attempting to manipulate DOM objects so a timeout function is used.
    setTimeout(function () {
      let badges = document.querySelectorAll("span.cal-day-badge.ng-star-inserted");
      let group = 0;
      let groupBookmark = 0;
      
      for(let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        if(groupTitle == groups[groupIndex].group.toLowerCase()) {
          group = groups[groupIndex].id;
          break;
        }
      }

      // Bring Back after Push
      // NOTE: Some scenarios cause this to not find anything
      let element:any = document.querySelector(".groupColor");

      // let style:any = window.getComputedStyle(element, '::before');
      // style.setPropertyValue('background-color', "blue");

      if(element) {
        element.style.setProperty('--groupColor', groups[group].color.primary);
      }

      // Hiding all badges except for the currently focused on group. Then manipulating the badge via the DOM and changing its content to reflect
      // the live data value.

      // NOTE: Dates have to be processed in ascending order due to the use of the variable "groupBookmark". This variable helps performance by
      // not requring processing of dates already processed.
        for(let badgeIndex = 0; badgeIndex < badges.length; badgeIndex++) {
          for(let groupDateIndex = groupBookmark; groupDateIndex < groups[group].availability.length; groupDateIndex++) {

            if(isSameMonth(groups[group].availability[groupDateIndex].date, activeDate) && isSameYear(groups[group].availability[groupDateIndex].date, activeDate)) {
              if(badges[badgeIndex].nextElementSibling.textContent == getDate(groups[group].availability[groupDateIndex].date).toString()) {
                if(!badges[badgeIndex].parentElement.parentElement.parentElement.classList.contains("cal-out-month")) {

                  badges[badgeIndex].classList.remove("autoHide");
                  badges[badgeIndex].innerHTML = groups[group].availability[groupDateIndex].startTimes.length.toString();
                  groupBookmark = groupDateIndex;

                  break;
                  
                } else {
                  badges[badgeIndex].classList.add("autoHide");
                }
              } else {
                badges[badgeIndex].classList.add("autoHide");
              }
            } else {
              badges[badgeIndex].classList.add("autoHide");
            }
          }
        }
    }, 0);
  }

  setGroupFilter(group: string): void {
    this.groupOptionSelected = group;
    this.rebaseBadgeNumbers(this.groupOptionSelected);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();
  }

  // eventTimesChanged({
  //   event,
  //   newStart,
  //   newEnd,
  // }: CalendarEventTimesChangedEvent): void {
  //   this.handleEvent('Dropped or resized', event);

  //   this.events = this.events.map((iEvent) => {
  //     if (iEvent === event) {
  //       return {
  //         ...event,
  //         start: newStart,
  //         end: newEnd,
  //       };
  //     }

  //     return iEvent;
  //   });
    
  // }

  handleEvent(action: string, event: CalendarEvent): void {
    this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(date: Date): void {
    let startDate = new Date(date);
    let endDate = new Date(date);
    endDate.setHours(endDate.getHours() + 1);

    this.events.push(
      {
        title: 'Admin',
        start: startDate,
        end: endDate,
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    )
  }

  contextMenuAddEvent(date: Date): void {
    // this.events = [];

    this.addEvent(date);
    this.openAddWorkOrderDialog(date);

    this.refresh.next();
  }

  // LEFT FOR EXAMPLES DURING DEVELOPMENT
  // DELETE FOR PRODUCTION
  // contextMenuAddEvent(date: Date): void {
  //   this.events.push({
  //     start: date,
  //     title: 'New event',
  //     color: colors.red,
  //   });
  //   this.refresh.next();
  // }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
      this.rebaseBadgeNumbers(this.groupOptionSelected);
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
      this.rebaseBadgeNumbers(this.groupOptionSelected);
    }

    this.activeDayIsOpen = false;
  }

  public openAddWorkOrderDialog(data: any) {
    this.addWorkOrderDialogRef = this.dialog.open(
      VendorAddWorkOrderDialogComponent,
      {
        data: {
          date: data
        }
      }
      );

    this.addWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }
}
