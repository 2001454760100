import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-vendor-form-create-document-dialog.component',
    templateUrl: 'vendor-form-create-document-dialog.component.html',
    styleUrls: ['vendor-form-create-document-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorFormCreateDocumentDialogComponent implements OnInit {
    
    constructor(public matDialogRef: MatDialogRef<VendorFormCreateDocumentDialogComponent>) {

    }
  
    ngOnInit() {

    }

    close() {
      this.matDialogRef.close();
    }

  }