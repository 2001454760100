import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, Inject  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { InventoryItem, PartAttachBundle, Profile, ScheduleSwap } from '../../../data-models/models';

import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-vendor-part-attacher-dialog.component',
    templateUrl: 'vendor-part-attacher-dialog.component.html',
    styleUrls: ['vendor-part-attacher-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorPartAttacherDialogComponent implements OnInit {

    public isPartRequest: boolean = false;
    public isDetailView: boolean = false;
    public detailViewFocusBarcode: string = "";
    public attachedParts: Array<PartAttachBundle> = [];
    public workOrderId: string = "";
    public projectId: string = "";

    constructor(
      @Inject(MAT_DIALOG_DATA) public incomingData: any,
      private navigatorService: NavigatorService, 
      private fb: FormBuilder,
      public datePipe: DatePipe,
      public dialogRef: MatDialogRef<VendorPartAttacherDialogComponent>) {

    }
  
    ngOnInit() {

      if(this.incomingData.isPartOrder == null && this.incomingData.isPartOrder == undefined) {

        if(this.incomingData.hasOwnProperty('workOrderId')) {
          this.workOrderId = this.incomingData.workOrderId;
        }

        else if(this.incomingData.hasOwnProperty('projectId')) {
          this.projectId = this.incomingData.projectId;
        }

      } else {
        this.workOrderId = null;
        this.isPartRequest = this.incomingData.isPartOrder;

        console.log("Part Attacher: ", this.isPartRequest);
      }

      this.dialogRef.beforeClosed().subscribe(data => {
        this.close();
      });
      
    }
  
    submit() {
    }

    public reset(): void {

    }

    public attachPart(partBundle: PartAttachBundle) {

      if(partBundle != null && partBundle != undefined) {
        console.log("Attach Part: ", partBundle);

        this.attachedParts.push(partBundle);
      }
    }

    public openDetailView(barcode: string): void {
      this.detailViewFocusBarcode = barcode;
      this.isDetailView = true;

      console.log("Barcode: ", this.detailViewFocusBarcode);
    }

    public closeDetailView(): void {
      this.isDetailView = false;
      this.detailViewFocusBarcode = "";
    }

    public close() {
      this.dialogRef.close(this.attachedParts);
    }
  }