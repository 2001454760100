import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafeUrl } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { InventoryItem, PartAttachBundle, PartAttachDetailBundle } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { VendorPartAttacherDialogComponent } from '../../professional/vendor-part-attacher-dialog/vendor-part-attacher-dialog.component';

export interface AttachedPartBundles {
  parts: Array<PartAttachDetailBundle>,
  imgIds: Array<string>
}

@Component({
  selector: 'app-vendor-part-attachment',
  templateUrl: 'vendor-part-attachment.component.html',
  styleUrls: ['vendor-part-attachment.component.scss']
})
export class VendorPartAttachmentComponent implements OnInit {

  attachInventoryItemRef: MatDialogRef<VendorPartAttacherDialogComponent>;
  @Output() partsListEmitter = new EventEmitter<Array<PartAttachBundle>>();
  @Input() attachedPartsBundle: Array<PartAttachBundle> = [];
  @Input() workOrderId: string = "";
  @Input() projectId: string = "";

  public attachedParts: Array<PartAttachDetailBundle> = [];
  public partImgs: Array<SafeUrl> = [];
  
  private imgIds: Array<string> = [];
  private keyTypes:any = keyTypesEnum;
  private imageDeleteAction: boolean = false;

  public isAttachMenuOpen: boolean = false;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, public navigatorService: NavigatorService, private imageHandler: ImageHandlerService) { }

  ngOnInit() {
    if(this.attachedPartsBundle == null || this.attachedPartsBundle == undefined) {
      this.attachedPartsBundle = [];
    }

    this.initializeAttachedParts().then( (bundle: AttachedPartBundles) => {

      if(bundle != undefined && bundle != null) {

        this.attachedParts = bundle.parts;
        this.imgIds = bundle.imgIds;
        this.getAllImgs();
      }

    });
  }

  ngOnDestroy() {

  }  

  ngOnChanges(changes: SimpleChanges | any) {

    if(changes.attachedPartsBundle != undefined && changes.attachedPartsBundle != null) {

      this.attachedPartsBundle = [];
      this.attachedPartsBundle = changes.attachedPartsBundle.currentValue;

      if(this.attachedPartsBundle == null || this.attachedPartsBundle == undefined) {
        this.attachedPartsBundle = [];
      }

      this.initializeAttachedParts().then( (bundle: AttachedPartBundles) => {

        if(bundle != undefined && bundle != null) {

          this.attachedParts = bundle.parts;
          this.imgIds = bundle.imgIds;
          this.getAllImgs();
        }

      });

    }

  }

  private async initializeAttachedParts(): Promise<AttachedPartBundles> {

    this.attachedParts = [];
    this.imgIds = [];

    let bundle: AttachedPartBundles = {
      parts: [],
      imgIds: []
    }

    for(let bundleIndex: number = 0; bundleIndex < this.attachedPartsBundle.length; bundleIndex++) {

      await this.navigatorService.getAsyncSelectInventory(this.attachedPartsBundle[bundleIndex].partBarcode).then(data => {
          
        if(data != null && data != undefined) {
          let inventoryItem: InventoryItem = data[0];
          let partAttachDetailBundle: PartAttachDetailBundle = {
            part: inventoryItem,
            quantity: this.attachedPartsBundle[bundleIndex].quantity
          }

          bundle.parts.push(partAttachDetailBundle);
          bundle.imgIds.push(inventoryItem.lockedFields.gallery[0]);
        }

        
      });

    }

    return bundle;
  }

  attachPart(): void {
    this.openPartAttacherDialog();
  }

  removePart(part: InventoryItem, quantity: number): void {

    if(part != null && part != undefined) {
      part.lockedFields.quantity += quantity;

      this.navigatorService.upsertInventory(part);
      this.navigatorService.upsertInventoryBase(part);
    }
  }

  public openPartAttacherDialog() {
    this.attachInventoryItemRef = this.dialog.open(
        VendorPartAttacherDialogComponent,
        { data: this.workOrderId }
      );

    this.attachInventoryItemRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe( async (parts: Array<PartAttachBundle>) => {
      console.log("Part Attacher Dialog Closed: ", parts);

      for(let part of parts) {
        this.attachedPartsBundle.push(part);

        await this.navigatorService.getAsyncSelectInventory(part.partBarcode).then(data => {
          
          if(data != null && data != undefined) {
            let inventoryItem: InventoryItem = data[0];
            let partAttachDetailBundle: PartAttachDetailBundle = {
              part: inventoryItem,
              quantity: part.quantity
            }

            this.attachedParts.push(partAttachDetailBundle);
          }
          
        });

      }

      this.partsListEmitter.emit(this.attachedPartsBundle);

    })
  }

  public getImg(imgIndex: number): void {
    if(this.imgIds[imgIndex] != undefined) {
      this.imageHandler.getFile(this.imgIds[imgIndex]);
    }
  }

  public async getAllImgs(): Promise<void> {
    this.partImgs = [];

    this.imageHandler.getAsyncFiles(this.imgIds).then(imgs => {
      // this.gallery[]

      if(imgs) {
        this.partImgs = imgs;
      }

    });
  }

  public uploadImages(images: Array<any>): Array<string> {

    if(images != null && images != undefined && images.length > 0) {

      for(let imageIndex: number = 0; imageIndex < images.length; imageIndex++) {
        let file: File = images[imageIndex].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imgIds.push(imgIdUnique);

        if(imageIndex == images.length) {
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
            this.getAllImgs();
          });
        } else {
          this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

          });
        }

      }
    }

    return this.imgIds;
  }

  public deleteImg(imgIndex: number): void {
    this.partImgs.splice(imgIndex, 1);
    this.imgIds.splice(imgIndex, 1);
    this.imageDeleteAction = true;
  }

  openAttachMenu(): void {
    this.isAttachMenuOpen = true;
  }

  closeAttachMenu(): void {
    this.isAttachMenuOpen = false;
  }
}