import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, Inject  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { Shift, Color } from '../../../data-models/models';


@Component({
    selector: 'custom-shift-dialog',
    templateUrl: 'vendor-custom-shift-dialog.html',
    styleUrls: ['vendor-custom-shift-dialog.scss']
  })

  export class CustomShiftDialogComponent {

    id: string = "";
    backgroundColor: string;
    borderColor: string;
    title: string;
    startTime: string;
    endTime: string;
    textColor: string;

    constructor(
      private navigatorService: NavigatorService,
      private matDialogRef: MatDialogRef<CustomShiftDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public customShiftData: any) {

    }
  
    ngOnInit() {
      // this.form = this.formBuilder.group({
      //   filename: ''
      // })

      if(this.customShiftData) {
        this.id = this.customShiftData.editShift.id;
        this.backgroundColor = this.customShiftData.editShift.color.primary;
        this.borderColor = this.customShiftData.editShift.color.secondary;
        this.title = this.customShiftData.editShift.title;
        this.startTime = this.customShiftData.editShift.startTime;
        this.endTime = this.customShiftData.editShift.endTime;

      } else {
        this.backgroundColor = "#ffffff";
        this.borderColor = "#000000";
        this.title = "";
        this.textColor;
      }
    }

    titleChange(value: string): void {
      this.title = value;
    }

    getTime(timePicker: any, isStartTime: boolean): string {
      let time: string;

      if(isStartTime) {
        if(timePicker && timePicker.defaultTime) {
          this.startTime = timePicker.defaultTime;
        }

        time = this.startTime;
      } else {
          if(timePicker && timePicker.defaultTime) {
            this.endTime = timePicker.defaultTime;
          }
  
          time = this.endTime;
      }
      
      return time;
    }
  
    submit() {
      // let shiftTitleElement: any = document.getElementById("shiftTitle");
      // let shiftTitle: any = shiftTitleElement.value;
      let keyTypes: any = this.navigatorService.getKeyTypes();
      let id: string;

      if(this.id.length == 0) {
        id = this.navigatorService.generateKey(keyTypes.SHIFT_ID);
      } else {
        id = this.id;
      }

      let colorObject: Color = {
            primary: this.backgroundColor,
            secondary: this.borderColor
      };


      let shiftTemplate: Shift = {
        
          id: id,
          title: this.title,
          startTime: this.startTime,
          endTime: this.endTime, 
          color: colorObject
      };

      // this.navigatorService.addNewTimeShift(timeTemplate);

      this.matDialogRef.close(shiftTemplate);
    }

    getContrast(hexcolor){

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
    
      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }
    
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
    
      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    
    }
  }