import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords, emailValidator } from 'src/app/theme/utils/app-validators';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Address, CallLog, CompanyProfile, Contact } from 'src/app/data-models/models';
import { Subscription } from 'rxjs';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { CallLogStatusEnum, TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-vendor-recent-call-log',
  templateUrl: 'vendor-recent-call-log.component.html',
  styleUrls: ['vendor-recent-call-log.component.scss']
})
export class VendorRecentCallLogComponent implements OnInit {
  @Output('contactUpdate') contactUpdateEmitter: EventEmitter<any> = new EventEmitter<any>();

  public callLogStatusCategories: any = CallLogStatusEnum;
  public callLog: Array<CallLog> = [ ];

  public contactFocus: Contact | null = null;
  public rightPanelExpanded: boolean = false;
  public leftPanelExpanded: boolean = false;

  public contactForm: FormGroup;
  public detailsExpanded: boolean = false;
  private keyTypes:any = keyTypesEnum;

  public callInProgress:boolean = false;

  private imgSubscription: Subscription;
  private callInProgressSubscription: Subscription;
  private callIncomingSubscription: Subscription;
  private callOutboundSubscription: Subscription;

  public imgId: string = null;
  public image: any;
  public imageDeleteAction: boolean = false;

  public lockedMode:boolean = true;

  constructor(
    public formBuilder: FormBuilder, 
    public router:Router, 
    public snackBar: MatSnackBar, 
    public navigatorService: NavigatorService, 
    private twilioHandler: TwilioHandlerService,
    private imageHandler: ImageHandlerService) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      first_name: [ {value: '', disabled: this.lockedMode}, Validators.compose([Validators.required, Validators.minLength(3)])],
      last_name: [ {value: '', disabled: this.lockedMode}],

      company_name: [ {value: '', disabled: this.lockedMode}],
      email: [ {value: '', disabled: this.lockedMode}],
      phone: [ {value: '', disabled: this.lockedMode}, Validators.required],
  
      facebook: [{value: '', disabled: this.lockedMode}],
      twitter: [{value: '', disabled: this.lockedMode}],
      linkedin: [{value: '', disabled: this.lockedMode}],
      instagram: [{value: '', disabled: this.lockedMode}],
      website: [{value: '', disabled: this.lockedMode}],

      street: [ {value: '', disabled: this.lockedMode}],
      city: [ {value: '', disabled: this.lockedMode}],
      state: [ {value: '', disabled: this.lockedMode}],
      zip: [ {value: '', disabled: this.lockedMode}]
    });

    // this.initializeForm();

    this.callInProgress = this.twilioHandler.getIncomingCall();
    
    if(!this.callInProgress) {
      this.callInProgress = this.twilioHandler.getCallInitiated();
    }

    this.initializeContactList();
    this.listeners();

  }

  ngOnDestroy() {

    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }

    if(this.callInProgressSubscription) {
      this.callInProgressSubscription.unsubscribe();
    }

    if(this.callIncomingSubscription) {
      this.callIncomingSubscription.unsubscribe();
    }

    if(this.callOutboundSubscription) {
      this.callOutboundSubscription.unsubscribe();
    }

  }  

  private listeners(): void {

    this.callIncomingSubscription = this.twilioHandler.getIncomingCallTrigger().subscribe( inboundCall => {
      this.callInProgress = inboundCall;
    });

    this.callOutboundSubscription = this.twilioHandler.getOutboundCallTrigger().subscribe( outboundCall => {
      this.callInProgress = outboundCall;
    });

    this.callInProgressSubscription = this.twilioHandler.getCallInitiatedTrigger().subscribe( initiated => {
      this.callInProgress = initiated;
    });

  }

  public initializeContactList(): void {

    this.navigatorService.getCallLog().then( (log: Array<CallLog>) => {

      if(log != undefined && log != null) {
        
        this.callLog = [];
        this.callLog = log;

      }

    });

  }

  public updateContactList(): void {
    this.initializeContactList();
  }

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {

      if(img) {
        this.image = img;

        console.log("Image: ", this.image);
      }

    });
    
  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getFile(this.imgId);
    }
  }

  public uploadImage(image: any, folder: string): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique, folder).then(data => {
          this.imgId = imgIdUnique;
          this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
  }

  public deleteImg(): void {
    this.contactForm.controls["image"].setValue("");
    this.image = null;
    this.imageDeleteAction = true;
  }

  public expandRightPanelToggle(contact: Contact | null): void {
    this.contactFocus = contact;
    this.rightPanelExpanded = true;
  }

  public collapseRightPanelToggle(): void {
    this.rightPanelExpanded = false;
  }

  public expandLeftPanelToggle(): void {
    this.rightPanelExpanded = true;
  }

  public collapseLeftPanelToggle(): void {
    this.rightPanelExpanded = false;
  }


  public editToggle(): void {
    if(this.lockedMode) {
      this.lockedMode = false;
    } else {
      this.lockedMode = true;
    }

    // this.initializeForm();
  }

  public makeOutboundCall(): void {

    let phone: string = this.contactForm.get("phone").value;

    if(phone.length != 10) {
      this.snackBar.open('Call Connection Refusal: A 10 digit number is required to initiate a phone call.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.twilioHandler.setPhoneNumber(phone);
    this.twilioHandler.makeOutboundCall(phone);
  }

  public disconnectOutboundCall(): void {
    this.twilioHandler.disconnectCall()
  }

  public onRegisterFormSubmit():void {
    if (this.contactForm.valid) {
      let companyId: string = this.navigatorService.generateKey(this.keyTypes.COMPANY_ID);

      let address: Address = {
        street: this.contactForm.get("street").value,
        city: this.contactForm.get("city").value,
        state: this.contactForm.get("state").value,
        zip: this.contactForm.get("zip").value
      }

      let contact: Contact = {
        id: companyId,

        firstName: this.contactForm.get("first_name").value,
        lastName: this.contactForm.get("last_name").value,

        companyName: this.contactForm.get("company_name").value,

        email: this.contactForm.get("email").value,
        phone: this.contactForm.get("phone").value,
        address: address,
        linkedin: this.contactForm.get("linkedin").value,
        twitter: this.contactForm.get("twitter").value,
        instagram: this.contactForm.get("instagram").value,
        facebook: this.contactForm.get("facebook").value,
        website: this.contactForm.get("website").value,

        createdBy: this.navigatorService.getProfileId(),
        createdDate: new Date
      }

      this.navigatorService.upsertContact(contact).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Contact Updated!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.contactUpdateEmitter.emit();
        }

      });

    }
  }

}
