import { Component, OnInit, Input, ViewChild, SimpleChange, EventEmitter, Output } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper'; 
import { Property } from '../../app.models';
import { Settings, AppSettings } from '../../app.settings';
import { Observable, Subscription } from 'rxjs'; 

import { AppService } from '../../app.service'; 
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component'; 
import { AssetItem, CMMSLocation, InventoryItem, InventoryReservationListItem, PartAttachBundle, Profile } from 'src/app/data-models/models';

import { ImageHandlerService } from '../../services/vendor/image-handler.service';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendorWorkOrderDialogComponent } from '../professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.scss'] 
})
export class InventoryItemComponent implements OnInit {
  @Input() item: InventoryItem | any;
  @Input() itemImg: SafeUrl;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;   
  @Input() pickerView: boolean = false;   
  @Input() isPartAttacher: boolean = false;
  @Input() isPartRequest: boolean = false;
  @Input() workOrderId: string = "";
  @Input() projectId: string = "";

  @Output() partAttacherDetailEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() partAttacherAttachEmitter: EventEmitter<PartAttachBundle> = new EventEmitter<PartAttachBundle>();

  workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  
  private keyTypes: any = keyTypesEnum;

  public isPartReturnMenuOpen: boolean = false;
  public isReservationMenuOpen: boolean = false;
  public isReserveListOpen: boolean = false;
  public reservationList: Array<any> = [];
  
  public partAttachForm: FormGroup;
  public partAttacherHandlingOption: string = "Picked Up";
  
  public isAttachMode: boolean = false;
  public column:number = 4;
  // public address:string; 
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  public settings: Settings;
  public maxDisplayedFields: number = 4;
  public quanityWarning: number = null;

  public gallery: Array<string> = [];
  private imgSubscription: Subscription;


  constructor(
    public dialog: MatDialog,
    public appSettings:AppSettings, 
    public appService:AppService, 
    private navigatorService: NavigatorService,
    private imageHandler: ImageHandlerService, 
    private snackBar: MatSnackBar,
    public fb: FormBuilder) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() { 
    this.setWarningTag();
    this.getReservationProfiles();

    this.partAttachForm = this.fb.group({
      part: [null, Validators.required],
      quantity: [0, Validators.required],
      handlingOption: [0, Validators.required]
    });

  }

  ngAfterViewInit(){
    this.initCarousel();
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  } 

  ngOnDestroy(){ 
    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }
  }
 
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue);
      if(!changes.viewColChanged.isFirstChange()){
        if(this.item.lockedFields.gallery.length > 1){     
          if(this.directiveRef) {
            this.directiveRef.update();  
          }
        } 
      }
    } 

    for (let propName in changes) {      
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.property.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;       
      //     this.directiveRef.update();  
      //   }       
      // }      
    }  

    if(this.item) {
      // this.getImgs();
    }

    // this.getReservationProfiles();
  }

  public getReservationProfiles(): void {
    this.reservationList = [];

    if(this.item.lockedFields.reservationList != null && this.item.lockedFields.reservationList != undefined) {

      console.log("Reservation List: ", this.item.lockedFields.reservationList);
      
      for(let reservationIndex: number = 0; reservationIndex < this.item.lockedFields.reservationList.length; reservationIndex++) {
        this.navigatorService.getEmployee(this.item.lockedFields.reservationList[reservationIndex].originId).then(profile => {

          if(profile != null && profile != undefined) {
            // let reservationIndex: number = this.item.lockedFields.reservationList.findIndex(element => {
            //   return element.originId == profile.id
            // });

            // let quantity: number = null;
            // let workOrderId: string = "";

            // if(reservationIndex != null && reservationIndex != undefined) {
              let quantity: number = this.item.lockedFields.reservationList[reservationIndex].quantity;
              let workOrderId: string = this.item.lockedFields.reservationList[reservationIndex].workOrderId;
            // }

            this.reservationList.push({
              profile: profile,
              quantity: quantity,
              workOrderId
            });
          }

        });
      }
    }

  }

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.item.lockedFields.gallery[0]).subscribe(img => {
      // this.gallery[]

      if(img) {
        this.item.lockedFields.gallery[0] = img;
      }

      // this.imgSubscription.unsubscribe();
    });

  }

  public getImgs(): void {

    this.imgSubscription = this.imageHandler.getFile(this.item.lockedFields.gallery[0]).subscribe(img => {
      // this.gallery[]

      if(img) {
        this.item.lockedFields.gallery[0] = img;
      }

      // this.imgSubscription.unsubscribe();
    });

  }

  public setWarningTag() {
    let quantity: number = this.item.lockedFields.quantity;

    // Out of Stock
    if(quantity == 0) {
      this.quanityWarning = 0;
    } 

    // Critical
    else if(quantity <= 5 && quantity > 0) {
      this.quanityWarning = 1;
    } 

    // Low
    else if(quantity <= 10 && quantity > 5) {
      this.quanityWarning = 2;
    } 

    else {
      this.quanityWarning = null;
    }
    
  }

  public getColumnCount(value){
    if(value == 25) {
      this.column = 4;
    }
    else if(value == 33.3) {
      this.column = 3;
    }
    else if(value == 50) {
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }

  public getStatusBgColor(status){
    switch (status) {
      case 'Urgent':
        return '#558B2F';  
      case 'For Bid':
        return '#1E88E5'; 
      case 'Pending Bid':
        return '#009688';
      case 'Closing Soon':
        return '#FFA000';
      case 'Quote':
        return '#F44336';
      case 'Closed':
        return '#000';
      default: 
        return '#01579B';
    }
  }


  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,  
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: "slide"
    }
  }

  // Old data type was string. There currently isn't a clean platform update setup so two data types are allowed.
  // This requires adjustment.
  translateLocation(locatedAt: CMMSLocation | string): string {
    let locationTitle: string = "";

    if(typeof locatedAt === 'string') {
      return locationTitle;
    } else {
      locationTitle = locatedAt.title;
    }

    return locationTitle
  }

  public openPartAttacher(): void {
    this.isAttachMode = true;
  }

  public closePartAttacher(): void {
    this.isAttachMode = false;
  }

  public attachPartRequest(inventoryItem: InventoryItem): void {
    console.log("Attach Part Request => Inventory Item");
    
    let quantity: number = this.partAttachForm.controls["quantity"].value;

    let partAttachBundle: PartAttachBundle = {
      partBarcode: inventoryItem.lockedFields.barcode,
      quantity: quantity,
      reservation: []
    };

    this.partAttacherAttachEmitter.emit(partAttachBundle);
    this.isAttachMode = false;
  }

  public attachPart(inventoryItem: InventoryItem): void {

    let quantity: number = this.partAttachForm.controls["quantity"].value;
    let handlingOptions: number = this.partAttachForm.controls["handlingOption"].value;
    let reservations: Array<InventoryReservationListItem> = inventoryItem.lockedFields.reservationList;

    if(reservations == null || reservations == undefined) {
      reservations = [];
    }

    if(handlingOptions == 1) {
      reservations.push({
        id: this.navigatorService.generateKey(this.keyTypes.CMMS_RESERVATION_ID),
        workOrderId: this.workOrderId,
        originId: this.navigatorService.getProfileId(),
        originDate: new Date(),
        inventoryBarcode: inventoryItem.lockedFields.barcode,
        quantity: quantity
      });
    }

    let partAttachBundle: PartAttachBundle = {
      partBarcode: inventoryItem.lockedFields.barcode,
      quantity: quantity,
      reservation: reservations
    };

    if(Number(quantity) > Number(inventoryItem.lockedFields.quantity)) {
      this.snackBar.open('You cannot attach a higher quantity than what you have in stock!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });

      return;
    }

    if(inventoryItem != null && inventoryItem != undefined) {
      this.item.lockedFields.quantity = Number(this.item.lockedFields.quantity) - Number(quantity);

      this.setWarningTag();
      this.partAttacherAttachEmitter.emit(partAttachBundle);
    }

    this.isAttachMode = false;
  }

  public returnPart(inventoryItemBundle: PartAttachBundle) {

    if(inventoryItemBundle != null && inventoryItemBundle != undefined) {
      this.item.lockedFields.quantity++;
      this.setWarningTag();
    }
  }
  
  public details(barcode: string){
    // this.appService.addToCompare(this.item, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
    if(barcode != null && barcode != undefined) {
      this.partAttacherDetailEmitter.emit(barcode);
    }
  }

  public openReservationList(): void {
    this.isReserveListOpen = true;
  }

  public hideReservationList(): void {
    this.isReserveListOpen = false;
  }

  public openReservationMenu(menuOption: number) {
    this.partAttachForm.controls["handlingOption"].setValue(menuOption);

    this.isReservationMenuOpen = true;
  }

  public closeReservationMenu() {
    this.partAttachForm.controls["quantity"].reset();
    this.isReservationMenuOpen = false;
  }

  public openPartReturnMenu() {
    this.isPartReturnMenuOpen = true;
  }

  public closePartReturnMenu() {
    this.partAttachForm.controls["quantity"].reset();
    this.isPartReturnMenuOpen = false;
  }

  public partReturn() {
    let quantity: number = this.partAttachForm.controls["quantity"].value;

    if(this.item != null && this.item != undefined && quantity != null && quantity != undefined) {
      this.item.lockedFields.quantity = Number(this.item.lockedFields.quantity) + Number(quantity);

      

      this.navigatorService.upsertInventory(this.item);
      this.navigatorService.upsertInventoryBase(this.item).then(data => {
        this.setWarningTag();
      });
    }

    this.closePartReturnMenu();
  }

  public createPartPullRequest() {

    let quantity: number = this.partAttachForm.controls["quantity"].value;
    let handlingOptions: number = this.partAttachForm.controls["handlingOption"].value;
    let reservations: Array<InventoryReservationListItem> = this.item.lockedFields.reservationList;

    if(reservations == null || reservations == undefined) {
      reservations = [];
    }

    if(handlingOptions == 1) {
      reservations.push({
        id: this.navigatorService.generateKey(this.keyTypes.CMMS_RESERVATION_ID),
        workOrderId: null,
        originId: this.navigatorService.getProfileId(),
        originDate: new Date(),
        inventoryBarcode: this.item.lockedFields.barcode,
        quantity: quantity
      });
    }

    if(Number(quantity) > Number(this.item.lockedFields.quantity)) {
      this.snackBar.open('You cannot pull a higher quantity than what you have in stock!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });

      return;
    }

    if(this.item != null && this.item != undefined) {
      this.item.lockedFields.quantity = Number(this.item.lockedFields.quantity) - Number(quantity);
      this.item.lockedFields.reservationList = reservations;

      this.setWarningTag();

      this.navigatorService.upsertInventory(this.item);
      this.navigatorService.upsertInventoryBase(this.item).then(data => {
        this.getReservationProfiles();
      });
    }

    this.closeReservationMenu();
  }

  pickupReservation(quantity: number, reservationIndex: number): void {
    this.deleteReservationRequest(quantity, reservationIndex);
  }

  public deleteReservationRequest(quantity: number, reservationIndex: number): void {
    if(quantity != null && quantity != undefined) {
      this.item.lockedFields.quantity = Number(this.item.lockedFields.quantity) + Number(quantity);
      this.reservationList.splice(reservationIndex, 1);
      this.item.lockedFields.reservationList.splice(reservationIndex, 1);

      this.navigatorService.upsertInventory(this.item);
      this.navigatorService.upsertInventoryBase(this.item);
    
    }
  }

  public addToCompare(){
    // this.appService.addToCompare(this.item, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
    // return this.appService.Data.compareList.filter(item=>item.id == this.item.id)[0];
  }

  public addToFavorites(){
    // this.appService.addToFavorites(this.item, (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
    // return this.appService.Data.favorites.filter(item=>item.id == this.item.id)[0];
  }

  public openWorkOrderDialog(workOrderId: string) {

    if(workOrderId != null && workOrderId != undefined && workOrderId.length > 0) {
      this.navigatorService.getWorkOrder(workOrderId).then(data => {

        if(data != null && data != undefined) {
          this.workOrderDialogRef = this.dialog.open(
            VendorWorkOrderDialogComponent,
            { data: data }
          );

          this.workOrderDialogRef.beforeClosed().subscribe(data => {
            console.log("Before Closed: ", data);
          })
          
          this.workOrderDialogRef.afterClosed().pipe(
            filter(data => data)
          ).subscribe(data => {
            console.log("Dialog Closed: ", data);

          })

        }
      });

    }

  }


}
