import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject, SimpleChanges  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { isThisMinute } from 'date-fns';
import { is } from 'date-fns/locale';
import { Subscription } from 'rxjs/internal/Subscription';
import { ImgPanelStepTrackedDetails, ImgPanelTrackedDetails, InfoPanel, InfoPanelImgSlide, InfoPanelStep, InfoPanelStepTrackedDetails, InfoPanelTrackedDetails, Profile } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { TwilioHandlerService, VerificationStatusCodes } from 'src/app/services/vendor/twilio-handler.service';


// VERIFYING = 0,
// INCORRECT_CODE = 1,
// EXPIRED_CODE = 2,
// SUCCESS = 3,
// SERVER_ERROR = 4

@Component({
    selector: 'app-email-verified.component',
    templateUrl: 'email-verified.component.html',
    styleUrls: ['email-verified.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class EmailVerifiedComponent implements OnInit {
    public verificationStatusCodes: any = VerificationStatusCodes;

    private emailVerifiedSubscription: Subscription;

    private userId: string = "";
    private email: string = "";
    private code: string = "";

    private codeLength: number = 6;

    public verifiedCategory: number = this.verificationStatusCodes.VERIFYING;
    private IP: any = null;
    
    constructor(
      private twilioHandlerService: TwilioHandlerService,
      private activatedRoute: ActivatedRoute) {
        
    }
  
    ngOnInit() {
      this.initializeParameters();
      this.registerListeners();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy() {
      this.unregisterListeners();
    } 

    private registerListeners(): void {

      this.emailVerifiedSubscription = this.twilioHandlerService.getEmailVerifiedListener().subscribe( (verificationCode: number) => {

        switch(verificationCode) {

          case this.verificationStatusCodes.VERIFYING:
            this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
            break;
          
          case this.verificationStatusCodes.INCORRECT_CODE:
            this.verifiedCategory = this.verificationStatusCodes.INCORRECT_CODE;
            break;

          case this.verificationStatusCodes.EXPIRED_CODE:
            this.verifiedCategory = this.verificationStatusCodes.EXPIRED_CODE;
            break;

          case this.verificationStatusCodes.VERIFIED:
            this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
            break;

          case this.verificationStatusCodes.SERVER_ERROR:
            this.verifiedCategory = this.verificationStatusCodes.SERVER_ERROR;
            break;

        }

      });

    }

    private unregisterListeners(): void {
  
      if(this.emailVerifiedSubscription) {
        this.emailVerifiedSubscription.unsubscribe();
      }

    } 

    private initializeParameters(): void {

      this.activatedRoute.params.subscribe(params => { 
        this.userId = params['user_id']; 
        this.email = params['email']; 
        this.code = params['code']; 
  
        this.twilioHandlerService.emailCodeVerification(this.userId, this.email, this.code);
        
      });

    }

  }