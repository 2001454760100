import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject, SimpleChanges  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isThisMinute } from 'date-fns';
import { is } from 'date-fns/locale';
import { Subscription } from 'rxjs/internal/Subscription';
import { ImgPanelStepTrackedDetails, ImgPanelTrackedDetails, InfoPanel, InfoPanelImgSlide, InfoPanelStep, InfoPanelStepTrackedDetails, InfoPanelTrackedDetails, Profile, UserDataSync } from 'src/app/data-models/models';
import { HeartBeatService } from 'src/app/services/vendor/heartbeat.service';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { TwilioHandlerService, VerificationStatusCodes } from 'src/app/services/vendor/twilio-handler.service';




@Component({
    selector: 'app-verification-dialog.component',
    templateUrl: 'verification-dialog.component.html',
    styleUrls: ['verification-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

export class VerificationDialogComponent implements OnInit {
  public verificationPanelCategories: any = this.twilioHandlerService.getVerificationPanelCategories();
  public verificationStatusCodes: any = VerificationStatusCodes;
  public verifiedCategory: number = null;

  private phoneVerifiedSubscription: Subscription;
  private emailVerifiedSubscription: Subscription;
  private userHeartbeatSubscription: Subscription;

  public dialogTitle: string = "";

  public panelStep: InfoPanelStep = null;
  private codeLength: number = 6;

  private infoPanelTrackedReturn: InfoPanelTrackedDetails = null;

  private isConfirmed: boolean = false;
  private IP: any = null;
  
  constructor(
    private navigatorService: NavigatorService, 
    private twilioHandlerService: TwilioHandlerService,
    private heartbeat: HeartBeatService,
    private snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<VerificationDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public verificationPanel: number) {

  }

  ngOnInit() {
    this.matDialogRef.disableClose = true;

    if(this.verificationPanel != undefined && this.verificationPanel != null) {

      if(this.verificationPanel == this.verificationPanelCategories.PHONE) {
        this.dialogTitle = "Verify Phone";
        this.sendPhoneVerification();
      }

      else if(this.verificationPanel == this.verificationPanelCategories.EMAIL) {
        this.dialogTitle = "Verify Email";
        this.sendEmailVerification();
      }

      this.registerListeners();

    }
  }

  ngOnChanges(changes: SimpleChanges): void {


  }

  ngOnDestroy() {
    this.unregisterListeners();
  } 

  private registerListeners(): void {

    this.phoneVerifiedSubscription = this.twilioHandlerService.getPhoneVerifiedListener().subscribe( (verificationCode: number) => {

      switch(verificationCode) {

        case this.verificationStatusCodes.VERIFYING:
          this.verifiedCategory = this.verificationStatusCodes.VERIFYING;
          break;
        
        case this.verificationStatusCodes.INCORRECT_CODE:
          this.verifiedCategory = this.verificationStatusCodes.INCORRECT_CODE;
          break;

        case this.verificationStatusCodes.EXPIRED_CODE:
          this.verifiedCategory = this.verificationStatusCodes.EXPIRED_CODE;
          break;

        case this.verificationStatusCodes.VERIFIED:
          this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
          break;

        case this.verificationStatusCodes.SERVER_ERROR:
          this.verifiedCategory = this.verificationStatusCodes.SERVER_ERROR;
          break;

      }

      if(this.verifiedCategory == this.verificationStatusCodes.VERIFIED) {
        this.close(new Date);
      }

    });

    this.emailVerifiedSubscription = this.twilioHandlerService.getEmailVerifiedListener().subscribe( (verificationCode: number) => {

      console.log("Email Verified: ", verificationCode);

      switch(verificationCode) {

        case this.verificationStatusCodes.VERIFYING:
          this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
          break;
        
        case this.verificationStatusCodes.INCORRECT_CODE:
          this.verifiedCategory = this.verificationStatusCodes.INCORRECT_CODE;
          break;

        case this.verificationStatusCodes.EXPIRED_CODE:
          this.verifiedCategory = this.verificationStatusCodes.EXPIRED_CODE;
          break;

        case this.verificationStatusCodes.VERIFIED:
          this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
          break;

        case this.verificationStatusCodes.SERVER_ERROR:
          this.verifiedCategory = this.verificationStatusCodes.SERVER_ERROR;
          break;

      }

      if(this.verifiedCategory == this.verificationStatusCodes.VERIFIED) {
        this.close(new Date);
      }

    });

    this.userHeartbeatSubscription = this.heartbeat.getUserDataSyncTrigger().subscribe( (dataSync: UserDataSync) => {

      if(dataSync != undefined && dataSync != null) {

          if(dataSync.categories.email_verified != undefined && dataSync.categories.email_verified != null) {
              this.close(new Date);
          }

      }

    });

  }

  private unregisterListeners(): void {
    if(this.phoneVerifiedSubscription) {
      this.phoneVerifiedSubscription.unsubscribe();
    }

    if(this.emailVerifiedSubscription) {
      this.emailVerifiedSubscription.unsubscribe();
    }

    if(this.userHeartbeatSubscription) {
      this.userHeartbeatSubscription.unsubscribe();
    }
  }

  sendPhoneVerification(): void {
    
    let profile: Profile = this.navigatorService.getCurrentProfile();

    this.twilioHandlerService.sendPhoneVerification(profile.id, profile.phone);

  }

  resendPhoneVerification(): void {
    
    this.sendPhoneVerification();
    this.snackBar.open('Resent A New Verification Code, Please Check Your Phone.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

  }

  sendEmailVerification(): void {
    
    let profile: Profile = this.navigatorService.getCurrentProfile();    
    this.twilioHandlerService.sendEmailVerification(profile.id, profile.email);

  }

  resendEmailVerification(): void {
    
    this.sendEmailVerification();
    this.snackBar.open('Resent A New Verification Code, Please Check Your Email.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

  }

  codeInput(code: string): void {
    
    if(code.length == this.codeLength) {
      let profile: Profile = this.navigatorService.getCurrentProfile();
      
      this.twilioHandlerService.phoneCodeVerification(profile.id, profile.phone, code);
    }

  }

  submitCode(code: string): void {
    console.log("Code: ", code);

    let profile: Profile = this.navigatorService.getCurrentProfile();

    let id: string = profile.id;
    let email: string = profile.email;

    this.verifiedCategory = this.verificationStatusCodes.VERIFYING;

    this.twilioHandlerService.asyncEmailCodeVerification(id, email, code).then( (status: number) => {

      console.log("Verification Status Code: ", status);

      switch(status) {

        case this.verificationStatusCodes.VERIFYING:
          this.verifiedCategory = this.verificationStatusCodes.VERIFYING;
          break;
        
        case this.verificationStatusCodes.INCORRECT_CODE:
          this.verifiedCategory = this.verificationStatusCodes.INCORRECT_CODE;
          this.snackBar.open('The code you entered was incorrect, please ensure you typed it correctly. If you\'re still having problems then resend a new code and repeat the verification process.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          break;

        case this.verificationStatusCodes.EXPIRED_CODE:
          this.verifiedCategory = this.verificationStatusCodes.EXPIRED_CODE;
          this.snackBar.open('The code you entered was expired. Please resend a new code and repeat the verification process.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          break;

        case this.verificationStatusCodes.VERIFIED:
          this.verifiedCategory = this.verificationStatusCodes.VERIFIED;
          this.snackBar.open('Your email has been verified, congratulations!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          break;

        case this.verificationStatusCodes.SERVER_ERROR:
          this.verifiedCategory = this.verificationStatusCodes.SERVER_ERROR;
          this.snackBar.open('There was an issue processing your request, please try again later!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          break;

      }

      if(this.verifiedCategory == this.verificationStatusCodes.VERIFIED) {
        this.close(new Date);
      }

    });

  }

  nextStep(): void {

  }

  previousStep(): void {

  }

  close(verificationDate): void {
    // this.infoPanelTrackedReturn.IP = this.IP;

    // switch(this.infoPanel.category) {
    //   case this.infoPanelCategories.INFORMATIONAL:
    //     this.closeInformational();
    //     break;

    //   case this.infoPanelCategories.CONFIRM:
    //     this.closeConfirm();
    //     break;

    //   default:
    //     this.matDialogRef.close();
    //     break;

    // }

    this.unregisterListeners();
    this.matDialogRef.close(verificationDate);
  }

  private closeInformational(): void {
    this.matDialogRef.close(this.infoPanelTrackedReturn);
  }

  private closeConfirm(): void {
    this.matDialogRef.close(this.isConfirmed);
  }

}