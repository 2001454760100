import {Component, OnInit, ViewChild, AfterViewInit, Input, Output  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigatorService, keyTypesEnum } from '../../../services/vendor/navigator.service';
import { Address, CMMSLocation, CMMSLocationType, DialogNewInventory, InventoryItem } from '../../../data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';


@Component({
    selector: 'vendor-add-cmms-location-dialog',
    templateUrl: 'vendor-add-cmms-location-dialog.component.html',
    styleUrls: ['vendor-add-cmms-location-dialog.component.scss']
  })

  export class VendorAddCMMSLocationDialogComponent implements OnInit {
    @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
    public submitForm:FormGroup; 
    backgroundColor: string;
    borderColor: string;
    textColor: string;

    public isCMMSLocationCompleted: boolean = false;
    public inventory: Array<InventoryItem> = [];
    public locationTypes: Array<CMMSLocationType> = [];
    public isInitialAdd: boolean = true;
    private keyTypes: any = keyTypesEnum;


    constructor(
      private navigatorService: NavigatorService, 
      private imageHandlerService: ImageHandlerService,
      private fb: FormBuilder,
      public matDialogRef: MatDialogRef<VendorAddCMMSLocationDialogComponent>) {

    }
  
    ngOnInit() {
      this.submitForm = this.fb.group({
        basic: this.fb.group({
          originatorId: [ this.navigatorService.getProfileId(), Validators.required ],
          originationDate: [ new Date, Validators.required ],
          title: [ null, Validators.required ],
          desc: "",
          street: [ null, Validators.required ],
          city: [ null, Validators.required ],
          zip: [ null, Validators.required ],
          state: [ null, Validators.required ],
          type: [ 0, Validators.required ],
          images: [[]]
        })
      }); 

      // this.inventory = this.navigatorService.getLocalTrackedInventory();

      // USE FOR PRODUCTION
      // this.navigatorService.getLocalTrackedFields().subscribe(data => { 
      //   if(data) {
      //     this.inventory = data; 
      //   }
      // });

      this.locationTypes = this.navigatorService.getCMMSLocationTypes();
      this.matDialogRef.disableClose = true; //disable default close operation
    }
  
    submit() {

      if(this.isCMMSLocationCompleted) {

        let address: Address = {
          street: this.submitForm.get("basic")['controls'].street.value,
          city: this.submitForm.get("basic")['controls'].city.value,
          state: this.submitForm.get("basic")['controls'].state.value,
          zip: this.submitForm.get("basic")['controls'].zip.value,
        }

        let dialogData: CMMSLocation = {
          id: this.navigatorService.generateKey(this.keyTypes.CMMS_LOCATION_ID),
          originId: this.submitForm.get("basic")['controls'].originatorId.value,
          originDate: this.submitForm.get("basic")['controls'].originationDate.value,

          title: this.submitForm.get("basic")['controls'].title.value,
          desc: this.submitForm.get("basic")['controls'].desc.value,
          address: address,
          images: this.submitForm.get("basic")['controls'].images.value,
          type: this.submitForm.get("basic")['controls'].type.value,

          isDisplayed: true
        };

        console.log("New CMMS Location: ", dialogData);
        this.matDialogRef.close(dialogData);
      } else {
        this.close();
      }
    }

    CMMSLocationCompleted() {
      this.isCMMSLocationCompleted = true;
    }

    close() {
      this.matDialogRef.close();
    }

    public reset() {
      this.horizontalStepper.reset(); 
      
      this.submitForm.reset({
        
      });   
    }

    public onSelectionChange(e:any) { 
      console.log("Forward");
      if(e.selectedIndex == 4){   
        this.horizontalStepper._steps.forEach(step => step.editable = false);
        console.log(this.submitForm.value);      
      }
    }

    public generateBarcode(): void {
      let barcode: string = this.navigatorService.generateKey(this.keyTypes.BARCODE_UPC_A);
      this.submitForm.get("details").get("barcode").setValue(barcode);
      console.log("New Barcode: " + barcode);
    }

    getContrast(hexcolor) {

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
    
      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }
    
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
    
      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    
    };
  }