import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation  } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { keyTypesEnum, NavigatorService } from '../../../services/vendor/navigator.service';
import { workOrderElementEnum } from '../../../services/vendor/navigator.service';

import { WorkOrderTemplate, WorkOrder, LinkedData, WorkOrderCustomField } from '../../../data-models/models'
import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { faDollyFlatbed, faThList } from '@fortawesome/free-solid-svg-icons';

import { Observable, Subscription } from 'rxjs'; 


export enum propertyEnum {
  VALUE = 0,
  IS_DISABLED = 1,
  IS_REQUIRED = 2,
  IS_TEXT_CONTROL_INCLUDED = 3,
  TEXT_PLACEHOLDER = 4,
  COLUMN_SIZE = 5,
  SLOT_INDEX = 6,
  IMAGE_HOLDING_SIZE = 7,
  TEXT_AREA_ROWS = 8,
  HEADER_FONT_SIZE = 9,
  TEXT_HORIZONTAL_ALIGNMENT = 10,
  TEXT_VERTICAL_ALIGNMENT = 11,
  LINKED_DATA = 12,
  TAB_TITLE = 13
}

@Component({
  selector: 'app-vendor-work-order-builder',
  templateUrl: 'vendor-work-order-builder.component.html',
  styleUrls: ['vendor-work-order-builder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,  // Use to disable CSS Encapsulation for this component
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class VendorWorkOrderBuilderComponent implements OnInit, AfterViewInit {

  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);
  public propertyEnum = propertyEnum;
  public keyTypes = keyTypesEnum;

  private workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  public elementEnum: any = workOrderElementEnum;

  public customFields: Array<WorkOrderCustomField>;
  public template: WorkOrderTemplate;
  public workOrderData: WorkOrder;
  public workOrderKeys: Array<LinkedData> = [];
  public workOrderLookupKeys: Array<LinkedData> = [];

  public addNewLinkedField: boolean = false;

  // Temp
  public followOnArray: Array<WorkOrder> = [];

  // Builder Tools
  public isFullScreen: boolean = false;
  public zoomLevel: number = 100;
  public zoomLevelTransform: string = "scale(1, 1)";

  // Element Focus
  public activeProperty: any;
  public activeElement: any;
  public activeElementID: number = 0;
  public elementFocusID: number = 0;

  public tabIndex: number = 0;
  public rowIndex: number = 0;
  public elementIndex: number = 0;

  // Properties
  public value: any;
  public isDisabled: boolean;
  public isRequired: boolean;
  public isTextControlsIncluded?: boolean;
  public textPlaceHolder: string;
  public columnSize: number;
  public slotIndex: number;
  public imageHoldingSize: number;
  public textAreaRows: number;
  public headerFontSize: number;
  public textHorizontalAlignment: number;
  public textVerticalAlignment: number;
  public linkedData: LinkedData = { title: "", data: null };
  public linkedDataTitle: string = "";
  public tabTitle: string;

  // Subscriptions
  private workOrderTemplateSubscriber: Subscription;
  private workOrderCustomFieldSubscriber: Subscription;
  
  constructor(private vendorService: NavigatorService, public dialog: MatDialog) {
      
  }

  ngOnInit(): void {
    this.followOnArray = this.createFollowOnMaintenanceWorkOrders();
    // this.template = this.vendorService.getLocalWorkOrderTemplate();
    
    this.subscribeToTemplate();
    this.subscribeToWorkOrderCustomFields();

    this.workOrderData = {
      technicianId: "",
      originatorId: "",
      workOrderId: "",
      companyId: "",

      originationDate: new Date,
      timeStretch: 30,
      custPocName: "",
      custPocPhone: null,
      custPocEmail: "",
      custFirstName: "",
      custMiddleName: '',
      custLastName: "",
      custEmail: "",
      custPhone: '1234567890',
      custAddress: {
        street: "",
        city: "",
        state: "",
        zip: 12345
      },
      custBillingAddress: {
        street: "",
        city: "",
        state: "",
        zip: 12345
      },
      custBillAmount: 0,
      description: "",
      dateContactLog: [
        new Date
      ],
      dateInitialContact: new Date,
      dateScheduled: new Date,
      priority: 2,
      skillLvl: 1,
      specialNotes: "",
      status: 1,
      foreign_work_orders: [],
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: []
    }
  }

  ngAfterViewInit(): void {

    this.keyLookupTable();
   
  }

  ngOnDestroy() {
    if(this.workOrderTemplateSubscriber) {
      this.workOrderTemplateSubscriber.unsubscribe();
    }

    if(this.workOrderCustomFieldSubscriber) {
      this.workOrderCustomFieldSubscriber.unsubscribe();
    }
  }  

  subscribeToTemplate(): void {
    this.workOrderTemplateSubscriber = this.vendorService.getWorkOrderTemplate().subscribe(template => {
      if(template) {
        this.template = template;
      }
    });
  }

  getWorkOrderTemplate(): void {
    this.vendorService.getWorkOrderTemplate();
  }

  subscribeToWorkOrderCustomFields(): void {
    this.workOrderCustomFieldSubscriber = this.vendorService.getCustomWorkOrderCustomFields().subscribe(customFields => {
      if(customFields) {
        this.customFields = customFields;
      }
    });
  }

  getWorkOrderCustomFields(): void {
    this.vendorService.getCustomWorkOrderCustomFields();
  }

  keyLookupTable(): void {
    // iterate over the Work Order Data object
    for (const key in this.workOrderData) {
      this.workOrderKeys.push( { title: key, data: this.workOrderData[key] } );
      // console.log(`${key}: ${this.workOrderData[key]}`);
    }
  }

  // Disable!!!! Cannot allow a user to change this
  // keyLookup.push({ title: "Company ID", data: "companyId" });

  // Needs to go into a Date Drop Down Menu
  // keyLookup.push({ title: "Date Scheduled", data: "dateScheduled" });
  // keyLookup.push({ title: "Origination Date", data: "originationDate" });
  // keyLookup.push({ title: "Initial Contact Date", data: "dateInitialContact" });
  linkedDataKeyLookup(): Array<LinkedData> {
    let keyLookup: Array<LinkedData> = [];

    switch(this.elementFocusID) {
      case this.elementEnum.TEXT_FIELD:

        // W/O Info 

        // THESE MUST BE LOCKED DOWN
        keyLookup.push({ title: "Technician ID", data: "technicianId" });
        keyLookup.push({ title: "Originator ID", data: "originatorId" });
        keyLookup.push({ title: "W/O ID", data: "workOrderId" });
        keyLookup.push({ title: "Minutes Required", data: "timeStretch" });
        keyLookup.push({ title: "Initial Contact Date", data: "dateInitialContact" });
        keyLookup.push({ title: "Date Scheduled", data: "dateScheduled" });
        keyLookup.push({ title: "Origin Date", data: "originationDate" });
        
        // POC Contact
        keyLookup.push({ title: "POC Full Name", data: "custPocName" });
        keyLookup.push({ title: "POC Phone Number", data: "custPocPhone" });
        keyLookup.push({ title: "POC Email", data: "custPocEmail" });

        // Customer Contact
        keyLookup.push({ title: "First Name", data: "custFirstName" });
        keyLookup.push({ title: "Middle Name", data: "custMiddleName" });
        keyLookup.push({ title: "Last Name", data: "custLastName" });
        keyLookup.push({ title: "Email", data: "custEmail" });
        keyLookup.push({ title: "Phone Number", data: "custPhone" });

        // Address
        keyLookup.push({ title: "Street", data: "custAddress.street" });
        keyLookup.push({ title: "City", data: "custAddress.city" });
        keyLookup.push({ title: "State", data: "custAddress.state" });
        keyLookup.push({ title: "Zip Code", data: "custAddress.zip" });

        // Billing Address
        keyLookup.push({ title: "Billing Street", data: "custBillingAddress.street" });
        keyLookup.push({ title: "Billing City", data: "custBillingAddress.city" });
        keyLookup.push({ title: "Billing State", data: "custBillingAddress.state" });
        keyLookup.push({ title: "Billing Zip Code", data: "custBillingAddress.zip" });

        // Billing Amount
        keyLookup.push({ title: "Bill Total", data: "custBillAmount" });

        for(let customField of this.customFields) {
          keyLookup.push({
            title: customField.title,
            data: customField.key
          })
        }

        break;

      case this.elementEnum.TEXT_AREA:
        keyLookup.push({ title: "Description", data: "description" });
        keyLookup.push({ title: "Notes", data: "specialNotes" });
        break;

      case this.elementEnum.QUILL_EDITOR:
        keyLookup.push({ title: "Description", data: "description" });
        keyLookup.push({ title: "Notes", data: "specialNotes" });
        break;

      case this.elementEnum.IMG_IMPORT:
        break;

      case this.elementEnum.PARAGRAPH:
        keyLookup.push({ title: "Description", data: "description" });
        keyLookup.push({ title: "Notes", data: "specialNotes" });
        break;

      case this.elementEnum.DROP_DOWN_MENU:
        keyLookup.push({ title: "Priority", data: "priority" });
        keyLookup.push({ title: "Skill Level", data: "skillLvl" });
        keyLookup.push({ title: "Status", data: "status" });
        keyLookup.push({ title: "Completed", data: "isCompleted" });
        keyLookup.push({ title: "Paid", data: "isPaid" });
        keyLookup.push({ title: "Employee Group", data: "technicianId" });
        break;

      case this.elementEnum.FOLLOW_ON_TABLE:
        keyLookup.push({ title: "Follow On W/O's", data: "foreign_work_orders" });
        break;
    }

    return keyLookup;
  }

  customLinkedDataChange(title: string): void {

  }

  addNewDataLink(): void {
    this.addNewLinkedField = true;
  }

  saveNewDataLink(title: string): void {
    let fieldId: string = "custom-" + this.vendorService.generateKey(this.keyTypes.FIELD_ID);
    let customField: WorkOrderCustomField = {
      key: fieldId,
      title: title,
      data: null
    };

    this.vendorService.updateWorkOrderCustomField(customField);
    this.getWorkOrderCustomFields();
    this.addNewLinkedField = false;
  }

  cancelNewDataLink(): void {
    this.addNewLinkedField = false;
  }

  linkedDataChange(option): void {
    this.linkedData.title = option;
    this.linkedData.data = this.findDataValueWithKey(this.workOrderLookupKeys, this.linkedData.title);

    this.linkedDataTitle = option;

    this.saveProperties();
  }

  fullScreenClick(): void {
    if(this.isFullScreen) {
      this.isFullScreen = false;
    } else {
      this.isFullScreen = true;
    }

    this.vendorService.setCustomWorkOrderBuilderIsFullScreen(this.isFullScreen);
  }

  onZoomSliderChange(zoomeLvl: any): void {
    let zoomLevelFraction = zoomeLvl.value / 100;

    this.zoomLevel = zoomeLvl.value;
    this.zoomLevelTransform = "scale(" + zoomLevelFraction + ", " + zoomLevelFraction + ")";
  }

  elementSizeChange(isIncrease: boolean): void {
    let currentColumnSize = this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.columnSize;

    if(currentColumnSize === 1 && !isIncrease || currentColumnSize === 8 && isIncrease) {
      // Do Nothing
    } else {
        if(isIncrease) {
          this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.columnSize = currentColumnSize + 1;
        } else {
          this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.columnSize = currentColumnSize - 1;
        } 
    }

    this.columnSize = this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.columnSize;
  }

  addRow(tabIndex: number): void {
    this.template.tabs[tabIndex].rows.push(
      {
        columns: 8,
        elements: []
      }
    );
  }

  addTab(selectAfterAdding: boolean) {
    this.template.tabs.push(
      {
        id: this.template.tabs.length + 1,
        title: "New Tab",
        rows: []
      }
    );

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);
    }
  }

  deleteRow(tabIndex: number, rowIndex: number): void {
    this.template.tabs[tabIndex].rows.splice(rowIndex, 1);
  }

  deleteTab(index: number) {
    this.template.tabs.splice(index, 1);
  }

  addElement(tabIndex: number, rowIndex: number) {
    let newElement: any;

    switch(this.activeElementID) {
      case this.elementEnum.PARAGRAPH:
        newElement = {
          element: this.activeElementID,
          properties: {
            columnSize: 2,
            isDisabled: false,
            isRequired: false,
            slotIndex: 0,
            headerFontSize: 16,
            value: "Text",
            linkedData: {
              title: "",
              data: null
            }

          }
        };
        break;

      case this.elementEnum.HEADER:
        newElement = {
          element: this.activeElementID,
          properties: {
            columnSize: 2,
            isDisabled: false,
            isRequired: false,
            slotIndex: 0,
            headerFontSize: 32,
            value: "Header",
            linkedData: {
              title: "",
              data: null
            }
          }
        };
        break;

      default:
        newElement = {
          element: this.activeElementID,
          properties: {
            columnSize: 2,
            isDisabled: false,
            isRequired: false,
            slotIndex: 0,
            textPlaceHolder: "Field",
            linkedData: {
              title: "",
              data: null
            },
            value: "Insert Value"
          }
        };
    }

    this.template.tabs[tabIndex].rows[rowIndex].elements.push(newElement);
  }

  removeElement(elementIndex: number, tabIndex: number, rowIndex: number) {
    this.template.tabs[tabIndex].rows[rowIndex].elements.splice(elementIndex, 1);

    this.tabIndex = 999;
  }

  tabFocus(tabIndex: number): void {
    this.tabIndex = tabIndex;
  }

  elementFocus(event: Event, tabIndex: number, rowIndex: number, elementIndex: number, elementID: number) {

    this.elementFocusID = elementID;
    this.tabIndex = tabIndex;
    this.rowIndex = rowIndex;
    this.elementIndex = elementIndex;
    this.workOrderLookupKeys = this.linkedDataKeyLookup();

    // Properties
    this.value = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.value;
    this.isDisabled = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.isDisabled;
    this.isRequired = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.isRequired;
    this.isTextControlsIncluded = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.isTextControlsIncluded;
    this.textPlaceHolder = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.textPlaceHolder;
    this.columnSize = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.columnSize;
    this.slotIndex = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.slotIndex;
    this.imageHoldingSize = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.imageHoldingSize;
    this.textAreaRows = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.textAreaRows;
    this.headerFontSize = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.headerFontSize;
    this.textHorizontalAlignment = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.textHorizontalAlignment;
    this.textVerticalAlignment = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.textVerticalAlignment;
    this.linkedData = this.template.tabs[tabIndex].rows[rowIndex].elements[elementIndex].properties.linkedData;
    this.linkedDataTitle = this.linkedData.title;

    this.changeSizeIcons();

    if(this.activeElement) {
      this.activeElement.classList.remove("activeElement");

      this.activeElement = event.currentTarget;
      this.activeElement.classList.add("activeElement");
    } else{
      this.activeElement = document.querySelector(".activeElement");

      if(this.activeElement) {
        this.activeElement.classList.remove("activeElement");
      }

      this.activeElement = event.currentTarget;
      this.activeElement.classList.add("activeElement");
    }
  }

  update(event: any, updateCode: number): void {

    switch(updateCode) {

      case this.propertyEnum.VALUE:
        this.value = event;
        break

      case this.propertyEnum.IS_DISABLED:
        this.isDisabled = event;
        break

      case this.propertyEnum.IS_REQUIRED:
        this.isRequired = event;
        break

      case this.propertyEnum.IS_TEXT_CONTROL_INCLUDED:
        this.isTextControlsIncluded = event;
        break

      case this.propertyEnum.TEXT_PLACEHOLDER:
        this.textPlaceHolder = event;
        break

      case this.propertyEnum.COLUMN_SIZE:
        this.columnSize = event;
        break

      case this.propertyEnum.SLOT_INDEX:
        this.slotIndex = event;
        break

      case this.propertyEnum.IMAGE_HOLDING_SIZE:
        this.imageHoldingSize = event;
        break

      case this.propertyEnum.TEXT_AREA_ROWS:
        this.textAreaRows = event;
        break

      case this.propertyEnum.HEADER_FONT_SIZE:
        this.headerFontSize = event;
        break

      case this.propertyEnum.TEXT_HORIZONTAL_ALIGNMENT:
        this.textHorizontalAlignment = event;
        break

      case this.propertyEnum.TEXT_VERTICAL_ALIGNMENT:
        this.textVerticalAlignment = event;
        break

      case this.propertyEnum.LINKED_DATA:
        this.linkedData = event;
        break

      case this.propertyEnum.TAB_TITLE:
        this.tabTitle = event;
        break
    }


    this.saveProperties();
  }

  saveProperties(): void {

    // Properties
    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.value = this.value;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.isDisabled = this.isDisabled;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.isRequired = this.isRequired;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.isTextControlsIncluded = this.isTextControlsIncluded;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.textPlaceHolder = this.textPlaceHolder;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.columnSize = this.columnSize;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.slotIndex = this.slotIndex;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.imageHoldingSize = this.imageHoldingSize;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.textAreaRows = this.textAreaRows;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.headerFontSize = this.headerFontSize;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.textHorizontalAlignment = this.textHorizontalAlignment;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.textVerticalAlignment = this.textVerticalAlignment;

    this.template.tabs[this.tabIndex].rows[this.rowIndex].elements[this.elementIndex].properties.linkedData = this.linkedData;


    // this.vendorService.setWorkOrderTemplate(this.template);
    
  }

  publish(): void {
    this.vendorService.updateWorkOrderTemplate(this.template);

    this.getWorkOrderTemplate();
  }

  saveRowProperties(): void {
    
  }

  saveTabProperties(): void {
    if(this.tabIndex != null && this.tabTitle.length > 0) {
      this.template.tabs[this.tabIndex].title = this.tabTitle;
    }
  }

  isFieldDate(linkedData: LinkedData): boolean {
    let isDate: boolean = false;

    if(linkedData.data != null) {
      isDate = linkedData.data.indexOf("date") > -1 || linkedData.data.indexOf("Date") > -1;
    }

    return isDate;
  }

  getLinkedDataTitle(): string {
    return this.linkedData.title;
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  findDataValueWithKey(objects: Array<LinkedData>, key: string): string {
    return objects[objects.findIndex(element => element.title == key)].data;
  }

  changeSizeIcons(): void {
    let sizeIcons = document.querySelectorAll(".sizeIcon");

    // Clearing out all of these classes
    if(this.columnSize <= 4 && this.columnSize > 0) {
      for(let sizeIconIndex: number = 0; sizeIconIndex < sizeIcons.length; sizeIconIndex++) {
        if(sizeIcons[sizeIconIndex].classList.contains("activeSizeIcon")) {
          sizeIcons[sizeIconIndex].classList.remove("activeSizeIcon");
        }
      }
    }

    // Setting these classes
    if(this.columnSize <= 4 && this.columnSize > 0) {
      for(let sizeIconIndex: number = 0; sizeIconIndex < this.columnSize; sizeIconIndex++) {
        if(sizeIcons[sizeIconIndex]) {
          sizeIcons[sizeIconIndex].classList.add("activeSizeIcon");
        }
      }
    }
  }

  addColumnSize(event: Event, tabIndex: number, rowIndex: number, elementIndex: number, elementID: number) {

  }

  subtractColumnSize(event: Event, tabIndex: number, rowIndex: number, elementIndex: number, elementID: number) {

  }

  activePropertyHighlighter(event:any, elementID: number) {

    this.activeElementID = elementID;

    if(this.activeProperty) {
      this.activeProperty.classList.remove("activeProperty");

      this.activeProperty = event.currentTarget;
      this.activeProperty.classList.add("activeProperty");
    } else{
      this.activeProperty = document.querySelector(".activeProperty");

      if(this.activeProperty) {
        this.activeProperty.classList.remove("activeProperty");
      }

      this.activeProperty = event.currentTarget;
      this.activeProperty.classList.add("activeProperty");
    }
  }

  public openWorkOrderDialog(): void {
    this.workOrderDialogRef = this.dialog.open(
      VendorWorkOrderDialogComponent,
      {
        data: {
          workOrderData: this.createPreviewWorkOrder()
        }
      }
    );

    this.workOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }

  createPreviewWorkOrder(): WorkOrder  {

    let custPhone: string = null;
    let dateContactLog: Array<Date> = [new Date];
    let followOnMaintence: Array<string> = ["Fix Broken Stuff", "Refix Broken Stuff", "Try Not to Break New Stuff"];
    // let workOrderNumber:number = id + 123;
  
    return {
      technicianId: "1a23bcff",
      originatorId: this.vendorService.getProfileId(),
      workOrderId: this.vendorService.generateKey(3),
      companyId: this.vendorService.getCompanyId(),

      originationDate: new Date,
      custPocName: "Morty Smith",
      custPocPhone: 1234567890,
      custPocEmail: 'null@gmail.com',
      custFirstName: "Rick",
      custMiddleName: '',
      custLastName: 'Sanchez',
      custEmail: 'rick@gmail.com',
      custPhone: custPhone,
      custAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillingAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillAmount: 45,
      description: 'Customer Tales of Broken Stuff. Customer Tales of Broken Stuff. Customer Tales of Broken Stuff',
      dateContactLog: dateContactLog,
      dateInitialContact: new Date,
      dateScheduled: new Date,
      priority: 3,
      skillLvl: 2,
      specialNotes: "",
      status: 1,
      foreign_work_orders: followOnMaintence,
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: []
    };

  }

  createFollowOnMaintenanceWorkOrders(): Array<WorkOrder>  {

    let followOnArray: Array<WorkOrder> = [];
    let custPhone: string = null;
    let dateContactLog: Array<Date> = [new Date];
    let followOnMaintence: Array<string> = ["Fix Broken Stuff", "Refix Broken Stuff", "Try Not to Break New Stuff"];
    // let workOrderNumber:number = id + 123;

    for(let index: number = 0; index < 13; index++) {

      followOnArray.push({
        technicianId: "1a23bcff",
        originatorId: this.vendorService.getProfileId(),
        workOrderId: this.vendorService.generateKey(3),
        companyId: this.vendorService.getCompanyId(),

        originationDate: new Date,
        custPocName: "Morty Smith",
        custPocPhone: 1234567890,
        custPocEmail: 'null@gmail.com',
        custFirstName: "Rick",
        custMiddleName: '',
        custLastName: 'Sanchez',
        custEmail: 'rick@gmail.com',
        custPhone: custPhone,
        custAddress: {
          street: "10945 Estate Ln",
          city: "Dallas",
          state: "TX",
          zip: 75238
        },
        custBillingAddress: {
          street: "10945 Estate Ln",
          city: "Dallas",
          state: "TX",
          zip: 75238
        },
        custBillAmount: 45,
        description: 'Customer Tales of Broken Stuff. Customer Tales of Broken Stuff. Customer Tales of Broken Stuff',
        dateContactLog: dateContactLog,
        dateInitialContact: new Date,
        dateScheduled: new Date,
        priority: 3,
        skillLvl: 2,
        specialNotes: "",
        status: 1,
        foreign_work_orders: followOnMaintence,
        isAutoGenerated: false,
        isCompleted: false,
        isPaid: false,
        customFields: []
      });

    }
  
    return followOnArray;
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  drop(event: CdkDragDrop<string[]>, tabIndex: number) {
    moveItemInArray(this.template.tabs[tabIndex].rows, event.previousIndex, event.currentIndex);
  }
}
