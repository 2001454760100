import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-register-company-dialog.component',
    templateUrl: 'register-company-dialog.component.html',
    styleUrls: ['register-company-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class RegisterCompanyDialogComponent implements OnInit {
    
    constructor(public matDialogRef: MatDialogRef<RegisterCompanyDialogComponent>) {

    }
  
    ngOnInit() {

    }

    close() {
      this.matDialogRef.close();
    }

  }