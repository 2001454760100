import { Component, Input, OnInit } from "@angular/core";
import { Slide } from "./carousel-support-feature.interface";
import { trigger, transition, useAnimation } from "@angular/animations";
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

import {
  AnimationType,
  scaleIn,
  scaleOut,
  fadeIn,
  fadeOut,
  flipIn,
  flipOut,
  jackIn,
  jackOut
} from "./carousel-support-feature.animations";

@Component({
  selector: "app-carousel-support-feature",
  templateUrl: "./carousel-support-feature.component.html",
  styleUrls: ["./carousel-support-feature.component.scss"],
  animations: [
    trigger("slideAnimation", [
      /* scale */
      transition("void => scale", [
        useAnimation(scaleIn, { params: { time: "500ms" } })
      ]),
      transition("scale => void", [
        useAnimation(scaleOut, { params: { time: "500ms" } })
      ]),

      /* fade */
      transition("void => fade", [
        useAnimation(fadeIn, { params: { time: "500ms" } })
      ]),
      transition("fade => void", [
        useAnimation(fadeOut, { params: { time: "500ms" } })
      ]),

      /* flip */
      transition("void => flip", [
        useAnimation(flipIn, { params: { time: "500ms" } })
      ]),
      transition("flip => void", [
        useAnimation(flipOut, { params: { time: "500ms" } })
      ]),

      /* JackInTheBox */
      transition("void => jackInTheBox", [
        useAnimation(jackIn, { params: { time: "700ms" } })
      ]),
      transition("jackInTheBox => void", [
        useAnimation(jackOut, { params: { time: "700ms" } })
      ])
    ])
  ]
})

export class CarouselSupportFeatureComponent implements OnInit {
  @Input() slides: Slide[];
  @Input() animationType = AnimationType.Scale;
  public config: SwiperConfigInterface = { };

  currentSlide: number = 0;
  currentPage: number = 0;
  pageSize: number = 3;
  currentMobileSlide: number = 0;
  isMobile: boolean = false;

  constructor() {}

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      // breakpoints: {
      //   480: {
      //     slidesPerView: 1
      //   },
      //   740: {
      //     slidesPerView: 2,
      //   },
      //   960: {
      //     slidesPerView: 3,
      //   }
      // }
    }
  }

  isMobileView(): boolean {
    let isMobileView: boolean = false;

    return isMobileView;
  }

  setCurrentPage(pageIndex: number) {
    this.currentPage = pageIndex;
  }

  setCurrentSlide(slideIndex: number) {
    if(!this.isMobile) {
      this.currentSlide = slideIndex;
      this.currentMobileSlide = slideIndex;
    }

    this.isMobile = false;
  }

  setCurrentMobileSlide(slideIndex: number) {
    this.currentSlide = slideIndex;
    this.currentMobileSlide = slideIndex;

    this.isMobile = true;
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }

  ngOnInit() {
    this.preloadImages(); // for the demo
  }

  preloadImages() {
    for (const slide of this.slides) {
      new Image().src = slide.src;
    }
  }
}