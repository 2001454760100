import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ski-lift-landscape-animation',
  templateUrl: './ski-lift-landscape-animation.component.html',
  styleUrls: ['./ski-lift-landscape-animation.component.scss']
})
export class SkiLiftAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
