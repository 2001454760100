import { Component, OnInit } from '@angular/core';
import { Settings, AppSettings } from '../../app.settings';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-meeseeks',
  templateUrl: './meeseeks.component.html',
  styleUrls: ['./meeseeks.component.scss']
})
export class MeeseeksComponent implements OnInit {

  public bgImage;
  public settings: Settings;
  constructor(public appSettings:AppSettings, private viewportScroller: ViewportScroller) {
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){    
    
  }

  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
}

}
