import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Component, OnInit, ViewEncapsulation, Inject, SimpleChanges  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isThisMinute } from 'date-fns';
import { is } from 'date-fns/locale';
import { InvoiceSettings, PaymentSettings, StripePaymentDetails, StripePaymentLineItems, WOInvoice, WorkOrder, WorkOrderQuickAction, WorkOrderService } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import pdfMake from "pdfmake/build/pdfmake";
import { filter } from 'rxjs/operators';
import { VendorInvoiceGeneratorDialogComponent } from '../../professional/vendor-invoice-generator-dialog/vendor-invoice-generator-dialog.component';
import { quickActionCategoryEnum } from '../../menu-gooey/menu-gooey.component';

class Product {
  name: string;
  price: number;
  qty: number;

  constructor() {

  }
}

class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;
  
  services: WorkOrderService[] = [];
  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show 
    // this.products.push(new Product());
  }
}

@Component({
    selector: 'app-vendor-work-order-quick-action-dialog.component',
    templateUrl: 'vendor-work-order-quick-action-dialog.component.html',
    styleUrls: ['vendor-work-order-quick-action-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorWorkOrderQuickActionDialogComponent implements OnInit {

    public keyTypes: any = keyTypesEnum;
    private invoiceDialogRef: MatDialogRef<VendorInvoiceGeneratorDialogComponent>;

    public quickActionCategories: any = quickActionCategoryEnum;
    public dialogTitle: string = "";

    public workOrder: WorkOrder = null;
    public companyTitle: string = this.navigatorService.getCompanyName();

    invoiceSettings: InvoiceSettings;
    invoice = new Invoice(); 
    public isStripeChargesEnabled: boolean = false;
    public stripeAccountID: string = null;

    public customerViewer: boolean = false;

    public incomingImages: Array<any> = [];
    public images: Array<any> = [];
    public imgIds: Array<string> = [];
    private imgImportchange: boolean = false;
    public imageDeleteAction: boolean = false;
    
    constructor(
      private http: HttpClient,
      private navigatorService: NavigatorService, 
      private paymentHandler: PaymentHandlerService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private imageHandler: ImageHandlerService, 
      private datePipe: DatePipe, 
      public matDialogRef: MatDialogRef<VendorWorkOrderQuickActionDialogComponent>, 
      @Inject(MAT_DIALOG_DATA) public quickAction: WorkOrderQuickAction) {

    }
  
    ngOnInit() {
      this.matDialogRef.disableClose = true;

      if(this.quickAction != undefined) {
        this.setDialogTitle();
        this.getWorkOrder(this.quickAction.workOrderId);
      }

    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    private setDialogTitle(): void {

      switch(this.quickAction.category) {

        case this.quickActionCategories.ATTACHED_PARTS:
          this.dialogTitle = "Inventory / Equipment / Parts";
          break;

        case this.quickActionCategories.STATUS:
          this.dialogTitle = "Status";
          break;

        case this.quickActionCategories.SERVICE:
          this.dialogTitle = "Services";
          break;

        case this.quickActionCategories.PAYMENT:
          this.dialogTitle = "Payment";
          break;

        default:
          this.dialogTitle = "Work Order";
          break;

      }

    }

    public servicesChange(service): void {
      this.workOrder.servicesRendered = service;
    }

    public attachedPartsChange(parts): void {
      this.workOrder.attachedParts = parts;
    }

    public openInvoice(invoice: WOInvoice): void {
      pdfMake.createPdf(invoice.invoice).open();
    }
    
    public makePayment(paymentDetails: StripePaymentDetails): void {

      if(paymentDetails != null && paymentDetails != undefined) {
       
        this.paymentHandler.makeStripePayment(paymentDetails);
        
      } else {
        this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }
  
    }
  
    public makePaymentAsForeign(paymentDetails: StripePaymentDetails): void {
  
      if(paymentDetails != null && paymentDetails != undefined) {
       
        this.paymentHandler.makeStripePaymentAsForeign(this.navigatorService.getCompanyId(), paymentDetails);
        
      } else {
        this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }
  
    }

    public async getStripeAccount(): Promise<any> {
      let returnAccount: any = null;
  
      await this.navigatorService.getCompanyLevelPaymentSettings().then(async (paymentSettingData: PaymentSettings) => {
  
        if(paymentSettingData != null && paymentSettingData != undefined) {
          await this.paymentHandler.getStripeAccount(paymentSettingData.stripe_account_id).then( (account: any) => {
  
            if(account != null && account != undefined) {
              console.log("Stripe Account: ", account);
  
              this.isStripeChargesEnabled = account.charges_enabled;
              this.stripeAccountID = paymentSettingData.stripe_account_id;
  
              returnAccount = account;
            }
  
          });
        }
  
      });
  
      return returnAccount;
    }

    // private async getInvoiceSettings(): Promise<InvoiceSettings> {
    //   let invoiceSettings: InvoiceSettings = null;

    //   await this.navigatorService.getCompanyLevelInvoiceSettings().then(settings => {
    //     if(settings != null && settings != undefined) {
    //       this.invoiceSettings = settings;
    //     }
    //   });

    //   return invoiceSettings;
    // }
  
    // public publishInvoice(invoice): void {
  
    //   if(this.workOrder.invoices == undefined || this.workOrder.invoices == null) {
    //     this.workOrder.invoices = [];
    //   }
  
    //   this.workOrder.invoices.push({
    //     id: this.workOrder.workOrderId,
    //     payment: this.getPaymentDetails(),
    //     invoice: invoice,
    //     date: new Date()
    //   });
  
    //   this.navigatorService.addWorkOrderInvoice(this.workOrder).then( (status: boolean) => {
  
    //     if(status) {
    //       // this.snackBar.open('Invoice Published!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    //     }
  
    //   });
    // }
  
    // private getPaymentDetails(): StripePaymentDetails {
    //   let payment: StripePaymentDetails = null;
  
    //   if(this.workOrder != null && this.workOrder != undefined) {
    //     let productsTotal: number = 0;
    //     let servicesTotal: number = 0;
    //     let subtotal: number = 0;
    //     let taxRate: number = this.invoiceSettings.tax_rate / 100;
  
    //     let paymentDetails: Array<StripePaymentLineItems> = [];
  
    //     for(let product of this.invoice.products) {
    //       productsTotal += product.price * product.qty;
    //     }
  
    //     for(let service of this.invoice.services) {
    //       servicesTotal += service.price * service.hrs;
    //     }
  
    //     subtotal = productsTotal + servicesTotal;
  
    //     if(subtotal > 0) {
    //       paymentDetails.push(
    //         {
    //           name: "Services",
    //           currency: "usd",
    //           quantity: 1,
  
    //           amount: Math.round( (servicesTotal * 100) )
    //         }
    //       );
  
    //       paymentDetails.push(
    //         {
    //           name: "Products",
    //           currency: "usd",
    //           quantity: 1,
  
    //           amount: Math.round( productsTotal * 100)
    //         }
    //       );
  
    //       // Custom Charge Fields
    //       for(let chargeField of this.invoiceSettings.custom_charge_fields) {
  
    //         // Percentage Based
    //         if(chargeField.type == 1) {
    //           paymentDetails.push(
    //             {
    //               name: chargeField.title,
    //               currency: "usd",
    //               quantity: 1,
        
    //               amount: Math.round( (subtotal * ( Number(chargeField.value) / 100 )) * 100)
    //             }
    //           );
  
    //         } else {
    //           // Fixed Based
    //           paymentDetails.push(
    //             {
    //               name: chargeField.title,
    //               currency: "usd",
    //               quantity: 1,
        
    //               amount: Math.round( (Number(chargeField.value)) * 100)
    //             }
    //           );
    //         }      
    //       }
          
    //       paymentDetails.push(
    //         {
    //           name: "Taxes",
    //           currency: "usd",
    //           quantity: 1,
  
    //           amount: Math.round( (subtotal * taxRate) * 100)
    //         }
    //       );
  
    //       payment = {
    //         stripeAccount: this.stripeAccountID,
    //         paymentDetails: paymentDetails
    //       };
  
    //     }
    //   }
  
    //   return payment;
    // }

    private getWorkOrder(id: string): void {

      this.navigatorService.getWorkOrder(id).then( (workOrder: WorkOrder) => {

        if(workOrder != undefined && workOrder != null) {
          this.workOrder = workOrder;

          if(this.quickAction.category == this.quickActionCategories.SERVICE && this.workOrder.images != undefined) {
            this.imgIds = this.workOrder.images
            this.getAllImgs();
          }
        }

      });

    }

    public imageDataChange(images: any): void {
      this.incomingImages = images;

      this.imgImportchange = true;
    }

    public getImg(imgIndex: number): void {
      if(this.imgIds[imgIndex] != undefined) {
        this.imageHandler.getFile(this.imgIds[imgIndex]);
      }
    }

    public getAllImgs(): void {
      this.images = [];
  
      this.imageHandler.getAsyncFiles(this.imgIds).then(imgs => {
        // this.gallery[]
  
        if(imgs) {
          this.images = imgs;
        }
      });
    }
  
    public uploadImages(images: Array<any>): Array<string> {
  
      if(images != null && images != undefined && images.length > 0) {

        for(let imageIndex: number = 0; imageIndex < images.length; imageIndex++) {
          let file: File = images[imageIndex].file;
          let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

          this.imgIds.push(imgIdUnique);
  
          if(imageIndex == images.length) {
            this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
              this.getAllImgs();
            });
          } else {
            this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

            });
          }

        }
      }
  
      return this.imgIds;
    }

    public deleteImg(imgIndex: number): void {
      this.images.splice(imgIndex, 1);
      this.imgIds.splice(imgIndex, 1);
      this.imageDeleteAction = true;
    }

    public close(): void {

      switch(this.quickAction.category) {

        case this.quickActionCategories.ATTACHED_PARTS:
          this.closeDefault();
          break;

        case this.quickActionCategories.STATUS:
          this.closeDefault();
          break;

        case this.quickActionCategories.SERVICE:
          this.closeDefault();
          break;

        case this.quickActionCategories.PAYMENT:
          this.closeDialog();
          break;

        default:
          this.closeDefault();
          break;

      }
    }

    private closeDefault(): void {
      this.matDialogRef.close(this.workOrder);
    }

    private closeDialog(): void {
      this.matDialogRef.close();
    }


    public openInvoiceGenerator(): void {
  
        this.invoiceDialogRef = this.dialog.open(
          VendorInvoiceGeneratorDialogComponent,
          { data: {
              workOrder: this.workOrder?.workOrderId,
              deal: null
            } 
          }
        );
  
        this.invoiceDialogRef.beforeClosed().subscribe(data => {
          console.log("Before Closed: ", data);
        });
        
        this.invoiceDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed: ", data);
  
  
          // this.navigatorService.updateWorkOrder(data).then(incomingData => {
  
  
          // });
  
        });
  
    }

  }