import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, SimpleChanges  } from '@angular/core';

import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChartDataSets, ChartOptions, ChartType, ChartData } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { ApplicationNotificationMessage, CMMSPartRequest, CMMSPartRequestPart, InventoryItem, LineChartData, NotificationCategory, Page, PageSection, PartAttachBundle, PartAttachDetailBundle, Profile, WorkOrder } from 'src/app/data-models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorPartAttacherDialogComponent } from '../../professional/vendor-part-attacher-dialog/vendor-part-attacher-dialog.component';
import { filter } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/vendor/notification.service';


@Component({
  selector: 'app-vendor-cmms-item-request',
  templateUrl: 'vendor-cmms-item-request.component.html',
  styleUrls: ['vendor-cmms-item-request.component.scss']
})
export class VendorCmmsItemRequestComponent implements OnInit, AfterViewInit {
  attachedPartsBundle: Array<PartAttachBundle> = [];
  attachInventoryItemRef: MatDialogRef<VendorPartAttacherDialogComponent>;

  private profile: Profile = null;
  private notificatonPageCategories: Array<NotificationCategory> = this.navigatorService.getNotificationPageCategories();
  
  public purchaseOrderDetailsForm: FormGroup;
  public newItemForm: FormGroup;
  public keyTypes: any = this.navigatorService.getKeyTypes();

  public attachedParts: Array<CMMSPartRequestPart> = [ ];
  public approvedVendors: Array<any> = [];
  public isNewPart: boolean = false;

  public isBillingSame: boolean = true;

  // Used to check for dirty fields when syncing data from numerous sources
  private originalPartRequestCopy: CMMSPartRequest = null;

  @Input() userPermissions: Page = null;
  @Input() attachedWorkOrder: WorkOrder = null;
  @Input() partRequest: CMMSPartRequest = null;
  @Input() dialogClosing: boolean = false;

  @Output() partRequestUpdated: EventEmitter<CMMSPartRequest> = new EventEmitter<CMMSPartRequest>();
    
  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, public notificationSystem: NotificationService, public navigatorService: NavigatorService, public fb: FormBuilder,) {
      
  }
  
  ngOnInit(): void {

    if(this.partRequest == null) {
      this.initializeForm();
    } else {
      this.originalPartRequestCopy = JSON.parse( JSON.stringify(this.partRequest) );
      this.autofillForm();
    }

    this.initializeNewItemForm();
    
    this.navigatorService.getAsyncProfile().then( (profile: Profile) => {

      if(profile != null && profile != undefined) {
        this.profile = profile;
        let requisitioner: string = this.profile.first_name + " " + this.profile.last_name;

        this.purchaseOrderDetailsForm.controls['requisitioner'].setValue(requisitioner);
      }

    });

  }
  
  ngAfterViewInit(): void {
    
  }

  ngOnDestroy() {
    
  }  

  ngOnChanges(changes: SimpleChanges) {
    console.log("Parent Dialog Change: " + changes.prop);
    
    if(changes.dialogClosing != undefined && changes.dialogClosing.currentValue != undefined) {
    
      this.dialogClosing = changes.dialogClosing.currentValue;
      this.dialogClosingCleanup();
      
    } 

    if(changes.partRequest != undefined && changes.partRequest.currentValue != undefined) {
    
      if(changes.partRequest.currentValue != null) {
        this.partRequest = changes.partRequest.currentValue;
        
        this.syncData();
      }

    } 
  } 

  private formToObject(): CMMSPartRequest {
    let partOrder: CMMSPartRequest = {
      id: this.purchaseOrderDetailsForm.controls['id'].value,
      due_date: new Date(this.purchaseOrderDetailsForm.controls['due_date'].value),
      origination_date: new Date(this.purchaseOrderDetailsForm.controls['origination_date'].value),
      requisitioner: this.purchaseOrderDetailsForm.controls['requisitioner'].value,

      active: this.purchaseOrderDetailsForm.controls['active'].value,
      status: this.purchaseOrderDetailsForm.controls['status'].value,
      approval_status: this.purchaseOrderDetailsForm.controls['approval_status'].value, 
      admin_notes: this.purchaseOrderDetailsForm.controls['admin_notes'].value, 

      vendor_id: this.purchaseOrderDetailsForm.controls['vendor_id'].value,
      company_title: this.purchaseOrderDetailsForm.controls['company_title'].value,
      parts: this.attachedParts,
      work_order: this.purchaseOrderDetailsForm.controls['work_order'].value,
      additional_details: this.purchaseOrderDetailsForm.controls['additional_details'].value,

      subtotal: this.purchaseOrderDetailsForm.controls['subtotal'].value,
      sales_tax: this.purchaseOrderDetailsForm.controls['sales_tax'].value,
      shipping_cost: this.purchaseOrderDetailsForm.controls['shipping_cost'].value,
      other_cost: this.purchaseOrderDetailsForm.controls['other_cost'].value,
      total: this.purchaseOrderDetailsForm.controls['total'].value,

      priority: this.purchaseOrderDetailsForm.controls['priority'].value,

      street: this.purchaseOrderDetailsForm.controls['street'].value,
      city: this.purchaseOrderDetailsForm.controls['city'].value,
      state: this.purchaseOrderDetailsForm.controls['state'].value,
      zip: this.purchaseOrderDetailsForm.controls['zip'].value,
      billing_street: this.purchaseOrderDetailsForm.controls['billingStreet'].value,
      billing_city: this.purchaseOrderDetailsForm.controls['billingCity'].value,
      billing_state: this.purchaseOrderDetailsForm.controls['billingState'].value,
      billing_zip: this.purchaseOrderDetailsForm.controls['billingZip'].value
    };

    return partOrder;
  }

  private syncData(): void {

    for(const key in this.originalPartRequestCopy) {

      if(this.originalPartRequestCopy[key] != this.partRequest[key]) {

        if(this.purchaseOrderDetailsForm.controls[key] != undefined) {
          this.originalPartRequestCopy[key] = this.partRequest[key];
          this.purchaseOrderDetailsForm.controls[key].setValue(this.partRequest[key]);
        }

      }

    }
  }

  private initializeForm(): void {

    let permissionDisable: boolean = true;
    let sectionIndex: number = this.userPermissions.permission.sections.findIndex( (section: PageSection) => { return section.id == "ldpke78" });
    
    if(this.userPermissions.permission.sections[sectionIndex].permissions.create) {
      permissionDisable = false;
    }

    this.purchaseOrderDetailsForm = this.fb.group({
      id: [{ value: this.navigatorService.generateKey(this.keyTypes.PURCHASE_ORDER_ID), disabled: false }],
      due_date: [ new Date(), Validators.required],
      origination_date: [ new Date(), Validators.required],
      requisitioner: { value: '', disabled: permissionDisable },

      active: { value: true, disabled: permissionDisable },
      status: { value: 0, disabled: permissionDisable },
      approval_status: { value: 0, disabled: permissionDisable },
      admin_notes: { value: "", disabled: permissionDisable },

      vendor_id: [ { value: '', disabled: permissionDisable } ],
      company_title: [ { value: '', disabled: permissionDisable } ],
      parts: [ { value: [], disabled: permissionDisable } ],
      work_order: [ { value: this.attachedWorkOrder, disabled: permissionDisable } ],
      additional_details: [ { value: '', disabled: permissionDisable } ],
      subtotal: [ { value: 0, disabled: permissionDisable } ],
      sales_tax: [ { value: 0, disabled: permissionDisable } ],
      shipping_cost: [ { value: 0, disabled: permissionDisable } ],
      other_cost: [ { value: 0, disabled: permissionDisable } ],
      total: [ { value: 0, disabled: permissionDisable } ],
      priority: [ { value: 0, disabled: permissionDisable } ],

      street: [ { value: '', disabled: permissionDisable }, Validators.required],
      city: [ { value: '', disabled: permissionDisable }, Validators.required],
      state: [ { value: '', disabled: permissionDisable }, Validators.required],
      zip: [ { value: null, disabled: permissionDisable }, Validators.required],

      billingStreet: [ { value: '', disabled: permissionDisable }, Validators.required],
      billingCity: [ { value: '', disabled: permissionDisable }, Validators.required],
      billingState: [ { value: '', disabled: permissionDisable }, Validators.required],
      billingZip: [ { value: null, disabled: permissionDisable }, Validators.required],
    });
  }

  private autofillForm(): void {

    let permissionDisable: boolean = true;
    let sectionIndex: number = this.userPermissions.permission.sections.findIndex( (section: PageSection) => { return section.id == "ldpke78" });
    
    if(this.userPermissions.permission.sections[sectionIndex].permissions.update) {
      permissionDisable = false;
    }

    this.purchaseOrderDetailsForm = this.fb.group({
      id: [{ value: this.partRequest.id, disabled: false }],
      due_date: [ { value: this.partRequest.due_date, disabled: permissionDisable }, Validators.required],
      origination_date: [ { value: this.partRequest.origination_date, disabled: permissionDisable }, Validators.required],
      requisitioner: { value: this.partRequest.requisitioner, disabled: permissionDisable },

      active: { value: this.partRequest.active, disabled: permissionDisable },
      status: { value: this.partRequest.status, disabled: permissionDisable },
      approval_status: { value: this.partRequest.approval_status, disabled: permissionDisable },
      admin_notes: { value: this.partRequest.admin_notes, disabled: permissionDisable },

      vendor_id: [ { value: this.partRequest.vendor_id, disabled: permissionDisable } ],
      company_title: [ { value: this.partRequest.company_title, disabled: permissionDisable } ],
      parts: [ { value: this.partRequest.parts, disabled: permissionDisable } ],
      work_order: [ { value: this.partRequest.work_order, disabled: permissionDisable } ],
      additional_details: [ { value: this.partRequest.additional_details, disabled: permissionDisable } ],
      subtotal: [ { value: this.partRequest.subtotal, disabled: permissionDisable } ],
      sales_tax: [ { value: this.partRequest.sales_tax, disabled: permissionDisable } ],
      shipping_cost: [ { value: this.partRequest.shipping_cost, disabled: permissionDisable } ],
      other_cost: [ { value: this.partRequest.other_cost, disabled: permissionDisable } ],
      total: [ { value: this.partRequest.total, disabled: permissionDisable } ],
      priority: [ { value: this.partRequest.priority, disabled: permissionDisable } ],

      street: [ { value: this.partRequest.street, disabled: permissionDisable }, Validators.required],
      city: [ { value: this.partRequest.city, disabled: permissionDisable }, Validators.required],
      state: [ { value: this.partRequest.state, disabled: permissionDisable }, Validators.required],
      zip: [ { value: this.partRequest.zip, disabled: permissionDisable }, Validators.required],

      billingStreet: [ { value: this.partRequest.billing_street, disabled: permissionDisable }, Validators.required],
      billingCity: [ { value: this.partRequest.billing_city, disabled: permissionDisable }, Validators.required],
      billingState: [ { value: this.partRequest.billing_state, disabled: permissionDisable }, Validators.required],
      billingZip: [ { value: this.partRequest.billing_zip, disabled: permissionDisable }, Validators.required],
    });

    this.attachedParts = this.partRequest.parts;
  }

  private initializeNewItemForm(): void {

    let permissionDisable: boolean = true;
    let sectionIndex: number = this.userPermissions.permission.sections.findIndex( (section: PageSection) => { return section.id == "ldpke78" });
    
    if(this.partRequest != null) {
      if(this.userPermissions.permission.sections[sectionIndex].permissions.update) {
        permissionDisable = false;
      }
    } else {
      if(this.userPermissions.permission.sections[sectionIndex].permissions.create) {
        permissionDisable = false;
      }
    }


    this.newItemForm = this.fb.group({
      id: [ { value: '', disabled: permissionDisable }],
      title: [ { value: '', disabled: permissionDisable }, Validators.required],
      price: [ { value: '', disabled: permissionDisable } ],
      unit: [ { value: '', disabled: permissionDisable } ],
      quantity: [ { value: '', disabled: permissionDisable }, Validators.required],
      desc: [ { value: '', disabled: permissionDisable } , Validators.required],
      model: [ { value: '', disabled: permissionDisable }, Validators.required],
    });
  }

  vendorSelectionCompare(o1: any, o2: any) {
    return (o1.id == o2.id);
  }

  openNewPart(): void {
    this.isNewPart = true;
  }

  cancelNewPart(): void {
    this.isNewPart = false;
    this.newItemForm.reset();
  }

  attachedPartLinkFieldUpdate(id: string, value: string): void {
    let partIndex: number = this.attachedParts.findIndex(element => {
      return element.id == id;
    });

    this.attachedParts[partIndex].links = value;
  }

  attachedPartQuantityFieldUpdate(id: string, quantity: number): void {
    let partIndex: number = this.attachedParts.findIndex(element => {
      return element.id == id;
    });

    let part: CMMSPartRequestPart = this.attachedParts[partIndex];
    let subtotal: number = 0;

    subtotal = (part.price * part.quantity);
    this.updateFinancialFields(subtotal, false);

    subtotal = (part.price * quantity);
    this.updateFinancialFields(subtotal, true);

    this.attachedParts[partIndex].quantity = quantity;
  }

  deleteAttachedPart(id: string): void {
    let partIndex: number = this.attachedParts.findIndex(element => {
      return element.id == id;
    });

    let part: CMMSPartRequestPart = this.attachedParts[partIndex];

    let addToSubtotal: number = part.price * part.quantity;
    this.updateFinancialFields(addToSubtotal, false);


    this.attachedParts.splice(partIndex, 1);
  }

  attachNewPart(): void {

    if(this.newItemForm.valid) {
      this.isNewPart = false;

      let newPart = {
        id: this.navigatorService.generateKey(this.keyTypes.PURCHASE_ORDER_NEW_PART_ID),
        title: this.newItemForm.controls['title'].value,
        price: this.newItemForm.controls['price'].value,
        unit: this.newItemForm.controls['unit'].value,
        quantity: this.newItemForm.controls['quantity'].value,
        desc: this.newItemForm.controls['desc'].value,
        model: this.newItemForm.controls['model'].value,
        links: "",
        isNew: false
      }

      let parts = this.purchaseOrderDetailsForm.controls['parts'].value;
      let addToSubtotal: number = newPart.price * newPart.quantity;

      this.updateFinancialFields(addToSubtotal, true);

      parts.push(newPart);
      this.attachedParts.push(newPart);

      this.purchaseOrderDetailsForm.controls['parts'].setValue(parts);
      this.newItemForm.reset();

      console.log("Attached Parts: ", this.purchaseOrderDetailsForm.controls['parts'].value);
    }
  }

  addExistingPart(): void {
    this.openPartAttacherDialog();
  }

  public openPartAttacherDialog() {
    this.attachInventoryItemRef = this.dialog.open(
      VendorPartAttacherDialogComponent,
      { data: { isPartOrder: true} }
    );

    this.attachInventoryItemRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe((parts: Array<PartAttachBundle>) => {
      console.log("Part Attacher Dialog Closed: ", parts);

      for(let part of parts) {
        this.attachedPartsBundle.push(part);

        this.navigatorService.getAsyncSelectInventory(part.partBarcode).then(data => {
          
          if(data != null && data != undefined) {
            let inventoryItem: InventoryItem = data[0];

            let addToSubtotal: number = inventoryItem.lockedFields.price * part.quantity;
            this.updateFinancialFields(addToSubtotal, true);

            this.attachedParts.push({
              id: inventoryItem.lockedFields.id,
              title: inventoryItem.lockedFields.title,
              price: inventoryItem.lockedFields.price,
              unit: inventoryItem.lockedFields.unit,
              quantity: part.quantity,
              desc: inventoryItem.lockedFields.desc,
              model: inventoryItem.lockedFields.model,
              links: "",
              isNew: false
            });

          }
        });
      }

    })
  }

  updateFinancialFields(newItem: number | string, addOrSubtract: boolean) {

    let addToSubtotal: number = 0;

    if(typeof newItem != 'number') {
      addToSubtotal = Number(newItem);
    } else {
      addToSubtotal = newItem;
    }

    // Financial
    let subtotal: number = this.purchaseOrderDetailsForm.controls['subtotal'].value;
    let tax: number = this.purchaseOrderDetailsForm.controls['sales_tax'].value;
    let shipping: number = this.purchaseOrderDetailsForm.controls['shipping_cost'].value;
    let other: number = this.purchaseOrderDetailsForm.controls['other_cost'].value;
    let total: number = 0;

    if(addOrSubtract) {
      subtotal += addToSubtotal;
    } else {
      subtotal -= addToSubtotal;
    }

    total = subtotal + tax + shipping + other;

    this.purchaseOrderDetailsForm.controls['subtotal'].setValue( Number(subtotal.toFixed(2)) );
    this.purchaseOrderDetailsForm.controls['total'].setValue( Number(total.toFixed(2)) );
  }

  updateIsBillingSame(isBillingSame: boolean): void {
    this.isBillingSame = isBillingSame;
  }

  resetForm(): void {
    this.purchaseOrderDetailsForm.reset();
    this.newItemForm.reset();
  }

  submitRequest(isBillingSame: boolean): void {

    if(isBillingSame) {
      let street: string = this.purchaseOrderDetailsForm.controls['street'].value;
      let city: string = this.purchaseOrderDetailsForm.controls['city'].value;
      let state: string = this.purchaseOrderDetailsForm.controls['state'].value;
      let zip: string = this.purchaseOrderDetailsForm.controls['zip'].value;

      this.purchaseOrderDetailsForm.controls['billingStreet'].setValue(street);
      this.purchaseOrderDetailsForm.controls['billingCity'].setValue(city);
      this.purchaseOrderDetailsForm.controls['billingState'].setValue(state);
      this.purchaseOrderDetailsForm.controls['billingZip'].setValue(zip);
    }

    if(this.purchaseOrderDetailsForm.valid) {
      let notification: ApplicationNotificationMessage = {
        id: this.navigatorService.generateKey(this.keyTypes.NOTIFICATION_ID),
        message: "A new part request requires approval",
        category: this.notificatonPageCategories[5].id,
        status: 0
      }

      let partOrder: CMMSPartRequest = this.formToObject();

      if(this.partRequest == null) {
        this.purchaseOrderDetailsForm.reset();
      } else {       
        
          notification.message = "Part request \'" + partOrder.id + "\' has been updated";

      }

      this.newItemForm.reset();

      if(this.purchaseOrderDetailsForm.dirty) {
        this.notificationSystem.addNewNotification(notification);
      }

      this.navigatorService.upsertCMMSPartRequest(partOrder).then( data => {

        if(this.partRequest != null) {
          this.partRequestUpdated.emit(partOrder);
        }

      });

      if(this.partRequest == null && this.partRequest == undefined) {
        this.snackBar.open('You part request has been submitted successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open('You part request has been saved!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    } else {

      if(this.partRequest != null) {
        this.partRequestUpdated.emit(null);
      }
      
      this.snackBar.open('Missing required fields!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

    // Notification Panel Update Required
  }

  dialogClosingCleanup(): void {

    if(this.dialogClosing) {
      console.log("Dialog Closing Cleanup");
      this.submitRequest(this.isBillingSame);
    }

  }

}