import { Injectable, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

import { AutomationsService } from '../vendor/automations.service';


@Injectable({
    providedIn: 'root'
})

export class UserMenuService {
    @ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;

    constructor(
        private automationService: AutomationsService,
        public router: Router) { 

    }

    init() {

    }

    public setUserMenuTrigger(userMenuTrigger: MatMenuTrigger): void {
        this.userMenuTrigger = userMenuTrigger;
    }

    public openUserMenu(): void {
        this.userMenuTrigger.openMenu();
    }
    
    public closeUserMenu(): void {
        this.userMenuTrigger.closeMenu();
    }

}