import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

import { NavigatorService, keyTypesEnum } from '../../services/vendor/navigator.service';
import { ImageHandlerService } from '../../services/vendor/image-handler.service';
import { VendorAddAssetDialogComponent } from '../../shared/professional/vendor-add-asset-dialog/vendor-add-asset-dialog.component';
import { CMMSLocation } from 'src/app/data-models/models';
import { VendorSidebarMenuService } from 'src/app/services/vendor/vendor-sidebar-menu.service';

@Component({
  selector: 'app-asset-toolbar',
  templateUrl: './asset-toolbar.component.html',
  styleUrls: ['./asset-toolbar.component.scss']
})
export class AssetToolbarComponent implements OnInit {
  @Input() isHomePage:boolean = false;
  @Input() showSidenavToggle:boolean = false;
  @Input() pickerView:boolean = false;
  
  @Output() onSidenavToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeSorting: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeViewType: EventEmitter<any> = new EventEmitter<any>();

  private addAssetDialogRef: MatDialogRef<VendorAddAssetDialogComponent>;

  private keyTypes:any = keyTypesEnum;
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public counts = [8, 12, 16, 24, 36];
  public count:any;
  public sortings = ['Sort by Default', 'Newest', 'Oldest', 'Popular', 'Price (Low to High)', 'Price (High to Low)'];
  public sort:any;

  constructor(public dialog: MatDialog, private navigatorService: NavigatorService, private imageHandler: ImageHandlerService, private sideMenu: VendorSidebarMenuService) { }

  ngOnInit() {
    this.count = (this.isHomePage) ? this.counts[0] : this.counts[1];
    this.sort = this.sortings[0];

    if(this.pickerView) {
      this.changeViewType('list', 100);
    }
  }

  ngOnChanges(){
    // console.log(' show toggle - ' ,this.showSidenavToggle)
  }

  public filterSearch(searchFilter: string): void {
    let focusLocations: Array<CMMSLocation> = this.sideMenu.getFocusedCMMSLocations();
    this.navigatorService.getPagedSearchDistinctAssets(searchFilter, focusLocations, 0, this.count);

    this.sideMenu.setAssetTextFilter(searchFilter);
  }
  
  public changeCount(count){
    this.count = count;
    this.onChangeCount.emit(count);
    // this.getAllProducts(); 
  }

  public changeSorting(sort){
    this.sort = sort;
    this.onChangeSorting.emit(sort);
  }

  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
    this.onChangeViewType.emit({viewType:viewType, viewCol:viewCol});
  }

  public sidenavToggle(){
    this.onSidenavToggle.emit();
  }

  public openNewAssetDialog() {
    this.addAssetDialogRef = this.dialog.open(
      VendorAddAssetDialogComponent,
      { data: null }
    );

    this.addAssetDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("Asset Work Order: " + data);

      for(let galleryIndex: number = 0; galleryIndex < data.gallery.length; galleryIndex++) {
        let file: File = data.gallery[galleryIndex].file;
        let imgId: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgId);

        data.assetData.lockedFields.gallery.push(imgId);
      }

      this.navigatorService.upsertAsset(data.assetData);

      // this.files.push({ name, content: ''});
    })
  }

}
