import { GuidedWalkthroughPage } from '../../data-models/models';

export class FormWalkthrough {

    private walkthrough: GuidedWalkthroughPage = {
        page_id: 0,
        title: 'Forms',
        page_walkthrough_completed: false,
        steps: [
          {
            step_id: 0,
            title: "Employee Real-Time Tracking",
            target_element_id: 'navContainer',
            target_query_path: '.stepOne',
            step_message: {
              title: "Employee Real-Time Tracking",
              message: "This map tracks the status and real-time location of all active technicians and upcoming work orders for the current day.",
            },
            isTopLevel: true,
            isInformational: false,
            step_completed: false
          },
          {
            step_id: 1,
            title: "Employee Real-Time Tracking",
            target_element_id: 'mapContainer',
            target_query_path: '.stepTwo',
            step_message: {
              title: "Employee Real-Time Tracking",
              message: "This map tracks the status and real-time location of all active technicians and upcoming work orders for the current day.",
            },
            isTopLevel: false,
            isInformational: true,
            step_completed: false
          },
          {
            step_id: 2,
            title: "Employee Real-Time Tracking",
            target_element_id: 'mapContainer',
            target_query_path: '.stepThree',
            step_message: {
              title: "Employee Real-Time Tracking",
              message: "This map tracks the status and real-time location of all active technicians and upcoming work orders for the current day.",
            },
            isTopLevel: false,
            isInformational: true,
            step_completed: false
          },
          {
            step_id: 3,
            title: "Employee Real-Time Tracking",
            target_element_id: 'mapContainer',
            target_query_path: '.stepFour',
            step_message: {
              title: "Employee Real-Time Tracking",
              message: "This map tracks the status and real-time location of all active technicians and upcoming work orders for the current day.",
            },
            isTopLevel: false,
            isInformational: true,
            step_completed: false
          },
          {
            step_id: 4,
            title: "Employee Real-Time Tracking",
            target_element_id: 'mapContainer',
            target_query_path: '.stepFive',
            step_message: {
              title: "Employee Real-Time Tracking",
              message: "This map tracks the status and real-time location of all active technicians and upcoming work orders for the current day.",
            },
            isTopLevel: false,
            isInformational: false,
            step_completed: false
          }
        ]
    };
   
    constructor() { }

    public getWalkthrough(): GuidedWalkthroughPage {
        return this.walkthrough;
    }

};