import { Component, OnInit, ViewChild } from '@angular/core';
import { InfoPanelImgSlide } from 'src/app/data-models/models';
import { AnimationType } from "../../carousel-support/carousel-support.animations";
import { CarouselSupportComponent } from "../../carousel-support/carousel-support.component";

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-vendor-features',
  templateUrl: 'vendor-features.component.html',
  styleUrls: ['vendor-features.component.scss']
})
export class VendorFeaturesComponent implements OnInit {
  @ViewChild(CarouselSupportComponent) carousel: CarouselSupportComponent;

  animationType = AnimationType.JackInTheBox;

  animationTypes = [
    {
      name: "Scale",
      value: AnimationType.Scale
    },
    {
      name: "Fade",
      value: AnimationType.Fade
    },
    {
      name: "Flip",
      value: AnimationType.Flip
    },
    {
      name: "Jack In The Box",
      value: AnimationType.JackInTheBox
    }
  ];

// Old Slides
// slides: Slide[] = [
//   {
//     headline: "Real-Time Dispatching",
//     src:
//       "../../../assets/images/vendor/platform/dispatch.png",
//     descriptors: [
//       "Active Technician Location",
//       "Work Order Tracking",
//       "Artificial Intelligence Disaster Support",
//       "Cross Load Error Checking",
//       "Automated Workload Balancing",
//       "A.I. Powered Work Order Monitoring"
//     ]
//   },
//   {
//     headline: "Multi Warehouse Inventory",
//     src:
//       "../../../assets/images/vendor/platform/inventory.png",
//       descriptors: [
//         "Part Reservation System",
//         "Customizable Quantity Alerting",
//         "Barcode Scanner Integration",
//         "Easy Inventory Database Import / Export",
//         "Global and Local Search",
//         "Hierarchical Inventory Management"
//       ]
//   },
//   {
//     headline: "Employee Scheduling",
//     src:
//       "../../../assets/images/vendor/platform/shift_scheduling.png",
//       descriptors: [
//         "Employee Leave Requesting",
//         "Hours of Operation Mapping",
//         "Shift Error Cross Checking",
//         "Geo Location Employee Clock-In",
//         "Custom Shift Creator",
//         "A.I. Monitored Scheduling",
//         "Automated Work Load Shift Suggestions"
//       ]
//   },
//   {
//     headline: "Work Scheduling",
//     src:
//       "../../../assets/images/vendor/platform/work_scheduling.png",
//       descriptors: [
//         "Employee Leave Requesting",
//         "Hours of Operation Mapping",
//         "Shift Error Cross Checking",
//         "Geo Location Employee Clock-In",
//         "Custom Shift Creator",
//         "A.I. Monitored Scheduling",
//         "Automated Work Load Shift Suggestions"
//       ]
//   },
//   {
//     headline: "Invoice",
//     src:
//       "../../../assets/images/vendor/platform/invoice.png",
//       descriptors: [
//         "Employee Leave Requesting",
//         "Hours of Operation Mapping",
//         "Shift Error Cross Checking",
//         "Geo Location Employee Clock-In",
//         "Custom Shift Creator",
//       ]
//   },
//   {
//     headline: "Control Center",
//     src:
//       "../../../assets/images/vendor/platform/admin.png",
//       descriptors: [
//         "Employee Leave Requesting",
//         "Hours of Operation Mapping",
//         "Shift Error Cross Checking",
//         "Geo Location Employee Clock-In",
//         "Custom Shift Creator",
//         "A.I. Monitored Scheduling"
//       ]
//   }
// ];


  slides: InfoPanelImgSlide[] = [
    {
      id: "null",
      headline: "Real-Time Dispatching",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "local_shipping",
      src:
        "../../../assets/images/vendor/platform/dispatch_v2.png",
      pageDescriptor: "Quickly and confidently understand your landscape. Schedules are always changing and so should your ability to pivot and reasses a situation. With a real-time map displaying your daily work orders and technicians to specialized gantt charts and work order tables, you'll never lose control of the day.",
      descriptors: [
        { id: "null", text: "Active Technician Location" },
        { id: "null", text: "Work Order Audit Log" },
        { id: "null", text: "Customer text and email automation" },
        { id: "null", text: "Automated Workload Balancing" },
        { id: "null", text: "A.I. Powered Work Order Monitoring" },
        { id: "null", text: "Eco Friendly Routing" },
        { id: "null", text: "Full Technician Dispatch Map Overview" }
      ]
    },
    {
      id: "null",
      headline: "Project Management",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "construction",
      src:
        "../../../assets/images/vendor/platform/project_management_v2.png",
      pageDescriptor: "Understanding where everyone is and what projects need to be done are vital to any organization. We take things 5 steps further by not only showing what needs to be accomplished today, but what projects are coming up and if any leads or jobs are stalling out.",
      descriptors: [
        { id: "null", text: "Customizable Pipelines with shared pipes" },
        { id: "null", text: "Project team chat" },
        { id: "null", text: "Internal data sync across all modules" },
        { id: "null", text: "Monitored projects with user defined rotting metrics" },
        { id: "null", text: "Activity and Task audit logging" },
        { id: "null", text: "Work order tracking" },
        { id: "null", text: "Global team and employee assignment" }
      ]
    },
    {
      id: "null",
      headline: "Forms",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "file_copy",
      src:
        "../../../assets/images/vendor/platform/forms_overview_v2.png",
      pageDescriptor: "Nothing can be done without getting it first in writing. We've made everyday tasks such as change order requests, collecting signatures, and even sending document or work orders out to external parties such as subcontractors as easy as 1, 2, 3. All powered by our simple drag and drop form builder.",
      descriptors: [
        { id: "null", text: "Drag and drop form builder" },
        { id: "null", text: "Signature requests with realtime audit logging" },
        { id: "null", text: "External document share ability" },
        { id: "null", text: "Form Templating" },
        { id: "null", text: "Status overview and audit tracking" },
        { id: "null", text: "Cross module data syncing" },
        { id: "null", text: "Single click change orders" }
      ]
    },
    {
      id: "null", 
      headline: "Multi Warehouse CMMS",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "warehouse",
      src:
        "../../../assets/images/vendor/platform/inventory_v2.png",
      pageDescriptor: "Swarm Operative's CMMS module isn't made for just simple inventory tracking. We've enhanced and integrated every aspect of it allowing you to monitor all of your assets, trucks, tools and even your storage facility. All while paring this critical type of information into every work order with ease.",
      descriptors: [
        { id: "null", text: "Part Reservation System" },
        { id: "null", text: "Customizable Quantity Alerting" },
        { id: "null", text: "Global and Local Search" },
        { id: "null", text: "Hierarchical Inventory Management" },
        { id: "null", text: "Barcode Scanner Integration" },
        { id: "null", text: "Global and Local Search" },
        { id: "null", text: "Easy Inventory Database Import / Export" },
        { id: "null", text: "Track Inventory, Assets, and Facility Management" }
      ]
    },
    {
      id: "null", 
      headline: "Shift Scheduling",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "calendar_month",
      src:
        "../../../assets/images/vendor/platform/shift_scheduling.png",
      pageDescriptor: "Keeping track of your employees and assigning them shifts or to on-call has never been so clear and easy. Integrated timesheets, PTO Requests, and even shift swaps are here to revitalize your entire operation and ensure you're on top of all aspects of your business.",
      descriptors: [
        { id: "null", text: "Employee PTO Requesting" },
        { id: "null", text:  "Hours of Operation Mapping" },
        { id: "null", text: "Auto Timesheet Tracking" },
        { id: "null", text: "Geo Location Employee Clock-In" },
        { id: "null", text: "Custom Shift Creator" },
        { id: "null", text: "Shift Swap Requests" },
        { id: "null", text: "A.I. Monitored Scheduling" },
        { id: "null", text: "Automated Work Load Shift Suggestions" },
      ]
    },
    {
      id: "null", 
      headline: "Work Scheduling",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "home_repair_service",
      src:
        "../../../assets/images/vendor/platform/work_scheduling.png",
      pageDescriptor: "You have 30 seconds or less from the time a customer calls in to the point of scheduling a work order. Newton assists in this process significantly by analyzing addresses to ensure their in your service area. We also revamped the traditional daily or weekly swim lane or gantt chart scheduling. To make things far easier and clearer, we provide an overview to see a weekly glance at all work being done.",
      descriptors: [
        { id: "null", text: "24/7 Shift Overlap Monitoring (double tapped shift)" },
        { id: "null", text: "A.I. Work Order Assigning Assistance " },
        { id: "null", text: "Service Area Analyzer" },
        { id: "null", text: "Scheduled Work Order Overflow Assistance" },
        { id: "null", text: "Live Technician Efficiency Tracker" },
        { id: "null", text: "Smart Geo Work Order Assigning and Geo Cluster" }
      ]
    },
    {
      id: "null", 
      headline: "Control Center",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "filter_tilt_shift",
      src:
        "../../../assets/images/vendor/platform/admin_v2.png",
      pageDescriptor: "Complex features and functions don't need to be complex. Swarm provides a robust set of tools to allow you to run your business as effective as possible.",
      descriptors: [
        { id: "null", text: "100% Customizable Work Orders" },
        { id: "null", text: "Role and Group Management" },
        { id: "null", text: "Full Control Over Employee Accounts and Information" },
        { id: "null", text: "Customizable UI Builder" }
      ]
    },
    {
      id: "null", 
      headline: "Invoice",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      icon: "request_quote",
      src:
        "../../../assets/images/vendor/platform/invoice_v2.png",
      pageDescriptor: "Save hours of work each week by automating invoices, providing direct links for payment to your customers, and even allowing financing so you never have to worry about turning away a job.",
      descriptors: [
        { id: "null", text: "Invoice Customization" },
        { id: "null", text: "Payments Through Platform / Mobile Payments" },
        { id: "null", text: "Invoice Tracker Auto Customer Notifications" },
        { id: "null", text: "Third-Party Financing" },
        { id: "null", text: "QR Code Payments" },
        { id: "null", text: "One Click Invoice Generation" }
      ]
    },
    {
      id: "null", 
      headline: "Upcoming",
      isImgFullScreen: false,
      isImgLeftAligned: false,
      gradientColor: true,
      src:
        "../../../assets/images/vendor/platform/partner_program.jpg",
      pageDescriptor: "Your operation is always evolving and taking on new projects. We aim to grow with you and to provide you with features you may not even know you need just yet.",
      descriptors: [
        { id: "null", text: "Payroll", icon: "account_balance"},
        { id: "null", text: "Language Translation", icon: "language" },
        { id: "null", text: "Integrations", icon: "hub" },
        { id: "null", text: "HR", icon: "chrome_reader_mode" }
      ]
    }
  ];

  folders: Section[] = [
    {
      name: 'Real-Time Technician Locations',
      updated: new Date('1/1/16'),
    },
    {
      name: 'Automated Scheduling',
      updated: new Date('1/17/16'),
    },
    {
      name: 'Fuel Efficient Routing',
      updated: new Date('1/28/16'),
    }
  ];

  notes: Section[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('2/20/16'),
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('1/18/16'),
    }
  ];

  invoices: Section[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('2/20/16'),
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('1/18/16'),
    }
  ];

  technicians: Section[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('2/20/16'),
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('1/18/16'),
    }
  ];

  mobiles: Section[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('2/20/16'),
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('1/18/16'),
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  setAnimationType(type) {
    this.animationType = type.value;
    setTimeout(() => {
      this.carousel.onNextClick();
    });
  }

}