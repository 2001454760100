import { Component, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { ChatAdapter } from '../ng-chat/core/chat-adapter';
import { MessagingAdapter } from './messaging-adapter';
import { AdapterPagedHistory } from './adapter-paged-history';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { HeartBeatService } from 'src/app/services/vendor/heartbeat.service';

@Component({
  selector: 'app-instant-messaging',
  templateUrl: './instant-messaging.component.html',
  styleUrls: ['./instant-messaging.component.scss']
})
export class InstantMessagingComponent {
  title = 'app';
  chats: any;
  openedChats: number = 0;

  profileId: string = this.navigatorService.getProfileId();

  @Input('hide') hide: boolean = true;
  @Output() chatCountEmitter: EventEmitter<number> = new EventEmitter<number>();

  // public adapter: ChatAdapter = new MessagingAdapter();

  public adapter: ChatAdapter = new AdapterPagedHistory(this.navigatorService, this.heartBeatService);
  public messagingAdapter: ChatAdapter = new MessagingAdapter(this.navigatorService, this.heartBeatService, this.imageHandler);

  constructor(private navigatorService: NavigatorService, private imageHandler: ImageHandlerService, private heartBeatService: HeartBeatService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Hide Friends List: ", this.hide);
  }

  // listeners() {
  //   let IMTitle: any = document.querySelector("ng-chat-friends-list").firstElementChild;
  //   this.chats = document.querySelectorAll(".ng-chat-window");
  //   let instantMessaging: any = document.querySelector(".ng-chat-title");
  //   var ctx = this;

  //   // instantMessaging.addEventListener('click', function() {
  //   //   document.querySelector(".messageIcon").classList.remove('messageIconHide');
  //   //   document.querySelector(".messageIcon").classList.add('messageIconShow');

  //   //   IMTitle.classList.remove('messageShow');
  //   //   IMTitle.classList.add('messageHide');
  //   //   ctx.isActiveMessaging = false;
  //   // });

  //   for(let chat in this.chats) {
  //     console.log("Chat: ", chat);
  //     // chat.addEventListener('click', function() {
  //     //   console.log("Chat Click");
  //     // });
  //   } 
  // }

  // registerChatListeners() {
  //   this.chats = document.querySelectorAll(".ng-chat-window");

  //   for(let chat of this.chats) {
  //     console.log("Chat v3: ", chat);
  //     chat.addEventListener('click', function() {
  //       console.log("Chat Click");
  //     });
  //   } 

  //   // Array.from(this.chats).forEach((el) => { 
  //   //   console.log("Chat V2: ", el);

  //   //   el.addEventListener('click', function() {
  //   //     console.log("Chat Click");
  //   //   });
  //   // });
  // }

  // unregisterListeners() {

  // }

  public messageSeen(event: any)
  {
    console.log(event);
  }

  public chatOpened(event: any) {
    console.log("Chat Opened: ", event);
    // let testElement = document.querySelector("ng-chat-window .ng-chat-title");
    let testElement = document.querySelector("#top-toolbar");

    console.log("Test Element: ", testElement);

    // this.eventFire(testElement, 'click');

    this.openedChats++;

    this.chatCountEmitter.emit(this.openedChats);
  }

  public chatClosed(event: any) {
    this.openedChats--;

    this.chatCountEmitter.emit(this.openedChats);
    console.log("Chat Closed: ", event);
  }


  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}