import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CRMActivity, CRMDeal } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

export interface ActivityDialogData {
  activity: CRMActivity,
  deal: CRMDeal
}

@Component({
    selector: 'app-vendor-crm-activity-dialog.component',
    templateUrl: 'vendor-crm-activity-dialog.component.html',
    styleUrls: ['vendor-crm-activity-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorCRMActivityDialogComponent implements OnInit {
    activity: CRMActivity = null;
    
    constructor(
      public matDialogRef: MatDialogRef<VendorCRMActivityDialogComponent>,
      private navigatorService: NavigatorService, 
      @Inject(MAT_DIALOG_DATA) public activityData: ActivityDialogData) {

    }
  
    ngOnInit() {
      this.matDialogRef.disableClose = true; //disable default close operation
    }

    submit(activity: CRMActivity) {

      if(activity != undefined && activity != null) {
        this.activity = activity;
      }

      if(this.activityData.activity == undefined || this.activityData.activity == null) {
        this.close();
      }

    }

    close() {
      this.matDialogRef.close(this.activity);
    }

  }