import { ChatAdapter } from '../ng-chat/core/chat-adapter';
import { IChatGroupAdapter } from '../ng-chat/core/chat-group-adapter';
import { User } from "../ng-chat/core/user";
import { ParticipantResponse } from "../ng-chat/core/participant-response";
import { Message } from "../ng-chat/core/message";
import { MessageType } from "../ng-chat/core/message-type.enum";
import { Group } from "../ng-chat/core/group";
import { ChatParticipantType } from "../ng-chat/core/chat-participant-type.enum";
import { IChatParticipant } from "../ng-chat/core/chat-participant";
import { ChatParticipantStatus } from "../ng-chat/core/chat-participant-status.enum";

import { Observable, of, Subscription } from 'rxjs';
import { delay } from "rxjs/operators";
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { GeneralDataSync, Profile, SessionDataSync } from 'src/app/data-models/models';
import { SafeUrl } from '@angular/platform-browser';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { HeartBeatService } from 'src/app/services/vendor/heartbeat.service';

export class MessagingAdapter extends ChatAdapter implements IChatGroupAdapter
{
    sessionSyncSubscription: Subscription;
    dataSyncSubscription: Subscription;
    employeesSubscription: Subscription;
    imgSubscription: Subscription;

    public employees: Array<Profile> = [];
    public profileImgs: Array<SafeUrl> = [];
    public static participants: IChatParticipant[] = [];

    private employeeUpdateTimestamp: Date = null;

    constructor(private navigatorService: NavigatorService, private heartBeatService: HeartBeatService, private imageHandler: ImageHandlerService) {
        super();

        this.sessionSyncHeartBeat();
        this.dataSyncHeartBeat();
    }

    sessionSyncHeartBeat(): void {
        this.sessionSyncSubscription = this.heartBeatService.getCompanySessionSync().subscribe( (sessionSync: Array<SessionDataSync>) => {

            if(sessionSync != null) {

                    for(let employee of this.employees) {

                        let sessionIndex = sessionSync.findIndex( (session: SessionDataSync) => { return employee.id == session.id });

                        if(sessionIndex > -1 && employee.online_status == ChatParticipantStatus.Offline) {

                            this.navigatorService.getAsyncProfile().then( (profile: Profile) => {

                                if(profile != undefined && profile != null) {

                                    employee.online_status = ChatParticipantStatus.Online;
                                    profile.online_status = ChatParticipantStatus.Online;
                                    this.navigatorService.updateProfile(profile, true);

                                }

                            });
                            
                        }

                        else if(sessionIndex == -1 && employee.online_status == ChatParticipantStatus.Online) {

                            this.navigatorService.getAsyncProfile().then( (profile: Profile) => {

                                if(profile != undefined && profile != null) {

                                    employee.online_status = ChatParticipantStatus.Offline;
                                    profile.online_status = ChatParticipantStatus.Offline;
                                    this.navigatorService.updateProfile(profile, true);

                                }

                            });

                        }

                    }
            
                this.updateParticipants();
            }

        });
    }

    dataSyncHeartBeat(): void {
        this.dataSyncSubscription = this.heartBeatService.getGeneralDataSync().subscribe( (dataSync: GeneralDataSync) => {

            if(dataSync == null || dataSync.categories == null || dataSync.categories.employees == null) {
                dataSync.categories.employees = new Date();
            }

            let categoryTimestamp: number = dataSync.categories.employees.getTime();
            
            if(this.employeeUpdateTimestamp == null || categoryTimestamp > this.employeeUpdateTimestamp.getTime()) {
                this.employeeUpdateTimestamp = new Date();

                this.getEmployees();
            }

        });
    }

    getEmployees(): void {
        this.navigatorService.getAsyncAllEmployees().then(data => { 
          
          if(data != undefined) {
            this.employees = [];
    
            this.employees = data

            this.getImgs();
          }
        });
    }

    public getImgs(): void {

        if(this.employees.length > 0) {
            let imgIds: Array<string> = [];

            for(let employee of this.employees) {
                if(employee.image) {
                    imgIds.push(employee.image);
                } else {
                    imgIds.push(null);
                }
            }

            this.imageHandler.getAsyncFiles(imgIds).then(imgs => {
                if(imgs != null && imgs != undefined) {
                    this.profileImgs = imgs;

                    this.updateParticipants();
                }
            });
        }

        // if(this.imgIds != undefined && this.imgIds.length > 0) {
        //     this.imageHandler.getAsyncFiles(this.imgIds).then(imgs => {
        //         this.employeeGroupProfileImgs = [];
        
        //         if(imgs) {
        
        //         for(let img of imgs) {
        //             this.employeeGroupProfileImgs.push(img);
        //         }
        
        //         this.changeDetectorRef.markForCheck();
        //         this.changeDetectorRef.detectChanges();
        //         }
        
        //     });
        // }
    }

    updateParticipants(): void {
        MessagingAdapter.participants = [];

        for(let employeeIndex: number = 0; employeeIndex < this.employees.length; employeeIndex++) {

            if(this.employees[employeeIndex].online_status == null || this.employees[employeeIndex].online_status == undefined) {
                this.employees[employeeIndex].online_status = ChatParticipantStatus.Offline;
            }

            MessagingAdapter.participants.push(
                {
                    participantType: ChatParticipantType.User,
                    id: this.employees[employeeIndex].id,
                    displayName: this.employees[employeeIndex].first_name + " " + this.employees[employeeIndex].last_name,
                    avatar: this.profileImgs[employeeIndex],
                    status: this.employees[employeeIndex].online_status
                });
        }

        console.log("Participants: ", MessagingAdapter.participants);
    }

    // public static mockedParticipants: IChatParticipant[] = [
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 1,
    //     displayName: "Arya Stark",
    //     avatar: "https://66.media.tumblr.com/avatar_9dd9bb497b75_128.pnj",
    //     status: ChatParticipantStatus.Online
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 2,
    //     displayName: "Cersei Lannister",
    //     avatar: null,
    //     status: ChatParticipantStatus.Online
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 3,
    //     displayName: "Daenerys Targaryen",
    //     avatar: "https://68.media.tumblr.com/avatar_d28d7149f567_128.png",
    //     status: ChatParticipantStatus.Busy
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 4,
    //     displayName: "Eddard Stark",
    //     avatar: "https://pbs.twimg.com/profile_images/600707945911844864/MNogF757_400x400.jpg",
    //     status: ChatParticipantStatus.Offline
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 5,
    //     displayName: "Hodor",
    //     avatar: "https://pbs.twimg.com/profile_images/378800000449071678/27f2e27edd119a7133110f8635f2c130.jpeg",
    //     status: ChatParticipantStatus.Offline
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 6,
    //     displayName: "Jaime Lannister",
    //     avatar: "https://pbs.twimg.com/profile_images/378800000243930208/4fa8efadb63777ead29046d822606a57.jpeg",
    //     status: ChatParticipantStatus.Busy
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 7,
    //     displayName: "John Snow",
    //     avatar: "https://pbs.twimg.com/profile_images/3456602315/aad436e6fab77ef4098c7a5b86cac8e3.jpeg",
    //     status: ChatParticipantStatus.Busy
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 8,
    //     displayName: "Lorde Petyr 'Littlefinger' Baelish",
    //     avatar: "http://68.media.tumblr.com/avatar_ba75cbb26da7_128.png",
    //     status: ChatParticipantStatus.Offline
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 9,
    //     displayName: "Sansa Stark",
    //     avatar: "http://pm1.narvii.com/6201/dfe7ad75cd32130a5c844d58315cbca02fe5b804_128.jpg",
    //     status: ChatParticipantStatus.Online
    // },
    // {
    //     participantType: ChatParticipantType.User,
    //     id: 10,
    //     displayName: "Theon Greyjoy",
    //     avatar: "https://thumbnail.myheritageimages.com/502/323/78502323/000/000114_884889c3n33qfe004v5024_C_64x64C.jpg",
    //     status: ChatParticipantStatus.Away
    // }];

    listFriends(): Observable<ParticipantResponse[]> {
        console.log("Participants: ", MessagingAdapter.participants);

        return of(MessagingAdapter.participants.map(user => {

            console.log("(IN) Participants: ", MessagingAdapter.participants);
            let participantResponse = new ParticipantResponse();

            participantResponse.participant = user;
            participantResponse.metadata = {
                totalUnreadMessages: Math.floor(Math.random() * 10)
            }

            return participantResponse;
        }));
    }

    getMessageHistory(destinataryId: any): Observable<Message[]> {
        let mockedHistory: Array<Message>;

        mockedHistory = [
            {
                fromId: MessageType.Text,
                toId: 999,
                message: "Hi there, here is a sample image type message:",
                dateSent: new Date()
            },
            {
              fromId: 1,
              toId: 999,
              type: MessageType.Image,
              message: "https://66.media.tumblr.com/avatar_9dd9bb497b75_128.pnj",
              dateSent: new Date()
            },
            {
                fromId: MessageType.Text,
                toId: 999,
                message: "Type any message below to test this Angular module.",
                dateSent: new Date()
            },
        ];

        return of(mockedHistory).pipe(delay(2000));
    }

    sendMessage(message: Message): void {

        let replyMessage = new Message();

        replyMessage.message = "You have typed '" + message.message + "'";
        replyMessage.dateSent = new Date();
        if (isNaN(message.toId))
        {
            let group = MessagingAdapter.participants.find(x => x.id == message.toId) as Group;

            // Message to a group. Pick up any participant for this
            let randomParticipantIndex = Math.floor(Math.random() * group.chattingTo.length);
            replyMessage.fromId = group.chattingTo[randomParticipantIndex].id;

            replyMessage.toId = message.toId;

            this.onMessageReceived(group, replyMessage);
        }
        else
        {
            console.log("Messaging Adapter Send: ", message);

            replyMessage.fromId = message.toId;
            replyMessage.toId = message.fromId;

            let user = MessagingAdapter.participants.find(x => x.id == replyMessage.fromId);

            this.onMessageReceived(user, replyMessage);
        }

    }

    groupCreated(group: Group): void {
        MessagingAdapter.participants.push(group);

        MessagingAdapter.participants = MessagingAdapter.participants.sort((first, second) =>
            second.displayName > first.displayName ? -1 : 1
        );

        // Trigger update of friends list
        this.listFriends().subscribe(response => {
            this.onFriendsListChanged(response);
        });
    }
}