import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { text } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';
import { GeneralDataSync, TextMessage } from 'src/app/data-models/models';
import { HeartBeatService } from 'src/app/services/vendor/heartbeat.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';

@Component({
  selector: 'app-vendor-text-chat',
  templateUrl: 'vendor-text-chat.component.html',
  styleUrls: ['vendor-text-chat.component.scss']
})

export class VendorTextChatComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('messageHistoryContainer') messageHistoryContainerRef: any;  
  @Input() singleChatPhoneNumber: string = null;
  @Input() minimized: boolean = false;

  private platformHeartbeatSubscription: Subscription;

  public textMessage: string = "";
  private lastMessagePull: Date = new Date();

  public textMessages: Array<TextMessage> = [];

  constructor(
    private navigatorService: NavigatorService,
    private heartbeat: HeartBeatService, 
    private twilioHandler: TwilioHandlerService) { }

  ngOnInit() {
    this.listeners();

    this.navigatorService.getAsyncTextMessages(this.singleChatPhoneNumber).then( textMessages => {
      console.log("Text Messages - Chat Log: ", textMessages);
      this.textMessages = [];

      if(textMessages != undefined && textMessages != null) {
        this.textMessages = textMessages;
      }

    });

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.navigatorService.getAsyncTextMessages(this.singleChatPhoneNumber).then( textMessages => {
      console.log("Text Messages - Chat Log: ", textMessages);
      this.textMessages = [];

      if(textMessages != undefined && textMessages != null) {
        this.textMessages = textMessages;
      }

    });

  }

  ngOnDestroy(): void {

    if(this.platformHeartbeatSubscription != undefined && this.platformHeartbeatSubscription != null) {
      this.platformHeartbeatSubscription.unsubscribe();
    }
    
  }

  private listeners(): void {
    this.platformHeartbeatSubscription = this.heartbeat.getGeneralDataSyncTrigger().subscribe( (dataSync: GeneralDataSync) => {

      if(dataSync != undefined && dataSync != null) {

          if(dataSync.categories.text_message != undefined && dataSync.categories.text_message != null) {
              
            if(dataSync.categories.text_message.getTime() > this.lastMessagePull.getTime()) {

              this.lastMessagePull = new Date();

              this.navigatorService.getAsyncTextMessages(this.singleChatPhoneNumber).then( textMessages => {
                console.log("Text Messages - Chat Log: ", textMessages);
                this.textMessages = [];

                if(textMessages != undefined && textMessages != null) {
                  this.textMessages = textMessages;
                }

              });

            }

          }

      }

    });
  }

  public updateTextMessage(message: string): void {
    this.textMessage = message;
  }

  public async sendMessage(): Promise<void> {

    if(this.textMessage.length > 0) {

      this.twilioHandler.sendTextMessage(this.textMessage, this.singleChatPhoneNumber).then( () => {

        let textMessage: TextMessage = {
          id: this.navigatorService.generateKey(),
          accountSid: this.navigatorService.getCallCenterId(),
          from: '+12542694774',  
          to: '+1' + this.singleChatPhoneNumber,
          senderId: this.navigatorService.getProfileId(),
          message: this.textMessage,
          dateSent: new Date()
        }

        this.textMessages.push(textMessage);
        this.navigatorService.insertTextMessage(textMessage);

      });



      // if(this.workOrder.b2cMessages == undefined || this.workOrder.b2cMessages == null) {
      //   this.workOrder.b2cMessages = [];
      // }

      // this.workOrder.b2cMessages.push({
      //   id: this.navigatorService.generateKey(this.keytypes.MESSAGE_ID),
      //   senderId: this.navigatorService.getProfileId(),
      //   message: this.textMessage,
      //   date: new Date()
      // });

      // if(!this.customerViewer) {
      //   this.navigatorService.getWorkOrder(this.workOrder.workOrderId).then( async (workOrder: WorkOrder) => {

      //     if(workOrder != undefined && workOrder != null) {

      //       workOrder.b2cMessages = this.workOrder.b2cMessages;

      //       await this.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {

      //         if(status) {
      //           this.twilioHandler.sendTextMessage(this.textMessage);
      //           this.textMessage = null;
      //         }

      //       });

      //     }

      //   });
    //   } else {
    //     this.navigatorService.getWorkOrderAsForeign(this.companyId, this.workOrderId).then( async (workOrder: WorkOrder) => {

    //       if(workOrder != undefined && workOrder != null) {

    //         workOrder.b2cMessages = this.workOrder.b2cMessages;

    //         await this.navigatorService.updateWorkOrderAsForeign(workOrder).then( (status: boolean) => {

    //           if(status) {
    //             this.twilioHandler.sendTextMessage(this.textMessage);
    //             this.textMessage = null;
    //           }

    //         });

    //       }

    //     });
    //   }

    //   this.messageHistoryContainerRef.nativeElement.scrollTop = this.messageHistoryContainerRef.nativeElement.scrollHeight;

    }

  }

}
