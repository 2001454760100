import { Component, OnInit } from '@angular/core';
import { Settings, AppSettings } from '../../app.settings';

@Component({
  selector: 'app-coming-soon-countdown',
  templateUrl: './coming-soon-countdown.component.html',
  styleUrls: ['./coming-soon-countdown.component.scss']
})
export class ComingSoonCountdownComponent implements OnInit {

  public bgImage;
  public settings: Settings;
  constructor(public appSettings:AppSettings) {
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){    
    
  }

}
