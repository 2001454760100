import { Component, OnInit, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavigatorService } from '../../../services/vendor/navigator.service';
import { Observable, Subscription } from 'rxjs'; 

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutoCompleteService, PredifinedPrice, WorkOrderService } from 'src/app/data-models/models';

export interface TableTitles {
  table: string,
  category: String,
  unit: String
}

@Component({
  selector: 'app-vendor-service-tracker',
  templateUrl: 'vendor-service-tracker.component.html',
  styleUrls: ['vendor-service-tracker.component.scss'] 
})

export class VendorServiceTrackerComponent implements OnInit {

  @Input() services: Array<WorkOrderService> = [];
  @Input() tableTitles: TableTitles = {
    table: "Services",
    category: "Service",
    unit: "Hours"
  }

  @Input() predefinedPrices: Array<PredifinedPrice> = [];

  @Input() autocompleteOptions: Array<WorkOrderService> = [];
  public filteredAutocompleteOptions: Array<WorkOrderService> = this.autocompleteOptions;

  @Output() servicesRenderedChange: EventEmitter<Array<WorkOrderService>> = new EventEmitter<Array<WorkOrderService>>();

  public infoForm:FormGroup;
  public options: string[] = ['One', 'Two', 'Three'];

  constructor(private navigatorService: NavigatorService, public formBuilder: FormBuilder, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(this.services == null || this.services == undefined) {
      this.services = [];
    }

    this.services = [...this.services];
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Work Order: " + changes.prop);

    this.filteredAutocompleteOptions = this.autocompleteOptions;

    this.updateInvoiceForm();
  } 

  public addService(): void {
    this.services.push(
      {
        desc: "",
        price: null,
        chargePrice: null,
        hrs: null
      }
    );

    this.servicesRenderedChange.emit(this.services);
  }

  public removeService(serviceIndex: number): void {
    this.services.splice(serviceIndex, 1);

    this.servicesRenderedChange.emit(this.services);
  }

  public addPredefinedService(predefinedPrice: PredifinedPrice): void {
    this.services.push(
      {
        desc: predefinedPrice.title,
        price: predefinedPrice.price,
        chargePrice: predefinedPrice.chargePrice,
        hrs: 0
      }
    );

    this.servicesRenderedChange.emit(this.services);
  }

  public updateServices(): void {
    this.servicesRenderedChange.emit(this.services);
  }
  
  public updateInvoiceForm(): void {
    if(this.infoForm != undefined) {
      this.infoForm.reset();
    }
  }

  public fieldChange(text: string): void {
    this.filteredAutocompleteOptions = this.filterValues(text);
    this.updateServices();
  }

  public filterValues(search: string): Array<WorkOrderService> {
    return this.autocompleteOptions.filter(value=>
    value.desc.toLowerCase().indexOf(search.toLowerCase()) === 0);
  }

  public autofill(service: WorkOrderService, autocomplete: AutoCompleteService): void {

    service.desc = autocomplete.desc;
    service.price = autocomplete.price;
    service.chargePrice = autocomplete.chargePrice;

    this.updateServices()
  }

} 