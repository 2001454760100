import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import {FormControl} from '@angular/forms';
import { GroupedObservable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {groups} from './groups/vendor-calendar-groups';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorShiftSwapDialogComponent } from '../../professional/vendor-shift-swap-dialog/vendor-shift-swap-dialog.component';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { AssignedShift, Color, EmployeeSchedule, Profile, Shift, ScheduleSwap } from '../../../data-models/models';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

let activeDate = new Date();

@Component({
  selector: 'app-vendor-shift-swap-request',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-shift-swap-request.component.html',
  styleUrls: ['vendor-shift-swap-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VendorShiftSwapRequestComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  groupOptionSelected: number = 0;
  selectOptions = groups;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  showMarker: boolean = false;

  public weekDays: Array<number> = [0, 1, 2, 3, 4, 5 ,6];

  public deleteBroadcast: boolean = false;

  profileIndex: number = null;
  public employees: Array<Profile> = [];
  public schedule: Array<EmployeeSchedule> = [];
  public scheduleSwap: ScheduleSwap;
  public requestStatusEnum: any;
  public keyTypesEnum: any;

  @Input()
  public activeShift: Shift;

  @Output() 
  requestScheduleSwapEvent = new EventEmitter<ScheduleSwap>();

  public openShiftSwapDialogRef: MatDialogRef<VendorShiftSwapDialogComponent>;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: Array<CalendarEvent[]> = [];

  activeDayIsOpen: boolean = false;

  constructor(
    private modal: 
    NgbModal,
    private navigatorService: NavigatorService,
    private changeDetectorRef: ChangeDetectorRef, 
    public dialog: MatDialog) { 

    for(let index = 0; index < groups.length; index++) {
      this.events.push([]);

      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events[this.groupOptionSelected].push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date,

          actions: [
            {
              label: '<i class="actionLayer"></i>',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
                console.log('Event deleted', event);
              }
            }
          ]
        })
      }
    }
  }

  ngOnInit(): void {

    this.requestStatusEnum = this.navigatorService.getShiftSwapRequestStatuses();
    this.keyTypesEnum = this.navigatorService.getKeyTypes();

    this.navigatorService.getAllEmployees().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;
      this.employees = [];

      if(data != null) {
        for(let index = 0; index < data.length; index++) {
          this.employees.push(employees[index]);

          this.schedule.push(
            {
              employeeId: employees[index].id,
              shifts: []
            }
          )

          if(employees[index].id == this.navigatorService.getProfileId()) {
            this.scheduleSwap = {
              id: this.navigatorService.generateKey(this.keyTypesEnum.SHIFT_SWAP_REQUEST_ID),
              requestProfile: employees[index].data,
              swapProfile: null,
              requestShift: null,
              swapShift: null,
              status: this.requestStatusEnum.REVIEW,
              requestorNotes: "",
              reviewerNotes: ""
            }

            this.profileIndex = index;
            this.requestScheduleSwapEvent.emit(this.scheduleSwap);
          }
        }

        this.navigatorService.getShiftSchedules().subscribe(data => { 
          // let result = this.filterData(data); 
    
          if(data != null) {
            this.loadEmployeeSchedules(data);
          } 
    
          this.changeDetectorRef.detectChanges();
        });

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngAfterViewInit(): void {

  }

  loadEmployeeSchedules(scheduleData: any): void {
    // this.schedule = [];
    let shifts: any;

    for(let index = 0; index < scheduleData.length; index++) {
      this.schedule.find((element, i) => {
        if(element.employeeId == scheduleData[index].data.employeeId) {
          this.schedule[i].shifts = scheduleData[index].data.shifts;
        }
      });

      // if(shifts) {
      //   shifts.shifts = scheduleData[index].data.shifts;
      // }
    }
  }

  nextMonth(): void {
    activeDate = addMonths(activeDate, 1);
  }

  previousMonth(): void {
    activeDate = addMonths(activeDate, -1);
  }


  setGroupFilter(group: number): void {
    this.groupOptionSelected = group;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  getShiftIndexes(segment: any): Array<number> {
    let indexes: Array<number> = [];
    let employeeSchedule: EmployeeSchedule = this.schedule[segment.date.getHours()];
    let storedMonth: number;
    let storedDay: number;
    let month: number;
    let day: number;

    for(let index: number = 0; index < employeeSchedule.shifts.length; index++) {
      month = employeeSchedule.shifts[index].date.getMonth();
      day = employeeSchedule.shifts[index].date.getUTCDate();
      storedMonth = segment.date.getMonth();
      storedDay = segment.date.getUTCDate();


      if(month == storedMonth && day == storedDay) {
        indexes.push(index);
      }
    }

    return indexes;
  }

  isShiftAssigned(segment: any): boolean {
    let isAssigned: boolean = false;
    let employeeSchedule: EmployeeSchedule = this.schedule[segment.date.getHours()];
    let storedMonth: number;
    let storedDay: number;
    let month: number;
    let day: number;

    for(let index: number = 0; index < employeeSchedule.shifts.length; index++) {
      month = employeeSchedule.shifts[index].date.getMonth();
      day = employeeSchedule.shifts[index].date.getUTCDate();
      storedMonth = segment.date.getMonth();
      storedDay = segment.date.getUTCDate();


      if(month == storedMonth && day == storedDay) {
        isAssigned = true;
        break;
      }
    }

    return isAssigned;
  }

  assignSwapShift(employeeIndex: number, shiftIndex: number): void {
    if(!this.scheduleSwap.requestShift) {
      this.scheduleSwap.requestShift = this.schedule[employeeIndex].shifts[shiftIndex];
    } else {
      this.scheduleSwap.swapProfile = this.employees[employeeIndex];
      this.scheduleSwap.swapShift = this.schedule[employeeIndex].shifts[shiftIndex];
    }
  }

  addShift(employeeIndex: number, dayIndex: number, date: Date): void {

    if(!this.deleteBroadcast) {
      let shift: AssignedShift = {
        date: date,
        shift: JSON.parse(JSON.stringify(this.activeShift))
      };

      this.schedule[employeeIndex].shifts.push(shift);
    }

    this.deleteBroadcast = false;
  }

  contextMenuAddEvent(date: Date): void {
    this.events = [];

    this.refresh.next();
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
    }

    this.activeDayIsOpen = false;
  }

  openSwapRequestDialog() {

    this.openShiftSwapDialogRef = this.dialog.open(
      VendorShiftSwapDialogComponent,
      {
        // data: {
        //   workOrderData: this.workOrders[workOrderIndex],
        //   requestId: employeeId
        // }
      }
    );

    this.openShiftSwapDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }

}


