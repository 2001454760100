import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Profile } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

@Component({
  selector: 'app-vendor-menu',
  templateUrl: './vendor-menu.component.html',
  styleUrls: ['./vendor-menu.component.scss']
})
export class VendorMenuComponent implements OnInit {
  profile: Profile = null;

  username: string = "Loading";
  jobTitle: string = "loading";

  constructor(public appService:AppService, private navigatorService: NavigatorService) { }

  ngOnInit() {
    this.profile = this.navigatorService.getCurrentProfile();

    this.username = this.profile.first_name + " " + this.profile.last_name;
    this.jobTitle = this.profile.job_title;
  }

  public logout():void {
    console.log("Vendor Menu Logoff");
    this.navigatorService.logout();
  }

}
