import { Component, OnInit, AfterViewInit, Input, SimpleChanges  } from '@angular/core';

import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChartDataSets, ChartOptions, ChartType, ChartData } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { LineChartData } from 'src/app/data-models/models';


@Component({
  selector: 'app-vendor-chart-line',
  templateUrl: 'vendor-chart-line.component.html',
  styleUrls: ['vendor-chart-line.component.scss']
})
export class VendorChartLineComponent implements OnInit, AfterViewInit {

  @Input() labels: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  @Input() data: LineChartData = {
    data: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
      { data: [92, 34, 40, 81, 65, 55, 74], label: 'Series B' }
    ]
  };

  public lineChartData: ChartDataSets[];

  public lineChartLabels: Label[];

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 12
  };

  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  public doughnutChartType: ChartType = 'doughnut';
    
  constructor(public snackBar: MatSnackBar, public navigatorService: NavigatorService) {
      
  }
  
  ngOnInit(): void {
    this.lineChartLabels = this.labels;
    this.lineChartData = this.data.data;
  }
  
  ngAfterViewInit(): void {
      
  }

  ngOnDestroy() {
    
  }  

  ngOnChanges(changes: SimpleChanges): void {

    this.lineChartData = this.data.data;
    this.lineChartLabels = this.labels;
    
  }

  // ===============================
  // Charts Logic
  // ===============================

  // Doughnut Events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
