import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-vendor-phone-hold',
  templateUrl: 'vendor-phone-hold.component.html',
  styleUrls: ['vendor-phone-hold.component.scss']
})
export class VendorPhoneHoldComponent implements OnInit { 
  @Input() minimized: boolean = false;

  constructor() { }

  ngOnInit() {
    
  }

}
