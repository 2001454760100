import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Profile } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LockScreenComponent implements OnInit {
  profile: Profile = null;

  username: string = "Loading";
  jobTitle: string = "loading";

  public imgId: string = null;
  public image: any;
  
  public date:any = new Date();
  public timerInterval:any;
  public form: FormGroup;
  constructor(public fb: FormBuilder, public router:Router, private navigatorService: NavigatorService, private imageHandler: ImageHandlerService) { }

  ngOnInit() {
    this.timerInterval = setInterval(() => {
      this.date = new Date();
    }, 1000);
    this.form = this.fb.group({ 
      password: [null, Validators.compose([Validators.required, Validators.minLength(3)])]
    });

    this.profile = this.navigatorService.getCurrentProfile();

    this.username = this.profile.first_name + " " + this.profile.last_name;
    this.jobTitle = this.profile.job_title;
    this.imgId = this.profile.image;

    this.getImg();
  }

  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');
  }

  ngOnDestroy(){

    clearInterval(this.timerInterval);
  }

  public onSubmit(values:Object):void {
    if (this.form.valid) {
      this.router.navigate(['/']);
    }
  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getAsyncFiles([this.imgId]).then(images => {
        if(images) {
          this.image = images[0];

        }
      });
    }
  }

}
