import {Component, OnInit, ViewEncapsulation, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CRMDeal } from 'src/app/data-models/models';


@Component({
    selector: 'app-vendor-project-dialog.component',
    templateUrl: 'vendor-project-dialog.component.html',
    styleUrls: ['vendor-project-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorProjectDialogComponent implements OnInit {

    constructor(
      @Inject(MAT_DIALOG_DATA) public project: CRMDeal) {

    }
  
    ngOnInit() {

      console.log("Init Project Dialog: ", this.project);
    }

}