import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

import { VendorSidebarMenuService } from '../../services/vendor/vendor-sidebar-menu.service'
import { NavigatorService, keyTypesEnum } from '../../services/vendor/navigator.service';
import { ImageHandlerService } from '../../services/vendor/image-handler.service';
import { VendorAddInventoryItemDialogComponent } from '../../shared/professional/vendor-add-inventory-item-dialog/vendor-add-inventory-item-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CMMSLocation } from 'src/app/data-models/models';

@Component({
  selector: 'app-inventory-toolbar',
  templateUrl: './inventory-toolbar.component.html',
  styleUrls: ['./inventory-toolbar.component.scss']
})
export class InventoryToolbarComponent implements OnInit {
  @Input() isHomePage:boolean = false;
  @Input() showSidenavToggle:boolean = false;
  @Input() pickerView:boolean = false;
  @Output() onSidenavToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeSorting: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeViewType: EventEmitter<any> = new EventEmitter<any>();

  private addInventoryDialogRef: MatDialogRef<VendorAddInventoryItemDialogComponent>;

  private keyTypes:any = keyTypesEnum;
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public counts = [8, 12, 16, 24, 36];
  public count:any;
  public sortings = ['Sort by Default', 'Newest', 'Oldest', 'Popular', 'Price (Low to High)', 'Price (High to Low)'];
  public sort:any;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private navigatorService: NavigatorService, private sideMenu: VendorSidebarMenuService, private imageHandler: ImageHandlerService) { }

  ngOnInit() {
    this.count = (this.isHomePage) ? this.counts[0] : this.counts[1];
    this.sort = this.sortings[0];

    if(this.pickerView) {
      this.changeViewType('list', 100);
    }
  }

  ngOnChanges(){
    // console.log(' show toggle - ' ,this.showSidenavToggle)
  }

  public changeCount(count){
    this.count = count;
    this.onChangeCount.emit(count);

    this.sideMenu.setInventoryPageLimit(count);
    // this.getAllProducts(); 
  }

  public filterSearch(searchFilter: string): void {
    let focusLocations: Array<CMMSLocation> = this.sideMenu.getFocusedCMMSLocations();
    this.navigatorService.getPagedSearchDistinctBaseInventory(searchFilter, focusLocations, 0, this.count);

    this.sideMenu.setInventoryTextFilter(searchFilter);
  }

  public changeSorting(sort){
    this.sort = sort;
    this.onChangeSorting.emit(sort);
  }

  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
    this.onChangeViewType.emit({viewType:viewType, viewCol:viewCol});
  }

  public sidenavToggle(){
    this.onSidenavToggle.emit();
  }

  public openNewInventoryDialog() {
    this.addInventoryDialogRef = this.dialog.open(
      VendorAddInventoryItemDialogComponent,
      { data: null }
    );

    this.addInventoryDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("Inventory Item: ", data);

      for(let galleryIndex: number = 0; galleryIndex < data.gallery.length; galleryIndex++) {
        let file: File = data.gallery[galleryIndex].file;
        let imgId: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgId);

        data.inventoryData.lockedFields.gallery.push(imgId);
      }

      console.log("On New Inventory Item Close: ", data.inventoryData);

      this.navigatorService.upsertInventoryBase(data.inventoryData).then(baseReponse => {

        this.navigatorService.upsertInventory(data.inventoryData).then(inventoryResponse => {

          this.navigatorService.getBaseInventory();
          this.snackBar.open('Inventory Item Uploaded!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

        });

      });
    
    })
  }

}
