import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { startOfDay } from 'date-fns';
import { GanttTask, Profile, WorkOrder } from 'src/app/data-models/models';
import { keyTypesEnum, NavigatorService, workOrderStatusTypes } from 'src/app/services/vendor/navigator.service';

import { Gantt } from './frappe-gantt/frappe_gantt'

export enum ganttViewTypes {
  FULL_VIEW = 0,
  DISCREET_VIEW = 1
}

@Component({
  selector: 'app-vendor-work-order-gantt',
  templateUrl: 'vendor-work-order-gantt.component.html',
  styleUrls: ['vendor-work-order-gantt.component.scss']
})
export class VendorWorkOrderGanttComponent implements OnInit {
  viewType: any = ganttViewTypes;
  keytypes: any = keyTypesEnum;
  workOrderStatusTypes: any = workOrderStatusTypes;
  startDate: Date = new Date();
  endDate: Date = new Date();

  @Input('workOrders') workOrders: Array<WorkOrder> = [];
  @Input('employees') employees: Array<Profile> = [];
  @Input("viewMode") viewMode: number = this.viewType.FULL_VIEW;
  @Input("expandCollapse") expand: boolean = true;
  @Input("actionMenu") actionMenu: boolean = true;

  @Output('ganttDataUpdated') ganttDataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('ganttViewModeUpdated') ganttViewModeUpdated: EventEmitter<number> = new EventEmitter<number>();
  @Output('ganttExpandCollapseUpdated') ganttExpandCollapseUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  employeeGanttData: any = [];

  // public tasks: Array<GanttTask> = [
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Redesign website',
  //     id: "Task 0",
  //     sub_tasks: [
  //       "Task 1"
  //     ],
  //     isCollapsed: false,
  //     progress: 20
  //   },
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Write new content',
  //     id: "Task 1",
  //     progress: 5,
  //     sub_tasks: [
  //       "Task 2"
  //     ],
  //     isCollapsed: false,
  //     dependencies: 'Task 0'
  //   },
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Apply new styles',
  //     id: "Task 2",
  //     progress: 10,
  //     sub_tasks: [
  //       "Task 3",
  //       "Task 4"
  //     ],
  //     isCollapsed: false,
  //     dependencies: 'Task 1'
  //   },
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Review',
  //     id: "Task 3",
  //     progress: 5,
  //     isCollapsed: false,
  //     dependencies: 'Task 2'
  //   },
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Deploy',
  //     id: "Task 4",
  //     progress: 0,
  //     sub_tasks: [
  //       "Task 5"
  //     ],
  //     isCollapsed: false,
  //     dependencies: 'Task 2'
  //   },
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Go Live!',
  //     id: "Task 5",
  //     isCollapsed: false,
  //     progress: 0,
  //     dependencies: 'Task 4',
  //     custom_class: 'bar-milestone'
  //   },
  //   {
  //     start: this.startDate,
  //     end: this.endDate,
  //     name: 'Long term task',
  //     id: "Task 6",
  //     isCollapsed: false,
  //     progress: 0
  //   }
  // ];

  public tasks: Array<GanttTask> = [];
  public wrapper_container: string = "#workOrderGantt";

  constructor(public navigatorService: NavigatorService) { }

  ngOnInit() {
    this.initializeGanttEmployees();

    this.convertWorkOrders().then( data => {
      this.initializeGanttChart();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Gantt Data Change");
    this.resetGanttChart();
  }

  private resetGanttChart(): void {
    this.initializeGanttEmployees();
    this.convertWorkOrders().then( (tasks: Array<GanttTask>) => {

      this.tasks = [];

      if(tasks.length > 0) {
        this.tasks = tasks;
      }

      this.initializeGanttChart();
    });
  }

  initializeGanttEmployees(): void {
    this.employeeGanttData = [];

    for(let employee of this.employees) {

      this.employeeGanttData.push({
        employeeId: employee.id,
        height: "48px"
      });

    }

  }

  async convertWorkOrders(): Promise<Array<GanttTask>> {
    let tasks: Array<GanttTask> = [];

    if(this.workOrders != undefined && this.workOrders != null && this.workOrders.length > 0) {

      for(let workOrder of this.workOrders) {

        await this.convertWorkOrder(workOrder).then( (ganttTasks: Array<GanttTask>) => {
          
          for(let ganttTask of ganttTasks) {
            tasks.push(ganttTask);
          }

        });
  
      }

    }

    return tasks;
  }

  async convertWorkOrder(workOrder: WorkOrder, parentId?: string): Promise<Array<GanttTask>> {
    let tasks: Array<GanttTask> = [];
    let task: GanttTask = null;
    let statusIcon: string = "";
    let status: string = "";

    if(workOrder.dateScheduled == undefined || workOrder.dateScheduled == null) {
      return tasks;
    }

    switch(workOrder.status) {

        case this.workOrderStatusTypes.STANDBY:
          statusIcon = 'sensor_occupied';
          status = "standby";
          break;

        case this.workOrderStatusTypes.HOLD:
          status = "hold";
          statusIcon = 'hourglass_bottom';
          break;

        case this.workOrderStatusTypes.ENROUTE:
          status = "enroute";
          statusIcon = 'local_shipping';
          break;

        case this.workOrderStatusTypes.STARTED:
          status = "started";
          statusIcon = 'construction';
          break;

        case this.workOrderStatusTypes.WORKING:
          status = "working";
          statusIcon = 'construction';
          break;

        case this.workOrderStatusTypes.COMPLETED:
          status = "completed";
          statusIcon = 'published_with_changes';
          break;

        case this.workOrderStatusTypes.CLOSED:
          status = "closed";
          statusIcon = 'task_alt';
          break;

        case this.workOrderStatusTypes.CANCELED:
          status = "canceled";
          statusIcon = 'free_cancellation';
          break;

        case this.workOrderStatusTypes.SCHEDULED:
          status = "scheduled";
          statusIcon = 'event_available';
          break;

        default:
          break;
    }

    if(workOrder.assignedStaff.length > 0 || workOrder.assignedStaff.length > 0) {

      for(let staff of workOrder.assignedStaff) {

        task = {
          start: workOrder.dateScheduled,
          end: this.calculateEndDate(workOrder.dateScheduled, workOrder.timeStretch),
          name: workOrder.description,
          task_id: this.navigatorService.generateKey(this.keytypes.WORK_ORDER_ID),
          id: workOrder.workOrderId,
          employee_id: staff,
          status: status,
          status_icon: statusIcon,
          // sub_tasks: [],
          progress: 50,
          custom_class: 'ganttWorkOrderTask'
        };

        tasks.push(task);
      }

    }

    // if(workOrder.parent_work_order != undefined && workOrder.parent_work_order != null) {
    //   let parentIndex: number = this.workOrders.findIndex( (wo: WorkOrder) => { return wo.workOrderId == workOrder.parent_work_order} );

    //   if(parentIndex > -1) {
    //     task.dependencies = workOrder.parent_work_order;
    //     task.isCollapsed = false;
    //   }
      
    // }

    if(workOrder.foreign_work_orders != undefined && workOrder.foreign_work_orders.length > 0) {
      task.sub_tasks = workOrder.foreign_work_orders;
      

      await this.getChildWorkOrdersAsTasks(workOrder).then( (ganttTasks: Array<GanttTask>) => {
        
        for(let ganttTask of ganttTasks) {
          ganttTask.dependencies = task.task_id;
          ganttTask.isCollapsed = false;

          tasks.push(ganttTask);
        }

      });
      
    } 

    return tasks;
  }

  async getChildWorkOrdersAsTasks(workOrder: WorkOrder): Promise<Array<GanttTask>> {
    let tasks: Array<GanttTask> = [];

    if(workOrder.foreign_work_orders == undefined) {
      return;
    }

    for(let childID of workOrder.foreign_work_orders) {

      await this.navigatorService.getWorkOrder(childID).then( (childWO: WorkOrder) => {

        if(childWO != undefined && childWO != null) {

          this.convertWorkOrder(childWO).then( (ganttTasks: Array<GanttTask>) => {
            
            for(let ganttTask of ganttTasks) {
              ganttTask.isAttached = true;
              tasks.push(ganttTask);
            }

          });
          
        }

      });

    }

    return tasks;
  }

  calculateEndDate(startDate: Date, minutes: number): Date {
    let endDate: Date = new Date(startDate);

    endDate.setMinutes(endDate.getMinutes() + minutes);

    return endDate;
  }

  initializeGanttChart(): void {
    let self: any = this;
    this.endDate.setHours(this.startDate.getHours() + 2);
    
    // if(this.tasks.length > 0) {
      let gantt_chart = new Gantt("#gantt", this.wrapper_container, this.tasks, {

        on_click: function (taskEvent) {
          console.log("Gantt Click: ", taskEvent);
        },

        on_dblclick: function (taskEvent) {
          console.log("Gantt dblclick: ", taskEvent);
        },

        on_date_change: function(task, start: Date, end: Date) {
          let changeWorkOrder: WorkOrder = self.workOrders.find( (workOrder: WorkOrder) => { return workOrder.workOrderId == task.id });

          let startMili: number = start.getTime();
          let endMili: number = end.getTime();

          let diffMili: number = endMili - startMili;
          let diffMinutes: number = Math.round( ( (diffMili / 1000) / 60) );

          if(changeWorkOrder != undefined && changeWorkOrder != null) {

            self.navigatorService.getWorkOrder(changeWorkOrder.workOrderId).then( (workOrder: WorkOrder) => {

              if(workOrder != undefined && workOrder != null) {

                workOrder.dateScheduled = start;
                workOrder.timeStretch = diffMinutes;

                changeWorkOrder = workOrder;

                self.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {
                  self.ganttDataUpdated.emit(status);
                });

              }

            });

          }

          console.log("Gantt Date Change: ", task);
        },

        on_employee_change: function(task, assigned_employee_index, updated_employee_index) {
          console.log("Gantt Employee Change: ", task);
          let employee = null;
          let prevEmployee = null;

          if(updated_employee_index >= 0 && assigned_employee_index >= 0) {
            employee = self.employees[updated_employee_index];
            prevEmployee = self.employees[assigned_employee_index];
          }

          // If the current and previous employee aren't located then do nothing
          if(employee == null || prevEmployee == null) return;


          let changeWorkOrder: WorkOrder = self.workOrders.find( (workOrder: WorkOrder) => { return workOrder.workOrderId == task.id });

          if(changeWorkOrder != undefined && changeWorkOrder != null) {

            self.navigatorService.getWorkOrder(changeWorkOrder.workOrderId).then( (workOrder: WorkOrder) => {

              if(workOrder != undefined && workOrder != null) {

                let prevAssignedEmployeeIndex: number = workOrder.assignedStaff.findIndex((id: string) => { return id == prevEmployee.id });

                if(prevAssignedEmployeeIndex > -1) {
                  workOrder.assignedStaff.splice(prevAssignedEmployeeIndex, 1);
                }

                if(employee != null) {
                  workOrder.assignedStaff.push(employee.id);
                }

                workOrder.technicianId = null;

                changeWorkOrder = workOrder;

                self.navigatorService.updateWorkOrder(workOrder).then( (status: boolean) => {
                  self.ganttDataUpdated.emit(status);
                });

              }

            });

          }

          console.log("Gantt Change Employee", employee);
        },

        on_collapse_task: function(task, deepest_layer) {
          let employeeIndex: number = self.employeeGanttData.findIndex( employee => { return employee.employeeId == task.employee_id });

          if(employeeIndex > -1) {
            let layerCalculation: number = deepest_layer * 48;
            self.employeeGanttData[employeeIndex].height = (48 + layerCalculation) + "px";
          }

          console.log("Gantt Collapse Task: ", deepest_layer);
        },

        on_expand_task: function(task, deepest_layer) {
          let employeeIndex: number = self.employeeGanttData.findIndex( employee => { return employee.employeeId == task.employee_id });

          if(employeeIndex > -1) {
            let layerCalculation: number = deepest_layer * 48;
            self.employeeGanttData[employeeIndex].height = (48 + layerCalculation) + "px";
          }

          console.log("Gantt Expand Task: ", deepest_layer);
        },

        on_progress_change: function(task, progress) {
          console.log(task, progress);
        },

        on_view_change: function(mode) {
          console.log(mode);
        },

        employees: this.employees,
        view_mode: 'Hour',
        language: 'en'
      });
      
      console.log("Gantt Chart: ", gantt_chart);
    // }
  }

  public updateGanttViewMode(viewMode: number): void {
    this.viewMode = viewMode;

    this.ganttViewModeUpdated.emit(this.viewMode);
  }

  public updateGanttExpandCollapse(expand: boolean): void {
    this.expand = expand;

    let gantt_element = document.querySelector(this.wrapper_container);
    gantt_element.scrollTop = 0;

    this.ganttExpandCollapseUpdated.emit(this.expand);
  }

  private eventFire(el, etype) {

    if(el == undefined && el == null) {
      return;
    }

    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

}