import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import {FormControl} from '@angular/forms';
import { GroupedObservable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {groups} from './groups/vendor-calendar-groups';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorShiftSwapDialogComponent } from '../../../shared/professional/vendor-shift-swap-dialog/vendor-shift-swap-dialog.component';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { AssignedShift, Color, EmployeeSchedule, Profile, Shift } from '../../../data-models/models';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

let activeDate = new Date();

@Component({
  selector: 'app-vendor-shift-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-shift-calendar.component.html',
  styleUrls: ['vendor-shift-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VendorShiftCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  groupOptionSelected: number = 0;
  selectOptions = groups;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  showMarker: boolean = false;

  public weekDays: Array<number> = [0, 1, 2, 3, 4, 5 ,6];

  public deleteBroadcast: boolean = false;

  public employees: Array<Profile> = [];
  public schedule: Array<EmployeeSchedule> = [];

  private employeesSubscriber: Subscription;
  private scheduleSubscriber: Subscription;

  @Input()
  public activeShift: Shift;

  @Output() 
  public publishEvent = new EventEmitter<Array<EmployeeSchedule>>();

  public openShiftSwapDialogRef: MatDialogRef<VendorShiftSwapDialogComponent>;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: Array<CalendarEvent[]> = [];

  activeDayIsOpen: boolean = false;

  constructor(
    private modal: 
    NgbModal,
    private navigatorService: NavigatorService,
    private changeDetectorRef: ChangeDetectorRef, 
    public dialog: MatDialog) { 

    for(let index = 0; index < groups.length; index++) {
      this.events.push([]);

      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events[this.groupOptionSelected].push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date,

          actions: [
            {
              label: '<i class="actionLayer"></i>',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
                console.log('Event deleted', event);
              }
            }
          ]
        })
      }
    }
  }

  ngOnInit(): void {
    this.subscribeToEmployees();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
    if(this.scheduleSubscriber) {
      this.scheduleSubscriber.unsubscribe();
    }

    if(this.employeesSubscriber) {
      this.employeesSubscriber.unsubscribe();
    }
  }  

  subscribeToEmployees(): void {
    this.employeesSubscriber = this.navigatorService.getAllEmployees().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;
      this.employees = [];

      if(data != null) {
        for(let index = 0; index < data.length; index++) {
          this.employees.push(employees[index]);

          if(!this.schedule.find(element => element.employeeId === employees[index].id)) {
            this.schedule.push(
              {
                employeeId: employees[index].id,
                shifts: []
              }
            );
          }
        }

        if(!this.scheduleSubscriber) {
          this.subscribeToSchedule();
        } else {
          this.getSchedules();
        }

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getEmployees(): void {
    this.navigatorService.getAllEmployees();
  }

  subscribeToSchedule(): void {
    this.scheduleSubscriber = this.navigatorService.getShiftSchedules().subscribe(data => { 
      // let result = this.filterData(data); 

      if(data != null) {
        this.loadEmployeeSchedules(data);
      } 

      this.changeDetectorRef.detectChanges();
    });
  }

  getSchedules(): void {
    this.navigatorService.getShiftSchedules();
  }

  getContrast(hexcolor: string): string {

    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
  
    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
  
    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
  
    // Get YIQ ratio
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
    // Check contrast
    return (yiq >= 128) ? '#000000' : '#ffffff';
  
  }

  loadEmployeeSchedules(scheduleData: any): void {
    // this.schedule = [];
    let shifts: any;

    for(let index = 0; index < scheduleData.length; index++) {
      this.schedule.find((element, i) => {
        if(element.employeeId == scheduleData[index].data.employeeId) {
          this.schedule[i].shifts = scheduleData[index].data.shifts;
        }
      });

      // if(shifts) {
      //   shifts.shifts = scheduleData[index].data.shifts;
      // }
    }
  }

  // Merging incoming and existing data requires further conditional coding
  // loadEmployeeSchedules(scheduleData: any): void {
  //   let scheduledMonth: number;
  //   let scheduledDay: number;
  //   let incomingMonth: number;
  //   let incomingDay: number;
  //   // this.schedule = [];

  //   for(let scheduleIndex = 0; scheduleIndex < this.schedule.length; scheduleIndex++) {
  //     for(let incomingIndex = 0; incomingIndex < scheduleData.length; incomingIndex++) {

  //       // Check Id
  //       if(this.schedule[scheduleIndex].employeeId == scheduleData[incomingIndex].data.employeeId) {

  //         if(this.schedule[scheduleIndex].shifts.length > 0) {
  //           for(let scheduleShiftIndex = 0; scheduleShiftIndex < this.schedule[scheduleIndex].shifts.length; scheduleShiftIndex++) {
  //             for(let incomingShiftIndex = 0; incomingShiftIndex < scheduleData[incomingIndex].data.shifts.length; incomingShiftIndex++) {

  //               incomingMonth = scheduleData[incomingIndex].data.shifts[incomingShiftIndex].date.getMonth();
  //               incomingDay = scheduleData[incomingIndex].data.shifts[incomingShiftIndex].date.getUTCDate();
  //               scheduledMonth = this.schedule[scheduleIndex].shifts[scheduleShiftIndex].date.getMonth();
  //               scheduledDay = this.schedule[scheduleIndex].shifts[scheduleShiftIndex].date.getUTCDate();

  //               // Check Date
  //               if(incomingMonth == scheduledMonth && incomingDay == scheduledDay) {

  //                 // Check Shift
  //                 if(this.schedule[scheduleIndex].shifts[scheduleShiftIndex].shift.id == scheduleData[incomingIndex].data.shifts[incomingShiftIndex].shift.id) {
  //                   // Do Nothing
  //                 } else {
  //                   this.schedule[scheduleIndex].shifts.push(scheduleData[incomingIndex].data.shifts[incomingShiftIndex]);
  //                 }
  //               }
  //             }
  //           }
  //         } else {
  //           for(let incomingShiftIndex = 0; incomingShiftIndex < scheduleData[incomingIndex].data.shifts.length; incomingShiftIndex++) {
  //             this.schedule[scheduleIndex].shifts.push(scheduleData[incomingIndex].data.shifts[incomingShiftIndex]);
  //           }
  //         }
  //       }
  //       // this.schedule.push(scheduleData[incomingIndex].data);
  //     }
  //   }
  // }

  nextMonth(): void {
    activeDate = addMonths(activeDate, 1);
  }

  previousMonth(): void {
    activeDate = addMonths(activeDate, -1);
  }


  setGroupFilter(group: number): void {
    this.groupOptionSelected = group;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  getShiftIndexes(segment: any): Array<number> {
    let indexes: Array<number> = [];
    let employeeSchedule: EmployeeSchedule = this.schedule[segment.date.getHours()];
    let storedMonth: number;
    let storedDay: number;
    let month: number;
    let day: number;

    for(let index: number = 0; index < employeeSchedule.shifts.length; index++) {
      month = employeeSchedule.shifts[index].date.getMonth();
      day = employeeSchedule.shifts[index].date.getUTCDate();
      storedMonth = segment.date.getMonth();
      storedDay = segment.date.getUTCDate();


      if(month == storedMonth && day == storedDay) {
        indexes.push(index);
      }
    }

    return indexes;
  }

  isShiftAssigned(segment: any): boolean {
    let isAssigned: boolean = false;
    let employeeSchedule: EmployeeSchedule = this.schedule[segment.date.getHours()];
    let storedMonth: number;
    let storedDay: number;
    let month: number;
    let day: number;

    if(employeeSchedule != undefined && employeeSchedule != null && employeeSchedule.shifts != undefined && employeeSchedule.shifts != null) {
      for(let index: number = 0; index < employeeSchedule.shifts.length; index++) {
        month = employeeSchedule.shifts[index].date.getMonth();
        day = employeeSchedule.shifts[index].date.getUTCDate();
        storedMonth = segment.date.getMonth();
        storedDay = segment.date.getUTCDate();


        if(month == storedMonth && day == storedDay) {
          isAssigned = true;
          break;
        }
      }
    }
    return isAssigned;
  }

  addShift(employeeIndex: number, dayIndex: number, date: Date): void {

    if(!this.deleteBroadcast) {
      let shift: AssignedShift = {
        date: date,
        shift: JSON.parse(JSON.stringify(this.activeShift))
      };

      if(this.schedule[employeeIndex].employeeId != this.employees[employeeIndex].id) {
        console.error("ID does not match!");
      }

      this.schedule[employeeIndex].shifts.push(shift);
    }

    this.deleteBroadcast = false;
  }

  removeShift(employeeIndex, shiftIndex): void {
    this.schedule[employeeIndex].shifts = this.schedule[employeeIndex].shifts.filter(shift => shift != this.schedule[employeeIndex].shifts[shiftIndex]);
    this.deleteBroadcast = true;
  }

  contextMenuAddEvent(date: Date): void {
    this.events = [];

    this.refresh.next();
  }

  cellEnter(employeeIndex: number, dayIndex: number): void {
    
  }

  cellExit(employeeIndex: number, dayIndex: number): void {

  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
    }

    this.activeDayIsOpen = false;
  }

  publish(): void {
    this.publishEvent.emit(this.schedule);
  }

  openSwapRequestDialog() {

    this.openShiftSwapDialogRef = this.dialog.open(
      VendorShiftSwapDialogComponent,
      {
        // data: {
        //   workOrderData: this.workOrders[workOrderIndex],
        //   requestId: employeeId
        // }
      }
    );

    this.openShiftSwapDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }

  openPTORequestDialog() {

    this.openShiftSwapDialogRef = this.dialog.open(
      VendorShiftSwapDialogComponent,
      {
        // data: {
        //   workOrderData: this.workOrders[workOrderIndex],
        //   requestId: employeeId
        // }
      }
    );

    this.openShiftSwapDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }

}


