import { NgModule } from '@angular/core';


@NgModule({
  imports: [

  ],
  exports: [

  ],
  declarations: [ 
    
  ],
  entryComponents:[
  ],
  providers:[
    
  ]
})
export class UserModule { }
