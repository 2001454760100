// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: "1.2.0",
  production: false,

  adminPhone: "4696904591",
  adminCompanyId: "25733455785f0ff4c796f5dd8883000b3813",

  // Dev Keys - IP Address Whitelisted
  // googleMapAPI: "AIzaSyD6rSU7DJjnu-ITxr4JC_CzQvv2gDCLfOY",
  googleMapAPI: "AIzaSyDXOKTov9d4OOaKCnZp18z6kR04szxh5OY",


  // NOTE: Misconfigured to a specific IP address to using a general key until fixed
  // googleMapEmbeddedAPI: "AIzaSyBRMo8FtcqFDv17GPfl3wPdG6rjoPx6rq0"
  googleMapEmbeddedAPI: "AIzaSyDXOKTov9d4OOaKCnZp18z6kR04szxh5OY"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
