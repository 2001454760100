import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { trigger, transition, useAnimation } from "@angular/animations";

import {
  AnimationType,
  scaleIn,
  scaleOut,
  fadeIn,
  fadeOut,
  flipIn,
  flipOut,
  jackIn,
  jackOut
} from "./carousel-support.animations";
import { ImgPanelStepTrackedDetails, ImgPanelTrackedDetails, InfoPanelImgSlide, InfoPanelImgSlideDescriptors, InfoPanelStepTrackedDetails, InfoPanelTrackedDetails } from "src/app/data-models/models";

@Component({
  selector: "app-carousel-support",
  templateUrl: "./carousel-support.component.html",
  styleUrls: ["./carousel-support.component.scss"],
  animations: [
    trigger("slideAnimation", [
      /* scale */
      transition("void => scale", [
        useAnimation(scaleIn, { params: { time: "500ms" } })
      ]),
      transition("scale => void", [
        useAnimation(scaleOut, { params: { time: "500ms" } })
      ]),

      /* fade */
      transition("void => fade", [
        useAnimation(fadeIn, { params: { time: "500ms" } })
      ]),
      transition("fade => void", [
        useAnimation(fadeOut, { params: { time: "500ms" } })
      ]),

      /* flip */
      transition("void => flip", [
        useAnimation(flipIn, { params: { time: "500ms" } })
      ]),
      transition("flip => void", [
        useAnimation(flipOut, { params: { time: "500ms" } })
      ]),

      /* JackInTheBox */
      transition("void => jackInTheBox", [
        useAnimation(jackIn, { params: { time: "700ms" } })
      ]),
      transition("jackInTheBox => void", [
        useAnimation(jackOut, { params: { time: "700ms" } })
      ])
    ])
  ]
})

export class CarouselSupportComponent implements OnInit {
  @Input() slides: InfoPanelImgSlide[];
  @Input() animationType = AnimationType.Scale;
  @Input() slidesID: string = "default";

  @Output('slideUpdate') slideUpdateEmitter = new EventEmitter<InfoPanelImgSlide>();
  @Output('trackedUserDetailsUpdate') trackedUserDetailsEmitter = new EventEmitter<ImgPanelTrackedDetails>();
  private trackedUserDetails: ImgPanelTrackedDetails = null;

  currentSlide = 0;

  constructor() {}

  ngOnInit() {
    this.preloadImages(); // for the demo
    this.initializeTrackedDetails();

    this.trackedDetailsUpdated();

    this.slideUpdateEmitter.emit(this.slides[this.currentSlide]);
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.currentSlide = 0;
    this.initializeTrackedDetails();
    
    this.trackedDetailsUpdated();

    this.slideUpdateEmitter.emit(this.slides[this.currentSlide]);
  }
  

  private initializeTrackedDetails(): void {
    if(this.slides == undefined || this.slides == null) {
      return;
    }

    this.trackedUserDetails = {
      id: this.slidesID,
      steps: []
    }

    let slideIndex: number = 0;

    for(let slide of this.slides) {

      this.trackedUserDetails.steps.push({
        id: slide.id,
        descriptors: []
      });

      if(slide.descriptors != undefined) {
        for(let descriptor of slide.descriptors) {

          this.trackedUserDetails.steps[slideIndex].descriptors.push({
            step_id: descriptor.id,
            date_seen: null,
            date_confirmed: null,
            date_accepted: null,
            isConfirmed: false,
            isAccepted: false,
            step_completed: false
          });

        }
      }

      slideIndex++;
    }
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);

    this.slideUpdateEmitter.emit(this.slides[this.currentSlide]);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);

    this.slideUpdateEmitter.emit(this.slides[this.currentSlide]);
  }

  preloadImages() {
    for (const slide of this.slides) {
      new Image().src = slide.src;
    }
  }

  private trackedDetailsUpdated(): void {
    this.trackedUserDetailsEmitter.emit(this.trackedUserDetails);
  }

  public checkboxSelection(descriptorId: string, checked: boolean) {

    let descriptorIndex: number = 
      this.trackedUserDetails.steps[this.currentSlide].descriptors.findIndex( 
        (descriptor: InfoPanelStepTrackedDetails) => { return descriptor.step_id == descriptorId }
      );
    
    if(descriptorIndex == -1) {
      return;
    }

    if(checked) {
      this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].isConfirmed = true;
      this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].isAccepted = true;
    } else {
      this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].isConfirmed = false;
      this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].isAccepted = false;
    }

    this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].date_confirmed = new Date();
    this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].date_accepted = new Date();

    this.trackedDetailsUpdated();
  }

  checkboxChecked(descriptorId: string): boolean {
    let descriptorIndex: number = 
    this.trackedUserDetails.steps[this.currentSlide].descriptors.findIndex( 
      (descriptor: InfoPanelStepTrackedDetails) => { return descriptor.step_id == descriptorId }
    );
  
    if(descriptorIndex == -1) {
      return false;
    }

    return this.trackedUserDetails.steps[this.currentSlide].descriptors[descriptorIndex].isAccepted;
  }

  openURL(url: string): void {
    window.open(url);
  }

}