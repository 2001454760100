import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

import { NavigatorService, keyTypesEnum } from './navigator.service';
import { CMMSLocation } from 'src/app/data-models/models';


@Injectable({
    providedIn: 'root'
})

export class VendorQuickActionMenuService {
    private quickActionMenuToggle: boolean = false;

    private quickActionMenuToggleSubject = new Subject<boolean>();


    constructor(public http:HttpClient, public realm:MongoService, public router:Router, private navigatorService: NavigatorService, private sanitizer:DomSanitizer) { 

    }

    public quickActionMenuToggleBroadcaster(): Observable<boolean> {
        return this.quickActionMenuToggleSubject.asObservable();
    }

    public getQuickActionMenuToggle(): boolean {
        return this.quickActionMenuToggle;
    }

    public setQuickActionMenuToggle(menuToggle: boolean): void {
        this.quickActionMenuToggle = menuToggle;
        this.quickActionMenuToggleSubject.next(this.quickActionMenuToggle);
    }

}