import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation, Input } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import {FormControl} from '@angular/forms';
import { GroupedObservable, scheduled, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {groups} from './groups/vendor-calendar-groups';
import { Subscription } from 'rxjs';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { AssignedShift, EmployeeSchedule, Profile, Shift, ScheduleSwap } from '../../../data-models/models';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

let activeDate = new Date();

@Component({
  selector: 'app-vendor-shift-swap-review',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-shift-swap-review.component.html',
  styleUrls: ['vendor-shift-swap-review.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VendorShiftSwapReviewComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  groupOptionSelected: number = 0;
  selectOptions = groups;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  showMarker: boolean = false;

  public weekDays: Array<number> = [0, 1, 2, 3, 4, 5 ,6];

  public deleteBroadcast: boolean = false;

  public employees: Array<Profile> = [];
  public schedule: Array<EmployeeSchedule> = [];

  @Input()
  public activeShift: Shift;

  @Input()
  public swapRequest: ScheduleSwap;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: Array<CalendarEvent[]> = [];

  activeDayIsOpen: boolean = false;

  constructor(
    private modal: 
    NgbModal,
    private navigatorService: NavigatorService,
    private changeDetectorRef: ChangeDetectorRef) { 

    for(let index = 0; index < groups.length; index++) {
      this.events.push([]);

      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events[this.groupOptionSelected].push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date,

          actions: [
            {
              label: '<i class="actionLayer"></i>',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
                console.log('Event deleted', event);
              }
            }
          ]
        })
      }
    }
  }

  ngOnInit(): void {

    this.navigatorService.getAllEmployees().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;
      this.employees = [];

      if(data != null) {
        for(let index = 0; index < data.length; index++) {

          if(employees[index].id == this.swapRequest.requestProfile.id || employees[index].id == this.swapRequest.swapProfile.id) {
            this.employees.push(employees[index]);

            this.schedule.push(
              {
                employeeId: employees[index].id,
                shifts: []
              }
            )

          }
        }

        this.navigatorService.getShiftSchedules().subscribe(data => { 
          // let result = this.filterData(data); 
    
          if(data != null) {
            this.loadEmployeeSchedules(data);
          } 
    
          this.changeDetectorRef.detectChanges();
        });

        this.changeDetectorRef.detectChanges();
      }
    });

    // this.navigatorService.getShiftSchedules().subscribe(data => { 
    //   // let result = this.filterData(data); 

    //   if(data != null) {
    //     this.loadEmployeeSchedules(data);
    //   } 

    //   this.changeDetectorRef.detectChanges();
    // });
  }

  ngAfterViewInit(): void {

  }

  loadEmployeeSchedules(scheduleData: any): void {
    // this.schedule = [];
    let shifts: any;

    for(let index = 0; index < scheduleData.length; index++) {
      this.schedule.find((element, i) => {
        if(element.employeeId == scheduleData[index].data.employeeId) {
          this.schedule[i].shifts = scheduleData[index].data.shifts;
        }
      });

      // if(shifts) {
      //   shifts.shifts = scheduleData[index].data.shifts;
      // }
    }
  }

  // loadEmployeeSchedules(scheduleData: any): void {
  //   this.schedule = [];

  //   for(let index = 0; index < scheduleData.length; index++) {
  //     console.log("Review Schedules: " + scheduleData[index].data);

  //     if(scheduleData[index].data.employeeId == this.swapRequest.requestProfile.id || scheduleData[index].data.employeeId == this.swapRequest.swapProfile.id) {
  //       this.schedule.push(scheduleData[index].data);
  //     }
  //   }
  // }

  nextMonth(): void {
    activeDate = addMonths(activeDate, 1);
  }

  previousMonth(): void {
    activeDate = addMonths(activeDate, -1);
  }


  setGroupFilter(group: number): void {
    this.groupOptionSelected = group;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  getShiftIndexes(segment: any): Array<number> {
    let indexes: Array<number> = [];
    let employeeSchedule: EmployeeSchedule = this.schedule[segment.date.getHours()];
    let storedMonth: number;
    let storedDay: number;
    let month: number;
    let day: number;

    for(let index: number = 0; index < employeeSchedule.shifts.length; index++) {
      month = employeeSchedule.shifts[index].date.getMonth();
      day = employeeSchedule.shifts[index].date.getUTCDate();
      storedMonth = segment.date.getMonth();
      storedDay = segment.date.getUTCDate();

      if(month == storedMonth && day == storedDay) {
        indexes.push(index);
      }
    }

    return indexes;
  }

  isShiftAssigned(segment: any): boolean {
    let isAssigned: boolean = false;
    let employeeSchedule: EmployeeSchedule = this.schedule[segment.date.getHours()];
    let storedMonth: number;
    let storedDay: number;
    let month: number;
    let day: number;

    if(employeeSchedule && employeeSchedule.shifts) {
      for(let index: number = 0; index < employeeSchedule.shifts.length; index++) {
        month = employeeSchedule.shifts[index].date.getMonth();
        day = employeeSchedule.shifts[index].date.getUTCDate();
        storedMonth = segment.date.getMonth();
        storedDay = segment.date.getUTCDate();
  
  
        if(month == storedMonth && day == storedDay) {
          isAssigned = true;
          break;
        }
      }
    }

    return isAssigned;
  }

  public isShiftDesignatedToSwap(segment: any, shiftIndex: number): boolean {
    let isDesignated: boolean = false;

    let isRequestorId: boolean = this.swapRequest.requestProfile.id == this.employees[segment.date.getHours()].id;
    let isDesignatedSwapId: boolean = this.swapRequest.swapProfile.id == this.employees[segment.date.getHours()].id;
    let isRequestorDate: boolean = this.swapRequest.requestShift.date.getMonth() == segment.date.getMonth() && this.swapRequest.requestShift.date.getDate() == segment.date.getUTCDate();
    let isDesignatedSwapDate: boolean = this.swapRequest.swapShift.date.getMonth() == segment.date.getMonth() && this.swapRequest.swapShift.date.getDate() == segment.date.getUTCDate();

    if(isRequestorId) {
      if(isRequestorDate) {
        if(this.schedule[segment.date.getHours()].shifts[shiftIndex].shift.id == this.swapRequest.requestShift.shift.id) {
          isDesignated = true;
        }
      }
    }

    else if(isDesignatedSwapId) {
      if(isDesignatedSwapDate) {
        if(this.schedule[segment.date.getHours()].shifts[shiftIndex].shift.id == this.swapRequest.swapShift.shift.id) {
          isDesignated = true;
        }
      }
    }
                  
    return isDesignated;
  }

  addShift(employeeIndex: number, dayIndex: number, date: Date): void {

    if(!this.deleteBroadcast) {
      let shift: AssignedShift = {
        date: date,
        shift: JSON.parse(JSON.stringify(this.activeShift))
      };

      this.schedule[employeeIndex].shifts.push(shift);
    }

    this.deleteBroadcast = false;
  }

  removeShift(employeeIndex, shiftIndex): void {
    this.schedule[employeeIndex].shifts = this.schedule[employeeIndex].shifts.filter(shift => shift != this.schedule[employeeIndex].shifts[shiftIndex]);
    this.deleteBroadcast = true;
  }

  contextMenuAddEvent(date: Date): void {
    this.events = [];

    this.refresh.next();
  }

  cellEnter(employeeIndex: number, dayIndex: number): void {
    
  }

  cellExit(employeeIndex: number, dayIndex: number): void {

  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
    }

    this.activeDayIsOpen = false;
  }
}
