import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Property } from 'src/app/app.models';
import { AssetWorkOrder, CMMSLocation, InventoryItem, MeterRecording } from 'src/app/data-models/models';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Observable, Subscription } from 'rxjs'; 
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings, Settings } from 'src/app/app.settings';
import { EmbedVideoService } from 'ngx-embed-video'; 
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

import { ImageHandlerService } from '../../services/vendor/image-handler.service';
import { NavigatorService, keyTypesEnum, workOrderTypesEnum } from '../../services/vendor/navigator.service';


import { VendorAddInventoryItemDialogComponent } from 'src/app/shared/professional/vendor-add-inventory-item-dialog/vendor-add-inventory-item-dialog.component';
import { VendorQuickActionMenuService } from 'src/app/services/vendor/vendor-quick-action-menu.service';

@Component({
  selector: 'app-vendor-inventory-item-detail',
  templateUrl: './inventory-item-detail.component.html',
  styleUrls: ['./inventory-item-detail.component.scss']
})
export class InventoryItemDetailComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: any;  
  @ViewChildren(SwiperDirective) swipers: QueryList<SwiperDirective>;
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation:true
  };

  @Input() incomingBarcode: string = null;

  private quickActionSubscriber: Subscription;
  public quickActionMenuExpanded: boolean = true;

  addAssetDialogRef: MatDialogRef<VendorAddInventoryItemDialogComponent>;
  public tableTypes = workOrderTypesEnum;
  private keyTypes:any = keyTypesEnum;
  public dateLastUpdated = null;
  private isLockedValuesChanged: boolean = false;

  public isMainImgLoaded: boolean = false;
  public imgIds: Array<string> = [];
  public sidenavOpen:boolean = true;
  public config: SwiperConfigInterface = {}; 
  public config2: SwiperConfigInterface = {}; 
  private sub: any;
  public asset:Property; 
  public settings: Settings;  
  public contactForm: FormGroup;
  public assetDetailsForm: FormGroup;
  public imgFocusIndex: number = 0;
  public focusedAssetIndex: number = 0;
  public focusedWorkOrders: Array<AssetWorkOrder> = [];

  // Local Fields
  public id: string;
  public title: string;
  public barcode: string;
  public price: number;
  public quantity: number;
  public desc: string;
  public located_at: CMMSLocation | string;
  public locations: Array<CMMSLocation> = [];
  public area: string;
  public gallery: Array<string>;
  public model: string;
  public serial_number: string;
  public isMetered: boolean;
  public meter_recordings: Array<MeterRecording>;
  public operational_status: string;
  public reservation_system: boolean;
  public serial_number_tracking: boolean;
  public work_orders: Array<AssetWorkOrder>;

  public assetList: Array<InventoryItem> = [];
  public galleryAssetList: Array<any> = [];
  public meterEditableStateList: Array<boolean> = [];
  public tempMeterTitleList: Array<string> = [];

  private assetSubscriber: Subscription;
  private assetWorkOrderSubscriber: Subscription;


  constructor(public appSettings:AppSettings, 
              public appService:AppService, 
              private activatedRoute: ActivatedRoute, 
              private quickActionService: VendorQuickActionMenuService,
              private embedService: EmbedVideoService,
              private changeDetectorRef: ChangeDetectorRef,
              public fb: FormBuilder,
              public dialog: MatDialog,
              private navigatorService: NavigatorService,
              private imageHandler: ImageHandlerService,
              private datePipe: DatePipe) {

    this.settings = this.appSettings.settings; 
  }

  ngOnInit() {
    this.getCMMSLocations();

    if(this.incomingBarcode == null || this.incomingBarcode == undefined) {
      this.sub = this.activatedRoute.params.subscribe(params => {   
        this.getInventoryItemById(params['inventory_id']); 
      });
    } else {
      this.getInventoryItemById(this.incomingBarcode); 
    }

    if(window.innerWidth < 960){
      this.sidenavOpen = false;
      if(this.sidenav){
        this.sidenav.close();
      } 
    };


    if(this.assetList.length > 0) {
      this.setDetailsFormValues();
    } else {
      this.assetDetailsForm = this.fb.group({
        id: ['', Validators.required],
        title: ['', Validators.required],
        barcode: [ {value:'', disabled: true }, Validators.required],
        price: [''],
        quantity: ['', Validators.required],
        desc: [ '' , Validators.required],
        located_at: [ '' ],
        area: [ '' ],
        gallery: [''],
        model: ['', Validators.required],
        serial_number: ['', Validators.required],
        serial_number_tracking: [false, Validators.required],
        isMetered: [ false ],
        operational_status: ['', Validators.required],
        availability: ['', Validators.required],
        reservation_system: [false, Validators.required],
        work_orders: [ [] ],
      });
    }

    this.quickActionMenuExpanded = this.quickActionService.getQuickActionMenuToggle();
    this.listeners();
  } 

  ngAfterViewInit(){
    this.config = {
      observer: false,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      }
    };

    this.config2 = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 16,      
      keyboard: true,
      navigation: false,
      pagination: false, 
      grabCursor: true,       
      loop: false, 
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3,
        }
      }
    } 

  }

  ngOnDestroy() {

    if(this.sub) {
      this.sub.unsubscribe();
    }

    if(this.assetSubscriber) {
      this.assetSubscriber.unsubscribe();
    }

    if(this.assetWorkOrderSubscriber) {
      this.assetWorkOrderSubscriber.unsubscribe();
    }

    if(this.quickActionSubscriber) {
      this.quickActionSubscriber.unsubscribe();
    }

  }  

  public listeners(): void {

    this.quickActionSubscriber = this.quickActionService.quickActionMenuToggleBroadcaster().subscribe( (expanded: boolean) => {

      this.quickActionMenuExpanded = expanded;

    });

  }

  public getInventoryItemById(id: string) {
    this.barcode = id;

    this.subscribeToInventory();
    this.subscribeToInventoryWorkOrders();
  }

  public getCMMSLocations() {   
    this.navigatorService.getAsyncAllCMMSLocations().then(locations => { 

      if(locations) {
        console.log("Ingested CMMS Locations: ", locations);
        this.locations = locations;
      }

    })
  }

  public subscribeToInventory() {   
    this.assetSubscriber = this.navigatorService.getSelectInventory(this.barcode).subscribe(data => { 

      if(data) {
        this.assetList = data;
        this.setDetailsFormValues();

        this.getImgs();
      }

    })
  }

  public getInventory() {   
    this.navigatorService.getSelectInventory(this.barcode);
  }

  public subscribeToInventoryWorkOrders() {
    this.assetWorkOrderSubscriber = this.navigatorService.getFilteredInventoryOpenWorkOrders(this.id).subscribe(data => { 

      if(data) {
        this.focusedWorkOrders = data;
      }

    })
  }

  public getInventoryWorkOrders() {   
    this.navigatorService.getFilteredInventoryOpenWorkOrders(this.id);
  }

  public getImgs(): void {
    this.imgIds = [];
    this.galleryAssetList = [];
    let localImgIds: Array<string> = []

    for(let assetIndex: number = 0; assetIndex < this.assetList.length; assetIndex++) {
      localImgIds = [];

      for(let imgIndex: number = 0; imgIndex < this.assetList[assetIndex].lockedFields.gallery.length; imgIndex++) {
        this.imgIds.push(this.assetList[assetIndex].lockedFields.gallery[imgIndex]);
        localImgIds.push(this.assetList[assetIndex].lockedFields.gallery[imgIndex]);
      }

      this.galleryAssetList.push(JSON.parse(JSON.stringify(localImgIds)));
    }

    this.imageHandler.getAsyncFiles(this.imgIds).then(imgs => {
      // this.gallery[]

      if(imgs) {
        let imgCounter: number = 0;

        for(let assetIndex: number = 0; assetIndex < this.assetList.length; assetIndex++) {

          for(let imgIndex: number = 0; imgIndex < this.assetList[assetIndex].lockedFields.gallery.length; imgIndex++) {
            this.assetList[assetIndex].lockedFields.gallery[imgIndex] = imgs[imgCounter];
            imgCounter++;
          }
        }

        this.isMainImgLoaded = true;
      }
    });
  }

  setDetailsFormValues(): void {
    this.assetDetailsForm = this.fb.group({
      id: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.id, disabled: true }, Validators.required],
      title: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.title, disabled: false }, Validators.required],
      barcode: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.barcode, disabled: true }, Validators.required],
      price: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.price, disabled: false }],
      quantity: [ { value: this.assetList.length, disabled: this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking }, Validators.required],
      desc: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.desc, disabled: false }, Validators.required],
      located_at: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.located_at, disabled: false }],
      area: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.area, disabled: false }],
      gallery: [ { value: null, disabled: false }],
      model: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.model, disabled: false }, Validators.required],
      serial_number: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.serial_number, disabled: false }, Validators.required],
      serial_number_tracking: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking, disabled: false }],
      isMetered: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.isMetered, disabled: false }],
      // operational_status: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.operational_status.toString(), disabled: false }, Validators.required],
      reservation_system: [ { value: this.assetList[this.focusedAssetIndex].lockedFields.reservation_system, disabled: false }, Validators.required],
      work_orders: [[]],
    });

    console.log("Quantity S/N Tracking: ", this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking);
    if(!this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking) {
      console.log("Quantity S/N Tracking False: ", this.assetList[this.focusedAssetIndex].lockedFields.quantity);
      this.assetDetailsForm.get("quantity").setValue(this.assetList[this.focusedAssetIndex].lockedFields.quantity);
    }

    this.dateLastUpdated = this.datePipe.transform(this.assetList[this.focusedAssetIndex].lockedFields.dateLastChanged, 'MMM d, y');
    this.setLocalFields();
    this.getInventoryWorkOrders();
  }

  // Used to set values for all items given the same barcode
  setLockedFormValues(): void {
    let title: string = this.assetDetailsForm.get("title").value;
    let reservation_system: boolean = this.assetDetailsForm.get("reservation_system").value;
    let serial_number_tracking: boolean = this.assetDetailsForm.get("serial_number_tracking").value;
    let model: string = this.assetDetailsForm.get("model").value;
    let isMetered: boolean = this.assetDetailsForm.get("isMetered").value;
    let quantity: number = this.assetDetailsForm.get("quantity").value;
    let location: CMMSLocation = this.assetDetailsForm.get("located_at").value;

    for(let assetIndex: number = 0; assetIndex < this.assetList.length; assetIndex++) {

      if(
        this.assetList[assetIndex].lockedFields.title != this.assetDetailsForm.get("title").value ||
        this.assetList[assetIndex].lockedFields.reservation_system != this.assetDetailsForm.get("reservation_system").value ||
        this.assetList[assetIndex].lockedFields.serial_number_tracking != this.assetDetailsForm.get("serial_number_tracking").value ||
        this.assetList[assetIndex].lockedFields.model != this.assetDetailsForm.get("model").value ||
        this.assetList[assetIndex].lockedFields.isMetered != this.assetDetailsForm.get("isMetered").value ||
        this.assetList[assetIndex].lockedFields.quantity != this.assetDetailsForm.get("quantity").value
      ) {
        this.assetList[assetIndex].lockedFields.title = title;
        this.assetList[assetIndex].lockedFields.reservation_system = reservation_system;
        this.assetList[assetIndex].lockedFields.serial_number_tracking = serial_number_tracking;
        this.assetList[assetIndex].lockedFields.model = model;
        this.assetList[assetIndex].lockedFields.isMetered = isMetered;
        this.assetList[assetIndex].lockedFields.quantity = quantity;

        this.isLockedValuesChanged = true;
      }

      // Special Conditional
      if(this.assetList[assetIndex].lockedFields.serial_number_tracking == false) {
        if(this.assetList[assetIndex].lockedFields.located_at != this.assetDetailsForm.get("located_at").value) {
          this.assetList[assetIndex].lockedFields.located_at = location;

          this.isLockedValuesChanged = true;
        }
      }
      
    }
  }

  // Used to set values for all items given the same barcode
  setLockedMeterValues(): void {

    if(this.assetList[this.focusedAssetIndex].lockedFields.isMetered) {
      for(let assetIndex: number = 0; assetIndex < this.assetList.length; assetIndex++) {

        if(!this.assetList[assetIndex].lockedFields.meter_recordings) {
          this.assetList[assetIndex].lockedFields.meter_recordings = JSON.parse(JSON.stringify(this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings));

          for(let meterIndex: number = 0; meterIndex < this.assetList[assetIndex].lockedFields.meter_recordings.length; meterIndex++) {
            this.assetList[assetIndex].lockedFields.meter_recordings[meterIndex].recording = "";
          }

          continue;
        }

        // Checking if anything new was added or if anything was removed
        if(this.assetList[assetIndex].lockedFields.meter_recordings.length > this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length) {

          while(this.assetList[assetIndex].lockedFields.meter_recordings.length != this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length) {
            if(this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length == 0) {
              this.assetList[assetIndex].lockedFields.meter_recordings = [];
            } else {
              this.assetList[assetIndex].lockedFields.meter_recordings.pop();
            }
          }

        } 

        else if( this.assetList[assetIndex].lockedFields.meter_recordings.length < this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length) {

          while(this.assetList[assetIndex].lockedFields.meter_recordings.length != this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length) {
              this.assetList[assetIndex].lockedFields.meter_recordings.push({
                title: "",
                unit: "",
                recording: ""
              });
          }

        }

        for(let meterIndex: number = 0; meterIndex < this.assetList[assetIndex].lockedFields.meter_recordings.length; meterIndex++) {

          // Syncing any details on locked fields
          if(
            this.assetList[assetIndex].lockedFields.meter_recordings[meterIndex].title != this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[meterIndex].title ||
            this.assetList[assetIndex].lockedFields.meter_recordings[meterIndex].unit != this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[meterIndex].unit
          ) {
            this.assetList[assetIndex].lockedFields.meter_recordings[meterIndex].title = this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[meterIndex].title;
            this.assetList[assetIndex].lockedFields.meter_recordings[meterIndex].unit = this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[meterIndex].unit;

            this.isLockedValuesChanged = true;
          }
        }
        
      }
    }
  }

  setMeteredInputState(): void {
    this.meterEditableStateList = [];

    if(this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings == null || this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings == undefined) {
      this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings = [];
    }

    for(let meterIndex: number = 0; meterIndex < this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length; meterIndex++) {
      this.meterEditableStateList.push(false);
    }
  }

  setMeteredInputTempTitle(): void {
    this.tempMeterTitleList = [];

    for(let meterIndex: number = 0; meterIndex < this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length; meterIndex++) {
      this.tempMeterTitleList.push("");
    }
  }

  refocusFormValues(): void {
    this.focusedWorkOrders = [];
    this.imgFocusIndex = 0;
    this.setMeteredInputState();
    this.setMeteredInputTempTitle();

    // A quick hack as some assets may be using isMetered for the first time and therefore,
    // it may come up as undefined.
    if(this.assetList[this.focusedAssetIndex].lockedFields.isMetered == undefined) {
      this.assetList[this.focusedAssetIndex].lockedFields.isMetered = false;
    }

    this.assetDetailsForm.setValue({
      id: this.assetList[this.focusedAssetIndex].lockedFields.id,
      title: this.assetList[this.focusedAssetIndex].lockedFields.title,
      barcode: this.assetList[this.focusedAssetIndex].lockedFields.barcode,
      price: this.assetList[this.focusedAssetIndex].lockedFields.price,
      quantity: this.assetList.length,
      desc: this.assetList[this.focusedAssetIndex].lockedFields.desc,
      located_at: this.assetList[this.focusedAssetIndex].lockedFields.located_at,
      area: this.assetList[this.focusedAssetIndex].lockedFields.area,
      gallery: null,
      model: this.assetList[this.focusedAssetIndex].lockedFields.model,
      serial_number: this.assetList[this.focusedAssetIndex].lockedFields.serial_number,
      serial_number_tracking: this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking,
      isMetered: this.assetList[this.focusedAssetIndex].lockedFields.isMetered,
      // operational_status: this.assetList[this.focusedAssetIndex].lockedFields.operational_status.toString(),
      reservation_system: this.assetList[this.focusedAssetIndex].lockedFields.reservation_system,
      work_orders: this.assetList[this.focusedAssetIndex].lockedFields.work_orders,
    });

    this.dateLastUpdated = this.datePipe.transform(this.assetList[this.focusedAssetIndex].lockedFields.dateLastChanged, 'MMM d, y');
    this.setLocalFields();
    this.getInventoryWorkOrders();
  }

  setLocalFields(): void {
      this.id = this.assetDetailsForm.get("id").value;
      this.title = this.assetDetailsForm.get("title").value;
      this.barcode = this.assetDetailsForm.get("barcode").value;
      this.price = this.assetDetailsForm.get("price").value;
      this.quantity = this.assetDetailsForm.get("quantity").value;
      this.desc = this.assetDetailsForm.get("desc").value;
      this.located_at = this.assetDetailsForm.get("located_at").value.title;
      this.area = this.assetDetailsForm.get("area").value;
      this.gallery = this.assetDetailsForm.get("gallery").value;
      this.model = this.assetDetailsForm.get("model").value;
      this.serial_number = this.assetDetailsForm.get("serial_number").value;
      this.serial_number_tracking = this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking,
      this.isMetered = this.assetDetailsForm.get("isMetered").value;
      // this.operational_status = this.assetDetailsForm.get("operational_status").value.toString();
      this.reservation_system = this.assetDetailsForm.get("reservation_system").value;
      this.work_orders = this.assetDetailsForm.get("work_orders").value;
  }

  // Old data type was string. There currently isn't a clean platform update setup so two data types are allowed.
  // This requires adjustment.
  translateLocation(locatedAt: CMMSLocation | string): string {
    let locationTitle: string = "";

    if(typeof locatedAt === 'string') {
      return locationTitle;
    } else {
      locationTitle = locatedAt.title;
    }

    return locationTitle
  }

  locationSelectionCompare(o1: any, o2: any) {
    return (o1.id == o2.id);
  }

  saveInventoryDetails(): void {

    let stagedImgIds: Array<string> = this.uploadImages(this.assetDetailsForm.get("gallery").value);

    for(let imgIdIndex: number = 0; imgIdIndex < stagedImgIds.length; imgIdIndex++) {
      this.galleryAssetList[this.focusedAssetIndex].push(stagedImgIds[imgIdIndex]);
    }

    let inventoryDetail: InventoryItem = {
      originatorId: this.assetList[this.focusedAssetIndex].originatorId,
      originationDate: this.assetList[this.focusedAssetIndex].originationDate,
      companyId: this.assetList[this.focusedAssetIndex].companyId,
    
      // This is for custom user defined properties
      custom_fields: this.assetList[this.focusedAssetIndex].custom_fields,
    
      lockedFields: {
        id: this.assetDetailsForm.get("id").value,
        title: this.assetDetailsForm.get("title").value,
        barcode: this.assetDetailsForm.get("barcode").value,
        price: this.assetDetailsForm.get("price").value,
        quantity: this.assetList.length,
        desc: this.assetDetailsForm.get("desc").value,
        located_at: this.assetDetailsForm.get("located_at").value,
        area: this.assetDetailsForm.get("area").value,
        gallery: this.galleryAssetList[this.focusedAssetIndex],
        dateLastChanged: new Date,
        model: this.assetDetailsForm.get("model").value,
        serial_number: this.assetDetailsForm.get("serial_number").value,
        serial_number_tracking: this.assetDetailsForm.get("serial_number_tracking").value,
        isMetered: this.assetDetailsForm.get("isMetered").value,
        // meter_recordings: this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings,
        // operational_status: this.assetDetailsForm.get("operational_status").value,
        availability: this.assetList[this.focusedAssetIndex].lockedFields.availability,
        reservation_system: this.assetDetailsForm.get("reservation_system").value,
        work_orders: this.assetDetailsForm.get("work_orders").value,
      }
    };

    if(!this.assetList[this.focusedAssetIndex].lockedFields.serial_number_tracking) {
      inventoryDetail.lockedFields.quantity = this.assetDetailsForm.get("quantity").value;
    }

    // If any of the locked/shared data fields are changed then all records must be updated
    if(this.isLockedValuesChanged) {
      for(let inventoryIndex: number = 0; inventoryIndex < this.assetList.length; inventoryIndex++) {
        this.assetList[inventoryIndex].lockedFields.gallery = this.galleryAssetList[inventoryIndex];
        this.navigatorService.upsertInventory(this.assetList[inventoryIndex]);

        if(inventoryIndex == 0) {
          this.navigatorService.upsertInventoryBase(this.assetList[inventoryIndex]);
        }
      }
    }

    if(this.assetDetailsForm.get("serial_number_tracking").value == false) {
      this.navigatorService.upsertInventoryBase(inventoryDetail);
    }

    this.navigatorService.upsertInventory(inventoryDetail).then(data => {
      this.navigatorService.getSelectInventory(this.assetDetailsForm.get("barcode").value);
    });
  }

  deleteInventoryDetails(itemId: string): void {
    this.navigatorService.deleteSelectInventory(itemId).then(data => {
      this.navigatorService.getSelectInventory(this.assetDetailsForm.get("barcode").value);
    });

    if(this.focusedAssetIndex > 0) {
      this.focusedAssetIndex = this.focusedAssetIndex - 1;
    }
  }

  deleteImg(imgIndex: number): void {
    this.assetList[this.focusedAssetIndex].lockedFields.gallery.splice(imgIndex, 1);
    this.galleryAssetList[this.focusedAssetIndex].splice(imgIndex, 1);
  }

  reservationOnChange(event: MatSlideToggleChange): void {
    this.assetDetailsForm.get("reservation_system").setValue(event.checked);
    this.setLockedFormValues();
  }

  SerialNumberOnChange(event: MatSlideToggleChange): void {
    this.assetDetailsForm.get("serial_number_tracking").setValue(event.checked);

    if(event.checked) {
      this.assetDetailsForm.get("quantity").setValue(this.assetList.length);
      this.assetDetailsForm.get("quantity").disable();
    } else {
      this.assetDetailsForm.get("quantity").setValue(this.assetList[this.focusedAssetIndex].lockedFields.quantity);
      this.assetDetailsForm.get("quantity").enable();
    }

    this.setLockedFormValues();
  }

  isMeteredOnChange(event: MatSlideToggleChange): void {
    this.assetDetailsForm.get("isMetered").setValue(event.checked);

    // Ensuring an array to hold recordings is created. If the asset has never had meter
    // recordings before then it will have a null value in its field.
    if(!this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings) {
      this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings = [];
    }

    this.setLockedFormValues();
    this.setLockedMeterValues();
  }

  quantityOnChange(event: string): void {
    console.log("Set Locked Form Value: ", event);
    this.setLockedFormValues();
  }

  operationalStatusChange(event: number): void {
    this.assetDetailsForm.get("operational_status").setValue(event);
  }

  refocusAsset(index: number): void {
    this.setLockedFormValues();
    this.setLockedMeterValues();
    this.focusedAssetIndex = index;

    this.refocusFormValues();
  }

  mainImgFocus(): string {
    let imgUrl: string = "";

    if(this.assetList.length > 0) {
      imgUrl = this.assetList[this.focusedAssetIndex].lockedFields.gallery[this.imgFocusIndex];
    }

    return imgUrl;
  }

  refocusMainImg(imgIndex: number) {
    this.imgFocusIndex = imgIndex;
  }

  addNewMeterRecording(): void {
    this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.push({
      title: "Reading: " + (this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.length + 1) ,
      unit: "",
      recording: ""
    });
  }

  updateMeterRecording(newInput: string, recordingIndex: number) {
    this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[recordingIndex].recording = newInput
  }

  updateMeterTitle(newInput: string, recordingIndex: number) {
    this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[recordingIndex].title = newInput
  }

  updateMeterUnit(newInput: string, unitIndex: number) {
    this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[unitIndex].unit = newInput;
  }

  meterEditableState(meterIndex: number): void {
    this.meterEditableStateList[meterIndex] = true;

    this.tempMeterTitleList[meterIndex] = this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[meterIndex].title
  }

  meterRecordingState(meterIndex: number): void {
    this.meterEditableStateList[meterIndex] = false;
  }

  meterSaveEdit(meterIndex: number): void {
    this.meterEditableStateList[meterIndex] = false;
  }

  meterCancelEdit(meterIndex: number): void {
    this.meterEditableStateList[meterIndex] = false;

    this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings[meterIndex].title = this.tempMeterTitleList[meterIndex];
  }

  deleteMeterField(recordingIndex: number): void {
    this.assetList[this.focusedAssetIndex].lockedFields.meter_recordings.splice(recordingIndex, 1);
  }

  

  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true; 
  }

  public onOpenedChange(){ 
    this.swipers.forEach(swiper => { 
      if(swiper){
        swiper.update();
      } 
    });     
  }

  public selectImage(index:number){ 
    this.swipers.forEach(swiper => {
      if(swiper['elementRef'].nativeElement.id == 'main-carousel'){
        swiper.setIndex(index);
      }      
    }); 
  }

  public uploadImages(images: Array<any>): Array<string> {
    let imgIds: Array<string> = [];

    if(images) {
      for(let galleryIndex: number = 0; galleryIndex < images.length; galleryIndex++) {
        let file: File = images[galleryIndex].file;
        let imgId: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgId);

        imgIds.push(imgId);
      }
    }

    return imgIds
  }

  public onIndexChange(index: number) {  
    this.swipers.forEach(swiper => { 
      let elem = swiper['elementRef'].nativeElement;
      if(elem.id == 'small-carousel'){
        swiper.setIndex(index);  
        for (let i = 0; i < elem.children[0].children.length; i++) {
          const element = elem.children[0].children[i]; 
          if(element.classList.contains('thumb-'+index)){
            element.classList.add('active-thumb'); 
          }
          else{
            element.classList.remove('active-thumb'); 
          }
        }
      } 
    });     
  }

  public openNewAssetDialog() {
    this.addAssetDialogRef = this.dialog.open(
      VendorAddInventoryItemDialogComponent,
      { data: this.assetList[0].lockedFields }
    );

    this.addAssetDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("Inventory Item Work Order", data);

      // ERROR THAT NEEDS TO BE FIXED (Haven't figured out the cause)
      // let imgIds: Array<string> = this.uploadImages(data.gallery);

      // for(let galleryIndex: number = 0; galleryIndex < imgIds.length; galleryIndex++) {
      //   imgIds.push(imgIds[galleryIndex]);
      // }

      for(let galleryIndex: number = 0; galleryIndex < data.gallery.length; galleryIndex++) {
        let file: File = data.gallery[galleryIndex].file;
        let imgId: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgId);

        data.assetData.lockedFields.gallery.push(imgId);
      }

      this.navigatorService.upsertAsset(data.assetData);
    });
  }

}