import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator, matchingPasswords } from 'src/app/theme/utils/app-validators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Profile } from '../../../data-models/models'

import { Observable, Subscription } from 'rxjs'; 
import { LatLng, MVCArray } from '@agm/core/services/google-maps-types';

export enum Serviceabletypes {
  SERVICEABLE = 0,
  POTENTIAL = 1,
  NON_SERVICEABLE = 2
}

@Component({
  selector: 'app-vendor-service-area-map',
  templateUrl: 'vendor-service-area-map.component.html',
  styleUrls: ['vendor-service-area-map.component.scss']
})
export class VendorServiceAreaMapComponent implements OnInit {

  //Set as any because our model is named the same as Google's (LatLng)
  @Input() incomingLatLng: any;
  @Output() incomingServiceable = new EventEmitter<number>();

  public tempMarker: google.maps.Marker = null;
  public serviceabletypes: any = Serviceabletypes;

  public poly: google.maps.Polygon;
  public map: google.maps.Map;
  public polyPaths: MVCArray<LatLng>;
  public markers: google.maps.Marker[] = [];
  public latLongMarkers: Array<any> = [];

  public zoom: number = 4; 
  public mapStyles: any;

  private serviceAreaSubscription: Subscription;

  constructor(public formBuilder: FormBuilder, public snackBar: MatSnackBar, public navigatorService: NavigatorService) { }

  ngOnInit() {
    this.mapStyles = this.navigatorService.getMapStyles();

    this.subscribeToServiceArea();
  }

  ngOnDestroy() {
    if(this.serviceAreaSubscription) {
      this.serviceAreaSubscription.unsubscribe();
    }
  } 

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {  
    console.log("Service Area Map Dynamic Data: ", changes);

    if(changes && changes.incomingLatLng) {
      this.incomingLatLng = changes.incomingLatLng.currentValue;

      if(this.map) {

        if(this.incomingLatLng) {
          this.map.setCenter(this.incomingLatLng);
          this.map.setZoom(12);
          this.addTempMarker(this.incomingLatLng);
          this.isInServiceArea(this.incomingLatLng);
        } else {
          this.resetTempMarker();
        }
      }
    }
  }

  subscribeToServiceArea(): void {
    this.serviceAreaSubscription = this.navigatorService.getServiceArea().subscribe(data => { 

      if(data != null) {
        this.latLongMarkers = data;
        this.updateLatLng(null);
      } 
    });
  }

  getServiceArea(): void {
    this.navigatorService.getServiceArea();
  }

  onMapReady(map?: google.maps.Map ) {
    if(!this.incomingLatLng) {
      this.incomingLatLng = {
        lat: 40.072817,
        lng: -98.413498
      };
    }

    if(map) {
      map.setOptions({
        streetViewControl: false,
        mapTypeControl: true,
        center: this.incomingLatLng,
        zoom: this.zoom
      });

      this.poly = new google.maps.Polygon({
        // paths: this.polyPaths,
        strokeColor: "#00FF00",
        strokeOpacity: 0,
        strokeWeight: 3,
        fillColor: "#00D100",
        fillOpacity: 0,
        geodesic: true,
      });

      this.map = map;

      this.poly.setMap(this.map);
    } 
  }

  addTempMarker(marker: any): void {

    if(this.tempMarker) {
      this.resetTempMarker();
    }

    let point = new google.maps.LatLng(marker.lat, marker.lng);

    // Add a new marker.
    this.tempMarker = new google.maps.Marker({
      position: point,
      title: "New W/O",
      map: this.map,
    });
  }

  addMarker(marker: any): void {
    let point = new google.maps.LatLng(marker.lat, marker.lng);

    // Add a new marker.
    this.markers.push(new google.maps.Marker({
      position: point,
      title: "New W/O",
      map: this.map,
    }));
  }

  updateLatLng(latLng: Array<LatLng>) {
    if(latLng) {
      this.latLongMarkers = latLng;
    }

    this.polyPaths = this.poly.getPath();

    for(let markerIndex: number = 0; markerIndex < this.latLongMarkers.length; markerIndex++) {
      let point = new google.maps.LatLng(this.latLongMarkers[markerIndex].lat, this.latLongMarkers[markerIndex].lng);

      // Because path is an MVCArray, we can simply append a new coordinate
      // and it will automatically appear.
      this.polyPaths.push(point);
    }
  }

  resetTempMarker(): void {
    this.tempMarker.setMap(null);
  }

  resetServiceArea(): void {
    this.polyPaths.clear();

    // Removing all markers
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }

    this.latLongMarkers = [];

    this.markers = [];
  }

  isInServiceArea(marker: any): boolean {

    let point = new google.maps.LatLng(marker.lat, marker.lng);
    let contains: boolean = google.maps.geometry.poly.containsLocation(point, this.poly);

    if(contains) {
      this.incomingServiceable.emit(this.serviceabletypes.SERVICEABLE);
    } else {
      this.incomingServiceable.emit(this.serviceabletypes.NON_SERVICEABLE);
    }

    console.log("Contains Check: ", contains);

    return contains;
  }

  save(): void {
    console.log("Poly: ", this.polyPaths);
    console.log("Markers: ", this.markers);
    console.log("Marker Lat/Lng: ", this.latLongMarkers);
    
    this.navigatorService.updateServiceArea(this.latLongMarkers);
  }
}