import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { keyTypesEnum, NavigatorService, workOrderTypesEnum } from '../../../services/vendor/navigator.service';
import { Address, DialogNewAsset, LinkedData, Profile, WorkOrder, WorkOrderCustomField, WorkOrderTemplate } from '../../../data-models/models';

import { Observable, Subject, Subscription } from 'rxjs';

import { workOrderElementEnum } from '../../../services/vendor/navigator.service';

import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {filter, takeUntil} from 'rxjs/operators';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';


@Component({
    selector: 'vendor-work-order-dialog',
    templateUrl: 'vendor-work-order-dialog.component.html',
    styleUrls: ['vendor-work-order-dialog.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
  })

  export class VendorWorkOrderDialogComponent implements OnInit {
    public exampleHeader = ExampleHeader;
    public tableTypes = workOrderTypesEnum;
    public submitForm: FormGroup; 
    public currentDate: string; 
    public dateScheduled: Date;
    public elementEnum: any = workOrderElementEnum;
    public workOrderKeys: Array<string> = [];
    public employees: Array<Profile> = [];
    public employeeOptions: Array<any> = [];
    public incomingImages: Array<any> = [];
    public images: Array<any> = [];
    public imgIds: Array<string> = [];
    public imageDeleteAction: boolean = false;
    public scheduledTime: string = "";
    public originationTime: string = "";
    public foreign_work_orders: Array<WorkOrder> = [];
    
    private workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;

    public template: WorkOrderTemplate;
    public customFields: Array<WorkOrderCustomField>;
    private originalAddress: Address = null;
    private keyTypes:any = keyTypesEnum;
    private imgImportchange: boolean = false;

    // Subscriptions
    private workOrderCustomFieldSubscriber: Subscription;
    private employeesSubscription: Subscription;
    private imgSubscription: Subscription;

    private fieldDisabledList: Array<string> = [
      "technicianId",
      "originatorId",
      "workOrderId",
      "companyId"
    ];

    constructor(
      private navigatorService: NavigatorService, 
      private imageHandler: ImageHandlerService,
      private fb: FormBuilder, 
      private datePipe: DatePipe, 
      @Inject(MAT_DIALOG_DATA) public workOrderData: any,
      public matDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>,
      public dialog: MatDialog) {
      
    }
  
    ngOnInit() {
      if(this.workOrderData.workOrderData != null && this.workOrderData.workOrderData != undefined) {
        this.workOrderData = this.workOrderData.workOrderData;
      }

      this.template = this.navigatorService.getLocalWorkOrderTemplate();
      this.subscribeToWorkOrderCustomFields();
      this.subscribeToEmployees();

      if(this.workOrderData != null && this.workOrderData != undefined) {
        this.originalAddress = JSON.parse(JSON.stringify(this.workOrderData.custAddress));
      }

      // console.log('${this.workOrderData[1]}');
      // console.log(`${key}: ${this.workOrderData[key]}`);

      this.submitForm = this.fb.group({
        workOrder: this.fb.group({
          originatorId: {value: '#B910-1', disabled: true},
          originationDate: {value: null, disabled: true},
          workOrderNumber: {value: '#Z36F8-218', disabled: true},
          dateScheduled: {value: null, disabled: false},
          desc: {value: null, disabled: true},
          gallery: null
        }),

        customerInfo: this.fb.group({
          custFirstName: {value: null, disabled: false},
          custLastName: {value: null, disabled: false},
          custEmail: {value: null, disabled: false},
          custPhone: {value: null, disabled: false},
          custStreet: {value: null, disabled: false},
          custState: {value: null, disabled: false},
          custZip: {value: null, disabled: false},
          
          priority: {value: null, disabled: false},
          skillLvl: {value: null, disabled: false},
          specialNotes: {value: null, disabled: false}
        }),

        priority: this.fb.group({
          priority: {value: null, disabled: false},
          skillLvl: {value: null, disabled: false},
          specialNotes: {value: null, disabled: false}
        })
      }); 

      this.navigatorService.getWorkOrderTemplateSubject().subscribe(template => {
        if(template) {
          this.template = template;
        }
      });

      if(!this.template) {
        this.navigatorService.getWorkOrderTemplate().subscribe(template => {
          if(template) {
            this.template = template;
          }
        });
      }

      // this.dateScheduled = this.datePipe.transform(this.data.date, "yyyy-MM-dd")
      // this.currentDate = this.datePipe.transform(new Date, "yyyy-MM-dd")
      this.getForeignWorkOrders();
      this.imgIds = this.workOrderData.images;

      if(this.imgIds == undefined || this.imgIds == null) {
        this.imgIds = [];
      }

      this.getAllImgs();
      this.matDialogRef.disableClose = true;
    }

    ngOnDestroy() {
      if(this.workOrderCustomFieldSubscriber) {
        this.workOrderCustomFieldSubscriber.unsubscribe();
      }

      if(this.employeesSubscription) {
        this.employeesSubscription.unsubscribe();
      }
    }

    ngAfterViewInit() {
      // iterate over the Work Order Data object
      for (const key in this.workOrderData) {
        this.workOrderKeys.push(key);
        console.log(`${key}: ${this.workOrderData[key]}`);
      }
    }

    subscribeToEmployees(): void {
      console.log("Subscribe to Employee");
  
      this.employeesSubscription = this.navigatorService.getAllEmployees().subscribe(data => { 
        // let result = this.filterData(data); 
  
        console.log("Returned Employee Data", data);
  
        if(data != undefined) {
          this.employees = data;
          this.employeeOptions = [];

          for(let employee of this.employees) {
            console.log("Employee Option");
            this.employeeOptions.push({ 
              title: employee.first_name + " " + employee.last_name,
              id: employee.id,
              key: employee.id
            });
          }

        }
      });
    }

    getAllEmployees(): void {
      this.navigatorService.getAllEmployees();
    }

    subscribeToWorkOrderCustomFields(): void {
      this.workOrderCustomFieldSubscriber = this.navigatorService.getCustomWorkOrderCustomFields().subscribe(customFields => {
        if(customFields) {
          this.customFields = customFields;

          if(this.workOrderData) {

            for(let customField of this.customFields) {
              let keyExist: boolean = false;
              
              if(this.workOrderData.workOrderData) {
                if(this.workOrderData.workOrderData.customFields == null) {
                  this.workOrderData.workOrderData.customFields = [];
                }

                keyExist = this.workOrderData.workOrderData.customFields.findIndex(element => element.key == customField.key) > -1;
              } else {
                if(this.workOrderData.customFields == null) {
                  this.workOrderData.customFields = [];
                }

                keyExist = this.workOrderData.customFields.findIndex(element => element.key == customField.key) > -1;
              }

              if(!keyExist) {
                if(this.workOrderData.workOrderData) {
                  this.workOrderData.workOrderData.customFields.push(customField);
                } else {
                  this.workOrderData.customFields.push(customField);
                }
              }

            }
          }
        }
      });
    }
  
    getWorkOrderCustomFields(): void {
      this.navigatorService.getCustomWorkOrderCustomFields();
    }

    async getForeignWorkOrders(): Promise<void> {
      let foreignWorkOrders: Array<any> = [];

      if(this.workOrderData.foreign_work_orders != undefined && this.workOrderData.foreign_work_orders != null) {

        for(let foreignKey of this.workOrderData.foreign_work_orders) {

          await this.navigatorService.getWorkOrder(foreignKey).then( (workOrder: WorkOrder) => {

            if(workOrder != null) {
              foreignWorkOrders.push(workOrder);
            }

          });

        }

        this.foreign_work_orders = foreignWorkOrders;

      }

    }
  
    submit() { 

      if(this.imgImportchange) {
        this.uploadImages(this.incomingImages);
      }
        
      if(this.workOrderData.workOrderData != null && this.workOrderData.workOrderData != undefined) {
        this.workOrderData.workOrderData.images = this.imgIds; 
        this.stringTimeToDate(this.scheduledTime, this.workOrderData.workOrderData.dateScheduled);
      } else {
        this.workOrderData.images = this.imgIds; 
        this.stringTimeToDate(this.scheduledTime, this.workOrderData.dateScheduled);
      }
      
      if(this.workOrderData.custAddress != this.originalAddress) {
        this.navigatorService.asyncAddressToLatLong(this.workOrderData.custAddress).then(data => {

          if(data != null && data != undefined) {
            this.workOrderData.latLng = data;
          }

          this.matDialogRef.close(this.workOrderData);

        });
      } else {
        this.matDialogRef.close(this.workOrderData);
      }

      console.log("Work Order Edit: ", this.workOrderData);
    }

    public reset() {
      this.submitForm.reset({
        
      });   
    }

    // This needs to be wired into a work order objects
    linkedDataChange(key: LinkedData, change: any): void {
      // this.linkedData.title = option;
      // this.linkedData.data = this.findDataValueWithKey(this.workOrderLookupKeys, this.linkedData.title);
  
      // this.linkedDataTitle = option;
      // return option.title;
      console.log("Data Change: ", change);

      // Checking for a Custom Field
      if(key.data.indexOf("custom") > -1) {
        if(this.workOrderData.workOrderData) {
          this.workOrderData.workOrderData.customFields[this.workOrderData.workOrderData.customFields.findIndex(element => element.key == key.data)].data = change;
        } else {
          this.workOrderData.customFields[this.workOrderData.customFields.findIndex(element => element.key == key.data)].data = change;
        }
      } 
      
      // Checking for multi level data
      else if(key.data.indexOf(".") > -1) {
        // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
        let key1 = key.data.substring(0, key.data.indexOf('.'));
        let key2 = key.data.substring(key.data.indexOf('.') + 1, key.data.length);

        if(this.workOrderData.workOrderData) {
          if(this.workOrderData.workOrderData[key1] && this.workOrderData.workOrderData[key1][key2]) {
            this.workOrderData.workOrderData[key1][key2] = change;
          }
        } else {
          if(this.workOrderData[key1] != undefined && this.workOrderData[key1][key2] != undefined) {
            this.workOrderData[key1][key2] = change;
          }
        }
      }

      else {
        if(this.workOrderData.workOrderData) {
          this.workOrderData.workOrderData[key.data] = change;
        } else {
          this.workOrderData[key.data] = change;
        }
      }

      // console.log("Work Order Change: ", this.workOrderData);
    }

    linkedComponentChange(key: string, change: any): void {

      if(this.workOrderData.workOrderData) {

        if(key == "foreign_work_orders") {
          if(this.workOrderData[key] == undefined) {
            this.workOrderData[key] = [];
          }

          this.workOrderData.workOrderData[key].push(change);

          this.getForeignWorkOrders();
        } else {
          this.workOrderData.workOrderData[key] = change;
        }

      } else {

        if(key == "foreign_work_orders") {
          if(this.workOrderData[key] == undefined) {
            this.workOrderData[key] = [];
          }

          this.workOrderData[key].push(change);
          this.getForeignWorkOrders();
        } else {
          this.workOrderData[key] = change;
        }
      }

    }

    imageDataChange(images: any): void {
      this.incomingImages = images;

      this.imgImportchange = true;
    }

    public getImg(imgIndex: number): void {
      if(this.imgIds[imgIndex] != undefined) {
        this.imageHandler.getFile(this.imgIds[imgIndex]);
      }
    }

    // public getAllImgs(): void {
    //   if(this.imgIds != undefined && this.imgIds != null && this.imgIds.length > 0) {

    //     for(let imgId of this.imgIds) {
    //       this.imageHandler.getFile(imgId);
    //     }
    //   }
    // }

    public getAllImgs(): void {
      this.images = [];
  
      this.imageHandler.getAsyncFiles(this.imgIds).then(imgs => {
        // this.gallery[]
  
        if(imgs) {
          this.images = imgs;
        }
      });
    }
  
    public uploadImages(images: Array<any>): Array<string> {
  
      if(images != null && images != undefined && images.length > 0) {

        for(let imageIndex: number = 0; imageIndex < images.length; imageIndex++) {
          let file: File = images[imageIndex].file;
          let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

          this.imgIds.push(imgIdUnique);
  
          if(imageIndex == images.length) {
            this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
              this.getAllImgs();
            });
          } else {
            this.imageHandler.uploadFile(file, imgIdUnique).then(data => {

            });
          }

        }
      }
  
      return this.imgIds;
    }

    public deleteImg(imgIndex: number): void {
      this.images.splice(imgIndex, 1);
      this.imgIds.splice(imgIndex, 1);
      this.imageDeleteAction = true;
    }

    test(data: any) {
      console.log("Clock Changed: ", data);
    }

    updateScheduleTime(time: string, linkedData: LinkedData) {

      if(this.isScheduleDate(linkedData)) {
        this.scheduledTime = time;
      } else {
        this.originationTime = time;
      }

      console.log("Clock Changed: ", time);
    }

    getScheduledTime(dateKey: LinkedData): string {
      let date: Date;

      if(this.workOrderData.workOrderDate != null && this.workOrderData.workOrderDate != undefined) {
        date = this.workOrderData.workOrderData[dateKey.data];
      } else {
        date = this.workOrderData[dateKey.data];
      }


      if(this.scheduledTime.length == 0 && typeof date != 'string' && date != null && date != undefined) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let meridiem = "AM";

        if(hours > 12) {
          hours -= 12;

          meridiem = "PM";
        }

        if(this.isScheduleDate(dateKey)) {
          this.scheduledTime = hours + ":" + minutes + " " + meridiem;
          return this.scheduledTime;
        } else {
          this.originationTime = hours + ":" + minutes + " " + meridiem;
          return this.originationTime;
        }
      }
    }

    stringTimeToDate(time: string, date: Date): void {

      if(time.length > 0 && date != null && date != undefined) {
        let parts, hours, minutes,
        timeReg = /(\d+)\:(\d+) (\w+)/;
        parts = time.match(timeReg);
        
        hours = /am/i.test(parts[3]) ?
            function(am) {return am < 12 ? am : 0}(parseInt(parts[1], 10)) :
            function(pm) {return pm < 12 ? pm + 12 : 12}(parseInt(parts[1], 10));
        
        minutes = parseInt(parts[2], 10);

        date.setHours(hours);
        date.setMinutes(minutes);
        
        console.log(time + ' => ' + date);
      }
    }

    // This needs to pull from a work order object given the specified linked data key
    getMenuData(linkedData: LinkedData): string {

      return "Out of Order"
    }

    getMenuOptions(linkedData: LinkedData): Array<string> {
      let menuOptions: Array<any> = [];

      if (typeof linkedData.data != "string") {
        return menuOptions;
      }

      switch(linkedData.data) {
        case "priority":
          menuOptions = this.navigatorService.getPriorityOptions();
          break;

        case "skillLvl":
          menuOptions = this.navigatorService.getSkillLvlOptions();
          break;

        case "status":
          menuOptions = this.navigatorService.getStatusOptions();
          break;

        case "isCompleted":
          menuOptions = this.navigatorService.getWorkCompletedOptions();
          break;

        case "isPaid":
          menuOptions = this.navigatorService.getPaidOptions();
          break;

        case "technicianId":
          menuOptions = this.employeeOptions;
          break;
      }

      return menuOptions;
    }

    isFieldDate(linkedData: LinkedData): boolean {
      let isDate: boolean = false;

      if(linkedData.data != null) {
        isDate = linkedData.data.indexOf("date") > -1 || linkedData.data.indexOf("Date") > -1;

        if(isDate) {
          let originDate: boolean = linkedData.data.indexOf("origination") > -1 || linkedData.data.indexOf("Origination") > -1;

          if(originDate) {
            isDate = false;
          }
        }
      }

      return isDate;
    }

    isOriginDate(linkedData: LinkedData): boolean {
      let isDate: boolean = false;

      if(linkedData.data != null) {
        isDate = linkedData.data.indexOf("origination") > -1 || linkedData.data.indexOf("Origination") > -1;
      }

      return isDate;
    }

    isScheduleDate(linkedData: LinkedData): boolean {
      let isDate: boolean = false;

      if(linkedData.data != null) {
        isDate = linkedData.data.indexOf("schedule") > -1 || linkedData.data.indexOf("Schedule") > -1;
      }

      return isDate;
    }

    isFieldForceDisabled(linkedData: LinkedData): boolean {
      let isForceDisabled: boolean = false;

      if(linkedData.data != null) {
        for(let index: number = 0; index < this.fieldDisabledList.length; index++) {
          isForceDisabled = linkedData.data.indexOf(this.fieldDisabledList[index]) > -1;

          if(isForceDisabled) {
            break;
          }
        }
      }

      return isForceDisabled;
    }

    insertKey(linkedKey: LinkedData): any {
      let data = "";

      if(linkedKey && linkedKey.data != null && this.workOrderData) {

        // Checking for a Custom Field
        if(linkedKey.data.indexOf("custom") > -1) {

          if(this.workOrderData.workOrderData) {
            data = this.workOrderData.workOrderData.customFields[this.workOrderData.workOrderData.customFields.findIndex(element => element.key == linkedKey.data)].data;
          } else {

            let index: number = this.workOrderData.customFields.findIndex(element => element.key == linkedKey.data);
            data = this.workOrderData.customFields[index].data;
          }

        } else {
        
          // Checking for multi level data
          if(linkedKey.data.indexOf(".") == -1) {

            if(this.workOrderData.workOrderData) {

              if(linkedKey.data != "workOrderId" && linkedKey.data != "technicianId" && linkedKey.data != "originatorId") {
                data = this.workOrderData.workOrderData[linkedKey.data];
              }  else {
                let id: string = this.workOrderData.workOrderData[linkedKey.data];
                data = id.substring( (id.length - 5), id.length);
              }

            } else {
              if(linkedKey.data != "workOrderId" && linkedKey.data != "technicianId" && linkedKey.data != "originatorId") {
                data = this.workOrderData[linkedKey.data];
              }  else {
                let id: string = this.workOrderData[linkedKey.data];

                if(id != undefined && id != null) {
                  data = id.substring( (id.length - 5), id.length);
                } else {
                  data = ""
                }
              }
            }

          } else {
            
            // NOT RATED FOR MULTI LEVEL OBJECTS. FOR LOOP IS REQUIRED FOR THAT
            let key1 = linkedKey.data.substring(0, linkedKey.data.indexOf('.'));
            let key2 = linkedKey.data.substring(linkedKey.data.indexOf('.') + 1, linkedKey.data.length);

            if(this.workOrderData.workOrderData) {
              if(this.workOrderData.workOrderData[key1] && this.workOrderData.workOrderData[key1][key2]) {
                data = this.workOrderData.workOrderData[key1][key2];
              }
            } else {
              if(this.workOrderData[key1] && this.workOrderData[key1][key2] != undefined) {
                data = this.workOrderData[key1][key2];
              }
            }
          }

        }
      }

      return data;
    }

    getContrast(hexcolor) {

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
    
      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }
    
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
    
      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    
    }


    public openWorkOrderDialog(workOrderId: string) {

      if(workOrderId != null && workOrderId != undefined && workOrderId.length > 0) {
        this.navigatorService.getWorkOrder(workOrderId).then(data => {
  
          if(data != null && data != undefined) {
            this.workOrderDialogRef = this.dialog.open(
              VendorWorkOrderDialogComponent,
              { data: data }
            );
  
            this.workOrderDialogRef.beforeClosed().subscribe(data => {
              console.log("Before Closed: ", data);
            })
            
            this.workOrderDialogRef.afterClosed().pipe(
              filter(data => data)
            ).subscribe(data => {
              console.log("Dialog Closed: ", data);

              if(data != undefined && data != null) {
                this.navigatorService.updateWorkOrder(data);
              }
            })
  
          }
        });
  
      }
  
    }

  }



  /** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  styles: [`
    .calendar-header {
      display: inline-flex;
      width: 80%;
      align-items: center;
      padding: 0.5em;
    }

    #timeContainer {
      display: inline-flex;
      width: 20%;
      justify-content: center;
    }

    .example-header-label {
      margin-top: -.8em;
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }

    .double-arrow {
      margin-left: -.6em
    }
  `],
  template: `
    <div class="calendar-header">
      <div role="button" class="example-double-arrow" (click)="previousClicked('year')">
        <span class="material-icons d-inline">chevron_left</span>
        <span class="material-icons d-inline double-arrow">chevron_left</span>
      </div>
      <div role="button" mat-icon-button (click)="previousClicked('month')">
        <span class="material-icons d-inline">chevron_left</span>
      </div>
      <span class="example-header-label">{{periodLabel}}</span>
      <div role="button" mat-icon-button (click)="nextClicked('month')">
        <span class="material-icons d-inline">chevron_right</span>
      </div>
      <div role="button" mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
        <span class="material-icons d-inline">chevron_right</span>
        <span class="material-icons d-inline double-arrow">chevron_right</span>
      </div>
    </div>

    <div role="button" id="timeContainer" (click)="openTimeClock()">
      <span class="material-icons">schedule</span>
    </div>                              
  `,
})

export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  public isTimeClockOpen: boolean = false;
  private timeElement: any = null;

  constructor(
      private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());

        this.timeElement = document.querySelector("#timePickerInput");

        console.log("Time Element: ", this.timeElement);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
        .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  openTimeClock(): void {
    this.eventFire(this.timeElement, 'click');
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}