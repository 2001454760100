import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { NavigatorService, pageEnum } from 'src/app/services/vendor/navigator.service';
import { Page } from 'src/app/data-models/models';
import { PlatformService, PlatformViewStates } from 'src/app/services/vendor/platform.service';
import { BasePages } from '../menu';

enum SolutionCategories {
  COMMERCIAL = 0,
  RESIDENTIAL = 1
}

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [ MenuService ]
})

export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId: number;
  @Input('isLoggedIn') isLoggedIn: boolean = false;

  public pageTypes: any = pageEnum;
  public basePages: any = BasePages;
  public solutionCategories: any = SolutionCategories;

  public platformViewStates: any = PlatformViewStates;
  private viewState: number = this.platformService.getPlatformViewState();

  public internalTools: boolean = this.platformService.internalToolsChecker();
  public adminTools: boolean = false;

  public menuItems: Array<Menu>;
  public permissions: Array<Page> = [];
  public pages: any = this.navigator.getPages();


  public activeSolution: number = this.solutionCategories.COMMERCIAL;
  
  constructor(
    private platformService: PlatformService, 
    public menuService:MenuService, 
    private navigator: NavigatorService) { }

  ngOnInit() {
    // this.menuItems = this.menuService.getHorizontalMenuItems();
    // this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId); 

    if(this.isLoggedIn) {
      this.observeUserPermissions(); 
      this.platformService.refreshUserPermissions(); 
    } else {
      this.menuItems = this.menuService.getHorizontalMenuItems();
      this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId); 
    }

  }

  private observeUserPermissions(): void {

    this.platformService.observeUserPermissions().subscribe( (permissions: Array<Page>) => {

      this.internalTools = this.platformService.internalToolsChecker();
      this.checkAdminAccess(permissions);

      if(permissions != undefined && permissions != null) {
        this.menuItems = this.menuService.getVendorHorizontalMenuItems();

        if(this.menuParentId == this.pageTypes.ADMIN) {
          
          if(!this.adminTools || this.viewState == this.platformViewStates.MOBILE) {
            let self = this;
            let adminIndex: number = this.menuItems.findIndex( (item: Menu) => { return item.id == self.pageTypes.ADMIN });

            if(adminIndex > -1) {
              this.menuItems.splice(adminIndex, 1);
            }
            
          } 

        }

        let filterIds: Array<number> = [this.menuParentId];

        for(let menuItem of this.menuItems) {
          if(menuItem.parentId == this.menuParentId && menuItem.hasSubMenu) {
            filterIds.push(menuItem.id);
          }
        }

        this.menuItems = this.menuItems.filter(item => {
          let keepItem: boolean = false;
          let filterIDIndex: number = filterIds.findIndex( (id: number) => { return item.parentId == id })

          if(filterIDIndex > -1) {
            keepItem = true;
          }

          return keepItem;
        }); 

        this.permissions = permissions;
        this.permissionFilterMenu();
      }

    });

  }

  private checkAdminAccess(permissions: Array<Page>): void {

    let adminPermissions: Page = permissions.find( (page: Page) => { return page.id == this.pageTypes.ADMIN });

    if(adminPermissions != undefined && adminPermissions != null) {

      if(adminPermissions?.permission?.pageAccess) {
        this.adminTools = true;
      } else {
        this.adminTools = false;
      }

    }

  }


  private permissionFilterMenu(): void {
    console.log("Menu Items: ", this.menuItems);
    console.log("Menu Permisions", this.permissions);

    for(let menuIndex: number = this.menuItems.length - 1; menuIndex >= 0; menuIndex--) {
      let menuItem: Menu = this.menuItems[menuIndex];

      if(menuItem == undefined || menuItem.permissionId == undefined) {
        continue;
      }

      let page: Page = this.permissions.find( (page: Page) => { return page.id == menuItem.permissionId });

      if( (page == undefined || page == null || !page.permission.pageAccess)) {
        this.menuItems.splice(menuIndex, 1);
      }

    }

  }


  public changeSolutionCategory(category: number): void {
    this.activeSolution = category
  }

}
