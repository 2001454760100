import { Component, OnInit, ViewChild, AfterViewInit, Input, Output  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ImageHandlerService } from '../../../services/vendor/image-handler.service';
import { NavigatorService, keyTypesEnum } from '../../../services/vendor/navigator.service';
import { InventoryItem, DialogNewAssetWorkOrder, AssetWorkOrder, Address } from '../../../data-models/models';


@Component({
    selector: 'vendor-add-asset-work-order-dialog',
    templateUrl: 'vendor-add-asset-work-order-dialog.component.html',
    styleUrls: ['vendor-add-asset-work-order-dialog.component.scss']
  })

  export class VendorAddAssetWorkOrderDialogComponent implements OnInit {
    @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
    public submitForm: FormGroup; 
    public inventory: Array<InventoryItem> = []; 
    public currentDate: string; 
    public assignedId: string;
    public dateScheduled: Date;
    public viewableDateScheduled: string;
    public isDateFieldsEnabled: boolean = false;
    public isWorkOrderCompleted: boolean = false;
    public recurringSelectedIndex: number = null;
    public recurringOptions: Array<string> = [
      "Daily",
      "Weekly",
      "Monthly",
      "Annually",
      "Custom"
    ];

    // Local Fields
    public id: string;
    public title: string;
    public barcode: string;
    public price: number;
    public desc: string;
    public located_at: string;
    public area: string;
    public gallery: Array<string>;
    public model: string;
    public serial_number: string;
    public operational_status: string;
    public isRecurring: boolean;
    public work_orders: Array<AssetWorkOrder>;

    public imageTest: any;

    private keyTypes: any = keyTypesEnum;

    constructor(
      private navigatorService: NavigatorService, 
      private imageHandlerService: ImageHandlerService, 
      private fb: FormBuilder, 
      private datePipe: DatePipe, 
      @Inject(MAT_DIALOG_DATA) public assetItem: any,
      public matDialogRef: MatDialogRef<VendorAddAssetWorkOrderDialogComponent>
      ) {
      
    }
  
    ngOnInit() {
      if(this.assetItem) {
        this.isDateFieldsEnabled = false;
        this.viewableDateScheduled = this.datePipe.transform(new Date, "yyyy-MM-dd");
      } else {
        this.isDateFieldsEnabled = true;
        this.viewableDateScheduled = this.datePipe.transform(new Date, "yyyy-MM-dd");
        this.dateScheduled = new Date;

        // Assigned ID needs to be passed in. Currently only a fake one is used
        this.assignedId = "a936cd5699";
      }
      
      this.matDialogRef.disableClose = true;//disable default close operation

      this.currentDate = this.datePipe.transform(new Date, "yyyy-MM-dd");

      this.submitForm = this.fb.group({

        workOrder: this.fb.group({
          originatorId: {value: this.navigatorService.getProfileId(), disabled: true},
          originationDate: {value: new Date, disabled: true},
          workOrderNumber: {value: this.navigatorService.generateKey(this.keyTypes.WORK_ORDER_ID), disabled: true},
          dateScheduled: {value: this.viewableDateScheduled, disabled: true},
          desc: [ '' , Validators.required],
          operational_status: ['', Validators.required],
          gallery: [[]]
        }),

        assetInfo: this.fb.group({
          id: [ { value: this.assetItem.lockedFields.id, disabled: true }, Validators.required],
          title: [ { value: this.assetItem.lockedFields.title, disabled: true }, Validators.required],
          barcode: [ { value: this.assetItem.lockedFields.barcode, disabled: true }, Validators.required],
          located_at: [ this.assetItem.lockedFields.located_at, Validators.required],
          area: [ this.assetItem.lockedFields.area ],
          model: [ { value: this.assetItem.lockedFields.model, disabled: true }, Validators.required],
          serial_number: [ { value: this.assetItem.lockedFields.serial_number, disabled: true }, Validators.required],
          recurring: [false, Validators.required]
        }),

        recurring: this.fb.group({
          recurring_option: [0],
          custom_recurring_frequency: ['', Validators.required],
          custom_recurring_period: ['', Validators.required]
        })
      }); 

      // USE FOR PRODUCTION
      // this.navigatorService.getLocalTrackedFields().subscribe(data => { 
      //   if(data) {
      //     this.inventory = data; 
      //   }
      // });
    }
  
    submit() {
      if(this.isWorkOrderCompleted) {
        let dialogData: DialogNewAssetWorkOrder = {
          isLoopBack: false,
          isCompleted: this.isWorkOrderCompleted,
          gallery: this.submitForm.get("workOrder")['controls'].gallery.value,
          // workOrderData: this.createWorkOrderObject()
          workOrderData: {
            // Base W/O
            technicianId: "",
            originatorId: this.submitForm.get("workOrder")['controls'].originatorId.value,
            workOrderId: this.submitForm.get("workOrder")['controls'].workOrderNumber.value,
            companyId: this.navigatorService.getCompanyId(),

            originationDate: this.submitForm.get("workOrder")['controls'].originationDate.value,
            custPocName: "",
            custPocPhone: null,
            custPocEmail: "",
            custFirstName: "",
            custMiddleName: "",
            custLastName: "",
            custEmail: "",
            custPhone: null,
            custAddress: null,
            custBillingAddress: null,
            custBillAmount: null,
            description: this.submitForm.get("workOrder")['controls'].desc.value,
            dateContactLog: null,
            dateInitialContact: null,
            dateScheduled: this.submitForm.get("workOrder")['controls'].dateScheduled.value,
            timeStretch: null,
            priority: null,
            visibleShift: null,
            gallery: [],

            skillLvl: null,
            specialNotes: "",
            status: null,
            foreign_work_orders: [],
            isAutoGenerated: false,
            isCompleted: false,
            isPaid: false,
            customFields: null,

            // Asset Specific
            operational_status: this.submitForm.get("workOrder")['controls'].operational_status.value,
            id: this.submitForm.get("assetInfo")['controls'].id.value,
            title: this.submitForm.get("assetInfo")['controls'].title.value,
            barcode: this.submitForm.get("assetInfo")['controls'].barcode.value,
            located_at: this.submitForm.get("assetInfo")['controls'].located_at.value,
            area: this.submitForm.get("assetInfo")['controls'].area.value,
            model: this.submitForm.get("assetInfo")['controls'].model.value,
            serial_number: this.submitForm.get("assetInfo")['controls'].serial_number.value,
            recurring: this.isRecurring,
            recurring_option: this.recurringSelectedIndex,
            custom_recurring_frequency: this.submitForm.get("recurring")['controls'].custom_recurring_frequency.value,
            custom_recurring_period: this.submitForm.get("recurring")['controls'].custom_recurring_period.value
          }
        };

        this.matDialogRef.close(dialogData);
      } else {
        this.close();
      }
    }

    workOrderCompleted() {

      if(this.isRecurring && this.recurringSelectedIndex != null) {
        this.isWorkOrderCompleted = true;

        if(!this.submitForm.get("workOrder")['controls'].dateScheduled.value) {
          this.submitForm.get("workOrder")['controls'].dateScheduled.setValue(new Date);
        }
      }

      else if(!this.isRecurring) {
        this.isWorkOrderCompleted = true;
      }

      else {
        this.recurringSelectedIndex = 0;
      }


    }

    close() {
      this.matDialogRef.close();
    }

    public reset() {
      this.horizontalStepper.reset(); 
      
      this.submitForm.reset({
        
      });   
    }

    public onSelectionChange(e:any) { 
      console.log("Forward");
      if(e.selectedIndex == 4) {   
        this.horizontalStepper._steps.forEach(step => step.editable = false);
        console.log(this.submitForm.value);      
      }
    }

    public recurringOnChange(event): void {
      this.isRecurring = event.checked;
    }

    public changSelectedRecurranceOption(selectedIndex: number): void {
      this.recurringSelectedIndex = selectedIndex;
    }

    public timePeriodChange(event) {
      this.submitForm.get("recurring")['controls'].custom_recurring_period.setValue(event);
    }
    
    public frequencyChange(event) {
      this.submitForm.get("recurring")['controls'].custom_recurring_frequency.setValue(event);
    }

    public operationalStatusChange(event) {
      
    }

    public uploadImage(): void {
      let images: Array<any> = this.submitForm.get("workOrder")['controls'].gallery.value;

      // Image upload test (DELETE FOR PRODUCTION)
      this.imageHandlerService.uploadFile(images[0].file, this.navigatorService.generateKey(this.keyTypes.IMAGE_ID));
    }

    public getImage(): void {
      // Image get test (DELETE FOR PRODUCTION)
      this.imageHandlerService.getFile("18249938d0acdec15e300dd9").subscribe(img => {
        this.imageTest = img;
      });
    }

    getContrast(hexcolor) {

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
    
      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }
    
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
    
      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    
    }

    createWorkOrderObject(): void {

      // return {
      //   technicianId: this.assignedId,
      //   originatorId: this.submitForm.get("workOrder").get("originatorId").value,
      //   workOrderId: this.submitForm.get("workOrder").get("workOrderNumber").value,
      //   companyId: this.navigatorService.getCompanyId(),

      //   originationDate: this.submitForm.get("workOrder").get("originationDate").value,
      //   timeStretch: this.submitForm.get("priority").get("timeStretch").value,
      //   custPocName: "",
      //   custPocPhone: null,
      //   custPocEmail: "",
      //   custFirstName: this.submitForm.get("customerInfo").get("custFirstName").value,
      //   custMiddleName: '',
      //   custLastName: this.submitForm.get("customerInfo").get("custLastName").value,
      //   custEmail: this.submitForm.get("customerInfo").get("custEmail").value,
      //   custPhone: this.submitForm.get("customerInfo").get("custPhone").value,
      //   custAddress: {
      //     street: this.submitForm.get("customerInfo").get("custStreet").value,
      //     city: "",
      //     state: this.submitForm.get("customerInfo").get("custState").value,
      //     zip: this.submitForm.get("customerInfo").get("custZip").value
      //   },
      //   custBillingAddress: {
      //     street: this.submitForm.get("customerInfo").get("custStreet").value,
      //     city: "",
      //     state: this.submitForm.get("customerInfo").get("custState").value,
      //     zip: this.submitForm.get("customerInfo").get("custZip").value
      //   },
      //   custBillAmount: 0,
      //   description: this.submitForm.get("workOrder").get("desc").value,
      //   dateContactLog: [
      //     new Date
      //   ],
      //   dateInitialContact: new Date,
      //   dateScheduled: this.dateScheduled,
      //   priority: this.submitForm.get("priority").get("priority").value,
      //   skillLvl: this.submitForm.get("priority").get("skillLvl").value,
      //   specialNotes: this.submitForm.get("priority").get("specialNotes").value,
      //   status: 1,
      //   foreign_work_orders: [],
      //   isAutoGenerated: false,
      //   isCompleted: false,
      //   isPaid: false,
      //   customFields: []
      // }
    }


  }