import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Page } from 'src/app/data-models/models';
import { environment } from 'src/environments/environment';

import { NavigatorService } from '../vendor/navigator.service';


export enum PlatformStates {
    PROFESSIONAL = 0,
    DEMO = 1
}

export enum PlatformViewStates {
    MOBILE = 0,
    DESKTOP = 1
}

@Injectable({
    providedIn: 'root'
})

export class PlatformService {

    private platformState: number = PlatformStates.DEMO;
    private viewState: number = PlatformViewStates.DESKTOP;

    private permissionsSubject: Subject<Array<Page>> = new Subject<Array<Page>>();

    private userPermissions: Array<Page> = [];

    constructor(
        private navigatorService: NavigatorService, 
        public router: Router) { 

    }

    public init() {
        this.initViewState();
    }

    private initViewState(): void {

        this.viewState = PlatformViewStates.DESKTOP;

    }

    public getPlatformViewState(): number {
        return this.viewState;
    }

    public getPlatformState(): number {
        return this.platformState;
    }

    public observeUserPermissions(): Observable<Array<Page>> {
        return this.permissionsSubject.asObservable();
    }

    public getPermissions(): Array<Page> {
        return this.userPermissions;
    }

    public refreshUserPermissions(): void {
        let self = this;
    
        this.navigatorService.getAsyncEmployeePagesPermissions(this.navigatorService.getProfileId()).then( (permissions: Array<Page>) => {
    
          if(permissions != null) {
            console.log("Returned User Permissions: ", permissions);
            self.userPermissions = permissions;

            self.permissionsSubject.next(self.userPermissions);
          }
    
        });
    }

    public setPlatformState(state: number): void {
        this.platformState = state;

        if(this.platformState == PlatformStates.DEMO) {
            console.log("Platform State Demo");
        } else {
            console.log("Platform State Professional");
        }
        
    }

    public versionChecker(version: string): boolean {
        let isGreaterThan: boolean = false;
        let versionBreakdown: Array<string> = version.split(".");
        let currentVersionBreakdown: Array<string> = environment.version.split(".");

        let segmentIndex: number = 0;

        for(let versionSegment of versionBreakdown) {

            let versionFormattedSegment: number = Number(versionSegment);
            let currentVersionFormattedSegment: number = Number(currentVersionBreakdown[segmentIndex]);

            if(currentVersionFormattedSegment > versionFormattedSegment) {
                console.log("Version 2 Features Authorized");
                isGreaterThan = true;
                break;
            }

            else if (currentVersionFormattedSegment == versionFormattedSegment) {
                continue;
            }

            else {
                isGreaterThan = false;
                break;
            }

            segmentIndex++; 
        }

        return isGreaterThan;
    }

    public internalToolsChecker(): boolean {

        let internal: boolean = false;
        let companyId: string = this.navigatorService.getCompanyId();

        if(companyId == environment.adminCompanyId) {
            internal = true;
        }

        return internal;

    }

}