import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject, OnDestroy  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Address, CRMDeal, CRMPipe, CRMPipeline, CRMPOC, DocumentedForm, LatLng } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { PageChangeEvent } from 'src/app/vendor/forms/forms.component';
import { Serviceabletypes } from '../../professional/vendor-service-area-map/vendor-service-area-map.component';
import { VendorFormBuilderDialogComponent } from '../vendor-form-builder-dialog/vendor-form-builder-dialog.component';

export interface ProgressTick {
  passed: boolean
}

export interface PipelineData {
  pipeline: string,
  pipes: Array<CRMPipe>,
  pipe: string
}

@Component({
  selector: 'app-vendor-crm-add-deal-dialog.component',
  templateUrl: 'vendor-crm-add-deal-dialog.component.html',
  styleUrls: ['vendor-crm-add-deal-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VendorCRMAddDealDialogComponent implements OnInit, OnDestroy {

  public dealForm: FormGroup;
  public pocForm: FormGroup;
  public locationForm: FormGroup;
  public progressTicker: Array<ProgressTick> = [];

  public serviceabletypes: any = Serviceabletypes;
  public addressPanelExpanded: boolean = false;
  public isInServiceArea: number = null;
  public serviceable: number = null;
  public incomingAddress: Address = null;
  public assignedAddress: Address = null;
  public addressLatLng: LatLng = null;
  public incomingLatLng: LatLng = null;
  public assignedStaff: Array<string> = [];
  public assignedTeams: Array<string> = [];
  public documents: Array<DocumentedForm> = [];

  public contacts: Array<CRMPOC> = [];
  public contactPanelExpanded: boolean = false;

  private formDialogRef: MatDialogRef<VendorFormBuilderDialogComponent>;
  public formPanelExpanded: boolean = false;
  public activeDoc: string = null;
  public activeTemplate: string = null;

  private addressToLatLngSubscription: Subscription;

  public pipelines: Array<CRMPipeline> = [
    { 
      id: this.navigatorService.generateKey(), 
      title: "Pipeline 1",
      pipes: []
    },
    { 
      id: this.navigatorService.generateKey(), 
      title: "Pipeline 2",
      pipes: []
    },
    { 
      id: this.navigatorService.generateKey(), 
      title: "Pipeline 3",
      pipes: []
    }
  ];

  public pipelineFocus: CRMPipeline = {
    id: "noselect", 
    title: "",
    pipes: []
  };

  constructor(
    private navigatorService: NavigatorService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<VendorCRMAddDealDialogComponent>, 
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public pipelineData: PipelineData) {

  }

  ngOnInit() {

    console.log("Pipeline Data: ", this.pipelineData);

    this.initializePipelineVariables();
    this.initializeTickerVariables();
    this.subscribeAddressToLatLng();

    this.dealForm = this.formBuilder.group({
      

      street: [ {value: '', disabled: false} ],
      city: [ {value: '', disabled: false} ],
      state: [ {value: '', disabled: false} ],
      zip: [ {value: '', disabled: false} ],

      title: [ {value: '', disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)]) ],
      organization: [ {value: '', disabled: false} ],
      value: [ {value: 0, disabled: false}, Validators.required ],
      probability: [ {value: 0, disabled: false} ],
      desc: [ {value: '', disabled: false}],
      notes: [ {value: '', disabled: false}],
      exp_close_date: [ {value: new Date, disabled: true}, Validators.required ]
    });

    this.pocForm = this.formBuilder.group({
      first_name: [ {value: '', disabled: false} ],
      last_name: [ {value: '', disabled: false}],

      poc_company_name: [ {value: '', disabled: false}],
      email: [ {value: '', disabled: false}],
      phone: [ {value: '', disabled: false} ]
    });

    this.locationForm = this.formBuilder.group({
      street: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      city: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      state: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(2)])],
      zip: ['', Validators.compose([
        Validators.required, 
        Validators.minLength(5),
        Validators.pattern('[0-9]*') ])]
    });

  }

  ngOnDestroy() {

    if(this.addressToLatLngSubscription) {
      this.addressToLatLngSubscription.unsubscribe();
    }
    
  } 

  subscribeAddressToLatLng(): void {
    this.addressToLatLngSubscription = this.navigatorService.addressToLatLong(null).subscribe(data => { 

      if(data != null) {
        console.log("GEO Lat/Lng: ", data);

        this.incomingLatLng = data;
      } 
    });
  }

  getAddressToLatLng(address: Address): void {
    this.navigatorService.addressToLatLong(address);
  }

  private initializeTickerVariables(): void {

    for(let tickIndex: number = 0; tickIndex < 20; tickIndex++) {

      this.progressTicker.push({
        passed: false
      });
      
    }

  }


  private initializePipelineVariables(): void {

    // for(let pipelineIndex: number = 0; pipelineIndex < this.; pipelineIndex++) {

    //   this.progressTicker.push({
    //     passed: false
    //   });

    //   console.log("Progress Ticker Initialized: ", this.progressTicker);
    // }

  }

  public onLocationFormSubmit(locationForm: Address): void {
    if(this.locationForm.valid) {
      console.log("Location Form: ", locationForm);

      this.incomingAddress = locationForm;

      this.getAddressToLatLng(this.incomingAddress);
    } else {
      this.isInServiceArea = null;
      this.incomingLatLng = null;
    }
  }

  public serviceAreaCheck(event): void {
    console.log("Incoming Service Check: ", event);
    this.isInServiceArea = event;
  }

  public expandContactPanel(): void {
    this.cancelNewAddress();
    this.collapseFormsPanel();
    this.contactPanelExpanded = true;
  }

  public collapseContactPanel(): void {
    this.contactPanelExpanded = false;
  }

  public expandAddressPanel(): void {
    this.cancelNewContact();
    this.collapseFormsPanel();
    this.addressPanelExpanded = true;
  }

  public collapseAddressPanel(): void {
    this.addressPanelExpanded = false;
  }

  public expandFormsPanel(): void {
    this.cancelNewAddress();
    this.cancelNewContact();
    this.formPanelExpanded = true;
  }

  public collapseFormsPanel(): void {
    this.formPanelExpanded = false;
  }

  public assignNewContact(): void {

    let address: Address = {
      street: this.dealForm.get("street").value,
      city: this.dealForm.get("city").value,
      state: this.dealForm.get("state").value,
      zip: this.dealForm.get("zip").value
    }

    let contact: CRMPOC = {
      id: this.navigatorService.generateKey(),
      first_name: this.pocForm.get("first_name").value,
      last_name: this.pocForm.get("last_name").value,
      company_name: this.pocForm.get("poc_company_name").value,

      email: this.pocForm.get("email").value,
      phone: this.pocForm.get("phone").value,
      address: address,
      tags: []
    }

    this.pocForm.get("first_name").setValue("");
    this.pocForm.get("last_name").setValue("");
    this.pocForm.get("poc_company_name").setValue("");

    this.pocForm.get("email").setValue("");
    this.pocForm.get("phone").setValue("");


    this.dealForm.get("street").setValue("");
    this.dealForm.get("city").setValue("");
    this.dealForm.get("state").setValue("");
    this.dealForm.get("zip").setValue(null);

    console.log("Assigned Contact: ", contact);
    this.contacts.push(contact);
    this.collapseContactPanel();
  }

  public cancelNewContact(): void {

    this.pocForm.get("first_name").setValue("");
    this.pocForm.get("last_name").setValue("");
    this.pocForm.get("poc_company_name").setValue("");

    this.pocForm.get("email").setValue("");
    this.pocForm.get("phone").setValue("");

    
    this.dealForm.get("street").setValue("");
    this.dealForm.get("city").setValue("");
    this.dealForm.get("state").setValue("");
    this.dealForm.get("zip").setValue(null);

    this.collapseContactPanel();
  }

  public assignNewAddress(): void {

    if(this.locationForm.valid) {
      let address: Address = {
        street: this.locationForm.get("street").value,
        city: this.locationForm.get("city").value,
        state: this.locationForm.get("state").value,
        zip: this.locationForm.get("zip").value
      }

      this.assignedAddress = address;
      this.serviceable = this.isInServiceArea;
      this.addressLatLng = this.incomingLatLng;
      this.collapseAddressPanel();
    } else {
      this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public cancelNewAddress(): void {
    this.locationForm.reset();
    this.collapseAddressPanel();
  }

  public assignedStaffUpdate(staff: Array<string>): void {
    this.assignedStaff = staff;
  }

  public assignedTeamsUpdate(teams: Array<string>): void {
    this.assignedTeams = teams;
  }

  public changePipeFocus(pipeId: string) {
    this.pipelineData.pipe = pipeId;
  }

  public pipelineFocusChange(pipelineId: string): void {

    let pipeline: CRMPipeline = this.pipelines.find( (pipe: CRMPipeline) => { return  pipe.id == pipelineId; });

    if(pipeline != undefined && pipeline != null) {
      this.pipelineFocus = pipeline;
      console.log("Pipeline Changed: ", this.pipelineFocus);
    }

  }

  public attachFormToDeal(documentedForm: DocumentedForm): void {

    this.documents.unshift(documentedForm);
    console.log("Assigned Documents: ", this.documents);
  }

  public submit(): void {

    if(this.dealForm.valid) {
      let deal: CRMDeal = {
        id: this.navigatorService.generateKey(),
        assignedStaff: this.assignedStaff,
        assignedTeams: this.assignedTeams,
        title: this.dealForm.get("title").value,
        companyTitle: this.dealForm.get("organization").value,
        description: this.dealForm.get("desc").value,
        notes: this.dealForm.get("notes").value,
        price: Number.parseInt(this.dealForm.get("value").value),
        poc: this.contacts,
        address: this.assignedAddress,
        latLng: this.addressLatLng,
        serviceable: this.serviceable,
        tags: [],
        leadSource: '',
        auditTrail: [],
        pipeAuditTrail: [],
        activities: [],
        pipeline: this.pipelineData.pipeline,
        pipe: this.pipelineData.pipe,
        priority: 0,
        probability: this.dealForm.get("probability").value,
        expectedCloseDate: this.dealForm.get("exp_close_date").value,

        documentedFormTrail: this.documents,

        last_update: new Date,
        created_by: this.navigatorService.getProfileId()
      }


      console.log("Deal: ", deal);
      this.matDialogRef.close(deal)
    } else {
      this.snackBar.open('Missing Required Fields!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }


  close() {
    this.matDialogRef.close();
  }





  public openDocument(pageChangeEvent: PageChangeEvent): void {

    console.log("Open Doc: ", pageChangeEvent);

    // this.activeFocus = true;
    this.activeTemplate = pageChangeEvent.value.template
    this.activeDoc =  pageChangeEvent.value.document;

    this.openFormBuilderDialog();
  }

  public openFormBuilderDialog(): void {
    let self = this;

    this.formDialogRef = this.dialog.open(
      VendorFormBuilderDialogComponent,
      { 
        data: {
          dealMode: true,
          activeTemplate: this.activeTemplate,
          activeDoc: this.activeDoc
        }
      
      }
    );

    this.formDialogRef.beforeClosed().subscribe( (document: DocumentedForm) => {
      console.log("Before Closed: ", document);


      if(document != undefined && document != null) {
        self.attachFormToDeal(document);
      }

    })
    
    this.formDialogRef.afterClosed().subscribe(document => {
      console.log("Dialog Closed: ", document);

    })
  
  }
}