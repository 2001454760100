import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-vendor-revenue-subscription',
  templateUrl: 'vendor-revenue-subscription.component.html',
  styleUrls: ['vendor-revenue-subscription.component.scss']
})
export class VendorRevenueSubscriptionComponent implements OnInit {
 
  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    
  }

}