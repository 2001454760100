import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApplicationNotificationMessage, ApplicationNotifications, CompanyProfile, LinkedAccount, NotificationCategory, Profile } from 'src/app/data-models/models';
import { UserMenuService } from 'src/app/services/pages/user-menu.service';
import { GuidedWalkthroughService } from 'src/app/services/vendor/guided-walkthrough.service';

// Services
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NavigatorService, notificationStatusEnum } from 'src/app/services/vendor/navigator.service';
import { NotificationService } from 'src/app/services/vendor/notification.service'
import { RegisterCompanyDialogComponent } from 'src/app/shared/register-company-dialog/register-company-dialog.component';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;
  
  profile: Profile = null;
  companyProfile: CompanyProfile = null

  username: string = "Loading";
  jobTitle: string = "loading";

  public notificationStatusEnum: any = notificationStatusEnum;
  
  public companyLogoId: string = null;
  public linkedAccountsLogoIds: Array<string> = [];
  public linkedAccounts: Array<CompanyProfile> = [];
  public imgId: string = null;

  public companyLogo: any = null;
  public linkedAccountsLogos: Array<any> = [];
  public image: any = null;

  public structuredNotifications: any = { messages: [], messageCount: 0 };
  public notificationStatus: number = 0;
  public notificatonUpdateTimestamp: Date = null;

  public panelOpenState: boolean = false;
  public companySwitchToggle: boolean = false;

  private openRegisterCompanyDialogRef: MatDialogRef<RegisterCompanyDialogComponent>;

  constructor(
    public appService:AppService, 
    private navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService, 
    private userMenuService: UserMenuService,
    private notificationService: NotificationService, 
    private guidedWalkthroughService: GuidedWalkthroughService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.setup();

    // this.structuredNotifications = this.notificationService.getStructuredNotifications();
    // this.notificationStatus = this.notificationService.getNotificationStatus();
    // let self: any = this;

    // Remove once live data is wired in!
    // setTimeout(function() { self.notificationService.initializeTestNotifications() }, 6000);

    this.notificatonUpdateTimestamp = new Date();
    this.pollNotifications();
  }

  ngAfterViewInit() {

    this.userMenuService.setUserMenuTrigger(this.userMenuTrigger);

  }

  ngOnDestroy() {


  }

  public pageGuidedWalkthrough(): void {
    this.guidedWalkthroughService.initiateGuidedStep();
  }

  public platformGuidedWalkthrough(): void {
    this.guidedWalkthroughService.initiateGuidedPlatformStep();
  }

  public logout():void {
    console.log("Attempting Logoff in User Menu");
    this.navigatorService.logout();
  }

  public openUserMenu(): void {
    this.userMenuTrigger.openMenu();
  }

  public closeUserMenu(): void {
    this.userMenuTrigger.closeMenu();
  }

  public setup(): void {
    this.profile = this.navigatorService.getCurrentProfile();

    if(this.profile == undefined || this.profile == null) {
      return;
    }

    this.navigatorService.getCompanyProfile(this.profile.company_id).then( (profileData: CompanyProfile) => {

      if(profileData != null && profileData != undefined) {
        this.companyProfile = profileData;

        this.companyLogoId = this.companyProfile.image;
        this.getImg(this.companyLogoId).then( image => {

          this.companyLogo = image;
          
        });
      }

    });

    this.username = this.profile.first_name + " " + this.profile.last_name;
    this.jobTitle = this.profile.job_title;
    this.imgId = this.profile.image;

    if(this.profile.linked_accounts != undefined && this.profile.linked_accounts.length > 0 && this.profile.company_id != null && this.profile.company_id.length > 0) {
      let self = this;
      let companyFocusIndex: number = this.profile.linked_accounts.findIndex( (linkedAccount: LinkedAccount) => { return linkedAccount.id == self.profile.company_id });

      if(companyFocusIndex > -1) {
        this.imgId = this.profile.linked_accounts[companyFocusIndex].profileImg;
        this.jobTitle = this.profile.linked_accounts[companyFocusIndex].jobTitle;
      }
    }

    this.getImg(this.imgId).then( image => {

      this.image = image;

    });

    this.getLinkedAccounts();
  }

  public getLinkedAccounts(): void {
    this.linkedAccountsLogoIds = [];
    this.linkedAccounts = [];
    this.linkedAccountsLogos = [];
    
    // Pull all linked account profiles and their associated images/logos
    for(let linkedAccount of this.profile.linked_accounts) {

      this.navigatorService.getAsyncCompanyProfile(linkedAccount.id).then( (profileData: CompanyProfile) => {

        if(profileData != null && profileData != undefined) {

          this.linkedAccounts.push(profileData);
          this.linkedAccountsLogos.push({
            id: profileData.id,
            profileImg: profileData.image,
            logo: null
          })

          if(profileData.image != undefined) {
            this.linkedAccountsLogoIds.push(profileData.image);
          } else {
            this.linkedAccountsLogoIds.push('');
          }

        }

        if(this.linkedAccountsLogoIds.length == this.profile.linked_accounts.length) {
          console.log("Getting Linked Account Imgs: ", this.linkedAccountsLogoIds);
          this.getImgs(this.linkedAccountsLogos).then( images => {

            if(images.length == this.linkedAccountsLogos.length) {

              for(let linkedAccountIndex: number = 0; linkedAccountIndex < this.linkedAccountsLogos.length; linkedAccountIndex++) {
                this.linkedAccountsLogos[linkedAccountIndex].logo = images[linkedAccountIndex];
              }

            }
      
          });
        }

      });

    }
  }

  public async getImg(imgId: string): Promise<any> {
    let image: any = null;

    if(imgId != undefined && imgId.length > 0) {
      await this.imageHandler.getAsyncFiles([imgId]).then(images => {
        if(images) {

          image = images[0];

        }
      });
    }

    return image;
  }

  public async getImgs(imgs: Array<string> | Array<LinkedAccount>): Promise<Array<any>> {
    let images: Array<any> = [];

    if(imgs != undefined && imgs.length > 0) {
      await this.imageHandler.getAsyncFiles(imgs, null, true).then(imgs => {

        if(imgs != undefined && imgs != null && imgs.length > 0) {

          images = imgs;

        }

      });
    }

    return images;
  }

  private pollNotifications(): void {
    let self: any = this;

    setTimeout(function() {
      let lastUpdate = self.notificationService.latestTimestamp();

      if(self.notificatonUpdateTimestamp < lastUpdate) {
        console.log("Notification Update Required!");
        self.structuredNotifications = self.notificationService.getStructuredNotifications();
        self.notificationStatus = self.notificationService.getHighestNotificationStatus();

        self.notificatonUpdateTimestamp = new Date();
      }

      self.pollNotifications();
    }, 3000);
  }

  public clearAllNotifications(): void {
    this.structuredNotifications = this.notificationService.clearAllNotifications();
    this.notificationStatus = this.notificationService.getHighestNotificationStatus();
  }

  public deleteNotifications(id: string): void {
    this.structuredNotifications = this.notificationService.deleteNotification(id);
    this.notificationStatus = this.notificationService.getHighestNotificationStatus();
  }

  public toggleCompanySwitch(): void {

    if(this.companySwitchToggle) {
      this.companySwitchToggle = false;
    } else {
      this.companySwitchToggle = true;
    }

  }

  public switchCompanyFocus(companyId: string) {

    this.navigatorService.switchCompanyProfile(companyId)

  }

  public openRegisterCompanyDialog() {

    this.openRegisterCompanyDialogRef = this.dialog.open(
      RegisterCompanyDialogComponent
    );

    this.openRegisterCompanyDialogRef.afterClosed().subscribe(data => {
      console.log("Dialog Closed", data);
      this.setup();
    })
  }

}