import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';

declare var google: any;
declare const gapi: any;

@Component({
  selector: 'app-document-creator',
  templateUrl: './document-creator.component.html',
  styleUrls: ['./document-creator.component.scss'] 
})
export class DocumentCreatorComponent implements OnInit {

  testURL: string;
  safeURL: SafeResourceUrl;

  refresh: Subject<any> = new Subject();

  constructor(private sanitizer: DomSanitizer) {
    // this.testURL = "https://docs.google.com/document/d/1uqNTnYQYDNJn1hD3pn8iGbOLjvIqSnB25f7dpHuCHzk/edit" + "&output=embed";
    // this.testURL = "https://docs.google.com/document/d/1uqNTnYQYDNJn1hD3pn8iGbOLjvIqSnB25f7dpHuCHzk/edit" + "&amp;embedded=true";
    // this.testURL = "https://drive.google.com/file/d/0B95tkuiaGdUXWW9YaHJPdUJoNTQ/preview?usp=drive_web";
    // this.testURL = "https://docs.google.com/document/d/1lR28ZUeA5-fCE8m_7B0llqCoxwdtnHwyAFRh1FPZRxM/edit?usp=drive_web";

    // Shared Document - No Need To Sign In
    this.testURL = " https://docs.google.com/document/d/1lR28ZUeA5-fCE8m_7B0llqCoxwdtnHwyAFRh1FPZRxM/edit?usp=sharing";

    // this.testURL = "https://docs.google.com/spreadsheets/d/1oKRHwda16NMVuppfgGn4TzOH5EqF01Nw8Dzn3lOvMQ4/edit?usp=drive_web";
  }

  ngOnInit() {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.testURL);
  }

    // The Browser API key obtained from the Google API Console.
    // Replace with your own Browser API key, or your own key.
    developerKey = 'AIzaSyCAOyX9LmSgLwwbMicsoLmFi27odvKLtj0';

    // The Client ID obtained from the Google API Console. Replace with your own Client ID.
    clientId = "590331887347-uspcb506lktbhl2k7jacgknad75dkt9u.apps.googleusercontent.com"

    // Replace with your own project number from console.developers.google.com.
    // See "Project number" under "IAM & Admin" > "Settings"
    // appId = "1234567890";

    // Scope to use to access user's Drive items.
    // scope = ['https://www.googleapis.com/auth/drive.file'];

    // pickerApiLoaded = false;

    // oauthToken;

  // ===========================================================================

    // developerKey = 'developer/API key here';
    // clientId = "client_id"
    scope = [
      'profile',
      'email',
      'https://www.googleapis.com/auth/drive'
    ].join(' ');
    pickerApiLoaded = false;
    oauthToken?: any;
  
    loadGoogleDrive() {
      gapi.load('auth', { 'callback': this.onAuthApiLoad.bind(this) });
      gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });
    }
  
    onAuthApiLoad() {
      var self = this;
      gapi.auth.authorize(
        {
          'client_id': this.clientId,
          'scope': this.scope,
          'immediate': false
        },
        this.handleAuthResult.bind(this));
    }
  
    onPickerApiLoad() {
      this.pickerApiLoaded = true;
    }

    createGoogleFile(fileType: string) {
      var parentId = '';//some parentId of a folder under which to create the new folder
      var fileMetadata = {
        'name' : 'New Folder',
        // 'mimeType' : 'application/vnd.google-apps.folder',
        'mimeType' : fileType,
        'parents': [parentId]
      };

      gapi.client.drive.files.create({
        resource: fileMetadata,
      }).then(function(response) {
        switch(response.status){
          case 200:
            var file = response.result;
            console.log('Created Folder Id: ', file.id);
            break;
          default:
            console.log('Error creating the folder, '+response);
            break;
          }
      });
      
    }
  
    handleAuthResult(authResult) {
      let src;
      let embedSrc;

      if (authResult && !authResult.error) {
        if (authResult.access_token) {
          let view = new google.picker.View(google.picker.ViewId.DOCS);
        //   view.setMimeTypes("image/png,image/jpeg,image/jpg,video/mp4");
          // view.setIncludeFolders(true);
          // view.setSelectFolderEnabled(false);
          let pickerBuilder = new google.picker.PickerBuilder();
          let picker = pickerBuilder.
            // enableFeature(google.picker.Feature.NAV_HIDDEN).
            setOAuthToken(authResult.access_token).
            addView(view).
            addView(new google.picker.DocsUploadView()).
            setCallback((e) => {

              var ctx = this;

              if (e[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                let doc = e[google.picker.Response.DOCUMENTS][0];
                src = doc[google.picker.Document.URL];

                if(doc[google.picker.Document.C1]) {
                  embedSrc = doc[google.picker.Document.C1];
                } else {
                  embedSrc = doc[google.picker.Document.EMBEDDABLE_URL];
                }


                console.log("Document selected is", doc,"and URL is ", src)

                if(doc[google.picker.Document.TYPE] === "document" && doc[google.picker.Document.SERVICE_ID] != "freebird") {
                  ctx.safeURL = ctx.sanitizer.bypassSecurityTrustResourceUrl(src);
                } else {
                  ctx.safeURL = ctx.sanitizer.bypassSecurityTrustResourceUrl(embedSrc);
                }
              }

              ctx.refresh.next();

              // Click focus is required in order for it to update changes
              ctx.eventFire(document.querySelector(".mat-input-element"), 'click');

            }).
            build();
          picker.setVisible(true);
          // picker.setIncludeFolders(true);
          // picker.setSelectFolderEnabled(false);

          
        }
      }
    }

    eventFire(el, etype) {
      if (el.fireEvent) {
        el.fireEvent('on' + etype);
      } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }
} 