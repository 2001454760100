import { Component, OnInit, ViewChild, AfterViewInit, Input  } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
  }
  
  /** Constants used to fill up our data base. */
  const COLORS: string[] = [
    'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
    'aqua', 'blue', 'navy', 'black', 'gray'
  ];
  const NAMES: string[] = [
    'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
    'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
  ];

@Component({
  selector: 'app-active-bid-expandable-table',
  templateUrl: './active-bid-expandable-table.component.html',
  styleUrls: ['./active-bid-expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ActiveBidExpandableTableComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['id', 'progress', 'name', 'color'];
    dataSource: MatTableDataSource<UserData>;
    expandedElement: UserData | null;
  
    @Input()
    componentId;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    // displayedRows$: Observable<ShipData[]>;
    // totalRows$: Observable<number>;
  
    constructor() {
        // Create 100 users
      const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));
  
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(users);
      this.componentId = "activeBidExpandTable";
     }
  
    ngOnInit(): void {
      
    }
  
    ngAfterViewInit(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
  
  /** Builds and returns a new User. */
  function createNewUser(id: number): UserData {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';
  
        let workOrderNum = Math.round(Math.random() * 10000) + 10000;

    return {
      id: workOrderNum.toString(),
      name: "01 Jan 2021",
      progress: "Description",
      color: "Pending"
    };

}
