import { Component, OnInit } from '@angular/core';

enum PlatformPages {
  PROJECTS = 1,
  DISPATCH = 2,
  SCHEDULING = 3,
  INVOICE = 4,
  CMMS = 5,
  FIELD_TECHNICIAN = 6,
  FORMS = 7,
  ADMIN  = 8
}

interface Page {
  id: number,
  title: string,
  icon: string,
  upcoming?: boolean,
  sections: Array<SectionDescriptor>
}

interface SectionDescriptor {
  title?: string,
  subtitle?: string,
  portrait?: boolean,
  img?: string,
  vid?: string,
  desc?: string,
  list?: Array<SectionBullet>,

  height?: number,
  invert?: boolean,
  backgroundImg?: string,
  backgroundColor?: string,
  textColor?: string,
  reverseRow?: boolean
}

interface SectionBullet {
  desc?: string
  icon?: string
}

@Component({
  selector: 'app-platform-modules',
  templateUrl: './platform-modules.component.html',
  styleUrls: ['./platform-modules.component.scss']
})

export class PlatformModulesComponent implements OnInit {

  public platformPages: any = PlatformPages;

  public pages: Array<Page> = [

    {

      id: this.platformPages.PROJECTS,
      title: "Project",
      icon: "construction",
      sections: [

        {
          subtitle: "Project Management",
          img: "assets/images/features/projects/main.png",
          desc: "Understanding where everyone is and what projects need to be done are vital to any organization. We take things 5 steps further by not only showing what needs to be accomplished today, but what projects are coming up and if any leads or jobs are stalling out.",
          reverseRow: false
        },

        {
          subtitle: "Tracker",
          img: "assets/images/features/projects/rotting_projects_activities.png",
          desc: "You define the criteria that best suites your business and we'll do the rest. Swarm Operative tracks your project's states, status, activities, if it's rotting, and everything in between.",
          list: [
            {
              icon: "task_alt",
              desc: "Project rotting that's defined per pipe"
            },
            {
              icon: "task_alt",
              desc: "Activity tracking, whether nothing is happening or if something is past due"
            },
            {
              icon: "task_alt",
              desc: "Team messaging"
            },
            {
              icon: "task_alt",
              desc: "Assigned staff or teams"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "Team Messaging",
          img: "assets/images/features/projects/project_team_messaging.png",
          desc: "Keep your staff, team, and customers in sync with a simple conversation. Dialog back and forth is tracked by either tagging someone in a note within a project or by a user directly responding within a project's chat window. Whether a project is active or you need to revisit it a year from now, all of your data and messages will be safely archived and are readily available.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Overview",
          img: "assets/images/features/projects/project.png",
          desc: "Everything that's done can now be quickly and easily logged to keeps teams in sync and customers happy.",
          list: [
            {
              icon: "task_alt",
              desc: "Log anything from a phone call to a lunch meeting"
            },
            {
              icon: "task_alt",
              desc: "Keep a detailed budget that reflects your profit margin per project"
            },
            {
              icon: "task_alt", 
              desc: "Add an address to see if your project is within your defined service area"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Budget",
          img: "assets/images/features/projects/budget.png",
          desc: "Break everything down to its simplest form, dollars and cents. We provide the tools needed to take a difficult task and show you what you stand to profit.",
          list: [
            {
              icon: "task_alt",
              desc: "Autocalculates you profit, loss, and estimated job cost"
            },
            {
              icon: "task_alt",
              desc: "Create predefined line items so staff can simply prefill a job's budget"
            },
            {
              icon: "task_alt", 
              desc: "Subcategory breakdown to understand where can become more efficient"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Modules",
          portrait: true,
          img: "assets/images/features/projects/modules.png",
          desc: "A Project isn't just a list of activities to do. We allow you to track all aspect of a job so you can keep all teams in sync, all the time.",
          list: [
            {
              icon: "task_alt",
              desc: "Job scope"
            },
            {
              icon: "task_alt",
              desc: "Work Orders"
            },
            {
              icon: "task_alt",
              desc: "Invoicing"
            },
            {
              icon: "task_alt",
              desc: "Attached Inventory"
            },
            {
              icon: "task_alt",
              desc: "Files and images"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        }

      ]

    },

    {

      id: this.platformPages.DISPATCH,
      title: "Dispatch",
      icon: "airport_shuttle",
      sections: [

        {
          subtitle: "Dispatch",
          img: "assets/images/features/dispatch/main.png",
          desc: "Quickly and confidently understand your landscape. Schedules are always changing and so should your ability to pivot and reasses a situation. With a real-time map displaying your daily work orders and technicians to specialized gantt charts and work order tables, you'll never lose control of the day.",

          reverseRow: false
        },

        {
          subtitle: "Map View",
          img: "assets/images/features/dispatch/map.png",
          desc: "At a glance, see where all of your technicians are so as new work comes in you can seemlessly assign a balanced workload.",
          list: [
            {
              icon: "task_alt",
              desc: "Work order, project, and technician tracking"
            },
            {
              icon: "task_alt",
              desc: "Live weather reporting"
            },
            {
              icon: "task_alt",
              desc: "Regionized work density"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "Ghantt Chart",
          img: "assets/images/features/dispatch/ghantt.png",
          desc: "This view allows you to analyze the status of all the work being done and allow you to spot any areas where someone may be falling behind. From here, you can reassign work to someone else, adjust start or end times, and even change status.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Tracker",
          portrait: true,
          img: "assets/images/features/dispatch/quick_filter.png",
          desc: "Not all work orders are the same. Some must take priority over others, while some need to sit in a holding pattern and can't be scheduled for days or even weeks.",
          list: [
            {
              icon: "task_alt",
              desc: "Priority level filtering"
            },
            {
              icon: "task_alt",
              desc: "Scheduled and unscheduled"
            },
            {
              icon: "task_alt",
              desc: "Advanced searching tools for open and closed work orders"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Calendar View",
          img: "assets/images/features/dispatch/calendar.png",
          desc: "At times it can be difficult to understand what's going on in the moment and what's coming up. We don't provide one way of viewing your work, we provide over 5.",
          list: [
            {
              icon: "task_alt",
              desc: "Month View"
            },
            {
              icon: "task_alt",
              desc: "Week long swim lanes"
            },
            {
              icon: "task_alt",
              desc: "Daily task overview"
            },
            {
              icon: "task_alt",
              desc: "Display scheduled work and events"
            },
            {
              icon: "task_alt",
              desc: "Team or staff member filtering options"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Team Filter",
          portrait: true,
          img: "assets/images/features/dispatch/group_filter.png",
          desc: "Our platform provides the ultimate tracking capabilites by allow you to quickly change between which team is actively being managed. Have one person manage everyone or a team of schedulers to handle sectors in order to work together.",
          list: [
            {
              icon: "task_alt",
              desc: "Quick change"
            },
            {
              icon: "task_alt",
              desc: "Autopopulating work order data"
            },
            {
              icon: "task_alt",
              desc: "Custom teams"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        }

      ]

    },

    {

      id: this.platformPages.SCHEDULING,
      title: "Scheduling",
      icon: "home_repair_service",
      sections: [

        {
          subtitle: "Scheduling",
          img: "assets/images/features/scheduling/main.png",
          desc: "You have 30 seconds or less from the time a customer calls in, to the point of scheduling a work order. Newton assists in this process significantly by analyzing addresses to ensure they're in your service area. We also revamped the traditional daily or weekly swim lane or gantt chart scheduling. To make things far easier and clearer, we provide an overview to see a weekly glance at all work being done.",

          reverseRow: false
        },

        {
          subtitle: "Map Oversight",
          portrait: true,
          img: "assets/images/features/scheduling/map.png",
          desc: "See where all of your technicians are as new work comes in. But we don't stop there, as you type in an address Newton will verify that it's within your defined service area.",
          list: [
            {
              icon: "task_alt",
              desc: "Technician overview"
            },
            {
              icon: "task_alt",
              desc: "Satellite view to have immediate oversight of where work will be performed"
            },
            {
              icon: "task_alt",
              desc: "Service area scan"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "Ghantt View",
          img: "assets/images/features/scheduling/ghantt.png",
          desc: "This view allows you to analyze the status of all the work being done and allow you to spot any areas where someone may be falling behind. From here, you can reassign work to someone else, adjust start or end times, and even change status.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Efficiency Reporting",
          img: "assets/images/features/scheduling/efficiency_chart.png",
          desc: "Sometimes it can be difficult to keep track of how much work is being assigned to someone. With Swarm, you can view a breakdown of the efficiency score per technician. Never be unbalanced again.",
          list: [
            {
              icon: "task_alt",
              desc: "Efficiency scores that highlight not only amount of work down but how well the work was completed"
            },
            {
              icon: "task_alt",
              desc: "A.I. tools to assist with self balancing work loads"
            },
            {
              icon: "task_alt",
              desc: "Technician performance tracking"
            },
            {
              icon: "task_alt",
              desc: "Audit reports that can be used for annual performance reviews"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Calendar",
          img: "assets/images/features/scheduling/calendar.png",
          desc: "Multiple vantage points to view and schedule work is a staple of how things are done as effectively as possilbe.",
          list: [
            {
              icon: "task_alt",
              desc: "Month view"
            },
            {
              icon: "task_alt",
              desc: "Weekly and daily swimlanes"
            },
            {
              icon: "task_alt",
              desc: "Track events and scheduled work"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Advanced Filtering",
          portrait: true,
          img: "assets/images/features/scheduling/calendar_filter.png",
          desc: "Data overload is a serious problem, you need to find what you're looking for immediately. Filter in on a team or simply an individual for a fine tuned experience.",
          list: [
            {
              icon: "task_alt",
              desc: "Team filtering and management"
            },
            {
              icon: "task_alt",
              desc: "Focus in on a select individual"
            },
            {
              icon: "task_alt",
              desc: "Filter on events or work orders"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        }

      ]

    },

    {

      id: this.platformPages.INVOICE,
      title: "Invoice",
      icon: "request_quote",
      sections: [

        {
          subtitle: "Invoicing",
          img: "assets/images/features/invoice/main.png", 
          desc: "Save hours of work each week by automating invoices, providing direct links for payment to your customers, and even allowing financing so you never have to worry about turning away a job.",

          reverseRow: false
        },

        {
          subtitle: "Subtitle",
          img: "assets/images/features/invoice/billing_table.png",
          desc: "Keep an eye on what needs to be billed right away or what work is still open. With this, you're able to see in real-time if something is over budget or deposits are due.",
          list: [
            {
              icon: "task_alt",
              desc: "Open and closed work order tracking"
            },
            {
              icon: "task_alt",
              desc: "Table view for quick sorting"
            },
            {
              icon: "task_alt",
              desc: "Auto invoice generating"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "Generator",
          img: "assets/images/features/invoice/generator.png",
          desc: "Use our simple one click invoice generating tools. All work performed, inventory used, and hours logged on a work order will be quickly scanned and assesmbled for you in an easy to read form.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Settings",
          img: "assets/images/features/invoice/settings.png",
          desc: "Invoices can vary from one operation to the next. That's why we don't lock you into one way of doing things and we allow you to define what you charge, fees, plus your own terms and conditions.",
          list: [
            {
              icon: "task_alt",
              desc: "Require customer digital signatures"
            },
            {
              icon: "task_alt",
              desc: "Create fees based on percentage or a flat rate"
            },
            {
              icon: "task_alt",
              desc: "Customize what you show your customer"
            },
            {
              icon: "task_alt",
              desc: "QR codes that embed the invoice number for quick scanning"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Customer Signature",
          img: "assets/images/features/invoice/signature.png",
          desc: "Ensure you get a customer's signature before you do any work or provide any services. A digital signature helps to protect your business from an issue that could arise in the future.",
          list: [
            {
              icon: "account_balance",
              desc: "Digital signature"
            },
            {
              icon: "task_alt",
              desc: "Easy to use auditing for use to verify work was completed"
            },
            {
              icon: "question_answer",
              desc: "Smooth and more realistic"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Invoicing PDF",
          img: "assets/images/features/invoice/pdf.png",
          desc: "Generate a quick PDF that you can download and store, email to a customer, or print off in the event you want hard copies. Whatever your style, we've got you covered.",
          list: [
            {
              icon: "task_alt",
              desc: "Autogenerating professional PDF"
            },
            {
              icon: "task_alt",
              desc: "QR code for quick access"
            },
            {
              icon: "task_alt",
              desc: "Hard copy"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        }

      ]

    },

    {

      id: this.platformPages.CMMS,
      title: "Inventory",
      icon: "warehouse",
      sections: [

        {
          subtitle: "Inventory Management",
          img: "assets/images/features/inventory/main.png", 
          desc: "Swarm Operative's CMMS module isn't made for just simple inventory tracking. We've enhanced and integrated every aspect of it allowing you to monitor all of your assets, trucks, tools and even your storage facility. All while paring this critical type of information into every work order with ease.",

          reverseRow: false
        },

        {
          subtitle: "Reservation System",
          img: "assets/images/features/inventory/reservation.png",
          desc: "Parts and equipment are limited. When a lot of work is going on things can become chaotic and inventory may run low and orders might not get filled fast enough. That's why we're created a reservation system so something can be reserved and approved even if the technician isn't immediately able to pick it up.",
          list: [
            {
              icon: "task_alt",
              desc: "Reserve and approve, no more first come first serve"
            },
            {
              icon: "task_alt",
              desc: "Tags to highlight low inventory"
            },
            {
              icon: "task_alt",
              desc: "Calculates inventory based on reservation system, not simply by what's physically in front of you"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "Inventory / Assets / Equipment",
          portrait: true,
          img: "assets/images/features/inventory/cmms.png",
          desc: "Average systems will allow you to track and manage basic inventory. We take things 10 steps further by displaying pictures, tracking inventory, tracking equipment, and even providing detailed breakdowns per serieal number.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Locations",
          img: "assets/images/features/inventory/locations.png",
          desc: "Whether you store everything in one place or multiple, we allow you to create and manage all of your locations in an easy to use UI.",
          list: [
            {
              icon: "task_alt",
              desc: "Filter your search per location"
            },
            {
              icon: "task_alt",
              desc: "Scan a workers van or truck for nearby equipment or parts"
            },
            {
              icon: "task_alt",
              desc: "Bar code scanning with your smartphone, no need to purchase expensive equipment"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Details",
          img: "assets/images/features/inventory/asset.png",
          desc: "Every piece of equipment or inventoried item holds a lot of data. Our platform give you the ability to track per serial number or in bulk.",
          list: [
            {
              icon: "task_alt",
              desc: "Location Management"
            },
            {
              icon: "task_alt",
              desc: "Serial number tracking"
            },
            {
              icon: "task_alt",
              desc: "Images alongside descriptions"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Work Orders",
          img: "assets/images/features/inventory/asset_details.png",
          desc: "Your inventory and equipment sometimes has some wear and tear, or perhaps it just needs to be check on to ensure a product is still servicable. Assign routine or unscheduled work orders to stay on top of everything.",
          list: [
            {
              icon: "task_alt",
              desc: "Custom meter recordings"
            },
            {
              icon: "task_alt",
              desc: "Routine or unscheduled work orders"
            },
            {
              icon: "task_alt",
              desc: "Audit logs of work done"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Meter Readings",
          img: "assets/images/features/inventory/meter_readings.png",
          desc: "It can be tedious to look into each inventoried item. Instead, we've created a designated page tailored to looking at all of your equipments readings to help streamline your operation.",
          list: [
            {
              icon: "task_alt",
              desc: "Custom meter readings"
            },
            {
              icon: "task_alt",
              desc: "Everything, all in one place"
            },
            {
              icon: "task_alt",
              desc: "Serial number tracking"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

      ]

    },

    {
      id: this.platformPages.FIELD_TECHNICIAN,
      title: "Upcoming",
      icon: "tips_and_updates",
      upcoming: true,
      sections: []
    },

    {

      id: this.platformPages.FORMS,
      title: "Forms",
      icon: "file_copy",
      sections: [

        {
          subtitle: "Forms",
          img: "assets/images/features/forms/main.png", 
          desc: "Nothing can be done without getting it first in writing. We've made everyday tasks such as change order requests, collecting signatures, and even sending document or work orders out to external parties such as subcontractors as easy as 1, 2, 3. All powered by our simple drag and drop form builder.",

          reverseRow: false
        },

        {
          subtitle: "Templates",
          img: "assets/images/features/forms/templates.png",
          desc: "Starting over from scratch isn't very efficient. Create as many templates as you'd like so employees can quickly put together a proposal, a checklist, or even a work order.",
          list: [
            {
              icon: "task_alt",
              desc: "Quickly create forms"
            },
            {
              icon: "task_alt",
              desc: "Defaulted field values"
            },
            {
              icon: "task_alt",
              desc: "External share options"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "External Access",
          img: "assets/images/features/forms/external_access.png",
          desc: "Forms are meant to be shared. You don't have to force subcontractors to use Swarm Operative in order to edit a form and to keep you, your teams, and your customers in the loop on how things are going.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Advanced Form Builder",
          img: "assets/images/features/forms/builder.png",
          desc: "We give you the power to create advance and complex forms that span from fields, images, calendar views, and even digital signature blocks. All of these advanced features with a simple to use drag and drop system.",
          list: [
            {
              icon: "task_alt",
              desc: "Drag and drop"
            },
            {
              icon: "task_alt",
              desc: "Digital signatures"
            },
            {
              icon: "task_alt",
              desc: "Dynamic data"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Audit Logging",
          img: "assets/images/features/forms/audit.png",
          desc: "Forms are created and edited very frequently, the problem is trying to keep track of what was changed, who made an edit, and why an adjustment was made. Keep your records straight with form audit logging.",
          list: [
            {
              icon: "task_alt",
              desc: "Track when a form changed"
            },
            {
              icon: "task_alt",
              desc: "Form life cycle"
            },
            {
              icon: "task_alt",
              desc: "Audit History"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Dynamic Builds",
          portrait: true,
          img: "assets/images/features/forms/components.png",
          desc: "Most form building tools allow you to builde with some basic images, fields, and text boxes. With Swarm Operative, you can build complex and dynamic forms that contain maps, calendars, signature blocks, and even inventory attachments.",
          list: [
            {
              icon: "task_alt",
              desc: "Auto data importing"
            },
            {
              icon: "task_alt",
              desc: "Living documents"
            },
            {
              icon: "task_alt",
              desc: "Dynamic data"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        }

      ]

    },

    {

      id: this.platformPages.ADMIN,
      title: "Admin",
      icon: "filter_tilt_shift",
      sections: [

        {
          subtitle: "Control Panel",
          img: "assets/images/features/admin/main.png", 
          desc: "Complex features and functions don't need to be complex. Swarm provides a robust set of tools to allow you to run your business as effective as possible.",

          reverseRow: false
        },

        {
          subtitle: "Employee Management",
          img: "assets/images/features/admin/employees.png",
          desc: "Keep track of everyone that you give access to your company. Manage their profiles and send invites directly to their email using our simple company linking system.",
          list: [
            {
              icon: "task_alt",
              desc: "Easy Link System"
            },
            {
              icon: "task_alt",
              desc: "Employee profile"
            },
            {
              icon: "task_alt",
              desc: "One step invite and revoke"
            }
          ],

          textColor: "#ffffff",
          backgroundColor: "#000000",
          reverseRow: true
        },

        {
          subtitle: "Permissions",
          img: "assets/images/features/admin/permissions.png",
          desc: "Create as many teams as you'd like. Assigning permissions to each team and restrict users from accessing pages or making changes that you want designated for select individuals.",
          reverseRow: false
        },

        {
          subtitle: "",
          backgroundImg: 'assets/images/others/test-v2.jpg',
          invert: true,
          height: 200,
          desc: "",

          textColor: "#ffffff",
          backgroundColor: "#292929",
          reverseRow: true
        },

        {
          subtitle: "Service Area",
          img: "assets/images/features/admin/service_area.png",
          desc: "Define where you want to work. Our A.I. will work with your entire staff to ensure no work is accidently accepted outside of your area.",
          list: [
            {
              icon: "task_alt",
              desc: "Work zones"
            },
            {
              icon: "task_alt",
              desc: "A.I. managed"
            },
            {
              icon: "task_alt",
              desc: "Multi zone designation"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        },

        {
          subtitle: "Custom Status",
          img: "assets/images/features/admin/custom_status.png",
          desc: "We allow you to break free from the standard systems that lock you into the way they do things. Create your own work order statuses and assign text plus email messages that will be autosent to your customers.",
          list: [
            {
              icon: "task_alt",
              desc: "Unlimited amount"
            },
            {
              icon: "task_alt",
              desc: "Define text and email messages"
            },
            {
              icon: "task_alt",
              desc: "Turn on or off customer notifications"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: false
        },

        {
          subtitle: "Work Order Builder",
          img: "assets/images/features/admin/work_order_builder.png",
          desc: "Don't follow the rules and create your own path. Build a work order that best fits your company, create templates, and change between any version you like.",
          list: [
            {
              icon: "task_alt",
              desc: "Easy drag and drop system"
            },
            {
              icon: "task_alt",
              desc: "Versions"
            },
            {
              icon: "task_alt",
              desc: "Unlimited templates"
            }
          ],

          textColor: "#000000",
          backgroundColor: "#ffffff",
          reverseRow: true
        }

      ]

    },

  ];


  public activePage: Page = this.pages[0];


  constructor() { }

  ngOnInit() {

  }

  public changePage(page: Page): void {

    if(!page.upcoming) {
      this.activePage = page;
    }

  }

}
