import { Component, OnInit, AfterViewInit, Input, SimpleChanges, ViewChild  } from '@angular/core';

import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChartDataSets, ChartOptions, ChartType, ChartData } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label, MultiDataSet } from 'ng2-charts';


@Component({
  selector: 'app-vendor-chart-bar',
  templateUrl: 'vendor-chart-bar.component.html',
  styleUrls: ['vendor-chart-bar.component.scss']
})
export class VendorChartBarComponent implements OnInit, AfterViewInit {
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  
  @Input() labels: Array<string> = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  @Input() data: any = [
    [350, 450, 150],
    // [50, 150, 120],
    // [250, 130, 70],
  ];
  @Input() colors: Color[] = [ ];

  public barChartLabels: Label[];
  public barChartData: MultiDataSet;
  public barChartColors: Color[];
  public update: boolean = false;

  public options: any = {
    

          legend: {
              display: false,
              labels: {
                fontColor: '#555555'
              }
          },

          // plugins: {
          //   datalabels: {
          //   color: ['#555555', '#555555', '#ffffff']
          //   }
          // },

          scales: {
            yAxes: [{
                display: true,
                ticks: {
                    suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                    // OR //
                    beginAtZero: true   // minimum value will be 0.
                }
            }]
          },

          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 12

  }

   // bar
  // public barChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  // public barChartData: MultiDataSet = [
  //   [350, 450, 100],
  //   [50, 150, 120],
  //   [250, 130, 70],
  // ];

  public chartType: ChartType = 'bar';
    
  constructor(public snackBar: MatSnackBar, public navigatorService: NavigatorService) {
      
  }
  
  ngOnInit(): void {
    this.barChartLabels = this.labels;
    this.barChartData = this.data;
    this.barChartColors = this.colors;
  }
  
  ngAfterViewInit(): void {
      
  }

  ngOnDestroy() {
    
  }  

  ngOnChanges(changes: SimpleChanges): void {

    this.update = true;
    let self = this;

    setTimeout(function(){
      self.barChartData = self.data;
      self.barChartLabels = self.labels;
      self.update = false;
    }, 100);

    
  }

  // ===============================
  // Charts Logic
  // ===============================

  // bar Events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
