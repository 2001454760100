import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-vendor-crm-customer-viewer',
  templateUrl: 'vendor-crm-customer-viewer.component.html',
  styleUrls: ['vendor-crm-customer-viewer.component.scss']
})
export class VendorCRMCustomerViewerComponent implements OnInit { 
  @Input() minimized: boolean = false;

  constructor() { }

  ngOnInit() {
    
  }

}
