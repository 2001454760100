import { Component, OnInit, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavigatorService } from '../../../services/vendor/navigator.service';
import { Observable, Subscription } from 'rxjs'; 

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutoCompleteService, PredifinedPrice } from 'src/app/data-models/models';

export interface TableTitles {
  table: string,
  category: String,
  unit: String
}

@Component({
  selector: 'app-vendor-custom-services',
  templateUrl: 'vendor-custom-services.component.html',
  styleUrls: ['vendor-custom-services.component.scss'] 
})

export class VendorCustomServicesComponent implements OnInit {

  @Input() services: Array<AutoCompleteService> = [];
  @Input() tableTitles: TableTitles = {
    table: "Services",
    category: "Service",
    unit: "Hours"
  }

  @Input() predefinedPrices: Array<PredifinedPrice> = [];

  @Output() servicesRenderedChange: EventEmitter<Array<AutoCompleteService>> = new EventEmitter<Array<AutoCompleteService>>();

  public infoForm:FormGroup;

  constructor(private navigatorService: NavigatorService, public formBuilder: FormBuilder, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(this.services == null || this.services == undefined) {
      this.services = [];
    }

    this.services = [...this.services];
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Work Order: " + changes.prop);
    
    // if(changes.workOrders != undefined && changes.workOrders.currentValue != undefined) {

      // this.workOrders = []
      // this.workOrders = changes.workOrders.currentValue;
      // this.dataSource.data = this.workOrders;

    // } 

    this.updateInvoiceForm();
  } 

  public addService(): void {
    this.services.push(
      {
        desc: "",
        price: null,
        chargePrice: null,
        unit: 'ea'
      }
    );

    this.servicesRenderedChange.emit(this.services);
  }

  public removeService(serviceIndex: number): void {
    this.services.splice(serviceIndex, 1);

    this.servicesRenderedChange.emit(this.services);
  }

  public addPredefinedService(predefinedPrice: PredifinedPrice): void {
    this.services.push(
      {
        desc: predefinedPrice.title,
        price: predefinedPrice.price,
        chargePrice: predefinedPrice.chargePrice,
        unit: 'ea'
      }
    );

    this.servicesRenderedChange.emit(this.services);
  }

  public updateServices(): void {
    this.servicesRenderedChange.emit(this.services);
  }
  
  public updateInvoiceForm(): void {
    if(this.infoForm != undefined) {
      this.infoForm.reset();
    }
  }
} 