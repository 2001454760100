import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation, Renderer2, ComponentRef,
    ComponentFactoryResolver,
    ViewContainerRef,
    ViewRef,  
    Input,
    SimpleChange} from '@angular/core';

import { keyTypesEnum, NavigatorService } from '../../../services/vendor/navigator.service';

import { WorkOrder, Address, LatLng, WorkOrderFilter, Profile, CustomChargeField, InvoiceSettings, CRMDeal } from '../../../data-models/models';
import { Observable, Subscription } from 'rxjs'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';



@Component({
  selector: 'app-vendor-invoice-base',
  templateUrl: 'vendor-invoice-base.component.html',
  styleUrls: ['vendor-invoice-base.component.scss'],
//   encapsulation: ViewEncapsulation.Emulated

})


export class VendorInvoiceBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('invoiceGenerator') invoiceGenerator;
  @Input() filteredEmployees: Array<string> = []; 
  @Input() focusedWorkOrder: WorkOrder = null;
  @Input() focusedDeal: CRMDeal = null;

  public invoiceSettings: InvoiceSettings = null;
  public autoHideTable: boolean = true;
  private keyTypes:any = keyTypesEnum;

  public isNewCustomChargeFieldOpen: boolean = false;
  public customChargeFields: Array<CustomChargeField> = [];
  public infoForm: FormGroup;

  public activeInvoice: number = 0;
  public isFullScreen: boolean = false;
  child_unique_key: number = 0;
  componentsReferences = Array<ComponentRef<any>>()

  private openWorkOrderSubscriber: Subscription;
  private closedWorkOrderSubscriber: Subscription;
  public weekStart: Date = new Date;
  public workOrders: Array<WorkOrder> = [];

  private tableFilter: WorkOrderFilter = null;
  public openOrClosedFilter: number = 1;
  private startDate: Date = new Date();
  private endDate: Date = new Date();
  
  constructor(private navigatorService: NavigatorService, public formBuilder: FormBuilder) { 
        
  }

  ngOnInit(): void {
    this.startDate.setDate(1);

    this.endDate = new Date(this.startDate);
    this.endDate.setMonth(this.startDate.getMonth() + 1);
    this.endDate.setDate(0);

    this.tableFilter = {
      searchFilter: "",
      dateFilter: {
        startDate: this.startDate,
        endDate: this.endDate
      }
    };

    this.infoForm = this.formBuilder.group({
      terms_conditions: [ '' ],
      tax_rate: [ 0, Validators.required ],
      grace_period: [ 0, Validators.required ],
      display_tax_rate: [ true, Validators.required ],
      display_qr_code: [ true, Validators.required ],
      display_logo: [ true, Validators.required ],
      display_signature: [ true, Validators.required ],
      display_overview_detail: [ true, Validators.required ]
    });

    this.getInvoiceSettings();
    this.subscribeToOpenWorkOrders();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
      
    if(this.openWorkOrderSubscriber) {
      this.openWorkOrderSubscriber.unsubscribe();
    }

    if(this.closedWorkOrderSubscriber) {
      this.closedWorkOrderSubscriber.unsubscribe();
    }

  }
  
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {  
    console.log("Work Order Dynamic Data: ", changes);
    console.log("Filtered Employees: ", this.filteredEmployees);
    
    if(this.filteredEmployees != null) {
      this.openOrClosedWorkOrderSwitch(this.openOrClosedFilter);
    }
  }

  getInvoiceSettings(): void {
    this.navigatorService.getCompanyLevelInvoiceSettings().then(settings => {
      if(settings != null && settings != undefined) {
        this.invoiceSettings = settings;
        this.updateSettingsForm(settings);
      }
    })
  }

  updateSettingsForm(settings: InvoiceSettings): void {

    if(settings != null && settings != undefined) {

      if(settings.terms_conditions != null && settings.terms_conditions != undefined)
      this.infoForm.controls["terms_conditions"].setValue(settings.terms_conditions);

      if(settings.grace_period != null && settings.grace_period != undefined)
      this.infoForm.controls["grace_period"].setValue(settings.grace_period);

      if(settings.terms_conditions != null && settings.tax_rate != undefined)
      this.infoForm.controls["tax_rate"].setValue(settings.tax_rate);

      if(settings.terms_conditions != null && settings.display_tax_rate != undefined)
      this.infoForm.controls["display_tax_rate"].setValue(settings.display_tax_rate);

      if(settings.terms_conditions != null && settings.display_qr_code != undefined)
      this.infoForm.controls["display_qr_code"].setValue(settings.display_qr_code);

      if(settings.terms_conditions != null && settings.display_logo != undefined)
      this.infoForm.controls["display_logo"].setValue(settings.display_logo);

      if(settings.terms_conditions != null && settings.display_signature != undefined)
      this.infoForm.controls["display_signature"].setValue(settings.display_signature);

      if(settings.terms_conditions != null && settings.display_overview_detail != undefined)
      this.infoForm.controls["display_overview_detail"].setValue(settings.display_overview_detail);

      console.log("Invoice Settings: ", settings);
      this.customChargeFields = settings.custom_charge_fields;
    }
  }

  subscribeToOpenWorkOrders(): void {
    this.openWorkOrderSubscriber = this.navigatorService.getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders(this.startDate, this.endDate, "", this.filteredEmployees, 0, 100).subscribe(data => { 
      // let result = this.filterData(data); 
      let workOrders: any = data;
      this.workOrders = [];

      if(data != null) {
        for(let index = 0; index < data.length; index++) {
          this.workOrders.push(workOrders[index]);
        }
      }

      if(this.closedWorkOrderSubscriber == null || this.closedWorkOrderSubscriber == undefined) {
        this.subscribeToClosedWorkOrders();
      }
      
    });
  }

  subscribeToClosedWorkOrders(): void {
    this.closedWorkOrderSubscriber = this.navigatorService.getEmployeeFilteredPagedDateRangeSearchClosedWorkOrders(this.startDate, this.endDate, "", this.filteredEmployees, 0, 100).subscribe(data => { 

      // let result = this.filterData(data); 
      let workOrders: any = data;
      this.workOrders = [];

      if(data != null) {
        for(let index = 0; index < data.length; index++) {
          this.workOrders.push(workOrders[index]);
        }
      }

    });
  }

  getFilteredOpenWorkOrders(searchFilter: string, startDate: Date, endDate: Date): void {
    this.navigatorService.getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders(startDate, endDate, searchFilter, this.filteredEmployees, 0, 100);
  }

  getFilteredClosedWorkOrders(searchFilter: string, startDate: Date, endDate: Date): void {
    this.navigatorService.getEmployeeFilteredPagedDateRangeSearchClosedWorkOrders(startDate, endDate, searchFilter, this.filteredEmployees, 0, 100);
  }

  filterWorkOrders(filter: WorkOrderFilter): void {
    this.tableFilter = filter;

    if(this.openOrClosedFilter == 0) {
      this.getFilteredOpenWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    } else {
      this.getFilteredClosedWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    }
  }

  openOrClosedWorkOrderSwitch(openOrClosedWorkOrders: number): void {
    this.openOrClosedFilter = openOrClosedWorkOrders;

    if(this.openOrClosedFilter == 0) {
      this.getFilteredOpenWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    } else {
      this.getFilteredClosedWorkOrders(this.tableFilter.searchFilter, this.tableFilter.dateFilter.startDate, this.tableFilter.dateFilter.endDate);
    }
  }

  navClick(event: any, id: number): void {
      let target = event.target || event.srcElement || event.currentTarget;
      let idAttr = target.attributes.id;
      let value = idAttr.nodeValue;

      document.querySelector("li.active").classList.remove("active");
      document.querySelector("#" + value).classList.add("active");

      this.activeInvoice = id;
      // this.focusedWorkOrder = null;

      this.getInvoiceSettings();
  }

  scrollToInvoice() {
    this.invoiceGenerator.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  fullScreenClick(): void {

      if(this.isFullScreen) {
          this.isFullScreen = false;
          document.querySelector(".vendor-container").classList.remove("fullScreen");
          document.querySelector(".vendor-container").classList.add("exitFullScreen");
      } else {
          this.isFullScreen = true;
          document.querySelector(".vendor-container").classList.remove("exitFullScreen");
          document.querySelector(".vendor-container").classList.add("fullScreen");
      }
  }

  invoiceFocusChange(workOrder: WorkOrder): void {
    if(workOrder) {
      this.autoHideTable = false;
      this.focusedWorkOrder = workOrder;
    } 
  }

  updateSettings(): void {
    setTimeout(() => {
      let settings: InvoiceSettings = {
        terms_conditions: this.infoForm.controls["terms_conditions"].value,
        tax_rate: this.infoForm.controls["tax_rate"].value,
        display_tax_rate: this.infoForm.controls["display_tax_rate"].value,
        display_qr_code: this.infoForm.controls["display_qr_code"].value,
        display_logo: this.infoForm.controls["display_logo"].value,
        display_signature: this.infoForm.controls["display_signature"].value,
        display_overview_detail: this.infoForm.controls["display_overview_detail"].value,
        grace_period: this.infoForm.controls["grace_period"].value,
        
        custom_charge_fields: this.customChargeFields
      }

      console.log("Invoice Settings Change: ", settings);

      this.navigatorService.updateCompanyLevelInvoice(settings);
    }, 50);
  }

  showNewChargeBlock(): void {
    this.isNewCustomChargeFieldOpen = true;
  }

  hideNewChargeBlock(): void {
    this.isNewCustomChargeFieldOpen = false;
  }

  // Type currently reads in as a boolean and then gets converted into an int. However,
  // future applications may require more than two options and this setup allows for
  // that future feature development.
  addNewCustomChargeField(title: string, type: boolean): void {
    let localType: number = 0;

    if(!type) {
      localType = 1;
    }

    this.customChargeFields.push({
      id: this.navigatorService.generateKey(this.keyTypes.INVOICE_CUSTOM_CHARGE_FIELD),
      title: title,
      value: 0,
      type: localType
    });

    this.updateSettings();

    this.hideNewChargeBlock();
  }

  deleteNewCustomChargeField(id: string): void {
    let chargeIndex: number = this.customChargeFields.findIndex( element => {
      return element.id == id;
    });

    this.customChargeFields.splice(chargeIndex, 1);

    this.updateSettings();
  }

  updateCustomChargeFieldValue(customChargeInput: string, chargeIndex: number): void {
    let chargeInput: number = Number(customChargeInput);

    this.customChargeFields[chargeIndex].value = chargeInput;

    this.updateSettings();

    console.log("Custom Charge Value Change: ", chargeInput);
  }
}