import { NgModule } from '@angular/core';

// Components
import { TextEditorComponent } from './text-editor/text-editor.component';
import { DocumentEditorToolbarComponent } from './document-editor-toolbar/document-editor-toolbar.component';


@NgModule({
  imports: [
  ],
  exports: [
    TextEditorComponent,
    DocumentEditorToolbarComponent
  ],
  declarations: [ 
    TextEditorComponent,
    DocumentEditorToolbarComponent
  ],
  bootstrap: [
    
  ],
  entryComponents:[
    
  ],
  providers:[

  ]
})

export class PrebuiltComponentsModule { }