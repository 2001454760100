import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { GroupedObservable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';


export interface GroupColor {
  primary: string;
  secondary: string;
}

export interface Availability {
  date: Date;
  startTimes: Date[];
  durations: number[]
}

export interface Group<MetaType = any> {
  id?: number;
  group: string;
  availability: Availability[];
  end?: Date;
  title: string;
  color?: GroupColor;
  meta?: MetaType;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#08e33f',
    secondary: '#87d69b',
  },
  orange: {
    primary: '#fa8500',
    secondary: '#fab15f',
  },
  teal: {
    primary: '#00ffcc',
    secondary: '#cffcf3',
  },
  purple: {
    primary: '#d900fa',
    secondary: '#e687f5',
  }
};

const CURRENT_DATE: Date = new Date();
const MONTH: number = CURRENT_DATE.getMonth();

export var groups: Group[] = [
  {
    id: 0,
    group: "admin",
    title: "Admin",
    availability: [
      {
        date: new Date(2021, MONTH, 1, 0,0,0),
        startTimes: [new Date(2021, MONTH, 1, 0,0,0), new Date(2021, MONTH, 1, 5,0,0)], 
        durations: [60]
      }
    ],
    color: colors.red
  },
  {
    id: 1,
    group: "technician",
    title: "Technician",
    availability: [
      {
        date: new Date(2021, MONTH, 10, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 15, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 15, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0),
          new Date(2021, MONTH, 1, 0,30,0)
        ], 
        durations: [60]
      }
    ],
    color: colors.blue
  },
  {
    id: 2,
    group: "consultant",
    title: "Consultant",
    availability: [
      {
        date: new Date(2021, MONTH, 1, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 2, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 10, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0),
          new Date(2021, MONTH, 1, 3,0,0),
          new Date(2021, MONTH, 1, 3,30,0),
          new Date(2021, MONTH, 1, 4,30,0),
          new Date(2021, MONTH, 1, 6,30,0),
          new Date(2021, MONTH, 1, 12,30,0),
          new Date(2021, MONTH, 1, 16,30,0),
          new Date(2021, MONTH, 1, 17,30,0),
          new Date(2021, MONTH, 1, 19,30,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 15, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0),
          new Date(2021, MONTH, 1, 3,0,0),
          new Date(2021, MONTH, 1, 3,30,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 16, 0,0,0),
        startTimes: [new Date(2021, MONTH, 15, 0,0,0)], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 21, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 22, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0)
        ], 
        durations: [60]
      },
      {
        date: new Date(2021, MONTH, 29, 0,0,0),
        startTimes: [
          new Date(2021, MONTH, 1, 0,0,0),
          new Date(2021, MONTH, 1, 2,0,0),
          new Date(2021, MONTH, 1, 2,30,0),
          new Date(2021, MONTH, 1, 4,0,0),
          new Date(2021, MONTH, 1, 6,0,0),
          new Date(2021, MONTH, 1, 12,0,0),
          new Date(2021, MONTH, 1, 15,30,0),
          new Date(2021, MONTH, 1, 17,0,0),
          new Date(2021, MONTH, 1, 19,0,0),
          new Date(2021, MONTH, 1, 0,30,0),
          new Date(2021, MONTH, 1, 3,0,0),
          new Date(2021, MONTH, 1, 3,30,0),
          new Date(2021, MONTH, 1, 4,30,0),
          new Date(2021, MONTH, 1, 6,30,0)
        ], 
        durations: [60]
      }
    ],
    color: colors.yellow
  },
  {
    id: 3,
    group: "test1",
    title: "Test 1",
    availability: [
      {
        date: new Date(2021, MONTH, 2, 0,0,0),
        startTimes: [new Date(2021, MONTH, 1, 0,0,0), new Date(2021, MONTH, 1, 5,0,0)], 
        durations: [60]
      }
    ],
    color: colors.green
  },
  {
    id: 4,
    group: "test2",
    title: "Test 2",
    availability: [
      {
        date: new Date(2021, MONTH, 5, 0,0,0),
        startTimes: [new Date(2021, MONTH, 1, 0,0,0), new Date(2021, MONTH, 1, 5,0,0)], 
        durations: [60]
      }
    ],
    color: colors.orange
  },
  {
    id: 5,
    group: "test3",
    title: "Test 3",
    availability: [
      {
        date: new Date(2021, MONTH, 19, 0,0,0),
        startTimes: [new Date(2021, MONTH, 1, 0,0,0), new Date(2021, MONTH, 1, 5,0,0)], 
        durations: [60]
      }
    ],
    color: colors.teal
  },
  {
    id: 6,
    group: "test4",
    title: "Test 4",
    availability: [
      {
        date: new Date(2021, MONTH, 29, 0,0,0),
        startTimes: [new Date(2021, MONTH, 29, 0,0,0), new Date(2021, MONTH, 29, 5,0,0)], 
        durations: [60]
      }
    ],
    color: colors.purple
  }
]

export class VendorCalendarGroups  {

  
  
}
