import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, Inject, SimpleChanges  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isThisMinute } from 'date-fns';
import { is } from 'date-fns/locale';
import { ImgPanelStepTrackedDetails, ImgPanelTrackedDetails, InfoPanel, InfoPanelImgSlide, InfoPanelStep, InfoPanelStepTrackedDetails, InfoPanelTrackedDetails } from 'src/app/data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

export enum infoPanelCategoryEnum {
  INFORMATIONAL = 0,
  CONFIRM = 1
}

export enum buttonAlignmentEnum {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2
}

@Component({
    selector: 'app-info-confirm-dialog.component',
    templateUrl: 'info-confirm-dialog.component.html',
    styleUrls: ['info-confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class InfoConfirmDialogComponent implements OnInit {
    public infoPanelCategories: any = infoPanelCategoryEnum;
    public buttonAlignmentCategories: any = buttonAlignmentEnum;
    
    public actionBtnAlignment = this.buttonAlignmentCategories.LEFT;

    public dialogTitle: string = "";

    public panelStep: InfoPanelStep = null;
    private panelStepIndex: number = 0;

    private infoPanelTrackedReturn: InfoPanelTrackedDetails = null;

    private isConfirmed: boolean = false;
    private IP: any = null;
    
    constructor(
      private http: HttpClient,
      private navigatorService: NavigatorService, 
      private imageHandlerService: ImageHandlerService, 
      private fb: FormBuilder, 
      private datePipe: DatePipe, 
      public matDialogRef: MatDialogRef<InfoConfirmDialogComponent>, 
      @Inject(MAT_DIALOG_DATA) public infoPanel: InfoPanel,) {

    }
  
    ngOnInit() {
      this.matDialogRef.disableClose = true;

      if(this.infoPanel != undefined && this.infoPanel != null) {
        this.panelStep = this.infoPanel.steps[0];
        this.panelStepIndex = 0;

        this.dialogTitle = this.infoPanel.title;

        this.initializeTrackedReturn();
        this.getIP();
      }
    }

    ngOnChanges(changes: SimpleChanges): void {

      if(this.infoPanel != undefined && this.infoPanel != null) {
        this.panelStep = this.infoPanel.steps[0];
        this.panelStepIndex = 0;

        this.dialogTitle = this.infoPanel.title;

        this.initializeTrackedReturn();
      }

    }

    initializeTrackedReturn(): void {
      this.infoPanelTrackedReturn = {
        id: this.infoPanel.id,
        userId: this.navigatorService.getProfileId(),
        category: this.infoPanel.category,
        info_panel_completed: this.infoPanel.info_panel_completed,
        steps: [],
        imgSteps: [],
        IP: this.IP
      }

      for(let step of this.infoPanel.steps) {
        this.infoPanelTrackedReturn.steps.push({
          step_id: step.step_id,
          date_seen: null,
          date_confirmed: null,
          date_accepted: null,
          isConfirmed: false,
          isAccepted: false,
          step_completed: step.step_completed
        });

      }
    }

    imgTrackedDetailsUpdated(trackedDetails: ImgPanelTrackedDetails): void {

      let trackedDetailIndex: number = this.infoPanelTrackedReturn.imgSteps.findIndex(
        (imgStep: ImgPanelTrackedDetails) => {
          return imgStep.id == trackedDetails.id;
        }
      );

      if(trackedDetailIndex == -1) {
        this.infoPanelTrackedReturn.imgSteps.push(trackedDetails);
      } else {
        this.infoPanelTrackedReturn.imgSteps[trackedDetailIndex] = trackedDetails;
      }

      console.log("Img Tracked Details Update: ", this.infoPanelTrackedReturn);

    }

    getIP(): void {

      this.http.get('https://www.cloudflare.com/cdn-cgi/trace', {responseType: 'text'}).subscribe( (data: any) => {

        if(data != undefined && data != null) {
          // Convert key-value pairs to JSON
          // https://stackoverflow.com/a/39284735/452587
          data = data.trim().split('\n').reduce(function(obj, pair) {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
          }, {});

          this.IP = data;
          console.log("IP After Data: ", data);
        }

      });
    }

    stepRequiredChecker(): boolean {
      let isRequirementsMet: boolean = true;

      this.infoPanelTrackedReturn.steps[this.panelStepIndex]


      // Image step requirement checker
      if(this.infoPanel.steps[this.panelStepIndex].step_message.imageMesssages != undefined) {

        for(let imgStep of this.infoPanel.steps[this.panelStepIndex].step_message.imageMesssages) {
          
          if(imgStep.descriptors != undefined) {

            for(let descriptor of imgStep.descriptors) {

              if(descriptor.confirmRequired != undefined && descriptor.confirmRequired) {
                isRequirementsMet = this.checkDetailTrackerImgConfirmStatus(imgStep.id, descriptor.id);

                if(!isRequirementsMet) {
                  return isRequirementsMet;
                }
              }

            }

          }

        }

      }



      return isRequirementsMet;
    }

    checkDetailTrackerImgConfirmStatus(imgStepID: string, descriptorID: string): boolean {
      let tracker: boolean = false;
      

      for(let trackedImgStep of this.infoPanelTrackedReturn.imgSteps) {

        // Finding Image Slide
        let imgStepIndex: number = trackedImgStep.steps.findIndex( 
          (step: ImgPanelStepTrackedDetails) => { 
            return step.id == imgStepID}
        );

        if(imgStepIndex == -1) {
          continue;
        }

        // Finding Descriptor
        let descriptorIndex: number = trackedImgStep.steps[imgStepIndex].descriptors.findIndex( 
          (descriptor: InfoPanelStepTrackedDetails) => {
            return descriptor.step_id == descriptorID;
          }
        )

        if(descriptorIndex == -1) {
          continue;
        }

        tracker = trackedImgStep.steps[imgStepIndex].descriptors[descriptorIndex].isAccepted

        return tracker;
        
      }

      return tracker;
    }

    autoChangeButtonAlignment(imgSlide: InfoPanelImgSlide): void {
      let align: number = this.buttonAlignmentCategories.LEFT;

      if(imgSlide.isImgLeftAligned) {
        align = this.buttonAlignmentCategories.RIGHT;
      }

      switch(align) {

        case this.buttonAlignmentCategories.LEFT:
          this.actionBtnAlignment = this.buttonAlignmentCategories.LEFT;
          break;

        case this.buttonAlignmentCategories.CENTER:
          this.actionBtnAlignment = this.buttonAlignmentCategories.CETNER;
          break;

        case this.buttonAlignmentCategories.RIGHT:
          this.actionBtnAlignment = this.buttonAlignmentCategories.RIGHT;
          break;

      }

    }

    nextStep(): void {

      this.infoPanelTrackedReturn.steps[this.panelStepIndex].step_completed = true;

      if(this.panelStepIndex < this.infoPanel.steps.length - 1) {

        this.panelStepIndex++;
        this.panelStep = this.infoPanel.steps[this.panelStepIndex];

        this.infoPanelTrackedReturn.steps[this.panelStepIndex].date_seen = new Date();
      }

    }

    previousStep(): void {

      if(this.panelStepIndex > 0) {
        this.panelStepIndex--;
        this.panelStep = this.infoPanel.steps[this.panelStepIndex];
      }

    }

    close(): void {
      this.infoPanelTrackedReturn.IP = this.IP;

      switch(this.infoPanel.category) {
        case this.infoPanelCategories.INFORMATIONAL:
          this.closeInformational();
          break;

        case this.infoPanelCategories.CONFIRM:
          this.closeConfirm();
          break;

        default:
          this.matDialogRef.close();
          break;

      }
    }

    private closeInformational(): void {
      this.matDialogRef.close(this.infoPanelTrackedReturn);
    }

    private closeConfirm(): void {
      this.matDialogRef.close(this.isConfirmed);
    }

  }