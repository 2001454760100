import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';

import { NavigatorService } from '../../../services/vendor/navigator.service';
import { InventoryItem, Profile, ScheduleSwap } from '../../../data-models/models';

export enum overviewActionEnum {
  CREATE_SHIFT_SWAP = 0,
  REVIEW_SHIFT_SWAPS = 1
}

@Component({
    selector: 'app-vendor-shift-swap-dialog.component',
    templateUrl: 'vendor-shift-swap-dialog.component.html',
    styleUrls: ['vendor-shift-swap-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorShiftSwapDialogComponent implements OnInit {
    @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
    public submitForm:FormGroup; 
    backgroundColor: string;
    borderColor: string;
    textColor: string;

    public overviewActions = overviewActionEnum;
    public activeOverviewAction: number = null;
    public scheduleSwap: ScheduleSwap;
    public requestStatusEnum: any;

    public swapRequests: any = [];

    constructor(
      private navigatorService: NavigatorService, 
      private fb: FormBuilder,
      public datePipe: DatePipe) {

    }
  
    ngOnInit() {
      this.requestStatusEnum = this.navigatorService.getShiftSwapRequestStatuses();

      this.submitForm = this.fb.group({
        basic: this.fb.group({
          title: [null, Validators.required],
          desc: null,
          priceDollar: null,
          unit: null,
          quantity: null,
          location: null,
          gallery: null
        })
      }); 

      this.navigatorService.getShiftSwapRequests().subscribe(data => { 
        // let result = this.filterData(data); 
        let requests: any = data;
        this.swapRequests = [];
  
        if(data != null) {
          for(let index = 0; index < requests.length; index++) {
            this.swapRequests.push(requests[index].data);
          }
        }
      });
    }
  
    submit() {
    }

    public reset(){
      this.horizontalStepper.reset(); 
      
      this.submitForm.reset({
        
      });   
    }

    public updateScheduleSwap(event: ScheduleSwap) {
      if(event) {
        this.scheduleSwap = event;
      }
    }

    public onSelectionChange(e:any){ 
      console.log("Forward");
      if(e.selectedIndex == 4){   
        this.horizontalStepper._steps.forEach(step => step.editable = false);
        console.log(this.submitForm.value);      
      }
    }

    public nextStep(): void {
      this.horizontalStepper.next();
    }

    public overviewNextStep(action: number): void {
      this.activeOverviewAction = action;
      this.horizontalStepper.next();
    }

    public submitRequest(): void {
      this.navigatorService.updateSwapRequest(this.scheduleSwap);
    }

    public decideRequest(requestIndex: number, decision: number): void {
      this.swapRequests[requestIndex].status = decision;

      this.navigatorService.updateSwapRequest(this.swapRequests[requestIndex]);
    }

    getContrast(hexcolor){

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
    
      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }
    
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
    
      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    
    };
  }