import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { NavigatorService, pageEnum } from 'src/app/services/vendor/navigator.service';
import { Page } from 'src/app/data-models/models';
import { PlatformService, PlatformViewStates } from 'src/app/services/vendor/platform.service';
import { BasePages } from '../menu';

@Component({
  selector: 'app-vendor-horizontal-menu',
  templateUrl: './vendor-horizontal-menu.component.html',
  styleUrls: ['./vendor-horizontal-menu.component.scss'],
  providers: [ MenuService ]
})
export class VendorHorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  public pages: any = this.navigator.getPages();

  public platformViewStates: any = PlatformViewStates;
  private viewState: number = this.platformService.getPlatformViewState();

  public isLoggedIn: boolean = true;

  public pageTypes: any = pageEnum;
  public basePages: any = BasePages;

  public internalTools: boolean = this.platformService.internalToolsChecker();
  public adminTools: boolean = false;

  public permissions: Array<Page> = [];

  constructor(
    private platformService: PlatformService, 
    public menuService: MenuService, 
    private navigator: NavigatorService) { }

  ngOnInit() {
    this.observeUserPermissions(); 
    this.platformService.refreshUserPermissions(); 

    // this.menuItems = this.menuService.getVendorHorizontalMenuItems();
    // this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId); 
  }

  private observeUserPermissions(): void {

    this.platformService.observeUserPermissions().subscribe( (permissions: Array<Page>) => {

      this.internalTools = this.platformService.internalToolsChecker();
      this.checkAdminAccess(permissions);

      if(permissions != undefined && permissions != null) {
        this.menuItems = this.menuService.getVendorHorizontalMenuItems();

        if(this.menuParentId == this.pageTypes.ADMIN) {
          
          if(!this.adminTools || this.viewState == this.platformViewStates.MOBILE) {
            let self = this;
            let adminIndex: number = this.menuItems.findIndex( (item: Menu) => { return item.id == self.pageTypes.ADMIN });

            if(adminIndex > -1) {
              this.menuItems.splice(adminIndex, 1);
            }
            
          } 

        }

        
      }


      this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId); 
    });

  }

  private checkAdminAccess(permissions: Array<Page>): void {

    let adminPermissions: Page = permissions.find( (page: Page) => { return page.id == this.pageTypes.ADMIN });

    if(adminPermissions != undefined && adminPermissions != null) {

      if(adminPermissions?.permission?.pageAccess) {
        this.adminTools = true;
      } else {
        this.adminTools = false;
      }

    }

  }

}
