import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigatorService, keyTypesEnum } from '../../../services/vendor/navigator.service';
import { InventoryItem, DialogNewWorkOrder, WorkOrder, Address, Profile } from '../../../data-models/models';
import { Subscription, Subject } from 'rxjs';

import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {takeUntil} from 'rxjs/operators';
import { TimePickerTypes } from '../vendor-work-audit-tracker/vendor-work-audit-tracker.component';


@Component({
  selector: 'vendor-add-work-order-dialog',
  templateUrl: 'vendor-add-work-order-dialog.component.html',
  styleUrls: ['vendor-add-work-order-dialog.component.scss']
})

export class VendorAddWorkOrderDialogComponent implements OnInit {
  @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
  public exampleHeader = ExampleHeader;

  public timePickerTypes: any = TimePickerTypes;
  public timePickerFocus: number = this.timePickerTypes.SCHEDULED;

  public submitForm: FormGroup; 
  public inventory: Array<InventoryItem> = []; 
  public currentDate: string; 
  public assignedId: string;
  public incomingAddress: Address;

  public assignedStaff: Array<string> = [];
  public assignedTeams: Array<string> = [];

  public emptyStart: any = null;
  public emptyEnd: any = null;

  public dateScheduled: Date;
  public endDateScheduled: Date;

  private autoMinuteAdjusted: boolean = false;
  public startTimeScheduled: any;
  public endTimeScheduled: any;
  public viewableDateScheduled: string;
  public isDateFieldsEnabled: boolean = false;
  public isWorkOrderCompleted: boolean = false;

  employeesSubscription: Subscription;

  @Input() technicians: Array<Profile> = [];
  @Input() parentWorkOrder: string = null;

  private keyTypes: any = keyTypesEnum;

  constructor(
    private navigatorService: NavigatorService, 
    private fb: FormBuilder, 
    private datePipe: DatePipe, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>
    ) {
    
  }

  ngOnInit() {
    if(this.technicians == null || this.technicians == undefined || this.technicians.length == 0) {
      this.subscribeToEmployees();
    }

    console.log("Incoming Add W/O Data: ", this.data);

    if(this.data && this.data.parentWorkOrder != null && this.data.parentWorkOrder != "") {
      console.log("Parent W/O: ", this.data.parentWorkOrder);
      this.parentWorkOrder = this.data.parentWorkOrder;

      this.isDateFieldsEnabled = true;
      this.dateScheduled = this.data.workOrder.date;
      // this.viewableDateScheduled = this.datePipe.transform(this.data.date, "yyyy-MM-dd");
      this.viewableDateScheduled = this.data.date;

      this.assignedId = this.data.workOrder.technicianId;

      if(this.data.address) {
        this.incomingAddress = this.data.workOrder.custAddress;
      } else {
        this.incomingAddress = {
          street: "",
          city: "",
          state: "",
          zip: null
        };
      }
    } 

    else if(this.data && this.data.date) {
      this.isDateFieldsEnabled = false;
      this.dateScheduled = this.data.date;
      // this.viewableDateScheduled = this.datePipe.transform(this.data.date, "yyyy-MM-dd");
      this.viewableDateScheduled = this.data.date;

      this.assignedId = this.data.assignedId;

      if(this.data.address) {
        this.incomingAddress = this.data.address;
      } else {
        this.incomingAddress = {
          street: "",
          city: "",
          state: "",
          zip: null
        };
      }
    } 
    
    else {
      this.isDateFieldsEnabled = true;

      this.incomingAddress = {
        street: "",
        city: "",
        state: "",
        zip: null
      };
    }

    if(this.data.deal == undefined) {
      this.data.deal = null;
    }

    if(this.assignedId != undefined && this.assignedId != null) {
      this.assignedStaff.push(this.assignedId);
    }

    if(this.dateScheduled != undefined) {
      let hours: number = this.dateScheduled.getHours();
      let minutes: number = this.dateScheduled.getMinutes();
      let ampm: string = "AM";
  
      if(hours >= 12) {
        ampm = "PM";
        hours -= 12;
      }
  
      this.startTimeScheduled = hours + ":" + minutes + " " + ampm;
      console.log("Start Time: ", this.startTimeScheduled);
    }
    
    console.log("Assigned ID: ", this.assignedId);
    
    this.matDialogRef.disableClose = true;//disable default close operation

    this.currentDate = this.datePipe.transform(new Date, "yyyy-MM-dd");

    this.submitForm = this.fb.group({

      workOrder: this.fb.group({
        originatorId: {value: this.navigatorService.getProfileId(), disabled: true},
        originationDate: {value: new Date(), disabled: true},
        assignedTech: {value: this.assignedId},
        workOrderNumber: {value: this.navigatorService.generateKey(this.keyTypes.WORK_ORDER_ID), disabled: true},
        dealId: {value: this.data.deal, disabled: false},
        dateScheduled: {value: this.viewableDateScheduled, disabled: false},
        timeScheduled: {value: this.startTimeScheduled, disabled: true},

        endDate: {value: null, disabled: false},
        timeStretch: {value: 0, disabled: false},
        desc: null,
        gallery: null
      }),

      customerInfo: this.fb.group({
        custFirstName: null,
        custLastName: null,
        custEmail: null,
        custPhone: null,


        custStreet: this.incomingAddress.street,
        custCity: this.incomingAddress.city,
        custState: this.incomingAddress.state,
        custZip: this.incomingAddress.zip,

        billingCustStreet: this.incomingAddress.street,
        billingCustCity: this.incomingAddress.city,
        billingCustState: this.incomingAddress.state,
        billingCustZip: this.incomingAddress.zip,
        
        priority: null,
        skillLvl: null,
        specialNotes: null
      }),

      priority: this.fb.group({
        priority: null,
        skillLvl: null,
        specialNotes: null
      })
    }); 

    if(this.data && this.data.parentWorkOrder != null && this.data.workOrder != null) {

      this.submitForm.get("customerInfo").get("custFirstName").setValue(this.data.workOrder.custFirstName);
      this.submitForm.get("customerInfo").get("custLastName").setValue(this.data.workOrder.custLastName);
      this.submitForm.get("customerInfo").get("custEmail").setValue(this.data.workOrder.custEmail);
      this.submitForm.get("customerInfo").get("custPhone").setValue(this.data.workOrder.custPhone);

      this.submitForm.get("customerInfo").get("custStreet").setValue(this.data.workOrder.custAddress.street);
      this.submitForm.get("customerInfo").get("custCity").setValue(this.data.workOrder.custAddress.city);
      this.submitForm.get("customerInfo").get("custState").setValue(this.data.workOrder.custAddress.state);
      this.submitForm.get("customerInfo").get("custZip").setValue(this.data.workOrder.custAddress.zip);

      this.submitForm.get("customerInfo").get("billingCustStreet").setValue(this.data.workOrder.custBillingAddress.street);
      this.submitForm.get("customerInfo").get("billingCustCity").setValue(this.data.workOrder.custBillingAddress.city);
      this.submitForm.get("customerInfo").get("billingCustState").setValue(this.data.workOrder.custBillingAddress.state);
      this.submitForm.get("customerInfo").get("billingCustZip").setValue(this.data.workOrder.custBillingAddress.zip);

      // this.submitForm.get("customerInfo").get("assignedTech").setValue(this.data.workOrder.technicianId);
    } 
  }

  ngAfterViewInit() {

    this.submitForm.get("workOrder").get("dateScheduled").setValue(this.dateScheduled);

  }

  ngDestroy() {
    if(this.employeesSubscription) {
      this.employeesSubscription.unsubscribe();
    }
  }

  subscribeToEmployees(): void {
    console.log("Subscribe to Employee");

    this.employeesSubscription = this.navigatorService.getAllEmployees().subscribe(data => { 
      // let result = this.filterData(data); 

      console.log("Returned Technicians", data);

      if(data != undefined) {
        this.technicians = data;
      }
    });
  }

  submit() {
    let dialogData: DialogNewWorkOrder = {
      isLoopBack: false,
      isCompleted: this.isWorkOrderCompleted,
      workOrderData: this.createWorkOrderObject()
    };

    console.log("Work Order Created: ", dialogData);

    this.matDialogRef.close(dialogData);
  }

  workOrderCompleted() {
    this.isWorkOrderCompleted = true;
  }

  close() {
    this.matDialogRef.close();
  }

  public reset() {
    this.horizontalStepper.reset(); 
    
    this.submitForm.reset({
      
    });   
  }

  public onSelectionChange(e:any) { 
    console.log("Forward");
    if(e.selectedIndex == 4){   
      this.horizontalStepper._steps.forEach(step => step.editable = false);
      console.log(this.submitForm.value);      
    }
  }

  getContrast(hexcolor) {

    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
  
    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
  
    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
  
    // Get YIQ ratio
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
    // Check contrast
    return (yiq >= 128) ? 'black' : 'white';
  
  }

  public assignedStaffUpdate(staff: Array<string>): void {
    this.assignedStaff = staff;
    console.log("Assigned Staff: ", staff);
  }

  public assignedTeamsUpdate(teams: Array<string>): void {
    this.assignedTeams = teams;
    console.log("Assigned Teams: ", teams);
  }

  public changeTimePicker(picker: number): void {

    if(this.timePickerFocus != picker) {
      this.timePickerFocus = picker;
    }

  }

  stringTimeToDate(time: string, date: Date): Date {

    if(
      date == undefined || 
      date == null) {
      return null;
    }

    if(
      time == undefined || 
      time == null) {

        time = "";

    }

    let reconstructedDate: Date = date;

    if(time.length > 0) {
      let parts, hours, minutes,
      timeReg = /(\d+)\:(\d+) (\w+)/;
      parts = time.match(timeReg);
      
      hours = /am/i.test(parts[3]) ?
          function(am) {return am < 12 ? am : 0}(parseInt(parts[1], 10)) :
          function(pm) {return pm < 12 ? pm + 12 : 12}(parseInt(parts[1], 10));
      
      minutes = parseInt(parts[2], 10);

      reconstructedDate.setHours(hours);
      reconstructedDate.setMinutes(minutes);
      
      console.log(time + ' => ' + reconstructedDate);
    }

    return reconstructedDate;
  }

  setStartTime(): void {

    let hours: number = this.dateScheduled.getHours();
    let minutes: number = this.dateScheduled.getMinutes();
    let ampm: string = "AM";

    if(hours > 12) {
      ampm = "PM";
      hours -= 12;
    }

    this.startTimeScheduled = hours + ":" + minutes + " " + ampm;
    console.log("Start Time: ", this.startTimeScheduled);

  }

  setEndTime(): void {

    let hours: number = this.endDateScheduled.getHours();
    let minutes: number = this.endDateScheduled.getMinutes();
    let ampm: string = "AM";

    if(hours > 12) {
      ampm = "PM";
      hours -= 12;
    }

    this.endTimeScheduled = hours + ":" + minutes + " " + ampm;
    console.log("End Time: ", this.endTimeScheduled);

  }

  updateScheduleDate(): void {

    if(this.submitForm.get("workOrder").get("dateScheduled").value != undefined && !this.autoMinuteAdjusted) {
      let startDate: Date = new Date(this.submitForm.get("workOrder").get("dateScheduled").value);

      this.dateScheduled = this.stringTimeToDate(this.startTimeScheduled, startDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  updateEndDate(): void {

    if(this.submitForm.get("workOrder").get("endDate").value != undefined && !this.autoMinuteAdjusted) {
      let endDate: Date = new Date(this.submitForm.get("workOrder").get("endDate").value.setHours(0, 0, 0, 0));

      this.endDateScheduled = this.stringTimeToDate(this.endTimeScheduled, endDate);
      this.updateMinutesRequired();
    } else {
      this.autoMinuteAdjusted = false;
    }

  }

  updateScheduleTime(scheduledTime): void {
    this.startTimeScheduled = scheduledTime;
    this.updateScheduleDate();

    console.log("Date Scheduled: ", this.dateScheduled);
  }

  updateEndTime(endTime): void {
    this.endTimeScheduled = endTime;
    this.updateEndDate();

    console.log("Date End: ", this.endTimeScheduled);
  }

  adjustMinutes(minutes: number) {
    const currentMinutes = this.submitForm.get("workOrder").get("timeStretch").value || 0;
    const newMinutes = currentMinutes + minutes;
    this.submitForm.get("workOrder").get("timeStretch").setValue(newMinutes >= 0 ? newMinutes : 0); // Prevent negative values
    this.minutesRequiredChange(newMinutes >= 0 ? newMinutes : 0); // Ensure positive value is passed to update function
  }
  
  minutesRequiredChange(minutes: number): void {
    
    this.submitForm.get("workOrder").get("timeStretch").setValue(minutes);
    console.log("Minutes Req. Update: ", minutes);

    this.autoUpdateEndDateByMinutes();

  }

  autoUpdateEndDateByMinutes(): void {
    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null) {
        this.dateScheduled = new Date();
    }

    if(
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        this.endDateScheduled = new Date();
    }


    let startMili: number = this.dateScheduled.getTime();
    let minutes: number = Number.parseInt(this.submitForm.get("workOrder").get("timeStretch").value);
    let minutesRegMili: number = (minutes * 1000) * 60;

    let diffMili: number = minutesRegMili + startMili;

    this.endDateScheduled.setTime(diffMili);

    console.log("Auto Date Start: ", this.dateScheduled); 
    console.log("Auto Date End: ", this.endDateScheduled);   

    this.autoMinuteAdjusted = true;
    this.submitForm.get("workOrder").get("dateScheduled").setValue(this.dateScheduled);

    this.autoMinuteAdjusted = true;
    this.submitForm.get("workOrder").get("endDate").setValue(this.endDateScheduled);

    this.setEndTime();
  }

  updateMinutesRequired(): void {

    if(
      this.dateScheduled == undefined || 
      this.dateScheduled == null || 
      this.endDateScheduled == undefined || 
      this.endDateScheduled == null) {
        return;
    }


    let startMili: number = this.dateScheduled.getTime();
    let endMili: number = this.endDateScheduled.getTime();

    let diffMili: number = endMili - startMili;

    let seconds: number = diffMili / 1000;
    let minutes: number = seconds / 60;

    this.submitForm.get("workOrder").get("timeStretch").setValue(minutes);

    console.log("Minute Diff: ", minutes);
  }

  createWorkOrderObject(): WorkOrder {

    let startDate: Date = this.stringTimeToDate(this.startTimeScheduled, this.submitForm.get("workOrder").get("dateScheduled").value);
    let endDate: Date = this.stringTimeToDate(this.endTimeScheduled, this.submitForm.get("workOrder").get("endDate").value);

    return {
      assignedStaff: this.assignedStaff,
      assignedTeams: this.assignedTeams,
      technicianId: this.submitForm.get("workOrder").get("assignedTech").value,
      originatorId: this.submitForm.get("workOrder").get("originatorId").value,
      workOrderId: this.submitForm.get("workOrder").get("workOrderNumber").value,
      dealId: this.submitForm.get("workOrder").get("dealId").value,
      companyId: this.navigatorService.getCompanyId(),

      originationDate: new Date(),
      timeStretch: Number.parseInt(this.submitForm.get("workOrder").get("timeStretch").value),
      custPocName: "",
      custPocPhone: null,
      custPocEmail: "",
      custFirstName: this.submitForm.get("customerInfo").get("custFirstName").value,
      custMiddleName: '',
      custLastName: this.submitForm.get("customerInfo").get("custLastName").value,
      custEmail: this.submitForm.get("customerInfo").get("custEmail").value,
      custPhone: this.submitForm.get("customerInfo").get("custPhone").value,

      custAddress: {
        street: this.submitForm.get("customerInfo").get("custStreet").value,
        city: this.submitForm.get("customerInfo").get("custCity").value,
        state: this.submitForm.get("customerInfo").get("custState").value,
        zip: this.submitForm.get("customerInfo").get("custZip").value
      },

      custBillingAddress: {
        street: this.submitForm.get("customerInfo").get("billingCustStreet").value,
        city: this.submitForm.get("customerInfo").get("billingCustCity").value,
        state: this.submitForm.get("customerInfo").get("billingCustState").value,
        zip: this.submitForm.get("customerInfo").get("billingCustZip").value
      },
      custBillAmount: 0,
      description: this.submitForm.get("workOrder").get("desc").value,
      dateContactLog: [
        new Date
      ],
      dateInitialContact: new Date,
      dateScheduled: startDate,
      endDate: endDate,
      priority: this.submitForm.get("priority").get("priority").value,
      skillLvl: this.submitForm.get("priority").get("skillLvl").value,
      specialNotes: this.submitForm.get("priority").get("specialNotes").value,
      status: 1,
      parent_work_order: this.parentWorkOrder,
      foreign_work_orders: [],
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: []
    }
  }
}


/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  styles: [`
    .calendar-header {
      display: inline-flex;
      width: 80%;
      align-items: center;
      padding: 0.5em;
    }

    #timeContainer {
      display: inline-flex;
      width: 20%;
      justify-content: center;
    }

    .example-header-label {
      margin-top: -.8em;
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }

    .double-arrow {
      margin-left: -.6em
    }
  `],
  template: `
    <div class="calendar-header">
      <div role="button" class="example-double-arrow" (click)="previousClicked('year')">
        <span class="material-icons d-inline">chevron_left</span>
        <span class="material-icons d-inline double-arrow">chevron_left</span>
      </div>
      <div role="button" mat-icon-button (click)="previousClicked('month')">
        <span class="material-icons d-inline">chevron_left</span>
      </div>
      <span class="example-header-label">{{periodLabel}}</span>
      <div role="button" mat-icon-button (click)="nextClicked('month')">
        <span class="material-icons d-inline">chevron_right</span>
      </div>
      <div role="button" mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
        <span class="material-icons d-inline">chevron_right</span>
        <span class="material-icons d-inline double-arrow">chevron_right</span>
      </div>
    </div>

    <div role="button" id="timeContainer" (click)="openTimeClock()">
      <span class="material-icons">schedule</span>
    </div>                              
  `,
})

export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  public isTimeClockOpen: boolean = false;
  private timeElement: any = null;

  constructor(
      private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());

        this.timeElement = document.querySelector(".activeTimePicker");
        
        console.log("Calendar Element: ", this._calendar);
        console.log("Date Adapter Element: ", this._dateAdapter);
        console.log("Time Element: ", this.timeElement);
  }

  ngAfterViewInit() { 
    let test = document.querySelector(".calendar-header");

    console.log("Calendar Input Element: ", test);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
        .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  openTimeClock(): void {
    this.eventFire(this.timeElement, 'click');
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}