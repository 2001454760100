import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

@Component({
  selector: 'app-day-night-switch',
  templateUrl: './day-night-switch.component.html',
  styleUrls: ['./day-night-switch.component.scss']
})
export class DayNightSwitchComponent implements OnInit {

  @Output() emitToggleSwitch = new EventEmitter<boolean>();
  @Input() switch: number = 0;

  constructor(public snackBar: MatSnackBar, public navigatorService: NavigatorService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }  

  toggle(): void {
    this.emitToggleSwitch.emit();
  }
}