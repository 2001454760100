import { PagedHistoryChatAdapter } from '../ng-chat/core/paged-history-chat-adapter';
import { ChatAdapter } from '../ng-chat/core/chat-adapter';
import { IChatGroupAdapter } from '../ng-chat/core/chat-group-adapter';
import { User } from "../ng-chat/core/user";
import { ParticipantResponse } from "../ng-chat/core/participant-response";
import { Message } from "../ng-chat/core/message";
import { MessageType } from "../ng-chat/core/message-type.enum";
import { Group } from "../ng-chat/core/group";
import { ChatParticipantType } from "../ng-chat/core/chat-participant-type.enum";
import { IChatParticipant } from "../ng-chat/core/chat-participant";
import { ChatParticipantStatus } from "../ng-chat/core/chat-participant-status.enum";


import { Observable, of } from 'rxjs';
import { MessagingAdapter } from './messaging-adapter';
import { delay } from "rxjs/operators";
import { HeartBeatService } from 'src/app/services/vendor/heartbeat.service';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { InstantMessage } from 'src/app/data-models/models';

export class AdapterPagedHistory extends PagedHistoryChatAdapter implements IChatGroupAdapter
{
    private historyMessages: Message[] = [];
    private pageNumber: number = 0;
    private limit: number = 50;
    
    constructor(private navigatorService: NavigatorService, private heartBeatService: HeartBeatService) {
        super();
        // for(let i: number = 0; i < 20; i++) {
        //     let msg = {
        //         fromId: 1,
        //         toId: 999,
        //         message: `${20-i}. Hi there, just type any message below to test this Angular module.`,
        //         dateSent: new Date()
        //     };
            
        //     this.historyMessages.push(msg);
        // }
    }

    listFriends(): Observable<ParticipantResponse[]> {
        let userId: string = this.navigatorService.getProfileId();

        if(userId != undefined && userId != null && userId.length > 0) {
            // console.log("Participants: ", MessagingAdapter.participants);

            return of(MessagingAdapter.participants.map(user => {
                let participantResponse = new ParticipantResponse();

                participantResponse.participant = user;
                participantResponse.metadata = {
                    // totalUnreadMessages: 5 // Demo history page size
                    totalUnreadMessages: 0
                }

                return participantResponse; 
            })); 
        }

        return null;
    }

    getMessageHistory(destinataryId: any): Observable<Message[]> {
       let mockedHistory: Array<Message>;
       mockedHistory = [
            {
                fromId: 1,
                toId: 999,
                message: "Hi there, just type any message below to test this Angular module.",
                dateSent: new Date()
            }
       ];

       return of(mockedHistory);
    }
    
    public async getMessageHistoryByPage(destinataryId: any, limit: number, page: number) : Promise<Observable<Message[]>> {
        console.log("Getting Paged Message History: ", destinataryId);
        let messageHistory: Array<Message> = [];

        await this.heartBeatService.getAsyncPagedInstantMessages(destinataryId, this.pageNumber, this.limit).then( (messages: Array<Message>) => {
            if(messages != null) {
                messageHistory = messages;
            }
        });

        console.log("Filtered Paged Message History: ", messageHistory);
        return of(messageHistory);
    }
    
    
    sendMessage(message: Message): void {
        
        // Group Messaging
        if (message.toId == null || message.toId == undefined)
        {
            let group = MessagingAdapter.participants.find(x => x.id == message.toId) as Group;

            // Message to a group. Pick up any participant for this
            let randomParticipantIndex = Math.floor(Math.random() * group.chattingTo.length);
            // replyMessage.fromId = group.chattingTo[randomParticipantIndex].id;

            // replyMessage.toId = message.toId;

            // this.onMessageReceived(group, replyMessage);
        }
        else
        {
            this.heartBeatService.updateInstantMessaging(message);
            console.log("Instant Message: ", message);
        }

    }

    groupCreated(group: Group): void {
        MessagingAdapter.participants.push(group);

        MessagingAdapter.participants = MessagingAdapter.participants.sort((first, second) => 
            second.displayName > first.displayName ? -1 : 1
        );

        // Trigger update of friends list
        this.listFriends().subscribe(response => {
            this.onFriendsListChanged(response);
        });
    }   
}