import {Component, OnInit, ViewChild, AfterViewInit, Input, Output  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigatorService, keyTypesEnum } from '../../../services/vendor/navigator.service';
import { CMMSLocation, DialogNewInventory, InventoryItem, Profile } from '../../../data-models/models';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';


@Component({
    selector: 'vendor-add-inventory-item',
    templateUrl: 'vendor-add-inventory-item-dialog.component.html',
    styleUrls: ['vendor-add-inventory-item-dialog.component.scss']
  })

  export class VendorAddInventoryItemDialogComponent implements OnInit {
    @ViewChild('horizontalStepper') horizontalStepper: MatStepper; 
    public submitForm:FormGroup; 
    backgroundColor: string;
    borderColor: string;
    textColor: string;

    public isInventoryItemCompleted: boolean = false;
    public inventory: Array<InventoryItem> = [];
    public isInitialAdd: boolean = true;
    private keyTypes: any = keyTypesEnum;

    public located_at: CMMSLocation | string;
    public locations: Array<CMMSLocation> = [];

    constructor(
      private navigatorService: NavigatorService, 
      private imageHandlerService: ImageHandlerService,
      private fb: FormBuilder,
      public matDialogRef: MatDialogRef<VendorAddInventoryItemDialogComponent>) {

    }
  
    ngOnInit() {
      this.getCMMSLocations();

      this.submitForm = this.fb.group({
        basic: this.fb.group({
          originatorId: [ this.navigatorService.getProfileId(), Validators.required ],
          originationDate: [ new Date, Validators.required ],
          title: [ null, Validators.required ],
          desc: "",
          price: [ null, Validators.required ],
          unit: [ null, Validators.required ],
          quantity: [ null, Validators.required ],
          located_at: [ null, Validators.required ],
          area: "",
          gallery: [[]]
        }),

        details: this.fb.group({
          id: [ { value: this.navigatorService.generateKey(this.keyTypes.INVENTORY_ID), disabled: true }, Validators.required ],
          barcode: [ null, Validators.required ],
          serial_number: "",
          model: ""
        })
      }); 

      // this.inventory = this.navigatorService.getLocalTrackedInventory();

      // USE FOR PRODUCTION
      // this.navigatorService.getLocalTrackedFields().subscribe(data => { 
      //   if(data) {
      //     this.inventory = data; 
      //   }
      // });

      this.matDialogRef.disableClose = true; //disable default close operation
    }

    public getCMMSLocations() {   
      this.navigatorService.getAsyncAllCMMSLocations().then(async locations => { 
  
        if(locations) {
          console.log("Ingested CMMS Locations: ", locations);
          this.locations = locations;

          let employees: Array<Profile> = await this.navigatorService.getAsyncAllEmployees();

          employees.forEach(employee => {
        
            this.locations.push({
              originId: employee?.id,
              originDate: new Date(),
              id: employee?.id,
              isDisplayed: true,
              title: employee?.first_name + " " + employee?.last_name,
              desc: '',
              address: {
                  street: employee?.address?.street,
                  city: employee?.address?.city,
                  state: employee?.address?.state,
                  zip: employee?.address?.zip
              },
              images: null,
              type: null
            });
    
          });

        }
  
      })
    }

    // Old data type was string. There currently isn't a clean platform update setup so two data types are allowed.
    // This requires adjustment.
    translateLocation(locatedAt: CMMSLocation | string): string {
      let locationTitle: string = "";

      if(typeof locatedAt === 'string') {
        return locationTitle;
      } else {
        locationTitle = locatedAt.title;
      }

      return locationTitle
    }

    locationSelectionCompare(o1: any, o2: any) {
      return (o1.id == o2.id);
    }
  
    submit() {
      if(this.isInventoryItemCompleted) {
        let dialogData: DialogNewInventory = {
          isLoopBack: false,
          isCompleted: this.isInventoryItemCompleted,
          gallery: this.submitForm.get("basic")['controls'].gallery.value,
          // workOrderData: this.createWorkOrderObject()
          inventoryData: {
              originatorId: this.submitForm.get("basic")['controls'].originatorId.value,
              originationDate: this.submitForm.get("basic")['controls'].originationDate.value,
              companyId: this.navigatorService.getCompanyId(),
              custom_fields: [],
              
              lockedFields: {
                id: this.submitForm.get("details")['controls'].id.value,
                title: this.submitForm.get("basic")['controls'].title.value,
                barcode: this.submitForm.get("details")['controls'].barcode.value,
                price: this.submitForm.get("basic")['controls'].price.value,
                unit: this.submitForm.get("basic")['controls'].unit.value,
                desc: this.submitForm.get("basic")['controls'].desc.value,
                located_at: this.submitForm.get("basic")['controls'].located_at.value,
                area: this.submitForm.get("basic")['controls'].area.value,
                gallery: [],
                dateLastChanged: new Date,
                model: this.submitForm.get("details")['controls'].model.value,
                serial_number: this.submitForm.get("details")['controls'].serial_number.value,
                serial_number_tracking: false,
                isMetered: false,
                operational_status: null,
                availability: true,
                reservation_system: false,
                work_orders: [],
              }

              // basic: this.fb.group({
              //   title: [ null, Validators.required ],
              //   desc: null,
              //   price: null,
              //   unit: null,
              //   quantity: [ null, Validators.required ],
              //   located_at: null,
              //   gallery: null
              // }),
      
              // details: this.fb.group({
              //   id: [ null, Validators.required ],
              //   barcode: [ null, Validators.required ],
              //   serial_number: null,
              //   model: null
              // })
          }
        };

        console.log("New Inventory Item: ", dialogData);
        this.matDialogRef.close(dialogData);
      } else {
        this.close();
      }
    }

    inventoryCompleted() {
      this.isInventoryItemCompleted = true;
    }

    close() {
      this.matDialogRef.close();
    }

    public reset() {
      this.horizontalStepper.reset(); 
      
      this.submitForm.reset({
        
      });   
    }

    public onSelectionChange(e:any) { 
      console.log("Forward");
      if(e.selectedIndex == 4){   
        this.horizontalStepper._steps.forEach(step => step.editable = false);
        console.log(this.submitForm.value);      
      }
    }

    public generateBarcode(): void {
      let barcode: string = this.navigatorService.generateKey(this.keyTypes.BARCODE_UPC_A);
      this.submitForm.get("details").get("barcode").setValue(barcode);
      console.log("New Barcode: " + barcode);
    }

    getContrast(hexcolor) {

      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
    
      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }
    
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
    
      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    
    };
  }