import { Component, OnInit, Input, SimpleChanges, ViewChild, OnChanges } from '@angular/core';

import { Observable, Subscription } from 'rxjs'; 
import SignaturePad from 'signature_pad/src/signature_pad'
import { DatePipe } from '@angular/common';

import { WorkOrder, CompanyProfile, WorkOrderService, InvoiceSettings, PaymentSettings, StripePaymentDetails, StripePaymentLineItems, CRMDeal, Revenue, RevInvoice } from '../../data-models/models';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { emailValidator, matchingPasswords } from 'src/app/theme/utils/app-validators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { settings } from 'cluster';

import { keyTypesEnum, NavigatorService } from '../../services/vendor/navigator.service';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { stat } from 'fs';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Product {
  name: string;
  price: number;
  qty: number;

  constructor() {

  }
}

class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;
  
  services: WorkOrderService[] = [];
  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show 
    // this.products.push(new Product());
  }
}

@Component({
  selector: 'app-local-invoice-creator',
  templateUrl: './local-invoice-creator.component.html',
  styleUrls: ['./local-invoice-creator.component.scss'] 
})

export class LocalInvoiceCreatorComponent implements OnInit, OnChanges {

  @ViewChild('signatureCanvas') signatureCanvas;
  @Input() focusedWorkOrder: WorkOrder;
  @Input() focusedDeal: CRMDeal;
  @Input() invoiceSettings: InvoiceSettings;

  private keyTypes:any = keyTypesEnum;
  private companyProfileSubscription: Subscription;
  
  signaturePad: any;
  invoice = new Invoice(); 

  public isStripeChargesEnabled: boolean = false;
  public stripeAccountID: string = null;

  private imgSubscription: Subscription;
  public imgId: string = null;
  public image: any;
  public imageDeleteAction: boolean = false;

  public companyProfile: CompanyProfile = null;
  public infoForm: FormGroup;
  public isSignatureLocked: boolean = false;
  public totalPrice: number = 0;

  constructor(
    private navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService, 
    private paymentHandler: PaymentHandlerService,
    public formBuilder: FormBuilder, 
    public snackBar: MatSnackBar, 
    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.getStripeAccount();
    this.getCompanyProfile();
    this.imgSubscriber();

    this.infoForm = this.formBuilder.group({
      first_name: [ '', Validators.compose([Validators.required, Validators.minLength(2)]) ],
      last_name: [ '', Validators.compose([Validators.required, Validators.minLength(2)]) ],
      organization: [ ''],
      email: [ '', Validators.compose([Validators.required, emailValidator]) ],
      phone: [ '', Validators.required],

      street: [ '', Validators.compose([Validators.required, Validators.minLength(3)]) ],
      city: [ '', Validators.compose([Validators.required, Validators.minLength(3)]) ],
      state: [ '', Validators.compose([Validators.required, Validators.minLength(2)]) ],
      zip: [ '', Validators.compose([Validators.required, Validators.minLength(5)]) ],

      dueDate: [ { value: new Date(), disabled: true }, Validators.compose([Validators.required]) ]
    });

    this.invoice.additionalDetails = "";
    this.invoice.products = [];
    this.invoice.services = [];
    this.clearSignaturePad();
  }

  ngAfterViewInit(): void {
    this.initializeSigniaturePad();
    this.initializeForm();
  }

  ngOnDestroy() {
        
    if(this.companyProfileSubscription) {
      this.companyProfileSubscription.unsubscribe();
    }

    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Work Order: " + changes);
    
    this.initializeForm();
  } 

  initializeForm(): void {
    this.isSignatureLocked = false;

    this.invoice.products = [];
    this.invoice.services = [];

    if(this.focusedWorkOrder != null && this.focusedWorkOrder != undefined) {

      // Services
      if(this.focusedWorkOrder?.budget?.breakdown?.labor?.lineItems != null && this.focusedWorkOrder?.budget?.breakdown?.labor?.lineItems != undefined) {

        this.focusedWorkOrder?.budget?.breakdown?.labor?.lineItems.forEach(service => {
          this.invoice.services.push({
            desc: service?.desc,
            price: service?.price,
            chargePrice: service?.chargePrice,
            hrs: service?.hrs
          });
        });
      }

      // Travel
      if(this.focusedWorkOrder?.budget?.breakdown?.travel?.lineItems != null && this.focusedWorkOrder?.budget?.breakdown?.travel?.lineItems != undefined) {

        this.focusedWorkOrder?.budget?.breakdown?.travel?.lineItems.forEach(service => {
          this.invoice.services.push({
            desc: service?.desc,
            price: service?.price,
            chargePrice: service?.chargePrice,
            hrs: service?.hrs
          });
        });
      }

      // Products
      if(this.focusedWorkOrder.attachedParts != null && this.focusedWorkOrder.attachedParts != undefined) {

        for(let attachedPart of this.focusedWorkOrder.attachedParts) {

          this.navigatorService.getAsyncSelectInventory(attachedPart.partBarcode).then(inventoryItem => {

            if(inventoryItem != null && inventoryItem != undefined) {
              this.invoice.products.push({
                name: inventoryItem[0].lockedFields.title,
                price: inventoryItem[0].lockedFields.price,
                qty: attachedPart.quantity
              });
            }

          });
          
        }
      }

      if(this.focusedWorkOrder.signature != null && this.focusedWorkOrder.signature != undefined) {
        this.drawSignaturePadData(this.focusedWorkOrder.signature);
      } else {
        this.clearSignaturePad();
      }

      if(this.focusedWorkOrder.specialNotes != null && this.focusedWorkOrder.specialNotes != undefined) {
        this.invoice.additionalDetails = this.focusedWorkOrder.specialNotes;
      }

      
      
      // if(changes.workOrders != undefined && changes.workOrders.currentValue != undefined) {

        // this.workOrders = []
        // this.workOrders = changes.workOrders.currentValue;
        // this.dataSource.data = this.workOrders;

      // } 

      this.updateInvoiceForm();
    }
  }

  getCompanyProfile(): void {
    this.navigatorService.getAsyncCompanyProfile().then(data => { 

      if(data != null) {
        this.companyProfile = data;

        this.imgId = data.image;
        this.image = null;
        this.getImg();
      } 

    });
  }

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {

      if(img) {
        this.image = img;

        console.log("Image: ", this.image);
      }

    });
    
  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getFile(this.imgId);
    }
  }

  public uploadImage(image: any): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
          this.imgId = imgIdUnique;
          this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
  }

  public deleteImg(): void {
    this.infoForm.controls["image"].setValue("");
    this.image = null;
    this.imageDeleteAction = true;
  }

  getBase64Image(img) {
    let imgElement = document.createElement("img");
    imgElement.src = img;

    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(imgElement, 0, 0);

    let dataURL = canvas.toDataURL("image/jpg");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  getPDFChargeArea(): any {
    let subtotal: number = Number( 
      this.invoice?.products.reduce((sum, p)=> sum + (p?.qty * p?.price), 0) ) 
      + 
      Number( this.invoice?.services.reduce((sum, service)=> sum 
        + 
        (service?.hrs * service?.chargePrice), 0) );

    let tax: number = subtotal * ( (this.invoiceSettings?.tax_rate ?? 0) / 100);

    let headerComplete: any = [];
    let valuesComplete: any = [];

    let headers = [];
    let values = [];
    let value: number = 0;
    let total: number = 0;

    headers.push({ text: "Subtotal", listType: 'none', margin: [2, 2] });
    values.push({ text: Number( subtotal ).toFixed(2), listType: 'none', margin: [2, 2] });

    total += subtotal;
    
    for(let chargeField of (this.invoiceSettings?.custom_charge_fields ?? []) ) {

      // Percentage Based
      if(chargeField?.type == 1) {
        value = subtotal * ( Number(chargeField?.value) / 100 );
      } else {
        // Fixed Based
        value = Number(chargeField?.value);
      }

      headers.push({ text: chargeField?.title, listType: 'none', margin: [2, 2] });
      // values.push({ text: Number(parseFloat(value + 'e' + 2) + 'e-' + 2).toFixed(2) , listType: 'none', margin: [2, 2] });

      values.push({ text: Number( value ).toFixed(2), listType: 'none', margin: [2, 2] });

      total += value;
    }

    headers.push({ text: "Tax", listType: 'none', margin: [2, 2] });
    values.push({ text: Number( tax ?? 0.00 ).toFixed(2) , listType: 'none', margin: [2, 2] });

    total += tax;

    headers.push({ text: "Total", listType: 'none', margin: [2, 7], bold: true });
    values.push({ text: Number( total ?? 0.00 ).toFixed(2), listType: 'none', margin: [2, 7], bold: true });

    headerComplete.push(
    [
      {
        alignment: 'left',
        width: 70,
        fontSize: 12,
        ol: headers
      }
    ]);

    valuesComplete.push(
    [
      {
        alignment: 'right',
        width: "auto",
        fontSize: 12,
        ol: values
      }
    ]);


    this.totalPrice = total;

    return {
      headers: headerComplete,
      values: valuesComplete
    }
  }

  getPDFQRCode(): any {
    let qr_code = {};

    if(this.invoiceSettings?.display_qr_code) {
      qr_code = {
        // qr: `First Name: ${this.infoForm.controls["first_name"].value}`, 
        qr: `${this.focusedWorkOrder?.workOrderId}`, 
        fit: '60',
        eccLevel: "H",
        alignment: 'right'
        // width: '1%'
      }
    }

    return qr_code;
  }

  getPDFCustomerInfoArea(): any {
    let customerArea = [];

    customerArea.push( 
      [
        {
          text: this.infoForm.controls["first_name"].value + " " + this.infoForm.controls["last_name"].value,
          margin: [2, 2],
          bold:true,
          alignment: 'justify'
        },
        { 
          text: this.infoForm.controls["street"].value,
          margin: [2, 2],
          alignment: 'justify' 
        },
        { 
          text: this.infoForm.controls["city"].value + ", " + this.infoForm.controls["state"].value + " " + this.infoForm.controls["zip"].value,
          margin: [2, 2],
          alignment: 'justify' 
        },
        { 
          text: " ",
          margin: [2, 2]
        },
        { 
          text: this.infoForm.controls["email"].value,
          margin: [2, 2],
          alignment: 'justify' 
        },
        { 
          text: this.infoForm.controls["phone"].value,
          margin: [2, 2],
          alignment: 'justify'
        }
      ]
    );

    if(!this.invoiceSettings?.display_overview_detail) {
      // Blank Column Used For Spacing
      customerArea.push( [] );
    }

    customerArea.push( 
      [
        {
          text: this.companyProfile?.company_name,
          margin: [2, 2],
          bold:true,
          alignment: 'justify'
        },
        { 
          text: this.companyProfile?.address?.street,
          margin: [2, 2],
          alignment: 'justify' 
        },
        { 
          text: this.companyProfile?.address?.city + ", " + this.companyProfile?.address?.state + " " + this.companyProfile?.address?.zip,
          margin: [2, 2],
          alignment: 'justify' 
        },
        { 
          text: " ",
          margin: [2, 2]
        },
        { 
          text: this.companyProfile?.email,
          margin: [2, 2],
          alignment: 'justify'
        },
        { 
          text: this.companyProfile?.phone,
          margin: [2, 2],
          alignment: 'justify'
        }

        // {
        //   text: `Date: ${new Date().toLocaleString()}`,
        //   alignment: 'right'
        // },
        // { 
        //   text: `Bill No : ${((Math.random() *1000).toFixed(0))}`,
        //   alignment: 'right'
        // }
      ]
    );

    if(this.invoiceSettings?.display_overview_detail) {
      // Blank Column Used For Spacing
      customerArea.push( 
        [
          // margin: [left, top, right, bottom]
          {
            text: "Invoice Details",
            margin: [40, 2, 2, 2],
            bold:true,
            alignment: 'left'
          },
          { 
            text: "Invoice # " + this.focusedWorkOrder?.workOrderId,
            margin: [40, 2, 2, 2],
            alignment: 'left' 
          },
          { 
            text: "Work Date: " + this.datePipe?.transform(this.focusedWorkOrder?.dateScheduled),
            margin: [40, 2, 2, 2],
            alignment: 'left' 
          },
          { 
            text: "Due: " + this.datePipe?.transform(this.infoForm.controls["dueDate"].value),
            margin: [40, 2, 2, 2],
            alignment: 'justify'
          }
  
          // {
          //   text: `Date: ${new Date().toLocaleString()}`,
          //   alignment: 'right'
          // },
          // { 
          //   text: `Bill No : ${((Math.random() *1000).toFixed(0))}`,
          //   alignment: 'right'
          // }
        ]
      );
    }

    return customerArea;
  }
  
  generatePDF(action = 'open') {
    // display_tax_rate: boolean,
    // display_qr_code: boolean,
    // display_logo: boolean,
    // display_signature: boolean,

    let qr_code = this.getPDFQRCode();
    // let logo = [];
    let signature = [];
    let customerInfoArea = this.getPDFCustomerInfoArea();
    let chargeArea: any = this.getPDFChargeArea();
    let docDefinition = null;
    

    docDefinition = {
      images: {
        logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=",
        signature: ""
      },

      content: [
        {
          columns: [
            {
              // if you specify both width and height - image will be stretched
              image: 'logo',
              fit: [50, 50]
            },
            {
              text: this.companyProfile?.company_name,
              fontSize: 28,
              alignment: 'center',
              color: '#047886'
              // width: '100%'
            },
            qr_code
          ]
        },
        // {
        //   text: 'INVOICE',
        //   fontSize: 20,
        //   bold: true,
        //   alignment: 'center',
        //   decoration: 'underline',
        //   color: '#000000'
        // },
        {
          text: 'Customer Details',
          style: 'sectionHeader'
        },
        {
          columns: 
            customerInfoArea
          
        },
        {
          text: 'Order Details',
          style: 'sectionHeader'
        },

        {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Service', 'Price', 'Hours', 'Amount'],
              ...this.invoice?.services.map(service => (
                [
                  { text: service?.desc, color: '#8F8F8F'}, 
                  { text: service?.chargePrice, color: '#8F8F8F'}, 
                  { text: service?.hrs, color: '#8F8F8F'}, 
                  { text: (service?.chargePrice * service?.hrs).toFixed(2), color: '#8F8F8F'}
                ])),
              [
                { text: 'Total Amount', colSpan: 3, color: '#5C5C5C' }, 
                {}, 
                {}, 
                {text: this.invoice?.services?.reduce((sum, service)=> sum + (service?.hrs * service?.chargePrice), 0).toFixed(2), color: '#5C5C5C' }
              ]
            ]
          }
        },

        // {
        //   text: 'Parts ',
        //   style: 'sectionHeader'
        // },
        { 
          text: " "
        },
        { 
          text: " "
        },
        {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Parts', 'Price', 'Quantity', 'Amount'],
              ...this.invoice.products.map(p => (
                [
                  { text: p?.name, color: '#8F8F8F'}, 
                  { text: p?.price, color: '#8F8F8F'}, 
                  { text: p?.qty, color: '#8F8F8F'}, 
                  { text: (p?.price * p?.qty).toFixed(2), color: '#8F8F8F'}
              ])),
              [
                { text: 'Total Amount', colSpan: 3, color: '#5C5C5C' }, 
                {}, 
                {}, 
                {text: this.invoice?.products.reduce((sum, p) => sum + (p?.qty * p?.price), 0).toFixed(2), color: '#5C5C5C' }
              ]
            ]
          }
        },
        { 
          text: " "
        },
        {
          columns: [
            [ ],
            [
              {
                columns: [
                  chargeArea?.headers,
                  chargeArea?.values
                ]
                
              }
            ]
          ]
        },
        { 
          text: " "
        },


        {
          columns: [
            [
              {
                text: 'Additional Details',
                style: 'sectionHeader'
              },
              {
                  text: this.invoice?.additionalDetails,
                  margin: [0, 0 ,0, 15]          
              },
            ],
          [
            {
              columns: [
                // [{ qr: `First Name: ${this.infoForm.controls["first_name"].value}`, fit: '50' }],
                // [{ text: 'Signature', alignment: 'right', italics: true}],
                [
                  {
                    alignment: 'right',
                    margin: [0, 50],
                    // if you specify both width and height - image will be stretched
                    image: 'signature',
                    width: 250
                  },
                  {
                    alignment: 'center',
                    text: this.infoForm.controls["first_name"].value + " " + this.infoForm.controls["last_name"].value,
                    margin: [5, -50]          
                  }
                ]
              ]
            }
          ]

        ]
      },



        {
          text: 'Terms and Conditions',
          style: 'sectionHeader'
        },
        {
            // ul: [
            //   'Order can be return in max 10 days.',
            //   'Warranty of the product will be subject to the manufacturer terms and conditions.'
            // ],
            text: this.invoiceSettings?.terms_conditions
        }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15,0, 15]          
        }
      }
    };


    if(action==='download') {
      if(this.infoForm.valid) {
        if(this.image != null && this.image != undefined && this.image?.changingThisBreaksApplicationSecurity != null && this.image?.changingThisBreaksApplicationSecurity != undefined) {
          this.getBase64FromURL(this.image?.changingThisBreaksApplicationSecurity).then( base64Logo => {
            if(base64Logo != null && base64Logo != undefined) {
              docDefinition.images.logo = base64Logo;
            }

            docDefinition.images.signature = this.getPNGBase64Signature();

            pdfMake.createPdf(docDefinition).download();
          });
        }
      } else {
        docDefinition.images.signature = this.getPNGBase64Signature();
        pdfMake.createPdf(docDefinition).download();
      }
    }

    else if(action==='publish') {
      if(this.infoForm.valid) {
        if(this.image != null && this.image != undefined && this.image?.changingThisBreaksApplicationSecurity != null && this.image?.changingThisBreaksApplicationSecurity != undefined) {
          this.getBase64FromURL(this.image?.changingThisBreaksApplicationSecurity).then( base64Logo => {
            if(base64Logo != null && base64Logo != undefined) {
              docDefinition.images.logo = base64Logo;
            }

            docDefinition.images.signature = this.getPNGBase64Signature();

            this.publishDirectInvoice(docDefinition);
          });
        }
      } else {
        this.snackBar.open('Invoice Form has missing or empty fields!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }
    }
    
    else if(action === 'print') {
      if(this.infoForm.valid) {
        if(this.image != null && this.image != undefined && this.image?.changingThisBreaksApplicationSecurity != null && this.image?.changingThisBreaksApplicationSecurity != undefined) {
          this.getBase64FromURL(this.image?.changingThisBreaksApplicationSecurity).then( base64Logo => {
            if(base64Logo != null && base64Logo != undefined) {
              docDefinition.images.logo = base64Logo;
            }

            docDefinition.images.signature = this.getPNGBase64Signature();

            pdfMake.createPdf(docDefinition).print();  
          });    
        }
      } 
      
      else {
        docDefinition.images.signature = this.getPNGBase64Signature();
        pdfMake.createPdf(docDefinition).print();
      }
    }
    
    else{
      if(this.infoForm.valid) {
        if(this.image != null && this.image != undefined && this.image?.changingThisBreaksApplicationSecurity != null && this.image?.changingThisBreaksApplicationSecurity != undefined) {
          this.getBase64FromURL(this.image?.changingThisBreaksApplicationSecurity).then( base64Logo => {
            if(base64Logo != null && base64Logo != undefined) {
              docDefinition.images.logo = base64Logo;
            }

            docDefinition.images.signature = this.getPNGBase64Signature();

            pdfMake.createPdf(docDefinition).open();   
          });
        } else {
          docDefinition.images.signature = this.getPNGBase64Signature();
          pdfMake.createPdf(docDefinition).open();
        }
      }   
    }

  }

  async getBase64FromURL(url: string) { 
    let base64 = null;

    await fetch(url)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(async blob => {

        await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
              base64 = reader.result;   
              resolve(base64);
            }
          });

      });
    
    
    return base64;
  }

  addService() {
    this.invoice.services.push(
      {
        desc: "",
        price: null,
        chargePrice: null,
        hrs: null
      }
    );
  }

  removeService(serviceIndex: number) {
    this.invoice.services.splice(serviceIndex, 1);
  }

  addProduct() {
    this.invoice.products.push(new Product());
  }

  removeProduct(productIndex: number) {
    this.invoice.products.splice(productIndex, 1);
  }
  
  updateInvoiceForm(): void {
    if(this.infoForm != undefined) {
      this.infoForm.reset();
      let dueDate: Date = new Date(this.focusedWorkOrder?.dateScheduled);

      if(this.invoiceSettings?.grace_period != null && this.invoiceSettings?.grace_period != undefined) {
        dueDate.setDate( dueDate.getDate() + this.invoiceSettings.grace_period );
      } else {
        dueDate.setDate( dueDate.getDate() );
      }

      if(this.focusedWorkOrder?.organization == undefined || this.focusedWorkOrder?.organization == null) {
        this.focusedWorkOrder.organization = "";
      }

      this.infoForm.setValue({
        first_name: this.focusedWorkOrder?.custFirstName,
        last_name: this.focusedWorkOrder?.custLastName,
        email: this.focusedWorkOrder?.custEmail,
        phone: this.focusedWorkOrder?.custPhone,

        street: this.focusedWorkOrder?.custAddress?.street,
        city: this.focusedWorkOrder?.custAddress?.city,
        state: this.focusedWorkOrder?.custAddress?.state,
        zip: this.focusedWorkOrder?.custAddress?.zip,

        organization: this.focusedWorkOrder?.organization,
        dueDate: dueDate
      });
    }
  }

  updateWorkOrderContactInfo(): void {
    if(this.infoForm != undefined) {

      this.focusedWorkOrder.custFirstName = this.infoForm.controls["first_name"].value;
      this.focusedWorkOrder.custLastName = this.infoForm.controls["last_name"].value;
      this.focusedWorkOrder.custEmail = this.infoForm.controls["email"].value;
      this.focusedWorkOrder.custPhone = this.infoForm.controls["phone"].value;

      this.focusedWorkOrder.custAddress.street = this.infoForm.controls["street"].value;
      this.focusedWorkOrder.custAddress.city = this.infoForm.controls["city"].value;
      this.focusedWorkOrder.custAddress.state = this.infoForm.controls["state"].value;
      this.focusedWorkOrder.custAddress.zip = this.infoForm.controls["zip"].value;

      this.focusedWorkOrder.organization = this.infoForm.controls["organization"].value;
    };
  }

  resizeCanvas() {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.signatureCanvas.nativeElement.width = this.signatureCanvas.nativeElement.offsetWidth * ratio;
    this.signatureCanvas.nativeElement.height = this.signatureCanvas.nativeElement.offsetHeight * ratio;
    this.signatureCanvas.nativeElement.getContext("2d").scale(ratio, ratio);
    this.clearSignaturePad(); // otherwise isEmpty() might return incorrect value
  }

  initializeSigniaturePad(): void {
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.signatureCanvas?.nativeElement);
    this.signaturePad.penColor = "rgb(66, 133, 244)";
    this.signaturePad.throttle = 0;

    this.signaturePad.addEventListener("endStroke", () => {
      console.log("Signature Ended");

      this.saveSignaturePadData();
    });
  }

  clearSignaturePad(): void {
    if(this.signaturePad != undefined) {
      this.signaturePad.clear();
    }
  }

  getPNGBase64Signature(): string {
    if(this.signaturePad != undefined) {
      return this.signaturePad.toDataURL();
    }
  }

  saveSignaturePadPNG(): void {
    if(this.signaturePad != undefined) {
      let image = this.signaturePad.toDataURL();

      // save image to disk
      let link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", image);
      link.setAttribute("download", this.infoForm.controls["first_name"].value + " " + this.infoForm.controls["last_name"].value + " - Signature.png");
      link.click();

      console.log("Signature PNG: ", image);
    }
  }

  saveSignaturePadJPG(): void {
    if(this.signaturePad != undefined) {
      let image = this.signaturePad.toDataURL("image/jpeg");

      // save image to disk
      let link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", image);
      link.setAttribute("download", this.infoForm.controls["first_name"].value + " " + this.infoForm.controls["last_name"].value + " - Signature.jpg");
      link.click();

      console.log("Signature JPG: ", image);
    }
  }

  saveSignaturePadSVG(): void {
    if(this.signaturePad != undefined) {
      let image = this.signaturePad.toDataURL("image/svg+xml");

      // save image to disk
      let link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", image);
      link.setAttribute("download", this.infoForm.controls["first_name"].value + " " + this.infoForm.controls["last_name"].value + " - Signature.svg");
      link.click();

      console.log("Signature SVG: ", image);
    }
  }

  saveSignaturePadData(): void {
    if(this.signaturePad != undefined) {
      let data = this.signaturePad.toData();

      this.focusedWorkOrder.signature = data;
      console.log("Signature Data: ", data);
    }
  }

  drawSignaturePadData(data: Array<any>): void {
    if(this.signaturePad != undefined && data != null && data != undefined) {
      this.resizeCanvas();

      this.signaturePad.fromData(data);
      this.isSignatureLocked = true;
    }
  }

  unlockSignaturePad(): void {
    this.isSignatureLocked = false;
  }

  public async publishDirectInvoice(invoice): Promise<void> {

    let revId: string = null;

    if(this.focusedWorkOrder != undefined && this.focusedWorkOrder != null) {

      if(this.focusedWorkOrder.invoices == undefined || this.focusedWorkOrder.invoices == null) {
      

        revId = this.navigatorService.generateKey(this.keyTypes.INVOICE);
        this.publishWorkOrderInvoice(revId);
      } else {
        revId = this.focusedWorkOrder.invoices;
      }

    }

    if(this.focusedDeal != undefined && this.focusedDeal != null) {

      if(this.focusedDeal.invoices == undefined || this.focusedDeal.invoices == null) {
      

        revId = this.navigatorService.generateKey(this.keyTypes.INVOICE);
        this.publishDealInvoice(revId);
      } else {
        revId = this.focusedDeal.invoices;
      }

    }

    if(revId == null) {
      revId = this.navigatorService.generateKey(this.keyTypes.INVOICE);
    }

    await this.navigatorService.getAsyncRevenueInvoice(revId).then( (revenue: Revenue) => {

      let newInvoice: RevInvoice = {
        id: this.navigatorService.generateKey(this.keyTypes.INVOICE),
        invoice: {
            payment: this.getPaymentDetails(),
            invoice: invoice,
            date: new Date()
          },
        price: this.totalPrice,
        dueDate: new Date,
        paid: null,
        desc: "",
      
        tag: [],
        last_update: new Date,
        created_by: this.navigatorService.getProfileId()
      }
      
      
      if(revenue != undefined && revenue != null && revenue.id != undefined && revenue.id != null) {
        revenue.directPayments.push(newInvoice);
      } else {

        revenue = {
          id: revId,
          companyId: this.navigatorService.getCompanyId(),
          dealId: this.focusedDeal?.id,
          woId: this.focusedWorkOrder?.workOrderId,
          custPhone: this.infoForm.controls["phone"].value,
          custEmail: this.infoForm.controls["email"].value,
          organization: this.infoForm.controls["organization"].value,
          subscriptions: [],
          directPayments: [newInvoice]
        }

      }

      this.navigatorService.upsertRevenueInvoice(revenue).then( (status: boolean) => {

        if(status) {
          this.snackBar.open('Invoice Published!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        }
  
      });

    });

  }

  public publishDealInvoice(revId: string): void {

    // this.workOrder.invoices.push({
    //   id: this.workOrder.workOrderId,
    //   payment: this.getPaymentDetails(),
    //   invoice: invoice,
    //   date: new Date()
    // });

    this.focusedDeal.invoices = revId;

    this.navigatorService.upsertCRMDeal(this.focusedDeal).then( (status: boolean) => {

      if(status) {
        // this.snackBar.open('Invoice Published!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  public publishWorkOrderInvoice(revId: string): void {

    // this.workOrder.invoices.push({
    //   id: this.workOrder.workOrderId,
    //   payment: this.getPaymentDetails(),
    //   invoice: invoice,
    //   date: new Date()
    // });

    this.focusedWorkOrder.invoices = revId;

    this.navigatorService.updateWorkOrder(this.focusedWorkOrder).then( (status: boolean) => {

      if(status) {
        // this.snackBar.open('Invoice Published!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  saveInvoice(): void {
    this.updateWorkOrderContactInfo();

    this.focusedWorkOrder.servicesRendered = this.invoice.services;
    this.focusedWorkOrder.specialNotes = this.invoice.additionalDetails;

    this.navigatorService.updateWorkOrder(this.focusedWorkOrder);
  }

  // =================================================
  //                      Stripe
  // =================================================

  private getPaymentDetails(): StripePaymentDetails {
    let payment: StripePaymentDetails = null;

    if(this.focusedWorkOrder != null && this.focusedWorkOrder != undefined) {
      let productsTotal: number = 0;
      let servicesTotal: number = 0;
      let subtotal: number = 0;
      let taxRate: number = this.invoiceSettings.tax_rate / 100;

      let paymentDetails: Array<StripePaymentLineItems> = [];

      for(let product of this.invoice.products) {
        productsTotal += product?.price * product.qty;
      }

      for(let service of this.invoice.services) {
        servicesTotal += service?.chargePrice * service.hrs;
      }

      subtotal = productsTotal + servicesTotal;

      if(subtotal > 0) {
        paymentDetails.push(
          {
            name: "Services",
            currency: "usd",
            quantity: 1,

            amount: Math.round( (servicesTotal * 100) )
          }
        );

        paymentDetails.push(
          {
            name: "Products",
            currency: "usd",
            quantity: 1,

            amount: Math.round( productsTotal * 100)
          }
        );

        // Custom Charge Fields
        for(let chargeField of this.invoiceSettings.custom_charge_fields) {

          // Percentage Based
          if(chargeField.type == 1) {
            paymentDetails.push(
              {
                name: chargeField.title,
                currency: "usd",
                quantity: 1,
      
                amount: Math.round( (subtotal * ( Number(chargeField.value) / 100 )) * 100)
              }
            );

          } else {
            // Fixed Based
            paymentDetails.push(
              {
                name: chargeField.title,
                currency: "usd",
                quantity: 1,
      
                amount: Math.round( (Number(chargeField.value)) * 100)
              }
            );
          }      
        }
        
        paymentDetails.push(
          {
            name: "Taxes",
            currency: "usd",
            quantity: 1,

            amount: Math.round( (subtotal * taxRate) * 100)
          }
        );

        payment = {
          stripeAccount: this.stripeAccountID,
          paymentDetails: paymentDetails
        };

      }
    }

    return payment;
  }

  public async getStripeAccountID(): Promise<string> {
    let stripeAccount: string = null;

    await this.navigatorService.getCompanyLevelPaymentSettings().then(async (paymentSettingData: PaymentSettings) => {

      if(paymentSettingData != null && paymentSettingData != undefined) {
        stripeAccount = paymentSettingData.stripe_account_id;
      }

    });

    return stripeAccount;
  } 

  public async getStripeAccount(): Promise<any> {
    let returnAccount: any = null;

    await this.navigatorService.getCompanyLevelPaymentSettings().then(async (paymentSettingData: PaymentSettings) => {

      if(paymentSettingData != null && paymentSettingData != undefined) {
        await this.paymentHandler.getStripeAccount(paymentSettingData.stripe_account_id).then( (account: any) => {

          if(account != null && account != undefined) {
            console.log("Stripe Account: ", account);

            this.isStripeChargesEnabled = account.charges_enabled;
            this.stripeAccountID = paymentSettingData.stripe_account_id;

            returnAccount = account;
          }

        });
      }

    });

    return returnAccount;
  }

  public makePayment(): void {

    if(this.focusedWorkOrder != null && this.focusedWorkOrder != undefined) {
      let payment: StripePaymentDetails = this.getPaymentDetails();

      if(payment != null && payment.stripeAccount != null && payment.stripeAccount != "") {
        this.paymentHandler.makeStripePayment(payment);
      } else {
        this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      }

    }

  }
  
  public makeAfterpayPayment(): void {

    if(this.focusedWorkOrder != null && this.focusedWorkOrder != undefined) {
      let productsTotal: number = 0;
      let servicesTotal: number = 0;
      let subtotal: number = 0;
      let taxRate: number = this.invoiceSettings.tax_rate / 100;

      let paymentDetails: Array<StripePaymentLineItems> = [];

      for(let product of this.invoice.products) {
        productsTotal += product?.price * product.qty;
      }

      for(let service of this.invoice.services) {
        servicesTotal += service?.chargePrice * service.hrs;
      }

      subtotal = productsTotal + servicesTotal;

      if(subtotal > 0) {
        paymentDetails.push(
          {
            name: "Services",
            currency: "usd",
            quantity: 1,

            amount: Math.round( (servicesTotal * 100) )
          }
        );

        paymentDetails.push(
          {
            name: "Products",
            currency: "usd",
            quantity: 1,

            amount: Math.round( productsTotal * 100)
          }
        );

        // Custom Charge Fields
        for(let chargeField of this.invoiceSettings.custom_charge_fields) {

          // Percentage Based
          if(chargeField.type == 1) {
            paymentDetails.push(
              {
                name: chargeField.title,
                currency: "usd",
                quantity: 1,
      
                amount: Math.round( (subtotal * ( Number(chargeField.value) / 100 )) * 100)
              }
            );

          } else {
            // Fixed Based
            paymentDetails.push(
              {
                name: chargeField.title,
                currency: "usd",
                quantity: 1,
      
                amount: Math.round( (Number(chargeField.value)) * 100)
              }
            );
          }      
        }
        
        paymentDetails.push(
          {
            name: "Taxes",
            currency: "usd",
            quantity: 1,

            amount: Math.round( (subtotal * taxRate) * 100)
          }
        );
        

        let payment: StripePaymentDetails = {
          stripeAccount: this.stripeAccountID,
          paymentDetails: paymentDetails
        };

        this.paymentHandler.makeAfterpayPayment(payment);

      }
    }
  }
} 