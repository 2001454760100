import {Component, OnInit, ViewChild, AfterViewInit, Input, Output, ViewEncapsulation, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkOrder, CRMDeal } from 'src/app/data-models/models';

import { NavigatorService } from '../../../services/vendor/navigator.service';


@Component({
    selector: 'app-vendor-project-budget-dialog.component',
    templateUrl: 'vendor-project-budget-dialog.component.html',
    styleUrls: ['vendor-project-budget-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  export class VendorProjectBudgetDialogComponent implements OnInit {

    private workOrderId: string = null;
    private projectId: string = null;

    public workOrder: WorkOrder;
    public project: CRMDeal;

    constructor(
      private navigatorService: NavigatorService,
      @Inject(MAT_DIALOG_DATA) public projectData: any) {

    }
  
    ngOnInit() {

      if(this.projectData != undefined && this.projectData != null) {
        this.workOrderId = this.projectData.workOrder;
        this.projectId = this.projectData.project;

        this.getWorkOrder();
        this.getDeal();
      }

      console.log("Project Data: ", this.projectData);
    }


    async getWorkOrder(): Promise<void> {

      if(this.workOrderId != undefined && this.workOrderId != null) {

        await this.navigatorService.getWorkOrder(this.workOrderId).then( (workOrder: WorkOrder) => {
          this.workOrder = workOrder;
        });

      }

    }

    getDeal(): void {

      if(this.projectId != undefined && this.projectId != null) {

        this.navigatorService.getAsyncCRMDeal(this.projectId).then( (project: CRMDeal) => {
          this.project = project;
        });

      }

    }
}