import { Component, Input, OnInit } from '@angular/core';
import { Settings, AppSettings } from '../../../app.settings';
import { ViewportScroller } from '@angular/common';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialBtn {
  number: number,
  alphabet_ref: string
}

export enum AdditionalMenuPages {
  HOLD = 0,
  CRM_VIEW = 1
}

@Component({
  selector: 'app-vendor-dial-pad',
  templateUrl: 'vendor-dial-pad.component.html',
  styleUrls: ['vendor-dial-pad.component.scss']
})

export class VendorDialPadComponent implements OnInit {
  @Input() minimized: boolean = false;
  public additionalMenuPages: any = AdditionalMenuPages;

  public callTimer: string = "";
  public incomingCall: boolean = false;
  public outboundCall: boolean = false;

  public phoneNumber: string = "";
  public callInitiated: boolean = false;
  public muteMic: boolean = false;

  public expandMessages: boolean = false;
  public additionalMenu: boolean = false;
  public expandCRMView: boolean = false;
  public activeAdditionalMenuPage: number = this.additionalMenuPages.HOLD;

  public dialPadNumbers: Array<DialBtn> = [
    { 
      number: 1, 
      alphabet_ref: ""
    },
    { 
      number: 2, 
      alphabet_ref: "abc"
    },
    { 
      number: 3, 
      alphabet_ref: "def"
    },
    { 
      number: 4, 
      alphabet_ref: "ghi"
    },
    { 
      number: 5, 
      alphabet_ref: "jkl"
    },
    { 
      number: 6, 
      alphabet_ref: "mno"
    },
    { 
      number: 7, 
      alphabet_ref: "pqrs"
    },
    { 
      number: 8, 
      alphabet_ref: "tuv"
    },
    { 
      number: 9, 
      alphabet_ref: "wxyz"
    }
  ]

  constructor(
    private twilioHandler: TwilioHandlerService,
    private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.initializeDialVariables();
    this.listeners();
  }

  ngOnDestroy(){    
    
  }

  private initializeDialVariables(): void {

    this.callTimer = this.twilioHandler.getConnectedCallTimer();

    this.incomingCall = this.twilioHandler.getIncomingCall();

    // this.callStatus = this.twilioHandler.getCallStatus();

    this.phoneNumber = this.twilioHandler.getPhoneNumber();

    this.callInitiated = this.twilioHandler.getCallInitiated();
    // this.callInitiated = true;

    this.muteMic = this.twilioHandler.getMuteMic();

  }

  private listeners(): void {

    // private callStatusTrigger = new Subject<boolean>();
    // private phoneNumberTrigger = new Subject<string>();
    // private callInitiatedTrigger = new Subject<boolean>();
    // private muteMicTrigger = new Subject<boolean>();

    this.twilioHandler.getConnectedCallTimerTrigger().subscribe( timer => {
      this.callTimer = timer;
    });

    this.twilioHandler.getIncomingCallTrigger().subscribe( inboundCall => {
      this.incomingCall = inboundCall;
    });

    this.twilioHandler.getOutboundCallTrigger().subscribe( outboundCall => {
      this.outboundCall = outboundCall;
    });

    this.twilioHandler.getPhoneNumberTrigger().subscribe( phoneNumber => {
      this.phoneNumber = phoneNumber;
    });

    this.twilioHandler.getCallInitiatedTrigger().subscribe( initiated => {
      this.callInitiated = initiated;
    });

    this.twilioHandler.getMuteMicTrigger().subscribe( muted => {
      this.muteMic = muted;
    });

    

  }

  public messagePanelToggle(): void {

    if(this.expandMessages) {
      this.expandMessages = false;
    } else {
      this.expandMessages = true;
    }

  }

  public additionalPanelToggle(): void {

    if(this.additionalMenu) {
      this.additionalMenu = false;
    } else {
      this.additionalMenu = true;
    }

  }

  public additionalMenuPageSwitcher(page: number): void {

    this.activeAdditionalMenuPage = page;

  }

  

  public dialBtnAdd(input: number): void {
    this.phoneNumber += input;

    this.twilioHandler.setPhoneNumber(this.phoneNumber);
  }

  public dialInputAdd(input: string): void {
    this.phoneNumber = input;
    this.twilioHandler.setPhoneNumber(this.phoneNumber);
  }

  public dialBtnRemove(): void {
    let tmp = this.phoneNumber.split(''); // convert to an array
    tmp.splice(this.phoneNumber.length - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
    tmp.join(''); // reconstruct the string

    this.phoneNumber = tmp.join('');
  }

  public acceptIncomingCall(): void {
    this.twilioHandler.acceptIncomingCall();
    this.callInitiated = true;
  }

  public rejectIncomingCall(): void {
    this.twilioHandler.rejectIncomingCall();
    this.callInitiated = false;
    this.incomingCall = false;
    this.muteMic = false;
    this.phoneNumber = "";
  }

  public makeOutboundCall(): void {

    if(this.phoneNumber.length != 10) {
      this.snackBar.open('Call Connection Refusal: A 10 digit number is required to initiate a phone call.', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.callInitiated = true;
    this.twilioHandler.makeOutboundCall(this.phoneNumber);
  }

  public placeCallOnHold(): void {
    this.twilioHandler.placeCallOnHold(null);
  }

  public disconnectOutboundCall(): void {
    this.callInitiated = false;
    this.phoneNumber = "";
    this.incomingCall = false;
    this.twilioHandler.disconnectCall()
    this.muteMic = false;
  }

  public muteMicToggle(): void {

    if(this.muteMic) {
      this.muteMic = false;
    } else {
      this.muteMic = true;
    }

    this.twilioHandler.muteMicToggle();

  }

}
