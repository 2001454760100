import { Component, OnInit, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavigatorService } from '../../../services/vendor/navigator.service';
import { Observable, Subscription } from 'rxjs'; 

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutocompleteServices, CRMDeal, WorkOrder, Budget, BudgetBreakdown } from 'src/app/data-models/models';
import { Color } from 'ng2-charts/lib/color';


@Component({
  selector: 'app-vendor-project-budget',
  templateUrl: 'vendor-project-budget.component.html',
  styleUrls: ['vendor-project-budget.component.scss'] 
})

export class VendorProjectBudgetComponent implements OnInit {

  @Input('deal') deal: CRMDeal = null;
  @Input('workOrder') workOrder: WorkOrder = null;

  public project: any = {
    budget: null
  }

  public autocompleteServices: AutocompleteServices = {
    labor: [],
    parts: [],
    equipment: [],
    travel: [],
    misc: []
  }

  public activeTab: string = 'labor';

  // Charts & Reporting
  private PL_profits: number = 0;
  private PL_profjected: number = 0;
  private PL_missed_earnings: number = 0;

  public PL_legend: boolean = false;
  public PL_labels: Array<string> = ['Profit', 'Projected', 'Loss'];
  public PL_data: Array< Array<any> > | Array<any> = [ 

    [
      0,
      150, 
      0
    ]

  ];
  
  public PL_margin: number | string = 21;

  public PL_colors: Color[] = [
    {
      backgroundColor:["#39c827","#eded1c","#ed261c"]
    }
  ];



  constructor(private navigatorService: NavigatorService, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.initBudget();
    this.getAutocompleteServices();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Incoming Project Budget: ", this.project);

    if(
      (this.project == undefined || this.project == null) &&
      (this.workOrder == undefined || this.workOrder == null)
    ) {
      return;
    }

    this.initBudget();
    this.verifyProfitMargin();

  } 

  private initBudget(): void {

    if(this.deal != undefined && this.deal != null) {
      this.project.budget = this.deal?.budget;
    }

    else if(this.workOrder != undefined && this.workOrder != null) {
      this.project.budget = this.workOrder?.budget;
    }

    if(this.project.budget == undefined || this.project.budget == null || typeof(this.project.budget) == 'string') {

      this.project.budget = {
        id: this.navigatorService.generateKey(),
        breakdown: {
          labor: {
            lineItems: [],
            total: 0
          },
          equipment: {
            lineItems: [],
            total: 0
          },
          parts: {
            lineItems: [],
            total: 0
          },
          travel: {
            lineItems: [],
            total: 0
          }
        },
        last_updated: new Date(),
        total: 0,
        chargeTotal: 0
      }

    }

  }

  private updateDeal(): void {
    let self = this;

    this.navigatorService.getAsyncCRMDeal(this.deal.id).then( (deal: CRMDeal) => {

      self.deal = deal;
      self.deal.budget = self.project.budget;
      self.navigatorService.upsertCRMDeal(self.deal);

    })

  }

  private updateWorkOrder(): void {
    let self = this;

    this.navigatorService.getWorkOrder(this.workOrder.workOrderId).then( (wo: WorkOrder) => {

      self.workOrder = wo;
      self.workOrder.budget = self.project.budget;
      self.navigatorService.updateWorkOrder(self.workOrder);

    })

  }

  private updateBudget(): void {
    console.log("Budget Update");

    if(this.deal != undefined && this.deal != null) {
      this.updateDeal();
    }

    else if(this.workOrder != undefined && this.workOrder != null) {
      this.updateWorkOrder();
    }

  }

  private getAutocompleteServices(): void {

    this.navigatorService.getAsyncAutocompleteServices().then((services: AutocompleteServices) => {

      this.autocompleteServices = services;

    });

  }

  public serviceTrackerChanged(event): void {
    console.log("Service Charges Updated", event);

    this.verifyPriceTotals();
    this.verifyProfitMargin();

    this.updateBudget();
  }

  public autocompleteServicesChanged(event): void {
    console.log("Autocomplete Services Changed");
    this.autocompleteServices[this.activeTab] = event;
    this.navigatorService.upsertAutocompleteServices(this.autocompleteServices);
  }

  public tabChanged(tab: string): void {
    this.activeTab = tab;

    if(this.activeTab == 'settings') {
      this.activeTab = 'labor';
    }

  }

  private verifyPriceTotals(): void {

    let budget: BudgetBreakdown = this.project.budget.breakdown;

    budget.equipment.total = 0;
    budget.equipment.chargeTotal = 0;

    budget.labor.total = 0;
    budget.labor.chargeTotal = 0;

    budget.parts.total = 0;
    budget.parts.chargeTotal = 0;

    budget.travel.total = 0;
    budget.travel.chargeTotal = 0;

    for(let lineItem of budget.equipment.lineItems) {
      budget.equipment.total += (lineItem.price * lineItem.hrs);
      budget.equipment.chargeTotal += (lineItem.chargePrice * lineItem.hrs);
    }

    for(let lineItem of budget.labor.lineItems) {
      budget.labor.total += (lineItem.price * lineItem.hrs);
      budget.labor.chargeTotal += (lineItem.chargePrice * lineItem.hrs);
    }

    for(let lineItem of budget.parts.lineItems) {
      budget.parts.total += (lineItem.price * lineItem.hrs);
      budget.parts.chargeTotal += (lineItem.chargePrice * lineItem.hrs);
    }

    for(let lineItem of budget.travel.lineItems) {
      budget.travel.total += (lineItem.price * lineItem.hrs);
      budget.travel.chargeTotal += (lineItem.chargePrice * lineItem.hrs);
    }

    this.project.budget.total = 
      budget.equipment.total + 
      budget.labor.total + 
      budget.parts.total + 
      budget.travel.total;

    this.project.budget.chargeTotal = 
      budget.equipment.chargeTotal + 
      budget.labor.chargeTotal + 
      budget.parts.chargeTotal + 
      budget.travel.chargeTotal;

  }

  private verifyProfitMargin(): void {
    this.PL_data = [ 
        
      [
        (this.project.budget != undefined && this.project.budget != null ? this.project.budget.chargeTotal : 0),
        (this.project.budget != undefined && this.project.budget != null ? this.project.price - (this.project.budget.chargeTotal + this.project.budget.total) : this.project.price),  
        (this.project.budget != undefined && this.project.budget != null ? this.project.budget.total : 0)
      ]

    ];

    if(this.PL_data[0][1] < 0) {
      this.PL_data[0][1] = 0
    }

    this.calculateProfitMargin();
  }

  private calculateProfitMargin(): void {
    console.log("Calculate P&L");

    let profit: number = this.PL_data[0][0];
    let loss: number = this.PL_data[0][2];

    let pl: number = profit - loss;
    let margin: number = (profit > 0)? (pl / profit) * 100 : 0 ;

    this.PL_margin = Math.round( margin );

    // this.PL_margin = "Calc..."
  }

} 