

export interface cssProperty {
    property: string;
    value?: string | Number;
}

export var properties: cssProperty[] = 
[
    {
        property: "align-content",
        value: ""
    }, 
    {
        property: "align-items",
        value: ""
    }, 	
    {
        property: "align-self",
        value: ""
    }, 	
    {
        property: "animation-delay",
        value: ""
    }, 
    {
        property: "animation-direction",
        value: ""
    }, 
    {
        property: "animation-duration",
        value: ""
    },	
    {
        property: "animation-fill-mode",
        value: ""
    }, 	
    {
        property: "animation-iteration-count",
        value: ""
    }, 	
    {
        property: "animation-play-state",
        value: ""
    }, 	
    {
        property: "animation-timing-function",
        value: ""
    }, 
    {
        property: "backface-visibility",
        value: ""
    }, 	
    {
        property: "background",
        value: ""
    },
    {
        property: "background-attachment",
        value: ""
    },	
    {
        property: "background-clip",
        value: ""
    }, 	
    {
        property: "background-color",
        value: ""
    },	
    {
        property: "background-image",
        value: ""
    },	
    {
        property: "background-origin",
        value: ""
    }, 
    {
        property: "background-position",
        value: ""
    },	
    {
        property: "background-repeat",
        value: ""
    },	
    {
        property: "background-size",
        value: ""
    }, 
    {
        property: "border",
        value: ""
    },	
    {
        property: "border-bottom",
        value: ""
    },	
    {
        property: "border-bottom-color",
        value: ""
    },
    {
        property: "border-bottom-left-radius",
        value: ""
    }, 
    {
        property: "border-bottom-right-radius",
        value: ""
    }, 	
    {
        property: "border-bottom-style",
        value: ""
    },	
    {
        property: "border-bottom-width",
        value: ""
    },	
    {
        property: "border-collapse",
        value: ""
    },	
    {
        property: "border-color",
        value: ""
    },	
    {
        property: "border-image",
        value: ""
    }, 	
    {
        property: "border-image-outset",
        value: ""
    }, 	
    {
        property: "border-image-repeat",
        value: ""
    }, 	
    {
        property: "border-image-slice",
        value: ""
    }, 
    {
        property: "border-image-source",
        value: ""
    },	
    {
        property: "border-image-width",
        value: ""
    }, 
    {
        property: "border-left",
        value: ""
    },	
    {
        property: "border-left-color",
        value: ""
    },	
    {
        property: "border-left-style",
        value: ""
    },	
    {
        property: "border-left-width",
        value: ""
    },	
    {
        property: "border-radius",
        value: ""
    }, 	
    {
        property: "border-right",
        value: ""
    },	
    {
        property: "border-right-color",
        value: ""
    },	
    {
        property: "border-right-style",
        value: ""
    },	
    {
        property: "border-right-width",
        value: ""
    },	
    {
        property: "border-spacing",
        value: ""
    },	
    {
        property: "border-style",
        value: ""
    },	
    {
        property: "border-top",
        value: ""
    },
    {
        property: "border-top-color",
        value: ""
    },
    {
        property: "border-top-left-radius",
        value: ""
    }, 
    {
        property: "border-top-right-radius",
        value: ""
    }, 	
    {
        property: "border-top-style",
        value: ""
    },
    {
        property: "border-top-width",
        value: ""
    },	
    {
        property: "border-width",
        value: ""
    },	
    {
        property: "bottom",
        value: ""
    },	
    {
        property: "box-shadow",
        value: ""
    }, 	
    {
        property: "box-sizing",
        value: ""
    }, 
    {
        property: "caption-side",
        value: ""
    },	
    {
        property: "clear",
        value: ""
    },	
    {
        property: "clip",
        value: ""
    },
    {
        property: "color",
        value: ""
    },	
    {
        property: "column-count",
        value: ""
    }, 	
    {
        property: "column-fill",
        value: ""
    }, 	
    {
        property: "column-gap",
        value: ""
    }, 	
    {
        property: "column-rule",
        value: ""
    }, 	
    {
        property: "column-rule-color",
        value: ""
    }, 	
    {
        property: "column-rule-style",
        value: ""
    }, 	
    {
        property: "column-rule-width",
        value: ""
    }, 	
    {
        property: "column-span",
        value: ""
    }, 	
    {
        property: "column-width",
        value: ""
    }, 	
    {
        property: "columns",
        value: ""
    }, 	
    {
        property: "content",
        value: ""
    },	
    {
        property: "counter-increment",
        value: ""
    },	
    {
        property: "counter-reset",
        value: ""
    },	
    {
        property: "cursor",
        value: ""
    },	
    {
        property: "direction",
        value: ""
    },	
    {
        property: "display",
        value: ""
    },	
    {
        property: "empty-cells",
        value: ""
    },	
    {
        property: "flex",
        value: ""
    }, 	
    {
        property: "flex-basis",
        value: ""
    }, 	
    {
        property: "flex-direction",
        value: ""
    }, 	
    {
        property: "flex-flow",
        value: ""
    }, 	
    {
        property: "flex-grow",
        value: ""
    },	
    {
        property: "flex-shrink",
        value: ""
    }, 	
    {
        property: "flex-wrap",
        value: ""
    }, 	
    {
        property: "float",
        value: ""
    },	
    {
        property: "font",
        value: ""
    },	
    {
        property: "font-family",
        value: ""
    },	
    {
        property: "font-size",
        value: ""
    },	
    {
        property: "font-size-adjust",
        value: ""
    }, 	
    {
        property: "font-stretch",
        value: ""
    }, 	
    {
        property: "font-style",
        value: ""
    },	
    {
        property: "font-variant",
        value: ""
    },	
    {
        property: "font-weight",
        value: ""
    },	
    {
        property: "height",
        value: ""
    },	
    {
        property: "justify-content",
        value: ""
    }, 	
    {
        property: "left",
        value: ""
    },	
    {
        property: "letter-spacing",
        value: ""
    },	
    {
        property: "line-height",
        value: ""
    },	
    {
        property: "list-style",
        value: ""
    },	
    {
        property: "list-style-image",
        value: ""
    },	
    {
        property: "list-style-position",
        value: ""
    },	
    {
        property: "list-style-type",
        value: ""
    },	
    {
        property: "margin",
        value: ""
    },	
    {
        property: "margin-bottom",
        value: ""
    },	
    {
        property: "margin-left",
        value: ""
    },	
    {
        property: "margin-right",
        value: ""
    },	
    {
        property: "margin-top",
        value: ""
    },	
    {
        property: "max-height",
        value: ""
    },	
    {
        property: "max-width",
        value: ""
    },	
    {
        property: "min-height",
        value: ""
    },	
    {
        property: "min-width",
        value: ""
    },	
    {
        property: "opacity",
        value: ""
    }, 	
    {
        property: "order",
        value: ""
    }, 	
    {
        property: "outline",
        value: ""
    },	
    {
        property: "outline-color",
        value: ""
    },	
    {
        property: "outline-offset",
        value: ""
    }, 	
    {
        property: "outline-style",
        value: ""
    },	
    {
        property: "outline-width",
        value: ""
    },	
    {
        property: "overflow",
        value: ""
    },	
    {
        property: "overflow-x",
        value: ""
    }, 	
    {
        property: "overflow-y",
        value: ""
    },	
    {
        property: "padding",
        value: ""
    },	
    {
        property: "padding-bottom",
        value: ""
    },	
    {
        property: "padding-left",
        value: ""
    },	
    {
        property: "padding-right",
        value: ""
    },	
    {
        property: "padding-top",
        value: ""
    },	
    {
        property: "page-break-after",
        value: ""
    },	
    {
        property: "page-break-before",
        value: ""
    },	
    {
        property: "page-break-inside",
        value: ""
    },	
    {
        property: "perspective",
        value: ""
    }, 	
    {
        property: "perspective-origin",
        value: ""
    }, 	
    {
        property: "position",
        value: ""
    },	
    {
        property: "quotes",
        value: ""
    },	
    {
        property: "resize",
        value: ""
    }, 
    {
        property: "right",
        value: ""
    },	
    {
        property: "tab-size",
        value: ""
    }, 	
    {
        property: "table-layout",
        value: ""
    },	
    {
        property: "text-align",
        value: ""
    },	
    {
        property: "text-align-last",
        value: ""
    }, 	
    {
        property: "text-decoration",
        value: ""
    },	
    {
        property: "text-decoration-color",
        value: ""
    }, 	
    {
        property: "text-decoration-line",
        value: ""
    }, 	
    {
        property: "text-decoration-style",
        value: ""
    }, 	
    {
        property: "text-indent",
        value: ""
    },	
    {
        property: "text-justify",
        value: ""
    }, 	
    {
        property: "text-overflow",
        value: ""
    }, 	
    {
        property: "text-shadow",
        value: ""
    }, 	
    {
        property: "text-transform",
        value: ""
    },	
    {
        property: "top",
        value: ""
    },	
    {
        property: "transform",
        value: ""
    }, 	
    {
        property: "transform-origin",
        value: ""
    }, 	
    {
        property: "transform-style",
        value: ""
    },	
    {
        property: "transition",
        value: ""
    }, 	
    {
        property: "transition-delay",
        value: ""
    }, 	
    {
        property: "transition-duration",
        value: ""
    }, 	
    {
        property: "transition-property",
        value: ""
    }, 	
    {
        property: "transition-timing-function",
        value: ""
    }, 	
    {
        property: "vertical-align",
        value: ""
    },	
    {
        property: "visibility",
        value: ""
    },	
    {
        property: "white-space",
        value: ""
    },	
    {
        property: "width",
        value: ""
    },	
    {
        property: "word-break",
        value: ""
    }, 	
    {
        property: "word-spacing",
        value: ""
    },	
    {
        property: "word-wrap",
        value: ""
    }, 	
    {
        property: "z-index",
        value: ""
    }
];