import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupPermissions, Profile } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';

export enum FilterCategories {
  FAVORITES = 0,
  OWNERS = 1,
  TEAMS = 2,
  FILTERS = 3
}

export interface FavoriteOwner {
  id: string,
  isFavorite: boolean,
  first_name?: string,
  last_name?: string
}

export interface ActiveOption {
  id: string,
  category: number
}

@Component({
  selector: 'app-vendor-team-menu',
  templateUrl: 'vendor-team-menu.component.html',
  styleUrls: ['vendor-team-menu.component.scss']
})
export class VendorTeamMenuComponent implements OnInit {

  @Output() employeeFilterChange: EventEmitter<Array<Profile>> = new EventEmitter<Array<Profile>>();

  public personalId: string = this.navigatorService.getProfileId();
  public filterCategories: any = FilterCategories;
  public activeFilterCategory: number = this.filterCategories.OWNERS;

  private favOwnersVault: Array<FavoriteOwner> = [];
  private teamsVault: Array<GroupPermissions> = [];
  public favOwners: Array<FavoriteOwner> = [];
  public teams: Array<GroupPermissions> = [];

  public filteredEmployeeOptions: Array<string> = [];
  public filteredEmployeeProfiles: Array<Profile> = [];
  private employeeLookupText: string = "";
  private ownerFilterText: string = "";

  public activeOption: ActiveOption = null;
  public hasFavorites: boolean = false;


  constructor(private navigatorService: NavigatorService, public snackBar: MatSnackBar, ) { }

  ngOnInit() {

    this.getTeams();

  }

  private getTeams(): void {

    this.navigatorService.getAsyncGroupPermissions().then( (teams: Array<GroupPermissions>) => {

      this.teams = teams;
      this.teamsVault = teams;

    });

  }

  public updateFilterText(filterText: string): void {
    this.employeeLookupText = filterText;
  }

  public filterEmployee(filterText: string, updateSearch: boolean = true): void {
    this.employeeLookupText = filterText;
    this.filteredEmployeeOptions = [];

    this.navigatorService.getPagedSearchProfiles(filterText, 0, 10).then( (filteredProfiles: Array<Profile>) => {
      if(updateSearch) {
        this.filteredEmployeeProfiles = [];
      }

      if(filteredProfiles != undefined && filteredProfiles != null) {
        
        if(updateSearch) {
          this.filteredEmployeeProfiles = filteredProfiles;
        }

        for(let company of filteredProfiles) {
          let name: string = company.first_name + ' ' + company.last_name;
          this.filteredEmployeeOptions.push(name);
        }

      }

    });

  }

  public addEmployee(): void {

    console.log("Add Employee: ", this.employeeLookupText);

    let employee: Profile = this.filteredEmployeeProfiles.find( (profile: Profile) => { 
      let name: string = profile.first_name + ' ' + profile.last_name;
      return name.toLowerCase() == this.employeeLookupText.toLowerCase();
    });

    if(employee != undefined && employee != null) {

      this.filteredEmployeeProfiles = [];
      this.filteredEmployeeOptions = [];
      this.employeeLookupText = "";

      let newFav: FavoriteOwner = {
        id: employee.id,
        isFavorite: true,
        first_name: employee.first_name,
        last_name: employee.last_name
      };

      this.favOwners.push(newFav);
      this.favOwnersVault.push(newFav);

      this.checkForFavorites();
      this.filterOwners();
    } else {
      this.snackBar.open('There was trouble locating profile information. Please make sure the employee\'s name was spelled correctly!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public toggleFavoriteMark(favorite: FavoriteOwner): void {

    if(favorite.isFavorite) {
      favorite.isFavorite = false;
    } else {
      favorite.isFavorite = true;
    }

    this.checkForFavorites();
  }

  private checkForFavorites(): void {

    let favorite: FavoriteOwner = this.favOwnersVault.find( (fav: FavoriteOwner) => { return fav.isFavorite });

    if(favorite != undefined && favorite != null) {
      this.hasFavorites = true;
    } else {
      this.hasFavorites = false;
    }

  }

  public changeActiveOption(option: string, type: number): void {
    let focusIds: Array<string> = [];

    this.activeOption = {
      id: option,
      category: type
    };

    if(type == this.filterCategories.OWNERS) {
      focusIds = [option];
    }

    else if(type == this.filterCategories.FAVORITES) {
      focusIds = [option];
    }

    else if (type == this.filterCategories.TEAMS) {
      
      let team: GroupPermissions = this.teamsVault.find( (el: GroupPermissions) => { return el.id == option });

      if(team != undefined && team != null) {
        focusIds = team.employeeIds;
      }

    }

    this.getEmployees(focusIds);
  }

  private getEmployees(ids: Array<string>): void {

    if(ids.length > 0) {

      this.navigatorService.getAsyncProfiles(ids).then( (profiles: Array<Profile>) => {

        console.log("Profiles: ", profiles);
        this.employeeFilterChange.emit(profiles);

      });

    }

  }

  public applyFilter(value: string): void {
    this.ownerFilterText = value;
    this.filterOwners();
  }

  public filterOwners(): void {
    this.teams = this.teamsVault.filter( (team: GroupPermissions) => { return team.group.toLowerCase().includes(this.ownerFilterText.toLowerCase()) } );

    this.favOwners = this.favOwnersVault.filter( (fav: FavoriteOwner) => { 
      let firstNameCheck: boolean = fav.first_name.toLowerCase().includes(this.ownerFilterText.toLowerCase());
      let lastNameCheck: boolean = fav.last_name.toLowerCase().includes(this.ownerFilterText.toLowerCase());
      return  firstNameCheck || lastNameCheck;
    } );
  }

  public changeFilterCategory(cat: number): void {
    this.filteredEmployeeProfiles = [];
    this.filteredEmployeeOptions = [];
    this.employeeLookupText = "";

    this.activeFilterCategory = cat;
  }

}