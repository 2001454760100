import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHTML'
})
export class SanitizeHTMLPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    console.log("Sanitize URL: ", value);

    let safeURL: SafeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(value);

    return safeURL;
  }
  
}