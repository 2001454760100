import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords, emailValidator } from 'src/app/theme/utils/app-validators';
import { keyTypesEnum, NavigatorService } from 'src/app/services/vendor/navigator.service';
import { Address, CompanyProfile, GroupPermissions, PlatformInvoice, StripePaymentDetails } from 'src/app/data-models/models';
import { GroupedObservable, Observable, Subscription } from 'rxjs';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { PlatformService } from 'src/app/services/vendor/platform.service';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { environment } from 'src/environments/environment';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {
  @Output('register') registerEmitter: EventEmitter<any> = new EventEmitter<any>();

  public basicInfoForm = this.formBuilder.group({
    company_name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
    email: ['', Validators.compose([Validators.required, emailValidator])],
    phone: ['', Validators.required],

    image: null,

    street: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
    city: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
    state: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    zip: ['', Validators.compose([Validators.required, Validators.minLength(5)])]
  });

  public socialInfoForm = this.formBuilder.group({
    facebook: null,
    twitter: null,
    linkedin: null,
    instagram: null,
    website: null
  });

  public complianceForm = this.formBuilder.group({
    ein: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],
    acceptTerms: [ false, Validators.requiredTrue],
    acceptESign: [ false, Validators.requiredTrue]
  });

  private keyTypes:any = keyTypesEnum;

  private imgSubscription: Subscription;
  public imgId: string = null;
  public image: any;
  public imageDeleteAction: boolean = false;

  private groupPermissionSubscriber: Subscription;
  public groupPermissions: Array<GroupPermissions> = [];
  public lockedGroupPermissions: Array<GroupPermissions> = [
  
    {
      id: "locked-all",
      group: "all",
      employeeIds: [ ],
      pages: JSON.parse( JSON.stringify(this.navigatorService.getPagePermissionsTemplate()) )
    },

    {
      id: "locked-admin",
      group: "admin",
      employeeIds: [ ],
      pages: JSON.parse( JSON.stringify(this.navigatorService.getPagePermissionsTemplate()) )
    }

  ];
  
  stepperOrientation: Observable<StepperOrientation>;


  private companyId: string = this.navigatorService.generateKey(this.keyTypes.COMPANY_ID);

  constructor(
    private platformService: PlatformService,
    private formBuilder: FormBuilder, 
    private breakpointObserver: BreakpointObserver,
    private router:Router, 
    private snackBar: MatSnackBar, 
    private navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService,
    private paymentHandler: PaymentHandlerService,
    private twilioHandlerService: TwilioHandlerService) { }

  ngOnInit() {

    this.registerListeners();

    this.basicInfoForm = this.formBuilder.group({
      company_name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.required],

      image: null,

      street: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      city: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      state: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      zip: ['', Validators.compose([Validators.required, Validators.minLength(5)])]
    });

    this.socialInfoForm = this.formBuilder.group({
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      website: null
    });

    this.complianceForm = this.formBuilder.group({
      ein: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],
      acceptTerms: [ false, Validators.requiredTrue],
      acceptESign: [ false, Validators.requiredTrue]
    });


  }

  ngOnDestroy() {

    if(this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }

    if(this.groupPermissionSubscriber) {
      this.groupPermissionSubscriber.unsubscribe();
    }

  }  

  private registerListeners(): void {

    this.stepperOrientation = this.breakpointObserver
    .observe('(min-width: 800px)')
    .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));

  }

  public openWindowURL(url: string): void {
    window.open("https://www.swarmoperative.com" + url);
  }

  public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {

      if(img) {
        this.image = img;

        console.log("Image: ", this.image);
      }

    });
    
  }

  public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
      this.imageHandler.getFile(this.imgId);
    }
  }

  public uploadImage(image: any, folder: string): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique, folder).then(data => {
          this.imgId = imgIdUnique;
          this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
  }

  public deleteImg(): void {
    this.basicInfoForm.controls["image"].setValue("");
    this.image = null;
    this.imageDeleteAction = true;
  }

  public createAnnualMemberInvoice(): void {

    let invoice: PlatformInvoice = {
      id: this.navigatorService.generateKey(),
      companyId: this.companyId,
      discountCode: null,
      discountPerc: 0,
      discountTotal: 0,
      quantity: 1,
      price: 99000,
      dueDate: new Date(),
      paid: null,
      desc: 'Swarm Operative Annual Member Fee',
      annualMembership: true,
      tag: [],
      last_update: new Date(),
      created_date: new Date(),
      created_by: 'swarm_operative'
    };

    this.paymentHandler.createPlatformPaymentInvoice(invoice);

  }

  public memberFee(platformInvoice: PlatformInvoice): void {

    let invoice: StripePaymentDetails = {
      stripeAccount: '',
      paymentDetails: [
        {
          name: platformInvoice.desc,
          currency: "usd",
          quantity: platformInvoice.quantity,
      
          // tax_rates: number;
          amount: platformInvoice.price
        }
      ]
    };

    // this.paymentHandler.makePlatformStripePayment(invoice, this.companyId);

  }

  public onRegisterFormSubmit():void {

    if(this.basicInfoForm.valid && this.socialInfoForm.valid && this.complianceForm.valid) {
      let companyId: string = this.companyId;
      let imgId: string = "";

      if((this.basicInfoForm.get("image").value != null && this.basicInfoForm.get("image").value.length > 0) || this.imageDeleteAction) {
        imgId = this.uploadImage(this.basicInfoForm.get("image").value, companyId);
        this.imageDeleteAction = false;
      } else {
        imgId = this.imgId; 
      }

      let address: Address = {
        street: this.basicInfoForm.get("street").value,
        city: this.basicInfoForm.get("city").value,
        state: this.basicInfoForm.get("state").value,
        zip: this.basicInfoForm.get("zip").value
      }

      let company: CompanyProfile = {
        id: companyId,
        company_name: this.basicInfoForm.get("company_name").value,
        email: this.basicInfoForm.get("email").value,
        phone: this.basicInfoForm.get("phone").value,
        address: address,
        linkedin: this.socialInfoForm.get("linkedin").value,
        twitter: this.socialInfoForm.get("twitter").value,
        instagram: this.socialInfoForm.get("instagram").value,
        facebook: this.socialInfoForm.get("facebook").value,
        website: this.socialInfoForm.get("website").value,

        ein: this.complianceForm.get("ein").value,
        acceptTerms: (this.complianceForm.get("acceptTerms").value)? new Date() : null,
        acceptESign: (this.complianceForm.get("acceptESign").value)? new Date() : null,

        image: imgId,
        created_date: new Date
      }

      this.navigatorService.registerNewCompany(company).then( (status: boolean) => {

        if(status) {
          this.newCompanyAlert();

          this.snackBar.open('Registration successfull!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.subscribeToGroupPermissions();
          this.registerEmitter.emit();
        }

      });

    }
  }

  private newCompanyAlert(): void {

    let message: string = "New Company Registration: " + this.navigatorService.getCompanyName();
    let phone: string = environment.adminPhone;

    this.twilioHandlerService.sendTextMessage(message, phone);

  }

// ==============================
//        Group Permissions
// ==============================

  private subscribeToGroupPermissions(): void {
    let self = this;

    this.groupPermissionSubscriber = this.navigatorService.getGroupPermissions().subscribe(data => { 

      if(data != null) {
        console.log("Returned Group Permissions: ", data);
        self.groupPermissions = data;
        self.loadLockedGroups();
        self.setDefaultPermissions();
      }

    });
  }

  private getGroupPermissions(): void {
    this.navigatorService.getGroupPermissions();
  }

  private loadLockedGroups(): void {
    let lockedAllExist: boolean = false;
    let lockedAdminExist: boolean = false;
      

    this.groupPermissions.forEach((groupPermission, groupIndex) => {

      if(groupPermission.id == "locked-all") {
        lockedAllExist = true;
      }

      if(groupPermission.id == "locked-admin") {
        lockedAdminExist = true;
      }

    });

    // All
    if(!lockedAllExist) {
      // Inserting the Locked All Group into the top of the Group Permissions array
      this.groupPermissions.splice(0, 0, this.lockedGroupPermissions[0]);
    }

    // Admin
    if(!lockedAdminExist) {
      this.groupPermissions.push(this.lockedGroupPermissions[1]);
    }

  }

  private setDefaultPermissions(): void {
    console.log("Setting Group Default Permissions: ", this.groupPermissions);

    for(let group of this.groupPermissions) {

      if(group.id == "locked-admin") {

        group.employeeIds.push(this.navigatorService.getProfileId());

        for(let page of group.pages) {

          page.permission.pageAccess = true;

          for(let section of page.permission.sections) {

            section.permissions.create = true;
            section.permissions.read = true;
            section.permissions.update = true;
            section.permissions.delete = true;

          }

        }

      } else {
        group.employeeIds.push(this.navigatorService.getProfileId());
      }

    }

    this.saveGroup();
  }

  private saveGroup(): void {
    console.log("Saving Group Permissions: ", this.groupPermissions);
    this.navigatorService.updateGroupPermissions(this.groupPermissions).then( (completed: boolean) => {

      if(completed) {
        this.snackBar.open('Groups Saved!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
        this.reloadGroupPermissions();
      }

      this.navigatorService.logout();
    });
  }

  private reloadGroupPermissions(): void {
    
   this.platformService.refreshUserPermissions();

  }

}
